import React from "react";
import DriverFormCompletedContent from "../../components/driver-components/DriverFormCompletedContent";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

type Props = {};

function AddDriverCompletedPage({}: Props) {
  return (
    <>
      <Head title={"Successful"} />
      <Content>
        <DriverFormCompletedContent />
      </Content>
    </>
  );
}

export default AddDriverCompletedPage;
