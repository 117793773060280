import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import User from "./dropdown/user/User";

import BackButton from "../../components/button/BackButton";
import { useLocation } from "react-router";
import HeaderLink from "./HeaderLink";
import SustainabilityScore from "./SustainabilityScore";
import React, { useCallback } from "react";
import AppWideTeslaConnectionBadge from "../../components/badge/AppWideTeslaConnectionBadge";


const FEEDBACK_URL = "https://vkozfz5086v.typeform.com/to/xQSdY7q3";
const HELP_CENTER_URL = "https://intercom.help/bluedot-for-fleets/en/";
const Header = ({ fixed, theme, className, setVisibility, ...props }) => {
  const location = useLocation();

  const routesWhereSustainabilityScoreNotVisible = [
    "/inactive-user",
    "/waiting-user"
  ];

  const isSustainabilityScoreVisible = !(routesWhereSustainabilityScoreNotVisible.find((r) => {
    return location.pathname === r;
  }));


  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,

    [`${className}`]: className
  });


  const handleOpenLinkClicked = useCallback((url) => {
    window.open(url, "_blank");
  }, []);

  return (
    <>
      <div className={headerClass}>
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xl-none ml-n1">
              <Toggle
                className="nk-nav-toggle nk-quick-nav-icon d-xl-none ml-n1"
                icon="menu"
                click={props.sidebarToggle}
              />
            </div>
            <div className="nk-header-brand  mr-2 d-none d-lg-block ">
              <BackButton />
              {/**
               *  <Logo />
               <div className="d-block d-lg-none ">
               <BlueDotTextLogo />
               </div>
               */}
            </div>
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">


                <li className="mr-1" onClick={() => handleOpenLinkClicked(FEEDBACK_URL)}>
                  <HeaderLink text="Give Feedback" />
                </li>
                <li className="mr-1" onClick={() => handleOpenLinkClicked(HELP_CENTER_URL)}>
                  <HeaderLink text="Help Center" />
                </li>
                {isSustainabilityScoreVisible && <li className="mr-4"><SustainabilityScore /></li>}

                <li className="user-dropdown " onClick={() => setVisibility(false)}>
                  <User />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <AppWideTeslaConnectionBadge />
    </>
  );
};
export default Header;
