import React from "react";
import { AppColors } from "../../../utils/colors.utils";

type Props = {};

function ChargingStationIcon({}: Props) {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 16.5L7 12.5H5.5V9.5L3 13.5H4.5V16.5ZM2 7.5H8V2.5H2V7.5ZM9 18.5H1C0.71667 18.5 0.479004 18.404 0.287004 18.212C0.0950036 18.02 -0.000663206 17.7827 3.4602e-06 17.5V2.5C3.4602e-06 1.95 0.196003 1.479 0.588003 1.087C0.980003 0.695002 1.45067 0.499335 2 0.500002H8C8.55 0.500002 9.021 0.696002 9.413 1.088C9.805 1.48 10.0007 1.95067 10 2.5V9.5H11.25C11.7333 9.5 12.146 9.671 12.488 10.013C12.83 10.355 13.0007 10.7673 13 11.25V15.875C13 16.1583 13.1167 16.4167 13.35 16.65C13.5833 16.8833 13.8417 17 14.125 17C14.425 17 14.6877 16.8833 14.913 16.65C15.1383 16.4167 15.2507 16.1583 15.25 15.875V6.5H15C14.7167 6.5 14.479 6.404 14.287 6.212C14.095 6.02 13.9993 5.78267 14 5.5V4C14 3.86667 14.05 3.75 14.15 3.65C14.25 3.55 14.3667 3.5 14.5 3.5V2.5C14.5 2.36667 14.55 2.25 14.65 2.15C14.75 2.05 14.8667 2 15 2C15.1333 2 15.25 2.05 15.35 2.15C15.45 2.25 15.5 2.36667 15.5 2.5V3.5H16.5V2.5C16.5 2.36667 16.55 2.25 16.65 2.15C16.75 2.05 16.8667 2 17 2C17.1333 2 17.25 2.05 17.35 2.15C17.45 2.25 17.5 2.36667 17.5 2.5V3.5C17.6333 3.5 17.75 3.55 17.85 3.65C17.95 3.75 18 3.86667 18 4V5.5C18 5.78334 17.904 6.021 17.712 6.213C17.52 6.405 17.2827 6.50067 17 6.5H16.75V15.875C16.75 16.575 16.4957 17.1877 15.987 17.713C15.4783 18.2383 14.858 18.5007 14.126 18.5C13.4093 18.5 12.7927 18.2373 12.276 17.712C11.7593 17.1867 11.501 16.5743 11.501 15.875V11.25C11.501 11.1667 11.48 11.104 11.438 11.062C11.396 11.02 11.3337 10.9993 11.251 11H10V17.5C10 17.7833 9.904 18.021 9.712 18.213C9.52 18.405 9.28267 18.5007 9 18.5Z"
        fill={AppColors.mint500}
      />
    </svg>
  );
}

export default ChargingStationIcon;
