import React, { useCallback } from "react";
import { useHistory } from "react-router";
import ButtonWithIcon from "./ButtonWithIcon";

type Props = {};

function BackToSignInButton({}: Props) {
  const history = useHistory();
  const handleBackToSignInButtonPressed = useCallback(() => {
    history.push("/auth/login");
  }, [history]);

  return (
    <ButtonWithIcon
      type="button"
      className="w-100"
      text={"Back to sign in"}
      onClick={handleBackToSignInButtonPressed}
    />
  );
}

export default BackToSignInButton;
