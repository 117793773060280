import React from "react";
import TableNoResultPlaceholder from "./TableNoResultPlaceholder";
import TableNoResultPlaceholderImage from "assets/images/placeholder/NoResultPlaceholder.png";

type Props = {};

function PaginatedTableFilterPlaceholder({}: Props) {
  return (
    <TableNoResultPlaceholder
      img={<img width={240} src={TableNoResultPlaceholderImage} alt="No Result placeholder for messages" />}
      title="No results found"
      description="Try different keywords or adjust your filters."
    />
  );
}

export default PaginatedTableFilterPlaceholder;
