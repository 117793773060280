import CustomerSupportTextButton from "components/button/CustomerSupportTextButton";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import InboxPageInfoModalIcon from "components/icon/icons/InboxPageInfoModalIcon";
import CustomModalWithXIcon from "components/modal/CustomModalWithXIcon";
import { BlackBodyText200, BlackH6Text100 } from "components/text/Text";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import UnderlinedTextButton from "../button/UnderlinedTextButton";
import HoverUnderlinedTextButton from "../button/HoverUnderlinedTextButton";

export default function InboxPageLearnMoreModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation("common");
  return (
    <>
      <CustomModalWithXIcon isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <Row className="w-100 h-100">
          <Col md="5" className="bg-violet-100  center p-4">
            <InboxPageInfoModalIcon />
          </Col>
          <Col md="7" className="p-5 h-max-80vh overflow-auto">
            <BlackH6Text100 className={"mb-2"}>Your Fleet Messages, Streamlined</BlackH6Text100>
            <BlackBodyText200 className="mb-2">All your messages from the Bluedot in one place</BlackBodyText200>

            <BlackBodyText200 className="mb-4">
              Welcome to your Alert Inbox, where staying informed about your EV fleet is simplified. Messages are
              personalized and sorted by priority so you don't miss anything important. You can act on messages directly
              from the Inbox. All your old messages are safely stored in Archived messages.
            </BlackBodyText200>

            <div className="pt-4">
              <CustomerSupportTextButton />
            </div>
          </Col>
        </Row>
      </CustomModalWithXIcon>
      <HoverUnderlinedTextButton text={"Learn more"} onClick={() => setIsModalOpen(true)} textClassName={"ml-1"} />
    </>
  );
}
