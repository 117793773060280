import FormButton from "./FormButton";

type Props = {
  readonly onVerifyButtonPressed?: () => void;
  readonly className?: string;
  readonly type?: string;
  readonly isLoading?: boolean;
  readonly isDisabled?: boolean;
};

function VerifyButton({ onVerifyButtonPressed, ...props }: Props) {
  return <FormButton text={"Verify"} onFormButtonPressed={onVerifyButtonPressed} {...props} />;
}

export default VerifyButton;
