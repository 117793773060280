import React from "react";
import { SvgProps } from "../../../types/svg.types";

const FleetGroupsIcon = ({ fill, width, height }: SvgProps) => {
  const fillValue = fill ?? "#680031";
  const widthValue = width ?? 22;
  const heightValue = height ?? 22;

  return (
    <svg
      width={`${widthValue}`}
      height={`${heightValue}`}
      viewBox={`0 0 ${widthValue} ${heightValue} `}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0879 8.9082C12.2451 8.9082 13.3549 8.4485 14.1732 7.63023C14.9915 6.81195 15.4512 5.70214 15.4512 4.54492C15.4512 3.38771 14.9915 2.27789 14.1732 1.45962C13.3549 0.641342 12.2451 0.181641 11.0879 0.181641C9.93068 0.181641 8.82086 0.641342 8.00258 1.45962C7.18431 2.27789 6.72461 3.38771 6.72461 4.54492C6.72461 5.70214 7.18431 6.81195 8.00258 7.63023C8.82086 8.4485 9.93068 8.9082 11.0879 8.9082ZM3.99756 12.1807C4.72082 12.1807 5.41445 11.8934 5.92587 11.3819C6.4373 10.8705 6.72461 10.1769 6.72461 9.45361C6.72461 8.73035 6.4373 8.03672 5.92587 7.5253C5.41445 7.01388 4.72082 6.72656 3.99756 6.72656C3.2743 6.72656 2.58066 7.01388 2.06924 7.5253C1.55782 8.03672 1.27051 8.73035 1.27051 9.45361C1.27051 10.1769 1.55782 10.8705 2.06924 11.3819C2.58066 11.8934 3.2743 12.1807 3.99756 12.1807ZM20.9053 9.45361C20.9053 10.1769 20.618 10.8705 20.1065 11.3819C19.5951 11.8934 18.9015 12.1807 18.1782 12.1807C17.455 12.1807 16.7613 11.8934 16.2499 11.3819C15.7385 10.8705 15.4512 10.1769 15.4512 9.45361C15.4512 8.73035 15.7385 8.03672 16.2499 7.5253C16.7613 7.01388 17.455 6.72656 18.1782 6.72656C18.9015 6.72656 19.5951 7.01388 20.1065 7.5253C20.618 8.03672 20.9053 8.73035 20.9053 9.45361ZM11.0879 9.99902C12.5344 9.99902 13.9217 10.5737 14.9445 11.5965C15.9674 12.6193 16.542 14.0066 16.542 15.4531V21.998H5.63379V15.4531C5.63379 14.0066 6.20842 12.6193 7.23126 11.5965C8.2541 10.5737 9.64137 9.99902 11.0879 9.99902ZM3.45215 15.4531C3.45215 14.6972 3.56123 13.9674 3.7663 13.278L3.58087 13.2933C2.6459 13.396 1.78174 13.8401 1.15408 14.5407C0.526417 15.2412 0.179443 16.1488 0.179688 17.0894V21.998H3.45215V15.4531ZM21.9961 21.998V17.0894C21.9962 16.1167 21.6251 15.1808 20.9586 14.4725C20.292 13.7642 19.3803 13.3369 18.4095 13.278C18.6135 13.9674 18.7236 14.6972 18.7236 15.4531V21.998H21.9961Z"
        fill={fillValue}
      />
    </svg>
  );
};
export default FleetGroupsIcon;

/**
 *
 */
