import { useTranslation } from "react-i18next";
import AddVehicleSmartCarFormStarter
  from "../../components/vehicle-components/form/add-vehicle/AddVehicleSmartCarFlowStarter";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import PageHeader from "../../components/header/PageHeader";

type Props = {};

function AddVehicleSmartcarPage(props: Props) {
  const { t } = useTranslation("common");

  return (
    <>
      <Head title={t("vehicle.title.add_vehicles_page")}></Head>
      <Content>
        <PageHeader title="Add Vehicle" description={"Connect EVs with Smartcar"} />

        <AddVehicleSmartCarFormStarter />
      </Content>
    </>
  );
}

export default AddVehicleSmartcarPage;
