import Row from "components/custom/Row";
import MessageIcon from "components/icon/icons/MessageIcon";
import TickIcon from "components/icon/icons/TickIcon";
import { BlackBody2Text0, BlackSubtitleText100 } from "components/text/Text";

type Props = {};

function DriverSelfSignupSteps({}: Props) {
  return (
    <Row className="mt-2 flex-nowrap h-min-200px">
      <div className="w-min-45px position-relative flex flex-column justify-between">
        <div className="position-relative ">
          <div className="driver-self-signup-step-circle">
            <BlackSubtitleText100>1</BlackSubtitleText100>
          </div>
          <div className="driver-self-signup-vertical-dot-line" />
        </div>

        <div className="position-relative">
          <div className="driver-self-signup-step-circle position-relative">
            <BlackSubtitleText100>2</BlackSubtitleText100>
            <div className="driver-self-signup-step-message-circle">
              <MessageIcon width={11} height={11} fill="white" />
            </div>
          </div>
          <div className="driver-self-signup-vertical-dot-line" />
        </div>

        <div className="center position-relative">
          <div className="driver-self-signup-step-tick-circle">
            <TickIcon />
          </div>
        </div>
      </div>

      <div className="d-flex flex-1 flex-column justify-between">
        <BlackBody2Text0 className="w-100 w-max-350px ml-2">
          <span className="font-weight-bold">Copy and share</span> your fleet code along with the driver app link to
          enable self-signup.
        </BlackBody2Text0>

        <BlackBody2Text0 className="w-100 w-max-350px ml-2 mt-3">
          When a driver signs up using your fleet code, you'll be <span className="font-weight-bold">notified</span> to
          review their application.
        </BlackBody2Text0>

        <BlackBody2Text0 className="w-100 w-max-350px ml-2 mt-3">
          <span className="font-weight-bold">Examine</span> the details of new drivers and{" "}
          <span className="font-weight-bold">approve</span> them to grant access to the driver app.
        </BlackBody2Text0>
      </div>
    </Row>
  );
}

export default DriverSelfSignupSteps;
