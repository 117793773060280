import React from "react";

type Props = {};

export default function DoughnutPlaceholder({}: Props) {
  return (
    <svg width="191" height="140" viewBox="0 0 191 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2820_23330)">
        <path
          d="M93.2662 44.0547C89.1471 44.3311 85.153 45.5843 81.6145 47.7108C78.0759 49.8372 75.0946 52.7758 72.9172 56.2832C70.7397 59.7906 69.4288 63.7662 69.0929 67.8808C68.757 71.9955 69.4058 76.131 70.9854 79.9452C72.5651 83.7593 75.0304 87.1425 78.1772 89.8147C81.324 92.487 85.0619 94.3714 89.0817 95.3122C93.1014 96.253 97.2874 96.2231 101.293 95.2249C105.299 94.2267 109.01 92.289 112.118 89.572L93.7862 71.2368C93.6216 71.0755 93.4908 70.8829 93.4015 70.6704C93.3122 70.4579 93.2662 70.2297 93.2662 69.9992V44.0547Z"
          fill="#6F7981"
        />
        <path
          d="M114.569 87.1176C118.723 82.3834 121.009 76.2975 121 69.9992C121.005 66.5474 120.32 63.1294 118.986 59.9459L97.9258 70.4742L114.569 87.1176ZM117.429 56.8467C115.285 53.1881 112.278 50.1098 108.67 47.8801C105.063 45.6504 100.965 44.3371 96.7333 44.0547V67.1947L117.429 56.8467Z"
          fill="#6F7981"
        />
      </g>
      <defs>
        <clipPath id="clip0_2820_23330">
          <rect width="52" height="52" fill="white" transform="translate(69 44)" />
        </clipPath>
      </defs>
    </svg>
  );
}
