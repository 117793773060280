import React from "react";
import driverFormPaymentResponsibilityImage from "../../assets/images/driverFormPaymentResponsibilityImage.svg";
import { BlockDesLight, BlockFormLabel, BlockText, BlockTitleSecondary, BlockTitleTertiary } from "../block/Block";
import Col from "../custom/Col";
import Row from "../custom/Row";
import DriversIcon from "../icon/icons/DriversIcon";
import { CustomerServiceLink } from "../links/Links";

export default function DriverFormResponsibilityModalContent() {
  return (
    <Row className="w-100 h-100 ">
      <Col md="5" className="add-driver-form-responsibility-modal-image-container center ">
        <img src={driverFormPaymentResponsibilityImage} alt="DriverFormPaymentResponsibilityImage" />
      </Col>
      <Col md="7" className="p-5 h-max-80vh overflow-auto">
        <BlockTitleSecondary className={"mb-5"}>Understanding Payment Responsibility</BlockTitleSecondary>
        <BlockDesLight>
          When setting up a new driver, you have the flexibility to decide who will cover the costs of EV charging. You
          can set this payment responsibility to either the driver or the organization.
        </BlockDesLight>

        <Row className={"my-4 align-items-center"}>
          <BlockTitleTertiary className={" mb-0 "}>Organization Pays</BlockTitleTertiary>
        </Row>

        <BlockDesLight>
          If you choose this option, your organization will be covering the costs of EV charging. Your organization will
          be billed for the driver's charging sessions at the end of the month or as per your chosen billing cycle. This
          might be preferable for company-owned vehicles or if the organization wants to promote EV use among its
          drivers.
        </BlockDesLight>

        <Row className={"my-4 align-items-center"}>
          <BlockTitleTertiary className={" mb-0 "}>Driver Pays</BlockTitleTertiary>
        </Row>
        <BlockDesLight>
          In this scenario, the driver is personally responsible for all their EV charging costs. This means the driver
          will need to pay for their charging sessions. To facilitate this, drivers will need to add their preferred
          payment method to their account using the driver app.
        </BlockDesLight>

        <CustomerServiceLink />
      </Col>
    </Row>
  );
}
