import { useTranslation } from "react-i18next";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import Card from "components/card/Card";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import DriverApprovalPageSectionBox from "components/driver-components/DriverApprovalPageSectionBox";
import DriverSelfSignupApprovalForm from "components/driver-components/form/DriverSelfSignupApprovalForm";
import SelfSignupDriverIcon from "components/icon/icons/SelfSignupDriverIcon";
import CircularLoadingSpinner from "components/loading/CircularLoadingSpinner";
import {
  BlackBodyText100,
  BlackBodyText200,
  BlackH6Text100,
  BlackOverlineText200,
  BodyText,
  PositiveLabelMediumText200
} from "components/text/Text";
import { useDriverSignupAsync } from "hooks/data/driver-data-accessor.hooks";
import React, { useCallback, useEffect, useState } from "react";
import { getLocalFormattedDateForTables } from "utils/date.utils";
import { getDriverFullAddress, getDriverSelfSignupFormStepStatus } from "utils/driver.utils";
import { getFormattedPhoneNumber, getFullNameByFirstNameAndLastName } from "utils/format.utils";
import DriverSelfSignupStepsHeader from "../../components/driver-components/DriverSelfSignupStepsHeader";
import { DriverSelfSignupStep, DriverSelfSignupUpdateCommand } from "../../types/driver.types";
import AlertConfirmDialog from "../../components/notification/AlertConfirmDialog";
import CancelButton from "../../components/button/CancelButton";
import PrimaryButton from "../../components/button/PrimaryButton";
import { updateDriverSignupAsync } from "../../services/driver.services";
import { handleApiErrorResponse } from "../../utils";
import { useIntercomTrigger } from "../../hooks/intercom.hooks";
import { useHistory, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useAlertManager } from "../../hooks/alert.hooks";
import PrimaryNegativeButton from "../../components/button/PrimaryNegativeButton";
import { CustomerServiceLink } from "../../components/links/Links";
import DoubleCheckedIcon from "../../components/icon/icons/DoubleCheckedIcon";

export default function DriverSelfSignupApprovalPage() {
  const [isInfoBadgeOpen, setIsInfoBadgeOpen] = useState(true);
  const { t } = useTranslation("common");


  const { triggerIntercom } = useIntercomTrigger();
  const [isDeclineDialogOpen, setIsDeclineDialogOpen] = useState(false);
  const [forwardingLocationAfterConfirm, setForwardingLocationAfterConfirm] = useState("");
  const [isNavigationAllowing, setIsNavigationAllowing] = useState(false);
  const [isExitFormWarningModalOpen, setIsExitFormWarningModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const [step, setStep] = useState(DriverSelfSignupStep.CHARGING);
  const { isChargingSetupStep, isReimbursementStep, isVehicleSetupStep } = getDriverSelfSignupFormStepStatus(step);

  const { driverSignup, isLoading } = useDriverSignupAsync();

  const driverEmail = driverSignup?.email;
  const driverFullName = getFullNameByFirstNameAndLastName(driverSignup?.firstName, driverSignup?.lastName);
  const driverPhoneNumber = getFormattedPhoneNumber(driverSignup?.phoneNumber);


  const history = useHistory();
  const dispatch = useDispatch();
  const { handleOpenErrorAlert } = useAlertManager();
  const { driverId } = useParams<{ driverId: string }>();


  /**
   *
   */
  useEffect(() => {
    if (isNavigationAllowing && forwardingLocationAfterConfirm) {
      history.goBack();
    }
  }, [forwardingLocationAfterConfirm, history, isNavigationAllowing]);


  /**
   *
   */
  const handleSubmitDeclineAsync = useCallback(async () => {
    try {
      setIsNavigationAllowing(true);
      setIsSubmitting(true);
      await updateDriverSignupAsync(driverId, DriverSelfSignupUpdateCommand.REJECT);

      history.replace("/drivers");
    } catch (error) {
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  }, [driverId, handleOpenErrorAlert, history]);


  /**
   *
   */
  const handleDialogConfirmed = useCallback(() => {
    if (forwardingLocationAfterConfirm) {
      setIsNavigationAllowing(true);
    }
  }, [forwardingLocationAfterConfirm]);

  return (
    <>
      <AlertConfirmDialog
        isDialogOpen={isExitFormWarningModalOpen}
        title="Unsaved Changes"
        description="You have unsaved changes. If you leave, you'll lose these changes."
        onDialogConfirmed={handleDialogConfirmed}
        toggleIsDialogOpen={() => setIsExitFormWarningModalOpen(false)}
      />

      <AlertConfirmDialog
        title={`Are you sure you want to decline this driver's signup request?`}
        descriptionOverride={
          <>
            <BlackBodyText100>
              This action will permanently remove their request from the platform, and you will no longer have access to
              their details. The driver will need to reapply for future consideration. Please note, this action cannot
              be undone.
            </BlackBodyText100>
            <BlackBodyText200>
              If you wish to provide specific feedback or need assistance, please contact{" "}
              <span className="text-decoration-underline pointer-events-all clickable" onClick={triggerIntercom}>
                customer support
              </span>
              .
            </BlackBodyText200>
          </>
        }
        isDialogOpen={isDeclineDialogOpen}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setIsDeclineDialogOpen(false)} />
            </div>

            <PrimaryButton
              type="button"
              text="Confirm decline"
              isLoading={isSubmitting}
              onClick={handleSubmitDeclineAsync}
            />
          </>
        }
      />
      <Head title={t("driver.main_page.title")}></Head>
      <Content>
        {isLoading ? (
          <CircularLoadingSpinner />
        ) : (
          <Row className="pb-4 mb-4 mt-2">
            <Col md="4" className="pr-4">
              <Card className={"mb-4"}>
                <div>
                  <SelfSignupDriverIcon width={48} height={48} />
                </div>

                <BlackH6Text100 className="mt-4 mb-2">{driverFullName}</BlackH6Text100>
                <DriverApprovalPageSectionBox label="Email" value={driverEmail} />
                <DriverApprovalPageSectionBox label="Phone number" value={driverPhoneNumber} />
                <DriverApprovalPageSectionBox isTruncateAllowed={false} label="Address"
                                              value={getDriverFullAddress(driverSignup?.address)} />
                <DriverApprovalPageSectionBox
                  label="Signup date"
                  value={getLocalFormattedDateForTables(driverSignup?.createdAt)}
                />


                {isChargingSetupStep ? <PrimaryNegativeButton
                    className={"w-150px"}
                    text={"Decline"}
                    onClick={() => setIsDeclineDialogOpen(true)} /> :
                  <Row className="align-items-center">
                    <DoubleCheckedIcon />
                    <PositiveLabelMediumText200 className={"ml-1"}> Approved</PositiveLabelMediumText200>
                  </Row>
                }

              </Card>


              {isChargingSetupStep && <Card className="my-4">
                <BodyText className="link-text">What’s Next:</BodyText>
                <BlackOverlineText200>
                  After your approval, we will notify the driver. They can then access the driver app based on your
                  configured settings.
                </BlackOverlineText200>
              </Card>}


              <CustomerServiceLink />

            </Col>
            <Col md="8" className="pl-4 pb-4 ">
              <DriverSelfSignupStepsHeader step={step} />
              <DriverSelfSignupApprovalForm step={step}
                                            setStep={setStep}
                                            setForwardingLocationAfterConfirm={setForwardingLocationAfterConfirm}
                                            setIsExitFormWarningModalOpen={setIsExitFormWarningModalOpen}
                                            isNavigationAllowing={isNavigationAllowing}
                                            setIsNavigationAllowing={setIsNavigationAllowing}
                                            driverFullName={driverFullName} />
            </Col>
          </Row>
        )}
      </Content>
    </>
  );
}


{/*
  <InfoBadge
                    onClose={() => setIsInfoBadgeOpen(false)}
                    textOverride={
                      <>
                        <BlackBody2Text100>
                          To ensure smooth onboarding, certain fields are essential when adding a new driver. Understand
                          the process and required fields before approval.{" "}
                          <DriverSelfSignupApprovalPageLearnMoreModal />
                        </BlackBody2Text100>
                      </>
                    }
                  />*/
}
