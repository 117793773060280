import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";
import { FleetGroup } from "types/fleet-group.types";
import { ApiResponse } from "types/api.types";

const module = "groups";

/**
 * GET: '/api/groups'
 */
export async function getFleetGroupsAsync(): Promise<ApiResponse<FleetGroup[]>> {
  const endpoint = generateApiEndpoint(module);
  const response = await restApiClient.get(endpoint);
  return response.data;
}

/**
 * GET: '/api/groups/${groupId}'
 */
export async function getFleetGroupAsync(groupId: string): Promise<ApiResponse<FleetGroup>> {
  const endpoint = generateApiEndpoint(`${module}/${groupId}`);
  const response = await restApiClient.get(endpoint);
  return response.data;
}

/**
 * PUT: '/api/groups/${groupId}'
 */
export async function updateFleetGroupAsync(groupId: string, payload: FleetGroup): Promise<ApiResponse<{}>> {
  const endpoint = generateApiEndpoint(`${module}/${groupId}`);
  const response = await restApiClient.put(endpoint, payload);
  return response.data;
}

/**
 * DELETE: '/api/groups/${groupId}'
 */
export async function deleteFleetGroupAsync(groupId: string): Promise<ApiResponse<{}>> {
  const endpoint = generateApiEndpoint(`${module}/${groupId}`);
  const response = await restApiClient.delete(endpoint);
  return response.data;
}

/**
 * POST: '/api/groups'
 */
export async function addFleetGroupAsync(payload: FleetGroup): Promise<ApiResponse<{}>> {
  const endpoint = generateApiEndpoint(module);
  const response = await restApiClient.post(endpoint, payload);
  return response.data;
}
