import { useAppDispatch, useAppSelector } from "hooks/redux.hooks";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router";
import { setInboxMessagesAndMeta, setIsInboxMessagesLoading } from "redux/slices/inboxSlice";
import { getInboxMessagesAsync } from "services/inbox.services";

export const useInboxMessagesAsync = () => {
    const dispatch = useAppDispatch()
    const { search } = useLocation();

    const getInboxMessagesAsyncCallback = useCallback(
        async (search?: string) => {
            try {
                dispatch(setIsInboxMessagesLoading(true))
                const res = await getInboxMessagesAsync(search)
                dispatch(setInboxMessagesAndMeta({ messages: res.data, meta: res.meta }))
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(setIsInboxMessagesLoading(false))
            }
        },
        [dispatch],
    )


    useEffect(() => {
        getInboxMessagesAsyncCallback(search);
    }, [getInboxMessagesAsyncCallback, search]);


    return { ...useAppSelector(s => s.inbox), refetch: getInboxMessagesAsyncCallback }
}
