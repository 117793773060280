import DashboardTablePlaceholderIcon from "../../icon/icons/DashboardTablePlaceholder";
import { BlackBodyText300, NeutralTitleMediumText100 } from "../../text/Text";

export default function NoDataPlaceholder({
                                            button,
                                            description = "No data to display",
                                            height = 400,
                                            title,
                                            spacing = "my-3",
                                            icon
                                          }: {
  icon?: JSX.Element;
  button?: JSX.Element;
  title?: string;
  description?: string;
  spacing?: string;
  height?: number
}) {
  return (
    <div className={`center h-100 flex-column h-min-${height}px rounded-xxl pt-2`}>
      {icon ?? <DashboardTablePlaceholderIcon />}
      {title && <NeutralTitleMediumText100>{title}</NeutralTitleMediumText100>}
      <BlackBodyText300 className={spacing}>{description}</BlackBodyText300>
      {button && <div className="my-2">{button}</div>}
    </div>
  )
    ;
}
