import React from "react";

type Props = {};

function InboxPageInfoModalIcon({}: Props) {
  return (
    <svg width="418" height="294" viewBox="0 0 418 294" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_23762_82450)">
        <path
          d="M340.637 194.614C341.104 194.007 341.433 193.305 341.6 192.557C341.767 191.809 341.768 191.034 341.602 190.286C341.437 189.538 341.11 188.835 340.644 188.227C340.178 187.619 339.584 187.12 338.904 186.767L339.94 174.834L332.841 172.437L331.615 189.307C331.097 190.489 331.03 191.819 331.427 193.046C331.823 194.273 332.657 195.312 333.768 195.966C334.88 196.621 336.193 196.844 337.459 196.595C338.724 196.346 339.854 195.641 340.635 194.615L340.637 194.614Z"
          fill="#FFB8B8"
        />
        <path d="M376.054 287.384H369.667L366.628 262.735H376.059L376.054 287.384Z" fill="#FFB8B8" />
        <path
          d="M365.102 285.558H377.425V293.317H357.343C357.343 291.259 358.16 289.285 359.615 287.83C361.07 286.375 363.044 285.558 365.102 285.558Z"
          fill="#2F2E41"
        />
        <path d="M361.552 271.027L355.348 272.552L346.514 249.344L355.672 247.093L361.552 271.027Z" fill="#FFB8B8" />
        <path
          d="M350.567 271.649L362.533 268.703L364.388 276.237L344.888 281.037C344.396 279.039 344.718 276.927 345.783 275.166C346.848 273.406 348.569 272.14 350.567 271.649Z"
          fill="#2F2E41"
        />
        <path
          d="M368.577 279.524C367.993 279.525 367.43 279.31 366.996 278.92C366.562 278.529 366.289 277.992 366.229 277.411L358.878 208.914L352.48 229.861L361.542 262.577C361.698 263.138 361.641 263.736 361.383 264.258C361.125 264.779 360.683 265.187 360.142 265.403L352.937 268.285C352.641 268.403 352.323 268.46 352.004 268.453C351.684 268.445 351.37 268.373 351.079 268.241C350.788 268.108 350.528 267.918 350.312 267.682C350.097 267.446 349.932 267.169 349.827 266.867L338.721 235.003C337.506 231.524 336.968 227.844 337.137 224.163L338.687 189.775L379.513 191.504L378.876 276.583C378.868 277.175 378.64 277.743 378.236 278.176C377.831 278.608 377.28 278.875 376.689 278.922L368.761 279.52C368.699 279.522 368.638 279.524 368.577 279.524Z"
          fill="#2F2E41"
        />
        <path
          d="M361.816 113.672C369.398 113.672 375.544 107.526 375.544 99.9438C375.544 92.3621 369.398 86.2158 361.816 86.2158C354.234 86.2158 348.088 92.3621 348.088 99.9438C348.088 107.526 354.234 113.672 361.816 113.672Z"
          fill="#FFB8B8"
        />
        <path
          d="M371.912 193.742C368.297 193.742 363.807 193.446 359.159 193.14C352.809 192.722 346.244 192.29 341.343 192.574C340.681 192.64 340.013 192.535 339.403 192.269C338.794 192.004 338.262 191.586 337.859 191.057C332.807 184.588 340.598 159.361 340.934 158.291L347.274 130.082C348.671 123.878 353.127 119.301 358.907 118.139C364.318 117.052 369.551 119.218 372.907 123.938C373.086 124.188 373.263 124.445 373.441 124.709C384.369 140.894 378.188 181.105 377.774 183.698C378.351 184.389 381.473 188.299 380.615 191.015C380.266 192.12 379.318 192.873 377.797 193.251C375.859 193.635 373.885 193.799 371.91 193.74L371.912 193.742Z"
          fill="#6E86FF"
        />
        <path
          d="M341.564 193.754C340.32 193.754 338.643 193.295 336.382 192.356C335.41 191.952 332.452 190.722 336.043 159.339C337.807 143.924 340.334 128.615 340.359 128.463L340.383 128.315L340.519 128.254C340.584 128.224 347.038 125.266 348.16 121.465C348.197 121.333 348.241 121.206 348.291 121.082C348.6 120.237 349.223 119.544 350.03 119.147C350.838 118.751 351.767 118.681 352.625 118.954C353.531 119.17 354.32 119.723 354.833 120.5C355.346 121.278 355.544 122.221 355.387 123.139C354.996 125.618 354.245 128.027 353.156 130.289C350.12 136.555 348.062 153.165 348.042 153.332C347.926 154.751 345.17 188.362 344.639 190.912C344.454 191.803 344.113 192.893 343.112 193.422C342.632 193.659 342.1 193.774 341.565 193.757L341.564 193.754Z"
          fill="#2F2E41"
        />
        <path
          d="M356.56 192.466C356.31 192.466 356.066 192.382 355.868 192.228C351.829 189.159 358.365 151.956 362.24 139.12C363.016 136.549 363.707 134.349 364.294 132.577C365.365 129.399 366.112 126.121 366.524 122.793C366.737 120.989 367.201 119.96 367.942 119.644C368.457 119.424 369.06 119.574 369.742 120.087C373.323 119.911 376.802 121.373 380.026 122.983L382.928 124.431L386.185 182.084C386.092 185.764 378.84 188.361 375.677 188.527C370.596 188.794 361.474 189.621 357.455 192.165C357.192 192.349 356.882 192.453 356.561 192.465L356.56 192.466Z"
          fill="#2F2E41"
        />
        <path
          d="M338.42 181.648C338.372 181.648 338.324 181.648 338.275 181.643L331.698 181.238C331.37 181.218 331.051 181.13 330.759 180.98C330.467 180.83 330.21 180.62 330.004 180.365C329.798 180.11 329.647 179.815 329.561 179.498C329.475 179.181 329.456 178.85 329.505 178.526L332.481 158.807L338.084 131.14C338.222 130.458 338.502 129.813 338.905 129.247C339.308 128.68 339.826 128.205 340.425 127.852C341.024 127.498 341.69 127.274 342.381 127.195C343.072 127.115 343.771 127.182 344.435 127.39C345.569 127.752 346.536 128.507 347.162 129.52C347.788 130.532 348.032 131.735 347.849 132.912L343.397 160.635L340.762 179.609C340.681 180.173 340.4 180.69 339.97 181.064C339.54 181.438 338.99 181.645 338.42 181.648Z"
          fill="#2F2E41"
        />
        <path
          d="M360.891 160.064C361.292 159.411 361.831 158.854 362.471 158.432C363.11 158.011 363.835 157.734 364.593 157.623C365.351 157.511 366.124 157.567 366.858 157.787C367.592 158.006 368.269 158.384 368.841 158.894L379.626 153.685L384.39 159.466L369.057 166.609C368.137 167.513 366.918 168.049 365.629 168.114C364.341 168.18 363.073 167.77 362.067 166.963C361.06 166.157 360.385 165.009 360.168 163.737C359.951 162.465 360.208 161.158 360.891 160.064Z"
          fill="#FFB8B8"
        />
        <path
          d="M375.872 165.659C375.341 165.659 374.826 165.48 374.408 165.151C373.991 164.823 373.697 164.363 373.573 163.847L372.008 157.336C371.888 156.846 371.929 156.331 372.124 155.866C372.319 155.401 372.659 155.011 373.092 154.754L382.538 149.1L377.709 131.418C377.291 129.904 377.483 128.287 378.242 126.912C379.002 125.537 380.27 124.515 381.775 124.063C383.279 123.612 384.9 123.767 386.292 124.496C387.683 125.225 388.734 126.47 389.219 127.964L394.551 144.243C395.206 146.227 395.211 148.369 394.565 150.356C393.92 152.344 392.658 154.073 390.962 155.294L377.257 165.21C376.854 165.502 376.369 165.659 375.872 165.659Z"
          fill="#2F2E41"
        />
        <path
          d="M273.81 0H9.32499C6.85327 0.00295837 4.48364 0.986158 2.73587 2.73393C0.988103 4.4817 0.0049115 6.85132 0.00195312 9.32304V37.784C0.0049115 40.2557 0.988103 42.6254 2.73587 44.3731C4.48364 46.1209 6.85327 47.1041 9.32499 47.1071H273.81C276.282 47.1041 278.651 46.1209 280.399 44.3731C282.147 42.6254 283.13 40.2557 283.133 37.784V9.32304C283.13 6.85132 282.147 4.4817 280.399 2.73393C278.651 0.986158 276.282 0.00295837 273.81 0ZM282.151 37.784C282.148 39.9953 281.268 42.1152 279.705 43.6788C278.141 45.2424 276.021 46.1223 273.81 46.1256H9.32499C7.11367 46.1223 4.99386 45.2424 3.43022 43.6788C1.86658 42.1152 0.986694 39.9953 0.983442 37.784V9.32304C0.986694 7.11172 1.86658 4.9919 3.43022 3.42826C4.99386 1.86462 7.11367 0.984741 9.32499 0.981489H273.81C276.021 0.984741 278.141 1.86462 279.705 3.42826C281.268 4.9919 282.148 7.11172 282.151 9.32304V37.784Z"
          fill="#1E44FF"
        />
        <path
          d="M273.81 114.333H9.32499C6.85327 114.33 4.48364 113.347 2.73587 111.599C0.988103 109.851 0.0049115 107.482 0.00195312 105.01V76.5496C0.0049115 74.0779 0.988103 71.7083 2.73587 69.9605C4.48364 68.2127 6.85327 67.2295 9.32499 67.2266H273.81C276.282 67.2295 278.651 68.2127 280.399 69.9605C282.147 71.7083 283.13 74.0779 283.133 76.5496V105.01C283.13 107.482 282.147 109.851 280.399 111.599C278.651 113.347 276.282 114.33 273.81 114.333ZM9.32499 68.2081C7.11345 68.2106 4.99321 69.0902 3.42941 70.654C1.86561 72.2178 0.985957 74.3381 0.983442 76.5496V105.01C0.985513 107.222 1.86496 109.343 3.42881 110.907C4.99266 112.471 7.11316 113.351 9.32499 113.353H273.81C276.022 113.351 278.142 112.471 279.706 110.908C281.27 109.344 282.149 107.223 282.152 105.012V76.5496C282.149 74.3381 281.27 72.2178 279.706 70.654C278.142 69.0902 276.022 68.2106 273.81 68.2081H9.32499Z"
          fill="#CCCCCC"
        />
        <path
          d="M273.81 181.557H9.32499C6.85327 181.554 4.48364 180.571 2.73587 178.823C0.988103 177.075 0.0049115 174.705 0.00195312 172.234V143.773C0.0049115 141.302 0.988103 138.932 2.73587 137.184C4.48364 135.436 6.85327 134.453 9.32499 134.45H273.81C276.282 134.453 278.651 135.436 280.399 137.184C282.147 138.932 283.13 141.302 283.133 143.773V172.234C283.13 174.705 282.147 177.075 280.399 178.823C278.651 180.571 276.282 181.554 273.81 181.557ZM9.32499 135.429C7.11345 135.432 4.99321 136.312 3.42941 137.875C1.86561 139.439 0.985957 141.559 0.983442 143.771V172.231C0.985809 174.443 1.86539 176.563 3.42921 178.127C4.99303 179.691 7.11335 180.571 9.32499 180.574H273.81C276.022 180.571 278.142 179.692 279.706 178.128C281.27 176.564 282.149 174.444 282.152 172.232V143.773C282.15 141.561 281.271 139.44 279.707 137.876C278.143 136.312 276.022 135.432 273.81 135.429H9.32499Z"
          fill="#CCCCCC"
        />
        <path
          d="M249.851 36.8016H76.6366C75.5955 36.8016 74.597 36.388 73.8608 35.6517C73.1245 34.9155 72.7109 33.917 72.7109 32.8759C72.7109 31.8347 73.1245 30.8362 73.8608 30.1C74.597 29.3638 75.5955 28.9502 76.6366 28.9502H249.851C250.892 28.9502 251.89 29.3638 252.627 30.1C253.363 30.8362 253.776 31.8347 253.776 32.8759C253.776 33.917 253.363 34.9155 252.627 35.6517C251.89 36.388 250.892 36.8016 249.851 36.8016Z"
          fill="#6E86FF"
        />
        <path
          d="M193.418 20.1189H76.6327C75.5916 20.1189 74.593 19.7053 73.8568 18.9691C73.1206 18.2329 72.707 17.2344 72.707 16.1933C72.707 15.1521 73.1206 14.1536 73.8568 13.4174C74.593 12.6812 75.5916 12.2676 76.6327 12.2676H193.418C194.459 12.2676 195.457 12.6812 196.194 13.4174C196.93 14.1536 197.343 15.1521 197.343 16.1933C197.343 17.2344 196.93 18.2329 196.194 18.9691C195.457 19.7053 194.459 20.1189 193.418 20.1189Z"
          fill="#6E86FF"
        />
        <path
          d="M249.851 104.028H76.6366C75.5955 104.028 74.597 103.615 73.8608 102.878C73.1245 102.142 72.7109 101.144 72.7109 100.102C72.7109 99.0613 73.1245 98.0628 73.8608 97.3266C74.597 96.5904 75.5955 96.1768 76.6366 96.1768H249.851C250.892 96.1768 251.89 96.5904 252.627 97.3266C253.363 98.0628 253.776 99.0613 253.776 100.102C253.776 101.144 253.363 102.142 252.627 102.878C251.89 103.615 250.892 104.028 249.851 104.028Z"
          fill="#CBD5E1"
        />
        <path
          d="M193.418 87.3436H76.6327C75.5916 87.3436 74.593 86.93 73.8568 86.1937C73.1206 85.4575 72.707 84.459 72.707 83.4179C72.707 82.3767 73.1206 81.3782 73.8568 80.642C74.593 79.9058 75.5916 79.4922 76.6327 79.4922H193.418C194.459 79.4922 195.457 79.9058 196.194 80.642C196.93 81.3782 197.343 82.3767 197.343 83.4179C197.343 84.459 196.93 85.4575 196.194 86.1937C195.457 86.93 194.459 87.3436 193.418 87.3436Z"
          fill="#CBD5E1"
        />
        <path
          d="M249.851 171.254H76.6366C75.5955 171.254 74.597 170.84 73.8608 170.104C73.1245 169.368 72.7109 168.369 72.7109 167.328C72.7109 166.287 73.1245 165.288 73.8608 164.552C74.597 163.816 75.5955 163.402 76.6366 163.402H249.851C250.892 163.402 251.89 163.816 252.627 164.552C253.363 165.288 253.776 166.287 253.776 167.328C253.776 168.369 253.363 169.368 252.627 170.104C251.89 170.84 250.892 171.254 249.851 171.254Z"
          fill="#CBD5E1"
        />
        <path
          d="M193.418 154.569H76.6327C75.5916 154.569 74.593 154.156 73.8568 153.419C73.1206 152.683 72.707 151.685 72.707 150.643C72.707 149.602 73.1206 148.604 73.8568 147.868C74.593 147.131 75.5916 146.718 76.6327 146.718H193.418C194.459 146.718 195.457 147.131 196.194 147.868C196.93 148.604 197.343 149.602 197.343 150.643C197.343 151.685 196.93 152.683 196.194 153.419C195.457 154.156 194.459 154.569 193.418 154.569Z"
          fill="#CBD5E1"
        />
        <path
          d="M417.314 294.001H306.645C306.497 294.001 306.354 293.942 306.25 293.837C306.145 293.732 306.086 293.59 306.086 293.442C306.086 293.294 306.145 293.151 306.25 293.047C306.354 292.942 306.497 292.883 306.645 292.883H417.314C417.462 292.883 417.604 292.942 417.709 293.047C417.814 293.151 417.873 293.294 417.873 293.442C417.873 293.59 417.814 293.732 417.709 293.837C417.604 293.942 417.462 294.001 417.314 294.001Z"
          fill="#CCCCCC"
        />
        <path
          d="M366.863 103.605C366.863 103.605 365.193 94.3681 361.391 95.4718C357.59 96.5754 347.806 97.7046 347.788 93.3544C347.77 89.0042 356.997 85.1592 362.437 85.6796C367.877 86.2 377.128 87.7921 376.618 95.9508C376.211 101.089 374.623 106.064 371.978 110.489L366.863 103.605Z"
          fill="#2F2E41"
        />
        <rect x="21" y="9.09961" width="28.8" height="28.8" rx="6" fill="#1E44FF" />
        <path
          d="M44.6112 17.167L31.9456 29.8326L26.1885 24.0755"
          stroke="white"
          stroke-width="2.30284"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect x="21.5" y="77.5996" width="27.8" height="27.8" rx="5.5" stroke="#CBD5E1" />
        <rect x="21.5" y="144.6" width="27.8" height="27.8" rx="5.5" stroke="#CBD5E1" />
      </g>
      <defs>
        <clipPath id="clip0_23762_82450">
          <rect width="417.871" height="294" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default InboxPageInfoModalIcon;
