import React from "react";

type Props = {};

function InboxMenuIconOutlined({}: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3834 5.4119C16.4928 5.4119 16.5986 5.44365 16.6939 5.50009C16.7856 5.55654 16.8632 5.64121 16.9126 5.73999L20.4264 12.7675C20.5357 12.9862 20.5957 13.2332 20.5957 13.4766V18.0028C20.5957 18.158 20.5322 18.3097 20.4228 18.4191C20.3099 18.532 20.1618 18.592 20.0066 18.592H4.00775C3.84899 18.592 3.7008 18.532 3.59144 18.4191C3.48208 18.3097 3.41858 18.158 3.41858 18.0028V13.4766C3.41858 13.2296 3.47504 12.9862 3.58793 12.7675L7.10166 5.74352C7.15105 5.64474 7.22513 5.5636 7.31685 5.50715C7.40858 5.45071 7.51796 5.41895 7.6238 5.41895H16.387M16.387 4.00781H7.6238C7.25337 4.00781 6.88999 4.11364 6.57248 4.30768C6.2585 4.50171 6.00098 4.78394 5.83517 5.11556L2.32142 12.1395C2.11328 12.5558 2.00391 13.0144 2.00391 13.4801V18.0099C2.00391 18.539 2.21559 19.0471 2.58954 19.4245C2.96349 19.7985 3.47504 20.0102 4.00422 20.0102H20.003C20.5322 20.0102 21.0437 19.7985 21.4177 19.4245C21.7917 19.0506 22.0033 18.539 22.0033 18.0099V13.4836C22.0033 13.018 21.894 12.5558 21.6858 12.1395L18.1721 5.11556C18.0063 4.78394 17.7523 4.50171 17.4348 4.30768C17.1173 4.11364 16.7539 4.00781 16.3834 4.00781H16.387Z"
        fill="#6F7981"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.70876 15.2977V14.504C9.70876 13.9148 9.47945 13.3609 9.06316 12.9447C8.65393 12.5354 8.08595 12.2991 7.50386 12.2991H5.52827C5.45418 12.2991 5.41184 12.2567 5.39068 12.2214C5.36951 12.1862 5.34833 12.1297 5.38361 12.0627L8.05773 6.71094H15.9495L18.6237 12.0627C18.6589 12.1297 18.6378 12.1862 18.6166 12.2214C18.5954 12.2567 18.5531 12.2991 18.479 12.2991H16.5034C15.9143 12.2991 15.3604 12.5284 14.9441 12.9447C14.5278 13.3609 14.2985 13.9148 14.2985 14.504V15.2977H9.70876Z"
        fill="#6F7981"
      />
      <path
        d="M15.5121 7.41505L17.6006 11.592H16.5034C15.7238 11.592 14.9935 11.8954 14.4431 12.4458C13.8928 12.9961 13.5894 13.7264 13.5894 14.5025V14.5907H10.4108V14.5025C10.4108 13.7264 10.1074 12.9926 9.55708 12.4458C9.00673 11.8954 8.27646 11.592 7.4968 11.592H6.39965L8.48813 7.41505H15.505M16.2282 6.00391H7.76139C7.66967 6.00391 7.58853 6.05682 7.5462 6.13796L4.74155 11.7473C4.45227 12.3258 4.87209 13.0032 5.51768 13.0032H7.49327C7.89192 13.0032 8.27293 13.1619 8.55516 13.4406C8.83739 13.7229 8.99614 14.1039 8.99614 14.5025V15.5044C8.99614 15.6349 9.04907 15.7655 9.1408 15.8572C9.23605 15.9525 9.36305 16.0054 9.49358 16.0054H14.4925C14.6266 16.0054 14.7536 15.9525 14.8453 15.8572C14.9406 15.762 14.99 15.6349 14.99 15.5044V14.5025C14.99 14.1039 15.1487 13.7229 15.4309 13.4406C15.7132 13.1584 16.0942 13.0032 16.4928 13.0032H18.4684C19.114 13.0032 19.5338 12.3223 19.2446 11.7473L16.4399 6.13796C16.3976 6.05682 16.3164 6.00391 16.2247 6.00391H16.2282Z"
        fill="#6F7981"
      />
    </svg>
  );
}

export default InboxMenuIconOutlined;
