/* eslint-disable no-unused-vars */
import { PaginationUrlQuery } from "./api.types";





export enum Period {
    ThisWeek = "thisWeek",
    LastWeek = 'lastWeek',
    ThisMonth = 'thisMonth',
    LastMonth = 'lastMonth',
    ThisQuarter = 'thisQuarter',
    LastQuarter = 'lastQuarter',
    ThisYear = 'thisYear',
    LastYear = "lastYear",
    AllTime = "allTime",
}


export enum SortableFieldIdentifier {
    ASC_DATE = 'Oldest first',
    DESC_DATE = 'Newest first',
    ASC_VEHICLE_NAME = 'Vehicle name (A-Z)',
    DESC_VEHICLE_NAME = 'Vehicle name (Z-A)',
    ASC_DRIVER_NAME = 'Driver name (A-Z)',
    DESC_DRIVER_NAME = 'Driver name (Z-A)',
}


export enum SortDirection {
    ASC = "ASC",
    DESC = "DESC",
}

export enum SortableFields {
    Date = "createdAt",
    ChargerName = "chargerName",
    DriverName = 'firstName',
    VehicleName = 'name',
}

export type SortOption = {
    label: string;
    value: {
        field: SortableFields
        direction: SortDirection
        isSeparatedFromTop?: boolean
    }
}


export type SortContext = {
    [k in keyof typeof SortableFields]?: keyof typeof SortDirection
}

export type SearchableFilter<T> = T & {
    $q?: {
        $eq?: string;
    };
};
export type Query<T extends Record<string, unknown>> = {
    filters?: T
    pagination?: PaginationUrlQuery;
    sort?: {
        [k in string]?: keyof typeof SortDirection;
    };
};