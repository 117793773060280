import React from "react";

type Props = {};

function CheckboxCheckedIcon({}: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="4.16667" fill="black" />
      <path
        d="M16.3968 5.60254L7.60126 14.3981L3.60327 10.4001"
        stroke="white"
        strokeWidth="1.5992"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckboxCheckedIcon;
