import { parsePhoneNumberFromString } from "libphonenumber-js";
import {
  Address,
  Driver,
  DriverFormMode,
  DriverFormState,
  DriverFormStatus,
  DriverFormStep,
  DriverFormType,
  DriverHookStates,
  DriverNonFormState,
  DriverSelfSignupStep,
  DriverSignUpApplicationStatus
} from "types/driver.types";

import ManualDriverIcon from "components/icon/icons/ManualDriverIcon";
import SelfSignupDriverIcon from "components/icon/icons/SelfSignupDriverIcon";
import { PayerType } from "types/payment.types";
import { getWhoPaysDisplayTextByPayerType } from "./charging.utils";
import { getFormattedNumberForUsdByCent } from "./format.utils";
import { getVehicleSummaryName } from "./vehicle.utils";
import { ReimbursementRectangleBound } from "../types/reimbursement.types";

/**
 * Redux driver slice
 */

export const initialDriverNonFormState = {
  mode: DriverFormMode.ADD,
  status: DriverFormStatus.NOT_STARTED,
  stepLength: 4,
  currentStep: 0,
  type: DriverFormType.MANUAL,
  isError: false,
  errorMessage: "",
  successMessageHelper: {
    driverName: "",
    driversCount: 0
  }
};

// We can change step later dynamically...
export const manualDriverNonFormState = {
  ...initialDriverNonFormState,
  type: DriverFormType.MANUAL,
  status: DriverFormStatus.ACTIVE
};

export const selfSignupDriverNonFormState = {
  ...initialDriverNonFormState,
  type: DriverFormType.SELF_SIGNUP,
  status: DriverFormStatus.ACTIVE
};

export const bulkUploadDriversNonFormState = {
  ...initialDriverNonFormState,
  status: DriverFormStatus.ACTIVE,
  mode: DriverFormMode.BULK
};

export const initialDriverFormState = {
  firstName: "",
  lastName: "",
  street1: "",
  street2: "",
  phoneNumber: "",
  postalCode: "",
  email: "",
  credit: "",
  fixedRate: "",
  vehicleAssignmentPeriodStartDate: new Date(),
  vehicleAssignmentPeriodEndDate: undefined,
  payer: PayerType.ORGANIZATION
};

export const getDriverFormTypeLabel = (driverFormType) => {
  if (driverFormType === "fixed") {
    return "Permanent";
  }

  return "Temporary";
};

export const getDriverIconByType = (isFormTypeManual: boolean) => {
  return isFormTypeManual ? <ManualDriverIcon /> : <SelfSignupDriverIcon />;
};

export const getDriverFormStepStatus = (driverNonFormState: DriverNonFormState) => {
  const { stepLength, currentStep } = driverNonFormState;

  const isLastStep = stepLength - 1 === currentStep;
  const isFirstStep = currentStep === 0;
  const isAddressSetupStep = currentStep === DriverFormStep.ADDRESS;
  const isChargingSetupStep = currentStep === DriverFormStep.CHARGING;
  const isReimbursementStep = currentStep === DriverFormStep.REIMBURSEMENT;
  const isVehicleSetupStep = currentStep === DriverFormStep.VEHICLE;


  return { isLastStep, isAddressSetupStep, isVehicleSetupStep, isFirstStep, isChargingSetupStep, isReimbursementStep };
};

export const getDriverFormButtonText = (driverNonFormState: DriverNonFormState, isReimbursementActive: boolean, isVehicleSelected: boolean) => {
  const { isAddressSetupStep, isChargingSetupStep, isVehicleSetupStep, isLastStep, isFirstStep, isReimbursementStep } =
    getDriverFormStepStatus(driverNonFormState);

  if (isVehicleSetupStep) {
    if (isVehicleSelected) {
      return "Continue";
    }

    return "Skip";
  }

  if (isReimbursementStep) {
    if (isReimbursementActive) {
      return "Continue";
    }

    return "Skip";
  }
};


export const getDriverSelfSignupFormStepStatus = (step: DriverSelfSignupStep) => {
  const isChargingSetupStep = step === DriverSelfSignupStep.CHARGING;
  const isReimbursementStep = step === DriverSelfSignupStep.REIMBURSEMENT;
  const isVehicleSetupStep = step === DriverSelfSignupStep.VEHICLE;


  return { isVehicleSetupStep, isChargingSetupStep, isReimbursementStep };
};

export const getDriverFormStateForEditMode = (driver?: Driver | null): DriverFormState => {
  if (!driver) {
    return initialDriverFormState;
  }

  const phoneNumberWithoutDialCode = parsePhoneNumberFromString(driver.phoneNumber)?.nationalNumber ?? "";

  const chargingLimit = driver?.limits?.chargingLimit;

  return {
    firstName: driver.firstName,
    lastName: driver.lastName,
    street1: driver.address?.street ?? "",
    street2: driver.address?.street2 ?? "",
    phoneNumber: phoneNumberWithoutDialCode,
    postalCode: driver.address?.postalCode ?? "",
    email: driver.email,
    credit: getChargingLimit(chargingLimit).value,
    fixedRate: getChargingLimit(chargingLimit).value,
    payer: driver?.payer
  };
};

export const getDriverSignupStatusDisplayContent = (signupStatus: DriverSignUpApplicationStatus) => {
  switch (signupStatus) {
    case DriverSignUpApplicationStatus.APPROVED:
      return {
        label: "Approved",
        textClass: "success-text",
        color: "#2BA318"
      };

    case DriverSignUpApplicationStatus.REJECTED:
      return {
        label: "Rejected",
        textClass: "text-cherry-100",
        color: "#C4003E"
      };
    default:
      return {
        label: "Pending approval",
        textClass: "text-blue-100",
        color: "#1E44FF"
      };
  }
};

export const getFormattedCreditValueAsMultipliedInteger = (credit) => {
  let formattedCreditValue;

  formattedCreditValue = credit?.replace("$", "");
  formattedCreditValue = parseInt(formattedCreditValue?.replace(/,/g, "")?.split(".")[0]);

  return formattedCreditValue * 100;
};

export const getFormattedCreditValueForAddDriverService = (credit) => {
  const formattedCreditValue = getFormattedCreditValueAsMultipliedInteger(credit);
  const creditValueAsPayload = isNaN(formattedCreditValue) ? null : formattedCreditValue;
  return creditValueAsPayload;
};

export const getFormattedCentByUsdString = (credit?: string) => {
  if (!credit) {
    return 0;
  }

  const formattedCreditValue = credit.replace("$", "").replace(/,/g, "");

  const [dollars, cents = "00"] = formattedCreditValue.split(".");

  let totalCents = parseInt(dollars) * 100;

  if (cents) {
    totalCents += parseInt(cents.length > 1 ? cents.substring(0, 2) : cents + "0");
  }

  return totalCents;
};


export const getFormattedCentByDecimalUsAmountAsString = (amount: string) => {
  const splitAmount = amount?.replace(/,/g, "")?.split(".");

  const dollars = parseInt(splitAmount[0] ?? 0);
  const cents = parseInt(splitAmount[1] ?? 0);

  return dollars * 100 + cents;
};


export const getFormattedFixedRateValueAsMultipliedInteger = (cent: string) => {
  if (!cent) {
    return 0;
  }

  const formattedValue = cent?.replace("$", "");

  return getFormattedCentByDecimalUsAmountAsString(formattedValue);
};

export const getPayerLabelByType = (payerType: PayerType) => {
  switch (payerType) {
    case PayerType.ORGANIZATION:
      return "Organization";
    case PayerType.DRIVER:
      return "Driver";
    default:
      console.error("Unknown payer type!");
      return "Driver";
  }
};

export const getDriverPaymentResponsibilityLabelByPayerType = (payerType?: PayerType) => {
  if (!payerType) {
    return "-";
  }
  return getWhoPaysDisplayTextByPayerType(payerType) + " Pays";
};

export const getChargingLimit = (chargingLimit?: number) => {
  if (chargingLimit || chargingLimit === 0) {
    const formattedChargingLimit = getFormattedNumberForUsdByCent(chargingLimit);
    return { value: formattedChargingLimit, label: formattedChargingLimit };
  }

  return { value: null, label: "Not set" };
};


export const getFormattedCentForDefaultValue = (cent?: number) => {
  if (cent || cent === 0) {
    const formattedCent = getFormattedNumberForUsdByCent(cent);
    return { value: formattedCent, label: formattedCent };
  }

  return { value: null, label: "Not set" };
};

export const getDriversForDriverSelector = (drivers: Driver[]) => {
  if (!drivers) {
    return [];
  }
  return drivers.map((d) => {
    return { label: `${d.firstName} ${d.lastName}`, value: d.id };
  });
};


export const getDriverFullName = (driver?: Driver | null) => {
  const firstName = driver?.firstName ?? "";
  const lastName = driver?.lastName ?? "";

  return `${firstName} ${lastName}`;
};

export const getDriverNameInitials = (driver?: Driver) => {
  const firstName = driver?.firstName ?? "";
  const lastName = driver?.lastName ?? "";

  return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
};

export const getUserStatusAsBoolean = (status: string) => {
  return status === "Active";
};

export const getDriverFullAddress = (address?: Address) => {
  //"1901 Thornridge Cir. Shiloh, Hawaii 81063, US"
  if (!address) {
    return "-";
  }

  const { street, street2, city, state, postalCode, country } = address;

  const streetInfo = `${street ?? ""}${street2 ? " " + street2 : ""}`;
  return `${streetInfo}.  ${city ?? ""},  ${state ?? ""},  ${postalCode ?? ""}  ${country ?? ""}.`;
};


export const getDriverRowForCsv = (driverRow: Driver) => {
  // We will add vehicle details as separated columns.
  const {
    firstName, lastName,
    vehicles,
    version,
    updatedAt,
    id,
    organizationId,
    groups,
    settings,
    ...otherDriverData
  } = driverRow;

  return {
    firstName,
    lastName,
    ...otherDriverData,
    vehicle: vehicles.length > 0 ? getVehicleSummaryName(vehicles[0]) : "-"
  };
};


export const getDriverReimbursementLocationFromLocations = (driverHook: DriverHookStates) => {
  const { isLoading, driver } = driverHook;
  if (isLoading || !driver || !driver?.chargingReimbursementLocations) {
    return;
  }

  if (driver?.chargingReimbursementLocations.length > 0) {
    return driver.chargingReimbursementLocations[0];
  }

};


export const getDriverSelfSignupStepsContent = (step: DriverSelfSignupStep) => {
  switch (step) {
    case DriverSelfSignupStep.CHARGING:
      return {
        label: "Charging Setup",
        stepText: "Next: +2 optional settings",
        isStepIndexVisible:false
      };
    case DriverSelfSignupStep.REIMBURSEMENT:
      return {
        label: "Home Charging",
        stepText: "1/2",
        isOptional: true,
        isStepIndexVisible:true,
        ix:1
      };
    case DriverSelfSignupStep.VEHICLE:
      return {
        label: "Vehicle Setup",
        ix: 2,
        stepText: '2/2',
        isOptional: true,
        isStepIndexVisible:true
      };

    default :
      return {
        label: "Charging Setup",
        ix: 1
      };
  }

};


const calculateRectangleArea = (bounds: ReimbursementRectangleBound): number => {
  const earthRadius = 6378137; // Earth's radius in meters

  const latDistance = (bounds.north - bounds.south) * (Math.PI / 180) * earthRadius;
  const lngDistance = (bounds.east - bounds.west) * (Math.PI / 180) * earthRadius * Math.cos(((bounds.north + bounds.south) / 2) * (Math.PI / 180));

  return Math.abs(latDistance * lngDistance);
};

const getZoomLevel = (area: number): number => {
  if (area <= 300) return 20;
  if (area <= 3000) return 19;
  if (area <= 30000) return 18;
  if (area <= 300000) return 17;
  if (area <= 3000000) return 16;
  if (area <= 30000000) return 15;
  if (area <= 300000000) return 14;
  if (area <= 3000000000) return 13;
  if (area <= 30000000000) return 12;
  return 11; // Adjust this as needed for larger areas
};

export const calculateZoomForRectangle = (bounds: ReimbursementRectangleBound): number => {
  const area = calculateRectangleArea(bounds);
  return getZoomLevel(area);
};