import FormButton from "./FormButton";

type Props = {
  readonly onProceedToSignInButtonPressed?: () => void;
  readonly className?: string;
  readonly type?: string;
  readonly isLoading?: boolean;
  readonly isDisabled?: boolean;
};

function ProceedToSignInButton({ onProceedToSignInButtonPressed, ...props }: Props) {
  return <FormButton text={"Proceed to Sign in"} onFormButtonPressed={onProceedToSignInButtonPressed} {...props} />;
}

export default ProceedToSignInButton;
