import React, { useRef, useState } from "react";
import { GoogleMap, Rectangle } from "@react-google-maps/api";
import { ErrorBoundary } from "react-error-boundary";
import { CircularProgress } from "@mui/material";
import { useResizeMapFullWidthHeight } from "../../hooks/google-maps.hooks";
import { BlackBodyText100, NeutralBodyText300 } from "../text/Text";
import { ReimbursementMapReadonlyProps } from "../../types/reimbursement.types";
import Row from "../custom/Row";
import Col from "../custom/Col";
import PencilIcon from "../icon/icons/PencilIcon";
import OutlinedButtonWithIcon from "../button/OutlinedButtonWithIcon";


type Props = ReimbursementMapReadonlyProps

const ReadonlyReimbursementMap: React.FC<Props> = ({
                                                     bounds,
                                                     center, address, isGoogleApiLoaded, isLoading,
                                                     onMapEditButtonPressed,
                                                     zoom
                                                   }: Props) => {

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const { mapDimensions } = useResizeMapFullWidthHeight(map, "map-container");


  const rectangleRef = useRef<google.maps.Rectangle | null>(null);


  return (
    <div id={"map-container"} className={"h-100 w-100"}>
      {(!isGoogleApiLoaded || isLoading || !center) ? (<div className={"center h-300px w-100"}>
            <CircularProgress size={75} style={{ color: "#1E44FF" }} />
          </div>
        ) :
        <>


          <Col className="mb-2">
            <Row className={"align-items-center justify-between"}>
              <BlackBodyText100>Home address:</BlackBodyText100>
              <OutlinedButtonWithIcon
                isSmall
                className={"ml-2"}
                leftIcon={<PencilIcon fill={"black"} />}
                text={""}
                onClick={onMapEditButtonPressed}
              />
            </Row>
            <NeutralBodyText300 className={"w-100 text-truncate"}>{address}</NeutralBodyText300>
          </Col>


          <GoogleMap
            options={{
              streetViewControl:false,
              fullscreenControl: false,
            }}
            mapContainerStyle={mapDimensions}
            mapContainerClassName="map-container"
            center={center}
            zoom={zoom}
            onLoad={mapInstance => setMap(mapInstance)}
          >
            <Rectangle
              bounds={bounds}
              editable={false}
              draggable={false}
              onLoad={rectangle => (rectangleRef.current = rectangle)}
              onUnmount={() => (rectangleRef.current = null)}
            />
          </GoogleMap>
        </>
      }
    </div>
  );
};

const ReimbursementMapWithErrorBoundary = (props: Props) => (
  <ErrorBoundary fallback={<>Error</>}>
    <ReadonlyReimbursementMap {...props} />
  </ErrorBoundary>
);

export default ReimbursementMapWithErrorBoundary;
