import { useTranslation } from "react-i18next";
import AssignDriverForm from "../../components/driver-components/AssignDriverForm";
import AssignDriverHeader from "../../components/driver-components/AssignDriverHeader";
import { useVehicleAsync } from "../../hooks/data/vehicle-data-accessor.hooks";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { getVehicleSummaryName } from "../../utils/vehicle.utils";

type Props = {};

function AssignDriverPage(props: Props) {
  const { t } = useTranslation("common");

  const { isLoading, vehicle } = useVehicleAsync();

  const doesNotVehicleExist = !vehicle && !isLoading;

  return (
    <>
      <Head title={t("vehicle.title.assign_driver_page")}></Head>
      <Content>
        {doesNotVehicleExist ? (
          <>Vehicle could not find!</>
        ) : (
          <>
            <AssignDriverHeader vehicleLabel={getVehicleSummaryName(vehicle)} />
            <AssignDriverForm isVehicleLoading={isLoading} vehicle={vehicle} />
          </>
        )}
      </Content>
    </>
  );
}

export default AssignDriverPage;
