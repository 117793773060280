import React from "react";
import { components } from "react-select";
import { Row } from "reactstrap";
import RSelect from "./ReactSelect";

export default function ReactSelectWithCustomFooter(props) {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  const CustomMenu = (customMenuProps) => {
    const { onMenuClose } = customMenuProps;

    const handleSelectAllClicked = () => {
      onMenuClose();
      props.onSelectAllPressed();
    };
    const handleSelectClearClicked = () => {
      onMenuClose();
      props.onSelectClearPressed();
    };

    return (
      <div>
        <components.Menu {...customMenuProps}>
          {customMenuProps.children}
          <Row className="justify-between m-0 p-1" style={{ zIndex: 99 }}>
            <div className="text-blue" onClick={handleSelectAllClicked}>
              Select all
            </div>
            <div className="text-danger" onClick={handleSelectClearClicked}>
              Clear all
            </div>
          </Row>
        </components.Menu>
      </div>
    );
  };

  const handleChange = (_) => {
    props.onChange(_);
    handleMenuClose();
  };

  return (
    <RSelect
      {...props}
      isClearable
      menuIsOpen={menuIsOpen}
      onChange={handleChange}
      onBlur={handleMenuClose}
      onMenuOpen={() => setMenuIsOpen(true)}
      components={{
        Menu: (menuProps) => <CustomMenu {...menuProps} options={props.options} onMenuClose={handleMenuClose} />,
      }}
      menuPosition="fixed"
      menuPortalTarget={document.querySelector(".modal-content")}
      styles={{
        menuList: (styles) => {
          return {
            ...styles,
            maxHeight: 215,
          };
        },
      }}

      // onChange={(data) => handleSelectorChange("drivers", data)}
    />
  );
}
