import React from "react";
import { NeutralBodyMediumText0, NeutralBodySmallText300, VioletTitleSmallText500 } from "../../../text/Text";
import { Row } from "reactstrap";
import HorizontalLine from "../../../line/HorizontalLine";

type Props = {
  rank: string
  label: string
  description?: string
  descriptionOverwrite?: React.JSX.Element
  isBorderSeperatorHidden?: boolean
}
const AddVehicleTeslaFlowStarterGuideCardBlock = ({
                                                    rank,
                                                    label,
                                                    description,
                                                    descriptionOverwrite,
                                                    isBorderSeperatorHidden
                                                  }: Props) => {
  return (
    <Row className={"px-3 d-flex flex-row flex-nowrap"}>
      <div className="violet-circle center mr-2 w-min-25px">
        <VioletTitleSmallText500>{rank}</VioletTitleSmallText500>
      </div>
      <div className={'w-100 w-max-100'}>
        <NeutralBodyMediumText0>{label}</NeutralBodyMediumText0>
        {description && (<NeutralBodySmallText300>{description}</NeutralBodySmallText300>)}
        {descriptionOverwrite && descriptionOverwrite}
        {!isBorderSeperatorHidden && <HorizontalLine />}
      </div>
    </Row>
  );
};

export default AddVehicleTeslaFlowStarterGuideCardBlock;