import React from "react";

const VehicleStatusParkingIcon = () => {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M0.109375 3.5C0.109375 2.57174 0.478124 1.6815 1.1345 1.02513C1.79088 0.368749 2.68112 0 3.60938 0L10.6094 0C11.5376 0 12.4279 0.368749 13.0842 1.02513C13.7406 1.6815 14.1094 2.57174 14.1094 3.5V10.5C14.1094 11.4283 13.7406 12.3185 13.0842 12.9749C12.4279 13.6313 11.5376 14 10.6094 14H3.60938C2.68112 14 1.79088 13.6313 1.1345 12.9749C0.478124 12.3185 0.109375 11.4283 0.109375 10.5L0.109375 3.5ZM5.31938 3.117C5.23721 3.11687 5.15583 3.13294 5.07989 3.16429C5.00395 3.19564 4.93493 3.24165 4.87679 3.2997C4.81864 3.35775 4.77252 3.4267 4.74105 3.50259C4.70957 3.57849 4.69338 3.65984 4.69338 3.742V10.77C4.69338 10.9358 4.75922 11.0947 4.87643 11.2119C4.99364 11.3292 5.15261 11.395 5.31838 11.395C5.48414 11.395 5.64311 11.3292 5.76032 11.2119C5.87753 11.0947 5.94338 10.9358 5.94338 10.77V8.466H8.50338C8.78243 8.466 9.05876 8.41104 9.31658 8.30424C9.57439 8.19745 9.80865 8.04093 10.006 7.8436C10.2033 7.64628 10.3598 7.41202 10.4666 7.1542C10.5734 6.89639 10.6284 6.62006 10.6284 6.341V5.242C10.6284 4.96294 10.5734 4.68661 10.4666 4.4288C10.3598 4.17098 10.2033 3.93672 10.006 3.7394C9.80865 3.54207 9.57439 3.38555 9.31658 3.27876C9.05876 3.17196 8.78243 3.117 8.50338 3.117H5.31938ZM8.50338 7.217H5.94338V4.367H8.50338C8.98637 4.367 9.37838 4.759 9.37838 5.242V6.342C9.37811 6.57389 9.28581 6.79619 9.12174 6.96007C8.95767 7.12395 8.73527 7.217 8.50338 7.217Z"
            fill="#6F7981" />
    </svg>
  );
};


export default VehicleStatusParkingIcon;