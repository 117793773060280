import { useDashboardOverviewsAsync } from "hooks/data/dashboard-data-accessor.hooks";
import { formatNumber } from "../../utils/charging.utils";
import { getFormattedNumberForUsdByCent } from "../../utils/format.utils";
import Col from "../custom/Col";
import Row from "../custom/Row";
import DashboardOverviewCard from "./DashboardOverviewCard";

export default function DashboardOverviewCards() {
  const { overviews, isLoading } = useDashboardOverviewsAsync();

  const energy = overviews?.totalEnergyOverview;
  const cost = overviews?.totalCostOverview;
  const vehicle = overviews?.totalVehicleOverview;

  return (
    <Row className="g-2">
      <Col lg="4">
        <DashboardOverviewCard
          isSmall
          total={vehicle?.total?.toString()}
          percent={vehicle?.monthlyTrend?.percentageChange}
          isLoading={isLoading}
          title={"Total Vehicles"}
        />
      </Col>
      <Col lg="4">
        <DashboardOverviewCard
          isSmall
          total={formatNumber(energy?.total)}
          percent={energy?.monthlyTrend?.percentageChange}
          isLoading={isLoading}
          title={"Total kWh Delivered"}
        />
      </Col>
      <Col lg="4">
        <DashboardOverviewCard
          isSmall
          total={getFormattedNumberForUsdByCent(cost?.total)}
          percent={cost?.monthlyTrend?.percentageChange}
          isLoading={isLoading}
          title={"Total Charging Cost"}
        />
      </Col>
    </Row>
  );
}
