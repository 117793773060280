import React from "react";

type Props = {};

function TeslaAndCarIntersectionIcon({}: Props) {
  return (
    <svg width="216" height="199" viewBox="0 0 216 199" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="129.6" height="129.6" rx="64.8" fill="#C4003E" />
      <path
        d="M64.8 44.9504L72.2064 35.8976C72.2064 35.8976 84.8928 36.1668 97.5344 42.0434C94.2977 46.9377 87.8738 49.3332 87.8738 49.3332C87.4409 45.0334 84.4285 43.9836 74.8486 43.9836L64.8022 100.689L54.6863 43.9724C45.1759 43.9724 42.1635 45.0244 41.7306 49.322C41.7306 49.322 35.3044 46.9511 32.07 42.0547C44.7094 36.178 57.398 35.9088 57.398 35.9088L64.8045 44.9504H64.7932H64.8ZM64.8 33.2912C75.0101 33.197 86.7052 34.8681 98.6783 40.101C100.28 37.203 100.688 35.9313 100.688 35.9313C87.6046 30.7432 75.3488 28.9577 64.8 28.9106C54.2511 28.9577 41.9953 30.7342 28.9119 35.9313C28.9119 35.9313 29.495 37.5081 30.9216 40.1145C42.897 34.8569 54.5898 33.1993 64.8 33.28V33.2912Z"
        fill="white"
      />
      <g clip-path="url(#clip0_2820_23729)">
        <rect x="79.6499" y="62.1006" width="136.35" height="136.35" rx="68.175" fill="#4665FF" />
        <path
          d="M182.7 125.699L175.798 118.289L169.674 104.137C169.148 103.149 168.411 102.334 167.534 101.77C166.656 101.207 165.668 100.914 164.664 100.921H130.15C129.146 100.914 128.158 101.207 127.281 101.77C126.403 102.334 125.666 103.149 125.14 104.137L119.017 118.289L112.114 125.699C111.833 126.001 111.609 126.366 111.455 126.772C111.302 127.178 111.223 127.617 111.223 128.06V155.598C111.223 156.451 111.517 157.269 112.039 157.872C112.561 158.476 113.269 158.815 114.007 158.815H125.14C126.254 158.815 127.924 157.528 127.924 156.241V152.382H166.891V155.598C166.891 156.885 168.004 158.815 169.117 158.815H180.808C181.546 158.815 182.254 158.476 182.776 157.872C183.298 157.269 183.591 156.451 183.591 155.598V128.06C183.591 127.617 183.512 127.178 183.359 126.772C183.206 126.366 182.982 126.001 182.7 125.699ZM130.707 107.353H164.107L169.674 120.219H125.14L130.707 107.353ZM133.49 136.943C133.49 138.23 131.82 139.517 130.707 139.517H119.017C117.903 139.517 116.79 137.587 116.79 136.3V129.224C117.347 127.294 118.46 126.008 120.13 126.651L131.264 129.224C132.377 129.224 133.49 131.154 133.49 132.441V136.943ZM178.024 136.3C178.024 137.587 176.911 139.517 175.798 139.517H164.107C162.994 139.517 161.324 138.23 161.324 136.943V132.441C161.324 131.154 162.437 129.224 163.551 129.224L174.684 126.651C176.354 126.008 177.468 127.294 178.024 129.224V136.3Z"
          fill="white"
        />
        <path
          d="M129.876 64.938C129.529 81.9084 119.07 118.239 80.0034 127.798C79.6571 111.866 89.1467 76.9904 129.876 64.938Z"
          fill="#23D206"
        />
      </g>
      <defs>
        <clipPath id="clip0_2820_23729">
          <rect x="79.6499" y="62.1006" width="136.35" height="136.35" rx="68.175" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TeslaAndCarIntersectionIcon;
