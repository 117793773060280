import Card from "components/card/Card";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import PageHeader from "components/header/PageHeader";
import InfoIcon from "components/icon/icons/InfoIcon";
import { BlackCaptionText200 } from "components/text/Text";
import { useTranslation } from "react-i18next";
import VehicleManualFlowForm from "../VehicleManualFlowForm";

type Props = {};

function AddVehicleManualFlowFormStarter({}: Props) {
  const { t } = useTranslation("common");
  return (
    <>
      <PageHeader
        title={t("vehicle.add_vehicle.manual.title") as string}
        description={t("vehicle.add_vehicle.manual.description") as string}
      />
      <Row className="w-100 w-max-100  center mt-5">
        <Col lg="2"></Col>
        <Col lg="7" className="justify-center flex-column">
          <div className="card  py-4 px-1 center rounded-xxl">
            <VehicleManualFlowForm />
          </div>
        </Col>
        <Col lg="3" className="pl-lg-3 align-self-start mt-2 mt-lg-0 ">
          <Card padding="p-4">
            <InfoIcon />
            <BlackCaptionText200 className="mt-2">{t("vehicle.form.starter.manual.info")}</BlackCaptionText200>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default AddVehicleManualFlowFormStarter;
