import { useCallback } from "react";
import { useUrlQuery } from "./router.hooks";
import { PaginationUrlQuery } from "types/api.types";
import { Query } from "types/filter.types";

export const useUrlPagination = () => {
  const { query, setQuery } = useUrlQuery<Query<{}>>();

  const pageQueryString = query?.pagination?.page ?? "0";
  const sizeQueryString = query?.pagination?.size ?? "20";

  const pageQueryNumber = parseInt(pageQueryString) + 1;
  const sizeQueryNumber = parseInt(sizeQueryString);


  const handleSetPaginationOnUrlQuery = useCallback(
    (paginationQuery: PaginationUrlQuery) => {
      setQuery(q => {
        return {
          ...q,
          pagination: paginationQuery
        };
      });
    },
    [setQuery]
  );


  const handleSetTableCurrentPage = useCallback(
    (page: number) => {
      const indexPage = page - 1;
      handleSetPaginationOnUrlQuery({
        page: indexPage.toString(),
        size: sizeQueryString
      });

    },
    [handleSetPaginationOnUrlQuery, sizeQueryString]
  );


  const handleSetTablePageRowSize = useCallback(
    (size: number) => {
      handleSetPaginationOnUrlQuery({
        page: "0",
        size: size.toString()
      });
    },
    [handleSetPaginationOnUrlQuery]
  );


  return {
    tableCurrentPage: pageQueryNumber,
    tablePageRowSize: sizeQueryNumber,
    handleSetTablePageRowSize,
    handleSetTableCurrentPage
  };
};