import React, { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";

import { RSelect } from "components/Component";
import Col from "components/custom/Col";

import DriverFormResponsibilityModal from "../DriverFormResponsibilityModal";

import { useAllVehiclesAsync } from "hooks/data/vehicle-data-accessor.hooks";
import "react-datepicker/dist/react-datepicker.css";
import { getVehicleSummaryName, mapVehiclesForSelector } from "utils/vehicle.utils";

import { BlackBodyText100, NeutralBodySmallText300, NeutralTitleSmallTextBlack } from "components/text/Text";

import { ErrorBoundary } from "react-error-boundary";

import VehicleDriverSchedulePeriodSelector from "components/vehicle-driver-schedule/form/VehicleDriverSchedulePeriodSelector";
import { DriverFormStepsCommonProps, DriverFormVehicleSetupStepStates } from "types/driver.types";
import { SelectorStringOption } from "types/editor.types";
import { VehicleDriverSchedule } from "types/vehicle-driver-schedule.types";
import { getVehicleDriverSchedulePeriodsForSelector } from "utils/vehicle-driver-schedule.utils";

type Props = DriverFormStepsCommonProps & {
  driverFormVehicleSetupStates: DriverFormVehicleSetupStepStates;
  isEditMode?: boolean;
  isPortal?: boolean;
  selectedScheduleToUpdate?: VehicleDriverSchedule | undefined;
};
export default function DriverFormVehicleSetupStep({
  isPortal,
  driverFormVehicleSetupStates,
  isEditMode,
  selectedScheduleToUpdate,
}: Props) {
  const { allVehicles: availableVehicles, isLoading } = useAllVehiclesAsync();
  const vehicleOptions = mapVehiclesForSelector(availableVehicles);

  const {
    vehicleSelector,

    setVehicleDriverSchedulePeriodSelector,
    handleSetVehicleSelector,
  } = driverFormVehicleSetupStates;

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!!selectedScheduleToUpdate && isEditMode) {
      setVehicleDriverSchedulePeriodSelector({
        ...getVehicleDriverSchedulePeriodsForSelector(
          selectedScheduleToUpdate.startsAt,
          selectedScheduleToUpdate.endsAt
        ),
      });
      handleSetVehicleSelector({
        label: getVehicleSummaryName(selectedScheduleToUpdate.vehicle),
        value: selectedScheduleToUpdate.vehicleId,
      });
    }
  }, [selectedScheduleToUpdate, isEditMode, setVehicleDriverSchedulePeriodSelector, handleSetVehicleSelector]);

  return (
    <ErrorBoundary fallback={<>An error occured</>}>
      <DriverFormResponsibilityModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

      <div className="d-flex flex-column w-100  p-0">
        <Col md="12" className="mb-2">
          <FormGroup>
            <BlackBodyText100 className="mb-1">Vehicle </BlackBodyText100>

            <RSelect
              menuPosition="fixed"
              menuPortalTarget={document.querySelector(".modal-content")}
              styles={{
                menuList: (styles) => {
                  return {
                    ...styles,
                    maxHeight: 200,
                  };
                },
              }}
              isDisabled={isEditMode}
              noOptionsMessage={() => (isLoading ? "Loading..." : "No vehicles")}
              placeholder={isLoading ? "Loading..." : "Choose a vehicle to assign"}
              options={vehicleOptions}
              value={vehicleSelector}
              onChange={(option: SelectorStringOption) => handleSetVehicleSelector(option)}
              isClearable
            />
          </FormGroup>
        </Col>
        <VehicleDriverSchedulePeriodSelector
          isEditMode={isEditMode}
          isPortal={isPortal}
          vehicleScheduleSelectorHooks={driverFormVehicleSetupStates}
        />
      </div>
    </ErrorBoundary>
  );
}

/**
 *  <div className="w-max-100 w-275px react-datepicker-container mt-2">
              <ReactDatePicker
                minDate={moment().add(1, "days").toDate()}
                //  showTimeSelect
                selected={expiryDate ?? new Date()}
                onChange={onExpiryDateChanged}
                // dateFormat="MMMM d, yyyy h:mm aa"
                className="form-control date-picker  w-100"
                disablePortal={true}
                popperPlacement="bottom-end"
              />
              <DateInputIcon />
            </div>


      <OutlinedButtonWithIcon
        onClick={handleAddVehicleAndPeriodSelector}
        type="button"
        leftIcon={<Icon className="text-black-100" name="plus" />}
        text="Assign more vehicles"
      />
 */
