import { RSelect } from "components/Component";
import FilterSelectorCardItem from "components/filter/FilterSelectorCardItem";
import { useSortingUrlQuery } from "hooks/filter.hooks";
import React, { useCallback } from "react";
import { SortDirection, SortOption, SortableFields } from "types/filter.types";
import { getSortingOption } from "utils/filter.utils";

type Props = {
  sortOptions: SortOption[];
};

function FilterSortingSelector({ sortOptions }: Props) {
  const { sortingQuery, setSortingQuery } = useSortingUrlQuery();

  const sortField = sortingQuery && (Object.keys(sortingQuery)[0] as SortableFields);

  const sortDirection = sortingQuery && (Object.values(sortingQuery)[0] as SortDirection);
  const selectedSortingOption = getSortingOption(sortField, sortDirection);

  const CustomOption = useCallback(
    (props) => {
      const { label, setValue, data } = props;
      return (
        <FilterSelectorCardItem
          isSeparatedFromBottom={data?.isSeparatedFromBottom}
          isSelected={selectedSortingOption.label === data.label}
          text={label}
          onClick={() => setValue({ value: data.value, label })}
          key={data.value}
        />
      );
    },
    [selectedSortingOption]
  );

  return (
    <div className="filter-selector w-200px mt-2 mt-md-0">
      <RSelect
        onChange={({ value, label }) => setSortingQuery(value.field, value.direction)}
        className="pl-md-2"
        isSearchable={false}
        options={sortOptions}
        components={{ Option: CustomOption }}
        value={selectedSortingOption}
        placeholder={selectedSortingOption.label}
      />
    </div>
  );
}

export default FilterSortingSelector;
