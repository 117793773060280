import { useMemo } from "react";
import { BlockLabel, BlockText } from "../block/Block";
import Card from "../card/Card";
import Row from "../custom/Row";

import { getLocalFormattedDateForTables } from "utils/date.utils";
import {
  convertSecondsToMinutesAndHours,
  getChargerInfo,
  getChargerTypeIcon,
  getChargingPayStatDisplayContent,
  getChargingPayStatDisplayText
} from "../../utils/charging.utils";
import ChargerLocationMap from "./ChargerLocationMap";
import ChargingSessionsDetailsPageCardBlock from "./ChargingSessionsDetailsPageCardBlock";
import { BlackBodyText100, BlackLinkText100 } from "../text/Text";
import { useStripeInvoiceUrlAccessor } from "../../hooks/invoice.hooks";
import { ErrorBoundary } from "react-error-boundary";
import { getFormattedCostByNetwork } from "../../utils/format.utils";
import ChargingStateBadge from "../badge/ChargingStateBadge";

export default function DetailsPageSessionCard({ chargingSessionDetails }) {
  const {
    charger,
    network,
    chargerName,
    cost,
    energyDelivered,
    createdAt: chargingDate,
    type,
    duration,
    paymentState,
    invoiceId,
    state
  } = chargingSessionDetails;

  const { handleForwardToStripeAsync, isForwarding } = useStripeInvoiceUrlAccessor();


  const { label: chargerLabel, logo } = useMemo(() => getChargerInfo(network), [network]);

  const formattedDate = getLocalFormattedDateForTables(chargingDate);

  const formattedDuration = duration ? convertSecondsToMinutesAndHours(duration) : 0;

  const formattedCost = getFormattedCostByNetwork(cost, network);

  const chargerIcon = getChargerTypeIcon(type);


  return (
    <Card>
      <Row className="mb-1 align-center">
        <div className="h-48px  d-flex align-center mr-3">{logo}</div>
        <div>
          <BlockText className={"m-0"}>{chargerName}</BlockText>
          <BlockLabel className={"fs-16px"}>{chargerLabel}</BlockLabel>
        </div>
      </Row>

      <ChargingSessionsDetailsPageCardBlock
        leftLabel="Duration"
        leftAfterText={<ChargingStateBadge state={state} />}
        leftText={formattedDuration}
        rightLabel="Date"
        rightText={formattedDate}
      />
      {(cost || energyDelivered) && (
        <ChargingSessionsDetailsPageCardBlock
          leftLabel="Cost"
          leftText={formattedCost}
          rightLabel="kWh"
          rightText={energyDelivered}
        />
      )}
      <ChargingSessionsDetailsPageCardBlock
        leftTextOverwrite={
          <Row>
            {chargerIcon}
            <BlackBodyText100>{type}</BlackBodyText100>
          </Row>
        }
        leftLabel="Type" />
      <ChargingSessionsDetailsPageCardBlock
        leftLabel="PayStat"
        leftTextOverwrite={<p className={getChargingPayStatDisplayContent(paymentState).textClass}>
          {getChargingPayStatDisplayText(paymentState)}
        </p>}
        rightLabel="Invoice"
        rightTextOverwrite={invoiceId ? (
          <BlackLinkText100 className={`${isForwarding && "cursor-progress"}`}
                            onClick={() => handleForwardToStripeAsync(invoiceId)}>
            See invoice
          </BlackLinkText100>
        ) : (
          "-"
        )}
      />


      {charger && <ChargingSessionsDetailsPageCardBlock leftLabel="Charger Location" leftText={charger.address} />}
      {charger &&
        <ErrorBoundary fallback={<>Google maps error</>}><ChargerLocationMap charger={charger} /></ErrorBoundary>}
    </Card>
  );
}

/**
 *
 */
