import React from "react";
import { SvgProps } from "../../../types/svg.types";

const ChargerIcon = ({ fill, width, height, className }: SvgProps) => {
  const fillValue = fill ?? "#680031";
  const widthValue = width ?? 16;
  const heightValue = height ?? 18;

  return (
    <svg
      width={`${widthValue}`}
      height={`${heightValue}`}
      viewBox={`0 0 ${widthValue} ${heightValue}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.0628 0.0860308C11.1851 0.156946 11.2797 0.267248 11.3313 0.398917C11.3828 0.530587 11.3882 0.67584 11.3465 0.810949L9.09548 8.12512H13.2488C13.3708 8.12507 13.4902 8.16077 13.5922 8.2278C13.6942 8.29483 13.7744 8.39026 13.8227 8.50232C13.8711 8.61437 13.8856 8.73814 13.8645 8.85835C13.8433 8.97856 13.7874 9.08994 13.7037 9.17875L3.70484 19.8025C3.6081 19.9054 3.47935 19.9725 3.3396 19.9928C3.19985 20.0132 3.05733 19.9856 2.93527 19.9146C2.81321 19.8435 2.71881 19.7332 2.66745 19.6017C2.61609 19.4701 2.61081 19.3251 2.65246 19.1901L4.90346 11.8747H0.750179C0.628124 11.8747 0.508726 11.839 0.406725 11.772C0.304724 11.705 0.224584 11.6096 0.1762 11.4975C0.127815 11.3854 0.113304 11.2617 0.134457 11.1415C0.155609 11.0213 0.2115 10.9099 0.29523 10.8211L10.2941 0.197268C10.3907 0.0945287 10.5193 0.0274982 10.6588 0.0070754C10.7984 -0.0133474 10.9407 0.0140388 11.0628 0.0847809V0.0860308Z"
        fill={fillValue}
      />
    </svg>
  );
};
export default ChargerIcon;

/**
 *   width={`${widthValue}`}
      height={`${heightValue}`}
      viewBox={`0 0 ${widthValue} ${heightValue}
 */
