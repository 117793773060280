import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { colorTheFleetGroups, setLatestExpandedGroup } from "../../redux/slices/fleetGroupsSlice";
import { deleteFleetGroupAsync } from "../../services/fleet-group.services";
import FleetGroupBadge from "../badge/FleetGroupBadge";
import FleetGroupsTableExpandedContent from "./FleetGroupsTableExpandedContent";
import FleetGroupsTablePlaceHolder from "./FleetGroupsTablePlaceHolder";

import { getUniqueVehicles } from "../../utils/vehicle.utils";
import Row from "../custom/Row";
import AlertDialog from "../notification/AlertDialog";

import LoadingSkeletonForTable from "components/loading/LoadingSkeletonForTable";
import { useAlertManager } from "hooks/alert.hooks";
import { useAppSelector } from "hooks/redux.hooks";
import { FleetGroup } from "types/fleet-group.types";
import { TableColumn } from "types/table.types";
import { handleApiErrorResponse } from "utils";
import { useFleetGroupBadgeDeeds } from "../../hooks/fleet-group.hooks";
import { getFormattedNumberForUsdByCent } from "../../utils/format.utils";
import MuiTooltip from "../tooltip/MuiTooltip";
import PrimaryNegativeButton from "../button/PrimaryNegativeButton";
import TrashIcon from "../icon/icons/TrashIcon";
import ButtonWithIcon from "../button/ButtonWithIcon";
import PencilIcon from "../icon/icons/PencilIcon";

export type FleetGroupsTableColumn = TableColumn<FleetGroup>;

type Props = { fleetGroups: FleetGroup[]; refetchFleetGroups: () => Promise<void>; isGroupsDataLoading: boolean };

export default function FleetGroupsTable({
                                           fleetGroups: fleetGroupsRawData,
                                           refetchFleetGroups,
                                           isGroupsDataLoading
                                         }: Props) {
  const { handleOpenErrorAlert, handleOpenSuccessAlert } = useAlertManager();
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [fleetGroupToBeDeleted, setFleetGroupToBeDeleted] = useState<FleetGroup | undefined>(undefined);
  const latestExpandedGroup = useAppSelector((state) => state.fleetGroups.latestExpandedGroup);

  const fleetGroups = useMemo(
    () => (fleetGroupsRawData ? [...fleetGroupsRawData]?.sort((a, b) => a.name.localeCompare(b.name)) ?? [] : []),
    [fleetGroupsRawData]
  );

  const dispatch = useDispatch();

  const { coloredGroups, getGroupBadgeContent } = useFleetGroupBadgeDeeds(fleetGroups);
  const history = useHistory();

  const handleToggleIsDialogOpen = useCallback(() => {
    setIsDialogOpen(!isDialogOpen);
  }, [isDialogOpen]);

  const handleDeleteGroup = useCallback(async () => {
    if (!fleetGroupToBeDeleted) {
      return;
    }

    try {
      setIsLoading(true);
      await deleteFleetGroupAsync(fleetGroupToBeDeleted.id);
      await refetchFleetGroups();
      handleOpenSuccessAlert("Fleet group was successfully deleted");
    } catch (error: any) {
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      handleToggleIsDialogOpen();
      setIsLoading(false);
    }
  }, [
    fleetGroupToBeDeleted,
    handleOpenErrorAlert,
    handleOpenSuccessAlert,
    handleToggleIsDialogOpen,
    refetchFleetGroups
  ]);

  const handleDeleteIconPressed = useCallback(
    async (group: FleetGroup) => {
      setFleetGroupToBeDeleted(group);
      handleToggleIsDialogOpen();
    },
    [handleToggleIsDialogOpen]
  );

  const handleRowExpandToggled = useCallback(
    (isExpanded: boolean, group: FleetGroup) => {
      if (isExpanded) {
        dispatch(setLatestExpandedGroup(group?.id));
        return;
      }
      dispatch(setLatestExpandedGroup(""));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(colorTheFleetGroups(fleetGroups));
  }, [coloredGroups, dispatch, fleetGroups]);

  const columns = useMemo(
    () => [
      {
        grow: 2,
        name: "Group",
        selector: (row) => {
          const badgeContent = getGroupBadgeContent(row?.name);
          return badgeContent ? (
            <p>
              {/**
               * Ugly solution : pointer-events:none prevents title attribute
               * https://stackoverflow.com/a/47412524/13592168
               */}
              <FleetGroupBadge badgeContent={badgeContent} />
            </p>
          ) : (
            <></>
          );
        }
      },
      {
        grow: 2,
        name: "Drivers",
        selector: (row) => {
          return <>{row?.users?.length ?? 0} Drivers </>;
        }
      },
      {
        grow: 2,

        name: "Vehicles",
        selector: (row) => <>{getUniqueVehicles(row?.vehicles)?.length ?? 0} Vehicles </>
      },
      {
        grow: 2,
        name: (
          <Row className="d-flex align-center">
            <span>Total Cost</span>
            <MuiTooltip title={"The total charging spending of the fleet group at all times."} placement="top">
              <em id="TableTotalCost" className="icon ni ni-alert-circle fs-22px ml-1 rdt_table_tooltip" />
            </MuiTooltip>
          </Row>
        ),

        selector: (row) => getFormattedNumberForUsdByCent(row?.cost)
      },

      {
        right: false,
        center: true,
        grow: 2,
        name: "Actions",
        cell: (row) => (
          <Row style={{ pointerEvents: "auto" }} className="w-100 w-min-100px  center py-1">


            <PrimaryNegativeButton
              isSmall
              leftIcon={<TrashIcon />}
              text={""}
              onClick={() => handleDeleteIconPressed(row)} />


            <ButtonWithIcon
              isSmall
              className={"ml-2"}
              leftIcon={<PencilIcon fill={"white"} />}
              text={""}
              onClick={() => history.push(`/fleet-groups/${row.id}`)}
            />


          </Row>
        )
      }
    ],
    [getGroupBadgeContent, handleDeleteIconPressed, history]
  ) as FleetGroupsTableColumn[];

  const ExpandedComponent = ({ data }: { data: FleetGroup }) => {
    return <FleetGroupsTableExpandedContent groupId={data.id} />;
  };

  const alertDialogTitle = "Confirm Group Deletion";
  const alertDialogDescription = `Are you sure you want to delete ${fleetGroupToBeDeleted?.name} Fleet Group? This action cannot be undone, but you can always create a new group.`;

  return (
    <>
      <AlertDialog
        isLoading={isLoading}
        onDialogConfirmed={handleDeleteGroup}
        isDialogOpen={isDialogOpen}
        toggleIsDialogOpen={handleToggleIsDialogOpen}
        title={alertDialogTitle}
        description={alertDialogDescription}
      />

      <DataTable
        customStyles={{
          headCells: {
            style: {
              display: "flex",
              justifyContent: "center"
            }
          },
          expanderButton: {
            style: {
              backgroundColor: "transparent",
              height: "100%",
              width: "100%",
              "&:hover:enabled": {
                cursor: "pointer"
              },

              "&:hover:not(:disabled)": {
                cursor: "pointer",
                backgroundColor: "transparent"
              },
              "&:focus": {
                outline: "none",
                backgroundColor: "transparent"
              },

              svg: {
                margin: "auto"
              }
            }
          }
        }}
        className="d-block rounded-xxl"
        columns={columns}
        data={fleetGroups}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        expandOnRowClicked
        expandableRowExpanded={(row) => row.id === latestExpandedGroup}
        onRowExpandToggled={handleRowExpandToggled}
        pagination
        highlightOnHover
        pointerOnHover
        responsive
        progressPending={isGroupsDataLoading}
        progressComponent={<LoadingSkeletonForTable />}
        noDataComponent={<FleetGroupsTablePlaceHolder />}
      />
    </>
  );
}
