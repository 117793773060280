import React, { ButtonHTMLAttributes } from "react";
import classNames from "classnames";

type ButtonProps = {
  color?: string;
  size?: string;
  outline?: boolean;
  disabled?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = ({ color, size, className, outline, disabled, ...props }) => {
  const buttonClass = classNames({
    btn: true,
    [`btn-${color}`]: !outline,
    [`btn-outline-${color}`]: outline,
    [`btn-${size}`]: size,
    disabled: disabled,
    [`${className}`]: className,
    "fs-17px": true,
  });

  return (
    <button className={buttonClass} disabled={disabled} {...props}>
      {props.children}
    </button>
  );
};

export default Button;
