import React from "react";
import FleetGroupsIcon from "./FleetGroupsIcon";

type Props = {};

function FleetGroupsMenuIconFilled({}: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0643 10.0012C13.1262 10.0012 14.1423 9.58136 14.8937 8.82993C15.6451 8.0785 16.0649 7.06247 16.0649 6.00059C16.0649 4.9387 15.6451 3.92268 14.8937 3.17125C14.1423 2.41981 13.1262 2 12.0643 2C11.0025 2 9.98644 2.41981 9.23501 3.17125C8.48357 3.92268 8.06376 4.9387 8.06376 6.00059C8.06376 7.06247 8.48357 8.0785 9.23501 8.82993C9.98644 9.58136 11.0025 10.0012 12.0643 10.0012ZM5.56251 13.0034C6.22575 13.0034 6.86076 12.7388 7.32997 12.2696C7.79917 11.8004 8.06376 11.1654 8.06376 10.5021C8.06376 9.8389 7.79917 9.20388 7.32997 8.73468C6.86076 8.26547 6.22575 8.00441 5.56251 8.00441C4.89927 8.00441 4.26426 8.269 3.79506 8.73468C3.32585 9.20388 3.06126 9.8389 3.06126 10.5021C3.06126 11.1654 3.32585 11.8004 3.79506 12.2696C4.26426 12.7388 4.89927 13.0034 5.56251 13.0034ZM21.0639 10.5021C21.0639 11.1654 20.7993 11.8004 20.3301 12.2696C19.8609 12.7388 19.2259 13.0034 18.5627 13.0034C17.8994 13.0034 17.2644 12.7388 16.7952 12.2696C16.326 11.8004 16.0614 11.1654 16.0614 10.5021C16.0614 9.8389 16.326 9.20388 16.7952 8.73468C17.2644 8.26547 17.8994 8.00441 18.5627 8.00441C19.2259 8.00441 19.8609 8.269 20.3301 8.73468C20.7993 9.20388 21.0639 9.8389 21.0639 10.5021ZM12.0643 11.0031C13.3908 11.0031 14.6608 11.5287 15.5993 12.4671C16.5377 13.4056 17.0633 14.6791 17.0633 16.0021V22.0029H7.06185V16.0021C7.06185 14.6756 7.5875 13.4056 8.52591 12.4671C9.46432 11.5287 10.7343 11.0031 12.0608 11.0031H12.0643ZM5.06508 16.0021C5.06508 15.3106 5.16386 14.6403 5.35437 14.0088L5.18503 14.0229C4.32776 14.1182 3.53399 14.5239 2.95895 15.166C2.38391 15.808 2.06641 16.6406 2.06641 17.5014V22.0029H5.06508V16.0021ZM22.0658 22.0029V17.5014C22.0658 16.6089 21.7271 15.7516 21.1133 15.1025C20.503 14.4533 19.6669 14.0617 18.7779 14.0088C18.9648 14.6403 19.0671 15.3106 19.0671 16.0021V22.0029H22.0658Z"
        fill="#0C0C0C"
      />
    </svg>
  );
}

export default FleetGroupsMenuIconFilled;
