import { InboxNotificationSettings } from "./settings.types";

export enum OrganizationProfileCommand {
  UPDATE_BUSINESS_NAME = "UPDATE_BUSINESS_NAME",
  UPDATE_INBOX_NOTIFICATION_SETTINGS = "UPDATE_INBOX_NOTIFICATION_SETTINGS",
}

export type UpdateOrganizationInboxNotificationSettingsPayload = {
  command: OrganizationProfileCommand.UPDATE_INBOX_NOTIFICATION_SETTINGS
  inboxSettings: InboxNotificationSettings
}

export type UpdateOrganizationBusinessNamePayload = {
  command: OrganizationProfileCommand.UPDATE_BUSINESS_NAME
  businessName: string
}

export type UpdateOrganizationPayload =
  UpdateOrganizationInboxNotificationSettingsPayload
  | UpdateOrganizationBusinessNamePayload

export enum UserSignUpApplicationStatus {
  PENDING_REVIEW = "PENDING_REVIEW",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}


