import { parsePhoneNumber } from "libphonenumber-js";
import { formatNumber } from "./charging.utils";

export function getMilesByKm(km: number) {
  if (!km) {
    return 0;
  }

  return km * 0.621371192;
}


export function formatMilesByKmForEnUs(km: number | string) {
  if (!km) {
    return 0;
  }
  const numberInKm = Number(km);

  return formatNumber(getMilesByKm(numberInKm));
}


export function getFormattedNumberForUsdByCent(cent: number | undefined | null) {
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format((cent ?? 0) / 100);
}


export const getFormattedCostByNetwork = (cent: number | undefined | null, network: string) => {
  const isUnknownNetwork = (network === "home" || network === "other");
  if (isUnknownNetwork && typeof cent !== "number") {
    // if cent is not a number return N/A
    return "N/A";
  }

  return getFormattedNumberForUsdByCent(cent);
};


export const getFormattedPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return "";
  }

  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);

  return parsedPhoneNumber.formatInternational();
};

export const getFormattedPhoneNumberNational = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return "";
  }

  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);

  return parsedPhoneNumber.formatNational();
};

export const formatPhoneNumberByDialCodeAndNumber = (dialCode: {
  label: string,
  value: string
}, phoneNumber: string) => {
  return dialCode?.value + phoneNumber;
};


export const getFullNameByFirstNameAndLastName = (firstName: string = "", lastName: string = "") => {
  return `${firstName} ${lastName}`;
};


export const mapStringifiedBooleansAsBooleans = (stringifiedBooleanList?: string[]) => {
  return stringifiedBooleanList?.map(sb => sb === "true") ?? [];
};


export const getFixedRate = (rate?: number) => {
  if (rate || rate === 0) {
    const formattedChargingLimit = getFormattedNumberForUsdByCent(rate);
    return { value: formattedChargingLimit, label: formattedChargingLimit + "/kWh" };
  }

  return { value: null, label: "Not set" };
};





