import React from "react";
import Col from "../../custom/Col";
import DriverDetailPageStatusCard from "../DriverDetailsPageStatusCard";
import DriverDetailsPagePersonalInformationCard from "../DriverDetailsPagePersonalInformationCard";
import DriverDetailsPageAddressCard from "../DriverDetailsPageAddressCard";
import DriverDetailsPageVehicleSetupCard from "../DriverDetailsPageVehicleSetupCard";
import DriverDetailsPageChargingCard from "../DriverDetailsPageChargingCard";
import Row from "../../custom/Row";
import { DriverHookStates } from "../../../types/driver.types";

type Props = {
  driverHook: DriverHookStates
}

const DriverDetailsAboutSection = ({ driverHook }: Props) => {
  return (
    <Row>
      <Col lg="6" className="pr-lg-2">
        <DriverDetailPageStatusCard {...driverHook} />

        <div className="mt-4">
          <DriverDetailsPagePersonalInformationCard {...driverHook} />
        </div>
        <div className="mt-4">
          <DriverDetailsPageAddressCard {...driverHook} />
        </div>
      </Col>
      <Col lg="6" className="pl-lg-2 mt-4 mt-lg-0">
        <DriverDetailsPageVehicleSetupCard {...driverHook} />
        <div className="mt-4">
          <DriverDetailsPageChargingCard {...driverHook} />
        </div>
      </Col>
    </Row>
  );
};

export default DriverDetailsAboutSection;