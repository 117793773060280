import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { Icon } from "../Component";
import ButtonWithIcon from "./ButtonWithIcon";

export default function AddDriversButton(props) {
  const widthClass = props.widthClass ?? "";

  /**
   * Render
   */
  return (
    <ButtonWithIcon
      className={`mt-md-0 ${widthClass}`}
      text={"Add Drivers"}
      onClick={props.onAddDriversClicked}
      {...props}
    />
  );
}
