import { CheckCircle } from "@mui/icons-material";
import { Snackbar } from "@mui/material";
import Card from "components/card/Card";
import Row from "components/custom/Row";
import InboxDangerIcon from "components/icon/icons/InboxDangerIcon";
import XIcon from "components/icon/icons/XIcon";
import { NeutralBodyText100 } from "components/text/Text";
import { useAlertManager } from "hooks/alert.hooks";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AlertType } from "types/alert.types";
import { AppColors } from "../../utils/colors.utils";
import HoverUnderlinedTextButton from "../button/HoverUnderlinedTextButton";
import WarningIcon from "../icon/icons/WarningIcon";

export default function AlertBox() {
  const { t } = useTranslation("common");

  const { isOpen, type, message, handleCloseAlert, actionText, callback: alertCallback } = useAlertManager();


  const isSuccessAndActionAlert = type === AlertType.SUCCESS_WITH_ACTION;
  const isSuccess = type === AlertType.SUCCESS || isSuccessAndActionAlert;
  const iconColor = isSuccess ? AppColors.positive400 : AppColors.cherry400;

  const alertContent = useMemo(() => {
    switch (type) {
      case AlertType.SUCCESS || AlertType.SUCCESS_WITH_ACTION:
        return {
          backgroundClass: "bg-positive-950",
          icon: <CheckCircle htmlColor={iconColor} />
        };

      case AlertType.ERROR:
        return {
          backgroundClass: "bg-cherry-1000",
          icon: <InboxDangerIcon />
        };

      case AlertType.WARNING:
        return {
          backgroundClass: "bg-orange-800",
          icon: <WarningIcon />
        };

      default:
        return {
          backgroundClass: "bg-positive-950",
          icon: <CheckCircle htmlColor={iconColor} />
        };

    }


  }, [iconColor, type]);


  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleCloseAlert}
    >
      <div>
        <Card
          padding="p-2"
          className={`d-flex flex-row align-items-center justify-between rounded-xxl  card-with-more-shadow ${
            alertContent.backgroundClass
          }`}
        >
          <Row className="align-items-center flex-nowrap">
            <div className="w-min-25px"> {alertContent.icon}</div>

            <div className="ml-1">
              <NeutralBodyText100> {message ?? t("error.unknown")}</NeutralBodyText100>
            </div>


            {isSuccessAndActionAlert && actionText &&
              <div className="ml-1">
                <HoverUnderlinedTextButton text={actionText}
                                           onClick={alertCallback} /></div>}
          </Row>
          <div className="clickable ml-4 w-min-25px" onClick={handleCloseAlert}>
            <XIcon fill={AppColors.neutral200} width="12" />
          </div>
        </Card>
      </div>
    </Snackbar>
  );
}
