import React from "react";
import Row from "../custom/Row";
import ToggleCheckbox from "../input/checkbox/ToggleCheckbox";
import MailOpenOutlineIcon from "../icon/icons/MailOpenOutlineIcon";
import VerticalLine from "../line/VerticalLine";
import { NeutralBodyMediumText100 } from "../text/Text";
import ArchiveOutlineIcon from "../icon/icons/ArchiveOutlineIcon";
import { InboxMessagesBulkActionHooks } from "../../types/inbox.types";

type Props = {
  inboxMessagesBulkActionHooks: InboxMessagesBulkActionHooks
}

const TableSelectableHeader = ({ inboxMessagesBulkActionHooks, ...props }: Props) => {
  const {
    handleArchiveInboxMessagesAsync,
    handleReadOrUnreadInboxMessagesAsync,
    isSubmitting,
    isClearable,
    isNextCommandRead,
    isAllSelected,
    handleSelectAllRows
  } = inboxMessagesBulkActionHooks;


  const isActionsDisabled = !isClearable || isSubmitting;
  return (
    <Row className="table-selectable-header mt-3 ">
      <>
        <Row className="align-items-center">
          <ToggleCheckbox {...props} isClearable={isClearable}
                          onToggle={inboxMessagesBulkActionHooks.handleToggleBulkCheckbox} />
          {!isAllSelected && <div onClick={handleSelectAllRows}>
            <NeutralBodyMediumText100 className={"ml-2 clickable"}>Select all</NeutralBodyMediumText100>
          </div>}
        </Row>


        {isClearable && <>
          <Row className={`ml-4 pl-2 center ${isActionsDisabled ? "disabled-action" : "clickable"}  `}
               onClick={isActionsDisabled ? () => {
               } : handleReadOrUnreadInboxMessagesAsync}>
            <MailOpenOutlineIcon />

            <NeutralBodyMediumText100
              className={"ml-2"}>{isNextCommandRead ? "Mark as read" : "Mark as unread"}</NeutralBodyMediumText100>
          </Row>

          <div className={"py-2"}>
            <VerticalLine />
          </div>


          <Row className={`center  ${isActionsDisabled ? "disabled-action" : "clickable"}`}
               onClick={isActionsDisabled ? () => {
               } : handleArchiveInboxMessagesAsync}>
            <ArchiveOutlineIcon />

            <NeutralBodyMediumText100 className={"ml-2"}>Archive</NeutralBodyMediumText100>
          </Row>
        </>}
      </>
    </Row>
  );
};

export default TableSelectableHeader;