import CancelButton from "components/button/CancelButton";
import PrimaryButton from "components/button/PrimaryButton";
import AlertConfirmDialog from "components/notification/AlertConfirmDialog";
import { useAlertManager } from "hooks/alert.hooks";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router";
import { updateVehicleTeslaAsync } from "services/vehicle.services";
import { TeslaCommand, TeslaDataAccessorHook } from "types/vehicle.types";
import { handleApiErrorResponse } from "utils";
import { getTeslaControlInfoDetails } from "../../../utils/vehicle.utils";
import RowBetweenBlock from "../../block/RowBetweenBlock";
import OutlinedButtonWithIcon from "../../button/OutlinedButtonWithIcon";
import VehicleDetailsVirtualKeyControlledButton from "./VehicleDetailsVirtualKeyControlledButton";

type Props = {
  teslaHook: TeslaDataAccessorHook;
  isReadonly: boolean
};

function VehicleDetailsRemoteStartEditor({ teslaHook, isReadonly }: Props) {
  const { isTeslaInfoLoading, refetchTeslaInfo } = teslaHook;


  const { vehicleId } = useParams<{ vehicleId: string }>();

  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleStartVehicleAsync = useCallback(async () => {
    try {
      setIsSubmitting(true);

      await updateVehicleTeslaAsync(vehicleId, {
        command: TeslaCommand.REMOTE_START
      });

      setIsConfirmDialogOpen(false);
      handleOpenSuccessAlert("Vehicle is started successfully!");
      refetchTeslaInfo();
    } catch (error) {
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  }, [handleOpenErrorAlert, handleOpenSuccessAlert, refetchTeslaInfo, vehicleId]);


  const { isRemoteStartVisible } = getTeslaControlInfoDetails(teslaHook);


  if (!isRemoteStartVisible && !isTeslaInfoLoading) {
    return <></>;
  }

  return (
    <>
      <div className="horizontal-border-line my-2 w-100" />
      <AlertConfirmDialog
        isDialogOpen={isConfirmDialogOpen}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setIsConfirmDialogOpen(false)} />
            </div>

            <PrimaryButton
              type="button"
              text={"Start Vehicle"}
              isLoading={isSubmitting}
              onClick={handleStartVehicleAsync}
            />
          </>
        }
        toggleIsDialogOpen={() => setIsConfirmDialogOpen(false)}
        title={`Are you sure you want to start this vehicle?`}
      />

      <RowBetweenBlock
        leftText={"Start vehicle"}
        rightOverwrite={<VehicleDetailsVirtualKeyControlledButton
          isVirtualKeyNeeded={isReadonly}
          type="button"
          text={"Start vehicle"}
          isLoading={isSubmitting}
          onClick={() => setIsConfirmDialogOpen(true)}
        />
        }
      />
    </>
  );
}

export default VehicleDetailsRemoteStartEditor;
