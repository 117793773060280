import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { useTranslation } from "react-i18next";
import ChargingSessionsDataTable from "../../components/charging-sessions-components/ChargingSessionsDataTable";
import ChargingSessionsStats from "../../components/charging-sessions-components/ChargingSessionsStats";
import PageHeader from "components/header/PageHeader";
import OutlinedButton from "../../components/button/OutlinedButton";
import { useHistory } from "react-router";
import { useFeatureFlags } from "../../hooks/feature-flag.hooks";

const ChargingSessionsPage = () => {
  const { t } = useTranslation("common");
  const { isTeslaInvoiceListingEnabled } = useFeatureFlags();

  const history = useHistory();
  return (
    <React.Fragment>
      <Head title={t("main_menu.chargingSessions")} />
      <Content className="h-auto">
        <PageHeader title={t("charging_sessions.title")} description={t("charging_sessions.description")}
                    rightElement={isTeslaInvoiceListingEnabled ?
                      <OutlinedButton onClick={() => history.push("/tesla/invoices")}
                                      text={"View Supercharger Invoices"} /> : <></>}
        />

        <ChargingSessionsStats />

        <ChargingSessionsDataTable />
      </Content>
    </React.Fragment>
  );
};

export default ChargingSessionsPage;
