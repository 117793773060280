// React
import { Suspense, useLayoutEffect } from "react";
import { Route, Switch } from "react-router-dom";

// Utils & Hooks
import { useLocation } from "react-router";

// Screens && Components
import ChargingSessionDetailsPage from "../pages/charging-sessions/ChargingSessionDetailsPage";
import ChargingSessionsPage from "../pages/charging-sessions/ChargingSessionsPage";
import DashboardPage from "../pages/dashboard/DashboardPage";
import AddDriverPage from "../pages/driver/AddDriverPage";
import Error404Modern from "../pages/error/404-modern";
import AddFleetGroupPage from "../pages/fleet-groups/AddFleetGroupPage";
import EditFleetGroupPage from "../pages/fleet-groups/EditFleetGroupPage";
import FleetGroupsPage from "../pages/fleet-groups/FleetGroupsPage";
import EditVehicle from "../pages/vehicles/EditVehiclePage";
import VehiclesPage from "../pages/vehicles/VehiclesPage";

import LogoutPage from "pages/auth/LogoutPage";
import DriverSupportNavigator from "pages/driver-support/DriverSupportNavigator";
import InboxPage from "pages/inbox/InboxPage";
import SettingsPage from "pages/settings/SettingsPage";
import AddDriverCompletedPage from "../pages/driver/AddDriverCompletedPage";
import AssignDriverFormCompletedPage from "../pages/driver/AssignDriverFormCompletedPage";
import AssignDriverPage from "../pages/driver/AssignDriverPage";
import BulkUploadDriversPage from "../pages/driver/BulkUploadDriversPage";
import AddVehicleManualPage from "../pages/vehicles/AddVehicleManualPage";
import AddVehiclePage from "../pages/vehicles/AddVehiclePage";
import AddVehicleSmartcarPage from "../pages/vehicles/AddVehicleSmartcarPage";
import SmartCarExchangePage from "../pages/vehicles/SmartCarExchangePage";
import VehicleDetailsPage from "../pages/vehicles/VehicleDetailsPage";
import VehicleFormCompletedPage from "../pages/vehicles/VehicleFormCompletedPage";
import VehicleLocatorPage from "../pages/vehicles/VehicleLocatorPage";

import InvoiceDetailsPage from "pages/invoice/InvoiceDetailsPage";
import InvoicesPage from "pages/invoice/InvoicesPage";


//Css
import DriverSelfSignupApprovalPage from "pages/driver/DriverSelfSignupApprovalPage";
import AddVehicleTeslaPage from "pages/vehicles/AddVehicleTeslaPage";
import TeslaExchangePage from "pages/vehicles/TeslaExchangePage";
import TeslaRedirectionPage from "pages/vehicles/TeslaRedirectionPage";
import { ErrorBoundary } from "react-error-boundary";
import "react-loading-skeleton/dist/skeleton.css";
import { handleLogsByEnv } from "utils/log.utils";
import ReimbursementsPage from "../pages/reimbursement/ReimbursementsPage";
import AddVehicleGeotabPage from "../pages/vehicles/AddVehicleGeotabPage";
import TeslaInvoicesPage from "../pages/vehicles/TeslaInvoicesPage";
import DriverRouter from "../pages/driver/DriverRouter";


const Pages = () => {
  const { pathname } = useLocation();

  handleLogsByEnv();

  useLayoutEffect(() => {
    window?.scrollTo(0, 0);
  }, [pathname]);


  return (
    <ErrorBoundary fallback={<></>}>
      <Suspense fallback={<></>}>

        <Switch>
          <Route path={`${process.env.PUBLIC_URL}/settings`} component={SettingsPage} />

          <Route path={`${process.env.PUBLIC_URL}/inbox`} component={InboxPage} />

          <Route exact path={`${process.env.PUBLIC_URL}/invoices`} component={InvoicesPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/invoices/:invoiceId`} component={InvoiceDetailsPage} />

          {/** Charging Sessions routes */}
          <Route exact path={`${process.env.PUBLIC_URL}/charging-sessions`} component={ChargingSessionsPage} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/charging-sessions/:chargingSessionId`}
            component={ChargingSessionDetailsPage}
          />


          {/** Reimbursement routes */}
          <Route exact path={`${process.env.PUBLIC_URL}/reimbursements`} component={ReimbursementsPage} />


          <Route path={`${process.env.PUBLIC_URL}/driver-support`} component={DriverSupportNavigator} />

          {/** Fleet Groups*/}
          <Route exact path={`${process.env.PUBLIC_URL}/fleet-groups`} component={FleetGroupsPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/add-fleet-group`} component={AddFleetGroupPage} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/fleet-groups/:fleetGroupId`}
            component={EditFleetGroupPage}
          />

          {/** Driver routes */}
          <Route exact path={`${process.env.PUBLIC_URL}/assign-driver/:vehicleId`} component={AssignDriverPage} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/assign-driver-completed/:operation`}
            component={AssignDriverFormCompletedPage}
          />

          <Route exact path={`${process.env.PUBLIC_URL}/add-driver`} component={AddDriverPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/add-driver/completed`} component={AddDriverCompletedPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/bulk-upload-drivers`} component={BulkUploadDriversPage} />


          <Route path={`${process.env.PUBLIC_URL}/drivers`} component={DriverRouter} />

          <Route exact path={`${process.env.PUBLIC_URL}/approve-driver/:driverId`}
                 component={DriverSelfSignupApprovalPage} />

          {/** Vehicle routes */}
          <Route exact path={`${process.env.PUBLIC_URL}/add-vehicle`} component={AddVehiclePage} />
          <Route exact path={`${process.env.PUBLIC_URL}/add-vehicle/smartcar`} component={AddVehicleSmartcarPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/add-vehicle/manual`} component={AddVehicleManualPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/add-vehicle/tesla`} component={AddVehicleTeslaPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/add-vehicle/geotab`} component={AddVehicleGeotabPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/add-vehicle/completed`} component={VehicleFormCompletedPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/vehicles`} component={VehiclesPage} />
          <Route path={`${process.env.PUBLIC_URL}/vehicles/:vehicleId`} component={VehicleDetailsPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/vehicle-locator`} component={VehicleLocatorPage} />


          <Route exact path={`${process.env.PUBLIC_URL}/tesla/auth`} component={TeslaRedirectionPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/tesla/invoices`} component={TeslaInvoicesPage} />
          <Route exact path={`${process.env.PUBLIC_URL}/tesla/exchange`} component={TeslaExchangePage} />
          <Route exact path={`${process.env.PUBLIC_URL}/smartcar/exchange`} component={SmartCarExchangePage} />
          <Route exact path={`${process.env.PUBLIC_URL}/edit-vehicle/:vehicleId`} component={EditVehicle} />

          <Route exact path={`${process.env.PUBLIC_URL}/`} component={DashboardPage} />


          <Route path={`${process.env.PUBLIC_URL}/logout`} component={LogoutPage} />
          <Route component={Error404Modern} />
        </Switch>

      </Suspense>
    </ErrorBoundary>
  );
};
export default Pages;
