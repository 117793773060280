import React from "react";

type Props = { onClick: () => {}; className?: string };

function NavbarToggleIcon({ onClick, className }: Props) {
  return (
    <svg
      onClick={onClick}
      className={`cursor-pointer ${className ?? ""}`}
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3083_7166)">
        <path
          d="M18.6667 2C19.5169 1.99995 20.335 2.32481 20.9536 2.90809C21.5722 3.49138 21.9446 4.28901 21.9944 5.13778L22 5.33333V18.6667C22 19.5169 21.6752 20.335 21.0919 20.9536C20.5086 21.5722 19.711 21.9446 18.8622 21.9944L18.6667 22H5.33333C4.4831 22 3.66498 21.6752 3.04638 21.0919C2.42777 20.5086 2.05544 19.711 2.00556 18.8622L2 18.6667V5.33333C1.99995 4.4831 2.32481 3.66498 2.90809 3.04638C3.49138 2.42777 4.28901 2.05544 5.13778 2.00556L5.33333 2H18.6667ZM18.6667 4.22222H8.66667V19.7778H18.6667C18.9388 19.7777 19.2015 19.6778 19.4049 19.497C19.6082 19.3161 19.7382 19.0669 19.77 18.7967L19.7778 18.6667V5.33333C19.7777 5.06119 19.6778 4.79851 19.497 4.59514C19.3161 4.39177 19.0669 4.26184 18.7967 4.23L18.6667 4.22222ZM16.1189 8.99222C16.3102 9.18355 16.4251 9.4381 16.4421 9.70813C16.4591 9.97816 16.3769 10.2451 16.2111 10.4589L16.1189 10.5633L14.6833 12L16.1189 13.4367C16.3102 13.628 16.4251 13.8825 16.4421 14.1526C16.4591 14.4226 16.3769 14.6896 16.2111 14.9033L16.1189 15.0078C15.9276 15.1991 15.673 15.314 15.403 15.331C15.133 15.348 14.866 15.2658 14.6522 15.1L14.5478 15.0078L12.3256 12.7856C12.1342 12.5942 12.0193 12.3397 12.0023 12.0696C11.9854 11.7996 12.0675 11.5327 12.2333 11.3189L12.3256 11.2144L14.5478 8.99222C14.7561 8.78392 15.0387 8.6669 15.3333 8.6669C15.628 8.6669 15.9105 8.78392 16.1189 8.99222Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3083_7166">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default NavbarToggleIcon;
