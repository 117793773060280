import React from "react";
import FleetGroupTablePlaceHolderImage from "../../images/fleet-groups-table-placeholder-image.png";
import Card from "../card/Card";
import ChargingSessionTablePlaceholderIcon from "../icon/icons/ChargingSessionTablePlaceholderIcon";
import { BlackBodyText300, BlackSubtitleText100 } from "../text/Text";

type Props = {
  readonly title?: string;
  readonly description?: string | null;
  readonly button?: JSX.Element;
};

export default function ChargingDataTablePlaceholder({ title, description, button }: Props) {
  return (
    <Card className="d-flex flex-1 flex-column justify-center align-center mt-3 h-400px text-center ">
      <div className="  mb-3">
        <ChargingSessionTablePlaceholderIcon />
      </div>
      {title && <BlackSubtitleText100 className="mt-2 px-4 text-center">{title}</BlackSubtitleText100>}
      {description && <BlackBodyText300>{description}</BlackBodyText300>}
      {button && <div className="mt-4">{button}</div>}
    </Card>
  );
}
