type Props = {
  fill?: string;
  className?: string;
};

function TeslaIcon({ fill = "#B32626", className }: Props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.0016 5.24347L13.1496 2.61808C13.1496 2.61808 16.8287 2.69614 20.4949 4.40043C19.5563 5.8198 17.6932 6.51453 17.6932 6.51453C17.5677 5.26754 16.6941 4.96311 13.9158 4.96311L11.0023 21.4082L8.06856 4.95985C5.31047 4.95985 4.43686 5.26493 4.31131 6.51128C4.31131 6.51128 2.44765 5.82371 1.50964 4.40368C5.17517 2.69939 8.855 2.62133 8.855 2.62133L11.0029 5.24347H10.9997H11.0016ZM11.0016 1.86221C13.9627 1.83489 17.3543 2.3195 20.8267 3.8371C21.2911 2.99667 21.4095 2.62784 21.4095 2.62784C17.6152 1.12325 14.0609 0.605457 11.0016 0.591797C7.94236 0.605457 4.38807 1.12065 0.59375 2.62784C0.59375 2.62784 0.762878 3.08513 1.17659 3.84101C4.64957 2.31625 8.04059 1.83554 11.0016 1.85896V1.86221Z"
        fill={fill}
      />
    </svg>
  );
}

export default TeslaIcon;
