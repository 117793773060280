import byteSize from "byte-size";

/**
 *
 * @param {String} email -  The email
 * @returns {boolean} if it is email it will return true.
 */
export const checkEmail = (email) => {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
};

export const byteDisplay = (byte) => {
  const byteValue = byte ?? 0;
  const convertedSize = byteSize(byteValue);
  return `${convertedSize?.value}${convertedSize?.unit}`;
};
