import React from "react";
import { SvgProps } from "types/svg.types";
import { AppColors } from "../../../utils/colors.utils";

const TrendUpIcon = ({ fill, width, height }: SvgProps) => {
  const fillValue = fill ?? "#680031";
  const widthValue = width ?? 16;
  const heightValue = height ?? 18;

  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill={AppColors.positive950} />
      <path
        d="M15 7.5L16.7175 9.2175L13.0575 12.8775L10.0575 9.8775L4.5 15.4425L5.5575 16.5L10.0575 12L13.0575 15L17.7825 10.2825L19.5 12V7.5H15Z"
        fill={AppColors.positive300}
      />
    </svg>
  );
};
export default TrendUpIcon;

/**
 *   width={`${widthValue}`}
 height={`${heightValue}`}
 viewBox={`0 0 ${widthValue} ${heightValue}
 */
