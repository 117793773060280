import React from "react";
import { Modal, ModalBody } from "reactstrap";
import XIcon from "../icon/icons/XIcon";
import DriverFormResponsibilityModalContent from "./DriverFormResponsibilityModalContent";

export default function DriverFormResponsibilityModal({ isModalOpen, setIsModalOpen }) {
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(!isModalOpen)}
      className="modal-dialog-centered  overflow-hidden "
    >
      <ModalBody className="w-md-100 h-md-75  h-lg-50  h-max-100 p-0 max-w-90  overflow-hidden position-relative">
        <div onClick={() => setIsModalOpen(false)} className="modal-close-button">
          <XIcon />
        </div>

        <DriverFormResponsibilityModalContent />
      </ModalBody>
    </Modal>
  );
}
