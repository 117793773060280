import OverviewCard from "components/dashboard-components/DashboardOverviewCard";
import { useDashboardOverviews } from "hooks/data/dashboard-data-accessor.hooks";
import { getFormattedNumberForUsdByCent } from "utils/format.utils";

export default function TotalCostCard() {
  const { overviews, isLoading: isOverviewsLoading } = useDashboardOverviews();

  return (
    <OverviewCard
      total={getFormattedNumberForUsdByCent(overviews?.totalCostOverview?.total)}
      percent={overviews?.totalCostOverview?.monthlyTrend?.percentageChange}
      isLoading={isOverviewsLoading}
      title={"Total Cost"}
    />
  );
}
