import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Driver, DriverFormStatus, DriverFormStep, DriverSliceState } from "types/driver.types";
import {
  bulkUploadDriversNonFormState,
  initialDriverFormState,
  initialDriverNonFormState,
  manualDriverNonFormState,
  selfSignupDriverNonFormState
} from "utils/driver.utils";

const initialState: DriverSliceState = {
  isDriversLoading: false,
  driver: undefined,
  drivers: [],
  meta: null,
  driverNonFormState: initialDriverNonFormState,
  driverFormState: initialDriverFormState
};

export const driverSlice = createSlice({
  name: "driver",
  initialState: initialState,
  reducers: {
    updateDriverFormState: (state, action) => {
      state.driverFormState = { ...state.driverFormState, ...action.payload };
    },
    incrementDriverNonFormStep: (state) => {
      state.driverNonFormState.currentStep += 1;
    },
    decrementDriverNonFormStep: (state) => {
      state.driverNonFormState.currentStep -= 1;
    },

    forwardDriverFormVehicleSetupStep: (state) => {
      state.driverNonFormState.currentStep = DriverFormStep.VEHICLE;
    },


    forwardDriverFormReimbursementSetupStep: (state) => {
      state.driverNonFormState.currentStep = DriverFormStep.REIMBURSEMENT;
    },

    setDriverFormSuccessMessageHelper: (state, action: PayloadAction<string>) => {
      state.driverNonFormState.successMessageHelper.driverName = action.payload;
    },

    setDriver: (state, action: PayloadAction<Driver>) => {
      state.driver = action.payload;
    },
    setDriversAndMeta: (state, action) => {
      state.drivers = action.payload.drivers;
      state.meta = action.payload.meta;
    },
    setIsDriversLoading: (state, action) => {
      state.isDriversLoading = action.payload;
    },

    setDriverNonFormStateAsCompleted: (state) => {
      state.driverNonFormState.status = DriverFormStatus.COMPLETED;
    },
    setBulkUploadDriverNonFormStateAsCompleted: (state, action) => {
      state.driverNonFormState.successMessageHelper.driversCount = action.payload.driversCount;
      state.driverNonFormState.status = DriverFormStatus.COMPLETED;
    },
    setDriverNonFormError: (state, action) => {
      state.driverNonFormState.errorMessage = action.payload.errorMessage;
      state.driverNonFormState.isError = action.payload.isError;
    },
    clearDriverNonFormError: (state) => {
      state.driverNonFormState.errorMessage = "";
      state.driverNonFormState.isError = false;
    },
    startDriverNonFormStateAsManual: (state) => {
      state.driverNonFormState = manualDriverNonFormState;
    },
    startDriverNonFormStateAsSelfSignup: (state) => {
      state.driverNonFormState = selfSignupDriverNonFormState;
    },
    startDriverNonFormStateAsBulkMode: (state) => {
      state.driverNonFormState = bulkUploadDriversNonFormState;
    },
    resetDriverForm: (state) => {
      state.driverNonFormState = initialDriverNonFormState;
      state.driverFormState = initialDriverFormState;
    },
    resetDriverFormState: (state) => {
      state.driverFormState = initialDriverFormState;
    }
  }
});

export const {
  decrementDriverNonFormStep,
  startDriverNonFormStateAsManual,
  startDriverNonFormStateAsSelfSignup,
  setDriverNonFormError,
  forwardDriverFormVehicleSetupStep,
  forwardDriverFormReimbursementSetupStep,
  incrementDriverNonFormStep,
  resetDriverForm,
  clearDriverNonFormError,
  updateDriverFormState,
  setBulkUploadDriverNonFormStateAsCompleted,
  setDriverNonFormStateAsCompleted,
  startDriverNonFormStateAsBulkMode,
  setDriver,
  setDriversAndMeta,
  setIsDriversLoading,
  resetDriverFormState,
  setDriverFormSuccessMessageHelper
} = driverSlice.actions;
export default driverSlice.reducer;
