// React

// Utils & Hooks

// Store

// Components

import Card from "../card/Card";
import Col from "../custom/Col";
import Row from "../custom/Row";
import RightChevron from "../icon/icons/RightChevron";
import { BlackBodyText300, BlackH5Text0 } from "../text/Text";

type Props = {
  readonly onClick?: () => void;
  readonly icon?: JSX.Element;
  readonly content?: JSX.Element;
  readonly title: string;
  readonly description: string;
  readonly className?: string;
  readonly widthClassName?:string
  readonly padding?:string
};

export default function SelectionCard({ onClick, icon, content, title, description, className,widthClassName='w-800px',padding='p-4' }: Props) {
  return (
    <Card
      onClick={onClick}
      padding={padding}
      className={`d-flex  flex-md-row   align-items-center ${widthClassName}   w-max-100 my-2 rounded-24 clickable hover-light ${
        className ?? ""
      }`}
    >
      <div className="mr-4 mb-4 mb-md-0">{icon}</div>
      <Row className="align-items-center justify-between w-90">
        <Col className="pr-4 w-90">
          {content ?? (
            <>
              <BlackH5Text0>{title}</BlackH5Text0>
              <BlackBodyText300 className="mt-1">{description}</BlackBodyText300>
            </>
          )}
        </Col>
        <RightChevron widthValue={15} heightValue={20} />
      </Row>
    </Card>
  );
}
