import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import vehicleReducer from "./slices/vehicleSlice";
import chargingSessionsReducer from "./slices/chargingSessionsSlice";
import fleetGroupsReducer from "./slices/fleetGroupsSlice";
import dashboardReducer from "./slices/dashboardSlice";
import driverReducer from "./slices/driverSlice";
import alertReducer from './slices/alertSlice'
import inboxReducer from './slices/inboxSlice'

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { UserState } from "types/user.types";
import { VehicleSliceState } from "types/vehicle.types";
import { DashboardSliceState } from "types/dashboard.types";
import { FleetGroupSliceState } from "types/fleet-group.types";
import { AlertSliceState } from "types/alert.types";
import { InboxSliceState } from "types/inbox.types";
import { DriverSliceState } from "types/driver.types";


export type RootState = {
  user: UserState,
  vehicle: VehicleSliceState,
  dashboard: DashboardSliceState,
  fleetGroups: FleetGroupSliceState,
  alert: AlertSliceState,
  inbox: InboxSliceState,
  driver: DriverSliceState,

  // TODO: Set proper type annotations.
  chargingSessions: any,
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const reducers = combineReducers<RootState>({
  user: userReducer,
  vehicle: vehicleReducer,
  driver: driverReducer,
  chargingSessions: chargingSessionsReducer,
  fleetGroups: fleetGroupsReducer,
  dashboard: dashboardReducer,
  alert: alertReducer,
  inbox: inboxReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);


export type AppDispatch = typeof store.dispatch;