import Row from "components/custom/Row";
import { useHistory } from "react-router";
import { Driver } from "types/driver.types";
import { getDriverFullName } from "utils/driver.utils";

type Props = {
  drivers: Driver[];
  isForwardActive?: boolean;
};

function DriverList({ drivers, isForwardActive }: Props) {
  const history = useHistory();

  const handleForward = (d: Driver) => {
    if (!isForwardActive) {
      return;
    }
    history.push(`drivers/${d.id}`);
  };

  return (
    <Row className="w-100 h-auto align-items-center py-2">
      {drivers?.map((d, ix) => (
        <span key={ix} onClick={() => handleForward(d)} className="list-badge">
          {getDriverFullName(d)}
        </span>
      ))}
    </Row>
  );
}

export default DriverList;
