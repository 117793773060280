import { getDriverFullName } from "./driver.utils";
import { getFixedRate, getFormattedNumberForUsdByCent } from "./format.utils";
import { getFormattedNumberForEnergy } from "./charging.utils";
import { getLocalFormattedDateAsDateShort } from "./date.utils";
import {
  LatLng,
  Reimbursement,
  ReimbursementPaymentState,
  ReimbursementRectangleBound
} from "../types/reimbursement.types";
import { DriverLocationPayload } from "../types/driver.types";
import { AppColors } from "./colors.utils";
import { DateTime } from "luxon";

export const getChargingReimbursementRowForCsv = (reimbursement: Reimbursement) => {
  // We will add vehicle details as separated columns.

  return {
    driver: getDriverFullName(reimbursement.user),
    status: reimbursement.paymentState,
    reimbursement: getFormattedNumberForUsdByCent(reimbursement?.netAmount),
    month: reimbursement?.period,
    fixedRate: getFixedRate(reimbursement?.payoutRate)?.label,
    totalKWh: `${getFormattedNumberForEnergy(reimbursement?.energyDelivered)} kWh`,
    date: getLocalFormattedDateAsDateShort(reimbursement?.createdAt)
  };
};


export const getFormattedReimbursementLocation = (rectangleBounds: ReimbursementRectangleBound): DriverLocationPayload => {
  return {
    "name": "Home",
    "polygon": {
      "type": "Polygon",
      "coordinates": [
        [
          [rectangleBounds.west, rectangleBounds.north], // Top-left
          [rectangleBounds.east, rectangleBounds.north], // Top-right
          [rectangleBounds.east, rectangleBounds.south], // Bottom-right
          [rectangleBounds.west, rectangleBounds.south], // Bottom-left
          [rectangleBounds.west, rectangleBounds.north]  // Close the loop back to top-left
        ]
      ]
    }
  };
};


export const getRectangleBoundsAndCenter = (coordinates?: number[][][]): {
  bounds: ReimbursementRectangleBound;
  center: LatLng
} | undefined => {

  if (!coordinates) {
    return;
  }

  if (coordinates && (coordinates?.length === 0 || coordinates[0]?.length === 0)) {
    return;
  }

  // Flatten the coordinates array
  const flatCoordinates = coordinates[0];

  const latitudes = flatCoordinates.map(coord => coord[1]);
  const longitudes = flatCoordinates.map(coord => coord[0]);

  const north = Math.max(...latitudes);
  const south = Math.min(...latitudes);
  const east = Math.max(...longitudes);
  const west = Math.min(...longitudes);

  const bounds: ReimbursementRectangleBound = { north, south, east, west };

  const center: LatLng = {
    lat: (north + south) / 2,
    lng: (east + west) / 2
  };

  return { bounds, center };
};


export const calculatePolygonCenter = (path: LatLng[]): LatLng => {
  const latSum = path.reduce((sum, point) => sum + point.lat, 0);
  const lngSum = path.reduce((sum, point) => sum + point.lng, 0);
  return {
    lat: latSum / path.length,
    lng: lngSum / path.length
  };
};

export const getSessionStatisticDataForGraphAsWeekOfLastQuarter = (
  statisticsByPeriod: { period: string, totalEnergy: number, totalCost: number }[]
) => {
  const data = statisticsByPeriod.map(({ totalCost }) => totalCost / 100);

  console.log("data", data);
  return [{
    data,
    label: "Total Cost",
    backgroundColor: AppColors.blue500,
    borderColor: AppColors.blue500,
    borderWidth: 1
  }];
};


export const getMonthLabelForStatisticGraphByStatisticData = (
  statisticsByPeriod: { period: string, totalEnergy: number, totalCost: number }[]
) => {
  const uniquePeriods = getUniquePeriodsFromStatistics(statisticsByPeriod);
  const monthPeriods = uniquePeriods.map((p) => getMonthNameFromYearMonthFormat(p));
  const uniqueMonthsSet = new Set<string>();
  monthPeriods.forEach((m) => {
    uniqueMonthsSet.add(m);
  });
  return { monthlyLabels: Array.from(uniqueMonthsSet), uniquePeriods };
};

export const getMonthNameFromYearMonthFormat = (yearMonth: string) => {
  const [year, month] = yearMonth.split("-").map(Number);
  const date = DateTime.fromObject({ year, month });
  const monthName = date.monthLong;
  return monthName ?? "-";
};

// Helper function to get unique periods
export const getUniquePeriodsFromStatistics = (statisticsByPeriod: { period: string }[]) => {
  const periodsSet = new Set(statisticsByPeriod.map(({ period }) => period));
  return Array.from(periodsSet);
};


export const getReimbursementPaymentStateOptions = () => {
  return [
    { label: "Paid", value: ReimbursementPaymentState.PAID, textClass: "success-text" },
    { label: "Unpaid", value: ReimbursementPaymentState.UNPAID, textClass: "text-cherry-100" },
    { label: "Canceled", value: ReimbursementPaymentState.VOID, textClass: "text-canceled" }
  ];
};