import React from "react";
import { SvgProps } from "../../../types/svg.types";

const ExportIcon = (props:SvgProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.75 15.75H19.6275L17.6925 17.6925L18.75 18.75L22.5 15L18.75 11.25L17.6925 12.3075L19.6275 14.25H9.75V15.75Z"
        fill="#0C0C0C"
      />
      <path
        d="M16.5 10.5V7.50001C16.5006 7.40131 16.4817 7.30346 16.4443 7.21208C16.407 7.1207 16.352 7.03759 16.2825 6.96751L11.0325 1.71751C10.9624 1.648 10.8793 1.59301 10.7879 1.55568C10.6966 1.51836 10.5987 1.49944 10.5 1.50001H3C2.60218 1.50001 2.22064 1.65805 1.93934 1.93935C1.65804 2.22066 1.5 2.60219 1.5 3.00001V21C1.5 21.3978 1.65804 21.7794 1.93934 22.0607C2.22064 22.342 2.60218 22.5 3 22.5H15C15.3978 22.5 15.7794 22.342 16.0607 22.0607C16.342 21.7794 16.5 21.3978 16.5 21V19.5H15V21H3V3.00001H9V7.50001C9 7.89784 9.15804 8.27937 9.43934 8.56067C9.72064 8.84198 10.1022 9.00001 10.5 9.00001H15V10.5H16.5ZM10.5 7.50001V3.30751L14.6925 7.50001H10.5Z"
        fill="#0C0C0C"
      />
    </svg>
  );
};
export default ExportIcon;

/**
 *   width={`${widthValue}`}
      height={`${heightValue}`}
      viewBox={`0 0 ${widthValue} ${heightValue} `}
 */
