import React, { useCallback, useState } from "react";
import EditorModal from "../modal/EditorModal";
import { NeutralBodyText300, NeutralTitleMediumTextBlack } from "../text/Text";
import HorizontalLine from "../line/HorizontalLine";
import ReimbursementMap from "../reimbursement/ReimbursementMap";
import { handleApiErrorResponse } from "../../utils";
import { useDriverDetailsReimbursementStates } from "../../hooks/driver.hooks";
import { useAlertManager } from "../../hooks/alert.hooks";
import { DriverHookStates } from "../../types/driver.types";
import { useForm } from "react-hook-form";
import { ReimbursementFormState } from "../../types/reimbursement.types";


type Props = {
  isMapEditorModalOpen: boolean
  setIsMapEditorModalOpen: (p: boolean) => void
  driverHook: DriverHookStates
}

const DriverDetailsPageReimbursementOnlyMapEditor = ({
                                                       driverHook,
                                                       isMapEditorModalOpen,
                                                       setIsMapEditorModalOpen
                                                     }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const driverId = driverHook.driver?.id;

  const { handleSubmit } = useForm<ReimbursementFormState>({
    mode: "all"
  });


  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();
  const driverFormReimbursementStepStates = useDriverDetailsReimbursementStates(driverHook);


  const handleSubmitLocation = useCallback(async () => {




    try {
      setIsSubmitting(true);
      await driverFormReimbursementStepStates.handleSubmitUpdateReimbursementLocationCallback(driverId);
      handleOpenSuccessAlert("Reimbursement locations saved successfully!");

      driverHook.refetchDriver();
      setIsMapEditorModalOpen(false);
    } catch (err) {
      handleOpenErrorAlert(handleApiErrorResponse(err));
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  }, [driverFormReimbursementStepStates, driverHook, driverId, handleOpenErrorAlert, handleOpenSuccessAlert, setIsMapEditorModalOpen]);

  return (
    <EditorModal
      modalSize="wide-sm"
      setIsModalOpen={setIsMapEditorModalOpen}
      isModalOpen={isMapEditorModalOpen}
      onSubmit={handleSubmit(handleSubmitLocation)}
      isLoading={isSubmitting}
    >
      <NeutralTitleMediumTextBlack>Home Charging Reimbursement Status</NeutralTitleMediumTextBlack>
      <NeutralBodyText300 className={"mb-3"}>Include driver for home charging reimbursement
        process.</NeutralBodyText300>
      <HorizontalLine />

      <ReimbursementMap {...driverFormReimbursementStepStates} mapId={"modal-map-container"} />
    </EditorModal>
  );
};

export default DriverDetailsPageReimbursementOnlyMapEditor;