import React from "react";
import { SvgProps } from "types/svg.types";

type Props = {};

const ThreeDotWithCircleBackgroundIcon = ({ fill = "#E5FC54" }: SvgProps) => {
  return (
    <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="48.5015" cy="48.7939" r="48.5" fill={fill} />
      <path
        d="M61.2474 65.1757C62.5185 65.1757 63.6026 64.7458 64.4998 63.886C65.397 62.9888 65.8456 61.8673 65.8456 60.5215C65.8456 59.1009 65.397 57.9794 64.4998 57.157C63.6026 56.2971 62.5185 55.8672 61.2474 55.8672C59.9764 55.8672 58.8923 56.2971 57.9951 57.157C57.0979 57.9794 56.6493 59.1009 56.6493 60.5215C56.6493 61.8673 57.0979 62.9888 57.9951 63.886C58.8923 64.7458 59.9764 65.1757 61.2474 65.1757ZM47.5572 65.1757C48.8282 65.1757 49.9123 64.7458 50.8095 63.886C51.7067 62.9888 52.1553 61.8673 52.1553 60.5215C52.1553 59.1009 51.7067 57.9794 50.8095 57.157C49.9123 56.2971 48.8282 55.8672 47.5572 55.8672C46.2861 55.8672 45.202 56.2971 44.3048 57.157C43.4076 57.9794 42.959 59.1009 42.959 60.5215C42.959 61.8673 43.4076 62.9888 44.3048 63.886C45.202 64.7458 46.2861 65.1757 47.5572 65.1757ZM33.8669 65.1757C35.1379 65.1757 36.2221 64.7458 37.1193 63.886C38.0165 62.9888 38.4651 61.8673 38.4651 60.5215C38.4651 59.1009 38.0165 57.9794 37.1193 57.157C36.2221 56.2971 35.1379 55.8672 33.8669 55.8672C32.5959 55.8672 31.5117 56.2971 30.6145 57.157C29.7173 57.9794 29.2687 59.1009 29.2687 60.5215C29.2687 61.8673 29.7173 62.9888 30.6145 63.886C31.5117 64.7458 32.5959 65.1757 33.8669 65.1757Z"
        fill="#013B3F"
      />
    </svg>
  );
};

export default ThreeDotWithCircleBackgroundIcon;
