import NotificationBadge from "components/badge/NotificationBadge";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import SendbirdDataProvider from "wrappers/SendBirdProvider";
import { useSendbirdSdkAccessor } from "../../hooks/driver-support.hooks";
import { isEmpty } from "../../utils/Utils";

function DriverSupportMenuItemNotificationBadgeBase() {
  const { pathname } = useLocation();
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  const { isSenbirdSdkAvailable, sdkInstance } = useSendbirdSdkAccessor();

  const getUnreadMessages = useCallback(async () => {
    if (!isSenbirdSdkAvailable) {
      return;
    }

    try {
      const unreadMessageCount_ = await sdkInstance?.groupChannel?.getTotalUnreadMessageCount();
      setUnreadMessageCount(unreadMessageCount_);
    } catch (error) {
      console.log("error");
    }
  }, [sdkInstance, isSenbirdSdkAvailable]);

  useEffect(() => {
    getUnreadMessages();
  }, [getUnreadMessages, sdkInstance, pathname]);

  return <NotificationBadge count={unreadMessageCount} />;
}

export default function DriverSupportMenuItemNotificationBadge() {
  return (
    <SendbirdDataProvider>
      <DriverSupportMenuItemNotificationBadgeBase />
    </SendbirdDataProvider>
  );
}
