import { Auth } from "aws-amplify";
import CryptoJS from "crypto-js";
import { AuthSignUpFormFlowSteps } from "types/auth.types";
import { defaultPasswordValidationMessages } from "../consts/auth.consts";
import AuthRegisterFlowCompanyStepBanner from "assets/images/auth/AuthRegisterFlowCompanyStepBanner.webp";
import AuthChargingSessionsOverviewBanner from "assets/images/auth/AuthChargingSessionsOverviewBannerImage.png";
import AuthSentCodeBanner from "assets/images/auth/AuthSentCodeBanner.webp";
import AuthDashboardOverviewBanner from "assets/images/auth/AuthDashboardOverviewBanner.png";

export async function signOut() {
  await Auth.signOut();
  sessionStorage.setItem("storedAccessObject", undefined);
  window.location.href = "/";
}

export function generateUserHmacForIntercom(user) {
  const secretKey = process.env.REACT_APP_INTERCOM_SECRET_KEY; // secret key (keep safe!)

  if (!secretKey) {
    return;
  }

  const userIdentifier = user?.id?.toString(); // user's id
  const hash = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(userIdentifier, secretKey));

  return hash;
}

/**
 *
 */
export const handleValidatePassword = (password) => {
  const trimmedPassword = password.trim();
  const passwordValidation = {
    length: { message: defaultPasswordValidationMessages.length, isValid: trimmedPassword?.length >= 8 },
    uppercase: {
      message: defaultPasswordValidationMessages.uppercase,
      isValid: trimmedPassword?.length >= 0 && /[A-Z]/.test(trimmedPassword),
    },
    lowercase: {
      message: defaultPasswordValidationMessages.lowercase,
      isValid: trimmedPassword?.length >= 0 && /[a-z]/.test(trimmedPassword),
    },
    number: { message: defaultPasswordValidationMessages.number, isValid: /\d/.test(trimmedPassword) },
  };

  const isValid = Object.values(passwordValidation).every((val) => val.isValid);

  return { isValid, passwordValidation };
};

export const AuthSignUpImageMap = new Map([
  [
    AuthSignUpFormFlowSteps.START,
    {
      Banner: (props) => (
        <img
          className={`h-max-90 ${props.className}`}
          src={AuthDashboardOverviewBanner}
          alt="Auth Dashboard Overview Banner"
        />
      ),
      align: "justify-content-end",
    },
  ],
  [
    AuthSignUpFormFlowSteps.COMPANY,
    {
      Banner: (props) => (
        <img
          width={500}
          className={props.className}
          src={AuthRegisterFlowCompanyStepBanner}
          alt="Auth Register Flow Company Step Banner"
        />
      ),
      align: "justify-content-center",
    },
  ],
  [
    AuthSignUpFormFlowSteps.CREDENTIALS,
    {
      Banner: (props) => (
        <img
          className={`h-max-90 ${props.className}`}
          src={AuthChargingSessionsOverviewBanner}
          alt="Auth Charging Sessions Overview Banner"
        />
      ),
      align: "justify-content-end",
    },
  ],
  [
    AuthSignUpFormFlowSteps.CONFIRMATION,
    {
      Banner: (props) => (
        <img
          width={400}
          className={`h-max-80 ${props.className}`}
          src={AuthSentCodeBanner}
          alt="Auth Dashboard Overview Banner"
        />
      ),
      align: "justify-content-center",
    },
  ],
]);

export const AuthSignUpImages = Array.from(AuthSignUpImageMap, ([id, value]) => ({ id, ...value }));
