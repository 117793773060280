import VehicleFormCompletedContent from "../../components/vehicle-components/form/VehicleFormCompletedContent";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { useLocation } from "react-router";

type Props = {};

function VehicleFormCompletedPage({}: Props) {
  const query = new URLSearchParams(useLocation().search);
  const provider = query.get("provider");

  const isTeslaContent = provider === "tesla";
  return (
    <>
      <Head title={"Successful"} />
      <Content>
        <VehicleFormCompletedContent isTeslaContent={isTeslaContent} />
      </Content>
    </>
  );
}

export default VehicleFormCompletedPage;
