import React from "react";
import MuiTooltip from "../../tooltip/MuiTooltip";
import OutlinedButtonWithIcon, { OutlinedButtonWithIconProps } from "../../button/OutlinedButtonWithIcon";

type Props = {
  isVirtualKeyNeeded: boolean;
} & OutlinedButtonWithIconProps
const VehicleDetailsVirtualKeyControlledButton = ({ isVirtualKeyNeeded, ...props }: Props) => {

  if (!isVirtualKeyNeeded) {
    return <OutlinedButtonWithIcon
      {...props}
    />;
  }

  return (
    <MuiTooltip title={"Virtual key must be added."} placement="top">
      <div>
        <OutlinedButtonWithIcon
          isDisabled={true}
          {...props}
        />
      </div>
    </MuiTooltip>
  );
};

export default VehicleDetailsVirtualKeyControlledButton;