import Box from "@mui/material/Box";
import * as React from "react";
import { useMemo } from "react";
import { DriverDetailsPageTabKey } from "types/driver.types";
import { StyledTab, StyledTabs } from "../../tab/StyledTab";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router";


type DriverDetailsPageTabProps = {};

export default function DriverDetailsPageTab({}: DriverDetailsPageTabProps) {
  const history = useHistory();
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const { driverId } = useParams<{ driverId: string }>();

  const activeTab = useMemo(() => {
    if (pathname.includes("charging-sessions")) {
      return DriverDetailsPageTabKey.CHARGING_SESSIONS;
    }
    if (pathname.includes("reimbursement")) {
      return DriverDetailsPageTabKey.REIMBURSEMENT;
    }

    return DriverDetailsPageTabKey.ABOUT;
  }, [pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const basePath = `/drivers/${driverId}`;
    if (newValue === DriverDetailsPageTabKey.CHARGING_SESSIONS) {
      history.push(`${basePath}/charging-sessions`);
      return;
    }
    if (newValue === DriverDetailsPageTabKey.REIMBURSEMENT) {
      history.push(`${basePath}/reimbursement`);
      return;
    }
    history.push(basePath);
  };

  return (
    <Box>
      <StyledTabs value={activeTab} onChange={handleChange}>
        <StyledTab label="About" />
        <StyledTab label="Charging sessions" />
        <StyledTab label="Reimbursement" />
        <></>

      </StyledTabs>
    </Box>
  );
}
