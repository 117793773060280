import Row from "../custom/Row";
import XCircleIcon from "../icon/icons/XCircleIcon";
import { CherryCaptionText100 } from "../text/Text";

type ConfirmPasswordValidationMessageProps = { validationMessage?: string };

export function ConfirmPasswordValidationMessage({ validationMessage }: ConfirmPasswordValidationMessageProps) {
  if (validationMessage) {
    return (
      <div className="password-validation-box-container">
        <Row>
          <div className="center mr-1">
            <XCircleIcon />
          </div>
          <CherryCaptionText100>{validationMessage}</CherryCaptionText100>
        </Row>
      </div>
    );
  }

  return <></>;
}
