import FormButton from "./FormButton";

type Props = {
  readonly onSignUpButtonPressed?: () => void;
  readonly className?: string;
  readonly type?: string;
  readonly isLoading?: boolean;
  readonly isDisabled?: boolean;
};

function SignUpButton({ onSignUpButtonPressed, ...props }: Props) {
  return <FormButton text={"Sign up"} onFormButtonPressed={onSignUpButtonPressed} {...props} />;
}

export default SignUpButton;
