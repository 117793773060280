import { useCallback, useEffect, useState } from "react";
import {
  InboxBulkActionCommand,
  InboxFilters,
  InboxMessage,
  InboxMessageDataType,
  InboxMessagesDataAccessorHooks,
  InboxMessageType
} from "../types/inbox.types";
import { useFilterUrlQuery } from "./filter.hooks";
import { useAppSelector } from "./redux.hooks";
import { useAlertManager } from "./alert.hooks";
import { handleApiErrorResponse } from "../utils";
import {
  archiveInboxMessagesAsync,
  readInboxMessagesAsync,
  unArchiveInboxMessagesAsync,
  unreadInboxMessagesAsync
} from "../services/inbox.services";
import { DriversTableType } from "../types/driver.types";
import { useHistory } from "react-router";

export const useInboxBulkActionHooks = (inboxMessagesDataAccessorHooks: InboxMessagesDataAccessorHooks) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleOpenErrorAlert, handleOpenSuccessAlert, handleOpenSuccessAlertWithAction } = useAlertManager();

  const { messages: inboxMessages, refetch } = inboxMessagesDataAccessorHooks;


  const [selectedInboxMessages, setSelectedInboxMessages] = useState<InboxMessage[]>([]);


  const getActionTypeBySelectedRows = () => {
    let readCount = 0;
    let unreadCount = 0;

    selectedInboxMessages.forEach(row => {
      if (row.read) {
        readCount++;
      } else {
        unreadCount++;
      }
    });


    return unreadCount >= readCount ? InboxBulkActionCommand.READ : InboxBulkActionCommand.UNREAD;
  };


  const isNextCommandRead = getActionTypeBySelectedRows() === InboxBulkActionCommand.READ;


  const isRowChecked = (id: string) => {
    return !!selectedInboxMessages.find(im => im.id === id);
  };

  const handleClearSelectedRows = () => {
    setSelectedInboxMessages([]);
  };


  const handleSelectRow = (message: InboxMessage) => {
    if (isRowChecked(message.id)) {
      setSelectedInboxMessages(rows => rows.filter(im => im.id !== message.id));
      return;
    }

    setSelectedInboxMessages(rows => [...rows, message]);
  };


  const handleSelectAllRows = () => {
    setSelectedInboxMessages(inboxMessages);
  };

  const isClearable = selectedInboxMessages.length > 0;
  const isAllSelected = isClearable && (inboxMessages.length === selectedInboxMessages.length);


  const handleToggleBulkCheckbox = () => {
    if (isAllSelected || isClearable) {
      handleClearSelectedRows();
      return;
    }

    handleSelectAllRows();
  };


  const handleUndoActionAsync = useCallback(async (type: InboxBulkActionCommand, affacted: string[]) => {
    try {
      setIsSubmitting(true);
      if (type === InboxBulkActionCommand.ARCHIVE) {
        await unArchiveInboxMessagesAsync({ ids: affacted });
      }

      if (type === InboxBulkActionCommand.READ) {
        await unreadInboxMessagesAsync({ ids: affacted });
      }

      if (type === InboxBulkActionCommand.UNREAD) {
        await readInboxMessagesAsync({ ids: affacted });
      }

      handleOpenSuccessAlert(`Action undone`);
      inboxMessagesDataAccessorHooks.refetch();
    } catch (e: unknown) {
      handleOpenErrorAlert(handleApiErrorResponse(e));
    } finally {
      setIsSubmitting(false);
    }
  }, [handleOpenErrorAlert, handleOpenSuccessAlert, inboxMessagesDataAccessorHooks]);


  const handleUpdateInboxMessagesAsync = useCallback(async (serviceCallback: (params: {
    ids: string[]
  }) => Promise<void>, successMessage: (count: number) => string, type: InboxBulkActionCommand) => {
    try {
      setIsSubmitting(true);
      const selectedMessagesIds = selectedInboxMessages.map(im => im.id);
      await serviceCallback({ ids: selectedMessagesIds });
      handleOpenSuccessAlertWithAction(successMessage(selectedInboxMessages.length), "Undo", () => handleUndoActionAsync(type, selectedMessagesIds));
      handleClearSelectedRows();
      inboxMessagesDataAccessorHooks.refetch();
    } catch (e: unknown) {
      handleOpenErrorAlert(handleApiErrorResponse(e));
    } finally {
      setIsSubmitting(false);
    }
  }, [handleOpenErrorAlert, handleOpenSuccessAlertWithAction, handleUndoActionAsync, inboxMessagesDataAccessorHooks, selectedInboxMessages]);

  const handleReadInboxMessagesAsync = useCallback(async () => {
    handleUpdateInboxMessagesAsync(readInboxMessagesAsync, count => `${count} messages marked as read.`, InboxBulkActionCommand.READ);
  }, [handleUpdateInboxMessagesAsync]);


  const handleUnReadInboxMessagesAsync = useCallback(async () => {
    handleUpdateInboxMessagesAsync(unreadInboxMessagesAsync, count => `${count} messages marked as unread.`, InboxBulkActionCommand.UNREAD);
  }, [handleUpdateInboxMessagesAsync]);


  const handleReadOrUnreadInboxMessagesAsync = useCallback(() => {
    if (isNextCommandRead) {
      handleReadInboxMessagesAsync();
      return;
    }

    handleUnReadInboxMessagesAsync();
  }, [handleReadInboxMessagesAsync, handleUnReadInboxMessagesAsync, isNextCommandRead]);

  const handleArchiveInboxMessagesAsync = useCallback(async () => {
    handleUpdateInboxMessagesAsync(archiveInboxMessagesAsync, count => `${count} messages archived successfully!`, InboxBulkActionCommand.ARCHIVE);
  }, [handleUpdateInboxMessagesAsync]);


  return {
    selectedInboxMessages,
    isRowChecked,
    handleClearSelectedRows,
    handleSelectRow,
    handleSelectAllRows,
    handleArchiveInboxMessagesAsync,
    handleReadOrUnreadInboxMessagesAsync,
    isAllSelected,
    handleToggleBulkCheckbox,
    isClearable,
    isSubmitting,
    isNextCommandRead
  };
};


export const useInboxQueryHooks = () => {
  const { filterQuery, setFilterQuery } = useFilterUrlQuery<InboxFilters>();

  const activeTabOnQuery = filterQuery?.archived?.$eq === "true" ? InboxMessageType.ARCHIVED : InboxMessageType.ACTIVE;

  const cachedMessage = useAppSelector((s) => s.inbox.selectedMessage);

  const activeTab = cachedMessage?.archived ? InboxMessageType.ARCHIVED : activeTabOnQuery;
  const isActiveTabArchived = activeTab === InboxMessageType.ARCHIVED;

  const handleSetNetworksOnQuery = useCallback(
    (messageType: InboxMessageType) => {
      setFilterQuery((q) => {
        return {
          archived: {
            $eq: messageType === InboxMessageType.ACTIVE ? "false" : "true"
          }
        };
      });
    },
    [setFilterQuery]
  );

  useEffect(() => {
    if (filterQuery) {
      return;
    }
    handleSetNetworksOnQuery(InboxMessageType.ACTIVE);
  }, [filterQuery, handleSetNetworksOnQuery]);

  return {
    filterQuery,
    setFilterQuery,
    activeTabOnQuery,
    cachedMessage,
    activeTab,
    handleSetNetworksOnQuery,
    isActiveTabArchived
  };
};


const useBindInboxMessageLinkBaseHook = () => {
  const history = useHistory();
  const handleLinkPressed = useCallback(
    (dataType: string, dataId: string) => {
      if (dataType === InboxMessageDataType.DRIVER) {
        return history.push(`/drivers/${dataId}`);
      }

      if (dataType === InboxMessageDataType.VEHICLE) {
        return history.push(`/vehicles/${dataId}`);
      }

      if (dataType === InboxMessageDataType.SELF_SIGNUP) {
        return history.push(`/drivers`, { tab: DriversTableType.PENDING });
      }
    },
    [history]
  );


  const handleBindEventsToInboxMessageTemplate = useCallback(() => {
    const links = window.document.querySelectorAll("b[data-link=\"true\"]");
    links?.forEach((link) => {
      const dataType = link.getAttribute("data-type");
      const dataId = link.getAttribute("data-id");
      link.classList.add("inbox-message-body-link");
      if (dataType && dataId) {
        link.addEventListener("click", () => handleLinkPressed(dataType, dataId));
      }
    });
  }, [handleLinkPressed]);


  return {
    handleBindEventsToInboxMessageTemplate

  };
};
export const useBindAllVisibleInboxMessageLinks = (isLoading: boolean) => {
  const { handleBindEventsToInboxMessageTemplate } = useBindInboxMessageLinkBaseHook();

  useEffect(() => {
    handleBindEventsToInboxMessageTemplate();
  }, [handleBindEventsToInboxMessageTemplate, isLoading]);
};


export const useBindInboxMessageDetailLinks = (message: InboxMessage | null) => {
    const { handleBindEventsToInboxMessageTemplate } = useBindInboxMessageLinkBaseHook();

    const handleBindEventsToInboxMessageTemplateCallbackWithTimeout = useCallback(() => {
      if (!message?.body) return;
      const DRAWER_SLIDE_ANIMATION_TIMEOUT = 500;
      setTimeout(() => {
        handleBindEventsToInboxMessageTemplate();
      }, DRAWER_SLIDE_ANIMATION_TIMEOUT);
    }, [handleBindEventsToInboxMessageTemplate, message?.body]);


    useEffect(() => {
      handleBindEventsToInboxMessageTemplateCallbackWithTimeout();
    }, [handleBindEventsToInboxMessageTemplate, handleBindEventsToInboxMessageTemplateCallbackWithTimeout]);
  }
;