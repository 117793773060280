import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { Icon } from "../Component";
import ButtonWithIcon from "./ButtonWithIcon";

type ContinueButtonProps = {
  readonly onContinueButtonClicked?: () => {};
  readonly className?: string;
  readonly type: string;
};
export default function ContinueButton({ onContinueButtonClicked, ...props }: ContinueButtonProps) {
  /**
   * Render
   */

  return <ButtonWithIcon text={"Continue"} onClick={onContinueButtonClicked} {...props} />;
}
