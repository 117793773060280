import React from "react";
import OutlinedButton from "./OutlinedButton";

type Props = {
  readonly onBackButtonClicked?: () => void;
  readonly className?: string;
};

function OutlinedBackButton({ onBackButtonClicked, className }: Props) {
  return <OutlinedButton type="button" text={"Back"} onClick={onBackButtonClicked} className={className} />;
}

export default OutlinedBackButton;
