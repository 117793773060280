import TableNoResultPlaceholder from "components/table/placeholder/TableNoResultPlaceholder";
import TableNoResultPlaceholderForMessages from "assets/images/placeholder/NoResultPlaceholderForMessages.png";
import React from "react";

type Props = {};

function InboxMessagesTablePlaceholder({}: Props) {
  return (
    <TableNoResultPlaceholder
      img={<img width={212} src={TableNoResultPlaceholderForMessages} alt="No Result placeholder for messages" />}
      title="No messages found"
      description="Your inbox is currently empty. New alerts and notifications will appear here as they arrive."
    />
  );
}

export default InboxMessagesTablePlaceholder;
