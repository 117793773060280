import { ApiResponse, PaginatedApiResponse } from "../types/api.types";
import {
  AuthorizeGeotabPayload,
  AuthorizeSmartCarPayload,
  AuthorizeTeslaPayload,
  TeslaAlerts,
  TeslaChargingWithTeslaInvoice,
  TeslaDrivePin,
  TeslaKeyStatus,
  TeslaMultipleDownloadResponse,
  TeslaVehicleInfo,
  UpdateTeslaPayload,
  Vehicle,
  VehicleChargingDetail,
  VehicleChargingSummary,
  VehicleLocation,
  VehicleSmartcarInfo,
  VPICVehicleInfo
} from "../types/vehicle.types";
import { generateApiEndpoint } from "../utils";
import restApiClient from "./client";

const SERVICE_END_POINT_NAME = "vehicles";

/**
 * GET: '/vehicles'
 */
export async function getVehiclesAsync(query: string): Promise<PaginatedApiResponse<Vehicle>> {
  const endpoint = generateApiEndpoint(SERVICE_END_POINT_NAME);
  return (await restApiClient.get<PaginatedApiResponse<Vehicle>>(endpoint + query)).data;
}


/**
 * GET: '/vehicles/{{vehicleId}}'
 */
export async function getVehicleAsync(vehicleId: string): Promise<ApiResponse<Vehicle>> {
  const endpoint = generateApiEndpoint(`${SERVICE_END_POINT_NAME}/${vehicleId}`);
  const response = await restApiClient.get(endpoint);

  return response.data;
}


/**
 * GET: '/vehicles/locations'
 */
export async function getVehicleLocationsAsync(): Promise<ApiResponse<Array<VehicleLocation>>> {

  const endpoint = generateApiEndpoint(`${SERVICE_END_POINT_NAME}/locations`);
  const response = await restApiClient.get(endpoint);

  return response.data;
}


/**
 * GET: '/vehicles/{{vehicleId}}/charging/summary'
 */
export async function getVehicleChargingSummaryByIdAsync(vehicleId: string): Promise<ApiResponse<VehicleChargingSummary>> {
  const endpoint = generateApiEndpoint(`${SERVICE_END_POINT_NAME}/${vehicleId}/charging/summary`);
  const response = await restApiClient.get(endpoint);

  return response.data;
}


/**
 * GET: '/admin/vpic/{vin}'
 */
export async function getVehicleVpicInfoAsync(vin: string): Promise<ApiResponse<VPICVehicleInfo>> {
  const endpoint = generateApiEndpoint(`vpic/${vin}`);
  const response = await restApiClient.get(endpoint);
  return response.data;
}


/**
 * GET: '/vehicles/{{vehicleId}}/charging/details'
 */
export async function getVehicleChargingListByIdAsync(vehicleId: string, query: string): Promise<PaginatedApiResponse<VehicleChargingDetail>> {
  const endpoint = generateApiEndpoint(`${SERVICE_END_POINT_NAME}/${vehicleId}/charging`);

  return (await restApiClient.get<PaginatedApiResponse<VehicleChargingDetail>>(endpoint + query)).data;
}


export async function getTeslaChargingInvoicesAsync(query?: string) {
  const endpoint = generateApiEndpoint(`tesla/charging`);
  return (await restApiClient.get<PaginatedApiResponse<TeslaChargingWithTeslaInvoice>>(endpoint + query)).data;
}

///admin/tesla/charging-invoice
export async function getTeslaChargingInvoiceAsync(id: string) {
  const endpoint = generateApiEndpoint(`tesla/charging-invoices/${id}`);
  return (await restApiClient.get<string>(endpoint, {
    headers: {
      Accept: "application/pdf, application/json, text/plain, */*"
    }
  }));
}


export async function downloadTeslaChargingInvoicesAsync(query?: string) {
  const endpoint = generateApiEndpoint(`tesla/charging-invoices`);
  return (await restApiClient.get<TeslaMultipleDownloadResponse>(endpoint + query));
}


/**
 * POST: '/vehicle'
 */
export async function addVehicle(data) {
  const endpoint = generateApiEndpoint(SERVICE_END_POINT_NAME);
  const response = await restApiClient.post(endpoint, data);

  return response.data;
}

/**
 * PATCH: '/vehicles/{id}'
 */
export async function updateVehicleAsync(vehicleId, data) {
  const endpoint = generateApiEndpoint(`${SERVICE_END_POINT_NAME}/${vehicleId}`);
  const response = await restApiClient.patch(endpoint, data);

  return response.data;
}


/**
 * DELETE: '/vehicles/{id}'
 */
export async function deleteVehicleSmartCarAsync(vehicleId) {
  const endpoint = generateApiEndpoint(`${SERVICE_END_POINT_NAME}/${vehicleId}/smartcar`);
  const response = await restApiClient.delete(endpoint);

  return response.data;
}


/**
 * POST: '/vehicles/{id}/add-users'
 */
export async function addUsersToVehicle(vehicleId, users) {
  const endpoint = generateApiEndpoint(`${SERVICE_END_POINT_NAME}/${vehicleId}/add-users`);
  const response = await restApiClient.post(endpoint, users);

  return response.data;
}

/**
 * POST: '/vehicles/{id}/remove-users'
 */
export async function removeUsersFromVehicle(vehicleId, users) {
  const endpoint = generateApiEndpoint(`${SERVICE_END_POINT_NAME}/${vehicleId}/remove-users`);
  const response = await restApiClient.post(endpoint, users);

  return response.data;
}


/**
 * Smartcar
 */

/**
 * GET: '/vehicles/{{vehicleId}}/smartcar/info'
 */
export async function getVehicleSmartcarInfoAsync(vehicleId: string): Promise<ApiResponse<VehicleSmartcarInfo>> {
  const endpoint = generateApiEndpoint(`${SERVICE_END_POINT_NAME}/${vehicleId}/smartcar/info`);
  const response = await restApiClient.get(endpoint);
  return response.data;
}

/**
 * POST: '/api/smartcar/authorize'
 */
export async function authorizeSmartCarStateAsync(smartCarStatePayload: AuthorizeSmartCarPayload) {
  const endpoint = generateApiEndpoint("smartcar/authorize");
  const response = await restApiClient.post<ApiResponse<{ message: string }>>(endpoint, smartCarStatePayload);

  return response.data;
}


/**
 * Tesla
 */


/**
 * GET: '/vehicles/{{vehicleId}}/tesla/info'
 */
export async function getVehicleTeslaInfoAsync(vehicleId: string): Promise<ApiResponse<TeslaVehicleInfo>> {
  const endpoint = generateApiEndpoint(`${SERVICE_END_POINT_NAME}/${vehicleId}/tesla/info`);
  const response = await restApiClient.get(endpoint);
  return response.data;
}


/**
 * GET: '/vehicles/{{vehicleId}}/tesla/info'
 */
export async function getVehicleTeslaKeyStatusAsync(vehicleId: string): Promise<ApiResponse<TeslaKeyStatus>> {
  const endpoint = generateApiEndpoint(`${SERVICE_END_POINT_NAME}/${vehicleId}/tesla/key`);
  const response = await restApiClient.get(endpoint);
  return response.data;
}

/**
 * GET: '/vehicles/{{vehicleId}}/tesla/info'
 */
export async function getVehicleTeslaDrivePinAsync(vehicleId: string): Promise<ApiResponse<TeslaDrivePin>> {
  const endpoint = generateApiEndpoint(`${SERVICE_END_POINT_NAME}/${vehicleId}/tesla/drive`);
  const response = await restApiClient.get(endpoint);
  return response.data;
}


/**
 * GET: '/vehicles/{{vehicleId}}/tesla/info'
 */
export async function getVehicleTeslaAlertsAsync(vehicleId: string): Promise<ApiResponse<TeslaAlerts>> {
  const endpoint = generateApiEndpoint(`${SERVICE_END_POINT_NAME}/${vehicleId}/tesla/alerts`);
  const response = await restApiClient.get(endpoint);
  return response.data;
}


/**
 * PATCH: '/vehicles/{{vehicleId}}/tesla'
 */
export async function updateVehicleTeslaAsync(vehicleId: string, payload: UpdateTeslaPayload): Promise<ApiResponse<TeslaVehicleInfo>> {
  const endpoint = generateApiEndpoint(`${SERVICE_END_POINT_NAME}/${vehicleId}/tesla`);
  const response = await restApiClient.patch(endpoint, payload);
  return response.data;
}

/**
 * POST: '/api/tesla/authorize'
 */
export async function authorizeTeslaAuthAsync(payload: AuthorizeTeslaPayload) {
  const endpoint = generateApiEndpoint("tesla/authorize");
  const response = await restApiClient.post<ApiResponse<{}>>(endpoint, payload);

  return response.data;
}


/**
 * POST: '/api/tesla/authorize'
 */
export async function authorizeGeotabAuthAsync(payload: AuthorizeGeotabPayload) {
  const endpoint = generateApiEndpoint("geotab/authorize");
  const response = await restApiClient.post<ApiResponse<{}>>(endpoint, payload);

  return response.data;
}


const services = {
  updateVehicleAsync,
  addVehicle,
  addUsersToVehicle,
  removeUsersFromVehicle
};

export default services;
