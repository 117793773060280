import * as React from "react";
import Box from "@mui/material/Box";
import { StyledTab, StyledTabs } from "components/tab/StyledTab";
import { useHistory, useLocation, useParams } from "react-router";
import { vehicleDetailsRoutes } from "utils/vehicle.utils";
import LoadingSkeleton from "../../loading/LoadingSkeleton";
import {
  useVehicleTeslaAlertsAsync,
  useVehicleTeslaKeyStatusAsync
} from "../../../hooks/data/vehicle-data-accessor.hooks";
import { VehicleDetailsRouteType } from "../../../types/vehicle.types";


type Props = {
  readonly isLoading?: boolean
  readonly isTeslaConnected?: boolean
}

export default function VehicleDetailsTab({ isLoading, isTeslaConnected }: Props) {
  const history = useHistory();
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const { pathname } = useLocation();

  const { isLoading: isKeyStatusLoading, keyStatus } = useVehicleTeslaKeyStatusAsync(isTeslaConnected);


  const { isLoading: isAlertsLoading, alerts } = useVehicleTeslaAlertsAsync(isTeslaConnected);


  const areThereAnyRecentAlert = alerts && alerts.length > 0;
  const isTeslaKeyPaired = keyStatus?.key.paired;
  const isTeslaKeySupported = keyStatus?.key.supported;
  const isKeyRelatedAlertVisible = !isTeslaKeyPaired && isTeslaKeySupported;


  const routeValues = Object.values(vehicleDetailsRoutes);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const route = routeValues.find(r => r.ix === newValue);

    history.replace(`/vehicles/${vehicleId}/${route?.path}`);
  };

  const currentPath = routeValues.find(r => pathname.includes(r.path));


  if (isLoading || isKeyStatusLoading || isAlertsLoading) {
    return <div className={"my-4"}><LoadingSkeleton count={1} height={40} /></div>;
  }


  return (
    <Box className={"my-4"}>
      <StyledTabs value={currentPath?.ix ?? 0} onChange={handleChange}>
        {routeValues.map((r, i) => {

            switch (r.type) {
              case VehicleDetailsRouteType.TESLA_ALERTS :
                return isTeslaConnected ? <StyledTab label={r.label} isDot={areThereAnyRecentAlert} /> : <></>;

              case VehicleDetailsRouteType.TESLA_CONTROLS:
                return isTeslaConnected ? <StyledTab label={r.label} isDot={isKeyRelatedAlertVisible} /> : <></>;

              default:
                return <StyledTab label={r.label} />;
            }
          }
        )}

        <div className={"tab-border-extender"} />
      </StyledTabs>

    </Box>
  );
}
