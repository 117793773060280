import React from "react";
import HorizontalLine from "../line/HorizontalLine";
import { BlackBody2Text200, BlackBodyText100 } from "../text/Text";
import Row from "../custom/Row";

type Props = {
  className?: string;
  leftText?: string;
  rightText?: string;
  leftOverwrite?: React.JSX.Element | string;
  rightOverwrite?: React.JSX.Element | string;
  isBorderBottomVisible?: boolean;
}

function RowBetweenBlock({
                           className,
                           leftText,
                           rightText,
                           leftOverwrite,
                           rightOverwrite,
                           isBorderBottomVisible
                         }: Props) {
  return (
    <>
      <Row
        className={`flex-nowrap align-items-center ${isBorderBottomVisible ? "" : "pb-1"} justify-between ${className}`}>
        {leftOverwrite ?? (leftText && <BlackBody2Text200>{leftText}</BlackBody2Text200>)}
        {rightOverwrite ?? (rightText && <BlackBodyText100>{rightText}</BlackBodyText100>)}
      </Row>

      {isBorderBottomVisible && <HorizontalLine />}
    </>

  );
}

export default RowBetweenBlock;