import React from "react";

export default function UploadDocumentIcon(props) {
  const fillColor = props.fillColor ?? "#1E44FF";
  return (
    <svg className="h-auto" width="99" height="102" viewBox="0 0 99 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M79.4414 13.8828H16.0312V71.336C16.0312 91.1255 23.1916 92.6881 27.337 92.2756L79.4414 92.083V72.8837V13.8828Z"
        fill="url(#paint0_linear_2342_9996)"
      />
      <rect x="22.0312" y="19.8828" width="17.5552" height="2.47556" rx="0.990224" fill="#CBD5E1" />
      <rect x="22.0312" y="25.6592" width="36.7062" height="2.47556" rx="0.990224" fill="#CBD5E1" />
      <rect x="22.0312" y="31.4355" width="30.3225" height="2.47556" rx="0.990224" fill="#CBD5E1" />
      <rect x="22.0312" y="37.2114" width="36.7062" height="2.47556" rx="0.990224" fill="#CBD5E1" />
      <rect x="22.0312" y="42.9878" width="29.7067" height="2.47556" rx="0.990224" fill="#CBD5E1" />
      <rect x="52" y="55" width="44" height="44" rx="22" fill={fillColor} />
      <g clip-path="url(#clip0_2342_9996)">
        <path
          d="M71.5997 79.4002H76.3997V72.2002H79.9997L73.9997 66.2002L67.9997 72.2002H71.5997V79.4002ZM85.2053 81.2386C84.9533 80.9698 83.2721 79.171 82.7921 78.7018C82.4557 78.3794 82.0076 78.1996 81.5417 78.2002H79.4333L83.1101 81.793H78.8573C78.7995 81.7919 78.7424 81.8061 78.6919 81.8341C78.6413 81.8622 78.599 81.903 78.5693 81.9526L77.5901 84.2002H70.4093L69.4301 81.9526C69.4002 81.9032 69.3578 81.8625 69.3073 81.8345C69.2568 81.8065 69.1998 81.7922 69.1421 81.793H64.8893L68.5649 78.2002H66.4577C65.9813 78.2002 65.5265 78.391 65.2073 78.7018C64.7273 79.1722 63.0461 80.971 62.7941 81.2386C62.2073 81.8638 61.8845 82.3618 62.0381 82.9774L62.7113 86.6662C62.8649 87.283 63.5405 87.7894 64.2137 87.7894H83.7881C84.4613 87.7894 85.1369 87.283 85.2905 86.6662L85.9637 82.9774C86.1149 82.3618 85.7933 81.8638 85.2053 81.2386Z"
          fill="#F7FAFD"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2342_9996"
          x1="47.7363"
          y1="13.8828"
          x2="47.7363"
          y2="92.3911"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.440964" stop-color="white" />
          <stop offset="1" stop-color="#94A3B8" />
        </linearGradient>
        <clipPath id="clip0_2342_9996">
          <rect width="24" height="24" fill="white" transform="translate(62 65)" />
        </clipPath>
      </defs>
    </svg>
  );
}
