import { useEffect } from "react";

export const useToggleBackButton = (isVisible: boolean) => {
  useEffect(() => {
    const backButton = document.getElementById("back-button");
    if (backButton) {
      if (isVisible) {
        backButton.style.setProperty("display", "inline-flex", "important");
      } else {
        backButton.style.setProperty("display", "none", "important");
      }
    }
  }, [isVisible]);
};
