import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import DriverDetailsPage from "./DriverDetailsPage";
import DriversPage from "./DriversPage";
import DriversPageSignupsTable from "../../components/driver-components/table/DriversPageSignupsTable";
import DriversPageDriversTable from "../../components/driver-components/table/DriversPageDriversTable";
import { useSyncOrganization } from "../../hooks/auth.hooks";

const DriverRouter = () => {
  const { isLoading } = useSyncOrganization();

  const { path } = useRouteMatch();


  return (
    <Switch>
      {/* Make sure that /pending comes before /:driverId to avoid conflicts */}
      <Route exact path={`${path}/pending`}
             render={() => <DriversPage isLoading={isLoading} tableComponent={<DriversPageSignupsTable />} />} />
      <Route path={`${path}/:driverId`} component={DriverDetailsPage} />
      <Route path={`${path}`}
             render={() => <DriversPage isLoading={isLoading} tableComponent={<DriversPageDriversTable />} />} />
    </Switch>
  );
};

export default DriverRouter;
