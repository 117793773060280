import { Charging } from "types/charging-sessions.types";
import { ApiResponse, PaginatedApiResponse } from "../types/api.types";
import {
  Driver,
  DriverAddPayload,
  DriverSelfSignupUpdateCommand,
  DriverSelfSignupUpdatePayload,
  DriverSignUpApplication,
  DriverUpdatePayload
} from "../types/driver.types";
import { generateApiEndpoint } from "../utils";
import restApiClient from "./client";

const serviceName = "users";

/**
 * GET: '/api/users'
 */
export async function getDriversAsync(query: string) {
  const endpoint = generateApiEndpoint(serviceName);
  return (await restApiClient.get<PaginatedApiResponse<Driver>>(endpoint + query)).data;
}


/**
 * GET: '/api/signups'
 */
export async function getDriverSignupsAsync(query: string) {
  const endpoint = generateApiEndpoint("signups");
  return (await restApiClient.get<PaginatedApiResponse<DriverSignUpApplication>>(endpoint + query)).data;
}


/**
 * GET: '/api/signups'
 */
export async function getDriverSignupAsync(id: string): Promise<ApiResponse<DriverSignUpApplication>> {
  const endpoint = generateApiEndpoint(`signups/${id}`);
  return (await restApiClient.get(endpoint)).data;
}


/**
 * PATCH: '/api/signups'
 */
export async function updateDriverSignupAsync(id: string, command: DriverSelfSignupUpdateCommand, payload?: DriverSelfSignupUpdatePayload): Promise<ApiResponse<DriverSignUpApplication>> {
  const endpoint = generateApiEndpoint(`signups/${id}/${command}`);
  return (await restApiClient.patch(endpoint, payload ?? null)).data;
}


/**
 * GET: '/api/users/${driverId}'
 */
export async function getDriverAsync(driverId): Promise<ApiResponse<Driver>> {
  const endpoint = generateApiEndpoint(`${serviceName}/${driverId}`);
  const response = await restApiClient.get(endpoint);

  return response.data;
}

/**
 * GET: '/users/{{driverId}}/charging/details'
 */
export async function getDriverChargingListByIdAsync(driverId: string, query: string): Promise<PaginatedApiResponse<Charging>> {
  const endpoint = generateApiEndpoint(`${serviceName}/${driverId}/charging`);
  return (await restApiClient.get<PaginatedApiResponse<Charging>>(endpoint + query)).data;
}

/**
 * POST: '/api/users'
 */
export async function addDriverAsync(data: DriverAddPayload): Promise<ApiResponse<Driver>> {
  const endpoint = generateApiEndpoint(serviceName);
  const response = await restApiClient.post(endpoint, data);

  return response.data;
}

/**
 * POST: '/api/users'
 */
export async function addDriversAsync(data, contentType) {
  //const test = "http://localhost:3001/api/articles/content";
  const endpoint = generateApiEndpoint(`bulk/${serviceName}`);
  const response = await restApiClient.post(endpoint, data, {
    headers: { "Content-Type": contentType }
  });

  return response.data;
}

/**
 * PATCH: '/api/users/${userId}'
 */
export async function updateDriverAsync(driverId: string, payload: DriverUpdatePayload): Promise<ApiResponse<Driver>> {
  const endpoint = generateApiEndpoint(`${serviceName}/${driverId}`);
  return (await restApiClient.patch(endpoint, payload)).data;
}



