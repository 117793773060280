import AlertConfirmDialog from "components/notification/AlertConfirmDialog";
import { useAuthServicesAsync } from "hooks/auth.hooks";
import React, { useState } from "react";
import CancelButton from "./CancelButton";
import OutlinedButton from "./OutlinedButton";
import YesButton from "./YesButton";

type Props = {
  readonly className?: string;
};

function LogoutOutlinedButton({ className, ...props }: Props) {
  const { logoutAsync } = useAuthServicesAsync();
  const [isLogoutConfirmDialogOpen, setIsLogoutConfirmDialogOpen] = useState(false);
  return (
    <>
      <AlertConfirmDialog
        isDialogOpen={isLogoutConfirmDialogOpen}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setIsLogoutConfirmDialogOpen(false)} />
            </div>

            <YesButton onYesButtonPressed={logoutAsync} />
          </>
        }
        toggleIsDialogOpen={() => setIsLogoutConfirmDialogOpen(false)}
        title={"Are you sure you want to log out?"}
      />
      <OutlinedButton
        text={"Logout"}
        onClick={() => setIsLogoutConfirmDialogOpen(true)}
        className={className}
        textColor={"#680031"}
        theme="primary"
        {...props}
      />
    </>
  );
}

export default LogoutOutlinedButton;
