import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router";

import Row from "../../custom/Row";

import FilterSearchInput from "components/filter/FilterSearchInput";
import PaginatedTable from "components/table/PaginatedTable";
import NoDataPlaceholder from "components/table/placeholder/NoDataPlaceholder";
import { useSyncOrganization } from "hooks/auth.hooks";
import { useDriverSignupsAsync } from "hooks/data/driver-data-accessor.hooks";
import { Driver, DriverSignUpApplication } from "types/driver.types";
import { TableColumn } from "types/table.types";
import { getLocalFormattedDateForTables } from "utils/date.utils";
import { getFormattedPhoneNumber } from "utils/format.utils";
import { getDriverFullName } from "../../../utils/driver.utils";
import RightChevron from "../../icon/icons/RightChevron";
import DriverSortingSelector from "../filter/DriverSortingSelector";
import DriverStatusBadge from "../../badge/DriverStatusBadge";

export type DriversPageSignupsTableColumn = TableColumn<DriverSignUpApplication>[];
const DriversPageSignupsTable = () => {
  const { t } = useTranslation("common");
  useSyncOrganization();

  const { driverSignups, meta, isLoading } = useDriverSignupsAsync();

  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        grow: 1.5,
        name: "Name",
        selector: (row: Driver) => getDriverFullName(row)
      },
      {
        grow: 1.5,
        name: t("general.status"),
        selector: (row: DriverSignUpApplication) => (
          <DriverStatusBadge status={row.status} />
        )
      },
      {
        grow: 2,
        name: "Email",
        selector: (row: DriverSignUpApplication) => row?.email
      },
      {
        grow: 1.5,
        name: "Phone number",
        selector: (row: DriverSignUpApplication) => getFormattedPhoneNumber(row?.phoneNumber)
      },

      {
        grow: 1.5,
        name: "Signup date",
        selector: (row: DriverSignUpApplication) => <>{getLocalFormattedDateForTables(row?.createdAt)} </>
      },
      {
        grow: 0.3,
        name: "",
        cell: (row: DriverSignUpApplication) => <RightChevron widthValue={5} />
      }
    ],
    [t]
  ) as DriversPageSignupsTableColumn;

  return (
    <>
      <Row className="justify-between my-2">
        <FilterSearchInput placeholder="Search driver" />
        <DriverSortingSelector />
      </Row>

      <PaginatedTable
        noDataPlaceholder={
          <NoDataPlaceholder
            description="No pending drivers yet. They'll appear here for approval once they sign up using the app." />
        }
        columns={columns}
        data={driverSignups}
        paginationTotalRows={meta?.pagination.totalElements}
        isLoading={isLoading}
        getRoutePath={id => `/approve-driver/${id}`}
      />
    </>
  );
};

export default DriversPageSignupsTable;
