import React, { ChangeEvent } from "react";
import SearchIcon from "../icon/icons/SearchIcon";
import XCircleIcon from "components/icon/icons/XCircleIcon";

type SearchInputProps = {
  readonly onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  readonly placeholder?: string;
  readonly className?: string;
  readonly defaultValue?: string;
  readonly value?: string;
  readonly isClearable?: boolean;
};
export default function SearchInput({
  onChange,
  placeholder,
  className,
  defaultValue,
  value,
  isClearable,
}: SearchInputProps) {
  return (
    <div className={`app-search-input-container position-relative center ${className ?? ""}`}>
      <div className="absolute-left p-2">
        <SearchIcon />
      </div>

      <input
        defaultValue={defaultValue}
        className="app-search-input"
        type="text"
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />

      {value && isClearable && (
        <div
          className="clickable absolute-right p-2"
          //@ts-ignore
          onClick={() => onChange && onChange({ target: { value: "" } } as unknown as ChangeEvent)}
        >
          <XCircleIcon fill="black" />
        </div>
      )}
    </div>
  );
}
