import Smartcar from "@smartcar/auth";
import {
  useVehicleAsync,
  useVehicleTeslaAlertsAsync,
  useVehicleTeslaAsync,
  useVehicleTeslaKeyStatusAsync
} from "hooks/data/vehicle-data-accessor.hooks";
import { PaginationMeta } from "./api.types";

import { Driver } from "./driver.types";
import { SelectorStringOption } from "./editor.types";
import { Period } from "./filter.types";
import { Charging } from "./charging-sessions.types";


type Home = {
  cost: Cost;
  energy: Energy;
  sessionSize: Energy;
}
type Public = Home

type Cost = {
  currency: null | string;
  count: number;
  percent: number;
}

type Energy = {
  count: number;
  percent: number;
}

type Total = {
  cost: Cost;
  energy: Energy;
  sessionSize: Energy;
  public_percent: number;
  home_percent: number;
}


export enum VehicleConnectionState {
  CONNECTED = "CONNECTED",
  NOT_CONNECTED = "NOT_CONNECTED",
  DISCONNECTED = "DISCONNECTED",
  NOT_FOUND = "NOT_FOUND"
}


export type VPICVehicleInfo = {
  carBrand: string
  carModel: string
  carYear: number
  vin: string
}


export type Battery = {
  percentRemaining: number;
  range: number;
  meta: Meta
}

export type Charge = {
  isPluggedIn: boolean;
  state: string;
  meta: Meta
}

export type Meta = {
  fetchedAt: Date
}

export type Location = {
  data: {
    latitude: number;
    longitude: number;
    address: string;
  }
  meta: Meta
}

export type Odometer = {
  distance: number;
  meta: Meta
}


export type VehicleLocation = {
  name: string,
  vin: string
  id: string;
  location: Location;
}


/**
 * Smartcar
 */


export type SmartCarOnCompleteFunctionParams = {
  err: any, code: string; status: any
} | null | Error

export type SmartcarType = typeof Smartcar

export enum SmartCarSelectType {
  BRAND = "BRAND",
  SINGLE = "SINGLE"
}

export type SmartCarState = {
  organizationId: string;
  selectType: SmartCarSelectType;
  permissions: string[];
  redirectUri: string;
}

export type AuthorizeSmartCarPayload = {
  code: string,
  state: string
}


/**
 * Tesla
 */
export enum TeslaCommand {
  // Tesla vehicle commands (Fleet manager and Driver)
  ACTUATE_FRONT_TRUNK = "ActuateFrontTrunk",
  ACTUATE_REAR_TRUNK = "ActuateRearTrunk",
  FLASH_LIGHTS = "FlashLights",
  HONK_HORN = "HonkHorn",
  LOCK = "Lock",
  UNLOCK = "Unlock",
  REMOTE_START = "RemoteStart",
  WAKE_UP = "WakeUp",
  OPEN_CHARGE_PORT = "OpenChargePort",
  CLOSE_CHARGE_PORT = "CloseChargePort",

  // Fleets API commands (Fleet manager-only)
  SEND_VIRTUAL_KEY_LINK_SMS = "SendVirtualKeyLinkSms",
  ENABLE_SYSTEM_MANAGED_DRIVE_PIN = "EnableSystemManagedDrivePIN",
  DISABLE_SYSTEM_MANAGED_DRIVE_PIN = "DisableSystemManagedDrivePIN",

  // Tesla vehicle commands (Fleet manager-only)
  ENABLE_DRIVE_PIN = "EnableDrivePIN",
  DISABLE_DRIVE_PIN = "DisableDrivePIN",

  ENABLE_ANTI_THEFT = "EnableAntiTheft",
  DISABLE_ANTI_THEFT = "DisableAntiTheft",
}


export type TeslaDataAccessorHook = ReturnType<typeof useVehicleTeslaAsync>;

export type TeslaKeyStatusDataAccessorHook = ReturnType<typeof useVehicleTeslaKeyStatusAsync>;
export type TeslaAlertsDataAccessorHook = ReturnType<typeof useVehicleTeslaAlertsAsync>;


export enum TeslaManagementMode {
  MANUAL = "MANUAL",
  SYSTEM = "SYSTEM",
}

export type TeslaAuthState = {
  organizationId: string,
  scopes: string[],
  redirectUri: string
}


export type AuthorizeGeotabPayload = {
  username: string
  password: string
  database: string
}


export type AuthorizeTeslaPayload = {
  state: TeslaAuthState,
  code: string
}

export type SendTeslaKeySmsPayload = {
  phoneNumber: string
}

export type UpdateTeslaPayload = {
  command: TeslaCommand
} |
  {
    command: TeslaCommand.ENABLE_DRIVE_PIN
    pin: string
  } | {
  command: TeslaCommand.SEND_VIRTUAL_KEY_LINK_SMS
  phoneNumber: string
}


type TeslaDoorState = {
  open: boolean;
}

type TeslaTrunkState = {
  open: boolean;
}

export type TeslaVehicleDoors = {
  frontDriver: TeslaDoorState;
  frontPassenger: TeslaDoorState;
  rearDriver: TeslaDoorState;
  rearPassenger: TeslaDoorState;
}

export type TeslaVehicleCharge = {
  limit: number,
  port: {
    open: boolean
  }
}

export type TeslaVehicleTrunks = {
  canActuate: boolean;
  front: TeslaTrunkState;
  rear: TeslaTrunkState;
}

export type TeslaRemoteStart = {
  supported: boolean;
  enabled: boolean;
  active: boolean;
}

export type TeslaDrivePin = {
  drive: {
    managementMode: TeslaManagementMode,
    pin: string | null,
    enabled: boolean
  }
  antiTheft: {
    enabled: boolean
  }
}


export enum TeslaVehicleStatus {
  IN_SERVICE = "IN_SERVICE",
  PARKED = "PARKED",
  CHARGING = "CHARGING",
  IN_MOTION = "IN_MOTION",

  // Helper enum, Asleep status is not coming by BE.
  ASLEEP = "ASLEEP"
}

export enum TeslaError {
  E_ASLEEP = "E_ASLEEP",
  E_VIRTUAL_KEY_NOT_PAIRED = "E_VIRTUAL_KEY_NOT_PAIRED",
  E_IN_VALET_MODE = "E_IN_VALET_MODE",
  E_UNKNOWN = "E_UNKNOWN",
}

export type TeslaVehicleInfo = {
  inService: boolean
  state: TeslaVehicleStatus;
  speed: number;
  doors: TeslaVehicleDoors;
  trunks: TeslaVehicleTrunks;
  locked: boolean;
  charge: TeslaVehicleCharge
  remoteStart: TeslaRemoteStart;
}

export type TeslaKeyStatus = {
  key: {
    supported: boolean,
    paired: boolean,
    accessType: string,
  }
}


/**
 * Vehicle Common Types
 */

interface VehicleInfoMeta {
  meta: {
    fetchedAt: Date
  };
}

interface VehicleBatteryInfo {
  percentage: number;
  range: number;
}

interface VehicleChargeInfo {
  connected: boolean;
  charging: boolean;
}

interface VehicleOdometerInfo {
  odometer: number;
}

interface VehicleLocationInfo {
  latitude: number;
  longitude: number;
}

interface VehicleTpmsInfo {
  frontLeft: number;
  frontRight: number;
  rearLeft: number;
  rearRight: number;
}

export interface VehicleBatteryInfoWithMeta extends VehicleInfoMeta {
  data: VehicleBatteryInfo;
}

export interface VehicleChargeInfoWithMeta extends VehicleInfoMeta {
  data: VehicleChargeInfo;
}

export interface VehicleOdometerInfoWithMeta extends VehicleInfoMeta {
  data: VehicleOdometerInfo;
}

export interface VehicleLocationInfoWithMeta extends VehicleInfoMeta {
  data: VehicleLocationInfo;
}

export interface VehicleTpmsInfoWithMeta extends VehicleInfoMeta {
  data: VehicleTpmsInfo;
}


export type VehicleTelemetry = {
  battery?: VehicleBatteryInfoWithMeta
  charge?: VehicleChargeInfoWithMeta
  location?: VehicleLocationInfoWithMeta
  odometer?: VehicleOdometerInfoWithMeta
  tpms?: VehicleTpmsInfoWithMeta
}


export type VehicleDataAccessorHook = ReturnType<typeof useVehicleAsync>;


export type Vehicle = {
  id: string;
  name: string;
  carBrand: string;
  carModel: string;
  carYear?: string;
  plateNumber: string;
  vin: string;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  deletedAt?: string;
  version: number;
  geotabConnectedAt: Date;
  geotabConnectionState: VehicleConnectionState;
  geotabDisconnectedAt: Date;
  smartcarConnectionState: VehicleConnectionState;
  smartcarConnectedAt: Date;
  teslaConnectionState: VehicleConnectionState
  teslaConnectedAt: Date
  telemetry?: VehicleTelemetry
  imagesBaseUrl?: string
  users: Driver[];
  organizationId: string;
}

export type VehicleSmartcarInfo = {
  id: string;
  smartcarConnected: boolean;
  smartcarConnectedAt: Date;
  odometer: Odometer;
  charge: Charge;
  battery: Battery;
  location: Location;
}

export type VehicleChargingDetail = {
  id: string;
  organizationId: string
  vehicleId: string
  userId: string
  connectorId: string;
  chargerName: string;
  network: string;
  status: string;
  type: string;
  cost: number;
  energyDelivered: string;
  duration: number;
  maxSessionDuration?: Date;
  startDate: number;
  paid: boolean;
  invoiceId: string;
  createdAt: string;
  updatedAt: string;
  user: Driver;
  vehicle: Vehicle;
  version: number
}


export type VehicleChargingSummary = {
  public: Public;
  home: Home;
  total: Total;
}


export type VehicleSliceState = {
  vehicles: Vehicle[]
  meta: PaginationMeta | null
  isVehiclesLoading: boolean,
  helperMessage: string | null
};


export type MixedVehicleData = {
  vehicleSmartcarInfo: VehicleSmartcarInfo;
  vehicle: Vehicle;
}

export type CachedVehicleData = {
  lastFetchedAt: number,
  vehicle: Vehicle
}


/**
 * Filter
 */

export type VehiclesTableFilters = {
  connection?: {
    $in: VehicleConnectionState[]
  },
  createdAt?: {
    $within?: Period,
    $gte?: string,
    $lt?: string
  },
  q?: {
    $eq: string
  }
}


/**
 * Form
 */

export enum AddVehicleFlowType {
  MANUAL,
  SMARTCAR,
  TESLA,
  GEOTAB
}


export enum AddVehicleGeotabStep {
  STARTER,
  FORM,
}

export enum VehicleDetailsEditFormMode {
  NAME, PLATE
}


export type VehicleFormVehicleSelectorState = SelectorStringOption


export type TeslaAlerts = TeslaAlert[]

export type TeslaAlert = {
  body: string
  createdAt: string
}


export enum VehicleDetailsRouteType {
  TESLA_CONTROLS,
  TESLA_ALERTS,
  DEFAULT
}


interface TeslaInvoice {
  displayName: string;
  fullName: string;
}

export type TeslaMultipleDownloadResponse = {
  message: string,
  url: string,
  failed: string[]
}


export interface TeslaChargingWithTeslaInvoice extends Charging {
  teslaInvoice: TeslaInvoice;
}