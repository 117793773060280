import { useAppDispatch, useAppSelector } from "hooks/redux.hooks";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { setIsFleetGroupsLoading, setFleetGroups } from "redux/slices/fleetGroupsSlice";
import { getFleetGroupAsync, getFleetGroupsAsync } from "services/fleet-group.services";
import { FleetGroup } from "types/fleet-group.types";

export const useFleetGroupsAsync = () => {
    const dispatch = useAppDispatch()

    const getFleetGroupsAsyncCallback = useCallback(
        async () => {
            try {
                dispatch(setIsFleetGroupsLoading(true));
                dispatch(setFleetGroups((await getFleetGroupsAsync()).data ?? []));
            } catch (error) {
                console.error(error)
            } finally {
                dispatch(setIsFleetGroupsLoading(false));
            }
        },
        [dispatch],
    )


    useEffect(() => {
        getFleetGroupsAsyncCallback();
    }, [getFleetGroupsAsyncCallback]);


    return { ...useAppSelector((state) => state.fleetGroups), refetch: getFleetGroupsAsyncCallback }
}



export const useFleetGroupAsync = (fleetGroupsId: string) => {
    const [fleetGroup, setFleetGroup] = useState<FleetGroup | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)

    const getFleetGroupAsyncCallback = useCallback(
        async () => {
            try {
                setIsLoading(true)
                setFleetGroup((await getFleetGroupAsync(fleetGroupsId)).data)
            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        },
        [fleetGroupsId],
    )


    useEffect(() => {
        if (!fleetGroupsId) {
            return
        }
        getFleetGroupAsyncCallback();
    }, [fleetGroupsId, getFleetGroupAsyncCallback]);


    return { fleetGroup, isLoading, refetch: getFleetGroupAsyncCallback }
}
