import React from "react";
import SettingsIcon from "./SettingsIcon";

type Props = {};

function SettingsMenuIconFilled({}: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.27081 22.1084L8.87216 18.9086C8.65697 18.8275 8.45235 18.7252 8.25832 18.6088C8.06781 18.4924 7.87731 18.3689 7.69739 18.2348L4.72341 19.4837L1.97168 14.7317L4.54701 12.7808C4.52937 12.6643 4.52232 12.5514 4.52232 12.4421V11.7683C4.52232 11.6589 4.52937 11.546 4.54701 11.4296L1.97168 9.47868L4.72341 4.73019L7.69739 5.97906C7.88084 5.845 8.07134 5.72153 8.27243 5.60511C8.47352 5.48869 8.67108 5.38638 8.87216 5.30524L9.27081 2.10547H14.7707L15.1694 5.30524C15.3846 5.3899 15.5892 5.48869 15.7832 5.60511C15.9737 5.72153 16.1642 5.845 16.3442 5.97906L19.3181 4.73019L22.0699 9.47868L19.4945 11.4296C19.5122 11.546 19.5192 11.6589 19.5192 11.7683V12.4421C19.5192 12.5514 19.5016 12.6643 19.4698 12.7808L22.0452 14.7317L19.2934 19.4837L16.3442 18.2348C16.1607 18.3689 15.9702 18.4924 15.7691 18.6088C15.568 18.7252 15.3705 18.8275 15.1694 18.9086L14.7707 22.1084H9.27081ZM12.0719 15.6101C13.0386 15.6101 13.8641 15.2679 14.5485 14.5835C15.2329 13.8991 15.5751 13.0736 15.5751 12.1069C15.5751 11.1403 15.2329 10.3148 14.5485 9.63038C13.8641 8.94598 13.0386 8.60378 12.0719 8.60378C11.1053 8.60378 10.2586 8.94598 9.58479 9.63038C8.91097 10.3148 8.5723 11.1368 8.5723 12.1069C8.5723 13.0736 8.91097 13.8991 9.58479 14.5835C10.2586 15.2679 11.0877 15.6101 12.0719 15.6101Z"
        fill="#0C0C0C"
      />
    </svg>
  );
}

export default SettingsMenuIconFilled;
