import React from "react";
import SecondaryButton from "./SecondaryButton";

type Props = { onSecondaryAddButtonClicked: () => void };

function SecondaryAddButton({ onSecondaryAddButtonClicked }: Props) {
  return <SecondaryButton height={35} text="Add" onClick={onSecondaryAddButtonClicked} />;
}

export default SecondaryAddButton;
