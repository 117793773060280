import SmartcarBrands from "assets/images/vehicle/SmartcarBrands.png";
import Col from "components/custom/Col";
import { BlackOverlineText300 } from "components/text/Text";

type Props = {};

function AddVehicleFlowSelectionSmartcarCardFooter({}: Props) {
  return (
    <Col className="mt-1">
      <BlackOverlineText300 className="mb-1">Makes:</BlackOverlineText300>
      <img width={200} src={SmartcarBrands} alt="Smartcar brands" />
    </Col>
  );
}

export default AddVehicleFlowSelectionSmartcarCardFooter;
