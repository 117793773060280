import React from "react";
import { useHistory } from "react-router";
import Row from "../custom/Row";
import CrossIcon from "../icon/icons/CrossIcon";
import LinkButton from "./LinkButton";

type Props = {};

function VehicleLocatorForwardingLinkButton({}: Props) {
  const history = useHistory();
  return (
    <Row className="mt-3">
      <LinkButton
        textClassName="fs-17px font-weight-500"
        text="Live Vehicle Locator"
        onClick={() =>
          window.open(
            "/vehicle-locator",
            "_blank" // <- This is what makes it open in a new window.
          )
        }
      />
      <CrossIcon />
    </Row>
  );
}

export default VehicleLocatorForwardingLinkButton;
