
export enum PaymentState {
    UNPAID = 'UNPAID',
    PAID = 'PAID',
    VOID = 'VOID'
}



export enum PayerType {
    DRIVER = "DRIVER",
    ORGANIZATION = "ORGANIZATION"
}