import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DashboardInsights, DashboardSliceState, DashboardInsightFilter, DashboardOverviews } from "types/dashboard.types";

const initialState: DashboardSliceState = {
  insightsState: {
    filter: DashboardInsightFilter.LastWeek,
    isLoading: false,
    insights: null
  },
  overviewsState: {
    isLoading: false,
    overviews: null
  }
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setDashboardOverviewsIsLoading: (state, action: PayloadAction<boolean>) => {
      state.overviewsState.isLoading = action.payload;
    },
    setDashboardInsightsIsLoading: (state, action: PayloadAction<boolean>) => {
      state.insightsState.isLoading = action.payload;
    },
    setDashboardInsightsFilter: (state, action: PayloadAction<DashboardInsightFilter>) => {
      state.insightsState.filter = action.payload;
    },
    setDashboardInsights: (state, action: PayloadAction<DashboardInsights>) => {
      state.insightsState.insights = action.payload;
    },
    setDashboardOverviews: (state, action: PayloadAction<DashboardOverviews>) => {
      state.overviewsState.overviews = action.payload;
    },
  },
});

export const {
  setDashboardInsightsIsLoading,
  setDashboardOverviewsIsLoading,
  setDashboardInsightsFilter,
  setDashboardInsights,
  setDashboardOverviews,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
