// React
import { useState } from "react";

// Third party
import { useTranslation } from "react-i18next";

// Components
import Col from "../../components/custom/Col";
import Head from "../../layout/head/Head";
import { AuthSignUpFormFlowSteps } from "../../types/auth.types";

import SignUpFormFlow from "components/auth-components/SignUpFormFlow";

// Img
import { AuthSignUpImageMap, AuthSignUpImages } from "utils/auth.utils";
import Row from "../../components/custom/Row";

export default function SignUpPage() {
  const [formFlowStep, setFormFlowStep] = useState<AuthSignUpFormFlowSteps>(AuthSignUpFormFlowSteps.START);
  const { t } = useTranslation("common");

  const pageBanner = AuthSignUpImageMap.get(formFlowStep);

  return (
    <>
      <Head title={t("auth.sign_up.title")}></Head>
      <Row className="screen-height-container">
        <Col className="bg-white scrollable-container">
          <SignUpFormFlow formFlowStep={formFlowStep} setFormFlowStep={setFormFlowStep} />
        </Col>
        <Col className={`h-100 d-none d-lg-flex align-items-center ${pageBanner?.align}`}>
          {AuthSignUpImages.map(({ Banner, id }) => (
            <Banner className={`${id === formFlowStep ? "d-flex" : "d-none"}`} />
          ))}
        </Col>
      </Row>
    </>
  );
}
