import React from "react";
import ButtonWithIcon from "./ButtonWithIcon";

type FormButtonProps = {
  readonly onFormButtonPressed?: () => void;
  readonly text?: string;
  readonly className?: string;
  readonly type?: string;
  readonly isLoading?: boolean;
  readonly isDisabled?: boolean;
};

function FormButton({ onFormButtonPressed, className, type = "submit", isLoading, isDisabled, text }: FormButtonProps) {
  return (
    <ButtonWithIcon
      isLoading={isLoading}
      isDisabled={isDisabled}
      type={type}
      text={text}
      onClick={onFormButtonPressed}
      className={className}
    />
  );
}

export default FormButton;
