import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ProceedToSignInButton from "../../button/ProceedToSignInButton";

import FormCompletedContent from "../../form/FormCompletedContent";
import CheckedTickCircleIcon from "../../icon/icons/CheckedTickCircleIcon";
import ThreeDotWithCircleBackgroundIcon from "../../icon/icons/ThreeDotWithCircleBackgroundIcon";
import TickCircleIcon from "../../icon/icons/TickCircleIcon";

type Props = {};

function ForgotPasswordFormFlowCompletedStep({}: Props) {
  const { t } = useTranslation("common");
  const history = useHistory();

  const title = t("auth.forgot_password.flow_steps.completed.title");
  const description = t("auth.forgot_password.flow_steps.completed.description");

  return (
    <FormCompletedContent icon={<TickCircleIcon fill={"#23D206"} width={100} height={100} />}>
      <>
        <FormCompletedContent.Title>{title}</FormCompletedContent.Title>

        <FormCompletedContent.Description>{description}</FormCompletedContent.Description>

        <FormCompletedContent.Buttons
          buttons={[<ProceedToSignInButton onProceedToSignInButtonPressed={() => history.replace("/auth/login")} />]}
        />
      </>
    </FormCompletedContent>
  );
}

export default ForgotPasswordFormFlowCompletedStep;
