import { VehicleChargingDetail } from "types/vehicle.types";
import BluedotInAppIcon from "../components/icon/icons/BluedotInAppIcon";

import ChargerIconAC from "../components/icon/icons/ChargerIconAC";
import ChargerIconDC from "../components/icon/icons/ChargerIconDC";
import HomeIcon from "../components/icon/icons/HomeIcon";

import { Driver } from "types/driver.types";
import { PayerType } from "types/payment.types";
import OtherNetworkIcon from "../components/icon/icons/OtherNetworkIcon";
import TeslaIcon from "../components/icon/icons/TeslaIcon";
import {
  BaseCharging,
  Charging,
  ChargingNetwork,
  ChargingPaymentState,
  TotalEnergyAndCostByPeriodAndNetwork
} from "../types/charging-sessions.types";
import { getMonthNameFromYearWeekFormat, getWeekIntervalFromYearWeekFormat } from "./date.utils";
import { getFormattedCostByNetwork } from "./format.utils";
import { getVehicleSummariesAsObjectForCsv } from "./vehicle.utils";
import { AppColors } from "./colors.utils";


export const getWhoPaysDisplayTextByPayerType = (payer: PayerType) => {
  switch (payer) {
    case PayerType.DRIVER:
      return "Driver";
    case PayerType.ORGANIZATION:
      return "Organization";
    default:
      return "-";
  }
};

export const getChargingPayStatDisplayContent = (paymentState: ChargingPaymentState) => {
  switch (paymentState) {
    case ChargingPaymentState.PAID:
      return {
        label: "Paid",
        textClass: "success-text",
        color: "#2BA318"
      };

    case ChargingPaymentState.UNPAID:
      return {
        label: "Unpaid",
        textClass: "text-cherry-500",
        color: "#C4003E"
      };
    default:
      return {
        label: "-",
        textClass: "",
        color: "#C4003E"
      };
  }
};

export const getChargingPayStatDisplayText = (paymentState: ChargingPaymentState) => {
  return getChargingPayStatDisplayContent(paymentState).label;
};

export const getChargerTypeIcon = (type): JSX.Element => {
  switch (type) {
    case "AC":
      return <ChargerIconAC />;
    case "DC":
      return <ChargerIconDC />;
    default:
      return <ChargerIconAC />;
  }
};


export const getChargerNetworks = () => {
  return [
    ChargingNetwork.CHARGE_POINT,
    ChargingNetwork.EVGO,
    ChargingNetwork.ELECTRIFY_AMERICA,
    ChargingNetwork.TESLA,
    ChargingNetwork.HOME,
    ChargingNetwork.OTHER
  ];
};

export const getChargingPaymentStateOptions = () => {
  return [
    { label: "Paid", value: ChargingPaymentState.PAID, textClass: "success-text" },
    { label: "Unpaid", value: ChargingPaymentState.UNPAID, textClass: "text-cherry-100" },
    { label: "Inapplicable", value: ChargingPaymentState.INAPPLICABLE, textClass: "text-canceled" }
  ];
};

export const getChargingNetworkForUrlQuery = (chargingNetwork: ChargingNetwork): string => {
  for (const key in ChargingNetwork) {
    if (ChargingNetwork[key] === chargingNetwork) {
      return key;
    }
  }

  return "";
};

export const mapChargingNetworksForUrlQuery = (chargingNetworks: ChargingNetwork[]) => {
  return chargingNetworks.map((n) => getChargingNetworkForUrlQuery(n)) as (keyof typeof ChargingNetwork)[];
};

export const mapChargingNetworksOnQueryToEnum = (chargingNetworksOnQuery: string[]): ChargingNetwork[] => {
  return chargingNetworksOnQuery.map((noq) => ChargingNetwork[noq]);
};


export function getChargerInfo(id: ChargingNetwork) {
  switch (id) {
    case ChargingNetwork.TESLA:
      return {
        logo: <TeslaIcon />,
        label: "Tesla",
        color: AppColors.cherry500
      };
    case ChargingNetwork.EVGO:
      return {
        logo: <BluedotInAppIcon />,
        label: "EVgo",
        color: AppColors.blue100
      };

    case ChargingNetwork.ELECTRIFY_AMERICA:
      return {
        logo: <BluedotInAppIcon />,
        label: "Electrify America",
        color: AppColors.mint500
      };
    case ChargingNetwork.CHARGE_POINT:
      return {
        logo: <BluedotInAppIcon />,
        label: "ChargePoint",
        color: "#FE7A30"
      };

    case ChargingNetwork.HOME:
      return {
        logo: <HomeIcon />,
        label: "Home",
        color: "#C89FFF",
        sortIndex: 1
      };

    default:
      return {
        logo: <OtherNetworkIcon />,
        label: "Other",
        color: "#909090",
        sortIndex: 2
      };
  }
}

const getNetworkProperties = getChargerInfo;

export function convertSecondsToMinutesAndHours(seconds) {
  if (seconds === 0) {
    return seconds;
  }

  if (!seconds) {
    return "-";
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (minutes === 0 && hours === 0) {
    return `${remainingSeconds}s`;
  }
  if (hours === 0) {
    return `${remainingMinutes}m ${remainingSeconds}s`;
  }
  return `${hours}h ${remainingMinutes}m ${remainingSeconds}s`;
}

export const getUniquePeriodsFromStatistics = (statisticsByPeriod: TotalEnergyAndCostByPeriodAndNetwork[]) => {
  const uniquePeriodsSet = new Set<string>();

  statisticsByPeriod.forEach((statistic) => {
    uniquePeriodsSet.add(statistic.period);
  });

  return Array.from(uniquePeriodsSet);
};

export const getMonthLabelForStatisticGraphByStatisticData = (
  statisticsByPeriod: TotalEnergyAndCostByPeriodAndNetwork[]
) => {
  const uniquePeriods = getUniquePeriodsFromStatistics(statisticsByPeriod);
  const monthPeriods = uniquePeriods.map((p) => getMonthNameFromYearWeekFormat(p));
  const uniqueMonthsSet = new Set<string>();
  monthPeriods.forEach((m) => {
    uniqueMonthsSet.add(m);
  });
  return { monthlyLabels: Array.from(uniqueMonthsSet), uniquePeriods };
};

export const getTooltipWeekLabelForStatisticGraphByYearWeekFormat = (weekYear: string) => {
  const { startOfTheWeek, nextOfTheWeek } = getWeekIntervalFromYearWeekFormat(weekYear);

  return `${startOfTheWeek} - ${nextOfTheWeek}`;
};
export const getSessionStatisticDataForGraphAsWeekOfLastQuarter = (
  statisticsByPeriod: TotalEnergyAndCostByPeriodAndNetwork[]
) => {
  const networksMap = new Map();
  const periodsSet = new Set();

  statisticsByPeriod.forEach(({ period }) => {
    periodsSet.add(period);
  });
  const periods = Array.from(periodsSet);

  statisticsByPeriod.forEach(({ network, totalCost, period }) => {
    if (!network) {
      return;
    }

    if (!networksMap.has(network)) {
      const { label, color, sortIndex } = getNetworkProperties(network as ChargingNetwork);
      networksMap.set(network, {
        data: new Array(periods.length).fill(0),
        label: label,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 1,
        sortIndex: sortIndex ?? 0
      });
    }

    const existingNetwork = networksMap.get(network);
    const periodIndex = periods.indexOf(period);
    existingNetwork.data[periodIndex] = totalCost / 100;
  });

  const networks = Array.from(networksMap.values());

  networks.sort((n1, n2) => n1.sortIndex - n2.sortIndex);
  return networks;
};

export function formatNumber(number) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(number);
}

export function getFormattedNumberForEnergy(energy) {
  if (!energy) {
    return `0.00`;
  }

  return formatNumber(energy);
}

export const getExtraChargingSessionColumnsForCsv = (charging: BaseCharging) => {
  return {
    user: charging.user?.firstName + " " + charging.user?.lastName ?? "",
    energyDelivered: `${getFormattedNumberForEnergy(charging.energyDelivered)}`,
    cost: getFormattedCostByNetwork(charging.cost, charging.network),
    ...getVehicleSummariesAsObjectForCsv(charging?.vehicle)
  };
};

export const getChargingSessionRowForCsv = (chargingRow: Charging | VehicleChargingDetail) => {
  // We will add vehicle details as separated columns.
  const {
    connectorId,
    vehicle,
    user,
    startDate,
    createdAt,
    updatedAt,
    version,
    organizationId,
    vehicleId,
    userId,
    ...otherChargingData
  } = chargingRow;
  return {
    ...otherChargingData,
    ...getExtraChargingSessionColumnsForCsv(chargingRow as BaseCharging)
  };
};


export const getChargingSessionRowWithDriverForCsv = (
  chargingRow: Charging | VehicleChargingDetail,
  driver: Driver
) => {
  // We will add vehicle details as separated columns.
  const {
    connectorId,
    vehicle,
    user,
    startDate,
    createdAt,
    updatedAt,
    version,
    organizationId,
    vehicleId,
    userId,
    ...otherChargingData
  } = chargingRow;

  return {
    ...getExtraChargingSessionColumnsForCsv({ ...chargingRow, user: driver } as BaseCharging),
    ...otherChargingData
  };
};
