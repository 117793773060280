import React from "react";
import { SvgProps } from "types/svg.types";

type Props = {};

function BluedotFleetOsLogo(props: SvgProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7564 15.7604C15.1472 16.3696 14.4225 16.8624 13.6173 17.2029L15.2558 21.082C16.5643 20.528 17.7432 19.7271 18.7332 18.7372C19.7231 17.7473 20.524 16.5683 21.078 15.2598C21.6312 13.9513 21.9375 12.5115 21.9375 11.0008C21.9375 9.49007 21.6312 8.05114 21.078 6.74175C20.524 5.43323 19.7231 4.25426 18.7332 3.26434C17.7432 2.27441 16.5643 1.47355 15.2558 0.91951L13.6173 4.79867C14.4225 5.13915 15.1481 5.63192 15.7564 6.2411C16.3656 6.85028 16.8584 7.575 17.1988 8.38024C17.5393 9.18548 17.7275 10.0712 17.7275 10.9999C17.7275 11.9285 17.5393 12.8143 17.1988 13.6196C16.8584 14.4248 16.3656 15.1504 15.7564 15.7587V15.7604ZM3.2603 18.7372C4.25022 19.7271 5.4292 20.528 6.73771 21.082L8.3762 17.2029C7.57096 16.8624 6.84537 16.3696 6.23707 15.7604C5.62788 15.1513 5.13511 14.4265 4.79464 13.6213C4.45416 12.8161 4.26598 11.9303 4.26598 11.0016C4.26598 10.073 4.45416 9.18723 4.79464 8.38199C5.13511 7.57675 5.62788 6.85116 6.23707 6.24285C6.84625 5.63367 7.57096 5.1409 8.3762 4.80042L6.73771 0.921261C5.4292 1.4753 4.25022 2.27617 3.2603 3.26609C2.27038 4.25601 1.46952 5.43498 0.91548 6.7435C0.361439 8.05202 0.0559711 9.49182 0.0559711 11.0025C0.0559711 12.5132 0.362314 13.9522 0.91548 15.2615C1.46952 16.5701 2.27038 17.749 3.2603 18.739V18.7372Z"
        fill="#1e44ff"
      />
    </svg>
  );
}

export default BluedotFleetOsLogo;
