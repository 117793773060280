import React from "react";

CurrentDriverAssignmentIcon.propTypes = {};

function CurrentDriverAssignmentIcon(props) {
  return (

    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0068 3.40724C12.9859 3.40724 14.5946 5.01593 14.5946 6.99506C14.5946 8.97419 12.9859 10.5864 11.0068 10.5864C9.02765 10.5864 7.41895 8.97771 7.41895 6.99506C7.41895 5.0124 9.02765 3.40724 11.0068 3.40724ZM11.0068 1.99609C8.24447 1.99609 6.00781 4.23628 6.00781 6.99506C6.00781 9.75384 8.24799 11.9976 11.0068 11.9976C13.7656 11.9976 16.0058 9.75737 16.0058 6.99506C16.0058 4.23275 13.7656 1.99609 11.0068 1.99609Z"
        fill="#FE7A30" />
      <path
        d="M15.7851 13.9519C15.8486 14.1036 15.778 14.28 15.6263 14.3435C15.2947 14.4846 14.9948 14.6857 14.7444 14.9397C14.4939 15.1902 14.2893 15.4901 14.1517 15.8217C14.0106 16.1533 13.933 16.5202 13.933 16.9012C13.933 17.2822 14.0106 17.6491 14.1517 17.9807C14.2928 18.3124 14.4939 18.6122 14.7479 18.8662C14.9984 19.1167 15.2982 19.3213 15.6299 19.4589C15.7815 19.5224 15.8556 19.6988 15.7886 19.8505L15.0407 21.625C14.9772 21.7767 14.8008 21.8473 14.6491 21.7838C14.0141 21.5156 13.4461 21.1241 12.9663 20.6478C12.4865 20.168 12.0985 19.5965 11.8303 18.9615C11.5622 18.3265 11.4141 17.6279 11.4141 16.8977C11.4141 16.1674 11.5622 15.4689 11.8303 14.8339C12.0985 14.1989 12.4865 13.6274 12.9663 13.1476C13.4461 12.6678 14.0176 12.2797 14.6526 12.0116C14.8043 11.9481 14.9807 12.0187 15.0442 12.1704L15.7921 13.9449L15.7851 13.9519Z"
        fill="#FE7A30" />
      <path
        d="M17.6221 19.8555C17.5586 19.7038 17.6291 19.5274 17.7808 19.4639C18.1125 19.3228 18.4123 19.1217 18.6628 18.8713C18.9133 18.6208 19.1179 18.3209 19.2555 17.9893C19.3966 17.6577 19.4742 17.2908 19.4742 16.9098C19.4742 16.5288 19.3966 16.1619 19.2555 15.8303C19.1144 15.4986 18.9133 15.1988 18.6593 14.9483C18.4088 14.6978 18.1089 14.4932 17.7773 14.3521C17.6256 14.2886 17.5515 14.1122 17.6186 13.9605L18.3665 12.186C18.43 12.0343 18.6064 11.9637 18.7581 12.0272C19.3931 12.2953 19.9611 12.6834 20.4408 13.1597C20.9206 13.6394 21.3087 14.211 21.5768 14.846C21.8449 15.481 21.9931 16.1795 21.9931 16.9098C21.9931 17.64 21.8449 18.3386 21.5768 18.9736C21.3087 19.6086 20.9206 20.1766 20.4408 20.6564C19.9611 21.1326 19.3895 21.5242 18.7545 21.7923C18.6028 21.8558 18.4264 21.7853 18.3629 21.6336L17.615 19.8591L17.6221 19.8555Z"
        fill="#FE7A30" />
      <path
        d="M8.37484 15.4072C8.33603 15.6119 8.30427 15.82 8.28311 16.0281V16.0528C8.25136 16.3351 8.23724 16.5926 8.23724 16.8501C8.23724 16.8607 8.23724 16.8678 8.23724 16.8784C8.23724 16.8854 8.23724 16.896 8.23724 16.903C8.23724 16.9454 8.23725 16.9877 8.24077 17.0301C8.24077 17.1782 8.25136 17.3299 8.26194 17.4746C8.33603 18.5647 8.61471 19.616 9.08392 20.5861H3.41114V20.2475C3.41114 17.5804 5.58076 15.4072 8.25135 15.4072H8.3713M9.54606 13.9961H8.25135C4.80111 13.9961 2 16.7937 2 20.2475V21.4963C2 21.7715 2.22225 21.9973 2.50095 21.9973H10.7138C11.1336 21.9973 11.3594 21.5175 11.1019 21.1823C10.2834 20.1134 9.7648 18.801 9.66955 17.3687C9.65897 17.2382 9.65191 17.1077 9.64839 16.9771C9.64839 16.9524 9.64485 16.9242 9.64485 16.8995C9.64485 16.8925 9.64485 16.8854 9.64485 16.8819C9.64485 16.8713 9.64485 16.8607 9.64485 16.8501C9.64485 16.6279 9.65896 16.4092 9.68012 16.194C9.68012 16.1869 9.68012 16.1798 9.68012 16.1728C9.70129 15.9823 9.72953 15.7918 9.76481 15.6083C9.82478 15.2838 9.90591 14.9698 10.0117 14.6629C10.1211 14.3348 9.88476 13.9996 9.54255 13.9996L9.54606 13.9961Z"
        fill="#FE7A30" />
    </svg>

  );
}

export default CurrentDriverAssignmentIcon;