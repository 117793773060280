import React from "react";
import { getDateAsFromNowFormatWithFetchedPrefix } from "../../../utils/date.utils";
import BlackInfoIcon from "../../icon/icons/BlackInfoIcon";
import MuiTooltip from "../../tooltip/MuiTooltip";

type Props = {
  readonly fetchedAt?: Date | number;
};

function VehicleDetailsFetchedInfoTooltip({ fetchedAt }: Props) {
  return (
    <MuiTooltip title={getDateAsFromNowFormatWithFetchedPrefix(fetchedAt)??'Vehicle info has not been fetched yet.'} placement="top">
      <div className="cursor-pointer">
        <BlackInfoIcon className="ml-1" width={16} />
      </div>
    </MuiTooltip>
  );
}

export default VehicleDetailsFetchedInfoTooltip;
