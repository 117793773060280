import { sendbirdSelectors, useSendbirdStateContext } from "@sendbird/uikit-react";
import { isEmpty } from "../utils/Utils";
import { useSelector } from "react-redux";

function useSendbirdSdkAccessor() {
  const context = useSendbirdStateContext();
  const sdkInstance = sendbirdSelectors.getSdk(context);
  const isSdkInstanceGenerated = !isEmpty(sdkInstance);

  const isSenbirdSdkAvailable = isSdkInstanceGenerated 

  return { sdkInstance, isSenbirdSdkAvailable };
}

export { useSendbirdSdkAccessor };
