import { CSVLink } from "react-csv";

import { PaginatedApiResponse } from "types/api.types";
import { useExportTableWithSemaphore } from "hooks/table.hooks";
import ExportIcon from "components/icon/icons/ExportIcon";
import { DateTime } from "luxon";
import React from "react";
import OutlinedButtonWithIcon from "./OutlinedButtonWithIcon";

type Props = {
  readonly totalPages: number;
  readonly rowSizePerPage: number;
  readonly fetchListAsync: (query: string) => Promise<PaginatedApiResponse<any>>;
  readonly csvRowFormatter: (c: any) => any;
  readonly fileName: string;
};

function PaginatedTableCsvLinkButton({ fetchListAsync, csvRowFormatter, totalPages, rowSizePerPage, fileName }: Props) {
  const { allListForCsv, isLoading, excelRef, progressText, handleExportTableWithSemaphore } =
    useExportTableWithSemaphore(fetchListAsync, csvRowFormatter, totalPages, rowSizePerPage);

  if (totalPages === 0) return (<></>);

  return (
    <>
      <div className="d-flex flex-1 justify-content-end ">
        <OutlinedButtonWithIcon
          onClick={handleExportTableWithSemaphore}
          className={`${isLoading && "cursor-not-allowed"}`}
          leftIcon={<ExportIcon />}
          text={isLoading ? progressText : "Export"}
        />
      </div>
      <CSVLink
        ref={excelRef}
        data={allListForCsv}
        asyncOnClick={true}
        filename={`${DateTime.now().toLocaleString(DateTime.DATE_FULL)}-${fileName}.csv`}
      />
    </>
  );
}

export default PaginatedTableCsvLinkButton;
