import React from "react";

type Props = {};

function ChargingSessionMenuIconOutlined({}: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0016 21.1887C6.93558 21.1887 2.81152 17.0647 2.81152 11.9987H4.22266C4.22266 16.2885 7.71171 19.7776 12.0016 19.7776C16.2914 19.7776 19.7805 16.2885 19.7805 11.9987C19.7805 7.70878 16.2914 4.21974 12.0016 4.21974C9.63086 4.21974 7.41889 5.28162 5.93013 7.13374L4.82945 6.25178C6.58632 4.06451 9.19693 2.80859 12.0016 2.80859C17.0676 2.80859 21.1916 6.93265 21.1916 11.9987C21.1916 17.0647 17.0711 21.1887 12.0016 21.1887Z"
        fill="#6F7981"
      />
      <path d="M8.26161 8.56916C8.24045 8.49861 8.19811 8.4351 8.14519 8.38571L6.88574 7.21094" fill="#6F7981" />
      <path
        d="M4.69908 5.16793L3.60545 4.14838C3.549 4.09546 3.48549 4.06018 3.41493 4.04254C3.34438 4.0249 3.27383 4.02843 3.2068 4.0496C3.13977 4.07077 3.08685 4.1131 3.05157 4.16602C3.01277 4.22247 2.99512 4.28597 2.99512 4.35652L3.01277 8.88629C3.01277 9.1156 3.22443 9.31669 3.45727 9.29905L7.97998 9.00624C8.05053 9.00271 8.11405 8.97801 8.16696 8.93568C8.21988 8.89334 8.25516 8.8369 8.27279 8.76987C8.29043 8.70284 8.2869 8.63229 8.26573 8.56526"
        fill="#6F7981"
      />
      <path
        d="M14.171 6.74982C14.1463 6.6828 14.0969 6.62635 14.0334 6.59107C13.9699 6.55579 13.8993 6.54168 13.8288 6.55227C13.7582 6.56285 13.6912 6.59813 13.6418 6.65105L8.55111 12.0592C8.50877 12.1051 8.48056 12.1616 8.46997 12.2215C8.45939 12.2815 8.46997 12.345 8.49114 12.4015C8.51583 12.4579 8.55817 12.5073 8.60756 12.5426C8.66048 12.5778 8.72046 12.5955 8.78396 12.5955H10.8971L9.75059 16.3209C9.72942 16.3879 9.73295 16.462 9.75765 16.529C9.78234 16.5961 9.83172 16.6525 9.89522 16.6878C9.95872 16.7231 10.0293 16.7372 10.0998 16.7266C10.1704 16.716 10.2374 16.6843 10.2868 16.6313L15.3775 11.2231C15.4199 11.1773 15.4481 11.1208 15.4587 11.0609C15.4692 11.0009 15.4587 10.9374 15.4375 10.8809C15.4128 10.8245 15.3704 10.7751 15.3211 10.7434C15.2681 10.7081 15.2082 10.6904 15.1447 10.6904H13.0315L14.178 6.96502C14.1992 6.89799 14.1957 6.82391 14.171 6.75688V6.74982Z"
        fill="#6F7981"
      />
    </svg>
  );
}

export default ChargingSessionMenuIconOutlined;
