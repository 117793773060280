import { useCallback, useMemo, useState } from "react";
import { deleteVehicleDriverScheduleAsync, updateVehicleDriverScheduleAsync } from "services/vehicle-driver-schedule.services";
import { VehicleDriverSchedule, VehicleDriverSchedulePeriod, VehicleDriverSchedulePeriodKey } from "types/vehicle-driver-schedule.types";
import { handleApiErrorResponse } from "utils";
import { ImmediateDateSpecifier } from "utils/date.utils";
import { useAlertManager } from "./alert.hooks";

export const useVehicleDriverSchedulePeriodSelector = () => {

    const defaultVehicleDriverSchedulePeriod: VehicleDriverSchedulePeriod = useMemo(() => {
        return {
            startsAt: undefined,
            endsAt: undefined,
        };
    }, []);
    const [vehicleDriverSchedulePeriodSelector, setVehicleDriverSchedulePeriodSelector] =
        useState(defaultVehicleDriverSchedulePeriod);


    const [selectedScheduleIdToDelete, setSelectedScheduleIdToDelete] = useState<
        { schedule: VehicleDriverSchedule; isActive: boolean } | undefined
    >(undefined);

    const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager()

    const handleSetVehicleSchedulePeriod = useCallback((key: VehicleDriverSchedulePeriodKey, value?: Date) => {
        setVehicleDriverSchedulePeriodSelector((s) => {
            return { ...s, [key]: value };
        });
    }, []);




    const handleDeleteScheduleAsync = useCallback(async () => {
        if (!selectedScheduleIdToDelete) {
            return;
        }
        try {
            const { isActive, schedule } = selectedScheduleIdToDelete;
            if (isActive) {
                await updateVehicleDriverScheduleAsync(schedule.id, {
                    endsAt: ImmediateDateSpecifier,
                    startsAt: schedule.startsAt,
                });
            } else {
                await deleteVehicleDriverScheduleAsync(schedule.id);
            }
            setSelectedScheduleIdToDelete(undefined);
            handleOpenSuccessAlert("Schedule is successfully deleted!");

        } catch (error) {
            console.error(error);
            handleOpenErrorAlert(handleApiErrorResponse(error));
        }
    }, [handleOpenErrorAlert, handleOpenSuccessAlert, selectedScheduleIdToDelete]);



    const handleResetState = useCallback(() => {
        setVehicleDriverSchedulePeriodSelector(defaultVehicleDriverSchedulePeriod);
    }, [defaultVehicleDriverSchedulePeriod]);

    return {
        handleResetState,
        handleSetVehicleSchedulePeriod,
        vehicleDriverSchedulePeriodSelector,
        setVehicleDriverSchedulePeriodSelector,
        setSelectedScheduleIdToDelete,
        selectedScheduleIdToDelete,
        handleDeleteScheduleAsync
    };
};