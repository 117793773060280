import React from "react";
import { FormGroup } from "reactstrap";

import Col from "components/custom/Col";

import { BlackBodyText100, NeutralTitleSmallTextBlack } from "components/text/Text";
import { ErrorBoundary } from "react-error-boundary";
import DriverReimbursementEditor from "../../reimbursement/ReimbursementToggleEditor";
import HorizontalLine from "../../line/HorizontalLine";
import ReimbursementMap from "../../reimbursement/ReimbursementMap";
import { DriverFormReimbursementStepStateHooks, ReimbursementFormStepsCommonProps } from "types/reimbursement.types";
import DriverFormReimbursementFixedEnergyRateInput from "./DriverFormReimbursementFixedEnergyRateInput";
import VioletInfoBadge from "../../badge/VioletInfoBadge";

type Props = ReimbursementFormStepsCommonProps & DriverFormReimbursementStepStateHooks & {
  isSmallTitleLabel?: boolean
  isToggleNeeded?: boolean
}

export default function DriverFormReimbursementSetupStep({
                                                           isSmallTitleLabel = true,
                                                           isToggleNeeded = true,
                                                           ...props
                                                         }:
                                                           Props
) {

  return (
    <ErrorBoundary fallback={<>An error occured</>}>
      {isToggleNeeded && (<DriverReimbursementEditor
        onStatusSwitchChanged={(props.setIsReimbursementActive)}
        isReimbursementActive={props.isReimbursementActive}
        label={"Home charging reimbursement status"}
        description={"Include driver for home charging reimbursement process."} />)}

      {(props.isReimbursementActive || !isToggleNeeded) && <div className="d-flex flex-column w-100  p-0">
        <Col md={"12"}>
          <FormGroup>
            <div className="relative  d-flex align-items-center">
              {/**     <span className="position-absolute dollar-prefix-text">$</span> */}
              <DriverFormReimbursementFixedEnergyRateInput isSmallLabels={true} errors={props.errors}
                                                           control={props.control} />
            </div>
          </FormGroup>
        </Col>

        <HorizontalLine />

        <Col className="mb-3">

          {isSmallTitleLabel ?
            <NeutralTitleSmallTextBlack>Driver's home charging region</NeutralTitleSmallTextBlack>
            : <BlackBodyText100>Driver's home charging region</BlackBodyText100>
          }


          <VioletInfoBadge
            className={"mt-2"}
            title={"Tip:"}
            description={"To get started, when the driver address appears, you can select the desired area on the map using the polygon tool."} />

        </Col>


        <ReimbursementMap
          {...props}
        />

      </div>
      }
    </ErrorBoundary>
  );
}
