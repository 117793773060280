import { ApiResponse, PaginatedApiResponse } from "types/api.types";
import { InboxMessage, UpdateInboxMessagesAsyncPayload } from "types/inbox.types";
import { generateApiEndpoint } from "utils";
import restApiClient from "./client";
import { defaultInboxMessagesQuery } from "utils/inbox.utils";


const service = "inbox";

/**
 * GET: '/api/admin/inbox/messages'
 */
export async function getInboxMessagesAsync(query: string = ""): Promise<PaginatedApiResponse<InboxMessage>> {
  const endpoint = generateApiEndpoint(`${service}/messages`);

  return (await restApiClient.get<PaginatedApiResponse<InboxMessage>>(endpoint + (query || defaultInboxMessagesQuery))).data;
}


/**
 * PATCH: '/api/admin/inbox/messages/archive'
 */
export async function archiveInboxMessagesAsync(payload: UpdateInboxMessagesAsyncPayload) {
  const endpoint = generateApiEndpoint(`${service}/messages/archive`);

  const response = await restApiClient.patch(endpoint, payload);
  return response.data;
}

/**
 * PATCH: '/api/admin/inbox/messages/unarchive'
 */
export async function unArchiveInboxMessagesAsync(payload: UpdateInboxMessagesAsyncPayload) {
  const endpoint = generateApiEndpoint(`${service}/messages/unarchive`);

  const response = await restApiClient.patch(endpoint, payload);
  return response.data;
}


/**
 * PATCH: '/api/admin/inbox/messages/read'
 */
export async function readInboxMessagesAsync(payload: UpdateInboxMessagesAsyncPayload) {
  const endpoint = generateApiEndpoint(`${service}/messages/read`);

  const response = await restApiClient.patch(endpoint, payload);
  return response.data;
}


/**
 * PATCH: '/api/admin/inbox/messages/unread'
 */
export async function unreadInboxMessagesAsync(payload: UpdateInboxMessagesAsyncPayload) {
  const endpoint = generateApiEndpoint(`${service}/messages/unread`);

  const response = await restApiClient.patch(endpoint, payload);
  return response.data;
}