import { Driver } from "types/driver.types";
import DriverDetailsSectionFormBox from "./DriverDetailsSectionFormBox";
import DriverDetailsSectionCard from "./driver-details/DriverDetailsSectionCard";

type Props = {
  isLoading: boolean;
  driver?: Driver;
};
export default function DriverDetailsPageAddressCard({ isLoading, driver }: Props) {
  const streetInfo = driver?.address?.street + " " + driver?.address?.street2;

  return (
    <>
      <DriverDetailsSectionCard
        loadingCount={5}
        isLoading={isLoading}
        headerTitle={"Address"}
        content={
          <>
            <DriverDetailsSectionFormBox label={"Address"} value={streetInfo} />
            <DriverDetailsSectionFormBox label={"State"} value={driver?.address?.state} />
            <DriverDetailsSectionFormBox label={"City"} value={driver?.address?.city} />
            <DriverDetailsSectionFormBox isBorderHidden label={"Post Code"} value={driver?.address?.postalCode} />
          </>
        }
      />
    </>
  );
}
