import { useState } from "react";
import CustomModalWithoutXIcon from "components/modal/CustomModalWithoutXIcon";
import { useTranslation } from "react-i18next";
import DriverSelfSignupModalContent from "./DriverSelfSignupModalContent";
import OutlinedButtonWithIcon from "../button/OutlinedButtonWithIcon";
import InfoIcon from "../icon/icons/InfoIcon";
import { AppColors } from "../../utils/colors.utils";

export default function DriverSelfSignupHelperButtonAndModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation("common");
  return (
    <>
      <CustomModalWithoutXIcon isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <DriverSelfSignupModalContent setIsSelfSignupModalOpen={setIsModalOpen} />
      </CustomModalWithoutXIcon>

      <OutlinedButtonWithIcon className={'ml-2'} isSmall={true} leftIcon={<InfoIcon fill={AppColors.neutral100} />} onClick={() => setIsModalOpen(true)} />
    </>
  );
}
