import React from "react";
import MuiTooltip from "../../tooltip/MuiTooltip";
import IosSwitch, { IosSwitchProps } from "../../input/switch/IosSwitch";

type Props = {
  isVirtualKeyNeeded: boolean;
} & IosSwitchProps
const VehicleDetailsVirtualKeyControlledToggle = ({ isVirtualKeyNeeded, ...props }: Props) => {
  if (!isVirtualKeyNeeded) {
    return <IosSwitch
      {...props}
    />;
  }

  return (
    <MuiTooltip title={"Virtual key must be added."} placement="top">
      <div>
        <IosSwitch
          isDisabled={isVirtualKeyNeeded}
          {...props}
        />
      </div>
    </MuiTooltip>
  );
};

export default VehicleDetailsVirtualKeyControlledToggle;