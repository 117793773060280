import { useMemo } from "react";
import { useAppSelector } from "./redux.hooks";


export function useOnboardingStatus() {

    const user = useAppSelector(state => state.user.currentUser)


    const isFirstBrowserLogin = useMemo(() => {
        const storedFlag = localStorage.getItem("isFirstBrowserLogin");

        // Set the flag to false for future visits
        localStorage.setItem("isFirstBrowserLogin", 'false');

        // Check if it was the first login based on the storedFlag
        return storedFlag === null;
    }, []);


    const isVehicleStepCompleted = !!user?.vehicleCount
    const isDriverStepCompleted = !!user?.driverCount
    const isGroupStepCompleted = !!user?.groupCount

    const areThereAnyTaskToCompleteOnboarding = !(isVehicleStepCompleted && isDriverStepCompleted)

    const hasUserStartedOnboardingYet = isVehicleStepCompleted || isDriverStepCompleted


    const isOnboardingActive = areThereAnyTaskToCompleteOnboarding
    const isOnboardingModalActive = !hasUserStartedOnboardingYet && isFirstBrowserLogin



    return { isOnboardingActive, isOnboardingModalActive, isVehicleStepCompleted, isDriverStepCompleted, isGroupStepCompleted };
}