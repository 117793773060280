import React from "react";

type Props = {
  className?: string;
  page?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  padding?: string;
};

const Card = (props: Props) => {
  const padding = props.padding ?? "p-3";
  return (
    <div onClick={props.onClick} className={`card rounded-xxl ${padding} m-0 ${props.className ?? ""}`}>
      {props.children}
    </div>
  );
};

export default Card;
