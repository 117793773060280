import { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { FleetGroup, FleetGroupWithColor } from "types/fleet-group.types";
import { colorTheFleetGroups } from "../redux/slices/fleetGroupsSlice";
import { useAppSelector } from "./redux.hooks";

export function useFleetGroupBadgeDeeds(fleetGroups: FleetGroup[]) {
  const dispatch = useDispatch();
  const coloredGroups = useAppSelector((state) => state.fleetGroups.coloredFleetGroups);
  const getGroupBadgeContent = useCallback(
    (groupName) => {
      const existingGroup = coloredGroups?.find((group) => group?.name === groupName);

      if (existingGroup && existingGroup?.color) {
        return existingGroup;
      }
    },
    [coloredGroups]
  );

  useEffect(() => {
    dispatch(colorTheFleetGroups(fleetGroups));
  }, [coloredGroups, dispatch, fleetGroups]);

  return { getGroupBadgeContent, coloredGroups };
}
