// React
import { useAlertManager } from "hooks/alert.hooks";
import { useAllDriversAsync } from "hooks/data/driver-data-accessor.hooks";
import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { getDriversForDriverSelector } from "utils/driver.utils";

// Hooks && Services && Utils
// Types
import CancelButton from "../button/CancelButton";

// Components
import { handleApiErrorResponse } from "utils";
import SaveButton from "../button/SaveButton";
import Card from "../card/Card";
import Row from "../custom/Row";
import { BlackH5Text0 } from "../text/Text";
import { Charging, ChargingUpdateCommands } from "../../types/charging-sessions.types";
import { assignDriverToChargingAsync } from "../../services/charging.service";
import { useFormDriverSelector } from "../../hooks/driver.hooks";
import DriverSelectorEditor from "../driver-components/editors/DriverSelectorEditor";

type Props = {
  readonly charging?: Charging;
  readonly onCompleteAction: () => void;
};


function ChargingAssignDriverForm({ charging, onCompleteAction }: Props) {
  const history = useHistory();
  const { allDrivers, isLoading: isDriverOptionsLoading } = useAllDriversAsync();


  const driverOptions = getDriversForDriverSelector(allDrivers);


  const { handleSetDriverSelector, driverSelector } = useFormDriverSelector();

  const { handleOpenErrorAlert, handleOpenSuccessAlert } = useAlertManager();

  const [isLoading, setIsLoading] = useState(false);

  const handleCancelButtonPressed = useCallback(() => {
    try {
      history.goBack();
    } catch (error) {
      console.error(error);
    }
  }, [history]);

  /**
   *
   */
  const handleAssignDriverAsync = useCallback(async () => {
    if (!charging || !driverSelector?.value) {
      return;
    }
    try {
      setIsLoading(true);
      const chargingId = charging.id;
      await assignDriverToChargingAsync(chargingId, {
        command: ChargingUpdateCommands.ASSIGN_DRIVER,
        driverId: driverSelector?.value
      });

      handleOpenSuccessAlert("Driver is assigned successfully !");
      onCompleteAction();

      history.push(`/charging-sessions/${chargingId}`);
    } catch (err: any) {
      handleOpenErrorAlert(handleApiErrorResponse(err));
    } finally {
      setIsLoading(false);
    }
  }, [charging, driverSelector?.value, handleOpenSuccessAlert, onCompleteAction, history, handleOpenErrorAlert]);


  // Values for rendering

  return (
    <div className="center">
      <div className="wide-sm-fix">
        <Card className="mt-4" padding="p-4">


          <DriverSelectorEditor
            isLoading={isDriverOptionsLoading}
            driverOptions={driverOptions}
            editorLabelOverride={<BlackH5Text0 className="mb-2">Assign your driver</BlackH5Text0>}
            onSelectedDriverChanged={handleSetDriverSelector}
            selectedDriver={driverSelector}
          />


          <Row className="justify-content-end align-items-center mt-4">
            <CancelButton type="button" onClick={handleCancelButtonPressed} />
            <SaveButton isLoading={isLoading} onClick={handleAssignDriverAsync} />
          </Row>


        </Card>
      </div>
    </div>
  );
}

export default ChargingAssignDriverForm;
