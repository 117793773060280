import { useEffect, useState } from "react";

export const useRemount = () => {
  const [isMounted, setIsMounted] = useState(true);


  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, [isMounted]);


  const remount = () => setIsMounted(false);


  return {
    isMounted, remount
  }
};