import React from "react";
import { FormGroup } from "reactstrap";
import {
  BlackBodyText100,
  BlackOverlineText300,
  NeutralBodyMediumText0,
  NeutralBodyMediumText500,
  NeutralBodySmallText100,
  NeutralBodyText300,
  NeutralBodyTextBlack,
  NeutralTitleSmallTextBlack
} from "../../text/Text";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { ReimbursementFormState } from "../../../types/reimbursement.types";
import ControllerValidationError from "../../form/ControllerValidationError";
import { getFormattedCentByUsdString } from "../../../utils/driver.utils";

type Props = {
  control: Control<ReimbursementFormState>
  errors: FieldErrors<ReimbursementFormState>
  isSmallLabels?: boolean
}

const DriverFormReimbursementFixedEnergyRateInput = ({ control, errors, isSmallLabels = false }: Props) => {
  return (
    <FormGroup>
      {isSmallLabels ? <>
          <NeutralTitleSmallTextBlack>Fixed Rate</NeutralTitleSmallTextBlack>
          <NeutralBodySmallText100>Set a specific price per kWh. This rate will remain the same until you change
            it</NeutralBodySmallText100>
        </> :
        <>
          <BlackBodyText100>Fixed Rate</BlackBodyText100>
          <BlackOverlineText300>Set a specific price per kWh. This rate will remain the same until you change
            it</BlackOverlineText300>
        </>
      }


      <div className="relative mt-2 d-flex align-items-center">
        {/**     <span className="position-absolute dollar-prefix-text">$</span> */}
        <Controller
          required
          // onValueChange={(e) => handleErrorMessage(e.value)}
          control={control}
          prefix="$"
          as={NumericFormat}
          decimalScale={2} // decimalScale prop to limit the decimal points up to 2
          allowNegative={false} // allowNegative prop to disallow negative values
          thousandSeparator
          name="fixedRate"
          className="form-control w-150px"
          placeholder="Type fixed rate"

          rules={{
            required: "Fixed rate is required.",
            validate: {
              positive: (value) => {
                try {
                  if (getFormattedCentByUsdString(value) <= 0) {
                    return "Rate must be greater than $0.00";
                  }

                  if (getFormattedCentByUsdString(value) > 200) {
                    return "Rate cannot exceed $2.00";
                  }

                } catch (e) {
                  return "Fixed rate is required.";
                }
              }
            }

          }}
          min={0}
          max={2}

        />
        {isSmallLabels ? (
          <>
            <NeutralBodyMediumText0 className={"ml-1"}>/kWh</NeutralBodyMediumText0>
            <NeutralBodyMediumText500 className={"ml-1"}>in all sessions</NeutralBodyMediumText500>
          </>
        ) : (
          <>
            <NeutralBodyTextBlack className={"ml-1"}>/kWh</NeutralBodyTextBlack>
            <NeutralBodyText300 className={"ml-1"}>in all sessions</NeutralBodyText300>
          </>
        )}

      </div>
      <ControllerValidationError error={errors && errors["fixedRate"]?.message} />

    </FormGroup>
  )
    ;
};

export default DriverFormReimbursementFixedEnergyRateInput;