import FilterEditorCard from "components/filter/FilterEditorCard";
import FilterMenuCheckbox from "components/filter/FilterMenuCheckbox";
import { useFilterUrlQuery, useSortFilterOptionsBySelectedItems } from "hooks/filter.hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ChargingFilters } from "types/charging-sessions.types";
import { useAllVehiclesAsync } from "../../../hooks/data/vehicle-data-accessor.hooks";
import { Vehicle } from "../../../types/vehicle.types";
import { getVehicleSummaryName } from "../../../utils/vehicle.utils";
import SearchInput from "../../input/SearchInput";
import Card from "../../card/Card";
import CircularLoadingSpinner from "../../loading/CircularLoadingSpinner";

type Props = {};

function ChargingFilterVehicleSelector(props: Props) {
  const { filterQuery, setFilterQuery } = useFilterUrlQuery<ChargingFilters>();
  const [filterSearchValue, setFilterSearchValue] = useState("");

  const vehiclesOnQuery = useMemo(() => {
    return filterQuery?.vehicle?.$in ?? [];
  }, [filterQuery?.vehicle?.$in]);

  const [selectedVehicles, setSelectedVehicles] = useState<string[]>(vehiclesOnQuery);
  const [variationCount, setVariationCount] = useState(0);

  const { isLoading, allVehicles } = useAllVehiclesAsync();

  const [vehicleOptions, setVehicleOptions] = useState<Vehicle[]>(allVehicles);

  useEffect(() => {
    setSelectedVehicles(vehiclesOnQuery);
    setVariationCount(Math.min(filterQuery?.vehicle?.$in?.length ?? 0, vehicleOptions.length));
  }, [filterQuery, vehicleOptions.length, vehiclesOnQuery]);

  const isSelectedVehicle = useCallback(
    (vehicle: Vehicle) => {
      return selectedVehicles.includes(vehicle.id);
    },
    [selectedVehicles]
  );

  const isSelectedVehicleByQuery = useCallback(
    (vehicle: Vehicle) => {
      return vehiclesOnQuery.includes(vehicle.id);
    },
    [vehiclesOnQuery]
  );

  useSortFilterOptionsBySelectedItems(setVehicleOptions, isSelectedVehicleByQuery);


  const handleVehicleToggled = useCallback(
    (vehicle: Vehicle) => {
      const vehicleId = vehicle.id;
      if (isSelectedVehicle(vehicle)) {
        setSelectedVehicles(selectedVehicles.filter((n) => n !== vehicleId));
        return;
      }
      setSelectedVehicles([...selectedVehicles, vehicleId]);
    },
    [isSelectedVehicle, selectedVehicles]
  );

  const handleSetNetworksOnQuery = useCallback(
    (vehicles: string[]) => {
      setFilterQuery((q) => {
        return {
          vehicle: {
            $in: vehicles
          }
        };
      });
    },
    [setFilterQuery]
  );

  const handleClearButtonPressed = useCallback(() => {
    setSelectedVehicles([]);
    handleSetNetworksOnQuery([]);
    setVariationCount(0);
  }, [handleSetNetworksOnQuery]);

  const handleApplyButtonPressed = useCallback(() => {
    handleSetNetworksOnQuery(selectedVehicles);
    setVariationCount(selectedVehicles.length);
  }, [handleSetNetworksOnQuery, selectedVehicles]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!filterSearchValue) {
      setVehicleOptions(allVehicles);
      return;
    }

    const lowerCaseSearchValue = filterSearchValue.toLowerCase();
    setVehicleOptions(allVehicles.filter(v => getVehicleSummaryName(v).toLowerCase().includes(lowerCaseSearchValue)));
  }, [allVehicles, filterSearchValue, isLoading]);

  return (
    <FilterEditorCard
      title="Vehicle"
      label="Vehicle"
      description="Vehicle on charging record"
      variationCount={variationCount}
      onApplyPressed={handleApplyButtonPressed}
      onClearPressed={handleClearButtonPressed}
    >
      {isLoading ? <CircularLoadingSpinner heightClassName={"h-200px"} size={30} /> : <>
        <Card padding={"p-0 mb-2 mt-1"} className={"w-300px"}>
          <SearchInput
            isClearable
            className="mb-2 mb-md-0 "
            onChange={(e) => setFilterSearchValue(e.target.value)}
            value={filterSearchValue}
            placeholder={"Search vehicle"}
          />
        </Card>


        <div className={"h-max-200px w-425px overflow-auto"}>
          {vehicleOptions.map((vehicle, ix) => (
            <React.Fragment key={ix}>
              <FilterMenuCheckbox
                textClassName={"text-truncate"}
                onToggle={() => handleVehicleToggled(vehicle)}
                isChecked={isSelectedVehicle(vehicle)}
                label={getVehicleSummaryName(vehicle)}
              />
            </React.Fragment>
          ))}
        </div>


      </>}

    </FilterEditorCard>
  );
}

export default ChargingFilterVehicleSelector;
