import { useTranslation } from "react-i18next";
import AddVehicleManualFormStarter from "../../components/vehicle-components/form/add-vehicle/AddVehicleManualFlowFormStarter";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

type Props = {};

function AddVehicleManualPage(props: Props) {
  const { t } = useTranslation("common");

  return (
    <>
      <Head title={t("vehicle.title.add_vehicles_page")}></Head>
      <Content>
        <AddVehicleManualFormStarter />
      </Content>
    </>
  );
}

export default AddVehicleManualPage;
