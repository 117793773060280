import React from "react";

type Props = {};

function CarMenuIconOutlined({}: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.7782 5.41153C16.7782 5.41153 16.7888 5.41505 16.8029 5.42563C16.8171 5.43621 16.8523 5.46092 16.8876 5.52089L18.5492 9.36272C18.6127 9.51089 18.7009 9.64495 18.8103 9.7649L20.5848 11.6699V18.5916H18.4081V18.0024C18.4081 17.2228 17.7766 16.5913 16.997 16.5913H6.99551C6.21585 16.5913 5.58437 17.2228 5.58437 18.0024V18.5916H3.40769V11.6699L5.18219 9.7649C5.29155 9.64848 5.37974 9.51089 5.44325 9.36272L7.10487 5.52089C7.14015 5.46444 7.17543 5.43621 7.19307 5.42563C7.20718 5.41505 7.21777 5.41505 7.2213 5.41505H16.7677M16.7677 4.00391C16.7677 4.00391 16.7606 4.00391 16.7571 4.00391H7.21777C7.21777 4.00391 7.2107 4.00391 7.20717 4.00391C6.932 4.00391 6.66389 4.08505 6.424 4.24028C6.18058 4.3955 5.97949 4.62129 5.83132 4.89293L4.13796 8.80179L2.22938 10.8479C2.15176 10.9326 2.08825 11.0314 2.04592 11.1443C2.00359 11.2572 1.98242 11.3771 1.98242 11.5006V19.1102C1.98242 19.3465 2.06357 19.5723 2.20821 19.7381C2.35285 19.9039 2.54688 19.9992 2.75149 19.9992H6.21234C6.51926 19.9992 6.98141 19.6429 6.98141 19.2901V18.0024H16.9829V19.1137C16.9829 19.47 17.2898 20.0027 17.5967 20.0027H21.2092C21.4138 20.0027 21.6079 19.9075 21.7525 19.7417C21.8972 19.5759 21.9783 19.3501 21.9783 19.1137V11.5041C21.9783 11.3807 21.9571 11.2607 21.9148 11.1478C21.8725 11.0349 21.809 10.9326 21.7314 10.8515L19.8228 8.80532L18.1294 4.89645C17.9848 4.62481 17.7802 4.39903 17.5367 4.24381C17.2968 4.08858 17.0287 4.00744 16.7535 4.00744L16.7677 4.00391Z"
        fill="#6F7981"
      />
      <path
        d="M7.50316 12.6938L4.81493 12.0235C4.40217 11.9212 4 12.2317 4 12.6585V14.3449C4 14.7082 4.29281 15.001 4.65618 15.001H7.3444C7.70777 15.001 8.00057 14.7082 8.00057 14.3449V13.3288C8.00057 13.029 7.79597 12.7644 7.50316 12.6938Z"
        fill="#6F7981"
      />
      <path
        d="M16.4994 12.6938L19.1876 12.0235C19.6004 11.9212 20.0025 12.2317 20.0025 12.6585V14.3449C20.0025 14.7082 19.7097 15.001 19.3464 15.001H16.6581C16.2948 15.001 16.002 14.7082 16.002 14.3449V13.3288C16.002 13.029 16.2066 12.7644 16.4994 12.6938Z"
        fill="#6F7981"
      />
      <path
        d="M7.89478 6H16.1147C16.2593 6 16.3934 6.0882 16.4463 6.22226L17.7939 9.50316C17.8927 9.73952 17.7163 10.0006 17.4623 10.0006H6.53656C6.27903 10.0006 6.10616 9.73952 6.20494 9.50316L7.56316 6.22226C7.6196 6.0882 7.75013 6 7.89478 6Z"
        fill="#6F7981"
      />
    </svg>
  );
}

export default CarMenuIconOutlined;
