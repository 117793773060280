import React from "react";

type Props = {};

function MessageMenuIconOutlined({}: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.1014 4.40919C20.366 4.40919 20.5918 4.63144 20.5918 4.89956V17.0989C20.5918 17.3635 20.3695 17.5893 20.1014 17.5893H5.00221C4.62825 17.5893 4.26841 17.7374 4.00382 18.002L3.41467 18.5912L3.42173 4.89956C3.42173 4.63144 3.63693 4.40919 3.90152 4.40919H20.1014ZM20.1014 2.99805H3.90152C2.85727 2.99805 2.01059 3.85179 2.01059 4.89956L2 21.9991L4.99868 19.0004H20.0979C21.1421 19.0004 21.9994 18.1467 21.9994 17.0989V4.89956C21.9994 3.85532 21.1457 2.99805 20.0979 2.99805H20.1014Z"
        fill="#6F7981"
      />
      <path
        d="M17.0006 9.99805H7.7435C7.19211 9.99805 6.74512 10.445 6.74512 10.9964V11C6.74512 11.5513 7.19211 11.9983 7.7435 11.9983H17.0006C17.552 11.9983 17.999 11.5513 17.999 11V10.9964C17.999 10.445 17.552 9.99805 17.0006 9.99805Z"
        fill="#6F7981"
      />
      <path
        d="M17.0006 13H7.7435C7.19211 13 6.74512 13.447 6.74512 13.9984V14.0019C6.74512 14.5533 7.19211 15.0003 7.7435 15.0003H17.0006C17.552 15.0003 17.999 14.5533 17.999 14.0019V13.9984C17.999 13.447 17.552 13 17.0006 13Z"
        fill="#6F7981"
      />
      <path
        d="M17.0006 6.99805H7.7435C7.19211 6.99805 6.74512 7.44504 6.74512 7.99643V7.99996C6.74512 8.55135 7.19211 8.99834 7.7435 8.99834H17.0006C17.552 8.99834 17.999 8.55135 17.999 7.99996V7.99643C17.999 7.44504 17.552 6.99805 17.0006 6.99805Z"
        fill="#6F7981"
      />
    </svg>
  );
}

export default MessageMenuIconOutlined;
