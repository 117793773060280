import Card from "components/card/Card";
import ReadonlyFormBox from "components/form/ReadonlyFormBox";
import PageHeader from "components/header/PageHeader";
import { useAuthStatus } from "hooks/auth.hooks";
import { getFormattedPhoneNumberNational } from "utils/format.utils";
import SettingsPageCompanyNameEditor from "./form/SettingsPageCompanyNameEditor";

type Props = {};

function SettingsPageAccountContent({}: Props) {
  const { user } = useAuthStatus();
  return (
    <Card padding="p-4 w-100">
      <PageHeader title="Account" description="Your personal details at a glance." />
      <ReadonlyFormBox className="mt-2" label="Full name" value={user?.name ?? "-"} />
      <SettingsPageCompanyNameEditor />
      <ReadonlyFormBox className="mt-2" label="Email" value={user?.email ?? "-"} />
      <ReadonlyFormBox className="mt-2" label="Phone number"
                       value={getFormattedPhoneNumberNational(user?.phoneNumber)} />
    </Card>
  );
}

export default SettingsPageAccountContent;
