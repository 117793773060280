import React from "react";

type Props = {};

function OtherNetworkIcon({}: Props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 19L11 15H9.5V12L7 16H8.5V19ZM6 10H12V5H6V10ZM13 21H5C4.71667 21 4.479 20.904 4.287 20.712C4.095 20.52 3.99934 20.2827 4 20V5C4 4.45 4.196 3.979 4.588 3.587C4.98 3.195 5.45067 2.99934 6 3H12C12.55 3 13.021 3.196 13.413 3.588C13.805 3.98 14.0007 4.45067 14 5V12H15.25C15.7333 12 16.146 12.171 16.488 12.513C16.83 12.855 17.0007 13.2673 17 13.75V18.375C17 18.6583 17.1167 18.9167 17.35 19.15C17.5833 19.3833 17.8417 19.5 18.125 19.5C18.425 19.5 18.6877 19.3833 18.913 19.15C19.1383 18.9167 19.2507 18.6583 19.25 18.375V9H19C18.7167 9 18.479 8.904 18.287 8.712C18.095 8.52 17.9993 8.28267 18 8V6.5C18 6.36667 18.05 6.25 18.15 6.15C18.25 6.05 18.3667 6 18.5 6V5C18.5 4.86667 18.55 4.75 18.65 4.65C18.75 4.55 18.8667 4.5 19 4.5C19.1333 4.5 19.25 4.55 19.35 4.65C19.45 4.75 19.5 4.86667 19.5 5V6H20.5V5C20.5 4.86667 20.55 4.75 20.65 4.65C20.75 4.55 20.8667 4.5 21 4.5C21.1333 4.5 21.25 4.55 21.35 4.65C21.45 4.75 21.5 4.86667 21.5 5V6C21.6333 6 21.75 6.05 21.85 6.15C21.95 6.25 22 6.36667 22 6.5V8C22 8.28334 21.904 8.521 21.712 8.713C21.52 8.905 21.2827 9.00067 21 9H20.75V18.375C20.75 19.075 20.4957 19.6877 19.987 20.213C19.4783 20.7383 18.858 21.0007 18.126 21C17.4093 21 16.7927 20.7373 16.276 20.212C15.7593 19.6867 15.501 19.0743 15.501 18.375V13.75C15.501 13.6667 15.48 13.604 15.438 13.562C15.396 13.52 15.3337 13.4993 15.251 13.5H14V20C14 20.2833 13.904 20.521 13.712 20.713C13.52 20.905 13.2827 21.0007 13 21Z"
        fill="#6F7981"
      />
    </svg>
  );
}

export default OtherNetworkIcon;
