import { LinearProgress, linearProgressClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { BlackBodyText300, GreenSubtitleText200 } from "../block/Block";
import Row from "../custom/Row";

const BorderLinearProgress = styled(LinearProgress)(({ theme, ...props }) => {
  return {
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "white",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: props.completed ? "#23D206" : "#1E44FF",
    },
  };
});

export default function DriverBulkUploadFormCsvUploaderProgressBar({ progressValue }) {
  const isUploadingCompleted = progressValue === 1;
  return (
    <Row className="flex-nowrap w-100 center mt-3">
      <BorderLinearProgress
        className="w-100 mr-3"
        variant="determinate"
        value={progressValue * 100}
        completed={isUploadingCompleted}
      />
      {isUploadingCompleted ? (
        <GreenSubtitleText200>Completed</GreenSubtitleText200>
      ) : (
        <BlackBodyText300>%{Math.floor(progressValue * 100)}</BlackBodyText300>
      )}
    </Row>
  );
}
