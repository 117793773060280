import React from "react";
import { BlackBody2Text200, BlackBodyText100 } from "../../text/Text";
import LoadingSkeleton from "../../loading/LoadingSkeleton";

type Props = {
  label: string
  text?: string
  textOverwrite?: React.JSX.Element
  isLoading?: boolean
}

function VehicleDetailsHeaderInfoBlock({ label, text, textOverwrite, isLoading }: Props) {
  if (isLoading) {
    return <LoadingSkeleton count={2} className={"w-100px"} />;
  }

  return (
    <div>
      <BlackBody2Text200>{label}</BlackBody2Text200>
      {textOverwrite ?? <BlackBodyText100>{text}</BlackBodyText100>}
    </div>
  );
}

export default VehicleDetailsHeaderInfoBlock;