import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { Icon } from "../Component";
import ButtonWithIcon from "./ButtonWithIcon";

export default function SeeDriversButton() {
  /**
   * Hooks
   */
  const history = useHistory();

  /**
   * Handlers
   */
  const handleSeeDriversButtonClicked = useCallback(() => {
    history.replace("/drivers");
  }, [history]);

  /**
   * Render
   */
  return <ButtonWithIcon className="w-100 mt-md-0" text={"See Drivers"} onClick={handleSeeDriversButtonClicked} />;
}
