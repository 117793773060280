import { useCallback, useState } from "react";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import { BlackBody2Text200, BlackOverlineText200, NeutralBodyMediumText0 } from "components/text/Text";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { TeslaCommand, TeslaDataAccessorHook } from "types/vehicle.types";
import AlertConfirmDialog from "../../notification/AlertConfirmDialog";
import CancelButton from "../../button/CancelButton";
import PrimaryButton from "../../button/PrimaryButton";
import { updateVehicleTeslaAsync } from "../../../services/vehicle.services";
import { handleApiErrorResponse } from "../../../utils";
import { useParams } from "react-router";
import { useAlertManager } from "../../../hooks/alert.hooks";
import VehicleDetailsVirtualKeyControlledToggle from "./VehicleDetailsVirtualKeyControlledToggle";

type Props = {
  teslaHook: TeslaDataAccessorHook;
  isControlsVisibleByKeyStatus?: boolean
};

function VehicleDetailsAntiTheftModeEditor({ teslaHook, isControlsVisibleByKeyStatus }: Props) {
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { teslaDrivePin, isTeslaDrivePinLoading: isLoading, refetchDrivePin } = teslaHook;

  const isEnabled = teslaDrivePin?.antiTheft.enabled;


  const handleToggleTheftModeAsync = useCallback(async () => {
    try {
      setIsSubmitting(true);

      await updateVehicleTeslaAsync(vehicleId, {
        command: isEnabled ? TeslaCommand.DISABLE_ANTI_THEFT : TeslaCommand.ENABLE_ANTI_THEFT
      });

      setIsDialogOpen(false);
      handleOpenSuccessAlert(isEnabled ? "Anti-Theft Mode has been successfully disabled." : "Anti-Theft Mode has been successfully enabled.");
      refetchDrivePin();
    } catch (error) {
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  }, [handleOpenErrorAlert, handleOpenSuccessAlert, isEnabled, refetchDrivePin, vehicleId]);


  if (isLoading) {
    return <div className={"py-2"}><LoadingSkeleton count={2} /></div>;
  }


  return (
    <>
      <AlertConfirmDialog
        isDialogOpen={isDialogOpen}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setIsDialogOpen(false)} />
            </div>

            <PrimaryButton
              //leftIcon={isOpen ? <UnlockFilledIcon className="mr-1" /> : <LockClosedFilledIcon className="mr-1" />}
              type="button"
              text={isEnabled ? "Disable" : "Enable"}
              isLoading={isSubmitting}
              onClick={handleToggleTheftModeAsync}
            />
          </>
        }
        toggleIsDialogOpen={() => setIsDialogOpen(false)}
        title={`${isEnabled ? "Disabled" : "Enable"} Anti-Theft Mode?`}
        description={
          isEnabled ? "Disabling Anti-Theft Mode will return the vehicle to its standard operational state." : ""
        }
        descriptionOverride={isEnabled ? undefined : <>
          <NeutralBodyMediumText0>
            Anti-Theft Mode will make the vehicle inconvenient to drive when theft is suspected.
            This mode:
          </NeutralBodyMediumText0>
          <NeutralBodyMediumText0>
            &#x2022; Limits speed and acceleration <br />
            &#x2022; Prevents charging above 20% <br />
            &#x2022; Boosts vehicle monitoring
          </NeutralBodyMediumText0>

          <NeutralBodyMediumText0 className={"mt-3"}>
            Anti-Theft Mode activates the next time the vehicle is put in Park, given that mobile data access is
            available.
          </NeutralBodyMediumText0>
        </>}
      />

      <Row className="justify-between align-items-center mt-2">
        <Col>
          <Row>
            <BlackBody2Text200>Anti-theft mode</BlackBody2Text200>
          </Row>

          <BlackOverlineText200 className="mt-1"> Anti-Theft Mode will make the vehicle inconvenient to drive when theft
            is suspected.</BlackOverlineText200>

        </Col>
        <div className="position-relative">
          <VehicleDetailsVirtualKeyControlledToggle
            isVirtualKeyNeeded={!isControlsVisibleByKeyStatus}
            onToggle={() => {
              setIsDialogOpen(true);
            }}
            label={""}
            isChecked={isEnabled}
          />

        </div>
      </Row>
    </>
  );
}

export default VehicleDetailsAntiTheftModeEditor;
