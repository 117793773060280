import React from "react";

export type TickCircleIconProps = {
  readonly fill?: string;
  readonly width?: number;
  readonly height?: number;
  readonly className?: string;
};

const TickCircleIcon = ({ fill, width, height, className }: TickCircleIconProps) => {
  const fillValue = fill ?? "#680031";
  const widthValue = width ?? 18;
  const heightValue = height ?? 18;

  return (
    <svg
      width={widthValue}
      height={heightValue}
      viewBox={`0 0 18 18`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99981 17.3346C13.6024 17.3346 17.3336 13.6034 17.3336 9.00079C17.3336 4.39816 13.6024 0.666992 8.99981 0.666992C4.39718 0.666992 0.666016 4.39816 0.666016 9.00079C0.666016 13.6034 4.39718 17.3346 8.99981 17.3346ZM6.74513 11.4857C6.74013 11.4807 6.73551 11.4755 6.73127 11.47L4.56348 9.30223C4.40075 9.1395 4.40075 8.87566 4.56348 8.71294L5.74205 7.53436C5.90478 7.37163 6.16861 7.37163 6.33134 7.53436L8.39606 9.59908L12.2185 5.77661C12.3813 5.61388 12.6451 5.61388 12.8078 5.77661L13.9864 6.95518C14.1491 7.11791 14.1491 7.38174 13.9864 7.54447L8.68979 12.8411C8.52706 13.0038 8.26322 13.0038 8.1005 12.8411L6.74513 11.4857Z"
        fill={fillValue}
      />
    </svg>
  );
};
export default TickCircleIcon;
