import Card from "../card/Card";

import { CircularProgress } from "@mui/material";
import BlackInfoIcon from "components/icon/icons/BlackInfoIcon";
import MuiTooltip from "components/tooltip/MuiTooltip";
import { useDashboardInsights } from "hooks/data/dashboard-data-accessor.hooks";
import { ErrorBoundary } from "react-error-boundary";
import Skeleton from "react-loading-skeleton";
import { BlackBodyText300, BlackSubtitleText100 } from "../text/Text";

type DashboardInsightCardProps = {
  readonly title?: string;
  readonly description?: string;
  readonly graph: JSX.Element;
  readonly height?: string;
  readonly isSkeletonHorizontalLoading?: boolean;
  readonly isPieChart?: boolean;
  readonly tooltipText?: string;
};

export default function DashboardInsightCard({
                                               title,
                                               description,
                                               graph,
                                               height,
                                               isSkeletonHorizontalLoading,
                                               isPieChart /* Pie chart is exception for overflow, since we have label boxes around the pie-chart, canvas is overflowing, but we dont want scroll, so we need to arrange exceptional hard coded logic */,
                                               tooltipText
                                             }: DashboardInsightCardProps) {
  const { isLoading } = useDashboardInsights();

  const cardHeight = height ?? "h-350px";
  const areThereTitleOrDescription = title || description;

  return (
    <ErrorBoundary FallbackComponent={() => <>An error ocurred..</>}>
      <Card className={`${cardHeight} d-flex justify-between position-relative`} padding="px-3">
        {isLoading ? (
          <div className="flex flex-1 justify-center align-items-center">
            {isSkeletonHorizontalLoading ? (
              <Skeleton containerClassName="w-100" width={"100%"} height={35} count={7} />
            ) : (
              <CircularProgress size={75} style={{ color: "#1E44FF" }} />
            )}
          </div>
        ) : (
          <>
            {tooltipText && (
              <MuiTooltip title={tooltipText} placement="top" className="absolute-top-right m-3">
                <div className="cursor-pointer">
                  <BlackInfoIcon width={16} />
                </div>
              </MuiTooltip>
            )}
            {areThereTitleOrDescription && (
              <div className="py-3  ">
                <BlackSubtitleText100>{title}</BlackSubtitleText100>
                {description && <BlackBodyText300>{description}</BlackBodyText300>}
              </div>
            )}
            <div
              className={`d-flex flex-1 flex-grow  justify-center ${
                areThereTitleOrDescription ? "align-items-end " : "align-items-center"
              }   ${isPieChart ? "" : "overflow-auto"}`}
              /* Using overflow-auto does not mean always we want scroll in here, it needs to be fit what remain in the card, and it will prevent the bar overflowing */
            >
              {graph}
            </div>
          </>
        )}
      </Card>
    </ErrorBoundary>
  );
}
