import { useDriverFormVehicleSetupStepStates } from "hooks/driver.hooks";
import { Control, DeepMap, FieldError, RegisterOptions } from "react-hook-form";
import { PaginationMeta } from "./api.types";
import { Charging } from "./charging-sessions.types";
import { SelectorState, SelectorStringOption } from "./editor.types";
import { Period } from "./filter.types";
import { FleetGroup } from "./fleet-group.types";
import { Invoice } from "./invoice.types";
import { PayerType } from "./payment.types";
import { VehicleDriverScheduleUpdatePayload } from "./vehicle-driver-schedule.types";
import { Vehicle } from "./vehicle.types";
import { useDriverAsync } from "../hooks/data/driver-data-accessor.hooks";


export enum DriverSignUpApplicationStatus {
  PENDING_REVIEW = "PENDING_REVIEW",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}


export enum DriverBadgeStatus {
  ACTIVE = "ACTIVE",
  NOT_ACTIVE = "NOT_ACTIVE",
  PENDING_REVIEW = "PENDING_REVIEW",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}


export enum DriversTableType {
  ALL,
  PENDING
}

export enum DriverDetailsPageTabKey {
  ABOUT,
  CHARGING_SESSIONS,
  REIMBURSEMENT
}

export enum Country {
  US = "US",
  CANADA = "CA"
}


export type Address = {
  street?: string;
  street2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: Country;
};


export enum UserStatus {
  Active = "Active",
  Inactive = "Inactive",
}

export enum UserType {
  FIXED = "fixed",
  TEMPORARY = "temporary",
}

export enum DriverAssignmentFormOperation {
  Unassign = "unassign",
  Assign = "assign",
}

export type UserVehicleAssignmentHistory = {
  id: string;
  userId: string;
  vehicleId: string;
  active: boolean;
  startedAt: string;
  stoppedAt?: string;
  createdAt: string;
  updatedAt: string;
  vehicle: Vehicle;
};

export type UserLimits = {
  id: string;
  chargingLimit: number;
};


export type DriverTableFilters = {
  active?: {
    $in?: boolean[] | string[];
  };
  type?: {
    $eq: keyof typeof UserType;
  };
  payer?: {
    $in: PayerType[];
  };
  createdAt?: {
    $within: Period;
    $gte: string;
    $lt: string;
  };
  q?: {
    $eq: string;
  };
};


/**
 * Read
 */
export type DriverSettings = {
  id: string;
  data: DriverSettingsPayload;
  createdAt: string;
  updatedAt: string;
};


export type DriverReimbursementlocation = DriverLocationPayload


export type Driver = {
  id: string;
  type: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  active: boolean;
  payer: PayerType;
  jobTitle: string;
  createdAt: string;
  updatedAt: string;
  activatedAt: string;
  expiredAt: string;
  address: Address;
  charging: Charging[];
  limits: UserLimits;
  groups: FleetGroup[];
  vehicles: Vehicle[];
  version: number;
  userVehicleAssignmentHistory: UserVehicleAssignmentHistory[];
  invoices: Invoice[];
  organizationId: string
  hasDefaultPaymentMethod: boolean
  chargingReimbursementLocations: DriverReimbursementlocation[]
  settings: DriverSettings;
};




export type DriverSignUpApplication = {
  id: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  address: Address;
  signUpCode: string;
  organizationId: string;
  status: DriverSignUpApplicationStatus;
  createdAt: string;
  updatedAt: string;
}


/**
 * Mutate
 */

export interface DriverSettingsPayload {
  chargingReimbursement: {
    enabled: boolean;
    tariff: {
      fixed: {
        // Rates are in cents
        energyPrice: number
        taxRate: number;
      };
    } | null
  };
}

export interface DriverLocationPayload {
  name: string;
  polygon: {
    type: string
    coordinates: number[][][]
  };
}

export enum DriverUpdateCommand {
  ENABLE_USER = "EnableUser",
  DISABLE_USER = "DisableUser",
  UPDATE_PERSONAL_INFORMATION = "UpdatePersonalInformation",
  UPDATE_CHARGING_SETTINGS = "UpdateChargingSettings",
  ENABLE_CHARGING_REIMBURSEMENT = "EnableChargingReimbursement",
  DISABLE_CHARGING_REIMBURSEMENT = "DisableChargingReimbursement",
  UPDATE_CHARGING_REIMBURSEMENT_FIXED_ENERGY_PRICE = "UpdateChargingReimbursementFixedEnergyPrice",
  UPDATE_CHARGING_REIMBURSEMENT_LOCATION = "UpdateChargingReimbursementLocation",
}


type DriverEnableCommand = {
  command: DriverUpdateCommand.ENABLE_USER;
}

type DriverDisableCommand = {
  command: DriverUpdateCommand.DISABLE_USER;
}

export type DriverUpdatePersonalInformationCommand = {
  command: DriverUpdateCommand.UPDATE_PERSONAL_INFORMATION;
  phoneNumber: string;
  groups: string[]; // UUIDs as strings
}

export type DriverUpdateChargingSetupCommand = {
  command: DriverUpdateCommand.UPDATE_CHARGING_SETTINGS;
  credit: number | null;
  payer: PayerType;
}


export type DriverEnableChargingReimbursementCommand = {
  command: DriverUpdateCommand.ENABLE_CHARGING_REIMBURSEMENT;
  fixedEnergyPrice: number,
  location?: DriverLocationPayload | null
}

export type DriverDisabledChargingReimbursementCommand = {
  command: DriverUpdateCommand.DISABLE_CHARGING_REIMBURSEMENT;
}

export type DriverUpdateChargingReimbursementFixedEnergyPriceCommand = {
  command: DriverUpdateCommand.UPDATE_CHARGING_REIMBURSEMENT_FIXED_ENERGY_PRICE;
  fixedEnergyPrice: number,
}

export type DriverUpdateChargingReimbursementLocationCommand = {
  command: DriverUpdateCommand.UPDATE_CHARGING_REIMBURSEMENT_LOCATION;
  location?: DriverLocationPayload | null
}


export type DriverStatusCommands = DriverEnableCommand | DriverDisableCommand

export type DriverReimbursementCommands = DriverEnableChargingReimbursementCommand
  | DriverDisabledChargingReimbursementCommand
  | DriverUpdateChargingReimbursementFixedEnergyPriceCommand
  | DriverUpdateChargingReimbursementLocationCommand


export type DriverAddPayload = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  address: Address
  payer: PayerType
  credit: number | null
  groups: string[]
}


export type DriverReimbursementPayload = {
  settings: DriverSettingsPayload;
  location?: DriverLocationPayload | null
}


export type DriverReimbursementSettings = DriverReimbursementPayload


export type DriverUpdatePayload =
  DriverStatusCommands
  | DriverUpdatePersonalInformationCommand
  | DriverUpdateChargingSetupCommand
  | DriverReimbursementCommands


/**
 * Redux slice & Form
 */
export enum DriverFormStep {
  PERSONAL_INFORMATION,
  ADDRESS,
  CHARGING,
  REIMBURSEMENT,
  VEHICLE,
}


export type DriverFormAddressSelectorState = {
  state: SelectorState<SelectorStringOption | undefined>;
  city: SelectorState<SelectorStringOption | undefined>;
  country: SelectorState<SelectorStringOption>;
};

export type DriverFormFleetGroupSelectorState = SelectorState<SelectorStringOption[]>;

export type DriverFormVehicleSelectorState = SelectorStringOption

export type DriverFormVehicleSetupStepStates = ReturnType<typeof useDriverFormVehicleSetupStepStates>;

export type DriverHookStates = ReturnType<typeof useDriverAsync>;


export type DriverFormStepsCommonProps = {
  register: (options: RegisterOptions) => React.LegacyRef<HTMLInputElement> | undefined;
  control: Control<DriverFormState>;
  errors: DeepMap<DriverFormState, FieldError>;
  isEditMode?: boolean;
  stepTitleOverride?: JSX.Element;
};


export enum DriverSelfSignupUpdateCommand {
  APPROVE = "approve",
  REJECT = "reject",
}


export type DriverSelfSignupUpdatePayload = {
  vehicleDriverSchedule: ({
    vehicleId: string,
  } & VehicleDriverScheduleUpdatePayload) | null
  credit: number | null
  payer: PayerType;
}


export enum DriverFormAddressSelectorKey {
  STATE = "state",
  CITY = "city",
  COUNTRY = "country"
}

export enum DriverFormType {
  MANUAL,
  SELF_SIGNUP,
}

export enum DriverFormStatus {
  NOT_STARTED = "NOT_STARTED",
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
}

export enum DriverFormMode {
  EDIT = "EDIT",
  ADD = "ADD",
  BULK = "BULK",
}

export type DriverNonFormState = {
  mode: DriverFormMode;
  status: DriverFormStatus;
  stepLength: number;
  currentStep: number;
  type: DriverFormType;
  isError: boolean;
  errorMessage: string;
  successMessageHelper: {
    driverName: string;
    driversCount: number;
  };
};

export type DriverFormState = {
  firstName: string;
  lastName: string;
  street1: string;
  street2: string;
  phoneNumber: string;
  postalCode: string;
  email: string;
  credit: string | null;
  fixedRate: string | null;
  payer: PayerType;
};


export type DriverSliceState = {
  isDriversLoading: boolean;
  driver?: Driver;
  drivers: Driver[];
  meta: PaginationMeta | null;
  driverNonFormState: DriverNonFormState;
  driverFormState: DriverFormState;
};


export enum DriverSelfSignupStep {
  CHARGING,
  REIMBURSEMENT,
  VEHICLE
}

/**
 * {

 }
 */
