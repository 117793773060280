import Col from "components/custom/Col";
import Row from "components/custom/Row";
import WarningIcon from "components/icon/icons/WarningIcon";
import {
  BlackBodyText200,
  NeutralBodyMediumText300,
  NeutralBodyText300,
  NeutralTitleSmallText100
} from "components/text/Text";
import React, { useCallback } from "react";
import HoverUnderlinedTextButton from "../../../button/HoverUnderlinedTextButton";

type Props = {};

function AddVehicleStarterWarningCard({}: Props) {
  const handleOpenLinkClicked = useCallback((url: string) => {
    window.open(url, "_blank");
  }, []);

  return (
    <div className="add-vehicle-starter-warning-card">
      <Row>
        <WarningIcon />
        <Col className="ml-2">
          {" "}
          <NeutralTitleSmallText100 className="mb-1">Don't forget to enable road segment data
            sharing:</NeutralTitleSmallText100>
          <Col className="pl-2">
            <NeutralBodyMediumText300>1. On the Tesla screen, navigate to 'Software'.</NeutralBodyMediumText300>
            <NeutralBodyMediumText300>2. Under Privacy, select 'Data Sharing'.</NeutralBodyMediumText300>
            <NeutralBodyMediumText300>3. Activate 'Road Segment Data Analytics'.</NeutralBodyMediumText300>
          </Col>
          <NeutralBodyMediumText300>
            For a step-by-step visual guide, watch our{" "}
            <HoverUnderlinedTextButton
              text={"helpful video tutorial"}
              onClick={() =>
                handleOpenLinkClicked("https://drive.google.com/file/d/11FSVnNAp5CqXHeakdi2eGwEstxTj3E7B/view")
              }
            />
            .
          </NeutralBodyMediumText300>
        </Col>
      </Row>
    </div>
  );
}

export default AddVehicleStarterWarningCard;
