import { ManageBillingButton } from "components/button/StripeButtons";
import PageHeader from "components/header/PageHeader";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import React from "react";
import InvoicesTable from "../../components/invoice/InvoicesTable";

type Props = {};

function InvoicesPage({}: Props) {
  return (
    <>
      <Head title={"Invoices"} />
      <Content>
        <PageHeader title={"Invoices"} description="Review and pay all your invoices seamlessly." />
        <ManageBillingButton />
        <InvoicesTable />
      </Content>
    </>
  );
}

export default InvoicesPage;
