import { DateTime } from "luxon";
import moment from "moment";
import { ChargingNetwork } from "types/charging-sessions.types";
import {
  CountByChargingLocation,
  CountByChargingNetwork,
  CountByChargingType,
  DashboardInsightFilter,
  DashboardInsightFilterReturnDataType,
  TotalCostOrEnergyByPeriod,
  WithTotal
} from "types/dashboard.types";
import { DefaultTimeZone } from "types/date.types";
import { GraphData } from "types/table.types";
import { getChargerInfo } from "./charging.utils";
import { AppColors } from "./colors.utils";


export const dashboardChargingTypeColors = {
  AC: AppColors.mint500,
  DC: AppColors.blue100
};

export function getSustainabilityScoreFilterDisplayValueByFilterKey(filter: DashboardInsightFilter) {
  switch (filter) {
    case DashboardInsightFilter.ThisYear:
      return "365 days";
    case DashboardInsightFilter.LastMonth:
    case DashboardInsightFilter.ThisWeek:
      return "30 days";
    case DashboardInsightFilter.LastWeek:
      return "7 days";
    case DashboardInsightFilter.ThisQuarter:
    case DashboardInsightFilter.LastQuarter:
      return "90 days";
    default:
      return "365 days";
  }
}

export function getProcessedLocationsGraphData(locations: CountByChargingLocation[]): GraphData {
  if (!locations) {
    return {
      data: [],
      labels: [],
      backgroundColors: []
    };
  }

  const data = locations?.map((l) => l.count);
  const labels = locations?.map((l) => {
    if (l.location === "home") {
      return "Home";
    }

    if (l.location === "other") {
      return "Other";
    }

    return l.location;
  });
  const backgroundColors = [
    AppColors.blue600,
    AppColors.violet600,
    AppColors.mint600,
    "#5D898B",
    "#A75E6E",
    "#F4B583",
    "#5CA778",
    "#73B5AF",
    "#84BCEF",
    "#C89FFF",
    "#C083D7",
    "#5D898B"
  ];

  return {
    data,
    labels,
    backgroundColors: backgroundColors.slice(0, locations.length) // This ensures you only use as many colors as you have locations
  };
}

export function getProcessedChargingTypeGraphData(chargingTypes: CountByChargingType[]): WithTotal<GraphData> {
  const acChargingType = "AC";
  const dcChargingType = "DC";

  const acCount = chargingTypes?.find((ct) => ct.type === acChargingType)?.count ?? 0;
  const dcCount = chargingTypes?.find((ct) => ct.type === dcChargingType)?.count ?? 0;
  const totalSessions = acCount + dcCount;
  return {
    total: totalSessions,
    data: [acCount, dcCount],
    labels: [acChargingType, dcChargingType],
    backgroundColors: [dashboardChargingTypeColors[acChargingType], dashboardChargingTypeColors[dcChargingType]]
  };
}


export function getProcessedChargingNetworkGraphData(countByChargingNetworks: CountByChargingNetwork[]): WithTotal<GraphData> {


  const chargingNetworksMap = new Map<string, { count: number, label: string, color: string }>();


  countByChargingNetworks.forEach(({ network, count }) => {
    const knownNetwork = getChargerInfo(network as ChargingNetwork);

    chargingNetworksMap.set(network, {
      count: chargingNetworksMap.get(network)?.count ?? 0 + count,
      label: knownNetwork.label,
      color: knownNetwork.color
    });
  });


  const chargingNetworksAttributes = Array.from(chargingNetworksMap.values());

  const labels = chargingNetworksAttributes.map((cn) => cn.label);
  const data = chargingNetworksAttributes.map((cn) => cn.count);
  const backgroundColors = chargingNetworksAttributes.map((cn) => cn.color);

  const totalChargingNetworks = data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);


  return {
    total: totalChargingNetworks,
    data,
    labels,
    backgroundColors
  };
}

function getWeeklyDataForGraphAsDaysOfWeek(totalDataListByPeriod: TotalCostOrEnergyByPeriod[]) {
  const weeklyGraphDataMap = new Map<string, number>();


  totalDataListByPeriod.forEach(({ period, total }) => {
    const periodDateAsDayName = DateTime.fromISO(period).toFormat("ccc");
    weeklyGraphDataMap.set(periodDateAsDayName, total);
  });

  const labels = Array.from(weeklyGraphDataMap.keys());
  const data = Array.from(weeklyGraphDataMap.values());


  return { data, labels };
}

function getMonthlyDataForGraphAsDaysOfMonth(totalDataListByPeriod: TotalCostOrEnergyByPeriod[]): GraphData {
  const data: Array<number> = [];
  const labels: Array<string> = [];

  totalDataListByPeriod.forEach(({ period, total }) => {
    data.push(total);
    labels.push(moment(period).date().toString());
  });

  return { data, labels };
}

function getDataForGraphAsMonthsOfYear(totalDataListByPeriod: TotalCostOrEnergyByPeriod[]): GraphData {
  const data: Array<number> = [];
  const labels: Array<string> = [];


  totalDataListByPeriod.forEach(({ period, total }) => {
    data.push(total);
    labels.push(DateTime.fromISO(period).toFormat("MMMM"));
  });

  return { data, labels };
}

const getQuarterlyDataForGraph = getDataForGraphAsMonthsOfYear;
const getYearlyDataForGraph = getDataForGraphAsMonthsOfYear;


export const convertFilterToFilterReturnType = (filter: DashboardInsightFilter): DashboardInsightFilterReturnDataType => {
  switch (filter) {
    case DashboardInsightFilter.ThisWeek:
    case DashboardInsightFilter.LastWeek:
      return DashboardInsightFilterReturnDataType.Weekly;
    case DashboardInsightFilter.ThisMonth:
    case DashboardInsightFilter.LastMonth:
      return DashboardInsightFilterReturnDataType.Monthly;
    case DashboardInsightFilter.ThisQuarter:
    case DashboardInsightFilter.LastQuarter:
    case DashboardInsightFilter.ThisYear:
      return DashboardInsightFilterReturnDataType.Quarterly;
    default:
      return DashboardInsightFilterReturnDataType.Weekly;
  }
};

export const getTotalEnergyOrCostDataForGraphByFilterReturnType = (filter: DashboardInsightFilterReturnDataType, totalDataListByPeriod: TotalCostOrEnergyByPeriod[]) => {
  switch (filter) {
    case DashboardInsightFilterReturnDataType.Weekly:
      return getWeeklyDataForGraphAsDaysOfWeek(totalDataListByPeriod);
    case DashboardInsightFilterReturnDataType.Monthly:
      return getMonthlyDataForGraphAsDaysOfMonth(totalDataListByPeriod);
    case DashboardInsightFilterReturnDataType.Quarterly:
      return getQuarterlyDataForGraph(totalDataListByPeriod);
    case DashboardInsightFilterReturnDataType.Yearly:
      return getYearlyDataForGraph(totalDataListByPeriod);
    default:
      return getWeeklyDataForGraphAsDaysOfWeek(totalDataListByPeriod);
  }
};


export const getTotalEnergyOrCostDataForGraphByFilter = (filter: DashboardInsightFilter, totalDataListByPeriod: TotalCostOrEnergyByPeriod[]) => {
  const filterReturnType = convertFilterToFilterReturnType(filter);
  return getTotalEnergyOrCostDataForGraphByFilterReturnType(filterReturnType, totalDataListByPeriod);
};


export const extendFilterOptions = (filter: DashboardInsightFilter) => {
  const now = DateTime.now().setZone(DefaultTimeZone).setLocale("en-US");

  switch (filter) {
    case DashboardInsightFilter.ThisWeek: {
      // Day-level grouping
      const startOfThisWeek = now.startOf("week");
      const startOfNextWeek = startOfThisWeek.plus({ week: 1 }).minus({ milliseconds: 1 });

      return {
        start: startOfThisWeek.toLocaleString(DateTime.DATE_FULL),
        end: startOfNextWeek.toLocaleString(DateTime.DATE_FULL)
      };
    }
    case DashboardInsightFilter.LastWeek: {
      // Day-level grouping
      const startOfLastWeek = now.startOf("week").minus({ weeks: 1 });
      const startOfThisWeek = startOfLastWeek.plus({ week: 1 }).minus({ milliseconds: 1 });
      return {
        start: startOfLastWeek.toLocaleString(DateTime.DATE_FULL),
        end: startOfThisWeek.toLocaleString(DateTime.DATE_FULL)
      };

    }
    case DashboardInsightFilter.ThisMonth: {
      // Week-level grouping
      const startOfThisMonth = now.startOf("month");
      const startOfNextMonth = startOfThisMonth.plus({ months: 1 }).minus({ milliseconds: 1 });
      return {
        start: startOfThisMonth.toLocaleString(DateTime.DATE_FULL),
        end: startOfNextMonth.toLocaleString(DateTime.DATE_FULL)
      };

    }
    case DashboardInsightFilter.LastMonth: {
      // Week-level grouping
      const startOfLastMonth = now.startOf("month").minus({ months: 1 });
      const startOfThisMonth = startOfLastMonth.plus({ months: 1 }).minus({ milliseconds: 1 });

      return {
        start: startOfLastMonth.toLocaleString(DateTime.DATE_FULL),
        end: startOfThisMonth.toLocaleString(DateTime.DATE_FULL)
      };
    }
    case DashboardInsightFilter.ThisQuarter: {
      // Month-level grouping
      const startOfThisQuarter = now.startOf("quarter");
      const startOfNextQuarter = startOfThisQuarter.plus({ quarters: 1 }).minus({ milliseconds: 1 });
      return {
        start: startOfThisQuarter.toLocaleString(DateTime.DATE_FULL),
        end: startOfNextQuarter.toLocaleString(DateTime.DATE_FULL)
      };
    }
    case DashboardInsightFilter.LastQuarter: {
      // Month-level grouping
      const startOfLastQuarter = now.startOf("quarter").minus({ quarters: 1 });
      const startOfThisQuarter = startOfLastQuarter.plus({ quarters: 1 }).minus({ milliseconds: 1 });
      return {
        start: startOfLastQuarter.toLocaleString(DateTime.DATE_FULL),
        end: startOfThisQuarter.toLocaleString(DateTime.DATE_FULL)
      };

    }
    case DashboardInsightFilter.ThisYear: {
      // Month-level grouping
      const startOfThisYear = now.startOf("year");
      const startOfNextYear = startOfThisYear.plus({ years: 1 });
      return {
        start: startOfThisYear.toLocaleString(DateTime.DATE_FULL),
        end: startOfNextYear.toLocaleString(DateTime.DATE_FULL)
      };
    }
    default:
      return { start: "", end: "" };
  }

};