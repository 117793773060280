// Third party
import { useTranslation } from "react-i18next";
import Row from "../../components/custom/Row";

// Components
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import Col from "../../components/custom/Col";
import LoginForm from "../../components/auth-components/LoginForm";

// Img
import BluedotLogoIcon from "components/icon/icons/BluedotLogoIcon";
import LogoutOutlinedButton from "components/button/LogoutOutlinedButton";
import { BlackBodyText100, BlackH3Text100, BlackSubtitleText100 } from "components/text/Text";
import { CustomerServiceLink } from "components/links/Links";
import Card from "components/card/Card";
import ClockIcon from "components/icon/icons/ClockIcon";
import CustomerSupportTextButton from "components/button/CustomerSupportTextButton";

const context = "WaitingUserPage";
export default function WaitingUserPage() {
  const { t } = useTranslation("common");
  console.log(context);
  return (
    <>
      <Head title={t("dashboard.title")}></Head>

      <Row className="screen-height-container ">
        <Col className="bg-white scrollable-container p-4">
          <div className="w-100 d-flex justify-content-end">
            <LogoutOutlinedButton />
          </div>
          <div className="auth-form-container">
            <BlackH3Text100 className="mb-3">Waiting for Approval</BlackH3Text100>
            <BlackBodyText100>
              Your account status will remain 'Waiting for Approval' until we have reviewed your information. We will
              assess your details and respond within 24 hours.
            </BlackBodyText100>

            <div className="py-4">
              <CustomerSupportTextButton />
            </div>

            <Card className="card-with-more-shadow mt-4 p-3">
              <ClockIcon />
              <BlackSubtitleText100 className="mt-2">What’s next?</BlackSubtitleText100>
              <BlackBodyText100>You’ll get an email once your account is activated and ready to use.</BlackBodyText100>
            </Card>
          </div>
        </Col>
        <Col lg="6" className="h-100 d-none d-lg-flex align-items-center justify-content-center">
          <BluedotLogoIcon />
        </Col>
      </Row>
    </>
  );
}
