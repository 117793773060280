import { useAppDispatch, useAppSelector } from "hooks/redux.hooks";
import { useCallback, useEffect } from "react";
import { setDashboardInsights, setDashboardInsightsIsLoading, setDashboardOverviews, setDashboardOverviewsIsLoading } from "redux/slices/dashboardSlice";
import { getDashboardInsightsByFilterAsync, getDashboardOverviewsAsync, } from "services/dashboard.service";

export const useDashboardInsightsAsync = () => {
    const dispatch = useAppDispatch()
    const { filter, insights, isLoading } = useAppSelector((state) => state.dashboard.insightsState);


    const getDashboardInsightsByFilterAsyncCallback = useCallback(
        async () => {
            try {

                dispatch(setDashboardInsightsIsLoading(true));
                const res = await getDashboardInsightsByFilterAsync(filter);

                const insights = res.data

                dispatch(setDashboardInsights(insights));
            } catch (error) {
                console.error(error)

            } finally {
                dispatch(setDashboardInsightsIsLoading(false));
            }
        },
        [dispatch, filter],
    )


    useEffect(() => {
        getDashboardInsightsByFilterAsyncCallback();
    }, [getDashboardInsightsByFilterAsyncCallback]);


    return { filter, insights, isLoading, refetch: getDashboardInsightsByFilterAsyncCallback }
}


export const useDashboardInsights = () => {
    const { filter, insights, isLoading } = useAppSelector((state) => state.dashboard.insightsState);
    return { filter, insights, isLoading }
}

export const useDashboardOverviews = () => {
    const { overviews, isLoading } = useAppSelector((state) => state.dashboard.overviewsState);
    return { overviews, isLoading }
}




export const useDashboardOverviewsAsync = () => {
    const dispatch = useAppDispatch()
    const { overviews, isLoading } = useAppSelector((state) => state.dashboard.overviewsState);


    const getDashboardOverviewsByFilterAsyncCallback = useCallback(
        async () => {
            try {
                dispatch(setDashboardOverviewsIsLoading(true));

                const res = await getDashboardOverviewsAsync()
                const overviews = res.data

                if (!overviews) {
                    throw Error('Overviews is undefined')
                }

                dispatch(setDashboardOverviews(overviews));

            } catch (error) {
                console.error(error)
            } finally {
                dispatch(setDashboardOverviewsIsLoading(false));
            }
        },
        [dispatch],
    )


    useEffect(() => {
        getDashboardOverviewsByFilterAsyncCallback();
    }, [getDashboardOverviewsByFilterAsyncCallback]);


    return { overviews, isLoading, refetch: getDashboardOverviewsByFilterAsyncCallback }
}



