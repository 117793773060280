import React from "react";
import { SvgProps } from "types/svg.types";

export default function PermanentDriverIcon(props: SvgProps) {
  const fillValue = props.fill ?? "#FE7A30";
  const widthValue = props.width ?? 65;
  const heightValue = props.height ?? 66;

  return (
    <svg
      className={widthValue ? "" : "add-driver-form-type-selection-icons"}
      width={widthValue}
      height={heightValue}
      viewBox="0 0 65 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="65" height="65" rx="32.5" fill={fillValue} />
      <path
        d="M37.1419 23.2792C37.1419 27.6866 33.5689 31.2595 29.1615 31.2595C24.7541 31.2595 21.1812 27.6866 21.1812 23.2792C21.1812 18.8718 24.7541 15.2988 29.1615 15.2988C33.5689 15.2988 37.1419 18.8718 37.1419 23.2792Z"
        fill="white"
      />
      <path
        d="M36.411 34.3758C36.5139 34.6192 36.4 34.9 36.1567 35.0031C35.6274 35.2272 35.1482 35.5509 34.7472 35.9519C34.3456 36.3535 34.0224 36.8292 33.7988 37.3604C33.5752 37.8883 33.4503 38.4737 33.4503 39.084C33.4503 39.6942 33.5748 40.2787 33.7984 40.8066C34.0225 41.3358 34.3462 41.815 34.7472 42.216C35.1488 42.6176 35.6245 42.9408 36.1557 43.1645C36.3997 43.2672 36.514 43.5484 36.411 43.7922L35.215 46.6218C35.1121 46.8653 34.8312 46.9793 34.5877 46.8764C33.5746 46.4485 32.6654 45.8274 31.8996 45.0653C31.1316 44.3009 30.5141 43.3873 30.0868 42.3756C29.6592 41.3633 29.4218 40.2488 29.4218 39.084C29.4218 37.9191 29.6592 36.8047 30.0868 35.7924C30.5147 34.7792 31.1358 33.8701 31.898 33.1043C32.6623 32.3363 33.576 31.7188 34.5877 31.2915C34.8312 31.1886 35.1121 31.3026 35.215 31.5462L36.411 34.3758Z"
        fill="white"
      />
      <path
        d="M39.351 43.7953C39.2481 43.5519 39.3619 43.2711 39.6053 43.168C40.1345 42.9439 40.6137 42.6202 41.0147 42.2192C41.4163 41.8176 41.7395 41.3419 41.9632 40.8107C42.1868 40.2828 42.3117 39.6974 42.3117 39.0871C42.3117 38.4769 42.1872 37.8924 41.9636 37.3645C41.7394 36.8353 41.4157 36.3561 41.0147 35.9551C40.6131 35.5535 40.1375 35.2303 39.6062 35.0066C39.3623 34.9039 39.248 34.6227 39.351 34.3789L40.5469 31.5493C40.6499 31.3058 40.9307 31.1918 41.1743 31.2947C42.1874 31.7226 43.0966 32.3437 43.8624 33.1058C44.6257 33.8691 45.2475 34.783 45.6752 35.7955C46.1027 36.8078 46.3402 37.9223 46.3402 39.0871C46.3402 40.252 46.1027 41.3664 45.6752 42.3787C45.2472 43.3919 44.6261 44.301 43.864 45.0668C43.1007 45.8301 42.1868 46.4519 41.1743 46.8796C40.9307 46.9825 40.6499 46.8685 40.5469 46.6249L39.351 43.7953Z"
        fill="white"
      />
      <path
        d="M29.7176 47.2187C30.1816 47.2187 30.3979 46.5927 30.0654 46.2689C28.1737 44.4264 26.9989 41.8515 26.9989 39.0022C26.9989 37.6412 27.2669 36.3427 27.7532 35.1569C27.8884 34.827 27.6548 34.4501 27.2984 34.4501H24.7723C19.263 34.4501 14.7969 38.9163 14.7969 44.4256V46.4207C14.7969 46.8614 15.1542 47.2187 15.5949 47.2187H29.7176Z"
        fill="white"
      />
    </svg>
  );
}
