import { TextButtonMode } from "./TextButton";
import UnderlinedTextButton from "./UnderlinedTextButton";

type Props = {
  readonly onClicked?: () => void;
};

function EditLinkButton({ onClicked }: Props) {
  return <UnderlinedTextButton mode={TextButtonMode.PRIMARY} onClick={onClicked} text={"Edit"} />;
}

export default EditLinkButton;
