import { PaginatedApiResponse } from "types/api.types";
import { VehicleDriverSchedule, VehicleDriverScheduleAddPayload, VehicleDriverScheduleUpdatePayload } from "types/vehicle-driver-schedule.types";
import { generateApiEndpoint } from "utils";
import restApiClient from "./client";



export const defaultVehicleDriverSchedulesQuery = ''

const service = 'vehicle-driver-schedule'

/**
 * POST: '/api/vehicle-driver-schedule/${id}'
 */
export async function addVehicleDriverScheduleAsync(payload: VehicleDriverScheduleAddPayload) {
    const endpoint = generateApiEndpoint(`${service}`);
    return await restApiClient.post(endpoint, payload);
}


/**
 * GET: '/api/admin/vehicle-driver-schedule/query
 */
export async function getVehicleDriverScheduleAsync(query: string = '') {
    const endpoint = generateApiEndpoint(`${service}`);

    return (await restApiClient.get<PaginatedApiResponse<VehicleDriverSchedule>>(endpoint + (query || defaultVehicleDriverSchedulesQuery))).data;
}


/**
 * PATCH: '/api/vehicle-driver-schedule/${id}'
 */
export async function updateVehicleDriverScheduleAsync(scheduleId: string, payload: VehicleDriverScheduleUpdatePayload) {
    const endpoint = generateApiEndpoint(`${service}/${scheduleId}`);
    return await restApiClient.patch(endpoint, payload);
}

/**
 * DELETE: '/api/vehicle-driver-schedule/${id}'
 */
export async function deleteVehicleDriverScheduleAsync(scheduleId: string) {
    const endpoint = generateApiEndpoint(`${service}/${scheduleId}`);
    return await restApiClient.delete(endpoint);
}

