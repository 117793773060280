import { ErrorBoundary } from "react-error-boundary";
import Col from "../custom/Col";
import Row from "../custom/Row";
import {
  useReimbursementOverviewAsync,
  useReimbursementStatisticsByPeriodAsync
} from "../../hooks/data/reimbursement-data-accessor.hooks";
import TotalReimbursementCostStatisticCard from "./TotalReimbursementCostStatisticCard";
import TotalReimbursementEnergyStatisticCard from "./TotalReimbursementEnergyStatisticCard";
import {
  getMonthLabelForStatisticGraphByStatisticData,
  getSessionStatisticDataForGraphAsWeekOfLastQuarter
} from "../../utils/reimbursement.utils";
import ReimbursementGraph from "./ReimbursementGraph";


export default function ReimbursementStats() {
  const { statistics, isLoading: isStatisticsLoading } = useReimbursementStatisticsByPeriodAsync();

  const overviewHook = useReimbursementOverviewAsync();

  const statisticsForGraph = getSessionStatisticDataForGraphAsWeekOfLastQuarter(statistics);
  const { monthlyLabels, uniquePeriods } = getMonthLabelForStatisticGraphByStatisticData(statistics);

  return (
    <Row className="mb-4 justify-between">
      <Col md="4" className="justify-between flex-column " spacing={3}>
        <Col className="pb-2">
          <TotalReimbursementCostStatisticCard  {...overviewHook} />
        </Col>

        <Col className="pt-2 mb-4 mb-lg-0">
          <TotalReimbursementEnergyStatisticCard {...overviewHook} />
        </Col>
      </Col>
      <ErrorBoundary fallback={<>An error ocurred</>}>
        <Col md="8" className="flex-1 pl-md-4 mt-xs-2  mt-lg-0">
          <ReimbursementGraph
            labels={monthlyLabels}
            uniquePeriods={uniquePeriods}
            data={statisticsForGraph}
            isLoading={isStatisticsLoading}
          />
        </Col>
      </ErrorBoundary>
    </Row>
  );
}


/**
 * <ReimbursementGraph
 *             labels={monthlyLabels}
 *             uniquePeriods={uniquePeriods}
 *             data={chargingSessionStatisticsForGraph}
 *             isLoading={isStatisticsLoading}
 *           />
 */