import React from "react";
import { SvgProps } from "../../../types/svg.types";

function BluedotLogoIcon({ className, fill = "#CBD5E1", width = 309, height = 284 }: SvgProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 309 284"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clip-path="url(#clip0_3366_16112)">
        <path
          d="M87.0678 74.9607C95.6489 66.3797 105.857 59.4384 117.2 54.6424L94.1201 0C75.6881 7.8043 59.0809 19.0854 45.1367 33.0296C31.1925 46.9737 19.9114 63.581 12.1071 82.0129C4.31517 100.445 0 120.726 0 142.006C0 163.286 4.31517 183.555 12.1071 201.999C19.9114 220.431 31.1925 237.039 45.1367 250.983C59.0809 264.927 75.6881 276.208 94.1201 284.012L117.2 229.37C105.857 224.574 95.6366 217.633 87.0678 209.052C78.4868 200.471 71.5456 190.262 66.7495 178.919C61.9535 167.577 59.3028 155.1 59.3028 142.018C59.3028 128.937 61.9535 116.46 66.7495 105.118C71.5456 93.7749 78.4868 83.5541 87.0678 74.9854V74.9607ZM263.09 33.0296C249.146 19.0854 232.538 7.8043 214.107 0L191.027 54.6424C202.369 59.4384 212.59 66.3797 221.159 74.9607C229.74 83.5417 236.681 93.7502 241.477 105.093C246.273 116.436 248.924 128.913 248.924 141.994C248.924 155.075 246.273 167.552 241.477 178.895C236.681 190.237 229.74 200.458 221.159 209.027C212.578 217.608 202.369 224.549 191.027 229.345L214.107 283.988C232.538 276.183 249.146 264.902 263.09 250.958C277.034 237.014 288.315 220.407 296.119 201.975C303.924 183.543 308.227 163.261 308.227 141.982C308.227 120.702 303.911 100.433 296.119 81.9883C288.315 63.5563 277.034 46.9491 263.09 33.0049V33.0296Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3366_16112">
          <rect width="308.227" height="284" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BluedotLogoIcon;
