import Card from "../card/Card";

import Row from "../custom/Row";
import ReimbursementMonthlyGraph from "./ReimbursementMonthlyGraph";
import TooltipWithInfoIcon from "../tooltip/TooltipWithInfoIcon";

export default function ReimbursementGraph({ data, labels, isLoading, uniquePeriods }) {
  return (
    <Card className={"h-100 h-max-375px justify-evenly"}>
      <Row className="justify-between">
        <Row className="align-items-center">
          <h6 className=" text-dark font-weight-normal">Reimbursement Cost [ $ ]</h6>
          <TooltipWithInfoIcon description={"Chart excludes canceled HCR receipts."} />
        </Row>

        <span className="font-weight-light">Monthly</span>
      </Row>
      <ReimbursementMonthlyGraph uniquePeriods={uniquePeriods} labels={labels} data={data} isLoading={isLoading} />
    </Card>
  );
}
