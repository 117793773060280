import { FeatureName } from "../types/user.types";
import { useAuthStatus } from "./auth.hooks";

export const useFeatureFlags = () => {
  const { user } = useAuthStatus();

  const isTeslaInvoiceListingEnabled = user?.features.some(({ name, isEnabled }) => {
    return isEnabled && name === FeatureName.TESLA_INVOICE_LISTING_ENABLED;
  });

  const isChargingAssignDriverEnabled = user?.features.some(({ name, isEnabled }) => {
    return isEnabled && name === FeatureName.CHARGING_ASSIGN_DRIVER_ENABLED;
  });

  return { isTeslaInvoiceListingEnabled, isChargingAssignDriverEnabled };
};