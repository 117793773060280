import { useCallback, useState } from "react";
import { getInvoiceStripePortalUrlAsync } from "services/invoice.services";
import BlackButton from "./BlackButton";

type StripeButtonProps = {
  readonly onClick?: () => void;
  readonly text?: string;
  readonly className?: string;
  readonly type?: string;
  readonly isLoading?: boolean;
  readonly isDisabled?: boolean;
};

export const ManageBillingButton = (props: StripeButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleManageBillingButtonClicked = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getInvoiceStripePortalUrlAsync();
      window.open(response.url, "_blank", "noreferrer");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <BlackButton
      className="w-175px"
      text="Manage billing"
      isLoading={isLoading}
      isDisabled={isLoading}
      onClick={handleManageBillingButtonClicked}
      {...props}
    />
  );
};
