import ButtonWithIcon from "./ButtonWithIcon";
import { ButtonProps } from "../../types/button.types";



function TertiaryButton(props: ButtonProps) {
  return <ButtonWithIcon theme={'tertiary'} {...props}  />;
}

export default TertiaryButton;
