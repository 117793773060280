import React from "react";

export default function PermanentDriverIconLight() {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="56" height="56" rx="28" fill="#CBD5E1" />
      <path
        d="M32.0011 19.6254C32.0011 23.4226 28.9229 26.5008 25.1257 26.5008C21.3285 26.5008 18.2503 23.4226 18.2503 19.6254C18.2503 15.8282 21.3285 12.75 25.1257 12.75C28.9229 12.75 32.0011 15.8282 32.0011 19.6254Z"
        fill="white"
      />
      <path
        d="M31.3714 29.1855C31.46 29.3952 31.362 29.6372 31.1523 29.726C30.6963 29.9191 30.2835 30.198 29.938 30.5434C29.592 30.8894 29.3136 31.2992 29.1209 31.7569C28.9282 32.2117 28.8206 32.7161 28.8206 33.2418C28.8206 33.7675 28.9279 34.2711 29.1205 34.7259C29.3136 35.1819 29.5925 35.5947 29.938 35.9402C30.284 36.2862 30.6938 36.5646 31.1515 36.7573C31.3617 36.8458 31.4601 37.0881 31.3714 37.2981L30.341 39.7359C30.2523 39.9457 30.0104 40.044 29.8005 39.9553C28.9277 39.5866 28.1444 39.0515 27.4846 38.3949C26.823 37.7364 26.291 36.9493 25.9229 36.0777C25.5545 35.2055 25.3499 34.2454 25.3499 33.2418C25.3499 32.2382 25.5545 31.2781 25.9229 30.406C26.2915 29.5331 26.8266 28.7498 27.4833 28.0901C28.1417 27.4284 28.9289 26.8965 29.8005 26.5283C30.0104 26.4397 30.2523 26.5379 30.341 26.7477L31.3714 29.1855Z"
        fill="white"
      />
      <path
        d="M33.9043 37.3008C33.8157 37.0911 33.9137 36.8492 34.1234 36.7604C34.5794 36.5673 34.9922 36.2884 35.3377 35.9429C35.6837 35.5969 35.9621 35.1871 36.1548 34.7294C36.3475 34.2747 36.4551 33.7703 36.4551 33.2445C36.4551 32.7188 36.3478 32.2152 36.1552 31.7604C35.9621 31.3045 35.6832 30.8916 35.3377 30.5462C34.9917 30.2002 34.5819 29.9217 34.1242 29.729C33.914 29.6405 33.8155 29.3983 33.9043 29.1882L34.9347 26.7504C35.0234 26.5406 35.2653 26.4424 35.4752 26.531C36.348 26.8997 37.1313 27.4348 37.791 28.0914C38.4486 28.749 38.9844 29.5364 39.3528 30.4087C39.7212 31.2808 39.9257 32.241 39.9257 33.2445C39.9257 34.2481 39.7212 35.2082 39.3528 36.0804C38.9842 36.9532 38.449 37.7365 37.7924 38.3963C37.1348 39.0539 36.3475 39.5896 35.4752 39.958C35.2653 40.0467 35.0234 39.9485 34.9347 39.7386L33.9043 37.3008Z"
        fill="white"
      />
      <path
        d="M25.6047 40.2502C26.0045 40.2502 26.1909 39.7108 25.9045 39.4319C24.2747 37.8445 23.2625 35.6261 23.2625 33.1714C23.2625 31.9988 23.4934 30.8801 23.9123 29.8585C24.0288 29.5743 23.8276 29.2496 23.5205 29.2496H21.3442C16.5978 29.2496 12.75 33.0973 12.75 37.8438V39.5627C12.75 39.9424 13.0578 40.2502 13.4375 40.2502H25.6047Z"
        fill="white"
      />
    </svg>
  );
}
