import { Row } from "reactstrap";
import MuiTooltip from "../tooltip/MuiTooltip";

export type TWithName =
  | {
      name: string;
      [key: string]: any;
    }
  | undefined;

type Props = {
  list?: TWithName[];
};

export default function DynamicListWithTooltip({ list }: Props) {
  if (list?.length === 0 || !list) {
    return <></>;
  }

  if (list?.length === 1 && list[0]?.name) {
    return <p className="mr-1 mb-0 w-max-110px text-truncate text-black-100">{list[0]?.name}</p>
  }

  const firstItemName = list[0]?.name;
  const others = list.slice(1, list.length);
  const othersLength = others.length;

  let uniqueKey = "";
  others.forEach((u) => {
    uniqueKey += u?.name[0] + `${u?.name[u?.name.length - 1]}` + `-${othersLength}`;
  });

  return (
    <Row className="flex align-center m-0">
      <p className="mr-1 mb-0 w-max-110px text-truncate text-black-100">{firstItemName}</p>

      <MuiTooltip
        title={others.map((o, ix) => (
          <div style={{ padding: 2 }} key={ix}>{`${o?.name}`}</div>
        ))}
        placement="top"
      >
        <div id={uniqueKey} className="help-fill rounded-circle center bg-dark w-20px h-20px text-white fs-12px">
          +{othersLength}
        </div>
      </MuiTooltip>
    </Row>
  );
}

/**
 *  {others.map((ou, ix) => (
          <p key={ix}>{ou.firstName}</p>
        ))}
 */
