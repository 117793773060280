// React
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";

// Third party libraries
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Form, FormGroup } from "reactstrap";

// Components
import ControllerValidationError from "components/form/ControllerValidationError";

// Services & Utilities & Hooks
import CancelButton from "components/button/CancelButton";
import SaveButton from "components/button/SaveButton";
import { useDebounce } from "hooks/vehicle.hooks";
import vehicleService, { getVehicleVpicInfoAsync } from "services/vehicle.services";

import { RSelect } from "components/Component";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import { useAlertManager } from "hooks/alert.hooks";
import { Vehicle } from "types/vehicle.types";
import { handleApiErrorResponse } from "utils";

type Props = {
  readonly isEditMode?: boolean;
  readonly defaultDataForEditMode?: Vehicle;
};

type Selectors = {
  carBrand: {
    isRequired: boolean;
    error?: boolean;
    data?: {
      label?: string;
      value?: string;
    };
    errorMessage: string;
  };
  carModel: {
    isRequired: boolean;
    error?: boolean;
    data?: {
      label?: string;
      value?: string;
    };
    errorMessage: string;
  };
};

/**
 * Component
 */
export default function VehicleManualFlowForm({ isEditMode, defaultDataForEditMode }: Props) {
  /**
   * Initial Hooks
   */

  const history = useHistory();
  const { t } = useTranslation("common");

  /**
   * State Hooks
   */

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [vinState, setVinState] = useState({
    vin: "",
    isVinVerified: false,
    isVinErrorMessageVisible: false,
    vinErrorMessage: t("vehicle.form.vin.error")
  });

  const { vin, isVinVerified, vinErrorMessage, isVinErrorMessageVisible } = vinState;

  const debouncedVinValue = useDebounce(vin, 500) as string;

  const defaultValues = useMemo(() => {
    return {
      defaultCarModel:
        { label: defaultDataForEditMode?.carModel, value: defaultDataForEditMode?.carModel } ?? undefined,
      defaultCarBrand:
        { label: defaultDataForEditMode?.carBrand, value: defaultDataForEditMode?.carBrand } ?? undefined
    };
  }, [defaultDataForEditMode]);

  const { handleShowAlertByType, handleCloseAlert, handleOpenErrorAlert } = useAlertManager();
  const [selectors, setSelectors] = useState<Selectors>({
    carBrand: {
      isRequired: true,
      error: undefined,
      data: defaultValues?.defaultCarBrand,
      errorMessage: t("vehicle.form.car_brand.error")
    },
    carModel: {
      isRequired: true,
      error: undefined,
      data: defaultValues?.defaultCarModel,
      errorMessage: t("vehicle.form.car_model.error")
    }
  });

  const { register, handleSubmit } = useForm();

  /**
   * Utils
   */
  const redirectToVehiclesScreen = useCallback(() => {
    setTimeout(() => {
      history.push("/vehicles");
    }, 1000);
  }, [history]);

  const validateSelectors = useCallback(() => {
    if (isEditMode) {
      return true;
    }

    let errorCount = 0;

    if (!isVinVerified) {
      setVinState({ ...vinState, isVinErrorMessageVisible: true });
    }

    const newSelectorState = { ...selectors };
    Object.keys(selectors).forEach((selector) => {
      const iteratedSelectorData = selectors[selector];
      const { isRequired, data, errorMessage, isMulti } = iteratedSelectorData;

      if (isRequired) {
        const isDataEntered = isMulti ? data && data?.length > 0 : !!data?.value;

        const newErrorStatus = isDataEntered ? undefined : errorMessage;

        if (!isDataEntered) {
          errorCount++;
        }

        if (newErrorStatus !== iteratedSelectorData.error) {
          newSelectorState[selector] = { ...iteratedSelectorData, error: newErrorStatus };
        }
      }
    });

    setSelectors(newSelectorState);

    return errorCount === 0;
  }, [isEditMode, isVinVerified, selectors, vinState]);

  const handleInitFormFlags = useCallback(() => {
    setIsSubmitting(true);
    handleCloseAlert();
  }, [handleCloseAlert]);

  const resetVinStateAsUnverified = useCallback(() => {
    setVinState((vinState) => {
      return { ...vinState, isVinVerified: false };
    });
    setSelectors((selectors) => {
      return {
        ...selectors,
        carModel: { ...selectors.carModel, data: undefined },
        carBrand: { ...selectors.carBrand, data: undefined }
      };
    });
  }, []);

  /**
   * Handlers
   */

  /**
   *
   */
  const handleSelectorChange = (selector, value) => {
    setSelectors({ ...selectors, [selector]: { ...selectors[selector], data: value } });
  };

  const handleVinChanged = useCallback(
    (e) => {
      setVinState({ ...vinState, vin: e.target.value?.trim() });
    },
    [vinState]
  );




  /**
   *
   */
  const handleValidatedSubmitEdit = useCallback(
    async (validatedData) => {
      const areTheSelectorsValidated = validateSelectors();
      if (!areTheSelectorsValidated) {
        return;
      }

      handleInitFormFlags();
      const isSuccess = true;
      let message = "Vehicle was updated successfully!";
      const vehicleId = defaultDataForEditMode?.id;

      try {
        await vehicleService.updateVehicleAsync(vehicleId, validatedData);

        redirectToVehiclesScreen();
      } catch (err: any) {
        message = handleApiErrorResponse(err);
      } finally {
        setIsSubmitting(false);
        onFormReset();
        handleShowAlertByType(isSuccess, message);
      }
    },
    [
      defaultDataForEditMode?.id,
      handleInitFormFlags,
      handleShowAlertByType,
      redirectToVehiclesScreen,
      validateSelectors
    ]
  );

  /**
   *
   */
  const handleValidatedSubmitAdd = useCallback(
    async (validatedData) => {
      const areTheSelectorsValidated = validateSelectors();
      if (!areTheSelectorsValidated) {
        return;
      }

      handleInitFormFlags();

      let isSuccess = true;
      let message = "Vehicle successfully added!";
      try {

        await vehicleService.addVehicle(validatedData);

        redirectToVehiclesScreen();
      } catch (err: any) {
        message = handleApiErrorResponse(err);
        isSuccess = false;
      } finally {
        setIsSubmitting(false);
        onFormReset();
        handleShowAlertByType(isSuccess, message);
      }
    },
    [handleInitFormFlags, handleShowAlertByType, redirectToVehiclesScreen, validateSelectors]
  );

  const handleGetVehicleVpicInfoAsyncCallback = useCallback(
    async (vin: string) => {
      try {
        const vpicInfo = (await getVehicleVpicInfoAsync(vin)).data;
        const { carBrand, carModel } = vpicInfo;

        setSelectors((selectors) => {
          return {
            ...selectors,
            carModel: { ...selectors.carModel, data: { label: carModel, value: carModel }, error: false },
            carBrand: { ...selectors.carBrand, data: { label: carBrand, value: carBrand }, error: false }
          };
        });
        setVinState((vinState) => {
          return { ...vinState, isVinVerified: true };
        });
      } catch (error) {
        resetVinStateAsUnverified();
        handleOpenErrorAlert(handleApiErrorResponse(error));
      }
    },
    [handleOpenErrorAlert, resetVinStateAsUnverified]
  );

  /**
   * Effects
   */

  useEffect(() => {
    console.log("debouncedVinValue.length", debouncedVinValue.length);
    if (debouncedVinValue.length === 17) {
      handleGetVehicleVpicInfoAsyncCallback(debouncedVinValue);
    } else {
      resetVinStateAsUnverified();
    }
  }, [debouncedVinValue, handleGetVehicleVpicInfoAsyncCallback, resetVinStateAsUnverified]);

  const onFormReset = () => {
  };

  const handleValidatedSubmit = isEditMode ? handleValidatedSubmitEdit : handleValidatedSubmitAdd;

  const shortCircuit = isEditMode && !defaultDataForEditMode;

  if (shortCircuit) {
    return <></>;
  }

  return (
    <>
      <Form
        className="w-100 h-100 center"
        onReset={() => history.push("/vehicles")}
        onSubmit={handleSubmit(handleValidatedSubmit)}
      >
        <div className=" gy-4 w-100 h-100  p-2">
          <Col>
            <FormGroup>
              <label className="form-label">{t("vehicle.form.name.title")}</label>
              <input
                defaultValue={defaultDataForEditMode?.name ?? undefined}
                className="form-control"
                type="text"
                name="name"
                placeholder={t("vehicle.form.name.placeholder") ?? ""}
                // defaultValue={data?.businessName}
                ref={register({ required: "required" })}
              />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <label className="form-label">{t("vehicle.form.plate_number.title")}</label>
              <input
                defaultValue={defaultDataForEditMode?.plateNumber}
                className="form-control"
                type="text"
                name="plateNumber"
                // defaultValue={data?.businessName}
                placeholder={t("vehicle.form.plate_number.placeholder") ?? ""}
                ref={register()}
              />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <label className="form-label">{t("vehicle.form.vin.title")}</label>
              <input
                disabled={isEditMode}
                defaultValue={defaultDataForEditMode?.vin}
                className="form-control"
                type="text"
                name="vin"
                onChange={handleVinChanged}
                // defaultValue={data?.businessName}
                placeholder={t("vehicle.form.vin.placeholder") ?? ""}
                // ref={register({ required: t("general.required") })}
                ref={register({ required: "required" })}
              />
              <ControllerValidationError error={!isVinVerified && isVinErrorMessageVisible && vinErrorMessage} />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <label className="form-label">{t("vehicle.form.car_brand.title")}</label>
              <RSelect
                defaultValue={defaultValues.defaultCarBrand}
                isDisabled={true}
                // inputRef={register({ required: "required" })}
                //isOptionDisabled={() => true}
                value={selectors.carBrand.data}
                name={"carBrand"}
                placeholder={t("vehicle.form.car_brand.placeholder")}
                options={[]}
                onChange={(data) => handleSelectorChange("carBrand", data)}
              />
              <ControllerValidationError error={selectors.carBrand.error} />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <label className="form-label">{t("vehicle.form.car_model.title")}</label>
              <RSelect
                defaultValue={defaultValues?.defaultCarModel}
                value={selectors.carModel.data}
                isDisabled={true}
                name={"carModel"}
                placeholder={t("vehicle.form.car_model.placeholder")}
                options={[]}
                onChange={(data) => handleSelectorChange("carModel", data)}
              />
              <ControllerValidationError error={selectors.carModel.error} />
            </FormGroup>
          </Col>

          <Row className="w-100 justify-end align-center mt-4 mt-md-0">
            <CancelButton />
            <SaveButton isDisabled={isSubmitting} isLoading={isSubmitting} />
          </Row>
        </div>
      </Form>
    </>
  );
}
