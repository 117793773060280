import React from "react";

type Props = {};

function VehiclePlaceholderIconSmall({}: Props) {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_26847_54405)">
        <path
          d="M15.4524 5.35809C15.4206 5.27342 15.373 5.19668 15.3148 5.13583L13.8833 3.60116L12.6132 0.66943C12.5048 0.465691 12.3513 0.296349 12.1687 0.179926C11.9888 0.0608573 11.7851 0 11.576 0H4.42397C4.21494 0 4.0112 0.0608573 3.82863 0.17728C3.64606 0.293703 3.49524 0.463045 3.38411 0.666784L2.11404 3.59852L0.685218 5.13583C0.627007 5.19933 0.579379 5.27342 0.547627 5.35809C0.515876 5.44276 0.5 5.53272 0.5 5.62533V11.3327C0.5 11.51 0.560857 11.6793 0.669342 11.8037C0.777827 11.928 0.923355 11.9995 1.07682 11.9995H3.67252C3.90272 11.9995 4.24934 11.7322 4.24934 11.4676V10.5019H11.7507V11.3353C11.7507 11.6026 11.9809 12.0021 12.2111 12.0021H14.9205C15.074 12.0021 15.2195 11.9307 15.328 11.8063C15.4365 11.682 15.4974 11.5126 15.4974 11.3353V5.62798C15.4974 5.53537 15.4815 5.44541 15.4497 5.36073L15.4524 5.35809ZM3.654 4.12771L4.67269 1.66696C4.71503 1.56641 4.81293 1.50026 4.92142 1.50026H11.0865C11.195 1.50026 11.2956 1.56641 11.3352 1.66696L12.346 4.12771C12.4201 4.30499 12.2878 4.50079 12.0973 4.50079H3.90272C3.70956 4.50079 3.57991 4.30499 3.654 4.12771ZM5.00079 7.75798C5.00079 8.03052 4.78118 8.25013 4.50864 8.25013H2.49242C2.21988 8.25013 2.00026 8.03052 2.00026 7.75798V6.49321C2.00026 6.17305 2.30191 5.93756 2.61148 6.01693L4.62771 6.51967C4.84733 6.57523 5.00079 6.77104 5.00079 6.99594V7.75798ZM14.0024 7.75798C14.0024 8.03052 13.7828 8.25013 13.5102 8.25013H11.494C11.2215 8.25013 11.0019 8.03052 11.0019 7.75798V6.99594C11.0019 6.77104 11.1553 6.57259 11.3749 6.51967L13.3912 6.01693C13.7007 5.9402 14.0024 6.17305 14.0024 6.49321V7.75798Z"
          fill="#0C0C0C"
        />
      </g>
      <defs>
        <clipPath id="clip0_26847_54405">
          <rect width="15" height="11.9995" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default VehiclePlaceholderIconSmall;
