import React, { useState } from "react";
import VehiclePlaceholder from "components/vehicle-components/placeholder/VehiclePlaceholder";
import LoadingSkeleton from "components/loading/LoadingSkeleton";

type Props = {
  vehicleName?: string;
  imgSource?: string;
  isSmall?: boolean;
};
export default function VehicleAvatar({ vehicleName, imgSource, isSmall }: Props) {
  const [imageError, setImageError] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleError = (e:any) => {
    console.error(e);
    setImageError(true);
    setIsLoading(false);
  };

  const containerClassName = isSmall ? "w-min-45px h-min-28px h-28px" : "w-100";
  const vehicleWidth = isSmall ? 45 : 212;
  const vehicleHeight = isSmall ? 28 : 130;

  const vehiclePlaceholderWidth = isSmall ? 20 : 75;
  const vehiclePlaceholderHeight = isSmall ? 16 : 130;

  const imageSourceWithDimension = imgSource ? imgSource + (isSmall ? "/small.png" : "/large.png") : undefined;

  return (
    <div className="d-flex center m-0 w-100 h-100 pointer-events-none">
      <div className={`${containerClassName} d-flex align-items-center justify-content-center`}>
        {isLoading && (
          <>
            {isSmall ? (
              <div className="w-100 d-flex align-items-center">
                <LoadingSkeleton count={1} height={24} />
              </div>
            ) : (
              <VehiclePlaceholder
                className="bg-white"
                width={vehiclePlaceholderWidth}
                height={vehiclePlaceholderHeight}
              />
            )}
          </>
        )}
        {!imageError && imageSourceWithDimension ? (
          <img
            className={`${isLoading && "d-none"}`}
            onLoad={() => setIsLoading(false)}
            onError={handleError}
            src={imageSourceWithDimension}
            width={vehicleWidth}
            height={vehicleHeight}
            alt="vehicle"
          />
        ) : (
          <>
            {!isLoading && (
              <VehiclePlaceholder
                className={`${!isSmall ? "bg-white rounded-xxl" : "bg-secondary rounded-sm"}`}
                width={vehiclePlaceholderWidth}
                height={vehiclePlaceholderHeight}
              />
            )}
          </>
        )}
      </div>
      {vehicleName && <p className="flex-lg-grow-1 mb-0 ml-2 text-truncate pointer-events-none">{vehicleName}</p>}
    </div>
  );
}
