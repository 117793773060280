import Row from "../custom/Row";
import RightArrowItemIcon from "../icon/icons/RightArrowItemIcon";
import IosSwitch from "../input/switch/IosSwitch";
import Col from "../custom/Col";
import { NeutralBodyLargeText100, NeutralBodyMediumText200 } from "../text/Text";
import { InboxSettingsKey } from "../../types/settings.types";
import { getInboxNotificationPreferencesDisplayContent } from "../../utils/settings.utils";

type Props = {
  readonly isLoading: boolean
  readonly itemKey: InboxSettingsKey;
  readonly onToggle: (key: InboxSettingsKey) => void,
  readonly isChecked: boolean,
  readonly isDisabled: boolean,
};

function SettingsPagePreferencesContentItem({ isChecked, onToggle, isLoading, isDisabled, itemKey }: Props) {
  const { title, description } = getInboxNotificationPreferencesDisplayContent(itemKey);
  return (
    <Row className={"w-100 flex-nowrap"}>
      <RightArrowItemIcon className={"mt-1"} />
      <Row className="justify-between w-100 ml-2">
        <Col>
          <NeutralBodyLargeText100>{title}</NeutralBodyLargeText100>
          <NeutralBodyMediumText200>{description}</NeutralBodyMediumText200>
        </Col>
        <IosSwitch isDisabled={isLoading || isDisabled} onToggle={() => onToggle(itemKey)} isChecked={isChecked} />
      </Row>
    </Row>
  );
}

export default SettingsPagePreferencesContentItem;
