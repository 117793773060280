import React from "react";
import { BodyText, WhiteBody2Text0 } from "../text/Text";

const NewBadge = () => {
  return (
    <div className={"bg-violet-500 rounded-xl position-relative ml-1 px-1"}>
      <WhiteBody2Text0>New</WhiteBody2Text0>
    </div>
  );
};

export default NewBadge;