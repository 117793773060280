import React from "react";
import Row from "../../../custom/Row";
import BulbOutlineIcon from "../../../icon/icons/BulbOutlineIcon";

import { NeutralBodySmallText100, NeutralBodySmallText300, NeutralTitleMediumText100 } from "../../../text/Text";

import Card from "../../../card/Card";
import AddVehicleTeslaFlowStarterGuideCardBlock from "./AddVehicleTeslaFlowStarterGuideCardBlock";
import { useHistory } from "react-router";
import Col from "../../../custom/Col";
import { TextButtonMode } from "../../../button/TextButton";
import HoverUnderlinedTextButton from "../../../button/HoverUnderlinedTextButton";
import LearnMoreTextButton from "../../../button/LearnMoreTextButton";
import { useIntercomTrigger } from "../../../../hooks/intercom.hooks";

const AddVehicleTeslaFlowStarterGuide = () => {
  const history = useHistory();
  const { triggerIntercom } = useIntercomTrigger();
  return (

    <Card className="w-100 h-100">
      <div className={"violent-card mb-4"}>
        <Row className={"align-items-center"}>
          <BulbOutlineIcon />
          <NeutralTitleMediumText100 className={"ml-1"}>Important guide</NeutralTitleMediumText100>
        </Row>
        <NeutralBodySmallText100>If your Tesla vehicle belongs to a business organization (i.e., not owned
          individually), in order to create a streamlined flow to connect your Tesla Business vehicles to Bluedot
          FleetOS, we recommend the following procedure:</NeutralBodySmallText100>
      </div>

      <AddVehicleTeslaFlowStarterGuideCardBlock
        rank={"1"}
        label={"Create a Tesla account under your organization dedicated for charging payments, such as "}
        descriptionOverwrite={<HoverUnderlinedTextButton mode={TextButtonMode.BLUE} textClassName={"word-break-word"}
                                                         text={"tesla_charging_payer@yourorganization.com"} />} />

      <AddVehicleTeslaFlowStarterGuideCardBlock
        rank={"2"}
        label={"Add this account as a driver to all your vehicles in Tesla Business."}
        description={"Note that Tesla allows up to 5 drivers per vehicle. So adding a dedicated charging payment responsible driver won't cause any operational limitation problems in practical terms."}
      />
      <AddVehicleTeslaFlowStarterGuideCardBlock
        rank={"3"}
        label={"Mark this account as the charging payment responsible for all your vehicles in Tesla Business."}
      />

      <AddVehicleTeslaFlowStarterGuideCardBlock
        rank={"4"}
        label={"Connect this driver account to Bluedot FleetOS through"}
        descriptionOverwrite={<HoverUnderlinedTextButton text={"https://fleets.thebluedot.co/add-vehicle/tesla"}
                                                         onClick={() => history.push("/add-vehicle/tesla")} />} />
      <AddVehicleTeslaFlowStarterGuideCardBlock
        rank={"5"}
        label={"We recommend that you never change the charging payment responsibility of the vehicle during its lifetime"}
      />

      <AddVehicleTeslaFlowStarterGuideCardBlock
        isBorderSeperatorHidden
        rank={"6"}
        label={"In the future, if new vehicles arrive, apply the 2nd and 3rd items for the newly arrived vehicles in Tesla Business."}
        descriptionOverwrite={<Col className={`pl-1`}>
          <NeutralBodySmallText300>
            &#x2022; {" "} Note that other steps are not necessary for new vehicles
          </NeutralBodySmallText300>
          <NeutralBodySmallText300>
            &#x2022; {" "} Our systems check Tesla connections for new vehicles on a daily basis
          </NeutralBodySmallText300>
          <NeutralBodySmallText300>
            &#x2022;{" "} Thus your new vehicle should appear in Bluedot FleetOS at most in 24 hours
          </NeutralBodySmallText300>
          <NeutralBodySmallText300>
            &#x2022; {" "}If you like your vehicle to be added sooner, please contact us
          </NeutralBodySmallText300>
        </Col>} />

      <Row className={"w-100 px-2 mt-4"}> <LearnMoreTextButton
        onClick={() => window.open("https://intercom.help/bluedot-for-fleets/en/articles/9631032-tesla-account-connection-how-to-guide", "_blank")} /></Row>

    </Card>
  );
};

export default AddVehicleTeslaFlowStarterGuide;