import React from "react";
import Row from "../../custom/Row";
import Col from "../../custom/Col";
import InsightCard from "./VehicleDetailsChargingInsightsCard";
import VehicleDetailsChargingInsightsLocationGraph from "./VehicleDetailsChargingInsightsLocationGraph";
import { VehicleChargingSummary } from "types/vehicle.types";
import { getVehicleAllLocationInsightDetails } from "../../../utils/vehicle.utils";

type Props = {
  vehicleChargingSummary: VehicleChargingSummary | undefined
  isLoading: boolean
}

function VehicleDetailsAllLocationInsight({ vehicleChargingSummary, isLoading }: Props) {
  const {
    isTotalCostOverflow,
    formattedTotalCost,
    formattedTotalEnergy,
    isTotalEnergyOverflow,
    totalSessionSize,
    homeSessionSize,
    publicSessionSize
  } = getVehicleAllLocationInsightDetails(vehicleChargingSummary);


  if (isLoading) {
    return <InsightCard.Loading />;
  }

  return (
    <Row className="center h-100">
      <Col xs="5" lg="4" className="h-100">
        <InsightCard.BlackTitle>All Locations</InsightCard.BlackTitle>
        <InsightCard.Cost isOverflow={isTotalCostOverflow} className="mt-3">
          {formattedTotalCost}
        </InsightCard.Cost>
        <InsightCard.Energy isOverflow={isTotalEnergyOverflow} className="mt-2">
          {formattedTotalEnergy} kWh
        </InsightCard.Energy>
        <InsightCard.Sessions className="mt-2">{totalSessionSize} sessions</InsightCard.Sessions>
      </Col>
      <Col xs="7" lg="8" className="h-100 w-100">
        <VehicleDetailsChargingInsightsLocationGraph
          homeSessionSize={homeSessionSize}
          publicSessionSize={publicSessionSize}
        />
      </Col>
    </Row>
  );
}

export default VehicleDetailsAllLocationInsight;