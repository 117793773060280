import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";
import { User } from "../types/user.types";
import { ApiResponse } from "../types/api.types";
import { UpdateOrganizationPayload } from "types/organization.types";

/**
 * GET: '/api/organization'
 */
export async function getOrganizationInfoAsync(): Promise<ApiResponse<User>> {
    const endpoint = generateApiEndpoint("organization");
    const response = await restApiClient.get(endpoint);
    return response.data;
}



/**
 * POST: '/api/organization'
 */
export async function updateOrganizationInfoAsync(payload: UpdateOrganizationPayload): Promise<ApiResponse<{}>> {
    const endpoint = generateApiEndpoint("organization");
    const response = await restApiClient.patch(endpoint, payload);
    return response.data;
}
