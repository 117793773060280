import { TotalCostChart } from "components/charts/Chart";
import { useMemo } from "react";
import { DashboardTablePlaceholder } from "../dashboard-placeholders/DashboardTablePlaceholder";

import { useDashboardInsights } from "hooks/data/dashboard-data-accessor.hooks";
import { DashboardInsightFilterReturnDataType } from "types/dashboard.types";
import {
  convertFilterToFilterReturnType,
  extendFilterOptions,
  getTotalEnergyOrCostDataForGraphByFilter
} from "utils/dashboard.utils";
import { getMonthNameByDateString } from "utils/date.utils";

export default function DashboardCostGraph() {
  const { filter, insights } = useDashboardInsights();
  const totalCostData = insights?.totalCostByPeriod;
  const areThereAnyData = totalCostData && totalCostData.length > 0;

  const totalEnergyOrCostDataForGraph = useMemo(() => {
    if (!areThereAnyData) {
      return undefined;
    }

    // We need to convert total cost value cent to dollar
    const totalCostDataInDollar = totalCostData.map((tcd) => {
      return { ...tcd, total: tcd.total / 100 };
    });

    return getTotalEnergyOrCostDataForGraphByFilter(filter, totalCostDataInDollar);
  }, [areThereAnyData, totalCostData, filter]);

  const areThereAnyTotalValueInsideGraphDataList =
    (totalEnergyOrCostDataForGraph?.data ?? []).reduce((accumulator, currentValue) => accumulator + currentValue, 0) >
    0;

  if (!totalEnergyOrCostDataForGraph || !areThereAnyTotalValueInsideGraphDataList) {
    return <DashboardTablePlaceholder />;
  }

  const { data, labels } = totalEnergyOrCostDataForGraph;

  const totalCostChartData = {
    labels: labels,
    dataUnit: "USD",
    stacked: true,
    datasets: [
      {
        backgroundColor: data?.map((_) => "#1E44FF"),
        data: data
      }
    ]
  };

  const isMonthly = convertFilterToFilterReturnType(filter) === DashboardInsightFilterReturnDataType.Monthly;
  const { start } = extendFilterOptions(filter);
  const selectedMonth = isMonthly ? getMonthNameByDateString(start) : undefined;
  return <TotalCostChart tooltipTitlePrefix={selectedMonth} data={totalCostChartData} />;
}
