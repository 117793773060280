import { CircularProgress } from "@mui/material";
import RightChevron from "components/icon/icons/RightChevron";
import { useHistory } from "react-router";
import { VehicleLocation } from "types/vehicle.types";
import { useCachedVehicleSmartcarDataAsync } from "../../../hooks/data/vehicle-data-accessor.hooks";
import { useGoogleReverseGeocoding } from "../../../hooks/google-maps.hooks";
import TemporaryDriverIcon from "../../../images/TemporaryDriverLogo.png";
import { getFormattedPhoneNumber } from "../../../utils/format.utils";
import {
  formatBatteryRemainingPercent,
  getVehicleConnectionStatus,
  getVehicleSummaryName
} from "../../../utils/vehicle.utils";
import ConnectionStatusBadge from "../../badge/ConnectionStatusBadge";
import Row from "../../custom/Row";
import ChargerLighteningIcon from "../../icon/icons/ChargerLighteningIcon";
import LocationIcon from "../../icon/icons/LocationIcon";
import PermanentDriverIcon from "../../icon/icons/PermanentDriverIcon";
import LoadingSkeleton from "../../loading/LoadingSkeleton";
import { BlackBodyText100, BlackCaptionText100, BlackCaptionText300, GreenCaptionText200 } from "../../text/Text";
import VehicleDetailsFetchedInfoTooltip from "../vehicle-details/VehicleDetailsFetchedInfoTooltip";

type Props = {
  readonly selectedMarker: VehicleLocation;
};

function LiveVehicleLocatorInfoWindowContent({ selectedMarker }: Props) {
  const { isLoading, vehicle } = useCachedVehicleSmartcarDataAsync(selectedMarker.id);
  const { isLocationLoading, location } = useGoogleReverseGeocoding(vehicle?.telemetry?.location?.data);

  const history = useHistory();

  if (isLoading || !vehicle) {
    return (
      <div className="w-200px h-275px center">
        <CircularProgress size={35} style={{ color: "#1E44FF" }} />
      </div>
    );
  }

  const { connectionStatus } = getVehicleConnectionStatus(vehicle);
  const driver = vehicle?.users?.length > 0 ? vehicle?.users[0] : undefined;
  const driverName = driver?.firstName ? driver.firstName + " " + driver?.lastName : "No driver assigned";
  const phoneNumber = driver?.phoneNumber;

  const isDriverPermanent = driver?.type === "fixed";
  const batteryPercentRemaining = vehicle.telemetry?.battery?.data.percentage.toString();
  const formattedBatteryRemainingPercent = formatBatteryRemainingPercent(batteryPercentRemaining);
  const batteryPercentDisplayText = formattedBatteryRemainingPercent ? `${formattedBatteryRemainingPercent}%` : "-";
  const icon = isDriverPermanent ? (
    <PermanentDriverIcon width={30} height={30} fill={"#FE7A30"} />
  ) : (
    <img width={30} height={30} src={TemporaryDriverIcon} alt="Temporary Driver Icon" />
  );

  return (
    <div>
      <Row className="d-flex align-items-center">
        {icon}
        <div className="ml-1">
          <BlackBodyText100>{driverName}</BlackBodyText100>
          <BlackCaptionText300>{getFormattedPhoneNumber(phoneNumber)}</BlackCaptionText300>
        </div>
        <div className="d-flex flex-1 justify-content-end">
          <VehicleDetailsFetchedInfoTooltip fetchedAt={vehicle?.telemetry?.location?.meta?.fetchedAt} />
        </div>
      </Row>
      <div className="horizontal-border-line-light my-2 mb-1 w-100" />
      <BlackCaptionText100 className="w-max-200px">{getVehicleSummaryName(vehicle)}</BlackCaptionText100>
      <div className="horizontal-border-line-light my-2 mb-1 w-100" />

      <div className="d-flex justify-content-start">
        <ConnectionStatusBadge connectionStatus={connectionStatus} />
      </div>
      <div className="align-items-center flex-nowrap d-inline-flex mt-2">
        {!isLocationLoading ? (
          <>
            <LocationIcon />
            <BlackCaptionText100 className="w-max-200px ml-1">{location}</BlackCaptionText100>
          </>
        ) : (
          <LoadingSkeleton count={1} />
        )}
      </div>
      <Row className="mt-2">
        <ChargerLighteningIcon fill="#23d206" />
        <GreenCaptionText200 className="ml-1">{batteryPercentDisplayText}</GreenCaptionText200>
      </Row>
      <div className="horizontal-border-line-light my-2 mb-1 w-100" />
      <Row onClick={() => history.push(`/vehicles/${vehicle?.id}`)} className="align-items-center clickable">
        <BlackCaptionText100 className="w-max-200px ml-1 mr-1">See details </BlackCaptionText100>
        <RightChevron widthValue={6} />
      </Row>
    </div>
  );
}

export default LiveVehicleLocatorInfoWindowContent;
