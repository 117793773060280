import React from "react";
import ButtonWithIcon from "./ButtonWithIcon";

type Props = {
  readonly onGotItButtonPressed: () => void;
};

function GotItButton({ onGotItButtonPressed }: Props) {
  return <ButtonWithIcon className="w-175px mt-md-0" text={"Got it"} onClick={onGotItButtonPressed} />;
}

export default GotItButton;
