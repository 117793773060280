import React from "react";

const TableCheckboxUnCheckedIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="4.5" stroke="#94A3B8" />
    </svg>
  );
};

export default TableCheckboxUnCheckedIcon;