import LoadingSkeletonForPaginatedTable from "components/loading/LoadingSkeletonForPaginatedTable";
import { useFilterUrlQuery } from "hooks/filter.hooks";
import { useUrlPagination } from "hooks/pagination.hooks";
import React, { useRef } from "react";
import DataTable, { TableProps } from "react-data-table-component";
import NoDataPlaceholder from "./placeholder/NoDataPlaceholder";
import PaginatedTableFilterPlaceholder from "./placeholder/PaginatedTableFilterPlaceholder";
import { useTableRowHtmlElementReplacementWithRef } from "../../hooks/table.hooks";

type Props = TableProps<any> & {
  isLoading: boolean;
  noDataPlaceholder?: React.JSX.Element;
  getRoutePath?: (id: string) => string | undefined
};

function PaginatedTable({ onChangeRowsPerPage, onChangePage, noDataPlaceholder, getRoutePath, ...props }: Props) {
  const { filterQuery } = useFilterUrlQuery();
  const anyFilterApplied = !!filterQuery;
  const { tablePageRowSize, tableCurrentPage, handleSetTableCurrentPage, handleSetTablePageRowSize } =
    useUrlPagination();

  const tableRef = useRef<HTMLDivElement>(null);

  useTableRowHtmlElementReplacementWithRef(getRoutePath, tableRef);


  return (
    <div ref={tableRef} className={`${props.isLoading && "mt-2"}`}>
      <DataTable
        progressComponent={<LoadingSkeletonForPaginatedTable />}
        noDataComponent={
          anyFilterApplied ? <PaginatedTableFilterPlaceholder /> : noDataPlaceholder ?? <NoDataPlaceholder />
        }
        paginationDefaultPage={tableCurrentPage}
        paginationPerPage={tablePageRowSize}
        onChangePage={handleSetTableCurrentPage}
        onChangeRowsPerPage={handleSetTablePageRowSize}
        paginationRowsPerPageOptions={[20, 50]}
        style={{ borderRadius: 10 }}
        className={`d-block rounded-xxl`}
        paginationServer
        pagination
        pointerOnHover
        highlightOnHover
        responsive
        progressPending={props.isLoading}
        {...props}
      />
    </div>
  );
}

export default PaginatedTable;
