import CancelButton from "components/button/CancelButton";
import SaveButton from "components/button/SaveButton";
import Row from "components/custom/Row";
import { BlackH5Text0 } from "components/text/Text";
import { SyntheticEvent } from "react";
import { Form, Modal, ModalBody } from "reactstrap";
import { BaseModalProps } from "./BaseModal";

type Props = BaseModalProps & {
  onSubmit: (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => void;
  submitButton?: JSX.Element;
  isLoading: boolean;
  isDisabled?: boolean;
  title?: string;
  modalSize?: "wide-sm" | "wide-xs";
  isOverflowByModal?: boolean
  modalPadding?: string
  buttonPadding?: string
};

const EditorModal = (props: Props) => {
  return (
    <Modal
      isOpen={props.isModalOpen}
      toggle={() => props.setIsModalOpen(false)}
      className={`${props.modalSize ?? "wide-xs"} modal-dialog-centered  overflow-hidden `}
    >
      <ModalBody
        id="modal-content"
        className={`w-md-100 h-md-75  h-lg-50  h-max-100 ${props.modalPadding ?? "p-3"} max-w-90 ${props.isOverflowByModal ? "overflow-auto" : "overflow-hidden"}  position-relative`}
      >
        <Form onSubmit={props.onSubmit}>
          {props.title ?? <BlackH5Text0>{props.title}</BlackH5Text0>}
          {props.children}
          <Row className={`w-100 d-flex justify-content-end align-items-center mt-4 ${props.buttonPadding ?? ""}`}>
            <CancelButton type="button" onClick={() => props.setIsModalOpen(false)} />
            <div className="ml-1">
              {props.submitButton ?? (
                <SaveButton type="submit" isDisabled={props.isDisabled} isLoading={props.isLoading} />
              )}
            </div>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default EditorModal;
