import classNames from "classnames";
import React from "react";

export type TextProps = {
  className?: string;
  page?: boolean;
  children?: React.ReactNode;
  [key: string]: any;
};

export const OverlineTitle: React.FC<TextProps> = ({ className, alt, ...props }) => {
  const classes = classNames({
    "overline-title": true,
    [`${className}`]: className,
    "overline-title-alt": alt
  });
  return (
    <React.Fragment>
      {!props.tag ? (
        <h6 className={classes}>{props.children}</h6>
      ) : (
        <props.tag className={classes}>{props.children}</props.tag>
      )}
    </React.Fragment>
  );
};

export const Des: React.FC<TextProps> = ({ className, page, children, ...props }) => {
  const classes = [`fs-16px page_description nk-block-des${className ? " " + className : ""}`];
  return <div className={classes.join(" ")}>{children}</div>;
};

// Headings

// h3
const H3FontClasses = "fs-41px font-weight-600";

export const BlackH3Text0: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${H3FontClasses} text-black-0 fix-font-spacing ${className ? " " + className : ""}`];
  return <h5 className={classes.join(" ")}>{props.children}</h5>;
};

export const BlackH3Text100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${H3FontClasses} text-black-100 fix-font-spacing  ${className ? " " + className : ""}`];
  return <h5 className={classes.join(" ")}>{props.children}</h5>;
};

export const BlackH3Text300: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${H3FontClasses} text-black-300 fix-font-spacing  ${className ? " " + className : ""}`];
  return <h5 className={classes.join(" ")}>{props.children}</h5>;
};

// h5
const H5FontClasses = "fs-26px font-weight-700";

export const BlackH5Text0: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${H5FontClasses} text-black-0 ${className ? " " + className : ""}`];
  return <h5 className={classes.join(" ")}>{props.children}</h5>;
};

export const BlackH5Text100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${H5FontClasses} text-black-100 ${className ? " " + className : ""}`];
  return <h5 className={classes.join(" ")}>{props.children}</h5>;
};

export const BlackH5Text300: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${H5FontClasses} text-black-300 ${className ? " " + className : ""}`];
  return <h5 className={classes.join(" ")}>{props.children}</h5>;
};

// h5
const NewH5FontClasses = "fs-26px font-weight-500";

export const NeutralH5TextBlack: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${NewH5FontClasses} text-neutral-black ${className ? " " + className : ""}`];
  return <h5 className={classes.join(" ")}>{props.children}</h5>;
};

export const NeutralH5Text100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${NewH5FontClasses} text-neutral-100 ${className ? " " + className : ""}`];
  return <h5 className={classes.join(" ")}>{props.children}</h5>;
};

export const NeutralH5Text300: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${NewH5FontClasses} text-neutral-300 ${className ? " " + className : ""}`];
  return <h5 className={classes.join(" ")}>{props.children}</h5>;
};


// Label - Medium
const LabelMediumFontClasses = "fs-12px font-weight-normal";


export const PositiveLabelMediumText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${LabelMediumFontClasses} text-positive-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const PositiveLabelMediumText400: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${LabelMediumFontClasses} text-positive-400 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


// overline
const OverlineFontClasses = "fs-12px font-weight-400";

export const WhiteOverlineText0: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${OverlineFontClasses} text-white-0 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const BlackOverlineText0: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${OverlineFontClasses} text-black-0 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


export const OverlineCherryText400: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${OverlineFontClasses} text-cherry-400 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const OverlineCherryText500: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${OverlineFontClasses} text-cherry-500 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


export const PurpleOverlineText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${OverlineFontClasses} text-purple-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const BlackOverlineText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${OverlineFontClasses} text-black-100 ${className ? " " + className : ""}`];
  return (
    <p className={classes.join(" ")} title={props.title}>
      {props.children}
    </p>
  );
};


export const BlackOverlineText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${OverlineFontClasses} text-black-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const BlackOverlineText300: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${OverlineFontClasses} text-black-300 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

// h6
const H6FontClasses = "fs-22px font-weight-500";

export const BlackH6Text0: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${H6FontClasses} text-black-0 ${className ? " " + className : ""}`];
  return <h6 className={classes.join(" ")}>{props.children}</h6>;
};

export const BlackH6Text100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${H6FontClasses} text-black-100 ${className ? " " + className : ""}`];
  return <h6 className={classes.join(" ")}>{props.children}</h6>;
};

export const BlackH6Text200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${H6FontClasses} text-black-200 ${className ? " " + className : ""}`];
  return <h6 className={classes.join(" ")}>{props.children}</h6>;
};

export const BlackH6Text300: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${H6FontClasses} text-black-300 ${className ? " " + className : ""}`];
  return <h6 className={classes.join(" ")}>{props.children}</h6>;
};

// Subtitles

export const WhiteSubtitleText0: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-17px font-weight-500 text-white-0 ${className ? " " + className : ""}`];
  return (
    <p data-testid="subtitle" className={classes.join(" ")}>
      {props.children}
    </p>
  );
};

export const BlackSubtitleText0: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-17px font-weight-500 text-black-0 ${className ? " " + className : ""}`];
  return (
    <p data-testid="subtitle" className={classes.join(" ")}>
      {props.children}
    </p>
  );
};

export const BlackSubtitleText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-17px font-weight-500 text-black-100 ${className ? " " + className : ""}`];
  return (
    <p data-testid="subtitle" className={classes.join(" ")}>
      {props.children}
    </p>
  );
};

export const BlackSubtitleText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-17px font-weight-500 text-black-200 ${className ? " " + className : ""}`];
  return (
    <p data-testid="subtitle" className={classes.join(" ")}>
      {props.children}
    </p>
  );
};

export const BlackSubtitleText300: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-17px font-weight-500 text-black-300 ${className ? " " + className : ""}`];
  return (
    <p data-testid="subtitle" className={classes.join(" ")}>
      {props.children}
    </p>
  );
};

export const GreenSubtitleText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-17px font-weight-500 text-green-100 ${className ? " " + className : ""}`];
  return (
    <p data-testid="subtitle" className={classes.join(" ")}>
      {props.children}
    </p>
  );
};

export const GreenSubtitleText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-17px font-weight-500 text-green-200 ${className ? " " + className : ""}`];
  return (
    <p data-testid="subtitle" className={classes.join(" ")}>
      {props.children}
    </p>
  );
};

export const PurpleSubtitleText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-17px font-weight-500 text-purple-200 ${className ? " " + className : ""}`];
  return (
    <p data-testid="subtitle" className={classes.join(" ")}>
      {props.children}
    </p>
  );
};


export const CherrySubtitleText500: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-17px font-weight-500 text-cherry-500 ${className ? " " + className : ""}`];
  return (
    <p data-testid="subtitle" className={classes.join(" ")}>
      {props.children}
    </p>
  );
};


export const BlueSubtitleText500: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-17px font-weight-500 text-blue-500 ${className ? " " + className : ""}`];
  return (
    <p data-testid="subtitle" className={classes.join(" ")}>
      {props.children}
    </p>
  );
};

// Body Texts

export const BodyMediumText: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-14px font-weight-normal  ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const BodyText: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal  ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const BlueBodyText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-blue-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const CherryBodyText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-cherry-100 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const BlackBodyText0: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-black-0 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


export const NeutralBodyTextBlack: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-neutral-black ${className ? " " + className : ""}`];
  return (
    <p className={classes.join(" ")} title={props.title}>
      {props.children}
    </p>
  );
};


const BodyMediumFontClasses = "fs-14px font-weight-normal";

export const NeutralBodyMediumText0: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodyMediumFontClasses} text-neutral-black ${className ? " " + className : ""}`];
  return (
    <p className={classes.join(" ")} title={props.title}>
      {props.children}
    </p>
  );
};


export const NeutralBodyMediumText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodyMediumFontClasses} text-neutral-100 ${className ? " " + className : ""}`];
  return (
    <p className={classes.join(" ")} title={props.title}>
      {props.children}
    </p>
  );
};


export const NeutralBodyMediumText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodyMediumFontClasses} text-neutral-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralBodyMediumText300: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodyMediumFontClasses} font-weight-normal text-neutral-300 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


export const NeutralBodyMediumText400: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodyMediumFontClasses} font-weight-normal text-neutral-400 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralBodyMediumText500: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodyMediumFontClasses} font-weight-normal text-neutral-500 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


const BodyLargeFontClasses = "fs-16px font-weight-normal";

export const NeutralBodyLargeText0: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodyLargeFontClasses} text-neutral-black ${className ? " " + className : ""}`];
  return (
    <p className={classes.join(" ")} title={props.title}>
      {props.children}
    </p>
  );
};


export const NeutralBodyLargeText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodyLargeFontClasses} text-neutral-100 ${className ? " " + className : ""}`];
  return (
    <p className={classes.join(" ")} title={props.title}>
      {props.children}
    </p>
  );
};


export const NeutralBodyLargeText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodyLargeFontClasses} text-neutral-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralBodyLargeText300: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodyLargeFontClasses} font-weight-normal text-neutral-300 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


export const NeutralBodyLargeText400: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodyLargeFontClasses} font-weight-normal text-neutral-400 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralBodyLargeText500: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodyLargeFontClasses} font-weight-normal text-neutral-500 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


export const NeutralBodyText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-neutral-100 ${className ? " " + className : ""}`];
  return (
    <p className={classes.join(" ")} title={props.title}>
      {props.children}
    </p>
  );
};

export const NeutralBodyText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-neutral-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralBodyText300: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-neutral-300 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


export const NeutralBodyText400: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-neutral-400 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralBodyText500: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-neutral-500 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const BlackBodyText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-black-100 ${className ? " " + className : ""}`];
  return (
    <p className={classes.join(" ")} title={props.title}>
      {props.children}
    </p>
  );
};

export const BlackBodyText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-black-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const BlackBodyText300: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-black-300 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const GreenBodyText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-green-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const OrangeBodyText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-orange-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const VioletBodyText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-violet-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


export const MintBodyText500: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-mint-500 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


export const BlueBodyText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-blue-100 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


// Title-Small
const TitleSmallFontClasses = "fs-14px font-weight-500";


export const NeutralTitleSmallTextBlack: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${TitleSmallFontClasses} text-neutral-black ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralTitleSmallText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${TitleSmallFontClasses} text-neutral-100 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralTitleSmallText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${TitleSmallFontClasses} text-neutral-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


export const VioletTitleSmallText500: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${TitleSmallFontClasses} text-violet-500 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


// Title-Large
const TitleLargeFontClasses = "fs-21px font-weight-500";


export const NeutralTitleLargeTextBlack: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${TitleLargeFontClasses} text-neutral-black ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralTitleLargeText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${TitleLargeFontClasses} text-neutral-100 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralTitleLargeText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${TitleLargeFontClasses} text-neutral-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralTitleLargeText300: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${TitleLargeFontClasses} text-neutral-300 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


// Title-Medium
const TitleMediumFontClasses = "fs-16px font-weight-500";


export const NeutralTitleMediumTextBlack: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${TitleMediumFontClasses} text-neutral-black ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralTitleMediumText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${TitleMediumFontClasses} text-neutral-100 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralTitleMediumText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${TitleMediumFontClasses} text-neutral-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralTitleMediumText300: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${TitleMediumFontClasses} text-neutral-300 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


// Body-Small

const BodySmallFontClasses = "fs-12px font-weight-400";


export const NeutralBodySmallTextBlack: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodySmallFontClasses} text-neutral-black ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


export const NeutralBodySmallText50: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodySmallFontClasses} text-neutral-50 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};
export const NeutralBodySmallText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodySmallFontClasses} text-neutral-100 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralBodySmallText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodySmallFontClasses} text-neutral-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeutralBodySmallText300: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${BodySmallFontClasses} text-neutral-300 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


// Body2
const Body2FontClasses = "fs-14px font-weight-normal";

export const Body2Text: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${Body2FontClasses}  ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


export const NeuralBody2TextBlack: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${Body2FontClasses} text-neutral-black ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeuralBody2Text100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${Body2FontClasses} text-neutral-100 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const NeuralBody2Text200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${Body2FontClasses} text-neutral-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};


export const BlackBody2Text0: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${Body2FontClasses} text-black-0 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const BlackBody2Text100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${Body2FontClasses} text-black-100 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const BlackBody2Text200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${Body2FontClasses} text-black-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const WhiteBody2Text0: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${Body2FontClasses} text-white-0 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const BlueBody2Text200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`${Body2FontClasses} text-blue-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

// Link Text

export const BlackLinkText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [
    `fs-16px font-weight-normal text-decoration-underline cursor-pointer  text-black-100 hover-purple-200  pointer-events-all${
      className ? " " + className : ""
    }`
  ];

  return (
    <span data-testid={props["data-testid"] ?? "link-button"} onClick={props.onClick} className={classes.join(" ")}>
      {props.children}
    </span>
  );
};

export const BlackLinkText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [
    `fs-16px font-weight-normal text-decoration-underline cursor-pointer  text-black-200  pointer-events-all${
      className ? " " + className : ""
    }`
  ];

  return (
    <span data-testid={props["data-testid"] ?? "link-button"} onClick={props.onClick} className={classes.join(" ")}>
      {props.children}
    </span>
  );
};

export const BlackLinkText300: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [
    `fs-16px font-weight-normal text-decoration-underline cursor-pointer  text-black-300 ${
      className ? " " + className : ""
    }`
  ];
  return (
    <span data-testid={props["data-testid"] ?? "link-button"} onClick={props.onClick} className={classes.join(" ")}>
      {props.children}
    </span>
  );
};

export const LinkTextPrimary: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal link-text ${className ? " " + className : ""}`];
  return (
    <span data-testid={props["data-testid"] ?? "link-button"} onClick={props.onClick} className={classes.join(" ")}>
      {props.children}
    </span>
  );
};

// Caption Text
export const BlackCaptionText0: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-14px font-weight-normal text-black-0 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const BlackCaptionText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-14px font-weight-normal text-black-100 pointer-events-all ${className ? " " + className : ""}`];
  return (
    <p className={classes.join(" ")} onClick={props?.onClick}>
      {props.children}
    </p>
  );
};

export const BlackCaptionText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-14px font-weight-normal text-black-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const BlackCaptionText300: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-14px font-weight-normal text-black-300 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const BlueCaptionText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-14px font-weight-normal text-blue-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const PurpleCaptionText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-14px font-weight-normal text-purple-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const WhiteCaptionText0: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-14px font-weight-normal text-white ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const GreenCaptionText0: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-14px font-weight-normal text-green-0 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const GreenCaptionText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-14px font-weight-normal text-green-100 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const GreenCaptionText200: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-14px font-weight-normal text-green-200 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};

export const CherryCaptionText100: React.FC<TextProps> = ({ className, page, ...props }) => {
  const classes = [`fs-14px font-weight-normal text-cherry-100 ${className ? " " + className : ""}`];
  return <p className={classes.join(" ")}>{props.children}</p>;
};