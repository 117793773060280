import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { Icon } from "../Component";
import ButtonWithIcon from "./ButtonWithIcon";

export default function AddVehicleButton() {
  /**
   * Hooks
   */
  const history = useHistory();

  /**
   * Handlers
   */
  const handleAddVehicleClicked = useCallback(() => {
    history.push("/add-vehicle");
  }, [history]);

  /**
   * Render
   */
  return (
    <ButtonWithIcon
      className="mt-md-0"
      leftIcon={<Icon name="plus" />}
      text={"Add Vehicle"}
      onClick={handleAddVehicleClicked}
    />
  );
}
