import React, { useCallback, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { DashboardInsightFilter } from "types/dashboard.types";
import FilterSelectorCardItem from "./FilterSelectorCardItem";
import { RSelect } from "components/Component";

import { ClickAwayListener, Popper } from "@mui/material";
import FilterMenuCard from "./FilterMenuCard";
import Row from "components/custom/Row";
import { useFilterUrlQuery } from "hooks/filter.hooks";
import { ChargingFilters } from "types/charging-sessions.types";
import { Period } from "types/filter.types";
import { getLocalFormattedDateAsDateShortForFilter } from "utils/date.utils";
import { DateTime } from "luxon";

type Props = {};

const customRangeValue = "customRange";

const defaultOption = { label: "All time", value: Period.AllTime };
const filterValueOptions = [
  defaultOption,
  { label: "Last Week", value: Period.LastWeek },
  { label: "This Week", value: Period.ThisWeek },
  { label: "Last Month", value: Period.LastMonth },
  { label: "This Month", value: Period.ThisMonth },
  { label: "Last Quarter", value: Period.LastQuarter },
  { label: "This Quarter", value: Period.ThisQuarter },
  { label: "Last Year", value: Period.LastYear },
  { label: "This Year", value: Period.ThisYear },
  { label: "Custom range", value: customRangeValue },
];

const previousDate = new Date();
previousDate.setDate(previousDate.getDate() - 1);

function FilterDateSelector({}: Props) {
  const { filterQuery, setFilterQuery } = useFilterUrlQuery<ChargingFilters>();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState<null | Date>(previousDate);
  const [endDate, setEndDate] = useState<null | Date>(new Date());

  const isStartDateValid = !startDate || DateTime.fromJSDate(startDate).isValid;
  const isEndDateValid = !endDate || DateTime.fromJSDate(endDate).isValid;

  const isSelectedRangeOnQuery = filterQuery?.createdAt?.$gte || filterQuery?.createdAt?.$lt;

  const selectedRange = isSelectedRangeOnQuery
    ? {
        label: `${getLocalFormattedDateAsDateShortForFilter(filterQuery?.createdAt?.$gte)} - 
        ${getLocalFormattedDateAsDateShortForFilter(filterQuery?.createdAt?.$lt)}
        `,
      }
    : undefined;
  const selectedPeriodOnQuery =
    filterValueOptions.find((options) => options.value === filterQuery?.createdAt?.$within) ?? defaultOption;

  const handleSetDatePeriodOnQuery = useCallback(
    (period: Period) => {
      setFilterQuery((q) => {
        return {
          createdAt: {
            $within: period,
          },
        };
      });
    },
    [setFilterQuery]
  );

  const handleClearButtonPressed = useCallback(() => {
    setFilterQuery((q) => {
      return {
        createdAt: {
          $gte: undefined,
          $lt: undefined,
        },
      };
    });
    setIsDatePickerOpen(false);
  }, [setFilterQuery]);

  const handleApplyButtonPressed = useCallback(() => {
    setFilterQuery((q) => {
      return {
        createdAt: {
          $gte: startDate,
          $lt: endDate,
        },
      };
    });
    setIsDatePickerOpen(false);
  }, [endDate, setFilterQuery, startDate]);

  const handlePeriodChanged = useCallback(
    ({ value, label }) => {
      if (label === "Custom range") {
        setIsDatePickerOpen(true);
        return;
      }
      handleSetDatePeriodOnQuery(value);
    },
    [handleSetDatePeriodOnQuery]
  );

  return (
    <>
      <div className="filter-selector w-175px position-relative">
        <RSelect
          className="pl-md-1"
          isSearchable={false}
          value={isSelectedRangeOnQuery ? selectedRange : selectedPeriodOnQuery}
          options={filterValueOptions}
          onChange={handlePeriodChanged}
          components={{
            Option: ({ data, label, setValue }) => (
              <FilterSelectorCardItem
                isSeparatedFromTop={data.value === customRangeValue}
                isSeparatedFromBottom={data.value === Period.AllTime}
                isSelected={
                  isSelectedRangeOnQuery ? data.value === customRangeValue : selectedPeriodOnQuery.label === label
                }
                text={label}
                onClick={(e) => setValue({ value: data.value, label })}
                key={data.value}
              />
            ),
          }}
          //value={selectedSortingOption}
          placeholder={"Select date"}
        />

        {isDatePickerOpen && (
          <ClickAwayListener onClickAway={() => setIsDatePickerOpen(false)}>
            <div className="filter-date-picker-popup">
              <FilterMenuCard
                isApplyButtonDisabled={!isStartDateValid || !isEndDateValid}
                onApplyPressed={handleApplyButtonPressed}
                onClearPressed={handleClearButtonPressed}
                children={
                  <>
                    <Row className="flex-nowrap align-items-center">
                      <div className="pr-1">
                        <ReactDatePicker
                          className="form-control"
                          open={false}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                        />
                      </div>
                      <div className="pl-1">
                        <ReactDatePicker
                          selected={endDate}
                          className="form-control"
                          open={false}
                          onChange={(date) => setEndDate(date)}
                        />
                      </div>
                    </Row>

                    <ReactDatePicker
                      inline
                      className="form-control date-picker w-100"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setStartDate(update[0]);
                        setEndDate(update[1]);
                      }}
                      isClearable={true}
                    />
                  </>
                }
              />
            </div>
          </ClickAwayListener>
        )}
      </div>
    </>
  );
}

export default FilterDateSelector;
