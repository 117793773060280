import { RSelect } from "components/Component";
import FilterSelectorCardItem from "components/filter/FilterSelectorCardItem";
import FilterSortingSelector from "components/filter/FilterSortingSelector";
import { useSortingUrlQuery } from "hooks/filter.hooks";
import React, { useCallback } from "react";
import { SortDirection, SortableFields } from "types/filter.types";
import { getSortingOption } from "utils/filter.utils";

const sortOptions = [
  getSortingOption(SortableFields.Date, SortDirection.DESC),
  getSortingOption(SortableFields.Date, SortDirection.ASC),
];

function ChargingSortingSelector() {
  return <FilterSortingSelector sortOptions={sortOptions} />;
}

export default ChargingSortingSelector;
