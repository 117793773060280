import React from "react";

type Props = {};

function DriversMenuIconOutlined({}: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0038 3.40724C12.983 3.40724 14.5917 5.01593 14.5917 6.99506C14.5917 8.97419 12.983 10.5864 11.0038 10.5864C9.02472 10.5864 7.41602 8.97771 7.41602 6.99506C7.41602 5.0124 9.02472 3.40724 11.0038 3.40724ZM11.0038 1.99609C8.24154 1.99609 6.00488 4.23628 6.00488 6.99506C6.00488 9.75384 8.24506 11.9976 11.0038 11.9976C13.7626 11.9976 16.0028 9.75737 16.0028 6.99506C16.0028 4.23275 13.7626 1.99609 11.0038 1.99609Z"
        fill="#6F7981"
      />
      <path
        d="M15.7802 13.9519C15.8437 14.1036 15.7731 14.28 15.6214 14.3435C15.2898 14.4846 14.99 14.6857 14.7395 14.9397C14.489 15.1902 14.2844 15.4901 14.1468 15.8217C14.0057 16.1533 13.9281 16.5202 13.9281 16.9012C13.9281 17.2822 14.0057 17.6491 14.1468 17.9807C14.2879 18.3124 14.489 18.6122 14.743 18.8662C14.9935 19.1167 15.2934 19.3213 15.625 19.4589C15.7767 19.5224 15.8507 19.6988 15.7837 19.8505L15.0358 21.625C14.9723 21.7767 14.7959 21.8473 14.6442 21.7838C14.0092 21.5156 13.4412 21.1241 12.9614 20.6478C12.4816 20.168 12.0936 19.5965 11.8255 18.9615C11.5573 18.3265 11.4092 17.6279 11.4092 16.8977C11.4092 16.1674 11.5573 15.4689 11.8255 14.8339C12.0936 14.1989 12.4816 13.6274 12.9614 13.1476C13.4412 12.6678 14.0127 12.2797 14.6477 12.0116C14.7994 11.9481 14.9758 12.0187 15.0393 12.1704L15.7872 13.9449L15.7802 13.9519Z"
        fill="#6F7981"
      />
      <path
        d="M17.6221 19.8536C17.5586 19.7019 17.6291 19.5255 17.7808 19.462C18.1125 19.3209 18.4123 19.1198 18.6628 18.8693C18.9133 18.6188 19.1179 18.319 19.2555 17.9873C19.3966 17.6557 19.4742 17.2888 19.4742 16.9078C19.4742 16.5268 19.3966 16.1599 19.2555 15.8283C19.1144 15.4967 18.9133 15.1968 18.6593 14.9463C18.4088 14.6959 18.1089 14.4912 17.7773 14.3501C17.6256 14.2866 17.5515 14.1102 17.6186 13.9585L18.3665 12.184C18.43 12.0323 18.6064 11.9618 18.7581 12.0253C19.3931 12.2934 19.9611 12.6814 20.4408 13.1577C20.9206 13.6375 21.3087 14.209 21.5768 14.844C21.8449 15.479 21.9931 16.1776 21.9931 16.9078C21.9931 17.6381 21.8449 18.3366 21.5768 18.9716C21.3087 19.6066 20.9206 20.1746 20.4408 20.6544C19.9611 21.1307 19.3895 21.5223 18.7545 21.7904C18.6028 21.8539 18.4264 21.7833 18.3629 21.6316L17.615 19.8571L17.6221 19.8536Z"
        fill="#6F7981"
      />
      <path
        d="M8.37191 15.4092C8.3331 15.6138 8.30134 15.822 8.28018 16.0301V16.0548C8.24843 16.337 8.23431 16.5945 8.23431 16.8521C8.23431 16.8627 8.23431 16.8697 8.23431 16.8803C8.23431 16.8874 8.23431 16.8979 8.23431 16.905C8.23431 16.9473 8.23432 16.9897 8.23784 17.032C8.23784 17.1802 8.24843 17.3319 8.25901 17.4765C8.3331 18.5666 8.61178 19.6179 9.08099 20.5881H3.40821V20.2494C3.40821 17.5823 5.57783 15.4092 8.24842 15.4092H8.36837M9.54313 13.998H8.24842C4.79818 13.998 1.99707 16.7956 1.99707 20.2494V21.4983C1.99707 21.7734 2.21932 21.9992 2.49802 21.9992H10.7109C11.1307 21.9992 11.3565 21.5194 11.0989 21.1843C10.2805 20.1153 9.76187 18.803 9.66662 17.3707C9.65604 17.2401 9.64898 17.1096 9.64546 16.9791C9.64546 16.9544 9.64192 16.9262 9.64192 16.9015C9.64192 16.8944 9.64192 16.8874 9.64192 16.8838C9.64192 16.8732 9.64192 16.8627 9.64192 16.8521C9.64192 16.6298 9.65603 16.4111 9.67719 16.1959C9.67719 16.1888 9.67719 16.1818 9.67719 16.1747C9.69836 15.9842 9.7266 15.7937 9.76188 15.6103C9.82185 15.2857 9.90298 14.9717 10.0088 14.6648C10.1182 14.3367 9.88183 14.0016 9.53962 14.0016L9.54313 13.998Z"
        fill="#6F7981"
      />
    </svg>
  );
}

export default DriversMenuIconOutlined;
