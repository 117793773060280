import { WhiteBody2Text0 } from "components/text/Text";
import React from "react";

type Props = {
  readonly text: string | number;
};

function BlackCircleBadge({ text }: Props) {
  return (
    <div className="help-fill rounded-circle center bg-dark w-20px h-20px text-white">
      <WhiteBody2Text0>{text}</WhiteBody2Text0>
    </div>
  );
}

export default BlackCircleBadge;
