import FilterMenu from "components/filter/FilterMenu";
import React from "react";

import FilterDateSelector from "components/filter/FilterDateSelector";
import FilterDriverStatusSelector from "./FilterDriverStatusSelector";
import DriverSortingSelector from "./DriverSortingSelector";
import FilterDriverPayerStatusSelector from "./FilterDriverPayerStatus";

type Props = {};

function DriversTableFilterMenu(props: Props) {
  return (
    <FilterMenu
      selectors={[<FilterDriverStatusSelector />, <FilterDriverPayerStatusSelector />]}
      sortingSelector={<DriverSortingSelector />}
    />
  );
}

export default DriversTableFilterMenu;
