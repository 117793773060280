export function getCurrentUrl() {
  let currentUrl;
  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }

  return currentUrl;
}
