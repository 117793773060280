import React from "react";
import { SvgProps } from "types/svg.types";

const ChargerIconAC = ({ fill, width, height, className }: SvgProps) => {
  const fillValue = fill ?? "#680031";
  const widthValue = width ?? 16;
  const heightValue = height ?? 18;

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4 14.3747L8.30285 21.1901C8.2612 21.3251 8.26648 21.4701 8.31784 21.6017C8.3692 21.7332 8.4636 21.8435 8.58566 21.9146C8.70772 21.9856 8.85024 22.0132 8.98999 21.9928L10.4 14.3747ZM10.4 14.3747H10.5538V13.8747M10.4 14.3747L10.5538 13.8747M10.5538 13.8747L10.076 13.7276L10.0307 13.8747M10.5538 13.8747H10.0307M10.0307 13.8747H6.40057H10.0307ZM16.3816 2.50181C16.4041 2.49852 16.4269 2.50142 16.4476 2.51005L16.4624 2.5186C16.4868 2.53277 16.5058 2.55482 16.5161 2.58115C16.5264 2.60747 16.5274 2.6365 16.5191 2.66352L16.519 2.66388L14.268 9.97805L14.0689 10.6251H14.7459H18.8992H18.8994C18.9238 10.6251 18.9476 10.6322 18.968 10.6456C18.9884 10.659 19.0044 10.6781 19.0141 10.7005C19.0238 10.7229 19.0267 10.7477 19.0224 10.7717C19.0182 10.7957 19.007 10.818 18.9903 10.8357L18.99 10.8361L8.99113 21.4599L8.99099 21.46C8.97165 21.4806 8.94592 21.494 8.91797 21.4981C8.89004 21.5021 8.86155 21.4966 8.83715 21.4824C8.81275 21.4682 8.79388 21.4462 8.78361 21.4199C8.77334 21.3936 8.77229 21.3646 8.78061 21.3376L8.78074 21.3372L11.0317 14.0217L11.2308 13.3747H10.5538H6.40057H6.40037C6.37597 13.3747 6.3521 13.3676 6.33171 13.3542C6.31132 13.3408 6.2953 13.3217 6.28563 13.2993C6.27595 13.2769 6.27305 13.2521 6.27728 13.2281L5.78485 13.1415L6.27728 13.2281C6.28151 13.2041 6.29268 13.1818 6.30942 13.1641L6.30972 13.1637L16.3086 2.53995L16.3087 2.53983C16.328 2.51929 16.3537 2.50589 16.3816 2.50181Z"
        fill="#0C0C0C"
        stroke="white"
      />
    </svg>
  );
};
export default ChargerIconAC;

/**
 *   width={`${widthValue}`}
      height={`${heightValue}`}
      viewBox={`0 0 ${widthValue} ${heightValue}
 */
