import React, { useMemo } from "react";

import Badge from "./Badge";
import { ChargingState } from "../../types/charging-sessions.types";

export default function ChargingStateBadge({ state }: { state: ChargingState }) {
  const badgeContent = useMemo(() => {
    const commonStyles = "px-3 rounded-xxxl center";
    switch (state) {
      case ChargingState.INITIAL:
        return {
          text: "Initial",
          textStyle: "text-blue-200",
          containerStyle: `bg-blue-1000 ${commonStyles}`
        };

      case ChargingState.START_WAITING:
        return {
          text: "Started",
          textStyle: "text-blue-200",
          containerStyle: `bg-blue-1000 ${commonStyles}`
        };

      case ChargingState.ACTIVE:
        return {
          text: "Charging",
          textStyle: "text-blue-200",
          containerStyle: `bg-blue-1000 ${commonStyles}`
        };

      case ChargingState.STOP_WAITING:
        return {
          text: "Stopping",
          textStyle: "text-yellow-200",
          containerStyle: `bg-yellow-700 ${commonStyles}`
        };

      case ChargingState.COMPLETED:
        return {
          text: "Done",
          textStyle: "text-positive-200",
          containerStyle: `bg-positive-950 ${commonStyles}`
        };

      case ChargingState.FAILED:
        return {
          text: "Failed",
          textStyle: "text-cherry-200",
          containerStyle: `bg-cherry-1000 ${commonStyles}`
        };


      default:
        return {
          text: "Unknown state",
          textStyle: "text-neutral-200",
          containerStyle: `bg-neutral-900 ${commonStyles}`
        };
    }
  }, [state]);
  return <Badge {...badgeContent} />;
}
