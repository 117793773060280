import React, { useEffect, useState } from "react";

import { NavLink, Link } from "react-router-dom";
import Icon from "../../components/icon/Icon";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import MenuItemIcon from "./MenuItemIcon";
import { getCurrentUrl } from "./menu.utilities";
import { useAppMenu } from "../../hooks/menu.hooks";
import { useMixpanel } from "../../hooks/mixpanel.hooks";
import { MixPanelEvents } from "../../types/mixpanel.types";

const MenuHeading = ({ heading }) => {
  const { t } = useTranslation("common");
  return (
    <li className="nk-menu-heading">
      <h6 className="overline-title text-primary-alt">{t(`main_menu.${heading}`)}</h6>
    </li>
  );
};

const MenuItem = ({
  isAvailable,
  isIconJsx,
  IconFilled,
  IconOutlined,
  NotificationBadge,
  link,
  text,
  sub,
  subPanel,
  panel,
  newTab,
  mobileView,
  sidebarToggle,
  badge,
  ...props
}) => {
  const { t } = useTranslation("common");
  const currentUrl = getCurrentUrl();

  const { trackWithMixPanel } = useMixpanel();

  const toggleActionSidebar = (e) => {
    if (!sub && !newTab && mobileView) {
      sidebarToggle(e);
    }
  };

  const menuHeight = (el) => {
    var totalHeight = [];

    return [50, 50, 50];
  };

  const makeParentActive = (el, childHeight) => {
    let element = el.parentElement.parentElement.parentElement;
    let wrap = el.parentElement.parentElement;
    if (element.classList[0] === "nk-menu-item") {
      element.classList.add("active");
      const subMenuHeight = menuHeight(el.parentNode.children);
      wrap.style.height = subMenuHeight + childHeight - 50 + "px";
      makeParentActive(element);
    }
  };

  useEffect(() => {
    var element = document.getElementsByClassName("nk-menu-item active current-page");
    var arrayElement = [...element];

    arrayElement.forEach((dom) => {
      if (dom.parentElement.parentElement.parentElement.classList[0] === "nk-menu-item") {
        dom.parentElement.parentElement.parentElement.classList.add("active");
        const subMenuHeight = menuHeight(dom.parentNode.children);
        dom.parentElement.parentElement.style.height = subMenuHeight + "px";
        makeParentActive(dom.parentElement.parentElement.parentElement, subMenuHeight);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const menuToggle = (e) => {
    e.preventDefault();
    var self = e.target.closest(".nk-menu-toggle");
    var parent = self.parentElement;
    var subMenu = self.nextSibling;
    var subMenuItem = subMenu.childNodes;
    var parentSiblings = parent.parentElement.childNodes;
    var parentMenu = parent.closest(".nk-menu-wrap");
    //For Sub Menu Height
    var subMenuHeight = menuHeight(subMenuItem);
    // Get parent elements
    const getParents = (el, parentSelector) => {
      parentSelector = document.querySelector(".nk-menu");
      if (parentSelector === undefined) {
        parentSelector = document;
      }
      var parents = [];
      var p = el.parentNode;
      while (p !== parentSelector) {
        var o = p;
        parents.push(o);
        p = o.parentNode;
      }
      parents.push(parentSelector);
      return parents;
    };
    var parentMenus = getParents(self);
    if (!parent.classList.contains("active")) {
      // For Parent Siblings
      for (var j = 0; j < parentSiblings.length; j++) {
        parentSiblings[j].classList.remove("active");
        if (typeof parentSiblings[j].childNodes[1] !== "undefined") {
          parentSiblings[j].childNodes[1].style.height = 0;
        }
      }
      if (parentMenu !== null) {
        if (!parentMenu.classList.contains("sub-opened")) {
          parentMenu.classList.add("sub-opened");

          for (var l = 0; l < parentMenus.length; l++) {
            if (typeof parentMenus !== "undefined") {
              if (parentMenus[l].classList.contains("nk-menu-wrap")) {
                parentMenus[l].style.height = subMenuHeight + parentMenus[l].clientHeight + "px";
              }
            }
          }
        }
      }
      // For Current Element
      parent.classList.add("active");
      subMenu.style.height = subMenuHeight + "px";
    } else {
      parent.classList.remove("active");
      if (parentMenu !== null) {
        parentMenu.classList.remove("sub-opened");
        for (var k = 0; k < parentMenus.length; k++) {
          if (typeof parentMenus !== "undefined") {
            if (parentMenus[k].classList.contains("nk-menu-wrap")) {
              parentMenus[k].style.height = parentMenus[k].clientHeight - subMenuHeight + "px";
            }
          }
        }
      }
      subMenu.style.height = 0;
    }
  };

  const menuItemClass = classNames({
    "nk-menu-item": true,
    "has-sub": sub,
    "active current-page": currentUrl === process.env.PUBLIC_URL + link,
  });

  const menuLinkClass = classNames({
    "current-page-link": currentUrl === process.env.PUBLIC_URL + link,
  });

  const isCurrentMenuItemActive = currentUrl === process.env.PUBLIC_URL + link;
  const icon = isCurrentMenuItemActive ? IconFilled : IconOutlined;

  if (!isAvailable) {
    return <></>;
  }

  const handleMenuItemClicked = () => {
    trackWithMixPanel(MixPanelEvents.CLICK_PAGENAME, link);

    if (newTab && sub) {
      menuToggle();
    }
  };

  return (
    <li className={menuItemClass} onClick={(e) => toggleActionSidebar(e)}>
      {newTab ? (
        <Link
          to={`${process.env.PUBLIC_URL + link}`}
          onClick={handleMenuItemClicked}
          target="_blank"
          rel="noopener noreferrer"
          className={`${menuLinkClass} nk-menu-link align-center"`}
        >
          <MenuItemIcon JsxIcon={icon} isActive={isCurrentMenuItemActive} />
          <span className="nk-menu-text">{t(`main_menu.${text}`)}</span>
          {NotificationBadge && <NotificationBadge />}
        </Link>
      ) : (
        <NavLink
          to={`${process.env.PUBLIC_URL + link}`}
          className={`${menuLinkClass} nk-menu-link${sub ? " nk-menu-toggle" : ""}`}
          onClick={handleMenuItemClicked}
        >
          <MenuItemIcon JsxIcon={icon} isActive={isCurrentMenuItemActive} />
          <span className="nk-menu-text">{t(`main_menu.${text}`)}</span>
          {badge && <span className="nk-menu-badge">{badge}</span>}
          {NotificationBadge && <NotificationBadge />}
        </NavLink>
      )}
      {sub ? (
        <div className="nk-menu-wrap">
          <MenuSub sub={sub} sidebarToggle={sidebarToggle} mobileView={mobileView} />
        </div>
      ) : null}
    </li>
  );
};

const PanelItem = ({
  isAvailable,
  isIconJsx,
  IconFilled,
  IconOutlined,
  link,
  text,
  subPanel,
  index,
  data,
  setMenuData,
  ...props
}) => {
  const { menu } = useAppMenu();
  const { t } = useTranslation("common");
  const menuItemClass = classNames({
    "nk-menu-item": true,
  });

  const { trackWithMixPanel } = useMixpanel();

  const currentUrl = getCurrentUrl();
  const icon = isCurrentMenuItemActive ? IconFilled : IconOutlined;
  const isCurrentMenuItemActive = currentUrl === process.env.PUBLIC_URL + link;

  if (!isAvailable) {
    return <></>;
  }

  const handleMenuItemClicked = () => {
    trackWithMixPanel(MixPanelEvents.CLICK_PAGENAME, link);
    setMenuData([menu[index]]);
  };

  if (data === menu) {
    return (
      <li className={menuItemClass}>
        <Link to={`${process.env.PUBLIC_URL}${link}`} className="nk-menu-link" onClick={handleMenuItemClicked}>
          <MenuItemIcon JsxIcon={icon} isActive={isCurrentMenuItemActive} />
          <span className="nk-menu-text">{t(`main_menu.${text}`)}</span>
          <span className="nk-menu-badge">HOT</span>
        </Link>
      </li>
    );
  } else {
    return (
      <React.Fragment>
        {subPanel.map((item) => {
          return <MenuItem {...item} />;
        })}
        <MenuHeading heading="Return to" />
        <li className={menuItemClass}>
          <Link to={`${process.env.PUBLIC_URL}/`} className="nk-menu-link" onClick={handleMenuItemClicked}>
            <span className="nk-menu-icon">
              <Icon name="dashlite-alt" />
            </span>
            <span className="nk-menu-text">Main Dashboard</span>
          </Link>
        </li>
        <li className={menuItemClass}>
          <Link to={`${process.env.PUBLIC_URL}/`} className="nk-menu-link" onClick={handleMenuItemClicked}>
            <span className="nk-menu-icon">
              <Icon name="layers-fill" />
            </span>
            <span className="nk-menu-text">All Components</span>
          </Link>
        </li>
      </React.Fragment>
    );
  }
};

const MenuSub = ({ icon, link, text, sub, sidebarToggle, mobileView, ...props }) => {
  const { t } = useTranslation("common");
  return (
    <ul className="nk-menu-sub" style={props.style}>
      {sub.map((item) => {
        return <MenuItem {...item} key={t(`main_menu.${item.text}`)} />;
      })}
    </ul>
  );
};

const Menu = ({ sidebarToggle, mobileView , sidebarData}) => {
  const { menu } = useAppMenu(sidebarData);

  const [data, setMenuData] = useState(menu);

  useEffect(() => {
    setMenuData(menu);
  }, [menu]);

  useEffect(() => {
    data.forEach((item, index) => {
      if (item.panel) {
        let found = item.subPanel.find((sPanel) => process.env.PUBLIC_URL + sPanel.link === window.location.pathname);
        if (found) {
          setMenuData([menu[index]]);
        }
      }
    });
  }, [data, menu]);
  const { t } = useTranslation("common");

  return (
    <ul className="nk-menu">
      {data.map((item, index) => {
        return item.heading ? (
          <MenuHeading heading={t(item.heading)} key={t(item.heading)} />
        ) : item.panel ? (
          <PanelItem
            {...item}
            key={t(item.text)}
            index={index}
            data={data}
            setMenuData={setMenuData}
            sidebarToggle={sidebarToggle}
          />
        ) : (
          <MenuItem
            {...item}
            key={t(item.text)}
            text={t(item.text)}
            sidebarToggle={sidebarToggle}
            mobileView={mobileView}
          />
        );
      })}
    </ul>
  );
};

export default Menu;
