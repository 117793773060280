import { ChargerNetworkDoughnutChart } from "components/charts/Chart";
import { useDashboardInsights } from "hooks/data/dashboard-data-accessor.hooks";
import { useMemo } from "react";
import { getProcessedChargingNetworkGraphData } from "../../../utils/dashboard.utils";
import GraphPlaceholder from "../dashboard-placeholders/GraphPlaceholder";

export default function DashboardChargerNetworkGraph() {
  const { insights } = useDashboardInsights();
  const chargingNetworksData = insights?.countByChargingNetwork;

  const areThereAnyData = chargingNetworksData && chargingNetworksData.length > 0;

  const processedGraphData = useMemo(() => {
    if (!areThereAnyData) {
      return undefined;
    }
    return getProcessedChargingNetworkGraphData(chargingNetworksData);
  }, [areThereAnyData, chargingNetworksData]);

  if (!processedGraphData) {
    return <GraphPlaceholder />;
  }

  const { data, labels, total: totalChargingNetworks, backgroundColors } = processedGraphData;

  const doughnutData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: backgroundColors,
      },
    ],
  };

  return <ChargerNetworkDoughnutChart data={doughnutData} totalChargers={totalChargingNetworks} />;
}
