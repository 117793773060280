import { GoogleMap, Marker } from "@react-google-maps/api";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useGoogleApiLoader } from "../../hooks/google-maps.hooks";

function ChargerLocationMap({ charger }) {
  const { isLoaded } = useGoogleApiLoader();
  console.log("charger", charger);
  const [mapDimensions, setMapDimensions] = useState({ width: "500px", height: "260px" });

  const [map, setMap] = React.useState(null);

  const center = useMemo(() => {
    return {
      lat: charger.latitude,
      lng: charger.longitude
    };
  }, [charger.latitude, charger.longitude]);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const resizeMap = useCallback(() => {
    const parentDiv = document.getElementById("map-container");
    setMapDimensions({
      width: parentDiv.clientWidth + "px",
      height: mapDimensions.height
    });

    if (map) {
      window.google.maps.event.trigger(map, "resize");
      map.setCenter(center);
    }
  }, [center, map, mapDimensions.height]);

  useEffect(() => {
    window.addEventListener("resize", resizeMap);

    return () => {
      window.removeEventListener("resize", resizeMap);
    };
  }, [resizeMap]);

  return (
    <div id="map-container" style={{ width: "100%", height: "100%" }}>
      {isLoaded ? (
        <GoogleMap mapContainerStyle={mapDimensions} center={center} zoom={15} onLoad={onLoad} onUnmount={onUnmount}>
          <Marker
            position={center}
            icon={{
              // eslint-disable-next-line @typescript-eslint/no-var-requires
              url: require("../../assets/images/vehicleCharger.svg").default,
              scale: 5
            }}
          />
        </GoogleMap>
      ) : (
        <></>
      )}
    </div>
  );
}

export default React.memo(ChargerLocationMap);
