import React, { useMemo, useState } from "react";
import DynamicUserAvatarList from "../user/DynamicUserAvatarList";
import VehicleAvatar from "../user/VehicleAvatar";

import { useHistory } from "react-router";
import AssignDriverLinkButton from "../button/AssignDriverLinkButton";

import AddVehicleButton from "components/button/AddVehicleButton";
import Row from "components/custom/Row";
import FilterSearchInput from "components/filter/FilterSearchInput";
import InfoIcon from "components/icon/icons/InfoIcon";
import XIcon from "components/icon/icons/XIcon";
import PaginatedTable from "components/table/PaginatedTable";
import { BlackBodyText100 } from "components/text/Text";
import { useVehiclesAsync } from "hooks/data/vehicle-data-accessor.hooks";
import { useVehicleAdditionStatus } from "hooks/vehicle.hooks";
import { TableColumn } from "react-data-table-component";
import { Card } from "reactstrap";
import { Vehicle } from "types/vehicle.types";
import { getLocalFormattedDateForTables } from "utils/date.utils";
import { getVehicleConnectionStatus, getVehicleModelYear, getVehicleRowForCsv } from "../../utils/vehicle.utils";
import ConnectionStatusBadge from "../badge/ConnectionStatusBadge";
import RightChevron from "../icon/icons/RightChevron";
import VehiclesTableFilterMenu from "./filter/VehiclesTableFilterMenu";
import PaginatedTableCsvLinkButton from "../button/PaginatedTableCsvLinkButton";
import { getVehiclesAsync } from "../../services/vehicle.services";

type VehiclesTableColumn = TableColumn<Vehicle>[];
export default function VehiclesTable() {
  const { isVehiclesLoading, vehicles, meta } = useVehiclesAsync();
  const history = useHistory();

  const [isVehicleAdditionStatusToggled, setIsVehicleAdditionStatusToggled] = useState(false);

  const { areVehiclesBeingAdded } = useVehicleAdditionStatus();

  const columns = useMemo(
    () => [
      {
        grow: 1.5,
        name: "Vehicle Name",
        selector: (row) => <VehicleAvatar isSmall={true} vehicleName={row.name} imgSource={row.imagesBaseUrl} />
      },
      {
        grow: 1,
        name: "Status",
        selector: (row) => {
          return <ConnectionStatusBadge connectionStatus={getVehicleConnectionStatus(row).connectionStatus} />;
        }
      },
      {
        grow: 0.8,
        name: "Make",
        selector: (row) => {
          return row?.carBrand;
        }
      },
      {
        grow: 0.9,
        name: "Model",
        selector: (row) => getVehicleModelYear(row)
      },

      {
        style: {
          pointerEvents: "all",
          zIndex: 99
        },
        grow: 1.2,
        name: "Active Assignment",
        selector: (row) =>
          row.users?.length > 0 ? (
            <DynamicUserAvatarList users={row.users} />
          ) : (
            <AssignDriverLinkButton vehicleId={row.id} />
          )
      },
      {
        width: "200px",
        name: "Added",
        selector: (row) => <>{getLocalFormattedDateForTables(row?.createdAt)} </>
      },
      {
        center: true,
        right: true,
        width: "70px",
        name: "",
        cell: (row) => (
          <Row className="w-100 justify-end pr-2" onClick={() => history.push(`/vehicles/${row?.id}`)}>
            <RightChevron widthValue={8} heightValue={10} style={{ pointerEvents: "none" }} />
          </Row>
        )
      }
    ],
    [history]
  ) as VehiclesTableColumn;

  return (
    <>


      <Row className="justify-between my-2">
        <FilterSearchInput placeholder="Search vehicle" />


        <Row>
          <PaginatedTableCsvLinkButton
            fileName="vehicles"
            csvRowFormatter={getVehicleRowForCsv}
            totalPages={meta?.pagination.totalPages ?? 0}
            rowSizePerPage={meta?.pagination.size ?? 20}
            fetchListAsync={getVehiclesAsync}
          />

          <div className={"ml-4"}>
            <AddVehicleButton />
          </div>
        </Row>
      </Row>

      <VehiclesTableFilterMenu />

      <PaginatedTable
        columns={columns}
        data={vehicles}
        paginationTotalRows={meta?.pagination.totalElements}
        isLoading={isVehiclesLoading}
        getRoutePath={id => `/vehicles/${id}`}
      />
    </>
  );
}
