import FilterMenu from "components/filter/FilterMenu";
import React from "react";
import FilterPaymentStateSelector from "../charging-sessions-components/filter/ChargingPaymentStateSelector";
import ReimbursementFilterPaymentStateSelector from "./ReimbursementFilterPaymentStateSelector";

type Props = {};

function ReimbursementsTableFilterMenu(props: Props) {
  return (
    <FilterMenu
      selectors={[<ReimbursementFilterPaymentStateSelector key={3} />
      ]}
      sortingSelector={<></>}
    />
  );
}

export default ReimbursementsTableFilterMenu;
