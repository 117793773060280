import LoadingSkeleton from "../../loading/LoadingSkeleton";
import Row from "../../custom/Row";
import InfoIcon from "../../icon/icons/InfoIcon";
import { AppColors } from "../../../utils/colors.utils";
import Col from "../../custom/Col";
import { NeutralBodyMediumText0, NeutralBodySmallText300 } from "../../text/Text";
import { formatDateToHumanize } from "../../../utils/date.utils";
import { useVehicleTeslaAlertsAsync } from "../../../hooks/data/vehicle-data-accessor.hooks";
import HorizontalLine from "../../line/HorizontalLine";
import NoDataPlaceholder from "../../table/placeholder/NoDataPlaceholder";
import EmptyBoxWithInfoIcon from "../../icon/icons/EmptyBoxWithInfoIcon";

type Props = {}

function VehicleDetailsAttributes({}: Props) {
  const { isLoading, alerts } = useVehicleTeslaAlertsAsync(true);

  const areThereAnyAlerts = alerts && alerts.length > 0;

  if (isLoading) {
    return <LoadingSkeleton height={40} count={5} />;
  }


  if (!areThereAnyAlerts) {
    return <NoDataPlaceholder
      icon={<EmptyBoxWithInfoIcon className={"mb-3"} />}
      height={300}
      spacing={"my-1"}
      title={"No Alerts Yet"}
      description={"When your Tesla generates alerts, they’ll be displayed here for your review and convenience."}
    />;

  }

  return (
    <div className="h-max-400px overflow-auto">
      {alerts?.map((alert, ix) =>
        <>
          <Row className={"align-items-center"}>
            <div className={"alert-info-badge center mr-3"}>
              <InfoIcon fill={AppColors.cherry100} />
            </div>

            <Col>
              <NeutralBodyMediumText0>{alert.body}</NeutralBodyMediumText0>
              <NeutralBodySmallText300>{formatDateToHumanize(alert.createdAt)}</NeutralBodySmallText300>
            </Col>
          </Row>

          {(ix !== alerts?.length && alerts?.length !== 1) && <HorizontalLine />}
        </>
      )}
    </div>
  );
}

export default VehicleDetailsAttributes;