import React from "react";

const MailOpenOutlineIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.525 6.03312L9.38215 3.00124C9.26329 2.94261 9.13253 2.91211 9 2.91211C8.86747 2.91211 8.73671 2.94261 8.61785 3.00124L2.475 6.03312C2.2395 6.1485 2.04096 6.32744 1.90181 6.54972C1.76265 6.772 1.68842 7.02876 1.6875 7.29101V13.7812C1.6875 14.5578 2.32523 15.1875 3.11203 15.1875H14.8894C15.6762 15.1875 16.3139 14.5578 16.3139 13.7812V7.29101C16.3129 7.02861 16.2384 6.77175 16.099 6.54945C15.9596 6.32716 15.7608 6.14831 15.525 6.03312Z"
        stroke="black" strokeWidth="1.19" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.9688 12.9375L9.42445 9.40289C9.27634 9.28769 9.09406 9.22514 8.90643 9.22514C8.71879 9.22514 8.53651 9.28769 8.3884 9.40289L3.84375 12.9375M10.875 10.3711L15.6562 6.75M2.15625 6.75L7.04297 10.4414"
        stroke="black" strokeWidth="1.19" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default MailOpenOutlineIcon;