import React from "react";
import { Row as ReactstrapRow } from "reactstrap";

// Row with zero margin
export default function Row(props) {
  return (
    <ReactstrapRow {...props} className={`m-0 ${props?.className ? props.className : ""}`}>
      {props?.children}
    </ReactstrapRow>
  );
}
