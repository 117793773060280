import OverviewCard from "components/dashboard-components/DashboardOverviewCard";
import { useDashboardOverviews } from "hooks/data/dashboard-data-accessor.hooks";
import { getFormattedNumberForEnergy } from "utils/charging.utils";
import ChargerIcon from "../icon/icons/ChargerIcon";
import { ReimbursementOverview } from "../../types/reimbursement.types";

type Props = {
  overview: ReimbursementOverview | undefined
  isLoading: boolean
}

export default function TotalReimbursementEnergyStatisticCard({ overview, isLoading }: Props) {
  const energyOverview = overview?.totalEnergyOverview;
  return (
    <OverviewCard
      total={getFormattedNumberForEnergy(energyOverview?.total) }
      percent={energyOverview?.monthlyTrend?.percentageChange}
      isLoading={isLoading}
      title={"Total kWh"}
      titleLeftIcon={<ChargerIcon width={14} height={20} fill={'black'}/>}
    />
  );
}
