import React from "react";

type Props = {};

function CarIconWithCircleBackground({}: Props) {
  return (
    <svg width="65" height="66" viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="65" height="65" rx="32.5" fill="#4665FF" />
      <path
        d="M49.1256 30.8187L45.835 27.2861L42.9158 20.5397C42.6652 20.0686 42.3138 19.6801 41.8955 19.4115C41.4771 19.143 41.006 19.0035 40.5275 19.0064H24.0743C23.5957 19.0035 23.1246 19.143 22.7063 19.4115C22.288 19.6801 21.9365 20.0686 21.6859 20.5397L18.7668 27.2861L15.4761 30.8187C15.3421 30.9624 15.2351 31.1365 15.1621 31.3302C15.089 31.5239 15.0514 31.7329 15.0515 31.9441V45.072C15.0515 45.4787 15.1913 45.8687 15.4401 46.1562C15.689 46.4438 16.0265 46.6053 16.3784 46.6053H21.6859C22.2166 46.6053 23.0128 45.992 23.0128 45.3787V43.5388H41.589V45.072C41.589 45.6853 42.1197 46.6053 42.6505 46.6053H48.2233C48.5752 46.6053 48.9127 46.4438 49.1616 46.1562C49.4104 45.8687 49.5502 45.4787 49.5502 45.072V31.9441C49.5504 31.7329 49.5127 31.5239 49.4396 31.3302C49.3666 31.1365 49.2597 30.9624 49.1256 30.8187ZM24.3396 22.0729H40.2621L42.9158 28.206H21.6859L24.3396 22.0729ZM25.6665 36.1791C25.6665 36.7924 24.8704 37.4057 24.3396 37.4057H18.7668C18.236 37.4057 17.7053 36.4857 17.7053 35.8724V32.4992C17.9706 31.5792 18.5014 30.9659 19.2975 31.2726L24.605 32.4992C25.1357 32.4992 25.6665 33.4192 25.6665 34.0325V36.1791ZM46.8965 35.8724C46.8965 36.4857 46.3657 37.4057 45.835 37.4057H40.2621C39.7313 37.4057 38.9352 36.7924 38.9352 36.1791V34.0325C38.9352 33.4192 39.466 32.4992 39.9967 32.4992L45.3042 31.2726C46.1003 30.9659 46.6311 31.5792 46.8965 32.4992V35.8724Z"
        fill="white"
      />
    </svg>
  );
}

export default CarIconWithCircleBackground;
