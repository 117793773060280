import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { AuthSignUpFormFlowSteps, AuthSignUpFormState } from "../../types/auth.types";
import BluedotFleetWordmark from "assets/images/logo/BluedotFleetWordmark.png";
import AuthFlowBackButton from "../button/AuthFlowBackButton";
import SignUpFormFlowStartStep from "./form-components/SignUpFormFlowStartStep";
import SignUpFormFlowCompanyStep from "./form-components/SignUpFormFlowCompanyStep";
import SignUpFormFlowCredentialsStep from "./form-components/SignUpFormFlowCredentialsStep";
import SignUpFormFlowConfirmationStep from "./form-components/SignUpFormFlowConfirmationStep";
import AuthBluedotFleetWordMark from "./form-components/AuthBluedotFleetWordMark";

type Props = {
  formFlowStep: AuthSignUpFormFlowSteps;
  setFormFlowStep: Dispatch<SetStateAction<AuthSignUpFormFlowSteps>>;
};

function SignUpFormFlow({ formFlowStep, setFormFlowStep }: Props) {
  const [registerFormState, setRegisterFormState] = useState<AuthSignUpFormState>({
    emailAddressCodeSent: {
      encrypted: "",
      original: "",
    },
    phoneNumber: "",
    dialCode: { label: "US +1", value: "+1" },
    name: "",
    companyName: "",
  });

  const history = useHistory();

  const handleBackButtonPressed = () => {
    if (formFlowStep === AuthSignUpFormFlowSteps.COMPANY) {
      setFormFlowStep(AuthSignUpFormFlowSteps.START);
      return;
    }

    if (formFlowStep === AuthSignUpFormFlowSteps.CREDENTIALS) {
      setFormFlowStep(AuthSignUpFormFlowSteps.COMPANY);
      return;
    }

    if (formFlowStep === AuthSignUpFormFlowSteps.CONFIRMATION) {
      setFormFlowStep(AuthSignUpFormFlowSteps.CREDENTIALS);
      return;
    }

    history.goBack();
  };

  const formStep = useMemo(() => {
    switch (formFlowStep) {
      case AuthSignUpFormFlowSteps.START:
        return (
          <SignUpFormFlowStartStep
            onFormStateChanged={setRegisterFormState}
            setFormFlowStep={setFormFlowStep}
            registerFormState={registerFormState}
          />
        );

      case AuthSignUpFormFlowSteps.COMPANY:
        return (
          <SignUpFormFlowCompanyStep
            registerFormState={registerFormState}
            onFormStateChanged={setRegisterFormState}
            setFormFlowStep={setFormFlowStep}
          />
        );

      case AuthSignUpFormFlowSteps.CREDENTIALS:
        return (
          <SignUpFormFlowCredentialsStep
            onFormStateChanged={setRegisterFormState}
            setFormFlowStep={setFormFlowStep}
            registerFormState={registerFormState}
          />
        );

      case AuthSignUpFormFlowSteps.CONFIRMATION:
        return <SignUpFormFlowConfirmationStep emailAddressCodeSent={registerFormState.emailAddressCodeSent} />;

      default:
        return (
          <SignUpFormFlowStartStep
            registerFormState={registerFormState}
            onFormStateChanged={setRegisterFormState}
            setFormFlowStep={setFormFlowStep}
          />
        );
    }
  }, [formFlowStep, registerFormState, setFormFlowStep]);

  const isFormFlowStepStart = AuthSignUpFormFlowSteps.START === formFlowStep;

  return (
    <div className="auth-form-container">
      {!isFormFlowStepStart && <AuthFlowBackButton onAuthFlowBackButtonPressed={handleBackButtonPressed} />}
      <AuthBluedotFleetWordMark />
      <div className="py-2">{formStep}</div>
    </div>
  );
}

export default SignUpFormFlow;
