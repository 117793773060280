import { SvgProps } from "types/svg.types";

const CarMenuIconFilled = (props: SvgProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3330_8444)">
        <path
          d="M21.9374 11.1444C21.895 11.0315 21.8315 10.9292 21.7539 10.8481L19.8452 8.80176L18.1517 4.89261C18.0071 4.62095 17.8024 4.39515 17.559 4.23991C17.3191 4.08115 17.0474 4 16.7687 4H7.23219C6.95347 4 6.68181 4.08115 6.43837 4.23638C6.19493 4.39162 5.99383 4.61742 5.84564 4.88908L4.15215 8.79824L2.24697 10.8481C2.16935 10.9327 2.10584 11.0315 2.06351 11.1444C2.02117 11.2573 2 11.3773 2 11.5008V19.1109C2 19.3473 2.08115 19.5731 2.2258 19.7389C2.37045 19.9047 2.5645 20 2.76913 20H6.23021C6.53716 20 6.99934 19.6437 6.99934 19.2908V18.0031H17.0015V19.1144C17.0015 19.4708 17.3085 20.0035 17.6154 20.0035H21.2282C21.4329 20.0035 21.6269 19.9083 21.7716 19.7424C21.9162 19.5766 21.9974 19.3508 21.9974 19.1144V11.5043C21.9974 11.3808 21.9762 11.2609 21.9338 11.148L21.9374 11.1444ZM6.20551 9.50386L7.56384 6.22271C7.62029 6.08864 7.75083 6.00044 7.89548 6.00044H16.116C16.2606 6.00044 16.3947 6.08864 16.4476 6.22271L17.7954 9.50386C17.8942 9.74024 17.7178 10.0013 17.4637 10.0013H6.53716C6.2796 10.0013 6.10673 9.74024 6.20551 9.50386ZM8.00132 14.3444C8.00132 14.7078 7.70849 15.0007 7.34509 15.0007H4.65667C4.29327 15.0007 4.00044 14.7078 4.00044 14.3444V12.658C4.00044 12.2311 4.40265 11.9171 4.81544 12.0229L7.50386 12.6933C7.79669 12.7674 8.00132 13.0284 8.00132 13.3283V14.3444ZM20.004 14.3444C20.004 14.7078 19.7111 15.0007 19.3477 15.0007H16.6593C16.2959 15.0007 16.0031 14.7078 16.0031 14.3444V13.3283C16.0031 13.0284 16.2077 12.7638 16.5006 12.6933L19.189 12.0229C19.6018 11.9206 20.004 12.2311 20.004 12.658V14.3444Z"
          fill="#0C0C0C"
        />
      </g>
      <defs>
        <clipPath id="clip0_3330_8444">
          <rect width="20.0009" height="16" fill="white" transform="translate(2 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CarMenuIconFilled;
