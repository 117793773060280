import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationMeta } from "types/api.types";
import { InboxMessage, InboxSliceState } from "types/inbox.types";

const initialState: InboxSliceState = {
  selectedMessage: null,
  messages: [],
  meta: null,
  isLoading: false,
};

export const inboxSlice = createSlice({
  name: "inbox",
  initialState,
  reducers: {
    setInboxMessagesAndMeta: (state, action: PayloadAction<{ messages: InboxMessage[]; meta: PaginationMeta }>) => {
      state.messages = action.payload.messages;
      state.meta = action.payload.meta;
    },
    setIsInboxMessagesLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSelectedInboxMessage: (state, action: PayloadAction<InboxMessage | null>) => {
      state.selectedMessage = action.payload;
    },
    setInboxMessageAsRead: (state, action: PayloadAction<string>) => {
      const messageToUpdate = state.messages.find((m) => m.id === action.payload);
      if (messageToUpdate) messageToUpdate.read = true;
    },
    setInboxMessageAsArchived: (state, action: PayloadAction<string>) => {
      state.messages = state.messages.filter((m) => m.id !== action.payload);
    },
  },
});

export const {
  setInboxMessagesAndMeta,
  setIsInboxMessagesLoading,
  setInboxMessageAsRead,
  setInboxMessageAsArchived,
  setSelectedInboxMessage,
} = inboxSlice.actions;
export default inboxSlice.reducer;
