// Third party
import { useTranslation } from "react-i18next";
import Row from "../../components/custom/Row";

// Components
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import Col from "../../components/custom/Col";
import LoginForm from "../../components/auth-components/LoginForm";

// Img
import BluedotFleetWordmark from "assets/images/logo/BluedotFleetWordmark.png";
import BluedotLogoIcon from "components/icon/icons/BluedotLogoIcon";
import LogoutOutlinedButton from "components/button/LogoutOutlinedButton";
import { BlackBodyText0, BlackBodyText100, BlackH3Text100, BlackSubtitleText100 } from "components/text/Text";

import OpenMailLink from "components/button/OpenMailLink";
import BackToSignInButton from "components/button/BackToSignInButton";

export default function InactiveUserPage() {
  const { t } = useTranslation("common");

  return (
    <>
      <Head title={t("auth.inactive_user.title")}></Head>

      <Row className="screen-height-container ">
        <Col className="bg-white scrollable-container p-4">
          <div className="auth-form-container">
            <div className="w-100 mb-2 d-flex  justify-content-start pb-4">
              <img width={220} src={BluedotFleetWordmark} alt="logo" />
            </div>

            <BlackH3Text100>{t("auth.inactive_user.page_header")}</BlackH3Text100>
            <BlackBodyText100 className="my-3 mb-4">
              {t("auth.inactive_user.page_description")}{" "}
              <OpenMailLink mailTo="info@thebluedot.co" label="Customer Support" />
            </BlackBodyText100>
            <BackToSignInButton />
          </div>
        </Col>
        <Col lg="6" className="h-100 d-none d-lg-flex align-items-center justify-content-center">
          <BluedotLogoIcon />
        </Col>
      </Row>
    </>
  );
}
