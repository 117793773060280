import CancelButton from "components/button/CancelButton";
import PrimaryButton from "components/button/PrimaryButton";
import Row from "components/custom/Row";
import GreenReferralCodeIcon from "components/icon/icons/GreenReferralCodeIcon";
import ReferralCodeIcon from "components/icon/icons/ReferralCodeIcon";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import {
  BlackBody2Text100,
  BlackBody2Text200,
  BlackBodyText100,
  BlackBodyText200,
  BlackH5Text100,
  BlackLinkText100,
  BlackSubtitleText200
} from "components/text/Text";
import { useAlertManager } from "hooks/alert.hooks";
import { useAuthStatus } from "hooks/auth.hooks";
import { useBranchIoDriverAppLink } from "hooks/driver.hooks";
import { Dispatch, SetStateAction } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DriverSelfSignupSteps from "./DriverSelfSignupSteps";

type Props = { setIsSelfSignupModalOpen: Dispatch<SetStateAction<boolean>> };

function DriverSelfSignupModalContent({ setIsSelfSignupModalOpen }: Props) {
  const { appLink } = useBranchIoDriverAppLink();

  const { handleOpenSuccessAlert } = useAlertManager();
  const { user } = useAuthStatus();

  return (
    <div className="p-4">
      <Row className="justify-between align-items-center">
        <GreenReferralCodeIcon />
        <div className="bg-secondary rounded-xxl p-2">
          <Row>
            <BlackBodyText200 className="mr-1">Unique fleet code: </BlackBodyText200>
            <BlackBodyText100>{user?.signUpCode.code}</BlackBodyText100>
          </Row>
          <Row>
            <BlackBodyText200 className="mr-1">Driver app:</BlackBodyText200>
            {appLink ? (
              <BlackLinkText100
                onClick={() => {
                  if (appLink) {
                    window.open(appLink, "_blank");
                  }
                }}
              >
                {appLink}
              </BlackLinkText100>
            ) : (
              <div className="w-300px">
                <LoadingSkeleton count={1} />
              </div>
            )}
          </Row>
        </div>
      </Row>

      <BlackSubtitleText200 className="mt-2">Driver Self-Signup</BlackSubtitleText200>
      <BlackH5Text100>with fleet code</BlackH5Text100>
      <BlackBody2Text100 className="mt-1">
        Effortlessly expand your fleet by enabling drivers to self-signup using your unique fleet code. Simply share the
        code and driver app link, then approve their access.
      </BlackBody2Text100>

      <DriverSelfSignupSteps />

      <BlackBody2Text200 className="mt-2">
        For assistance or questions, please contact our support team.
      </BlackBody2Text200>

      <Row className="mt-3 justify-content-end">
        <CancelButton onClick={() => setIsSelfSignupModalOpen(false)} />
        <CopyToClipboard
          disabled={!appLink}
          text={`Driver app: ${appLink}, Unique fleet code: ${user?.signUpCode.code}`}
          onCopy={() => handleOpenSuccessAlert("Copied to clipboard!")}
        >
          <PrimaryButton
            isDisabled={!appLink}
            className="ml-2"
            text="Copy code & link"
            leftIcon={<ReferralCodeIcon fill={'white'} className="mr-2" />}
          />
        </CopyToClipboard>
      </Row>
    </div>
  );
}

export default DriverSelfSignupModalContent;
