import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { Icon } from "../Component";
import ButtonWithIcon from "./ButtonWithIcon";

export default function AddDriverButton(props) {
  const widthClass = props.widthClass ?? "";

  /**
   * Hooks
   */
  const history = useHistory();

  /**
   * Handlers
   */
  const handleAddDriverClicked = useCallback(() => {
    if (props.type === "submit") {
      return;
    }
    history.push("/add-driver");
  }, [history, props.type]);

  /**
   * Render
   */
  return (
    <ButtonWithIcon
      className={`mt-md-0 ${widthClass}`}
      leftIcon={<Icon name="plus" />}
      text={"Add Driver"}
      onClick={handleAddDriverClicked}
      isLoading={props.isLoading}
    />
  );
}
