import React, { useMemo } from "react";
import { SettingsPageTabs } from "types/settings.types";
import { Link } from "react-router-dom";
import SettingsUserIconFilled from "../icon/icons/SettingsUserIconFilled";
import SettingsUserIconOutlined from "../icon/icons/SettingsUserIconOutlined";
import SettingsLockIconFilled from "../icon/icons/SettingsLockIconFilled";
import SettingsLockIconOutlined from "../icon/icons/SettingsLockIconOutlined";
import SettingsPreferencesIconFilled from "../icon/icons/SettingsPreferencesIconFilled";
import SettingsPreferencesIconOutlined from "../icon/icons/SettingsPreferencesIconOutlined";
import { NeutralBodyMediumText100, NeutralBodyMediumText200 } from "../text/Text";

type Props = {
  readonly tab: SettingsPageTabs;
  readonly isActive: boolean;
};

function SettingsPageTabLink({ tab, isActive }: Props) {
  const TabLinkContent = useMemo(() => {
    switch (tab) {
      case SettingsPageTabs.ACCOUNT:
        return {
          icon: isActive ? <SettingsUserIconFilled /> : <SettingsUserIconOutlined />,
          label: "Account"
        };

      case SettingsPageTabs.SECURITY:
        return {
          icon: isActive ? <SettingsLockIconFilled /> : <SettingsLockIconOutlined />,
          label: "Access & Security"
        };

      case SettingsPageTabs.PREFERENCES:
        return {
          icon: isActive ? <SettingsPreferencesIconFilled /> : <SettingsPreferencesIconOutlined />,
          label: "Preferences"
        };

      default:
        return {
          icon: isActive ? <SettingsPreferencesIconFilled /> : <SettingsPreferencesIconOutlined />,
          label: "Access & Security"
        };
    }
  }, [isActive, tab]);

  return (
    <li className="nk-menu-item px-1">
      <Link to={`/settings/${tab}`} className={`nk-menu-link`}>
        <div className="settings-menu-icon">{TabLinkContent.icon}</div>

        {isActive ? <NeutralBodyMediumText100>{TabLinkContent.label}</NeutralBodyMediumText100> :
          <NeutralBodyMediumText200>{TabLinkContent.label}</NeutralBodyMediumText200>}

      </Link>
    </li>
  )
    ;
}

export default SettingsPageTabLink;
