import React from "react";
import { Col as ReactstrapCol } from "reactstrap";

// Row with zero padding
export default function Col(props) {
  return (
    <ReactstrapCol {...props} className={`p-0 ${props?.className ? props.className : ""}`}>
      {props?.children}
    </ReactstrapCol>
  );
}
