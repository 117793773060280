import React from "react";
import { BlockLabel } from "../../block/Block";
import DashboardTablePlaceholderIcon from "../../icon/icons/DashboardTablePlaceholder";
import { BlackBodyText300 } from "../../text/Text";

export function DashboardTablePlaceholder({ button }: { button?: JSX.Element }) {
  return (
    <div className="center h-100 flex-column  rounded-xxl">
      <DashboardTablePlaceholderIcon />
      <BlackBodyText300 className={"my-3"}>No data to display</BlackBodyText300>
      {button && <div className="my-2">{button}</div>}
    </div>
  );
}
