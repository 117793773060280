import NeedHelpButton from "components/button/NeedHelpLinkButton";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import CustomModalWithXIcon from "components/modal/CustomModalWithXIcon";
import { BlackBodyText100, BlackBodyText200, BlackH6Text0 } from "components/text/Text";
import { useState } from "react";

import TeslaAndCarIntersectionIcon from "components/icon/icons/TeslaAndCarIntersectionIcon";
import { CustomerServiceLink } from "components/links/Links";

type Props = {
  readonly helpButtonText?: string;
};

export default function AddVehicleFlowSelectionInfoModal({ helpButtonText }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <CustomModalWithXIcon isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <Row className="w-100 h-100 ">
          <Col md="5" className="bg-blue-1000  center p-4">
            <TeslaAndCarIntersectionIcon />
          </Col>
          <Col md="7" className="p-5 h-max-80vh overflow-auto">
            <BlackH6Text0 className={"mb-2"}>Understanding Your Vehicle Addition Options</BlackH6Text0>
            <BlackBodyText100 className="mt-4">Tesla Vehicle Addition:</BlackBodyText100>
            <Col className={"mt-4 pl-2"}>
              <BlackBodyText200>
                <span className="mr-1"> &#x2022;</span> Vehicle Connection through Telematics: Enjoy a seamless
                experience as we automatically fetch detailed data and insights about your Tesla vehicle using
                telematics technology.
              </BlackBodyText200>
              <BlackBodyText200>
                <span className="mr-1"> &#x2022;</span> Live Data Synchronization: Stay up-to-date with real-time data
                synchronization, allowing for efficient fleet management with the latest information always at hand.
              </BlackBodyText200>
              <BlackBodyText200>
                <span className="mr-1"> &#x2022;</span> Ease of Use: Avoid the hassle of manual entries; just connect
                your vehicle and let our system do the work.
              </BlackBodyText200>
            </Col>

            <BlackBodyText100 className="mt-4">Non-Tesla Vehicle Addition:</BlackBodyText100>
            <BlackBodyText200 className={"mt-4 pl-2"}>
              <span className="mr-1"> &#x2022;</span> VIN-Based Entry: Quickly add your non-Tesla vehicles using the
              straightforward VIN-based entry method. It's a straightforward process requiring just a few details.
            </BlackBodyText200>

            <CustomerServiceLink />
          </Col>
        </Row>
      </CustomModalWithXIcon>
      <NeedHelpButton text={helpButtonText} onClick={() => setIsModalOpen((isModalOpen) => !isModalOpen)} />
    </>
  );
}
