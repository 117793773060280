import React from "react";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import AssignDriverFormCompletedContent from "./AssignDriverFormCompletedContent";

type Props = {};

function AssignDriverFormCompletedPage({}: Props) {
  return (
    <>
      <Head title={"Successful"} />
      <Content>
        <AssignDriverFormCompletedContent />
      </Content>
    </>
  );
}

export default AssignDriverFormCompletedPage;
