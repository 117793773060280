import React from "react";
import { Link } from "react-router-dom";
import Icon from "../icon/Icon";
import classNames from "classnames";
import { BlockFormLabel, BlockFormLabelWithoutMargin } from "../block/Block";
import Row from "../custom/Row";
import CustomerServiceIcon from "../icon/icons/CustomerServiceIcon.svg";

import { useIntercom } from "react-use-intercom";

export const LinkItem = ({ ...props }) => {
  return (
    <li>
      {props.tag !== "a" ? (
        <Link to={process.env.PUBLIC_URL + props.link} {...props}>
          {props.icon ? <Icon name={props.icon} /> : null} <span>{props.text || props.children}</span>
        </Link>
      ) : (
        <a href={process.env.PUBLIC_URL + props.link} onClick={(ev) => ev.preventDefault()}>
          {props.icon ? <Icon name={props.icon} /> : null} <span>{props.text || props.children}</span>
        </a>
      )}
    </li>
  );
};

export const ExternalLinkItem = ({ ...props }) => {
  return (
    <li>
      {props.tag !== "a" ? (
        <Link to={props.link} {...props}>
          {props.icon ? <Icon name={props.icon} /> : null} <span>{props.text || props.children}</span>
        </Link>
      ) : (
        <a href={props.link} target="_blank">
          {props.icon ? <Icon name={props.icon} /> : null} <span>{props.text || props.children}</span>
        </a>
      )}
    </li>
  );
};

export const LinkList = ({ ...props }) => {
  const listClasses = classNames({
    "link-list": !props.opt,
    "link-list-opt": props.opt,
    [`${props.className}`]: props.className,
  });
  return <ul className={listClasses}>{props.children}</ul>;
};

export const CustomerServiceLink = () => {
  const intercom = useIntercom();
  const handleCustomerSupportLinkPressed = () => {
    intercom.show();
  };
  return (
    <div
      data-testid="customer-service-button"
      className="d-flex flex-row   align-items-center mt-4"
      onClick={handleCustomerSupportLinkPressed}
    >
      <img className="mr-2" src={CustomerServiceIcon} alt="CustomerServiceIcon" />
      <BlockFormLabelWithoutMargin className="link-text mb-0">Customer Support </BlockFormLabelWithoutMargin>
    </div>
  );
};
