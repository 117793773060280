import React, { useCallback } from "react";

import Col from "components/custom/Col";
import Row from "components/custom/Row";
import VehicleDetailsDriverAssignment from "../VehicleDetailsDriverAssignment";
import { BlackBody2Text200, BlackSubtitleText100 } from "../../../text/Text";
import Card from "../../../card/Card";
import VehicleDetailsAssignDriverInfoButtonAndModal from "../VehicleDetailsAssignDriverInfoButtonAndModal";
import { VehicleDriverSchedulesDataAccessorHook } from "../../../../types/vehicle-driver-schedule.types";
import { getCategorizedSchedules } from "../../../../utils/vehicle-driver-schedule.utils";
import VehicleDetailsVehicleDriverScheduleListWithEditors from "../VehicleDetailsVehicleDriverScheduleListWithEditors";
import OutlinedAddButton from "../../../button/OutlinedAddButton";
import { useHistory } from "react-router";
import { useVehicleIdFromParams } from "../../../../hooks/vehicle.hooks";
import VehicleDetailsOldDriverAssignmentsModal from "../VehicleDetailsOldDriverAssignmentsModal";

type Props = {
  // readonly vehicleHook: VehicleDataAccessorHook;
//  readonly teslaHook: TeslaDataAccessorHook;
  readonly vehicleDriverSchedulesHook: VehicleDriverSchedulesDataAccessorHook
}

function VehicleDetailsAssignmentsContent({ vehicleDriverSchedulesHook }: Props): JSX.Element {
  const { schedules, isLoading: isSchedulesLoading, refetch } = vehicleDriverSchedulesHook;

  const history = useHistory();
  const { vehicleId } = useVehicleIdFromParams();
  const { activeSchedules, plannedSchedules } = getCategorizedSchedules(schedules);

  const handleAssignDriverButtonClicked = useCallback(() => {
    history.push(`/assign-driver/${vehicleId}`);
  }, [history, vehicleId]);

  return (
    <Row>
      <Col lg={4} className="pr-lg-3">

        <BlackSubtitleText100>Current assignment</BlackSubtitleText100>

        <Card className={"mt-4"}>
          <VehicleDetailsDriverAssignment
            vehicleDriverSchedulesHook={vehicleDriverSchedulesHook}
          />
        </Card>

        <VehicleDetailsAssignDriverInfoButtonAndModal />


        <VehicleDetailsOldDriverAssignmentsModal />


      </Col>
      <Col lg={8} className="pl-lg-3">

        <BlackSubtitleText100 className={"mb-2"}>Current schedule</BlackSubtitleText100>

        <VehicleDetailsVehicleDriverScheduleListWithEditors isLoading={isSchedulesLoading}
                                                            refetch={refetch}
                                                            schedules={activeSchedules} />


        <BlackSubtitleText100 className={"mt-4"}>Scheduled assignments</BlackSubtitleText100>
        <BlackBody2Text200 className={"mb-2"}>The driver will be automatically assigned to the vehicle on the start
          date.</BlackBody2Text200>

        <VehicleDetailsVehicleDriverScheduleListWithEditors isLoading={isSchedulesLoading}
                                                            refetch={refetch}
                                                            schedules={plannedSchedules} />

        <div className={"mt-4 pt-2"}>
          <OutlinedAddButton onClick={handleAssignDriverButtonClicked}
                             text={"Add new assignment"} />
        </div>
      </Col>


    </Row>
  );
}

export default VehicleDetailsAssignmentsContent;