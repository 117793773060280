import { DateTime, Duration } from "luxon";
import moment from "moment";


export const DateTimeOneHourLaterObject = { minutes: 59, seconds: 59 };

export const ImmediateDateSpecifier = "immediately";
export const IndefiniteDateSpecifier = "indefinitely";

export const PredefinedMaxDateValue = "9999-01-01T00:00:00.000Z";

export const getLocalTimeZoneDate = (date: Date | string) => {
  return moment(date).utc().date();
};


export function formatDateToHumanize(dateString: string) {
  const date = DateTime.fromISO(dateString);
  const now = DateTime.now();

  if (date.hasSame(now, "week")) {
    return date.toRelative()
  } else {
    return getLocalFormattedDateForTables(dateString);
  }
}


export function formatSecondsToHumanize(seconds?: number) {
  if (!seconds) {
    return "-";
  }

  const duration = Duration.fromMillis(seconds * 1000).shiftTo("days", "hours", "minutes", "seconds").toObject();


  if (!duration) {
    return "-";
  }

  let formatted = "";


  if (duration.days && duration.days > 0) {
    formatted += `${duration.days} day${duration.days > 1 ? "s" : ""} `;
  }

  if (duration.hours && duration.hours > 0) {
    formatted += `${duration.hours} hour${duration.hours > 1 ? "s" : ""} `;
  }

  // Check if duration includes minutes
  if (duration.minutes && duration.minutes > 0) {
    formatted += `${duration.minutes} minute${duration.minutes > 1 ? "s" : ""} `;
  }


  return formatted.trim(); // Trim any extra space at the end
}

export const getDateAsFromNowFormat = (date: Date | string | number | undefined) => {
  if (!date) {
    return;
  }
  return moment(date).fromNow();
};


export const getDateAsFromNowFormatWithFetchedPrefix = (date?: Date | string | number) => {
  if (!date) {
    return;
  }
  return `fetched ${getDateAsFromNowFormat(date)}`;
};


export const getDateTimeWithTimezone = (date: string) => {
  return DateTime.fromISO(date);
};


export const getMonthNameByDateString = (date?: string) => {
  if (!date) {
    return "-";
  }

  const dateTime = DateTime.fromFormat(date, "LLLL d, yyyy");
  return dateTime.toFormat("MMMM");
};

export const getMonthNameFromYearWeekFormat = (yearWeek: string) => {
  const [year, weekNumber] = yearWeek.split("-").map(Number);

  const date = DateTime.fromObject({ weekYear: year, weekNumber });

  const monthName = date.monthLong;

  return monthName ?? "-";
};

export const getWeekIntervalFromYearWeekFormat = (yearWeek: string) => {
  const [year, weekNumber] = yearWeek.split("-").map(Number);

  const startOfTheWeek = DateTime.fromObject({ weekYear: year, weekNumber });
  const nextOfTheWeek = startOfTheWeek.plus({ week: 1 }).toLocaleString(DateTime.DATE_FULL);

  const formattedStartOfTheWeek = startOfTheWeek.toLocaleString(DateTime.DATE_FULL);

  return { startOfTheWeek: formattedStartOfTheWeek, nextOfTheWeek };
};

export const getLocalDate = (date: string | Date) => {
  if (typeof date === "string") {
    return DateTime.fromISO(date).toLocal();
  }

  return date.toLocaleDateString();
};


export const getLocalFormattedDateAsMonthYear = (date?: string) => {
  if (!date) {
    return "-";
  }
  const localDate = DateTime.fromISO(date);
  return localDate.toFormat("LLL yyyy");
};

export const getLocalFormattedDateForTables = (date?: string) => {
  if (!date) {
    return "-";
  }
  const localDate = getLocalDate(date);
  return localDate.toLocaleString(DateTime.DATETIME_SHORT);
};


export const getLocalFormattedDateAsDateShort = (date?: string) => {
  if (!date) {
    return "-";
  }
  const localDate = getLocalDate(date);
  return localDate.toLocaleString(DateTime.DATE_SHORT);
};

export const getLocalFormattedDateAsMonthName = (date?: string) => {
  if (!date) {
    return "-";
  }
  const localDate = DateTime.fromISO(date);
  return localDate.toFormat("LLL");
};

export const getLocalFormattedDateAsDateShortForFilter = (date?: string | null | Date) => {
  if (!date) {
    return "...";
  }
  const localDate = getLocalDate(date);
  return localDate.toLocaleString(DateTime.DATE_SHORT);
};

export const getLocalFormattedDateAsDateMed = (date?: string) => {
  if (!date) {
    return "-";
  }
  const localDate = getLocalDate(date);
  return localDate.toLocaleString(DateTime.DATE_MED);
};


export const isDateExpired = (expiryDate: Date) => {
  if (!expiryDate) {
    return true;
  }
  const now = new Date().getTime();
  return now > expiryDate.getTime();
};


export function formatSeconds(seconds?: number) {
  if (!seconds) {
    return "-";
  }

  const duration = Duration.fromMillis(seconds * 1000).shiftTo("hours", "minutes", "seconds").toObject();


  if (!duration) {
    return "-";
  }

  let formatted = "";


  if (duration.hours && duration.hours > 0) {
    formatted += `${duration.hours} hour${duration.hours > 1 ? "s" : ""} `;
  }

  // Check if duration includes minutes
  if (duration.minutes && duration.minutes > 0) {
    formatted += `${duration.minutes} minute${duration.minutes > 1 ? "s" : ""} `;
  }

  // Include seconds if there are no hours or minutes, or if there are remaining seconds
  if ((duration.seconds && duration.seconds > 0) || (!duration.hours && !duration.minutes)) {
    const secs = Math.floor(duration.seconds ?? 0); // Ensure seconds are rounded down
    formatted += `${secs} second${secs > 1 ? "s" : ""}`;
  }

  return formatted.trim(); // Trim any extra space at the end
}