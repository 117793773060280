import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import LiveVehicleLocator from "../../components/vehicle-components/locator/LiveVehicleLocator";
import Head from "../../layout/head/Head";

type Props = {};

function VehicleLocatorPage(props: Props) {
  const { t } = useTranslation("common");

  return (
    <>
      <Head title={"Vehicle Locator"}></Head>

      <ErrorBoundary fallback={<></>}>
        <LiveVehicleLocator isPage />
      </ErrorBoundary>
    </>
  );
}

export default VehicleLocatorPage;
