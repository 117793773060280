import { SvgProps } from "types/svg.types";

type Props = {};

function SettingsLegalIconFilled(props: SvgProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M4.49969 2.00037C4.22375 2.00037 4.00006 2.22424 4.00006 2.50037L4 19.5818C4 19.7249 4.06128 19.8611 4.16832 19.956L6.33752 21.8789C6.52527 22.041 6.80353 22.041 6.99124 21.8789L8.99709 20.1007C9.18638 19.9329 9.47116 19.9329 9.66045 20.1007L11.6664 21.8788C11.8541 22.041 12.1324 22.041 12.3201 21.8788L14.3257 20.1007C14.515 19.9329 14.7998 19.9329 14.9891 20.1007L16.9958 21.8795C17.1832 22.0415 17.4608 22.0417 17.6486 21.8804L19.8307 19.9561C19.9383 19.8611 20 19.7246 20 19.581V5.09412C20 4.97156 19.955 4.85315 19.8735 4.7616L17.5651 2.16748C17.4703 2.06091 17.3345 2 17.1919 2L4.49969 2.00037ZM16.4929 6.56653C16.7689 6.56653 16.9926 6.79041 16.9926 7.06653V8.06653C16.9926 8.34265 16.7689 8.56653 16.4929 8.56653H7.49884C7.2229 8.56653 6.99918 8.34265 6.99918 8.06653V7.06653C6.99918 6.79041 7.2229 6.56653 7.49884 6.56653H16.4929ZM11.4944 11.0568C11.7704 11.0568 11.9941 11.2806 11.9941 11.5568V12.5568C11.9941 12.8329 11.7704 13.0568 11.4944 13.0568H7.49707C7.22113 13.0568 6.99741 12.8329 6.99741 12.5568V11.5568C6.99741 11.2806 7.22113 11.0568 7.49707 11.0568H11.4944Z"
            fill="black" />
    </svg>
  );
}

export default SettingsLegalIconFilled;
