import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CancelButton from "../button/CancelButton";
import DeleteButton from "../button/DeleteButton";

export default function AlertDialog({
  isLoading,
  isDialogOpen,
  toggleIsDialogOpen,
  onDialogConfirmed,
  description,
  title,
}) {
  const theme = useTheme();

  return (
    <Dialog open={isDialogOpen} onClose={toggleIsDialogOpen} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={toggleIsDialogOpen} />
        <DeleteButton isLoading={isLoading} onClick={onDialogConfirmed} />
      </DialogActions>
    </Dialog>
  );
}
