import { BlackBodyText100, BlackCaptionText200 } from "components/text/Text";
import React from "react";
import { Control, DeepMap, FieldError, RegisterOptions } from "react-hook-form";
import { FormGroup } from "reactstrap";
import ControllerValidationError from "./ControllerValidationError";

type Props = {
  register?: (options: RegisterOptions) => React.LegacyRef<HTMLInputElement> | undefined;
  control?: Control<any>;
  errors: DeepMap<any, FieldError>;
  defaultValue?: string;
  placeholder?: string;
  errorMessage?: string;
  formLabel?: string;
  formHelper?: string;
  registerRef?: any;
  name: string;
  type?: string;
  readonly?: boolean;
  inputMode?: string;
  overwriteFormHelper?: JSX.Element
};

export default function InputControllerWithValidationError({
                                                             register,
                                                             errors,
                                                             defaultValue,
                                                             placeholder,
                                                             type,
                                                             name,
                                                             errorMessage,
                                                             formLabel = "",
                                                             formHelper = "",
                                                             overwriteFormHelper,
                                                             registerRef,
                                                             inputMode,
                                                             ...props
                                                           }: Props) {
  const defaultType = "text";

  return (
    <FormGroup>
      {formLabel && <BlackBodyText100 className="mb-1">{formLabel}</BlackBodyText100>}
      {overwriteFormHelper ?? (formHelper && <BlackCaptionText200 className="mb-2">{formHelper}</BlackCaptionText200>)}
      <input
        className="form-control"
        type={type ?? defaultType}
        name={name}
        defaultValue={defaultValue}
        placeholder={placeholder}
        ref={
          registerRef ??
          (register &&
            register({
              required: errorMessage ?? `${name} is required.`
            }))
        }
        {...props}
      />
      <ControllerValidationError error={errors[name]?.message} />
    </FormGroup>
  );
}
