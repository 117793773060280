import React from "react";

const TableCheckboxCheckedIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="4.5" stroke="#2E3338" />
      <path d="M18 8.25L10.5 15.75L6.75 12" stroke="#2E3338" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round" />
    </svg>

  );
};

export default TableCheckboxCheckedIcon;