type Props = {};

function VehicleStatusDriveIcon({}: Props) {
  return (
    <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0217 10.5834L13.6533 0.583437C13.498 0.227812 13.1595 0 12.787 0H9.73733L9.81389 0.72375C9.82952 0.87125 9.71358 1 9.56514 1H8.65389C8.50546 1 8.38952 0.87125 8.40514 0.72375L8.4817 0H5.43202C5.05921 0 4.72077 0.227812 4.56545 0.583437L0.197017 10.5834C-0.0920452 11.2456 0.369205 12 1.06389 12H7.21514L7.53733 8.9475C7.5642 8.69312 7.77858 8.5 8.03452 8.5H10.1845C10.4405 8.5 10.6548 8.69312 10.6817 8.9475L11.0039 12H17.1551C17.8498 12 18.3111 11.2456 18.0217 10.5834ZM8.24702 2.22375C8.2535 2.16231 8.28251 2.10545 8.32845 2.06413C8.37438 2.02281 8.43398 1.99997 8.49577 2H9.72358C9.85139 2 9.95889 2.09656 9.97233 2.22375L10.1161 3.58562C10.1395 3.80719 9.96577 4 9.74327 4H8.47639C8.25358 4 8.08014 3.80719 8.10358 3.58562L8.24702 2.22375ZM9.97327 7.5H8.24545C7.94858 7.5 7.71702 7.24281 7.74827 6.9475L7.9067 5.4475C7.93358 5.19312 8.14795 5 8.40389 5H9.81483C10.0708 5 10.2851 5.19312 10.312 5.4475L10.4705 6.9475C10.5017 7.24281 10.2701 7.5 9.97327 7.5Z"
        fill="#6F7981"
      />
    </svg>
  );
}

export default VehicleStatusDriveIcon;
