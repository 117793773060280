import React from "react";
import { NeutralBodyMediumText200, NeutralTitleMediumText100 } from "../text/Text";
import PrimaryButton from "../button/PrimaryButton";
import TableNoResultPlaceholderImage from "../../assets/images/placeholder/NoResultPlaceholder.png";
import { useHistory } from "react-router";

const NotFoundContent = () => {
 const history = useHistory()
  return (
    <div className="center flex-column w-100 h-min-650px h-max-100">
      <img width={250} src={TableNoResultPlaceholderImage} alt="No Result" />
      <NeutralTitleMediumText100 className={"mt-4"}>It’s quiet in here</NeutralTitleMediumText100>
      <NeutralBodyMediumText200 className={"mt-1 mb-3"}>It doesn't have any information.</NeutralBodyMediumText200>
      <PrimaryButton text={"Home"} onClick={()=> history.push("/")} />
    </div>
  );
};

export default NotFoundContent;