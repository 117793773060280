import React from "react";

HonkHornIcon.propTypes = {};

function HonkHornIcon(props) {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 5.4922C16.333 7.2702 16.333 10.7142 15 12.4922M18 1.9922C21.988 5.8002 22.012 12.2092 18 15.9922M1 11.9512V6.0322C1 5.4582 1.448 4.9922 2 4.9922H5.586C5.71833 4.99173 5.8492 4.96446 5.97071 4.91204C6.09222 4.85963 6.20185 4.78314 6.293 4.6872L9.293 1.2992C9.923 0.6432 11 1.1082 11 2.0352V15.9492C11 16.8832 9.91 17.3442 9.284 16.6752L6.294 13.3062C6.20259 13.2075 6.09185 13.1287 5.96867 13.0747C5.84549 13.0207 5.71251 12.9926 5.578 12.9922H2C1.448 12.9922 1 12.5262 1 11.9512Z"
        stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}

export default HonkHornIcon;