import CancelButton from "components/button/CancelButton";
import PrimaryButton from "components/button/PrimaryButton";
import Row from "components/custom/Row";
import IosSwitch from "components/input/switch/IosSwitch";
import BaseModal from "components/modal/BaseModal";
import AlertConfirmDialog from "components/notification/AlertConfirmDialog";
import { BlackBody2Text200, BlackOverlineText200 } from "components/text/Text";
import TooltipWithInfoIcon from "components/tooltip/TooltipWithInfoIcon";
import { useAlertManager } from "hooks/alert.hooks";
import { useCallback, useState } from "react";
import { useParams } from "react-router";
import { updateVehicleTeslaAsync } from "services/vehicle.services";
import { TeslaCommand, TeslaDataAccessorHook, TeslaManagementMode } from "types/vehicle.types";
import { handleApiErrorResponse } from "utils";
import VehicleDetailsTeslaKeySendSmsForm from "./VehicleDetailsTeslaKeySendSmsForm";

type Props = {
  teslaHook: TeslaDataAccessorHook;
};

function VehicleDetailsSystemStatusEditor({ teslaHook }: Props) {
  const { teslaDrivePin, isTeslaDrivePinLoading: isLoading, refetchDrivePin } = teslaHook;

  const isEnabled = teslaDrivePin?.drive?.managementMode === TeslaManagementMode.SYSTEM;

  const { vehicleId } = useParams<{ vehicleId: string }>();
  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleToggleSystemStatusAsync = useCallback(async () => {
    try {
      setIsSubmitting(true);

      await updateVehicleTeslaAsync(vehicleId, {
        command: isEnabled
          ? TeslaCommand.DISABLE_SYSTEM_MANAGED_DRIVE_PIN
          : TeslaCommand.ENABLE_SYSTEM_MANAGED_DRIVE_PIN
      });

      setIsConfirmDialogOpen(false);
      refetchDrivePin();
      handleOpenSuccessAlert("Vehicle settings successfully updated!");
    } catch (error) {
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  }, [handleOpenErrorAlert, handleOpenSuccessAlert, isEnabled, refetchDrivePin, vehicleId]);

  return (
    <>
     
      <AlertConfirmDialog
        isDialogOpen={isConfirmDialogOpen}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setIsConfirmDialogOpen(false)} />
            </div>

            <PrimaryButton
              text={isEnabled ? "Disable" : "Enable"}
              isLoading={isSubmitting}
              onClick={handleToggleSystemStatusAsync}
            />
          </>
        }
        toggleIsDialogOpen={() => setIsConfirmDialogOpen(false)}
        title={`Are you sure you want to ${isEnabled ? "disable" : "enable"} system manged PIN to drive?`}
        description={
          isEnabled
            ? "When disabled, the system will not automatically activate the PIN to Drive feature for your vehicle under specific conditions."
            : "When enabled, the system may automatically activate the PIN to Drive feature for your vehicle under specific conditions."
        }
      />

      <Row className="justify-between align-items-center">
        <Row className="h-100 align-items-center">
          <BlackBody2Text200>System managed</BlackBody2Text200>
          <TooltipWithInfoIcon
            description="When enabled, the system may automatically activate the PIN to Drive feature for your vehicle under specific conditions, such as failure to pay the driver invoice within 48 hours or not adding a payment method to your profile within 12 hours." />
        </Row>


        <IosSwitch
          onToggle={() => {
            setIsConfirmDialogOpen(true);
          }}
          label={isEnabled ? "Enabled" : "Disabled"}
          isChecked={isEnabled}
        />


      </Row>

      <BlackOverlineText200 className="mt-1">
        When enabled, the system can automatically assign a PIN to the drive due to non-payment of the driver's bill or
        in certain situations.
      </BlackOverlineText200>
    </>
  );
}

export default VehicleDetailsSystemStatusEditor;

/**
 *   paddingClass="py-3 my-1"
 isLoading={isLoading}
 leftTextOverwrite={
 <Row className="h-100">
 <BlackBody2Text200>System managed</BlackBody2Text200>
 <TooltipWithInfoIcon description="When enabled, the system may automatically activate the PIN to Drive feature for your vehicle under specific conditions, such as failure to pay the driver invoice within 48 hours or not adding a payment method to your profile within 12 hours." />
 </Row>
 }
 */
