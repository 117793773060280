import React from "react";

type Props = {};

function VehicleLocatorPlaceholderIcon({}: Props) {
  return (
    <svg width="64" height="51" viewBox="0 0 64 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M62.6121 22.0556L56.6235 15.6266L51.3111 3.34904C50.8549 2.49178 50.2154 1.78473 49.4541 1.296C48.6928 0.807274 47.8354 0.553344 46.9645 0.558676H17.0218C16.1509 0.553344 15.2935 0.807274 14.5322 1.296C13.771 1.78473 13.1314 2.49178 12.6753 3.34904L7.36283 15.6266L1.37428 22.0556C1.13032 22.3171 0.935749 22.634 0.802777 22.9864C0.669805 23.3388 0.601301 23.7193 0.601563 24.1037V47.9948C0.601563 48.7349 0.855972 49.4446 1.30882 49.9679C1.76168 50.4912 2.37587 50.7852 3.0163 50.7852H12.6753C13.6412 50.7852 15.09 49.669 15.09 48.5529V45.2044H48.8963V47.9948C48.8963 49.1109 49.8622 50.7852 50.8281 50.7852H60.97C61.6105 50.7852 62.2247 50.4912 62.6775 49.9679C63.1304 49.4446 63.3848 48.7349 63.3848 47.9948V24.1037C63.385 23.7193 63.3165 23.3388 63.1836 22.9864C63.0506 22.634 62.856 22.3171 62.6121 22.0556V22.0556ZM17.5047 6.1394H46.4816L51.3111 17.3008H12.6753L17.5047 6.1394ZM19.9195 31.8107C19.9195 32.9269 18.4706 34.043 17.5047 34.043H7.36283C6.39694 34.043 5.43104 32.3688 5.43104 31.2526V25.1138C5.91399 23.4396 6.87988 22.3235 8.32873 22.8816L17.9877 25.1138C18.9536 25.1138 19.9195 26.7881 19.9195 27.9042V31.8107ZM58.5553 31.2526C58.5553 32.3688 57.5894 34.043 56.6235 34.043H46.4816C45.5157 34.043 44.0669 32.9269 44.0669 31.8107V27.9042C44.0669 26.7881 45.0328 25.1138 45.9986 25.1138L55.6576 22.8816C57.1064 22.3235 58.0723 23.4396 58.5553 25.1138V31.2526Z"
        fill="#A0A8AF"
      />
    </svg>
  );
}

export default VehicleLocatorPlaceholderIcon;
