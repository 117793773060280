// Third party
import { useTranslation } from "react-i18next";

// Components
import Head from "../../layout/head/Head";
import Col from "../../components/custom/Col";
import ForgotPasswordFormFlow from "../../components/auth-components/ForgotPasswordFormFlow";
import BluedotLogoIcon from "../../components/icon/icons/BluedotLogoIcon";
import AuthSentCodeBanner from "../../assets/images/auth/AuthSentCodeBanner.webp";
import Row from "../../components/custom/Row";

// Img
import { AuthForgotPasswordFormFlowSteps } from "../../types/auth.types";
import { useMemo, useState } from "react";
import AuthFlowBackButton from "../../components/button/AuthFlowBackButton";
import ForgotPasswordFormFlowCompletedStep from "../../components/auth-components/form-components/ForgotPasswordFormFlowCompletedStep";
import Content from "../../layout/content/Content";

export default function ForgotPasswordPage() {
  const [formFlowStep, setFormFlowStep] = useState<AuthForgotPasswordFormFlowSteps>(
    AuthForgotPasswordFormFlowSteps.EMAIL
  );
  const { t } = useTranslation("common");

  const PageBanner = useMemo(() => {
    switch (formFlowStep) {
      case AuthForgotPasswordFormFlowSteps.EMAIL:
        return <BluedotLogoIcon />;

      case AuthForgotPasswordFormFlowSteps.PASSWORD:
        return <img width={400} src={AuthSentCodeBanner} alt="Auth Sent Code Banner" />;

      default:
        return <></>;
    }
  }, [formFlowStep]);

  if (formFlowStep === AuthForgotPasswordFormFlowSteps.COMPLETE) {
    return (
      <Content>
        <ForgotPasswordFormFlowCompletedStep />;
      </Content>
    );
  }

  return (
    <>
      <Head title={t("auth.forgot_password.title")}></Head>

      <Row className="screen-height-container">
        <Col className="bg-white scrollable-container">
          <ForgotPasswordFormFlow formFlowStep={formFlowStep} setFormFlowStep={setFormFlowStep} />
        </Col>
        <Col className="h-100 d-none d-lg-flex align-items-center justify-content-center">{PageBanner}</Col>
      </Row>
    </>
  );
}
