import React from "react";
import InfoIcon from "../icon/icons/InfoIcon";
import HoverUnderlinedTextButton from "./HoverUnderlinedTextButton";

type Props = {
  readonly onClick: () => void;
  readonly text?: string;
};

export default function NeedHelpButton({ onClick, ...props }: Props) {
  const text = props.text ?? "Need help?";
  return (
    <HoverUnderlinedTextButton text={text} icon={<InfoIcon />} data-testid="need-help-link-button"
                               className="center my-3 my-md-0 " onClick={onClick} />

  );
}
