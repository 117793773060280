import { useMemo } from "react";

import { DriverFormMode, DriverFormStatus } from "types/driver.types";
import AddDriverForm from "./AddDriverForm";

import DriverBulkUploadForm from "./DriverBulkUploadForm";

import { useAppSelector } from "hooks/redux.hooks";
import DriverFormStarter from "./AddDriverFormStarter";

export default function AddDriverFormLayout() {
  const { mode: formMode, status: formStatus } = useAppSelector((state) => state.driver?.driverNonFormState);

  const isFormModeBulkUpload = formMode === DriverFormMode.BULK;
  const isFormActive = formStatus === DriverFormStatus.ACTIVE;

  let jsx = <></>;
  jsx = useMemo(() => {
    if (isFormActive) {
      return isFormModeBulkUpload ? <DriverBulkUploadForm /> : <AddDriverForm />;
    } else {
      return <DriverFormStarter />;
    }
  }, [isFormActive, isFormModeBulkUpload]);

  return <div className="d-flex flex-1 flex-grow-1 h-100 w-100 justify-center align-center  flex-column">{jsx}</div>;
}
