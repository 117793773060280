import { styled, Switch } from "@mui/material";
import CancelButton from "components/button/CancelButton";
import YesButton from "components/button/YesButton";
import Card from "components/card/Card";
import CreditCardIcon from "components/icon/icons/CreditCardIcon";
import HorizontalLine from "components/line/HorizontalLine";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import AlertConfirmDialog from "components/notification/AlertConfirmDialog";
import {
  BlackBody2Text100,
  BlackH5Text100,
  BlackOverlineText300,
  BlackSubtitleText100,
  BodyText
} from "components/text/Text";
import { useAlertManager } from "hooks/alert.hooks";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { updateDriverAsync } from "services/driver.services";
import { Driver, DriverStatusCommands, DriverUpdateCommand } from "types/driver.types";
import { handleApiErrorResponse } from "utils";
import Row from "../custom/Row";

const IOSSwitch = styled((props: { defaultChecked?: boolean; checked?: boolean }) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#23D206" : "#23D206",
        opacity: 1,
        border: 0
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff"
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500
    })
  }
}));

type Props = {
  isLoading: boolean;
  driver?: Driver;
  refetchDriver: () => Promise<void>
};

export default function DriverDetailPageStatusCard({ driver, refetchDriver, isLoading }: Props) {
  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const isDriverActive = driver?.active;

  const handleToggleDriverStatusAsync = useCallback(async () => {
    if (!driver) {
      return;
    }
    try {
      setIsSubmitting(true);
      const payload: DriverStatusCommands = {
        command: isDriverActive ? DriverUpdateCommand.DISABLE_USER : DriverUpdateCommand.ENABLE_USER
      };
      await updateDriverAsync(driver.id, payload)
      setIsConfirmDialogOpen(false);
      handleOpenSuccessAlert("Driver status successfully updated!");
      refetchDriver();
    } catch (error) {
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  }, [driver, handleOpenErrorAlert, handleOpenSuccessAlert, isDriverActive, refetchDriver]);

  return (
    <>
      <AlertConfirmDialog
        isDialogOpen={isConfirmDialogOpen}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setIsConfirmDialogOpen(false)} />
            </div>

            <YesButton isLoading={isSubmitting} onYesButtonPressed={handleToggleDriverStatusAsync} />
          </>
        }
        toggleIsDialogOpen={() => setIsConfirmDialogOpen(false)}
        titleOverride={
          <BlackH5Text100>
            Are you sure you want to set this driver as{" "}
            <span className={isDriverActive ? "text-bd-cherry-500 " : "text-green-200"}>
              {isDriverActive ? "inactive" : "active"}
            </span>
            ?
          </BlackH5Text100>
        }
        description={
          isDriverActive
            ? "Once set to inactive, they will lose access to the driver app."
            : "Once set to active, they will regain full access to the driver app."
        }
      />

      <Card padding="p-2 p-md-4">
        {isLoading ? (
          <LoadingSkeleton count={3} height={40} />
        ) : (
          <>
            <Row className="justify-between">
              <Row className="center">
                <div className="w-10px h-10px bg-black-100 rounded-circle" />
                <BlackSubtitleText100 className="ml-1">Status</BlackSubtitleText100>
              </Row>

              <Row className="align-items-center  clickable" onClick={() => setIsConfirmDialogOpen(true)}>
                <BodyText className={`${isDriverActive ? "text-green-200" : "text-danger"} mr-1`}>
                  {isDriverActive ? "Active" : "Inactive"}
                </BodyText>
                <IOSSwitch checked={isDriverActive} />
              </Row>
            </Row>

            <BlackOverlineText300 className="mt-1">
              Toggle driver status. Remember, an inactive status means driver cannot access the Driver app.
            </BlackOverlineText300>

            <HorizontalLine />

            <Row className="justify-between align-items-center">
              <Row className="center">
                <CreditCardIcon />
                <BlackSubtitleText100 className="ml-1">Card status</BlackSubtitleText100>
              </Row>

              <BlackBody2Text100>{driver?.hasDefaultPaymentMethod ? "Added" : "Not added"}</BlackBody2Text100>
            </Row>

            <BlackOverlineText300 className="mt-1">
              Displays whether the driver has added their payment method.
            </BlackOverlineText300>
          </>
        )}
      </Card>
    </>
  );
}

/**
 *
 */
