import { useIntercomTrigger } from "hooks/intercom.hooks";

type Props = {};

function CustomerSupportLinkButton({}: Props) {
  const { triggerIntercom } = useIntercomTrigger();
  return (
    <span className="text-decoration-underline pointer-events-all clickable" onClick={triggerIntercom}>
      customer support
    </span>
  );
}

export default CustomerSupportLinkButton;
