import React from "react";
import { Link } from "react-router-dom";
import LinkButton from "./LinkButton";

type Props = {
  mailTo: string;
  label: string;
};

function OpenMailLink({ label, mailTo }: Props) {
  return (
    <LinkButton
      text={label}
      onClick={() => {
        window.open(`mailto:${mailTo}`);
      }}
    />
  );
}

export default OpenMailLink;
