import { SvgProps } from "types/svg.types";

type Props = {};

function SettingsUserIconOutlined(props: SvgProps) {
  return (

    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8952 5.90519C13.8952 8.05637 12.1513 9.80027 10.0002 9.80027C7.84898 9.80027 6.10508 8.05637 6.10508 5.90519C6.10508 3.75401 7.84898 2.01011 10.0002 2.01011C12.1513 2.01011 13.8952 3.75401 13.8952 5.90519Z"
        stroke="#6F7981" strokeWidth="1.3" />
      <path
        d="M2.46836 17.0492C2.46836 14.2705 4.721 12.0179 7.49978 12.0179H12.4994C15.278 12.0179 17.5307 14.2705 17.5307 17.0492V17.731C17.5307 17.874 17.4147 17.99 17.2717 17.99H2.72744C2.58431 17.99 2.46836 17.874 2.46836 17.731V17.0492Z"
        stroke="#6F7981" strokeWidth="1.3" />
    </svg>

  );
}

export default SettingsUserIconOutlined;
