import { styled, Switch } from "@mui/material";
import Row from "components/custom/Row";
import { BodyText } from "components/text/Text";

const IOSSwitch = styled((props: { defaultChecked?: boolean; checked?: boolean; disabled?: boolean }) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#23D206" : "#23D206",
        opacity: 1,
        border: 0
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff"
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500
    })
  }
}));

export type IosSwitchProps = {
  isChecked?: boolean;
  label?: string;
  onToggle: () => void;
  isDisabled?: boolean;
};

function IosSwitch({ isChecked, label, onToggle, isDisabled }: IosSwitchProps) {
  return (
    <Row
      className={`align-items-center  ${isDisabled ? "cursor-not-allowed" : "clickable"}`}
      onClick={isDisabled ? () => {
      } : onToggle}
    >
      {label && <BodyText className={`${isChecked ? "text-green-200" : "text-purple-100"} mr-2`}>{label}</BodyText>}
      <IOSSwitch checked={isChecked} disabled={isDisabled} />
    </Row>
  );
}

export default IosSwitch;
