import React, { useState } from "react";
import BaseModal from "../../modal/BaseModal";
import { BlackLinkText200 } from "../../text/Text";
import {
  useOldVehicleDriverSchedulesWithPaginationAsync
} from "../../../hooks/data/vehicle-driver-schedule-data-accessor.hooks";
import LoadingSkeleton from "../../loading/LoadingSkeleton";
import VehicleDetailsOldDriverAssignmentsModalContent from "./VehicleDetailsOldDriverAssignmentsModalContent";


function VehicleDetailsOldDriverAssignmentsModal(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const oldSchedulesHook = useOldVehicleDriverSchedulesWithPaginationAsync();
  const { isLoading, schedules } = oldSchedulesHook;

  const areThereAnyOldSchedule = (schedules && schedules?.length > 0);


  return (
    <div className={"mt-4"}>
      {
        areThereAnyOldSchedule &&
        <BaseModal
          className="wide-sm"
          isCloseIconVisible
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        >
          <VehicleDetailsOldDriverAssignmentsModalContent oldSchedulesHook={oldSchedulesHook} />
        </BaseModal>
      }


      {isLoading ?
        <LoadingSkeleton count={1} height={20} />
        :
        areThereAnyOldSchedule &&
        <BlackLinkText200
          onClick={() => setIsModalOpen(true)}>
          View previous assignments
        </BlackLinkText200>
      }
    </div>

  );
}

export default VehicleDetailsOldDriverAssignmentsModal;