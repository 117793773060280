import React from "react";
import TableCheckboxCheckedIcon from "../../icon/icons/TableCheckboxCheckedIcon";
import TableCheckboxUnCheckedIcon from "../../icon/icons/TableCheckboxUnCheckedIcon";
import TableCheckboxClearableIcon from "../../icon/icons/TableCheckboxClearableIcon";

type Props = {
  readonly isChecked?: boolean;
  readonly isClearable?: boolean
};

function TableCheckbox({ isChecked, isClearable }: Props) {
  return isChecked ? <TableCheckboxCheckedIcon /> : (isClearable ? <TableCheckboxClearableIcon /> :
    <TableCheckboxUnCheckedIcon />);
}

export default TableCheckbox;
