import React from "react";
import LoadingSkeleton from "./LoadingSkeleton";

type Props = {
  count?: number;
};

const LoadingSkeletonChargingListTable = (props: Props) => {
  const count = props.count ?? 20;
  const headerAndPaginationHeight = 95;
  const rowHeight = 46;
  return (
    <div className="w-100 d-flex align-items-center overflow-hidden" style={{ height: count * rowHeight + headerAndPaginationHeight }}>
      <LoadingSkeleton count={count} height={rowHeight} />
    </div>
  );
};

export default LoadingSkeletonChargingListTable;
