import React from "react";

type Props = {};

function SettingsLogoutIcon({}: Props) {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.0013 23.8327C7.01805 23.8327 2.16797 18.9826 2.16797 12.9993C2.16797 7.0161 7.01805 2.16602 13.0013 2.16602C18.9846 2.16602 23.8346 7.0161 23.8346 12.9993C23.8346 18.9826 18.9846 23.8327 13.0013 23.8327ZM18.418 17.3327L23.8346 12.9993L18.418 8.66602V11.916H9.7513V14.0827H18.418V17.3327Z"
        fill="#0C0C0C"
      />
    </svg>
  );
}

export default SettingsLogoutIcon;
