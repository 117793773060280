import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { Icon } from "../Component";
import ButtonWithIcon from "./ButtonWithIcon";

export default function DownloadTemplateButton(props) {
  const widthClass = props.widthClass ?? "";

  /**
   * Render
   */
  return <ButtonWithIcon className={`mt-md-0 ${widthClass}`} text={"Download Template"} isLoading={props.isLoading} />;
}
