import React from "react";
import CounterCardWithIcon from "../card/CounterCardWithIcon";

import { useSelector } from "react-redux";
import FleetGroupsIcon from "../icon/icons/FleetGroupsIcon";

export default function FleetGroupsPageHeaderCard() {
  const fleetGroupsCount = useSelector((state) => state.fleetGroups?.fleetGroups?.length) ?? 0;

  return <CounterCardWithIcon icon={<FleetGroupsIcon fill={'#1E44FF'} />} count={fleetGroupsCount} description={"Groups"} />;
}
