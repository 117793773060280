// React
import { useCallback, useState } from "react";
// Third Party
// Services && Hooks && Utils
import { useTeslaConnection } from "hooks/vehicle.hooks";

// Types
// Components
import Permissions from "assets/images/vehicle/Permissions.png";
import SignInPanelTesla from "assets/images/vehicle/SignInPanelTesla.png";
import CircleBadge from "components/badge/CircleBadge";
import NextButton from "components/button/NextButton";
import Card from "components/card/Card";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import TeslaIconWithCircleBackground from "components/icon/icons/TeslaIconWithCircleBackground";
import { BlackBodyText200, BlackCaptionText300, BlackSubtitleText0 } from "components/text/Text";
import Skeleton from "react-loading-skeleton";
import AddVehicleFormStarterNeedHelpButtonAndModal from "./AddVehicleFlowSelectionNeedHelpButtonAndModal";
import AddVehicleStarterWarningCard from "./AddVehicleStarterWarningCard";
import AddVehicleTeslaFlowStarterGuide from "./AddVehicleTeslaFlowStarterGuide";

type Props = {};

const componentContext = "AddVehicleFormStarter";

export default function AddVehicleTeslaFlowStarter(props: Props) {
  const [loadedImages, setLoadedImages] = useState<string[]>([]);

  const { handleOpenTeslaConnectionFlow } = useTeslaConnection();

  const handleLoadImage = useCallback(
    (loadedImage) => {
      if (loadedImages.includes(loadedImage)) {
        return;
      }
      setLoadedImages((li) => [...li, loadedImage]);
    },
    [loadedImages]
  );

  const areAllImagesLoaded = loadedImages.length === 2;

  const renderImage = useCallback(
    (img: JSX.Element) => {
      return (
        <div className="center h-175px w-100">
          <>
            <div className={`${areAllImagesLoaded ? "d-block" : "d-none"}`}>{img}</div>

            {!areAllImagesLoaded && <Skeleton containerClassName="w-80" count={5} />}
          </>
        </div>
      );
    },
    [areAllImagesLoaded]
  );


  return (
    <div className="w-100 center">
      <Row className="mt-2">

        <Col md={"8"}  className={`pr-3 `}>
          <Card padding="p-4" className="w-100">

            <Row className="mb-3">
              <Col md="4" className="center flex-column h-200px justify-between">
                <TeslaIconWithCircleBackground width={60} height={60} />

                <Row className="w-100 center">
                  <CircleBadge className="h-20px w-20px bg-blue-100 text-white mr-2">1</CircleBadge>
                  <BlackSubtitleText0>Select Vehicle</BlackSubtitleText0>
                </Row>
              </Col>
              <Col md="4" className="center flex-column  mt-4 mt-md-0  h-200px justify-between">
                {renderImage(
                  <img
                    className="w-140px"
                    src={SignInPanelTesla}
                    alt="SignInPanelTesla"
                    onLoad={() => handleLoadImage("SignInPanelTesla")}
                  />
                )}

                <Row className="w-100 center">
                  <CircleBadge className="h-20px w-20px bg-blue-100 text-white mr-2">2</CircleBadge>
                  <BlackSubtitleText0>Sign in</BlackSubtitleText0>
                </Row>
              </Col>
              <Col md="4" className="center flex-column h-200px mt-3 mt-md-0 justify-between">
                {renderImage(
                  <img
                    className="w-140px"
                    src={Permissions}
                    alt="Permissions"
                    onLoad={() => handleLoadImage("Permissions")}
                  />
                )}

                <Row className="w-100 center">
                  <CircleBadge className="h-20px w-20px bg-blue-100 text-white mr-2">3</CircleBadge>
                  <BlackSubtitleText0>Grant Permissions</BlackSubtitleText0>
                </Row>
              </Col>
            </Row>

            <AddVehicleStarterWarningCard />
            <BlackBodyText200 className={"mt-3"}>
              By connecting your EV, you'll effortlessly track charging sessions, keep an eye on battery levels, and
              monitor
              its mileage. Let's get started!
            </BlackBodyText200>

            <Row className="mt-4 center">
              <Col md="6" className="d-flex justify-content-start ">
                <AddVehicleFormStarterNeedHelpButtonAndModal />
              </Col>
              <Col md="6">
                <Row className="center">
                  <Col className="mr-3">
                    <BlackCaptionText300 className="text-right">Next: Select your vehicle</BlackCaptionText300>
                  </Col>

                  <NextButton onNextButtonPressed={handleOpenTeslaConnectionFlow} />
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md={"4"} className={`pl-1`}>
          <AddVehicleTeslaFlowStarterGuide />
        </Col>
      </Row>
    </div>
  );
}
