// Third party
import { useTranslation } from "react-i18next";
import Row from "../../components/custom/Row";

// Components
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import Col from "../../components/custom/Col";
import LoginForm from "../../components/auth-components/LoginForm";

// Img
import AuthChargingSessionsOverviewBannerImage from "../../assets/images/auth/AuthChargingSessionsOverviewBannerImage.png";

export default function LoginPage() {
  const { t } = useTranslation("common");

  return (
    <>
      <Head title={t("auth.sign_in.title")}></Head>

      <Row className="screen-height-container">
        <Col className="bg-white scrollable-container">
          <LoginForm />
        </Col>
        <Col lg="6" className="h-100 d-none d-lg-flex align-items-center justify-content-end">
          <img
            className="h-max-90 rounded-xxl"
            src={AuthChargingSessionsOverviewBannerImage}
            alt="Auth Charging Session Overview Banner"
          />
        </Col>
      </Row>
    </>
  );
}
