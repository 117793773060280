import React from "react";
import { SvgProps } from "types/svg.types";

type Props = {};

function ChevronBottomIcon(props: SvgProps) {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11 1.5L6 6.5L1 1.5"
        stroke="#6F7981"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ChevronBottomIcon;
