import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "@sendbird/uikit-react/dist/index.css";
import { Amplify } from "aws-amplify";
import AlertBox from "components/notification/AlertBox";

import { ThemeProvider } from "@mui/material";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { PersistGate } from "redux-persist/integration/react";
import { MuiTheme } from "utils/mui.utils";
import App from "./App";
import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
import "./i18n";
import LoadingLogo from "./images/loading-logo.svg";
import { persistor, store } from "./redux/store";


const Error404Modern = lazy(() => import("./pages/error/404-modern"));

Amplify.configure({
  Auth: {
    // (required)- Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_POOL_ID,

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: process.env.REACT_APP_POOL_CLIENT_ID
  }
});

//if (process.env.NODE_ENV !== "development") console.log = () => { };

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
ReactDOM.render(
  <React.Fragment>
    <Suspense
      fallback={
        <div
          className="nk-block"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
            padding: 5
          }}
        >
          <img className="loading-animation" src={LoadingLogo} alt="Bluedot for Fleets Logo" width={400} />
        </div>
      }
    >
      <Router basename={`/`}>
        <ThemeProvider theme={MuiTheme}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Authenticator.Provider>
                <IntercomProvider appId={INTERCOM_APP_ID}>
                  <AlertBox />
                  <Route
                    render={({ location }) => (location.state && location.state.is404 ? <Error404Modern /> : <App />)}
                  />
                </IntercomProvider>
              </Authenticator.Provider>
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </Router>
    </Suspense>
  </React.Fragment>,
  document.getElementById("root")
);
