import React from "react";
import ButtonWithIcon from "./ButtonWithIcon";

type Props = {
  readonly onGetStartedButtonPressed: () => void;
};

function GetStartedButton({ onGetStartedButtonPressed }: Props) {
  return (
    <ButtonWithIcon className="w-175px mt-md-0 fs-17px" text={"Get Started"} onClick={onGetStartedButtonPressed} />
  );
}

export default GetStartedButton;
