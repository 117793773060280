import React from "react";

const BulbOutlineIcon = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_33885_83635)">
        <path
          d="M8.90627 11.75V11.0469C8.90627 10.1973 9.83029 9.39367 10.4297 8.82033C11.2746 8.01261 11.7188 6.92746 11.7188 5.65627C11.7188 3.31252 9.85168 1.43752 7.50002 1.43752C6.94557 1.43597 6.39628 1.54404 5.88374 1.7555C5.3712 1.96696 4.90552 2.27766 4.51346 2.66971C4.12141 3.06177 3.81071 3.52745 3.59925 4.03999C3.38779 4.55253 3.27972 5.10182 3.28127 5.65627C3.28127 6.88205 3.74445 8.04074 4.57033 8.82033C5.16652 9.38312 6.09377 10.1885 6.09377 11.0469V11.75M6.56252 14.5625H8.43752M6.09377 13.1563H8.90627M7.50002 11.75V8.00002"
          stroke="#BE8DFF" strokeWidth="1.07269" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.61719 7.53125C8.61719 7.53125 7.98701 8 7.50391 8C7.0208 8 6.39062 7.53125 6.39062 7.53125"
              stroke="#BE8DFF" strokeWidth="1.07269" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_33885_83635">
          <rect width="15" height="15" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default BulbOutlineIcon;