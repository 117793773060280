import React from "react";
import { useParams } from "react-router";
import Content from "../../layout/content/Content";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Head from "../../layout/head/Head";

import FleetGroupsPageHeader from "../../components/fleet-groups-components/FleetGroupsPageHeader";
import FleetGroupsMutationPagesContent from "../../components/fleet-groups-components/FleetGroupsMutationPagesContent";

export default function EditFleetGroupPage() {
  const { t } = useTranslation("common");

  return (
    <>
      <Head title={t("fleet_groups.edit_fleet_group_page.title")}></Head>
      <Content>
        <FleetGroupsPageHeader
          title={t("fleet_groups.edit_fleet_group_page.page_header")}
          description={t("fleet_groups.edit_fleet_group_page.page_description")}
        />

        <FleetGroupsMutationPagesContent isEditMode={true} />
      </Content>
    </>
  );
}
