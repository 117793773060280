import { SvgProps } from "types/svg.types";

const ReimbursementMenuIconFilled = (props: SvgProps) => {
  return (
    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5096 10.2581L11.1905 12.2543C11.0788 12.3219 10.9494 12.3572 10.8171 12.3572H6.66603C6.30736 12.3572 6.01631 12.0662 6.01631 11.7075C6.01631 11.3488 6.30736 11.0578 6.66603 11.0578H10.0116C10.5878 11.0578 11.0464 10.5786 11.0171 9.99648C10.9877 9.4526 10.4996 9.04102 9.95575 9.04102H6.01044C4.93738 9.04102 3.89666 9.41144 3.06174 10.0906L2.16213 10.8226C1.97692 10.9725 1.74467 11.0548 1.50654 11.0578H0.77157C0.448183 11.0578 0.183594 11.3224 0.183594 11.6458V14.2622C0.183594 14.5856 0.448183 14.8502 0.77157 14.8502H10.0939C10.6966 14.8502 11.2905 14.6856 11.8079 14.374L15.6091 12.0867C16.2265 11.7163 16.3059 10.8549 15.7708 10.3757C15.421 10.0641 14.9095 10.0141 14.5067 10.2552L14.5096 10.2581Z"
        fill="#0C0C0C" />
      <path
        d="M11.8521 0.0654383C9.49724 0.0654383 7.53929 1.77645 7.1424 4.01958H5.6313C5.28146 4.01958 5.09919 4.43704 5.34026 4.69281L7.62454 7.13879C7.88031 7.4122 8.31247 7.41514 8.57118 7.14467L10.8996 4.69575C11.1406 4.43998 10.9613 4.01958 10.6085 4.01958H9.25324C9.60602 2.92006 10.6379 2.12335 11.8521 2.12335C13.2544 2.12335 14.4127 3.18759 14.5627 4.54875C14.5832 4.72221 14.7273 4.85156 14.9037 4.85156H16.2795C16.4824 4.85156 16.6412 4.67811 16.6235 4.47526C16.4324 2.01164 14.3657 0.0625 11.8521 0.0625V0.0654383Z"
        fill="#0C0C0C" />
    </svg>
  );
};
export default ReimbursementMenuIconFilled;
