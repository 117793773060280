import React from "react";
import MessageIcon from "./MessageIcon";

type Props = {};

function MessageMenuIconFilled({}: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.1229 2.99805H3.923C2.87875 2.99805 2.03207 3.85179 2.03207 4.89956L2.02148 21.9991L5.02016 19.0004H20.1194C21.1636 19.0004 22.0209 18.1467 22.0209 17.0989V4.89956C22.0209 3.85532 21.1672 2.99805 20.1194 2.99805H20.1229ZM17.0219 14.9998H7.02046C6.46658 14.9998 6.02207 14.5518 6.02207 14.0014C6.02207 13.4511 6.47011 13.003 7.02046 13.003H17.0219C17.5758 13.003 18.0203 13.4511 18.0203 14.0014C18.0203 14.5518 17.5723 14.9998 17.0219 14.9998ZM17.0219 12.0011H7.02046C6.46658 12.0011 6.02207 11.5531 6.02207 11.0028C6.02207 10.4524 6.47011 10.0044 7.02046 10.0044H17.0219C17.5758 10.0044 18.0203 10.4524 18.0203 11.0028C18.0203 11.5531 17.5723 12.0011 17.0219 12.0011ZM17.0219 9.00246H7.02046C6.46658 9.00246 6.02207 8.55442 6.02207 8.00407C6.02207 7.45373 6.47011 7.00569 7.02046 7.00569H17.0219C17.5758 7.00569 18.0203 7.45373 18.0203 8.00407C18.0203 8.55442 17.5723 9.00246 17.0219 9.00246Z"
        fill="#0C0C0C"
      />
    </svg>
  );
}

export default MessageMenuIconFilled;
