import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import SendbirdDataProvider from "wrappers/SendBirdProvider";
import DriverSupportMessageRoom from "./DriverSupportMessageRoom";
import DriverSupportPage from "./DriverSupportPage";

type Props = {};

function DriverSupportNavigator({}: Props) {
  const { path } = useRouteMatch();
  return (
    <SendbirdDataProvider>
      <Switch>
        <Route path={`${path}/message-room/:messageRoomId`} component={DriverSupportMessageRoom} />
        <Route path={path} component={DriverSupportPage} />
      </Switch>
    </SendbirdDataProvider>
  );
}

export default DriverSupportNavigator;
