import React from "react";

const ChargingSessionMenuIconFilled = ({ fill, width, height }) => {
  const fillValue = fill ?? "#680031";
  const widthValue = width ?? 22;
  const heightValue = height ?? 22;

  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.14211 8.38605L6.88266 7.21127C7.76815 6.26581 8.90412 5.57788 10.1671 5.23568C11.7864 4.79822 13.508 4.95345 15.0214 5.68019C16.5349 6.4034 17.7379 7.6452 18.4152 9.18335C19.089 10.718 19.1914 12.4466 18.7045 14.0518C18.2141 15.657 17.1664 17.0328 15.7482 17.9289C14.33 18.825 12.6366 19.1848 10.9785 18.9414C9.32041 18.698 7.7999 17.8654 6.70274 16.5989C5.64791 15.3818 5.04463 13.8401 4.99171 12.2349C4.98819 12.1256 4.90001 12.0339 4.78711 12.0339L2.19766 12.0444C2.08829 12.0444 1.99657 12.1362 2.0001 12.2455C2.05654 14.5669 2.91734 16.8 4.44137 18.5569C6.00774 20.3631 8.17386 21.552 10.541 21.9013C12.9082 22.2505 15.3248 21.7355 17.3463 20.4584C19.3677 19.1778 20.8636 17.2163 21.5621 14.9267C22.2606 12.6371 22.116 10.1747 21.1528 7.98388C20.1897 5.79308 18.4717 4.01856 16.3126 2.98843C14.1536 1.95477 11.6946 1.73251 9.3839 2.35694C7.58117 2.84379 5.95836 3.82806 4.69539 5.1757L3.60176 4.15615C3.54531 4.10324 3.4818 4.06796 3.41124 4.05032C3.34068 4.03268 3.27014 4.03621 3.20311 4.05737C3.13608 4.07854 3.08316 4.12087 3.04788 4.17379C3.00908 4.23023 2.99143 4.29374 2.99143 4.3643L3.00906 8.89406C3.00906 9.12337 3.22074 9.32446 3.45357 9.30682L7.97629 9.01401C8.04685 9.01048 8.11036 8.98579 8.16328 8.94345C8.21619 8.90112 8.25147 8.84467 8.26911 8.77764C8.28675 8.71061 8.28321 8.64006 8.26204 8.57303C8.24088 8.50247 8.19854 8.43897 8.14562 8.38958L8.14211 8.38605Z"
        fill="#0C0C0C"
      />
      <path
        d="M14.171 6.74982C14.1463 6.6828 14.0969 6.62635 14.0334 6.59107C13.9699 6.55579 13.8993 6.54168 13.8288 6.55227C13.7582 6.56285 13.6912 6.59813 13.6418 6.65105L8.55111 12.0593C8.50877 12.1051 8.48056 12.1616 8.46997 12.2215C8.45939 12.2815 8.46997 12.345 8.49114 12.4015C8.51583 12.4579 8.55815 12.5073 8.60754 12.5426C8.66046 12.5778 8.72043 12.5955 8.78394 12.5955H10.8971L9.75059 16.3209C9.72942 16.3879 9.73293 16.462 9.75763 16.529C9.78232 16.5961 9.83172 16.6525 9.89522 16.6878C9.95872 16.7231 10.0293 16.7372 10.0998 16.7266C10.1704 16.716 10.2374 16.6843 10.2868 16.6314L15.3775 11.2232C15.4198 11.1773 15.4481 11.1208 15.4587 11.0609C15.4692 11.0009 15.4587 10.9374 15.4375 10.8809C15.4128 10.8245 15.3705 10.7751 15.3211 10.7434C15.2681 10.7081 15.2082 10.6904 15.1447 10.6904H13.0315L14.178 6.96502C14.1992 6.89799 14.1957 6.82391 14.171 6.75688V6.74982Z"
        fill="#0C0C0C"
      />
    </svg>
  );
};
export default ChargingSessionMenuIconFilled;

/**
 *   width={`${widthValue}`}
      height={`${heightValue}`}
      viewBox={`0 0 ${widthValue} ${heightValue} `}
 */
