import Smartcar from "@smartcar/auth";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { addVehicleDriverScheduleAsync } from "services/vehicle-driver-schedule.services";
import { VehicleDriverScheduleAddPayload } from "types/vehicle-driver-schedule.types";
import { isDateExpired } from "utils/date.utils";
import { getVehicleDriverSchedulePeriodsForPayload } from "utils/vehicle-driver-schedule.utils";
import { SmartcarExtended } from "../types/smartcar";
import {
  SmartCarSelectType,
  SmartCarState,
  TeslaAuthState,
  VehicleFormVehicleSelectorState
} from "../types/vehicle.types";
import { getSmartCarPermissionScopes, getTeslaPermissionScopes } from "../utils/vehicle.utils";
import { useAppDispatch, useAppSelector } from "./redux.hooks";
import { useVehicleDriverSchedulePeriodSelector } from "./vehicle-driver-schedule.hooks";
import { setVehicleHelperMessage } from "../redux/slices/vehicleSlice";

function useDebounce(value: number | string | any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [delay, value]);

  return debouncedValue;
}


const appUrl = process.env.REACT_APP_APP_URL;

// Smartcar 
const smartcarClientId = process.env.REACT_APP_SMARTCAR_CLIENT_ID;
const smartcarMode = process.env.REACT_APP_SMARTCAR_MODE;
const isSmartCarAuthFlowFlagActive = process.env.REACT_APP_SMARTCAR_NEW_TESLA_AUTH_FLOW?.toLowerCase?.() === "true";
const isSmartCarAccountSyncActive = process.env.REACT_APP_SMARTCAR_ACCOUNT_SYNC?.toLowerCase?.() === "true";

// Tesla 
const teslaApiBaseUrl = "https://auth.tesla.com/oauth2/v3/authorize";
const teslaClientId = process.env.REACT_APP_TESLA_CLIENT_ID;
const teslaRedirectUri = appUrl + "/" + process.env.REACT_APP_TESLA_REDIRECT_URI;

const useSmartCar = () => {
  const scopes = getSmartCarPermissionScopes();
  const redirectUri = `https://javascript-sdk.smartcar.com/v2/redirect?app_origin=${appUrl}`;
  const user = useAppSelector((state) => state.user.currentUser);

  const history = useHistory();

  const handleAppendSmartCarAuthUrlByFlags = useCallback((authUrl: URL, isSingleSelect?: boolean) => {
    const flags: Array<string> = [];

    if (isSmartCarAuthFlowFlagActive) {
      flags.push("tesla_auth:true");
    }

    if (isSmartCarAccountSyncActive && !isSingleSelect) {
      flags.push("account_sync:true");
    }

    if (flags.length > 0) {
      authUrl.searchParams.append("flags", flags.join(" "));
    }
  }, []);

  /**
   *
   */
  const handleAppendSmartCarAuthUrlByRememberCred = useCallback((authUrl: URL) => {
    authUrl.searchParams.append("remember_creds", "true");
  }, []);

  /**
   *
   */
  const handleAppendSmartCarAuthUrlByExtraParams = useCallback(
    (authUrl: URL, extraParams: URLSearchParams | undefined) => {
      if (extraParams) {
        extraParams?.forEach((value, key) => authUrl.searchParams.append(key, value));
      }
    },
    []
  );

  /**
   *
   */
  const handleSmartCarAuthCompleted = useCallback(
    (err: Error, code: string, status: string) => {
      if (err || !code || !status) {
        // This route will handle errors.
        history.push(`/smartcar/exchange`);
      }

      const queryParams = new URLSearchParams({
        code: code,
        state: status
      }).toString();
      history.push(`/smartcar/exchange?${queryParams}`);
    },
    [history]
  );

  const smartcarInstance: SmartcarExtended = useMemo(() => {
    if (!smartcarClientId || !redirectUri) {
      return;
    }
    return new Smartcar({
      clientId: smartcarClientId,
      redirectUri,
      scope: scopes,
      mode: smartcarMode,
      onComplete: handleSmartCarAuthCompleted,
      rememberCreds: true
    });
  }, [handleSmartCarAuthCompleted, redirectUri, scopes]);

  const handleSmartCarOpenDialog = useCallback(
    (params?: { extraParams?: URLSearchParams; isSingleSelect?: boolean }) => {
      if (!smartcarInstance || !user?.id) {
        return;
      }

      const stateObject: SmartCarState = {
        organizationId: user.id,
        selectType: SmartCarSelectType.BRAND,
        permissions: scopes,
        redirectUri
      };

      const encodedStateObject = encodeURIComponent(JSON.stringify(stateObject));
      // smartcarInstance.openDialog({ forcePrompt: true, state: encodedStateObject, });

      const baseAuthUrl = smartcarInstance.getAuthUrl({ forcePrompt: true, state: encodedStateObject });
      if (!baseAuthUrl) {
        return;
      }

      const authUrl = new URL(baseAuthUrl);

      handleAppendSmartCarAuthUrlByFlags(authUrl, params?.isSingleSelect);
      handleAppendSmartCarAuthUrlByRememberCred(authUrl);
      handleAppendSmartCarAuthUrlByExtraParams(authUrl, params?.extraParams);


      const popUpPositionLeft = (window.screen.width / 2) - (500 / 2);
      const popUpPositionTop = (window.screen.height / 2) - (800 / 2);

      const windowOptions = `width=500,height=800,top=${popUpPositionTop},left=${popUpPositionLeft}`;
      const windowTitle = "Connect your car";
      const windowUrl = authUrl.toString();

      window.open(windowUrl, windowTitle, windowOptions);
    },
    [handleAppendSmartCarAuthUrlByExtraParams, handleAppendSmartCarAuthUrlByFlags, handleAppendSmartCarAuthUrlByRememberCred, redirectUri, scopes, smartcarInstance, user?.id]
  );

  return { smartcarInstance, handleSmartCarOpenDialog };
};


export const useTeslaConnection = () => {
  const eventDataName = "TeslaAuthMessage";
  const user = useAppSelector((state) => state.user.currentUser);
  const teslaScopes = getTeslaPermissionScopes();

  const history = useHistory();


  useEffect(() => {
    function receiveMessage(event: MessageEvent) {


      if (!event?.origin || !appUrl || !event.origin.includes(appUrl)) {
        return;
      }

      const eventData = event.data;

      if (eventData && eventData.name === eventDataName) {

        const code = eventData.code;
        const state = eventData.state;
        console.log("event data , ", eventData);
        console.log("state", state);
        if (code && state) {
          history.push(`/tesla/exchange?code=${code}&state=${state}`);
        }

      }
    }

    window.addEventListener("message", receiveMessage, false);


    return () => {
      window.removeEventListener("message", receiveMessage, false);
    };
  }, [history]);


  /**
   *
   */
  const handleSwitchTab = useCallback(
    () => {
      if (!window.opener) {
        console.error("window.opener must be defined");
        window.close();
        return;
      }

      const params = new URLSearchParams(window.location.search);

      const message = {
        name: eventDataName,
        code: params.get("code") ?? "code",
        state: params.get("state") ?? "state",
        error: params.get("error"),
        errorDescription: params.get("error_description")
      };


      const targetOrigin = `${appUrl}/add-vehicle/tesla`;


      window.opener.postMessage(message, targetOrigin);
      window.close();
    },
    []
  );


  /**
   *
   */
  const handleSmartCarAuthCompleted = useCallback(
    (err: Error, code: string, status: string) => {
      if (err || !code || !status) {
        // This route will handle errors.
        history.push(`/smartcar/exchange`);
      }

      const queryParams = new URLSearchParams({
        code: code,
        state: status
      }).toString();
      history.push(`/smartcar/exchange?${queryParams}`);
    },
    [history]
  );


  const handleOpenTeslaConnectionFlow = useCallback(
    (params?: { extraParams?: URLSearchParams; isSingleSelect?: boolean }) => {
      if (!user?.id) {
        return;
      }


      //?&client_id=$CLIENT_ID&locale=en-US&prompt=login&redirect_uri=$REDIRECT_URI&response_type=code&scope=openid%20vehicle_device_data%20offline_access&state=$STATE


      const state: TeslaAuthState = {
        organizationId: user.id,
        scopes: teslaScopes,
        redirectUri: teslaRedirectUri ?? ""
      };

      //https://auth.tesla.com/oauth2/v3/authorize?client_id=d9d725a8-7241-4eb6-b614-cf398a584088&prompt=login&locale=en-US&response_type=code&scope%5B0%5D=openid&scope%5B1%5D=offline_access&scope%5B2%5D=user_data&scope%5B3%5D=vehicle_device_data&scope%5B4%5D=vehicle_cmds&scope%5B5%5D=vehicle_charging_cmds&scope%5B6%5D=energy_device_data&scope%5B7%5D=energy_cmds&state%5BorganizationId%5D=a46949ce-7b91-4ffd-a757-1cc60d782cd8&state%5Bscopes%5D%5B0%5D=openid&state%5Bscopes%5D%5B1%5D=offline_access&state%5Bscopes%5D%5B2%5D=user_data&state%5Bscopes%5D%5B3%5D=vehicle_device_data&state%5Bscopes%5D%5B4%5D=vehicle_cmds&state%5Bscopes%5D%5B5%5D=vehicle_charging_cmds&state%5Bscopes%5D%5B6%5D=energy_device_data&state%5Bscopes%5D%5B7%5D=energy_cmds&state%5BredirectUri%5D=


      const encodedStateObject = encodeURIComponent(JSON.stringify(state));
      const query = qs.stringify({
        client_id: teslaClientId,
        prompt: "login",
        locale: "en-US",
        redirect_uri: teslaRedirectUri,
        response_type: "code",
        scope: teslaScopes.join(" "),
        state: encodedStateObject
      }, {
        addQueryPrefix: true
      });

      //http://localhost:3000/tesla/auth?locale=en-US&code=NA_e3d8013f5ab7ce9948e10c1d64aeb513bf57041f0f8e178600fa99c97347&state=&issuer=https%3A%2F%2Fauth.tesla.com%2Foauth2%2Fv3


      const authUrl = teslaApiBaseUrl + query;
      const popUpPositionLeft = (window.screen.width / 2) - (500 / 2);
      const popUpPositionTop = (window.screen.height / 2) - (800 / 2);

      const windowOptions = `width=500,height=800,top=${popUpPositionTop},left=${popUpPositionLeft}`;
      const windowTitle = "Connect your car";
      const windowUrl = authUrl;

      window.open(windowUrl, windowTitle, windowOptions);
    },
    [teslaScopes, user?.id]
  );

  return { handleOpenTeslaConnectionFlow, handleSwitchTab };
};

export { useDebounce, useSmartCar };

const storageKey = "addVehicleInformFlagExpiryDate";

export const useVehicleAdditionStatus = () => {
  const areVehiclesBeingAdded = useMemo(() => {
    const addVehicleInformFlagExpiryDateString = sessionStorage.getItem(storageKey);

    if (!addVehicleInformFlagExpiryDateString) {
      return false;
    }

    try {
      if (!isDateExpired(new Date(addVehicleInformFlagExpiryDateString))) {
        return true;
      }
      sessionStorage.removeItem(storageKey);
      throw Error("Date is expired!");
    } catch (error) {
      console.error(error);
      return false;
    }
  }, []);

  const handleSetExpiryTimeForVehiclesAddition = useCallback(() => {
    const fiveMin = 5 * 60000;
    const now = new Date();
    const fiveMinutesLater = new Date(now.getTime() + fiveMin);
    sessionStorage.setItem(storageKey, fiveMinutesLater.toISOString());
  }, []);


  return { areVehiclesBeingAdded, handleSetExpiryTimeForVehiclesAddition };
};


export const useVehicleFormDriverSelector = () => {
  const [driverSelector, setDriverSelector] = useState<VehicleFormVehicleSelectorState | undefined>(undefined);

  const driverId = driverSelector?.value;
  const isDriverSelected = !!driverId;

  const handleSetDriverSelector = setDriverSelector;

  const handleResetState = useCallback(() => {
    setDriverSelector(undefined);
  }, []);

  return {
    driverId,
    isDriverSelected,
    driverSelector,
    handleSetDriverSelector,
    handleResetState
  };
};

export const useVehicleFormDriverSetupStates = () => {
  const vehicleFormDriverSelector = useVehicleFormDriverSelector();
  const { driverId } = vehicleFormDriverSelector;
  const vehicleDriverSchedulePeriodSelectorHooks = useVehicleDriverSchedulePeriodSelector();
  const { vehicleDriverSchedulePeriodSelector } = vehicleDriverSchedulePeriodSelectorHooks;


  const handleSubmitAddScheduleAsync = useCallback(
    async (vehicleId: string) => {
      if (!vehicleId) {
        throw Error("Vehicle id is missing");
      }

      if (!driverId) {
        throw Error("Please select a driver");
      }

      const { startsAt, endsAt } = vehicleDriverSchedulePeriodSelector;

      const payload: VehicleDriverScheduleAddPayload = {
        ...getVehicleDriverSchedulePeriodsForPayload(startsAt, endsAt),
        driverId,
        vehicleId
      };

      return addVehicleDriverScheduleAsync(payload);
    },
    [vehicleDriverSchedulePeriodSelector, driverId]
  );

  const handleResetState = useCallback(() => {
    vehicleFormDriverSelector.handleResetState();
    vehicleDriverSchedulePeriodSelectorHooks.handleResetState();
  }, [vehicleFormDriverSelector, vehicleDriverSchedulePeriodSelectorHooks]);

  return {
    ...vehicleDriverSchedulePeriodSelectorHooks,
    ...vehicleFormDriverSelector,
    handleSubmitAddScheduleAsync,
    handleResetState
  };
};


export const useAddVehicleCompletedHandlers = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const helperMessage = useAppSelector(state => state.vehicle.helperMessage);

  const handleForwardCompletedPage = useCallback((message: string) => {
    dispatch(setVehicleHelperMessage(message));
    history.replace("/add-vehicle/completed");
  }, [dispatch, history]);

  const handleResetHelperMessage = useCallback(() => {
    dispatch(setVehicleHelperMessage(null));
  }, [dispatch]);


  return { helperMessage, handleResetHelperMessage, handleForwardCompletedPage };
};

export const useVehicleIdFromParams = () => {
  return useParams<{ vehicleId: string }>();
};