import React from "react";
import { SvgProps } from "types/svg.types";

function PasswordEyeHideIcon(props: SvgProps) {
  return (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M2.76074 0.989746L3.90659 0.000124343L15.5216 13.4487L14.3757 14.4383L2.76074 0.989746Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.63646 2.01078C5.09021 2.23416 4.56072 2.50199 4.05352 2.81272C2.32405 3.87229 0.921192 5.3894 0 7.19638C0.921192 9.00337 2.32405 10.5205 4.05352 11.58C5.783 12.6396 7.77176 13.2004 9.8 13.2004C11.4011 13.2004 12.9776 12.8509 14.4214 12.1825L12.5903 10.0624C11.844 10.789 10.843 11.1964 9.8 11.1964C8.73913 11.1964 7.72172 10.775 6.97157 10.0248C6.22143 9.27467 5.8 8.25725 5.8 7.19638C5.8 6.13552 6.22143 5.1181 6.97157 4.36796C7.0974 4.24213 7.23075 4.12555 7.37057 4.01865L5.63646 2.01078ZM8.68251 5.5377C8.57673 5.60896 8.47728 5.69068 8.38579 5.78217C8.01071 6.15724 7.8 6.66595 7.8 7.19638C7.8 7.72682 8.01071 8.23552 8.38579 8.6106C8.76086 8.98567 9.26957 9.19638 9.8 9.19638C10.3304 9.19638 10.8391 8.98567 11.2142 8.6106C11.2362 8.58864 11.2576 8.56623 11.2784 8.54338L8.68251 5.5377ZM11.772 7.52972L9.75722 5.19684C9.77146 5.19654 9.78572 5.19638 9.8 5.19638C10.3304 5.19638 10.8391 5.4071 11.2142 5.78217C11.5893 6.15724 11.8 6.66595 11.8 7.19638C11.8 7.30883 11.7905 7.4203 11.772 7.52972ZM13.2429 9.23275C13.6045 8.62125 13.8 7.91889 13.8 7.19638C13.8 6.13552 13.3786 5.1181 12.6284 4.36796C11.8783 3.61781 10.8609 3.19638 9.8 3.19638C9.27586 3.19638 8.76233 3.29926 8.28639 3.49382L6.68709 1.64204C7.69312 1.34521 8.74118 1.19238 9.8 1.19238C11.8282 1.19238 13.817 1.75316 15.5465 2.81272C17.276 3.87229 18.6788 5.3894 19.6 7.19638C18.6788 9.00337 17.276 10.5205 15.5465 11.58C15.4861 11.617 15.4254 11.6534 15.3644 11.6892L13.2429 9.23275Z"
        fill="black"
      />
    </svg>
  );
}

export default PasswordEyeHideIcon;
