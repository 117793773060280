import Card from "../card/Card";
import StackedBarChart from "./StackedBarChart";
import Row from "../custom/Row";

export default function ChargingNetworkGraph({ data, labels, isLoading, uniquePeriods }) {
  return (
    <Card className={"h-100 h-max-375px justify-evenly"}>
      <Row className="justify-between">
        <h6 className=" text-dark font-weight-normal">Cost by Charging Networks</h6>
        <span className="font-weight-light">Weekly</span>
      </Row>
      <StackedBarChart uniquePeriods={uniquePeriods} labels={labels} data={data} isLoading={isLoading} />
    </Card>
  );
}
