import Row from "components/custom/Row";
import CenteredHeader from "components/header/CenteredHeader";
import ConnectIcon from "components/icon/icons/ConnectIcon";
import AddVehicleTeslaFlowStarter from "components/vehicle-components/form/add-vehicle/AddVehicleTeslaFlowStarter";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import { useTranslation } from "react-i18next";
import PageHeader from "../../components/header/PageHeader";

type Props = {};

function AddVehicleTeslaPage(props: Props) {
  const { t } = useTranslation("common");

  return (
    <>
      <Head title={t("vehicle.title.add_vehicles_page")}></Head>
      <Content>
        <PageHeader
          title="Add Vehicle"
         description={'Connect Your Tesla'}
        />
        <AddVehicleTeslaFlowStarter />
      </Content>
    </>
  );
}

export default AddVehicleTeslaPage;
