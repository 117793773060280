import React from "react";
import { SvgProps } from "types/svg.types";

type Props = {};

function ArrowRightIcon(props: SvgProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.5625 5.25L19.3125 12L12.5625 18.75M18.375 12H4.6875"
        stroke="#6F7981"
        stroke-width="2.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ArrowRightIcon;
