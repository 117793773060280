import React from "react";

export default function TickIcon({ fill = "white" }) {
  return (
    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.30518 9.0308L4.30609 9.0317C4.45235 9.17646 4.64417 9.25002 4.83596 9.25002C5.02775 9.25002 5.21957 9.17646 5.36583 9.0317L12.3672 2.03034C12.6592 1.73682 12.6592 1.26322 12.3672 0.969691L12.3663 0.968789C12.0728 0.67676 11.5987 0.677225 11.3052 0.969254L4.83597 7.43943L1.36629 3.96879C1.07276 3.67676 0.599162 3.67676 0.305635 3.96879L0.304733 3.96969C0.0127044 4.26322 0.0131565 4.73727 0.305185 5.03079L4.30518 9.0308Z"
        fill={fill}
      />
    </svg>
  );
}
