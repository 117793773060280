
export type AuthState = {
    unAuthenticatedUser: {
        email: string | null
    }
}


export enum AuthSignUpFormFlowSteps {
    START,
    COMPANY,
    CREDENTIALS,
    CONFIRMATION
}

export enum AuthForgotPasswordFormFlowSteps {
    EMAIL,
    PASSWORD,
    COMPLETE
}


export type AWSSuccessfulForgotPasswordSubmitResponse = {
    CodeDeliveryDetails: {
        AttributeName: string,
        DeliveryMedium: string,
        Destination: string
    }
}

export type AWSSuccessfulResendSignUpSubmitResponse = AWSSuccessfulForgotPasswordSubmitResponse

//https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html#API_SignUp_Errors
export enum AWSSignInErrorTypes {
    UserNotConfirmedException = 'UserNotConfirmedException'

}

export type AuthEmailAddressCodeSent = {
    encrypted: string;
    original: string;
}


export type DialCode = {
    label: string,
    value: string
}

export type AuthSignUpFormState = {
    emailAddressCodeSent: AuthEmailAddressCodeSent
    phoneNumber: string;
    name: string;
    companyName: string;
    dialCode: DialCode
};