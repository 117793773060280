import { FormValues } from "@aws-amplify/ui-react/dist/types/components/AccountSettings/types";
import React from "react";
import { DeepMap, FieldError, RegisterOptions, Validate } from "react-hook-form";
import { FormGroup } from "reactstrap";
import ControllerValidationError from "../../form/ControllerValidationError";
import { BlackBodyText200 } from "../../text/Text";

export type AuthFormInputProps = {
  register: (options: RegisterOptions) => React.LegacyRef<HTMLInputElement> | undefined;
  errors?: DeepMap<FormValues, FieldError>;
  defaultValue?: string | number | readonly string[] | undefined;
  placeholder?: string | undefined;
  type?: React.HTMLInputTypeAttribute | undefined;
  name?: string | undefined;
  errorMessage?: string | undefined;
  formLabel?: string | undefined;
  registerRef?: React.LegacyRef<HTMLInputElement> | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onValidate?: Validate | Record<string, Validate> | undefined;
  className?: string;
  [key: string]: any;
};

export default function AuthFormInput({
                                        register,
                                        errors,
                                        defaultValue,
                                        placeholder,
                                        type,
                                        name,
                                        errorMessage,
                                        formLabel,
                                        registerRef,
                                        className,
                                        onChange,
                                        onValidate,
                                        ...props
                                      }: AuthFormInputProps) {
  const defaultType = "text";

  return (
    <FormGroup>
      {formLabel && <BlackBodyText200>{formLabel}</BlackBodyText200>}

      <input
        className={`form-control ${className && className}`}
        type={type ?? defaultType}
        name={name}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        ref={
          registerRef ??
          register({
            required: errorMessage ?? `${name} is required.`,
            validate: onValidate
          })
        }
        {...props}
      />

      <ControllerValidationError error={errorMessage || errors} />
    </FormGroup>
  );
}
