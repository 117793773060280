import { useState } from "react";
import { useHistory } from "react-router";
import { DriverFormType } from "types/driver.types";
import Col from "../custom/Col";
import Row from "../custom/Row";

import { useOnboardingStatus } from "../../hooks/dashboard.hooks";
import AddVehicleButton from "../button/AddVehicleButton";
import CancelButton from "../button/CancelButton";
import AlertConfirmDialog from "../notification/AlertConfirmDialog";
import DriverFormDriverStatusInfoModal from "./DriverFormDriverStatusInfoModal";
import DriverFormTypeSelectionCard from "./DriverFormTypeSelectionCard";

import CenteredHeader from "components/header/CenteredHeader";
import HorizontalLine from "components/line/HorizontalLine";
import { BlackSubtitleText0 } from "components/text/Text";
import UnlinkIconWithoutExclamation from "../icon/icons/UnlinkIconWithoutExclamation";

export default function AddDriverFormStarter() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const history = useHistory();

  const { isVehicleStepCompleted } = useOnboardingStatus();

  return (
    <>
      <AlertConfirmDialog
        isDialogOpen={!isVehicleStepCompleted}
        title="Add a Vehicle First!"
        description="Oops! It looks like you’re trying to add a driver, but you need to add a vehicle first. Please start by adding your vehicles. Once that’s done, you’ll be able to assign drivers seamlessly."
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => history.push("/")} />
            </div>
            <AddVehicleButton />
          </>
        }
        icon={<UnlinkIconWithoutExclamation />}
      />
      <DriverFormDriverStatusInfoModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <CenteredHeader title={"Add New Driver"} description={"Choose how to add a new driver to your fleet."} />
      <Col className="justify-center align-items-center flex-column w-100 ">
        <DriverFormTypeSelectionCard type={DriverFormType.MANUAL} />
        <Row className="w-100 flex-nowrap justify-content-center align-items-center w-800px">
          <HorizontalLine />
          <div className="p-2 px-4">
            <BlackSubtitleText0>or</BlackSubtitleText0>
          </div>

          <HorizontalLine />
        </Row>
        <DriverFormTypeSelectionCard type={DriverFormType.SELF_SIGNUP} />
        <Row className="mt-4 d-flex w-800px  w-max-100 justify-center  justify-content-center  align-items-center">
          {/**
           *  <LinkTextPrimary
            className={"d-flex align-items-center my-3 my-md-0 "}
            onClick={() => history.push("/bulk-upload-drivers")}
          >
            Add Drivers in Bulk
            <RightLinkChevron className="ml-1" />
          </LinkTextPrimary>

            <LinkTextPrimary className="center my-3 my-md-0 " onClick={() => setIsModalOpen(true)}>
            <InfoIcon />
            <span className="ml-1"> Need help deciding?</span>
          </LinkTextPrimary>
           */}
        </Row>
      </Col>
    </>
  );
}
