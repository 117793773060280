import OverviewCard from "components/dashboard-components/DashboardOverviewCard";
import { useDashboardOverviews } from "hooks/data/dashboard-data-accessor.hooks";
import { getFormattedNumberForEnergy } from "utils/charging.utils";

export default function TotalChargeCard({ isLoading, chargingSessionsStats }) {
  const { overviews, isLoading: isOverviewsLoading } = useDashboardOverviews();

  return (
    <OverviewCard
      total={getFormattedNumberForEnergy(overviews?.totalEnergyOverview?.total)}
      percent={overviews?.totalEnergyOverview?.monthlyTrend?.percentageChange}
      isLoading={isOverviewsLoading}
      title={"Total kWh"}
    />
  );
}
