import React from "react";
import { SvgProps } from "types/svg.types";

const ChargerIconDC = ({ fill, width, height, className }: SvgProps) => {
  const fillValue = fill ?? "#680031";
  const widthValue = width ?? 16;
  const heightValue = height ?? 18;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.62461 14.3742L5.52746 21.1896C5.48581 21.3246 5.49109 21.4696 5.54245 21.6012C5.59381 21.7328 5.68821 21.843 5.81027 21.9141C5.93233 21.9851 6.07485 22.0127 6.2146 21.9924L7.62461 14.3742ZM7.62461 14.3742H7.77846V13.8742M7.62461 14.3742L7.77846 13.8742M7.77846 13.8742L7.30057 13.7272L7.25532 13.8742M7.77846 13.8742H7.25532M7.25532 13.8742H3.62518H7.25532ZM13.6062 2.50132C13.6287 2.49803 13.6515 2.50093 13.6722 2.50956L13.687 2.51811C13.7114 2.53229 13.7304 2.55434 13.7407 2.58066C13.751 2.60698 13.752 2.63602 13.7437 2.66303L13.7436 2.66339L11.4926 9.97756L11.2935 10.6246H11.9705H16.1238H16.124C16.1484 10.6246 16.1722 10.6318 16.1926 10.6452C16.213 10.6586 16.229 10.6776 16.2387 10.7C16.2484 10.7224 16.2513 10.7472 16.2471 10.7712C16.2428 10.7952 16.2317 10.8175 16.2149 10.8353L16.2146 10.8356L6.21574 21.4594L6.2156 21.4595C6.19626 21.4801 6.17053 21.4935 6.14258 21.4976C6.11465 21.5016 6.08616 21.4961 6.06176 21.4819C6.03736 21.4677 6.01848 21.4457 6.00822 21.4194C5.99795 21.3931 5.9969 21.3641 6.00522 21.3371L6.00535 21.3367L8.25635 14.0213L8.45545 13.3742H7.77846H3.62518H3.62498C3.60058 13.3742 3.57671 13.3671 3.55632 13.3537C3.53593 13.3403 3.51991 13.3212 3.51024 13.2988C3.50056 13.2764 3.49766 13.2517 3.50189 13.2276L3.00946 13.141L3.50189 13.2276C3.50612 13.2036 3.51729 13.1813 3.53403 13.1636L3.53433 13.1633L13.5332 2.53946L13.5333 2.53934C13.5526 2.5188 13.5783 2.5054 13.6062 2.50132Z"
        fill="#0C0C0C"
        stroke="white"
      />
      <path
        d="M14.6246 14.3742L12.5275 21.1896C12.4858 21.3246 12.4911 21.4696 12.5425 21.6012C12.5938 21.7328 12.6882 21.843 12.8103 21.9141C12.9323 21.9851 13.0749 22.0127 13.2146 21.9924L14.6246 14.3742ZM14.6246 14.3742H14.7785V13.8742M14.6246 14.3742L14.7785 13.8742M14.7785 13.8742L14.3006 13.7272L14.2553 13.8742M14.7785 13.8742H14.2553M14.2553 13.8742H10.6252H14.2553ZM20.6062 2.50132C20.6287 2.49803 20.6515 2.50093 20.6722 2.50956L20.687 2.51811C20.7114 2.53229 20.7304 2.55434 20.7407 2.58066C20.751 2.60698 20.752 2.63602 20.7437 2.66303L20.7436 2.66339L18.4926 9.97756L18.2935 10.6246H18.9705H23.1238H23.124C23.1484 10.6246 23.1722 10.6318 23.1926 10.6452C23.213 10.6586 23.229 10.6776 23.2387 10.7C23.2484 10.7224 23.2513 10.7472 23.2471 10.7712C23.2428 10.7952 23.2317 10.8175 23.2149 10.8353L23.2146 10.8356L13.2157 21.4594L13.2156 21.4595C13.1963 21.4801 13.1705 21.4935 13.1426 21.4976C13.1146 21.5016 13.0862 21.4961 13.0618 21.4819C13.0374 21.4677 13.0185 21.4457 13.0082 21.4194C12.998 21.3931 12.9969 21.3641 13.0052 21.3371L13.0054 21.3367L15.2563 14.0213L15.4554 13.3742H14.7785H10.6252H10.625C10.6006 13.3742 10.5767 13.3671 10.5563 13.3537C10.5359 13.3403 10.5199 13.3212 10.5102 13.2988C10.5006 13.2764 10.4977 13.2517 10.5019 13.2276L10.0095 13.141L10.5019 13.2276C10.5061 13.2036 10.5173 13.1813 10.534 13.1636L10.5343 13.1633L20.5332 2.53946L20.5333 2.53934C20.5526 2.5188 20.5783 2.5054 20.6062 2.50132Z"
        fill="#0C0C0C"
        stroke="white"
      />
    </svg>
  );
};
export default ChargerIconDC;

/**
 *   width={`${widthValue}`}
      height={`${heightValue}`}
      viewBox={`0 0 ${widthValue} ${heightValue}
 */
