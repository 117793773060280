import Row from "components/custom/Row";
import ReadonlyFormBox, { ReadonlyFormBoxProps } from "components/form/ReadonlyFormBox";
import React from "react";
import OutlinedButtonWithIcon from "../button/OutlinedButtonWithIcon";
import PencilIcon from "../icon/icons/PencilIcon";

type Props = ReadonlyFormBoxProps & {
  onEditButtonPressed?: () => void;
};

function SettingsPageFormBox({ onEditButtonPressed, ...props }: Props) {
  return (
    <Row className="w-100 flex-nowrap justify-between align-items-center mt-2">
      <ReadonlyFormBox {...props} textRightElement={onEditButtonPressed && (
        <OutlinedButtonWithIcon className="ml-3 clickable" leftIcon={<PencilIcon />}
                                onClick={onEditButtonPressed} />
      )} />
    </Row>
  );
}

export default SettingsPageFormBox;
