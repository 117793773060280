import React from "react";
import { SvgProps } from "../../../types/svg.types";

function GrayCircleIcon(props: SvgProps) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.665039" y="0.665039" width="16.67" height="16.67" rx="8.335" fill="#CBD5E1" />
    </svg>
  );
}

export default GrayCircleIcon;
