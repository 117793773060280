import React from "react";
import { Row, UncontrolledTooltip } from "reactstrap";
import FleetGroupBadge from "../badge/FleetGroupBadge";
import { TooltipComponent } from "../Component";
import MuiTooltip from "../tooltip/MuiTooltip";

export default function DynamicFleetGroupBadgeList({ getGroupBadgeContent, fleetGroups }) {
  if (fleetGroups?.length === 0 || !fleetGroups) {
    return <>-</>;
  }

  const firstGroup = fleetGroups[0];
  const firstGroupBadgeContent = getGroupBadgeContent(firstGroup?.name);

  if (fleetGroups?.length === 1 && fleetGroups[0].name) {
    return (
      <p>
        {/**
         * Ugly solution : pointer-events:none prevents title attribute
         * https://stackoverflow.com/a/47412524/13592168
         */}
        <FleetGroupBadge badgeContent={firstGroupBadgeContent} />
      </p>
    );
  }

  const others = fleetGroups.slice(1, fleetGroups.length);
  const othersLength = others.length;

  let uniqueKey = "";
  others.forEach((u) => {
    uniqueKey += u.name[0] + `${u.name[u.name.length - 1]}` + `-${othersLength}`;
  });

  return (
    <Row className="flex align-center m-0 flex-nowrap priority-link">
      <p className="mr-1 mb-0 w-max-100px">
        {/**
         * Ugly solution : pointer-events:none prevents title attribute
         * https://stackoverflow.com/a/47412524/13592168
         */}
        <FleetGroupBadge badgeContent={firstGroupBadgeContent} />
      </p>
      <MuiTooltip
        title={others.map((o, ix) => (
          <div style={{ padding: 2 }} key={ix}>{`${o.name}`}</div>
        ))}
        placement="top"
      >
        <div id={uniqueKey} className="help-fill rounded-circle center bg-dark w-25px h-25px text-white">
          +{othersLength}
        </div>
      </MuiTooltip>
    </Row>
  );
}

/**
 *  {others.map((ou, ix) => (
          <p key={ix}>{ou.firstName}</p>
        ))}
 */
