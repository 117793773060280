import React from "react";

type Props = {};

function InboxMenuIconFilled({}: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.82774 5.10775C5.99355 4.77613 6.24754 4.49743 6.56505 4.29987C6.88256 4.10231 7.24592 4 7.61634 4H16.3795C16.75 4 17.1169 4.10231 17.4308 4.29987C17.7484 4.4939 18.0024 4.77613 18.1682 5.10775L21.6819 12.1317C21.8901 12.548 21.9994 13.0066 21.9994 13.4758V18.0021C21.9994 18.5312 21.7878 19.0393 21.4138 19.4167C21.0399 19.7907 20.5283 20.0024 19.9991 20.0024H4.00029C3.47111 20.0024 2.95959 19.7907 2.58564 19.4167C2.21168 19.0428 2 18.5312 2 18.0021V13.4723C2 13.0066 2.10937 12.548 2.31752 12.1317L5.83125 5.10775H5.82774ZM16.3795 6.00029H7.61634L4.11671 12.9996H7.49641C7.89506 12.9996 8.27607 13.1583 8.5583 13.437C8.84052 13.7192 8.99928 14.1003 8.99928 14.4989V15.5008C8.99928 15.6313 9.05219 15.7619 9.14391 15.8536C9.23916 15.9489 9.36617 16.0018 9.4967 16.0018H14.4957C14.6297 16.0018 14.7567 15.9489 14.8484 15.8536C14.9437 15.7583 14.9966 15.6313 14.9966 15.5008V14.4989C14.9966 14.1003 15.1554 13.7192 15.4376 13.437C15.7198 13.1548 16.1009 12.9996 16.4995 12.9996H19.8792L16.3795 6.00029Z"
        fill="#0C0C0C"
      />
    </svg>
  );
}

export default InboxMenuIconFilled;
