import { TotalSessionsDoughnutChart } from "components/charts/Chart";
import { useDashboardInsights } from "hooks/data/dashboard-data-accessor.hooks";
import { useMemo } from "react";
import { getProcessedChargingTypeGraphData } from "../../../utils/dashboard.utils";
import GraphPlaceholder from "../dashboard-placeholders/GraphPlaceholder";

export default function DashboardChargerTypeGraph() {
  const { insights } = useDashboardInsights();
  const chargingTypesData = insights?.countByChargingType;

  const areThereAnyData = chargingTypesData && chargingTypesData.length > 0;

  const processedGraphData = useMemo(() => {
    if (!areThereAnyData) {
      return undefined;
    }
    return getProcessedChargingTypeGraphData(chargingTypesData);
  }, [areThereAnyData, chargingTypesData]);

  if (!processedGraphData) {
    return <GraphPlaceholder />;
  }

  const { data, labels, total: totalSessions, backgroundColors } = processedGraphData;

  const doughnutData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: backgroundColors,
      },
    ],
  };

  return <TotalSessionsDoughnutChart data={doughnutData} totalSessions={totalSessions} />;
}
