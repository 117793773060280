import { RSelect } from "components/Component";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup } from "reactstrap";
import { SelectorStringOption } from "../../../types/editor.types";

import { BlackH5Text0 } from "../../text/Text";
import { SelectedDriverForEditor } from "../AssignDriverForm";

type Props = {
  driverOptions: SelectorStringOption[];
  selectedDriver?: SelectedDriverForEditor;
  onSelectedDriverChanged: (sd: SelectedDriverForEditor) => void;
  editorLabelOverride?: JSX.Element;
  editorLabel?: string;
  isLoading: boolean;
  isDisabled?: boolean;
};

function DriverSelectorEditor({
  isLoading,
  editorLabelOverride,
  editorLabel,
  selectedDriver,
  onSelectedDriverChanged,
  driverOptions,
  isDisabled,
}: Props) {
  const { t } = useTranslation("common");

  const sortedDriverOptionsByName = useMemo(() => {
    return driverOptions.sort((d1, d2) => d1.label.localeCompare(d2.label));
  }, [driverOptions]);

  return (
    <>
      <FormGroup>
        {editorLabelOverride ?? <BlackH5Text0 className="mb-1">{editorLabel}</BlackH5Text0>}
        <RSelect
          menuPosition="fixed"
          menuPortalTarget={document.querySelector(".modal-content")}
          styles={{
            menuList: (styles) => {
              return {
                ...styles,
                maxHeight: 200,
              };
            },
          }}
          noOptionsMessage={() => (isLoading ? "Loading..." : "No drivers")}
          name={"drivers"}
          placeholder={isLoading ? "Loading..." : t("fleet_groups.common.form.drivers.placeholder")}
          options={sortedDriverOptionsByName}
          value={selectedDriver}
          onChange={onSelectedDriverChanged}
          isClearable
          isDisabled={isDisabled}
        />
      </FormGroup>
    </>
  );
}

/**
 *
 */

export default DriverSelectorEditor;
