import React from "react";

const DoubleCheckedIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.3125 4.5L8.4375 13.5L5.0625 10.125M5.0625 13.5L1.6875 10.125M12.9375 4.5L8.15625 9.98438"
            stroke="#188B04" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default DoubleCheckedIcon;