import PageHeader from "components/header/PageHeader";
import { useFleetGroupsAsync } from "hooks/data/fleet-group-data-accessor.hooks";
import { useCallback, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Row } from "reactstrap";
import { Icon, RSelect } from "../../components/Component";
import ButtonWithIcon from "../../components/button/ButtonWithIcon";
import Col from "../../components/custom/Col";
import FleetGroupsTable from "../../components/fleet-groups-components/FleetGroupsTable";
import SearchInput from "../../components/input/SearchInput";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { filterFleetGroupsByQuery } from "../../utils/fleet-groups.utils";

export default function FleetGroupsPage() {
  const { t } = useTranslation("common");

  const { fleetGroups, isFleetGroupsLoading, refetch: refetchFleetGroups } = useFleetGroupsAsync();

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobilePaginationVisible = width <= 768;

  const [filteredFleetGroups, setFilteredFleetGroups] = useState(fleetGroups ?? []);
  const [mobilePagination, setMobilePagination] = useState({ maxShown: 10 });

  const mobilePaginationOptions = [
    { label: "2", value: 2 },
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "100", value: 100 },
  ];

  useEffect(() => {
    let fleetGroupsToShown = fleetGroups;
    if (isMobilePaginationVisible) {
      fleetGroupsToShown = fleetGroupsToShown.slice(0, mobilePagination.maxShown);
    }

    setFilteredFleetGroups(fleetGroupsToShown);
  }, [isMobilePaginationVisible, mobilePagination.maxShown, fleetGroups]);

  const history = useHistory();

  const handleSearchInputTyped = useCallback(
    (event) => {
      const query = event.target.value.toLowerCase();
      if (!query) {
        setFilteredFleetGroups(fleetGroups);
        return;
      }

      const filteredFleetGroups_ = filterFleetGroupsByQuery(fleetGroups, query);
      setFilteredFleetGroups(filteredFleetGroups_);
    },
    [fleetGroups]
  );

  const handleAddFleetGroupClicked = useCallback(() => {
    history.push("/add-fleet-group");
  }, [history]);

  return (
    <>
      <Head title={t("fleet_groups.main_page.title")}></Head>

      <Content>
        <PageHeader
          title={t("fleet_groups.main_page.page_header")}
          description={t("fleet_groups.main_page.page_description")}
        />

        <ErrorBoundary fallback={<>An error occured</>}>
          <Col xs="3" className="p-0 d-block d-lg-none my-2 ">
            <RSelect
              value={{ label: mobilePagination.maxShown, value: mobilePagination.maxShown }}
              onChange={(e) => {
                setMobilePagination({ maxShown: e.value });
              }}
              options={mobilePaginationOptions}
            />
          </Col>
          <Row className="m-0 justify-between my-2">
            <SearchInput onChange={handleSearchInputTyped} placeholder={t("general.search_placeholder") ?? ""} />

            <ButtonWithIcon
              className="mt-2 mt-lg-0"
              leftIcon={<Icon name="plus" />}
              text={"Add Fleet Group"}
              onClick={handleAddFleetGroupClicked}
            />
          </Row>

          <FleetGroupsTable
            isGroupsDataLoading={isFleetGroupsLoading}
            fleetGroups={filteredFleetGroups}
            refetchFleetGroups={refetchFleetGroups}
          />
        </ErrorBoundary>
      </Content>
    </>
  );
}
