import React from "react";

const DashboardMenuIconFilled = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.99902 21.016V9.48343C2.99902 9.18003 3.14014 8.89075 3.38004 8.70377L11.3953 2.47005C11.7516 2.19488 12.2491 2.19488 12.6054 2.47005L20.6207 8.70377C20.8606 8.89075 21.0017 9.17651 21.0017 9.48343V21.016C21.0017 21.5593 20.5607 22.0003 20.0174 22.0003H14.9902C14.4469 22.0003 14.0059 21.5593 14.0059 21.016V15.9888C14.0059 15.4455 13.5649 15.0045 13.0217 15.0045H10.9931C10.4498 15.0045 10.0089 15.4455 10.0089 15.9888V21.016C10.0089 21.5593 9.5679 22.0003 9.02461 22.0003H3.99741C3.45412 22.0003 3.01313 21.5593 3.01313 21.016H2.99902Z"
        fill="#0C0C0C"
      />
    </svg>
  );
};
export default DashboardMenuIconFilled;
