import SleepingVehicle from "assets/images/vehicle/AsleepVehicle.png";
import Row from "components/custom/Row";
import { BlackBody2Text0, BlackCaptionText200, BlackOverlineText100 } from "components/text/Text";
import { TeslaDataAccessorHook } from "types/vehicle.types";
import { getTeslaVehicleStatusContent } from "utils/vehicle.utils";
import LoadingSkeleton from "../../loading/LoadingSkeleton";
import ProcessingCircularBar from "../../loading/ProcessingCircularBar";
import PrimaryButton from "../../button/PrimaryButton";

type Props = {
  teslaHook: TeslaDataAccessorHook;
};

function VehicleDetailsVehicleStateCard({ teslaHook }: Props) {


  if (teslaHook.isTeslaInfoLoading && !teslaHook.isWakeUpSequenceRunning) {
    return <div className={"py-2"}><LoadingSkeleton count={1} height={40} /></div>;
  }


  const { icon, label, textClass } = getTeslaVehicleStatusContent(teslaHook.teslaVehicleStatus);

  return (
    <div className="py-2">
      <Row className="justify-between">
        <BlackCaptionText200>Vehicle state</BlackCaptionText200>
        <Row>
          {icon}
          <BlackBody2Text0 className={`ml-1 ${textClass ?? ""}`}>{label}</BlackBody2Text0>
        </Row>
      </Row>


      {teslaHook.isWakeUpSequenceRunning && <ProcessingCircularBar />}
      {teslaHook.isTeslaSleeping && !teslaHook.isWakeUpSequenceRunning && (
        <div className="bg-secondary mt-4 rounded-xxl p-2">
          <Row className="flex-nowrap align-items-center">
            <img width={50} height={35} src={SleepingVehicle} alt="Sleeping Vehicle" />
            <BlackOverlineText100 className="ml-2">
              Your vehicle is currently in sleep mode, which means all controls are inactive. To use the controls,
              please 'Wake Up' the vehicle.
            </BlackOverlineText100>
            <PrimaryButton   className={"ml-1"} onClick={teslaHook.handleTriggerVehicleWakeUpSequence} text={"Wake Up"} />
          </Row>
        </div>
      )}
    </div>
  );
}

export default VehicleDetailsVehicleStateCard;
