import React from "react";
import { BlackBodyText100 } from "../text/Text";

type Props = {
  color: string
  timeDifference: string
}

function VehicleDriverSchedulePeriodBadge({ color, timeDifference }: Props) {

  return (
    <div className="driver-details-period-badge" style={{ backgroundColor: color }}>
      <BlackBodyText100>{timeDifference}</BlackBodyText100>
    </div>
  );
}

export default VehicleDriverSchedulePeriodBadge;