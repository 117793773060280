import React from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import HoverUnderlinedTextButton from "./HoverUnderlinedTextButton";

type Props = {};

const SignUpTextLink = (props: Props) => {
  const { url } = useRouteMatch();
  return (
    <Link to={`${url}/register`} type="button">
      <HoverUnderlinedTextButton text={'Sign up'}/>
    </Link>
  );
};

export default SignUpTextLink;
