import Row from "components/custom/Row";
import CircleBluedotLogoIcon from "components/icon/icons/CircleBluedotLogoIcon";
import CustomerSupportAvatar1 from "assets/images/avatar/CustomerSupportAvatar1.jpeg";
import CustomerSupportAvatar2 from "assets/images/avatar/CustomerSupportAvatar2.png";
import React from "react";

type Props = {};

function CustomerSupportAvatarList({}: Props) {
  return (
    <Row className=" align-items-center">
      <CircleBluedotLogoIcon />
      <img className="customer-support-avatar-image" src={CustomerSupportAvatar1} alt="Customer Support Avatar 1" />
      <img className="customer-support-avatar-image" src={CustomerSupportAvatar2} alt="Customer Support Avatar 2" />
    </Row>
  );
}

export default CustomerSupportAvatarList;
