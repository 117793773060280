import React from "react";
import Row from "../custom/Row";
import BulbOutlineIcon from "../icon/icons/BulbOutlineIcon";
import { NeutralBodySmallText100, NeutralTitleSmallText100 } from "../text/Text";

type Props = {
  title: string
  description: string
  className?: string
  isNoWrap?: boolean
}

const VioletInfoBadge = ({ title, description, className, isNoWrap = true }: Props) => {
  return (
    <div className={`violent-card  ${className ?? ""}`}>
      <Row className={`align-items-center ${isNoWrap ? "flex-nowrap" : ""}`}>
        <Row className={"w-min-48px align-items-center"}>
          <BulbOutlineIcon />
          <NeutralTitleSmallText100 className={"ml-1"}>{title}</NeutralTitleSmallText100>
        </Row>

        {description && <NeutralBodySmallText100>{description}</NeutralBodySmallText100>}
      </Row>
    </div>

  );
};

export default VioletInfoBadge;