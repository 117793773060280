import { SvgProps } from "types/svg.types";

type Props = {};

function LockClosedFilledIcon(props: SvgProps) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11 6.5V3.53125C11 2.86821 10.7366 2.23232 10.2678 1.76348C9.79893 1.29464 9.16304 1.03125 8.5 1.03125C7.83696 1.03125 7.20107 1.29464 6.73223 1.76348C6.26339 2.23232 6 2.86821 6 3.53125V6.5"
        stroke="black"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6.5H5C4.17157 6.5 3.5 7.17157 3.5 8V13.5C3.5 14.3284 4.17157 15 5 15H12C12.8284 15 13.5 14.3284 13.5 13.5V8C13.5 7.17157 12.8284 6.5 12 6.5Z"
        stroke="black"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LockClosedFilledIcon;
