import { BlackBodyText100, BlackBodyText300, BlackSubtitleText100, BlackSubtitleText300 } from "components/text/Text";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import React from "react";

type Props = {};

function TermsPage({}: Props) {
  return (
    <>
      <Head title={"Terms of Services"}></Head>

      <div className="center">
        <div className="wide-md-fix p-4">
          <BlackSubtitleText100 className="my-2 mt-4">Bluedot Fleet OS Terms of Services</BlackSubtitleText100>
          <BlackSubtitleText300 className="mt-3">
            THIS BLUEDOT FLEET OS TERMS OF SERVICE (“AGREEMENT”) IS BETWEEN BLUEDOT TECHNOLOGIES INC. (“BLUEDOT”) AND
            THE CLIENT (“CLIENT”) WHO HAS REGISTERED OR ENTERED AN ORDER FORM OR ACCEPTED THIS AGREEMENT BY REGISTERING
            FOR THE SERVICES DESCRIBED BELOW ONLINE AT WWW.THEBLUEDOT.CO/BUSINESS (“WEBSITE”). BY REGISTERING FOR,
            ACCESSING, AND USING THE BLUEDOT FLEET OS PLATFORM AND ASSOCIATED SERVICES, CLIENT AGREES TO AND IS BOUND BY
            THIS AGREEMENT. IF CLIENT DOES NOT AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT, CLIENT MAY NOT
            REGISTER FOR OR USE THE SERVICES.
          </BlackSubtitleText300>
          <BlackBodyText100 className="my-2 mt-4">1. SERVICE AND RESTRICTIONS</BlackBodyText100>
          <BlackBodyText100 className="mt-3">Service Description</BlackBodyText100>
          <BlackBodyText300>
            Bluedot provides a software solution (“Fleet OS”) and a mobile application (“Driver App”) for businesses to
            manage and monitor electric vehicle (EV) charging. Clients can order the Service and download the App by
            completing an online or signed order form, specifying the number of EVs, authorized users, subscription
            duration, and associated fees.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Access to Driver App and Account Responsibilities</BlackBodyText100>
          <BlackBodyText300>
            To access the Driver App and its features, users must register and create an account, providing necessary
            access credentials such as phone number, one-time passcode, and unique username/password. It is important to
            provide accurate information during registration and keep access credentials confidential. If there are
            concerns about unauthorized use, loss, or theft of credentials, Bluedot must be notified immediately. Users
            are solely responsible for all activities under their account, and Bluedot disclaims liability for any
            losses resulting from unauthorized account use.
          </BlackBodyText300>

          <BlackBodyText300 className="mt-1">
            By providing contact information during registration, users agree to receive postal and electronic
            communications (emails, text/SMS, phone calls) from Bluedot regarding site and service updates, legal
            notices, and other information. Users may also receive email updates or exclusive offers, but they have the
            option to unsubscribe or adjust preferences within their account settings. Opting out may restrict users
            from receiving important information about updates, enhancements, or promotions.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Order Forms </BlackBodyText100>
          <BlackBodyText300>
            Clients who adhere to this Agreement and pay the fees will be granted exclusive access to Fleet OS for the
            specified duration and a non-transferable license to download the App for internal business purposes.
            Bluedot will provide access credentials, which are considered confidential information.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Usage Restrictions</BlackBodyText100>
          <BlackBodyText300>
            Client and its authorized users will not, nor assist any third party to: (i) attempt to reverse engineer,
            decompile, or disassemble the Supplier application in order to find the source code, object code, technical
            structure or any algorithms used to deliver the application, (ii) modify or translate the application, (iii)
            sell, resell, license, sublicense, distribute, rent or lease any of the services to any third-party under
            this letter.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Account</BlackBodyText100>
          <BlackBodyText300>
            Customers are responsible for providing accurate information when creating an account and keeping it
            updated. Access and use of the Services are limited to authorized employees and contractors of the customer.
            Each authorized user must register and create an account, accepting the End User License Agreement. User
            credentials should be treated as confidential information and not shared without written permission from
            Bluedot. Customer is responsible for any acts or omissions of authorized users and must notify Bluedot of
            any unauthorized account use.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Client Responsibilities </BlackBodyText100>
          <BlackBodyText300>
            Client is responsible for obtaining and maintaining all necessary equipment and services needed to access
            the Service. Client is also responsible for the security of their account and credentials. Any misuse by
            authorized users or through Client’s account will be the Client's responsibility.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Access to Vehicle Informatics and Location Data </BlackBodyText100>
          <BlackBodyText300>
            Customers must connect their vehicles to Bluedot's platform to fully utilize the Services. By voluntarily
            adding vehicles to the platform, customers grant Bluedot the right to access their vehicle accounts, receive
            and process vehicle data, collect and process geolocation data, and collect and process driver data. Failure
            to provide necessary vehicle data, location data, and driver data may limit the functionality of the
            platform.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">No Liability for Vehicles/Authorized Users</BlackBodyText100>
          <BlackBodyText300>
            Company is not responsible for the safety, security or maintenance of any Vehicles or any accident, damages,
            losses, injuries or death caused by any Vehicles, whether or not through caused by use or access to the
            Service or by any Authorized User. Customer shall not access or use the Software or Service while operating
            a Vehicle.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Technical Support </BlackBodyText100>
          <BlackBodyText300>
            Bluedot will make reasonable efforts to maintain the Service and provide email support during standard
            business hours. Advance notice will be given for scheduled service disruptions.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Feedback </BlackBodyText100>
          <BlackBodyText300>
            Customers may provide feedback to Bluedot regarding the Service, but it does not create any confidentiality
            obligation. Bluedot may use, disclose, reproduce, license, distribute, or exploit the feedback without
            restriction or obligation to compensate the customer.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Resultant Data</BlackBodyText100>
          <BlackBodyText300>
            Bluedot has the right to collect and analyze data relating to the provision, use, and performance of the
            Service. This data may be used to improve the Service and other offerings. Bluedot may disclose this data in
            aggregated or de-identified form.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Intellectual Property Rights </BlackBodyText100>
          <BlackBodyText300>
            Customer agrees that Company shall own all intellectual property rights in and to the Service, Platform and
            Software, including any derivative works, improvements or customizations made for Customer. “Intellectual
            property rights” means all patents, copyright, trademark, trade secret or other proprietary right in any
            jurisdiction of the world.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Third-Party Entities, Resources, and Hyperlinks</BlackBodyText100>
          <BlackBodyText300>
            Bluedot does not have control over the charging stations suggested through the Service, as they are owned
            and managed by independent parties. Bluedot is not responsible for the operational efficiency of any
            specific station. Users must comply with the guidelines and regulations set by the station hosts. Bluedot
            disclaims liability for any damages or losses resulting from non-compliance. The Service may feature
            hyperlinks to third-party websites, services, or content. Bluedot does not endorse or take responsibility
            for these third-party entities. Users engage with them at their own discretion and acknowledge that this
            Agreement and Bluedot's Privacy Policy do not apply to these third-party sites.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-4">2.FEES AND PAYMENT</BlackBodyText100>
          <BlackBodyText300>
            Client agrees to pay Bluedot the fees specified in the Order Form. All fees are based on subscriptions, are
            non-refundable, and cannot be canceled. Disputes regarding billing must be raised within 60 days of the
            charge.Unpaid amounts are subject to a finance charge of 1.5% per month on any outstanding balance, or the
            maximum permitted by law, whichever is lower, plus all expenses of collection. Customer shall pay all taxes
            associated with the Service other than U.S. taxes based on Company’s net income. If the Service is ordered
            online, the fees can be paid by providing a valid credit card, which Customer authorizes Company to charge
            for any fees and taxes that are due.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-4">3. CONFIDENTIALITY:</BlackBodyText100>
          <BlackBodyText100 className="mt-3">Definition</BlackBodyText100>
          <BlackBodyText300>
            "Confidential Information" refers to any data shared by the "Disclosing Party" with the "Recipient,"
            regardless of the timing relative to this Agreement. This data can be in various formats, marked as private,
            or understood to be confidential or a trade secret, or orally communicated if it's identified as
            confidential when shared, and then promptly documented, marked, and handed over to the Recipient.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Obligations</BlackBodyText100>
          <BlackBodyText300>
            The Recipient must restrict the use of Confidential Information to purposes related to this Agreement and
            must not share it with third parties without consent. It should only be shared internally with individuals
            who need to be aware of it for Agreement-related activities and who are bound by strict confidentiality
            agreements. The Recipient is responsible for preventing unauthorized disclosure or use, taking robust
            protective measures, and promptly informing the Disclosing Party of any breaches{" "}
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Exceptions</BlackBodyText100>
          <BlackBodyText300>
            The obligations do not apply if the information is already public, was in the Recipient's possession before
            disclosure and was not confidential, is received from a third party without confidentiality obligations, or
            is independently developed by the Recipient without reference to the Confidential Information.{" "}
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Compelled Disclosure</BlackBodyText100>
          <BlackBodyText300>
            If the Recipient is legally required to disclose Confidential Information, they must notify the Disclosing
            Party in advance and collaborate to prevent public disclosure, limiting the disclosure to only what's
            specifically required.{" "}
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Confidentiality of Agreement</BlackBodyText100>
          <BlackBodyText300>
            The specifics of the Order Form are confidential, with its existence being non-confidential. No party can
            publicly discuss the Agreement details without written permission, though disclosure is allowed under
            specific circumstances like legal processes, IPOs, professional consultations, rights enforcement, or
            mergers and acquisitions.{" "}
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Return of Confidential Information</BlackBodyText100>
          <BlackBodyText300>
            After the Agreement ends, the Recipient should return or destroy all Confidential Information. However,
            information within archived backups can be retained, subject to this Agreement's terms, and a copy can be
            kept with legal counsel for compliance purposes.{" "}
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Injunctive Relief</BlackBodyText100>
          <BlackBodyText300>
            Breaches related to Confidential Information or intellectual property rights may result in irreparable
            damage. The Disclosing Party can seek immediate and lasting legal restraint without posting a bond, along
            with other available legal remedies.{" "}
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Survival of Confidentiality Obligations</BlackBodyText100>
          <BlackBodyText300>
            The Recipient's duties regarding confidentiality last for five years post-Agreement, or indefinitely for
            trade secrets, as long as they maintain that status.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-4">TERM AND TERMINATION</BlackBodyText100>
          <BlackBodyText300>
            The Agreement starts when the Company accepts the Order Form and continues as indicated therein. It
            auto-renews for equal periods unless a party opts out. If the Subscription Term isn't in the Order Form,
            it's a year, auto-renewing yearly unless opted out 30 days before expiry. Company can raise fees with 60
            days' notice before renewal; if unacceptable, Customer can opt out.
          </BlackBodyText300>
          <BlackBodyText300 className="mt-1">
            For material breach, a party can terminate with 30 days' notice if the breach isn't cured. On termination,
            Customer pays up until the last Service day. If Customer breaches, they pay all remaining fees.
          </BlackBodyText300>

          <BlackBodyText300 className="mt-1">
            If data costs rise or access ends, Company can increase fees or terminate with 30 days' notice. If
            unacceptable, Customer can terminate and get a refund for the unused period.
          </BlackBodyText300>

          <BlackBodyText300 className="mt-1">
            Post-termination, Customer must uninstall the Software and certify this. Certain sections survive
            termination.
          </BlackBodyText300>
          <BlackBodyText100 className="mt-4">INDEMNIFICATION</BlackBodyText100>
          <BlackBodyText100 className="mt-3">Indemnification by Customer </BlackBodyText100>
          <BlackBodyText300>
            Customer shall defend and hold harmless Company and its affiliates and its and their representatives,
            employees, directors, stockholders, consultants, and agents (collectively, the “Company Indemnitees”) from
            and against any claim, action, suit, or proceeding (each, a “Claim”)alleging: (1) a violation, infringement,
            or breach of any third-party rights related to Customer’s provision of Customer Data to Company, including
            the failure to provide notice and obtain consents to provide Personal Data to Company for processing under
            this Agreement, (2) that Customer’s use of the Service does not comply with applicable law or the Acceptable
            Use Policy, (3) a personal injury, death, or damage to property arising from Customer’s use of the Service
            or any Vehicles operated by or on behalf of Customer, and (4) any damages, losses, or liabilities arising
            from use of the Service in connection with any law enforcement action or use of the Service to reclaim or
            repossess any property or Vehicle . Customer shall indemnify a Company Indemnitee for any and all damages,
            losses, costs, expenses (including reasonable attorneys’ fees), judgements, fines, fees, and liabilities
            arising out of a Claim.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Indemnification by Company</BlackBodyText100>
          <BlackBodyText300>
            Company shall defend and hold harmless Customer and its affiliates and its and their representatives,
            employees, directors, stockholders, consultants, and agents (collectively, the “Customer Indemnitees”) from
            and against any Claim alleging that the Software as used in accordance with this Agreement infringes the
            intellectual property rights of any third party.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Indemnification Procedure</BlackBodyText100>
          <BlackBodyText300>
            The indemnified party shall provide the indemnifying party with prompt notice of a Claim, shall cooperate
            with the indemnifying party (at the indemnifying party’s expense) in the defense and settlement of a Claim,
            and shall permit the indemnified party to have sole control of the defense and settlement of a Claim,
            provided that the indemnifying party may participate in such defense with counsel of its own choice, but at
            its own expense. If the indemnified party does not defend a Claim, the indemnifying party may defend such
            Claim itself and, in addition to the amounts indemnified for above, any fees, expenses, costs, or other
            amounts incurred by the indemnified party in such defense shall be promptly paid by the indemnifying party.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-4">REPRESENTATIONS AND WARRANTIES</BlackBodyText100>
          <BlackBodyText100 className="mt-3">Representations and Warranties</BlackBodyText100>
          <BlackBodyText300>
            Each party represents and warrants that it has the power to enter into this Agreement and to perform its
            obligations hereunder, and that this Agreement does not conflict with any other agreement or obligations to
            which that party is bound. The Company further warrants that (1) the Service will operate substantially in
            accordance with its documentation and (2) to the best of the Company's knowledge, the Service, when
            delivered or provided to the Customer, is free of any code that is designed to disrupt, disable, harm,
            modify, delete, or otherwise impair the operation of the Service or any of the Customer's Equipment. The
            Company's sole and exclusive liability, and the Customer's sole and exclusive remedy, for breach of the
            foregoing shall be the Company's use of commercially reasonable efforts to maintain the Service as described
            in Section 1.13 above.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-3">Disclaimer of Warranties</BlackBodyText100>
          <BlackBodyText300>
            Except as set forth in Section 6.1, the Company makes no warranties, express, implied, statutory, or
            otherwise, with respect to the Service or its use or operation, including any warranties regarding the
            accuracy of the Service or that the Service will be uninterrupted or error-free. The Company disclaims the
            implied warranties of merchantability, fitness for a particular purpose, and non-infringement. The Company
            also disclaims any representations or warranties, or any damages, losses, liabilities, costs, or other
            amounts, related to the use of the Service for reclamation or repossession of any property or vehicle or use
            of the Service in connection with any law enforcement action.
          </BlackBodyText300>

          <BlackBodyText100 className="mt-4">MISCELLANEOUS </BlackBodyText100>
          <BlackBodyText300>
            Governing Law: This Agreement is governed by the laws of the state of California, excluding its conflicts of
            law principles and the United Nations Convention on Contracts for the International Sale of Goods.
          </BlackBodyText300>
          <BlackBodyText300 className="mt-1">
            Jurisdiction: The parties consent to the exclusive jurisdiction of the state and federal courts located
            within San Francisco, California, and waive any objections to jurisdiction, venue, or inconvenient forum.
          </BlackBodyText300>
          <BlackBodyText300 className="mt-1">
            Force Majeure: Neither party shall be liable for non-performance due to unforeseeable events beyond its
            control, with the right to terminate the Agreement if such events persist for more than 30 days.
          </BlackBodyText300>
          <BlackBodyText300 className="mt-1">
            Severability: If any provision of this Agreement is found to be unenforceable, it shall be modified to the
            minimum extent necessary to make it enforceable without affecting the validity of the other provisions.
          </BlackBodyText300>
          <BlackBodyText300 className="mt-1">
            Publicity: Unless otherwise specified in the Order Form, the Customer agrees to cooperate with the Company
            in the preparation and publication of a case study describing the Customer's use of the Service. The
            Customer also agrees that the Company may use the Customer's name and logo to identify the Customer as a
            customer of the Company for marketing and promotional purposes, provided that the Company complies with any
            trademark usage guidelines provided by the Customer in writing.
          </BlackBodyText300>
          <BlackBodyText300 className="mt-1">
            Waiver: The non-enforcement of any provision of this Agreement shall not be deemed a waiver of future
            enforcement of that or any other provision.
          </BlackBodyText300>
          <BlackBodyText300 className="mt-1">
            Assignment: The Customer may not assign this Agreement without the prior written consent of the Company,
            except in the case of a substantial asset transfer or corporate restructuring.
          </BlackBodyText300>
          <BlackBodyText300 className="mt-1">
            Amendment: No modification of this Agreement shall be effective unless it is in writing and signed by both
            parties.
          </BlackBodyText300>
          <BlackBodyText300 className="mt-1">
            Notices: All notices must be in writing, deemed received upon receipt, and sent to the addresses specified
            in the Order Form or as subsequently provided.
          </BlackBodyText300>
        </div>
      </div>
    </>
  );
}

export default TermsPage;
