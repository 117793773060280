import { useEffect } from "react";
import { useRouteMatch } from "react-router";
import { Switch, Route } from "react-router-dom";
import AuthFlowBackButton from "../../components/button/AuthFlowBackButton";
import { useAuthRedirect, usePreloadAuthImages } from "../../hooks/auth.hooks";
import ForgotPasswordPage from "./ForgotPasswordPage";
import LoginPage from "./LoginPage";
import SignUpPage from "./SignUpPage";
import UnConfirmedUserPage from "./UnConfirmedUserPage";

const AuthNavigator = (props) => {
  useAuthRedirect();
  usePreloadAuthImages();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/register`} component={SignUpPage} />
      <Route path={`${path}/forgot-password`} component={ForgotPasswordPage} />
      <Route path={`${path}/unconfirmed-user`} component={UnConfirmedUserPage} />
      <Route path={path} component={LoginPage} />
    </Switch>
  );
};
export default AuthNavigator;
