import React, { PropsWithChildren } from "react";
import { InvoicePaymentState } from "types/invoice.types";

type Props = PropsWithChildren<{
  paymentState: InvoicePaymentState;
}>;

function InvoiceTableRow({ paymentState, children }: Props) {
  const isUnpaid = paymentState === InvoicePaymentState.UNPAID;
  return <p className={`${isUnpaid ? "text-cherry-500" : ""} d-flex align-items-center`}>{children}</p>;
}

export default InvoiceTableRow;
