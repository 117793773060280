import React from "react";

type Props = {
  rank: number;
  iExtensionVisible?: boolean;
};

export default function RankingCircleBadge({ iExtensionVisible, rank }: Props) {
  const getExtension = () => {
    switch (rank) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "";
    }
  };
  return (
    <div className="circle-badge text-white">
      <span>{rank}</span>
      <span className="fs-10px" style={{ paddingTop: 2 }}>
        {iExtensionVisible && getExtension()}
      </span>
    </div>
  );
}
