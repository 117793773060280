import React, { useCallback } from "react";
import { useHistory } from "react-router";
import OutlinedButton from "./OutlinedButton";

export default function HomeButton(props) {
  /**
   * Hooks
   */
  const history = useHistory();

  /**
   * Handlers
   */
  const handleHomeButtonClicked = useCallback(() => {
    history.push("/");
  }, [history]);

  return <OutlinedButton className="w-100" text={"Home"} type="reset" onClick={handleHomeButtonClicked} {...props} />;
}
