import { useCallback, useEffect, useState } from "react";
import DriverDetailsSectionFormBox from "./DriverDetailsSectionFormBox";
import DriverDetailsSectionCard from "./driver-details/DriverDetailsSectionCard";

import ArrowUpRightIcon from "components/icon/icons/ArrowUpRightIcon";
import EditorModal from "components/modal/EditorModal";
import { useAlertManager } from "hooks/alert.hooks";
import { useDriverFormPersonalInformationStepStates } from "hooks/driver.hooks";
import { useAppDispatch } from "hooks/redux.hooks";
import parsePhoneNumberFromString from "libphonenumber-js";
import { useForm } from "react-hook-form";
import { updateDriverAsync } from "services/driver.services";
import {
  Driver,
  DriverFormState,
  DriverUpdateCommand,
  DriverUpdatePersonalInformationCommand
} from "types/driver.types";
import { handleApiErrorResponse } from "utils";
import { getDriverFullName } from "utils/driver.utils";
import { getFormattedPhoneNumber } from "utils/format.utils";
import { useFleetGroupBadgeDeeds } from "../../hooks/fleet-group.hooks";
import DynamicFleetGroupBadgeList from "../list/DynamicFleetGroupBadgeList";
import DriverFormPersonalInformationStep from "./form/DriverFormPersonalInformationStep";
import HoverUnderlinedTextButton from "../button/HoverUnderlinedTextButton";
import { TextButtonMode } from "../button/TextButton";

type Props = {
  isLoading: boolean;
  driver?: Driver;
  refetchDriver: () => Promise<void>
};

type EditDriverPersonalInformationFormState = {
  phoneNumber: string;
};

export default function DriverDetailsPagePersonalInformationCard({ isLoading, driver, refetchDriver }: Props) {
  /**
   * Hooks
   */

  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();

  const dispatch = useAppDispatch();

  const personalInformationStepStates = useDriverFormPersonalInformationStepStates();
  const { handleInitPersonalInformationStatesForEditForm } = personalInformationStepStates;

  const { handleSubmit, errors, trigger, reset, formState, register, control } = useForm<DriverFormState>({
    mode: "all"
  });

  const commonFormStepProps = { register, control, errors };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fleetGroups = driver?.groups ?? [];
  const { getGroupBadgeContent } = useFleetGroupBadgeDeeds(fleetGroups);

  /**
   * Effects
   */

  /**
   * Set initial values
   */
  useEffect(() => {
    if (!driver) {
      return;
    }
    const phoneNumberWithoutDialCode = parsePhoneNumberFromString(driver.phoneNumber)?.nationalNumber ?? "";

    handleInitPersonalInformationStatesForEditForm(driver);
    reset({ phoneNumber: phoneNumberWithoutDialCode });
  }, [driver, handleInitPersonalInformationStatesForEditForm, reset]);

  /**
   * Handlers
   */
  const handleSubmitAsync = useCallback(
    async (validData: EditDriverPersonalInformationFormState) => {
      if (!driver) {
        return;
      }
      try {
        const { dialCode, groupIds } = personalInformationStepStates;

        setIsSubmitting(true);

        const payload: DriverUpdatePersonalInformationCommand = {
          command: DriverUpdateCommand.UPDATE_PERSONAL_INFORMATION,
          phoneNumber: dialCode + validData.phoneNumber.trim(),
          groups: groupIds
        };

        await updateDriverAsync(driver.id, payload);

        setIsModalOpen(false);
        handleOpenSuccessAlert("Personal information successfully updated!");
        refetchDriver();
      } catch (error) {
        console.error(error);
        handleOpenErrorAlert(handleApiErrorResponse(error));
      } finally {
        setIsSubmitting(false);
      }
    },
    [driver, handleOpenErrorAlert, handleOpenSuccessAlert, personalInformationStepStates, refetchDriver]
  );

  /**
   * Values for rendering
   */
  const driverEmail = driver?.email;
  const driverFullName = getDriverFullName(driver);
  const phoneNumber = getFormattedPhoneNumber(driver?.phoneNumber);

  return (
    <>
      <EditorModal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        onSubmit={handleSubmit(handleSubmitAsync)}
        isLoading={isSubmitting}
      >
        <DriverFormPersonalInformationStep isEditMode {...commonFormStepProps} {...personalInformationStepStates} />
      </EditorModal>
      <DriverDetailsSectionCard
        loadingCount={5}
        onEditButtonPressed={() => setIsModalOpen(true)}
        isLoading={isLoading}
        headerTitle={"Personal information"}
        isEditButtonShowing
        content={
          <>
            <DriverDetailsSectionFormBox label={"Full Name"} value={driverFullName} />
            <DriverDetailsSectionFormBox label={"Email"}>
              <HoverUnderlinedTextButton
                mode={TextButtonMode.BLUE}
                icon={<ArrowUpRightIcon />}
                text={driverEmail}
                onClick={() => window.open("mailto:" + driverEmail)}
                className="d-flex align-items-center clickable"
              />


            </DriverDetailsSectionFormBox>
            <DriverDetailsSectionFormBox label={"Phone Number"} value={phoneNumber} />
            <DriverDetailsSectionFormBox isBorderHidden label={"Fleet Group (Optional)"}>
              <DynamicFleetGroupBadgeList getGroupBadgeContent={getGroupBadgeContent} fleetGroups={fleetGroups} />
            </DriverDetailsSectionFormBox>
          </>
        }
      />
    </>
  );
}
