import React from "react";

type Props = {};

function SettingsSecurityIcon({}: Props) {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6037 23.4617C3.87439 20.1536 3.32691 9.7775 4.17283 4.75038C4.19791 4.60139 4.31015 4.48679 4.45718 4.452L12.622 2.51988C12.6772 2.50679 12.7348 2.50625 12.7903 2.51829L21.7006 4.44988C21.8635 4.4852 21.9851 4.62707 21.9985 4.79323C23.06 17.9315 16.3963 22.7009 12.8089 23.4786C12.7401 23.4935 12.6695 23.4866 12.6037 23.4617Z"
        fill="#0C0C0C"
      />
    </svg>
  );
}

export default SettingsSecurityIcon;
