import React from "react";
import CenteredHeader from "../header/CenteredHeader";
import LoadingSkeleton from "../loading/LoadingSkeleton";

type Props = {
  readonly vehicleLabel: string;
};

function AssignDriverHeader({ vehicleLabel }: Props) {
  return <CenteredHeader title="Assign Driver" description={vehicleLabel} />;
}

export default AssignDriverHeader;
