import { useEffect } from "react";
import { BlockTitle } from "../Component";
import { BlockTitleTertiary } from "../block/Block";
import HomeButton from "../button/HomeButton";
import Card from "../card/Card";
import Col from "../custom/Col";
import Row from "../custom/Row";
import TickCircleIcon from "../icon/icons/TickCircleIcon";

import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { DriverFormMode, DriverFormStatus } from "types/driver.types";
import SeeDriversButton from "../button/SeeDriversButton";

export default function DriverFormCompletedContent() {
  const history = useHistory();
  const {
    mode: formMode,
    status: formStatus,
    successMessageHelper,
  } = useSelector((state) => state.driver?.driverNonFormState);


  const isFormModeBulkUpload = formMode === DriverFormMode.BULK;

  const isFormCompleted = formStatus === DriverFormStatus.COMPLETED;

  useEffect(() => {
    if (!isFormCompleted) {
      history.push("/");
    }
  }, [history, isFormCompleted]);

  return (
    <>
      <div className="my-5 py-5 center">
        <Card padding={"p-4"} className={"d-flex flex-column center w-max-100 wide-sm-fix"}>
          <TickCircleIcon fill={"#23D206"} width={100} height={100} />
      
          <BlockTitle page className={"mt-5 text-center"}>
            {isFormModeBulkUpload ? "Drivers" : "Driver"} Added Successfully
          </BlockTitle>
          <BlockTitleTertiary className={"mt-2 px-md-4 w-550px w-max-100 text-center"}>
            {isFormModeBulkUpload
              ? ` drivers is now part of your fleet. You can view or edit their details in the 'Drivers' section.`
              : `${successMessageHelper.driverName} is now part of your fleet. You can view or edit their details in the 'Drivers'
            section.`}
          </BlockTitleTertiary>

          <Row className="w-100 p-md-4  mt-4 mt-md-2">
            <Col md="6" className="p-2">
              <HomeButton onClick={() => history.replace("/")} />
            </Col>
            <Col md="6" className="p-2">
            <SeeDriversButton /> 
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
}
