import { SvgProps } from "types/svg.types";

type Props = {};

function ReferralCodeIcon(props: SvgProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1331 3.37272L16.6277 4.86733C17.0679 5.30756 17.0683 6.02099 16.6277 6.46157L16.0847 7.00451C15.9375 7.1517 15.9083 7.38155 16.0049 7.57169C16.4541 8.45544 16.756 9.53117 16.7282 10.5423C16.7277 10.5597 16.7377 10.5746 16.7545 10.5823C16.7711 10.5898 16.7867 10.5871 16.7979 10.5759L18.77 8.60387C20.3932 6.98059 20.3934 4.34843 18.77 2.72504L17.2754 1.23043C15.652 -0.392962 13.0198 -0.392849 11.3965 1.23043L6.16757 6.45939C4.54429 8.08266 4.54418 10.7148 6.16757 12.3382L6.91457 13.0852C7.09342 13.2641 7.3834 13.2641 7.56225 13.0852L9.05625 11.5912C9.23481 11.4126 9.23514 11.1232 9.05698 10.9443L8.78636 10.6724L8.30986 10.1959C7.86964 9.7557 7.86927 9.04227 8.30986 8.60168L13.5388 3.37272C13.9794 2.93213 14.6928 2.9325 15.1331 3.37272ZM4.86086 16.6341L3.36625 15.1395C2.92603 14.6993 2.92567 13.9859 3.36625 13.5453L3.90919 13.0024C4.05638 12.8552 4.08565 12.6253 3.989 12.4352C3.53981 11.5514 3.23791 10.4757 3.26577 9.46461C3.26625 9.44718 3.25625 9.43223 3.23938 9.42457C3.22283 9.41706 3.20722 9.41974 3.19602 9.43093L1.22396 11.403C-0.399318 13.0263 -0.399431 15.6584 1.22396 17.2818L2.71857 18.7764C4.34196 20.3998 6.97412 20.3997 8.5974 18.7764L13.8264 13.5475C15.4496 11.9242 15.4497 9.29204 13.8264 7.66865L13.0791 6.92144C12.9003 6.74258 12.6103 6.74258 12.4315 6.92144L10.9368 8.41605C10.758 8.5949 10.758 8.88488 10.9368 9.06373L11.6841 9.81094C12.1243 10.2512 12.1246 10.9646 11.6841 11.4052L6.45511 16.6341C6.01452 17.0747 5.30109 17.0744 4.86086 16.6341Z"
        fill={props.fill??"#2E3338"}
      />
    </svg>
  );
}

export default ReferralCodeIcon;
