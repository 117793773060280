import React from "react";
import SecondaryButton from "./SecondaryButton";

type Props = { onSecondaryStartButtonClicked: () => void };

function SecondaryStartButton({ onSecondaryStartButtonClicked }: Props) {
  return <SecondaryButton height={35} text="Start" onClick={onSecondaryStartButtonClicked} />;
}

export default SecondaryStartButton;
