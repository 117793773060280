import { SvgProps } from "types/svg.types";

const ReimbursementMenuIconOutlined = (props: SvgProps) => {
  return (
    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7268 10.0673C15.2623 9.29413 14.2569 9.04424 13.4807 9.50874L11.6345 10.62C11.6345 10.62 11.6345 10.6053 11.6345 10.5965C11.6345 9.69396 10.9025 8.95898 9.99699 8.95898C9.99111 8.95898 9.98817 8.95898 9.98229 8.95898C9.98229 8.95898 9.97641 8.95898 9.97347 8.95898H6.01346C4.93452 8.95898 3.88792 9.32941 3.053 10.0115L2.89424 10.1408L2.14752 10.7494C1.9623 10.9022 1.72711 10.9846 1.48604 10.9846H0.980383C0.965683 10.9846 0.950984 10.9846 0.936284 10.9846H0.568799C0.342429 10.9846 0.160156 11.1668 0.160156 11.3932V14.2037C0.160156 14.5271 0.424745 14.7917 0.748132 14.7917H10.1146C10.7173 14.7917 11.3111 14.6271 11.8285 14.3184L15.133 12.331C15.133 12.331 15.1535 12.3193 15.1653 12.3134C15.9385 11.8459 16.1884 10.8405 15.7239 10.0673H15.7268ZM10.9819 13.4511C10.7349 13.5452 10.3851 13.6099 10.1175 13.6099H1.34493V12.1635H1.49192C2.00346 12.1635 2.5003 11.9871 2.89718 11.6637L3.79973 10.9287C4.30833 10.5142 5.00214 10.2231 5.64891 10.1408H9.97935C10.1881 10.1408 10.388 10.276 10.438 10.4789C10.5144 10.7817 10.288 11.0522 9.99699 11.0522H6.65141C6.3486 11.0522 6.06343 11.258 6.02228 11.5578C5.9723 11.9224 6.25159 12.234 6.60731 12.234H10.6879C11.0524 12.234 11.4111 12.1341 11.7227 11.9459L12.7428 11.3315L14.0893 10.5201C14.3039 10.3907 14.5832 10.4613 14.7126 10.6759C14.839 10.8846 14.7743 11.1551 14.5714 11.2903C14.5656 11.2932 14.5626 11.2991 14.5567 11.3021C14.5567 11.3021 14.5538 11.3021 14.5509 11.3021C14.5479 11.3021 14.542 11.305 14.5391 11.3079L13.2073 12.1076L13.0809 12.184L10.9789 13.4482L10.9819 13.4511Z"
        fill="#6F7981" />
      <path
        d="M16.2605 4.79296H14.8846C14.7111 4.79296 14.5641 4.6636 14.5436 4.49015C14.3907 3.12899 13.2324 2.06475 11.833 2.06475C10.3278 2.06475 9.10479 3.28774 9.10479 4.79296H7.04688C7.04688 2.15295 9.19299 0.00683594 11.833 0.00683594C14.473 0.00683594 16.4133 1.95304 16.6044 4.4196C16.6191 4.62245 16.4604 4.7959 16.2605 4.7959V4.79296Z"
        fill="#6F7981" />
      <path
        d="M7.60371 7.08014L5.31942 4.63417C5.08129 4.3784 5.26062 3.96094 5.61047 3.96094H10.5877C10.9405 3.96094 11.1198 4.38134 10.8787 4.63711L8.55035 7.08603C8.29164 7.3565 7.85948 7.35355 7.60371 7.08014Z"
        fill="#6F7981" />
    </svg>

  );
};
export default ReimbursementMenuIconOutlined;
