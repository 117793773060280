import { useCallback } from "react";
import { closeAlert, setAlert } from "redux/slices/alertSlice";
import { AlertType } from "types/alert.types";
import { useAppDispatch, useAppSelector } from "./redux.hooks";

export const useAlertManager = () => {
  const dispatch = useAppDispatch();

  const handleOpenSuccessAlertWithAction = useCallback(
    (message: string, actionText: string, callback: () => void) => {
      dispatch(setAlert({ type: AlertType.SUCCESS_WITH_ACTION, message, actionText, callback }));
    },
    [dispatch]
  );

  const handleOpenSuccessAlert = useCallback(
    (message: string) => {
      dispatch(setAlert({ type: AlertType.SUCCESS, message }));
    },
    [dispatch]
  );


  const handleOpenWarningAlert = useCallback(
    (message: string) => {
      dispatch(setAlert({ type: AlertType.WARNING, message }));
    },
    [dispatch]
  );

  const handleOpenErrorAlert = useCallback(
    (message: string) => {
      dispatch(setAlert({ type: AlertType.ERROR, message }));
    },
    [dispatch]
  );


  const handleCloseAlert = useCallback(
    () => {
      dispatch(closeAlert());
    },
    [dispatch]
  );

  const handleShowAlertByType = useCallback(
    (isSuccess: boolean, message: string) => {
      if (isSuccess) {
        handleOpenSuccessAlert(message);
      } else {
        handleOpenErrorAlert(message);
      }
    },
    [handleOpenErrorAlert, handleOpenSuccessAlert]
  );


  return {
    ...useAppSelector(s => s.alert),
    handleOpenSuccessAlert,
    handleOpenErrorAlert,
    handleOpenWarningAlert,
    handleCloseAlert,
    handleShowAlertByType,
    handleOpenSuccessAlertWithAction
  };
};