// React
import React, { useCallback, useEffect } from "react";

// Third party
import mixpanel from "mixpanel-browser";
import { useLocation } from "react-router";

// Hooks & Utils
import { useAppSelector } from "./redux.hooks";

// Types
import { MixPanelEvents } from "../types/mixpanel.types";

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
export function useInitMixpanel() {
  const user = useAppSelector((state) => state.user.currentUser);
  const { pathname } = useLocation();

  /**
   *
   */
  useEffect(() => {
    if (!MIXPANEL_TOKEN) {
      return;
    }
    try {
      mixpanel.init(MIXPANEL_TOKEN);
    } catch (error) {
      console.log(error);
    }
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (!user) {
      return;
    }
    try {
      mixpanel.track(MixPanelEvents.VIEW_PAGENAME, { pathname: pathname, user: user });
    } catch (error) {
      console.log(error);
    }
  }, [pathname, user]);
}

export function useMixpanel() {
  const user = useAppSelector((state) => state.user.currentUser);
  const { pathname } = useLocation();
  const trackWithMixPanel = useCallback(
    (event: MixPanelEvents) => {
      if (!user) {
        return;
      }

      try {
        mixpanel.track(event, { pathname: pathname, user: user });
      } catch (error) {
        console.log(error);
      }
    },
    [pathname, user]
  );

  return { trackWithMixPanel };
}
