import React, { Dispatch, SetStateAction, SyntheticEvent, useCallback, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { BlackBodyText200, BlackBodyText300, BlackH3Text100, PurpleSubtitleText200 } from "../../text/Text";
import AuthFormInput from "./AuthFormInput";

import { AuthSignUpFormFlowSteps, AuthSignUpFormState } from "types/auth.types";

import ContinueButton from "components/button/ContinueButton";
import { useTranslation } from "react-i18next";

type Props = {
  readonly registerFormState: AuthSignUpFormState;
  readonly setFormFlowStep: Dispatch<SetStateAction<AuthSignUpFormFlowSteps>>;
  readonly onFormStateChanged: Dispatch<SetStateAction<AuthSignUpFormState>>;
};

type FormValues = {
  companyName: string;
};

function SignUpFormFlowCompanyStep({ onFormStateChanged, setFormFlowStep, registerFormState }: Props) {
  const { register, handleSubmit, trigger, errors } = useForm<FormValues>({
    mode: "all",
  });

  const { t } = useTranslation("common");

  /**
   *
   */
  const handleStepCompleted = useCallback(
    (values: FormValues) => {
      onFormStateChanged((oldState) => {
        return { ...oldState, companyName: values.companyName };
      });
      setFormFlowStep(AuthSignUpFormFlowSteps.CREDENTIALS);
    },
    [onFormStateChanged, setFormFlowStep]
  );

  /**
   *
   */
  const handleFormSubmit = useCallback(
    async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
      e.preventDefault();

      await trigger();

      handleSubmit(handleStepCompleted)(e);
    },
    [handleStepCompleted, handleSubmit, trigger]
  );

  return (
    <form onSubmit={handleFormSubmit}>
      <BlackH3Text100>{t("auth.sign_up.flow_steps.company.title")}</BlackH3Text100>

      <AuthFormInput
        defaultValue={registerFormState?.companyName}
        register={register}
        type="text"
        placeholder="Company name"
        name="companyName"
        aria-label="companyName"
        required
        className="w-max-100 w-100 mt-4"
      />

      <ContinueButton type="submit" className={"mt-4 w-100"} />
    </form>
  );
}

export default SignUpFormFlowCompanyStep;
