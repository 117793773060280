import React from "react";
import { SvgProps } from "../../../types/svg.types";

const EmptyBoxWithInfoIcon = (props: SvgProps) => {
  return (

    <svg width="126" height="105" viewBox="0 0 126 105" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_d_34725_88966)">
        <ellipse cx="63.1882" cy="85.8129" rx="56.0124" ry="4.33245" fill="#D9D9D9" />
      </g>
      <rect x="17.2852" y="0.621094" width="93.4572" height="60.6543" fill="#CBD5E1" />
      <path d="M124.355 35.2807L110.738 0.621094V44.5645L124.355 68.7025V35.2807Z" fill="#A4B1C5" />
      <path d="M17.2825 0L1.5 35.8975L17.2825 47.3475V0Z" fill="#A4B1C5" />
      <path
        d="M1.91 36.1756H124.09V91.5256C124.09 93.5636 122.438 95.2156 120.4 95.2156H5.6C3.56207 95.2156 1.91 93.5636 1.91 91.5256V36.1756Z"
        fill="white" stroke="#CBD5E1" strokeWidth="0.82" />
      <path d="M34.5 44.4258L30 36.4258H100.5L94 44.4258H34.5Z" fill="#CBD5E1" />
      <path
        d="M62.5 57.9062C55.5559 57.9062 49.9062 63.5559 49.9062 70.5C49.9062 77.4441 55.5559 83.0938 62.5 83.0938C69.4441 83.0938 75.0938 77.4441 75.0938 70.5C75.0938 63.5559 69.4441 57.9062 62.5 57.9062ZM62.5 77.2758C62.2605 77.2758 62.0264 77.2048 61.8272 77.0717C61.6281 76.9387 61.4729 76.7495 61.3812 76.5283C61.2896 76.307 61.2656 76.0635 61.3123 75.8286C61.3591 75.5937 61.4744 75.378 61.6437 75.2086C61.8131 75.0392 62.0289 74.9239 62.2638 74.8772C62.4987 74.8305 62.7421 74.8545 62.9634 74.9461C63.1847 75.0378 63.3738 75.193 63.5069 75.3921C63.6399 75.5912 63.7109 75.8254 63.7109 76.0649C63.7109 76.386 63.5834 76.694 63.3563 76.9211C63.1292 77.1482 62.8212 77.2758 62.5 77.2758ZM63.8151 65.0968L63.4675 72.4835C63.4675 72.7404 63.3655 72.9868 63.1838 73.1685C63.0021 73.3502 62.7557 73.4523 62.4988 73.4523C62.2419 73.4523 61.9955 73.3502 61.8138 73.1685C61.6321 72.9868 61.53 72.7404 61.53 72.4835L61.1825 65.1004V65.0974C61.1749 64.92 61.2033 64.7428 61.2659 64.5766C61.3286 64.4104 61.4242 64.2586 61.5471 64.1304C61.6699 64.0021 61.8175 63.9001 61.9808 63.8303C62.1442 63.7606 62.32 63.7247 62.4976 63.7247C62.6752 63.7247 62.851 63.7606 63.0143 63.8303C63.1777 63.9001 63.3252 64.0021 63.4481 64.1304C63.5709 64.2586 63.6666 64.4104 63.7292 64.5766C63.7919 64.7428 63.8203 64.92 63.8127 65.0974L63.8151 65.0968Z"
        fill="black" />
      <defs>
        <filter id="filter0_d_34725_88966" x="0.615781" y="81.4805" width="125.143" height="23.4241"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha" />
          <feOffset dy="8.2" />
          <feGaussianBlur stdDeviation="3.28" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_34725_88966" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_34725_88966" result="shape" />
        </filter>
      </defs>
    </svg>

  );
};

export default EmptyBoxWithInfoIcon;