import React from "react";

type Props = {};

function FleetGroupsMenuIconOutlined({}: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.50001 12.999C6.16325 12.999 6.79826 12.7344 7.26747 12.2652C7.73667 11.796 8.00126 11.161 8.00126 10.4977C8.00126 9.83448 7.73667 9.19947 7.26747 8.73027C6.79826 8.26106 6.16325 8 5.50001 8C4.83678 8 4.20176 8.26459 3.73256 8.73027C3.26335 9.19947 2.99876 9.83448 2.99876 10.4977C2.99876 11.161 3.26335 11.796 3.73256 12.2652C4.20176 12.7344 4.83678 12.999 5.50001 12.999ZM21.0014 10.4977C21.0014 11.161 20.7368 11.796 20.2676 12.2652C19.7984 12.7344 19.1634 12.999 18.5002 12.999C17.8369 12.999 17.2019 12.7344 16.7327 12.2652C16.2635 11.796 15.9989 11.161 15.9989 10.4977C15.9989 9.83448 16.2635 9.19947 16.7327 8.73027C17.2019 8.26106 17.8369 8 18.5002 8C19.1634 8 19.7984 8.26459 20.2676 8.73027C20.7368 9.19947 21.0014 9.83448 21.0014 10.4977ZM5.00258 15.9976C5.00258 15.3062 5.10136 14.6359 5.29187 14.0044L5.12253 14.0185C4.26526 14.1138 3.47149 14.5195 2.89645 15.1615C2.32141 15.8036 2.00391 16.6362 2.00391 17.497V21.9985H5.00258V15.9976ZM22.0033 21.9985V17.497C22.0033 16.6044 21.6646 15.7472 21.0508 15.098C20.4405 14.4489 19.6044 14.0573 18.7154 14.0044C18.9023 14.6359 19.0046 15.3062 19.0046 15.9976V21.9985H22.0033Z"
        fill="#6F7981"
      />
      <path
        d="M11.9986 3.41114C12.6901 3.41114 13.3392 3.67926 13.8296 4.16963C14.3129 4.65295 14.5881 5.31971 14.5881 6.00059C14.5881 6.68146 14.3129 7.34823 13.8296 7.83154C13.3428 8.32192 12.6901 8.59003 11.9986 8.59003C11.3072 8.59003 10.6581 8.32192 10.1677 7.83154C9.68436 7.34823 9.40919 6.68146 9.40919 6.00059C9.40919 5.31971 9.68436 4.65295 10.1677 4.16963C10.6545 3.67926 11.3072 3.41114 11.9986 3.41114ZM11.9986 2C10.9368 2 9.92073 2.41981 9.1693 3.17125C8.41786 3.92268 7.99805 4.9387 7.99805 6.00059C7.99805 7.06247 8.41786 8.0785 9.1693 8.82993C9.92073 9.58136 10.9368 10.0012 11.9986 10.0012C13.0605 10.0012 14.0765 9.58136 14.828 8.82993C15.5794 8.0785 15.9992 7.06247 15.9992 6.00059C15.9992 4.9387 15.5794 3.92268 14.828 3.17125C14.0765 2.41981 13.0605 2 11.9986 2Z"
        fill="#6F7981"
      />
      <path
        d="M11.9986 12.4111C12.9582 12.4111 13.8578 12.7851 14.5351 13.4624C15.2019 14.1292 15.5864 15.057 15.5864 15.999V20.5887H8.40724V15.999C8.40724 15.0535 8.79177 14.1292 9.45854 13.4624C10.1359 12.7851 11.039 12.4111 11.9951 12.4111M11.9951 11C10.6686 11 9.39856 11.5257 8.46015 12.4641C7.52174 13.4025 6.99609 14.676 6.99609 15.999V21.9999H16.9976V15.999C16.9976 14.6725 16.4719 13.4025 15.5335 12.4641C14.5951 11.5257 13.3251 11 11.9986 11H11.9951Z"
        fill="#6F7981"
      />
    </svg>
  );
}

export default FleetGroupsMenuIconOutlined;
