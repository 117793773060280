import React from "react";

const DriversMenuIconFilled = ({ fill, width, height }: { fill?: string; width?: number; height?: number }) => {
  const fillValue = fill ?? "#680031";
  const widthValue = width ?? 20;
  const heightValue = height ?? 20;

  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9989 6.99898C15.9989 9.76129 13.7587 12.0015 10.9999 12.0015C8.24116 12.0015 6.00098 9.76129 6.00098 6.99898C6.00098 4.23667 8.24116 2 10.9999 2C13.7587 2 15.9989 4.24019 15.9989 6.99898Z"
        fill="#0C0C0C"
      />
      <path
        d="M15.7763 13.9519C15.8398 14.1036 15.7692 14.28 15.6175 14.3435C15.2859 14.4846 14.986 14.6857 14.7356 14.9397C14.4851 15.1902 14.2805 15.4901 14.1429 15.8217C14.0018 16.1533 13.9241 16.5202 13.9241 16.9012C13.9241 17.2822 14.0018 17.6491 14.1429 17.9807C14.284 18.3124 14.4851 18.6122 14.7391 18.8662C14.9896 19.1167 15.2894 19.3213 15.621 19.4589C15.7727 19.5224 15.8468 19.6988 15.7798 19.8505L15.0319 21.625C14.9684 21.7767 14.792 21.8473 14.6403 21.7838C14.0053 21.5156 13.4373 21.1241 12.9575 20.6478C12.4777 20.168 12.0897 19.5965 11.8216 18.9615C11.5534 18.3265 11.4053 17.6279 11.4053 16.8977C11.4053 16.1674 11.5534 15.4689 11.8216 14.8339C12.0897 14.1989 12.4777 13.6274 12.9575 13.1476C13.4373 12.6678 14.0088 12.2797 14.6438 12.0116C14.7955 11.9481 14.9719 12.0187 15.0354 12.1704L15.7833 13.9449L15.7763 13.9519Z"
        fill="#0C0C0C"
      />
      <path
        d="M17.6182 19.8536C17.5547 19.7019 17.6252 19.5255 17.7769 19.462C18.1086 19.3209 18.4084 19.1198 18.6589 18.8693C18.9094 18.6188 19.114 18.319 19.2516 17.9873C19.3927 17.6557 19.4703 17.2888 19.4703 16.9078C19.4703 16.5268 19.3927 16.1599 19.2516 15.8283C19.1105 15.4967 18.9094 15.1968 18.6554 14.9463C18.4049 14.6959 18.105 14.4912 17.7734 14.3501C17.6217 14.2866 17.5476 14.1102 17.6146 13.9585L18.3626 12.184C18.4261 12.0323 18.6024 11.9618 18.7541 12.0253C19.3892 12.2934 19.9571 12.6814 20.4369 13.1577C20.9167 13.6375 21.3048 14.209 21.5729 14.844C21.841 15.479 21.9892 16.1776 21.9892 16.9078C21.9892 17.6381 21.841 18.3366 21.5729 18.9716C21.3048 19.6066 20.9167 20.1746 20.4369 20.6544C19.9571 21.1307 19.3856 21.5223 18.7506 21.7904C18.5989 21.8539 18.4225 21.7833 18.359 21.6316L17.6111 19.8571L17.6182 19.8536Z"
        fill="#0C0C0C"
      />
      <path
        d="M9.66625 17.3711C9.65567 17.2405 9.64861 17.11 9.64509 16.9795C9.64509 16.9548 9.64158 16.9265 9.64158 16.9019C9.64158 16.8948 9.64158 16.8877 9.64158 16.8842C9.64158 16.8736 9.64158 16.863 9.64158 16.8525C9.64158 16.6302 9.65568 16.4115 9.67685 16.1963C9.67685 16.1892 9.67685 16.1822 9.67685 16.1751C9.69801 15.9846 9.72623 15.7941 9.76151 15.6107C9.82149 15.2861 9.90263 14.9721 10.0085 14.6652C10.1178 14.3371 9.88146 14.002 9.53926 14.002H8.24454C4.79429 14.002 1.99316 16.7995 1.99316 20.2533V21.5022C1.99316 21.7773 2.21543 22.0031 2.49414 22.0031H10.707C11.1268 22.0031 11.3526 21.5233 11.0951 21.1882C10.2766 20.1193 9.758 18.8069 9.66274 17.3746L9.66625 17.3711Z"
        fill="#0C0C0C"
      />
    </svg>
  );
};
export default DriversMenuIconFilled;

/**
 *   width={`${widthValue}`}
      height={`${heightValue}`}
      viewBox={`0 0 ${widthValue} ${heightValue} `}
 */
