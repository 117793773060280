import React from "react";

import CopyCodeButton from "components/button/CopyCodeButton";
import DriverSelfSignupHelperButtonAndModal from "components/driver-components/DriverSelfSignupHelperButtonAndModal";
import DriversPageTab from "components/driver-components/DriversPageTab";
import WhiteReferralCodeIcon from "components/icon/icons/ReferralCodeIcon";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { BlackBodyText100, BlackBodyText200 } from "components/text/Text";
import { useAlertManager } from "hooks/alert.hooks";
import { useAuthStatus } from "hooks/auth.hooks";
import { useBranchIoDriverAppLink } from "hooks/driver.hooks";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ErrorBoundary } from "react-error-boundary";
import { useLocation, useRouteMatch } from "react-router";
import { DriversTableType } from "types/driver.types";
import Row from "../../components/custom/Row";
import PageHeader from "../../components/header/PageHeader";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

type Props = {
  tableComponent: JSX.Element
  isLoading: boolean
}

const DriversPage = ({ tableComponent, isLoading }: Props) => {
  const { user } = useAuthStatus();

  const { appLink } = useBranchIoDriverAppLink();

  const { handleOpenSuccessAlert } = useAlertManager();


  return (
    <React.Fragment>
      <Head title={"Drivers"} />
      <Content>
        <PageHeader
          title="Drivers"
          overrideDescription={
            isLoading ? (
              <div className="w-max-100 w-550px mt-1">
                <LoadingSkeleton count={1} />
              </div>
            ) : (
              <>
                <Row className="align-items-center mt-1 ">
                  <BlackBodyText200>View and manage all your drivers. Your organization has</BlackBodyText200>
                  <BlackBodyText100 className="ml-1">{user?.driverCount} total drivers.</BlackBodyText100>
                </Row>

                <Row className="align-items-center mt-1">
                  <BlackBodyText200 className="mr-1">Unique fleet code: </BlackBodyText200>
                  <BlackBodyText100>{user?.signUpCode.code}</BlackBodyText100>
                  <DriverSelfSignupHelperButtonAndModal />
                  <CopyToClipboard
                    disabled={!appLink}
                    text={`Driver app: ${appLink}, Unique fleet code: ${user?.signUpCode.code}`}
                    onCopy={() => handleOpenSuccessAlert("Copied to clipboard!")}
                  >
                    <CopyCodeButton
                      className="ml-2"
                      isDisabled={!appLink}
                      height={36}
                      text="Copy code & link"
                      leftIcon={<WhiteReferralCodeIcon className="mr-2" />}
                    />
                  </CopyToClipboard>
                </Row>
              </>
            )
          }
        />

        <div className="my-3">
          {isLoading ? (
            <div className="w-max-100 w-550px mt-1">
              <LoadingSkeleton count={1} />
            </div>
          ) : (
            <DriversPageTab
              pendingSignUpApplicationCount={user?.pendingSignUpApplicationCount}
            />
          )}

        </div>

        <ErrorBoundary fallback={<>An error occurred.</>}>
          {tableComponent}
        </ErrorBoundary>
      </Content>
    </React.Fragment>
  );
};

export default DriversPage;
