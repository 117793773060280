import React, { useCallback, useRef, useState } from "react";
import { Autocomplete, GoogleMap, Rectangle } from "@react-google-maps/api";
import { ErrorBoundary } from "react-error-boundary";
import { CircularProgress } from "@mui/material";
import { useResizeMapFullWidthHeight } from "../../hooks/google-maps.hooks";
import { NeutralTitleSmallTextBlack } from "../text/Text";
import { DriverFormReimbursementStepStateHooks } from "../../types/reimbursement.types";
import { useGoogleAutoComplete } from "../../hooks/reimbursement.hooks";


type Props = {
  initialAddress?: string;
  isReadonly?: boolean;
  onMapEditButtonPressed?: () => void
  mapId?: string
} & DriverFormReimbursementStepStateHooks

const ReimbursementMap: React.FC<Props> = ({
                                             setBoundsToSubmit,
                                             bounds,
                                             setBounds,
                                             center,
                                             address
                                             , isLoading,
                                             isGoogleApiLoaded,
                                             isReadonly,
                                             setNewInitialAddress,
                                             zoom,
                                             mapId = "map-container"
                                           }: Props) => {


  const [map, setMap] = useState<google.maps.Map | null>(null);

  const { mapDimensions } = useResizeMapFullWidthHeight(map, mapId);


  const rectangleRef = useRef<google.maps.Rectangle | null>(null);


  const onEdit = useCallback(() => {
    if (rectangleRef.current) {


      const newBounds = rectangleRef.current.getBounds()?.toJSON();
      if (newBounds) {
        setBounds(newBounds);
      }

    }
  }, [setBounds]);


  const onBoundsChanged = useCallback(() => {
    if (rectangleRef.current) {

      const bounds = rectangleRef.current.getBounds()?.toJSON();
      if (bounds) {
        setBoundsToSubmit(bounds); // Optionally set the bounds as before
      }

    }
  }, [setBoundsToSubmit]);


  const { ref } = useGoogleAutoComplete((place) => console.log("place", place));
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);


  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place?.formatted_address)
        setNewInitialAddress(place?.formatted_address);
    }
  };


  return (
    <div id={mapId} className={"h-100 w-100"}>
      {(!isGoogleApiLoaded || isLoading || !center) ? (<div className={"center h-300px w-100"}>
            <CircularProgress size={75} style={{ color: "#1E44FF" }} />
          </div>
        ) :
        <>
          <NeutralTitleSmallTextBlack className={"mb-1"}>Home address:</NeutralTitleSmallTextBlack>

          <Autocomplete onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                        onPlaceChanged={onPlaceChanged}>
            <input
              id={"google-autocomplete-address"}
              className="form-control mb-4"
              defaultValue={address}
              placeholder={address}
            />
          </Autocomplete>

          <GoogleMap
            options={{
              streetViewControl:false,
              fullscreenControl: false,
            }}
            mapContainerStyle={mapDimensions}
            mapContainerClassName="map-container"
            center={center}
            zoom={zoom}
            onLoad={mapInstance => setMap(mapInstance)}
          >
            <Rectangle
              bounds={bounds}
              editable={!isReadonly}
              draggable={!isReadonly}
              onBoundsChanged={onBoundsChanged}
              onDragEnd={onEdit}
              onLoad={rectangle => (rectangleRef.current = rectangle)}
              onUnmount={() => (rectangleRef.current = null)}
            />
          </GoogleMap>
        </>
      }
    </div>
  );
};

const ReimbursementMapWithErrorBoundary = (props: Props) => {

  if (!props.isGoogleApiLoaded) {
    return <></>;
  }

  return <ErrorBoundary fallback={<>Error</>}>
    <ReimbursementMap {...props} />
  </ErrorBoundary>;
};

export default ReimbursementMapWithErrorBoundary;

