import Row from "components/custom/Row";
import { useHistory } from "react-router";
import { Vehicle } from "types/vehicle.types";
import { getVehicleSummaryName } from "utils/vehicle.utils";

type Props = {
  vehicles: Vehicle[];
  isForwardActive?: boolean;
};

function VehicleList({ vehicles, isForwardActive }: Props) {
  const history = useHistory();

  const handleForward = (v: Vehicle) => {
    if (!isForwardActive) {
      return;
    }
    history.push(`/vehicles/${v.id}`);
  };

  return (
    <Row className="h-auto align-items-center py-2">
      {vehicles?.map((v, ix) => (
        <span key={ix} onClick={() => handleForward(v)} className="list-badge">
          {getVehicleSummaryName(v)}
        </span>
      ))}
    </Row>
  );
}

export default VehicleList;
