import { useCallback, useEffect, useState } from "react";
import DriverDetailsSectionCard from "./driver-details/DriverDetailsSectionCard";

import { Icon } from "components/Component";
import CancelButton from "components/button/CancelButton";
import PrimaryButton from "components/button/PrimaryButton";
import YesButton from "components/button/YesButton";
import HorizontalLine from "components/line/HorizontalLine";
import EditorModal from "components/modal/EditorModal";
import AlertConfirmDialog from "components/notification/AlertConfirmDialog";
import NoDataPlaceholder from "components/table/placeholder/NoDataPlaceholder";
import { useAlertManager } from "hooks/alert.hooks";
import { useVehicleDriverSchedulesAsync } from "hooks/data/vehicle-driver-schedule-data-accessor.hooks";
import { useDriverFormVehicleSetupStepStates } from "hooks/driver.hooks";
import { useAppDispatch } from "hooks/redux.hooks";
import { useForm } from "react-hook-form";
import { updateVehicleDriverScheduleAsync } from "services/vehicle-driver-schedule.services";
import { Driver, DriverFormState } from "types/driver.types";
import { VehicleDriverSchedule, VehicleDriverScheduleUpdatePayload } from "types/vehicle-driver-schedule.types";
import { handleApiErrorResponse } from "utils";
import { getVehicleDriverSchedulePeriodsForPayload } from "utils/vehicle-driver-schedule.utils";
import DriverVehicleScheduleCard from "./DriverVehicleScheduleCard";
import DriverFormVehicleSetupStep from "./form/DriverFormVehicleSetupStep";
import SecondaryButton from "../button/SecondaryButton";
import OutlinedButtonWithIcon from "../button/OutlinedButtonWithIcon";

type Props = {
  isLoading: boolean;
  driver?: Driver;
};

export default function DriverDetailsPageVehicleSetupCard({ isLoading, driver }: Props) {
  /**
   * Hooks
   */

  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();

  const dispatch = useAppDispatch();

  const [selectedScheduleToUpdate, setSelectedScheduleToUpdate] = useState<VehicleDriverSchedule | undefined>(
    undefined
  );

  const { isLoading: isSchedulesLoading, schedules, refetch } = useVehicleDriverSchedulesAsync();

  const driverFormVehicleSetupStepStates = useDriverFormVehicleSetupStepStates();
  const {
    vehicleId,
    isVehicleSelected,
    handleSubmitAddScheduleAsync,
    handleResetState,
    setSelectedScheduleIdToDelete,
    selectedScheduleIdToDelete,
    handleDeleteScheduleAsync,
  } = driverFormVehicleSetupStepStates;

  const { handleSubmit, errors, reset, register, control } = useForm<DriverFormState>({
    mode: "all",
  });

  const commonFormStepProps = { register, control, errors };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isEditMode = !!selectedScheduleToUpdate;

  /**
   * Effects
   */

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedScheduleToUpdate(undefined);
    }
  }, [isModalOpen]);

  /**
   * Handlers
   */

  const handleEditClicked = useCallback((schedule: VehicleDriverSchedule) => {
    setSelectedScheduleToUpdate(schedule);
    setIsModalOpen(true);
  }, []);

  const handleSubmitUpdateAsync = useCallback(async () => {
    if (!driver || !selectedScheduleToUpdate) {
      return;
    }

    const { vehicleDriverSchedulePeriodSelector } = driverFormVehicleSetupStepStates;
    const { startsAt, endsAt } = vehicleDriverSchedulePeriodSelector;

    if (!vehicleId) {
      throw Error("Please select vehicle");
    }

    try {
      setIsSubmitting(true);
      const payload: VehicleDriverScheduleUpdatePayload = {
        ...getVehicleDriverSchedulePeriodsForPayload(startsAt, endsAt),
      };
      await updateVehicleDriverScheduleAsync(selectedScheduleToUpdate.id, payload);
      setIsModalOpen(false);
      refetch();
      handleOpenSuccessAlert("Schedule is successfully updated!");
    } catch (error) {
      console.error(error);
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  }, [
    driver,
    driverFormVehicleSetupStepStates,
    handleOpenErrorAlert,
    handleOpenSuccessAlert,
    refetch,
    selectedScheduleToUpdate,
    vehicleId,
  ]);

  const handleSubmitDeleteAsync = useCallback(async () => {
    setIsSubmitting(true);
    await handleDeleteScheduleAsync();
    setIsSubmitting(false);
    setSelectedScheduleIdToDelete(undefined);
    refetch();
  }, [handleDeleteScheduleAsync, refetch, setSelectedScheduleIdToDelete]);

  const handleSubmitAddAsync = useCallback(async () => {
    if (!driver) {
      return;
    }

    try {
      setIsSubmitting(true);
      await handleSubmitAddScheduleAsync(driver.id);
      setIsModalOpen(false);
      handleOpenSuccessAlert("Schedule is successfully assigned!");
      refetch();
    } catch (error) {
      console.error(error);
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  }, [driver, handleOpenErrorAlert, handleOpenSuccessAlert, handleSubmitAddScheduleAsync, refetch]);

  /**
   * Values for rendering
   */

  return (
    <>
      <AlertConfirmDialog
        title={`Are you sure you want to ${
          selectedScheduleIdToDelete?.isActive ? "stop" : "delete"
        } this scheduled assignment?`}
        isDialogOpen={!!selectedScheduleIdToDelete}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setSelectedScheduleIdToDelete(undefined)} />
            </div>

            <YesButton isLoading={isSubmitting} onYesButtonPressed={handleSubmitDeleteAsync} />
          </>
        }
      />
      <EditorModal
        modalSize="wide-sm"
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        onSubmit={handleSubmit(isEditMode ? handleSubmitUpdateAsync : handleSubmitAddAsync)}
        isLoading={isSubmitting}
        isDisabled={!isVehicleSelected}
        submitButton={
          !isEditMode ? <PrimaryButton className="ml-2" text="Save assignment" isLoading={isSubmitting} /> : undefined
        }
      >
        <DriverFormVehicleSetupStep
          isPortal
          selectedScheduleToUpdate={selectedScheduleToUpdate}
          isEditMode={isEditMode}
          driverFormVehicleSetupStates={driverFormVehicleSetupStepStates}
          {...commonFormStepProps}
        />
      </EditorModal>
      <DriverDetailsSectionCard
        contentClassName="mt-2"
        loadingCount={5}
        isLoading={isLoading || isSchedulesLoading}
        headerTitle={"Vehicle setup"}
        headerButton={
          schedules.length < 3 ? (
            <OutlinedButtonWithIcon
              leftIcon={<Icon  name="plus" />}
              text="Assign vehicle"
              onClick={() => {
                handleResetState();
                setIsModalOpen(true);
              }}
            />
          ) : (
            <></>
          )
        }
        content={
          <>
            {schedules.length === 0 && <NoDataPlaceholder />}
            <div className="h-max-580px overflow-auto">
              {schedules.map((schedule, ix) => (
                <>
                  <HorizontalLine />
                  <DriverVehicleScheduleCard
                    ix={ix}
                    schedule={schedule}
                    onDeleteClicked={setSelectedScheduleIdToDelete}
                    onEditClicked={handleEditClicked}
                  />
                </>
              ))}
            </div>
          </>
        }
      />
    </>
  );
}
