import DateInputIcon from "components/icon/icons/DateInputIcon";
import { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type Props = {
  onDateChange: (date: Date) => void;
  selectedDate?: Date;
  placeholder?: string;
  [x: string]: any;
};

const DatePicker = forwardRef(({ selectedDate, onDateChange, placeholder, ...props }: Props, ref: any) => {
  return (
    <>
      <div className="w-max-100 w-275px react-datepicker-container ">
        <ReactDatePicker
          ref={ref}
          getPopupContainer={() => document.getElementById("modal-content")}
          popperPlacement="bottom"
          className="form-control  w-100"
          placeholderText={placeholder}
          onChange={(date: Date) => onDateChange(date)}
          selected={selectedDate}
          popperClassName={props.showTimeSelect && !props.todayButton && "w-min-350px"}
          {...props}
        />
        <DateInputIcon />
      </div>
    </>
  );
});

export default DatePicker;
