import React from "react";

type Props = {
  count: number;
};

function NotificationBadge({ count }: Props) {
  if (!count) {
    return <></>;
  }

  const countDisplay = count > 9 ? "9+" : count;

  return (
    <div className="position-relative notification-badge ml-2">
      <div className="notificationBadgeDot"></div>
      <p className="px-2 fs-13px rounded-xl notificationBadgeText">{countDisplay}</p>
    </div>
  );
}

export default NotificationBadge;
