import { SvgProps } from "types/svg.types";

type Props = {};

function SettingsPreferencesIconFilled(props: SvgProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7106 5.06494H6.41895C3.83436 5.06494 1.73145 7.16827 1.73145 9.75244C1.73145 12.3374 3.83436 14.4399 6.41895 14.4399H13.7106C16.2953 14.4399 18.3981 12.3374 18.3981 9.75244C18.3981 7.16827 16.2953 5.06494 13.7106 5.06494ZM13.7106 13.3983C12.9895 13.3983 12.2847 13.1849 11.6851 12.7841C11.0855 12.3833 10.6183 11.8141 10.3424 11.1474C10.0664 10.4816 9.9942 9.74828 10.1349 9.04161C10.2755 8.33411 10.6228 7.68411 11.1326 7.17494C11.6425 6.66494 12.2921 6.31744 12.9994 6.17661C13.7066 6.03577 14.4396 6.10827 15.1059 6.3841C15.772 6.65993 16.3414 7.12743 16.742 7.72743C17.1426 8.3266 17.3564 9.03161 17.3564 9.75244C17.3554 10.7191 16.971 11.6458 16.2874 12.3291C15.6039 13.0133 14.6773 13.3974 13.7106 13.3983Z"
        fill="#0C0C0C" />
      <path
        d="M13.7106 5.06494H6.41895C3.83436 5.06494 1.73145 7.16827 1.73145 9.75244C1.73145 12.3374 3.83436 14.4399 6.41895 14.4399H13.7106C16.2953 14.4399 18.3981 12.3374 18.3981 9.75244C18.3981 7.16827 16.2953 5.06494 13.7106 5.06494ZM13.7106 13.3983C12.9895 13.3983 12.2847 13.1849 11.6851 12.7841C11.0855 12.3833 10.6183 11.8141 10.3424 11.1474C10.0664 10.4816 9.9942 9.74828 10.1349 9.04161C10.2755 8.33411 10.6228 7.68411 11.1326 7.17494C11.6425 6.66494 12.2921 6.31744 12.9994 6.17661C13.7066 6.03577 14.4396 6.10827 15.1059 6.3841C15.772 6.65993 16.3414 7.12743 16.742 7.72743C17.1426 8.3266 17.3564 9.03161 17.3564 9.75244C17.3554 10.7191 16.971 11.6458 16.2874 12.3291C15.6039 13.0133 14.6773 13.3974 13.7106 13.3983Z"
        fill="black" fillOpacity="0.2" />
      <path
        d="M13.7106 5.06494H6.41895C3.83436 5.06494 1.73145 7.16827 1.73145 9.75244C1.73145 12.3374 3.83436 14.4399 6.41895 14.4399H13.7106C16.2953 14.4399 18.3981 12.3374 18.3981 9.75244C18.3981 7.16827 16.2953 5.06494 13.7106 5.06494ZM13.7106 13.3983C12.9895 13.3983 12.2847 13.1849 11.6851 12.7841C11.0855 12.3833 10.6183 11.8141 10.3424 11.1474C10.0664 10.4816 9.9942 9.74828 10.1349 9.04161C10.2755 8.33411 10.6228 7.68411 11.1326 7.17494C11.6425 6.66494 12.2921 6.31744 12.9994 6.17661C13.7066 6.03577 14.4396 6.10827 15.1059 6.3841C15.772 6.65993 16.3414 7.12743 16.742 7.72743C17.1426 8.3266 17.3564 9.03161 17.3564 9.75244C17.3554 10.7191 16.971 11.6458 16.2874 12.3291C15.6039 13.0133 14.6773 13.3974 13.7106 13.3983Z"
        fill="black" fillOpacity="0.2" />
      <path
        d="M13.7106 5.06494H6.41895C3.83436 5.06494 1.73145 7.16827 1.73145 9.75244C1.73145 12.3374 3.83436 14.4399 6.41895 14.4399H13.7106C16.2953 14.4399 18.3981 12.3374 18.3981 9.75244C18.3981 7.16827 16.2953 5.06494 13.7106 5.06494ZM13.7106 13.3983C12.9895 13.3983 12.2847 13.1849 11.6851 12.7841C11.0855 12.3833 10.6183 11.8141 10.3424 11.1474C10.0664 10.4816 9.9942 9.74828 10.1349 9.04161C10.2755 8.33411 10.6228 7.68411 11.1326 7.17494C11.6425 6.66494 12.2921 6.31744 12.9994 6.17661C13.7066 6.03577 14.4396 6.10827 15.1059 6.3841C15.772 6.65993 16.3414 7.12743 16.742 7.72743C17.1426 8.3266 17.3564 9.03161 17.3564 9.75244C17.3554 10.7191 16.971 11.6458 16.2874 12.3291C15.6039 13.0133 14.6773 13.3974 13.7106 13.3983Z"
        fill="black" fillOpacity="0.2" />
      <path
        d="M13.7106 5.06494H6.41895C3.83436 5.06494 1.73145 7.16827 1.73145 9.75244C1.73145 12.3374 3.83436 14.4399 6.41895 14.4399H13.7106C16.2953 14.4399 18.3981 12.3374 18.3981 9.75244C18.3981 7.16827 16.2953 5.06494 13.7106 5.06494ZM13.7106 13.3983C12.9895 13.3983 12.2847 13.1849 11.6851 12.7841C11.0855 12.3833 10.6183 11.8141 10.3424 11.1474C10.0664 10.4816 9.9942 9.74828 10.1349 9.04161C10.2755 8.33411 10.6228 7.68411 11.1326 7.17494C11.6425 6.66494 12.2921 6.31744 12.9994 6.17661C13.7066 6.03577 14.4396 6.10827 15.1059 6.3841C15.772 6.65993 16.3414 7.12743 16.742 7.72743C17.1426 8.3266 17.3564 9.03161 17.3564 9.75244C17.3554 10.7191 16.971 11.6458 16.2874 12.3291C15.6039 13.0133 14.6773 13.3974 13.7106 13.3983Z"
        fill="black" fillOpacity="0.2" />
    </svg>

  );
}

export default SettingsPreferencesIconFilled;
