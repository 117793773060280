import React from "react";


export enum TextButtonMode {
  PRIMARY,
  BLUE,
  NEGATIVE
}

export type TextButtonProps = {
  readonly icon?: React.JSX.Element;
  readonly text?: string;
  readonly textOverride?: React.JSX.Element;
  readonly onClick?: () => void;
  readonly className?: string;
  readonly textClassName?: string;
  readonly mode?: TextButtonMode;
  readonly id?:string
};

export default function TextButton({ icon,id, text, onClick, className, textClassName, textOverride }: TextButtonProps) {
  return (
    <span
      id={id}
      style={{ pointerEvents: "auto" }}
      className={`d-inline-flex  align-center justify-center rounded-xxl cursor-pointer ${className}`}
      onClick={onClick}
    >
      {icon && <div className="mr-1">{icon}</div>}
      {textOverride ?? <span className={`font-weight-normal ${textClassName ?? ""}`}>{text}</span>}
    </span>
  );
}
