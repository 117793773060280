import BlackInfoIcon from "components/icon/icons/BlackInfoIcon";
import MuiTooltip from "./MuiTooltip";

type Props = {
  readonly description?: string;
};

function TooltipWithInfoIcon({ description }: Props) {
  return (
    <MuiTooltip title={description} placement="top">
      <div className="cursor-pointer">
        <BlackInfoIcon className="ml-1" width={16} />
      </div>
    </MuiTooltip>
  );
}

export default TooltipWithInfoIcon;
