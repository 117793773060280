import FilterSortingSelector from "components/filter/FilterSortingSelector";
import { SortDirection, SortableFields } from "types/filter.types";
import { getSortingOption } from "utils/filter.utils";

const sortOptions = [
  getSortingOption(SortableFields.Date, SortDirection.DESC),
  getSortingOption(SortableFields.Date, SortDirection.ASC),
  getSortingOption(SortableFields.VehicleName, SortDirection.ASC),
  getSortingOption(SortableFields.VehicleName, SortDirection.DESC),
];

function VehicleSortingSelector() {
  return <FilterSortingSelector sortOptions={sortOptions} />;
}

export default VehicleSortingSelector;
