import React, { Children, PropsWithChildren } from "react";
import Card from "../../card/Card";
import Row from "../../custom/Row";
import LoadingSkeleton from "../../loading/LoadingSkeleton";
import {
  BlackBodyText100,
  VioletBodyText200,
  OrangeBodyText200,
  BlackH5Text100,
  BlackBodyText300,
  BlackCaptionText300,
  BlackH6Text100,
  BlackSubtitleText100, BlueBody2Text200, BlueBodyText100, MintBodyText500
} from "../../text/Text";

type Props = PropsWithChildren<{
  className?: string;
  isLoading?: boolean;
  isOverflow?: boolean;
}>;

const VehicleDetailsChargingInsightsCard = ({ children, isLoading, isOverflow }: Props) => {
  if (!children) {
    return <></>;
  }

  return (
    <Card className="d-flex h-100 flex-column">
      {isLoading ? (
        <VehicleDetailsChargingInsightsCard.Loading />
      ) : (
        React.cloneElement(<>{children}</>, { isOverflow, isLoading })
      )}
    </Card>
  );
};
VehicleDetailsChargingInsightsCard.Row = ({ children, className }: Props) => {
  return <Row className={`d-flex w-100 justify-between align-items-center ${className ?? ""}`}>{children}</Row>;
};

VehicleDetailsChargingInsightsCard.Cost = function ({ children, className, isOverflow }: Props) {
  const validatedChildren = children ?? "$0.00";

  if (isOverflow) {
    return <BlackSubtitleText100 className={className}>{validatedChildren}</BlackSubtitleText100>;
  }
  return <BlackH5Text100 className={className}>{validatedChildren}</BlackH5Text100>;
};

VehicleDetailsChargingInsightsCard.CostPercentage = function ({ children, className }: Props) {
  return <BlackH6Text100 className={className}>{children ?? "%-"}</BlackH6Text100>;
};

VehicleDetailsChargingInsightsCard.Energy = function ({ children, className, isOverflow }: Props) {
  const validatedChildren = children ?? "0.00 kWh";

  if (isOverflow) {
    return <BlackCaptionText300 className={className}>{validatedChildren}</BlackCaptionText300>;
  }
  return <BlackBodyText300 className={className}>{validatedChildren}</BlackBodyText300>;
};

VehicleDetailsChargingInsightsCard.EnergyPercentage = function ({ children, className }: Props) {
  return <BlackBodyText300 className={className}>{children ?? "-%"}</BlackBodyText300>;
};

VehicleDetailsChargingInsightsCard.Sessions = function ({ children, className }: Props) {
  return <BlackCaptionText300 className={className}>{children ?? "0 sessions"}</BlackCaptionText300>;
};

VehicleDetailsChargingInsightsCard.SessionsPercentage = function ({ children, className }: Props) {
  return <BlackCaptionText300 className={className}>{children ?? "-%"}</BlackCaptionText300>;
};

VehicleDetailsChargingInsightsCard.BlackTitle = function ({ children, className }: Props) {
  return <BlackBodyText100 className={className}>{children}</BlackBodyText100>;
};

VehicleDetailsChargingInsightsCard.OrangeTitle = function ({ children, className }: Props) {
  return <OrangeBodyText200 className={className}>{children}</OrangeBodyText200>;
};


VehicleDetailsChargingInsightsCard.HomeTitle = function ({ children, className }: Props) {
  return <BlueBodyText100 className={className}>{children}</BlueBodyText100>;
};

VehicleDetailsChargingInsightsCard.ChargingTitle = function ({ children, className }: Props) {
  return <MintBodyText500 className={className}>{children}</MintBodyText500>;
};

VehicleDetailsChargingInsightsCard.VioletTitle = function ({ children }: Props) {
  return <VioletBodyText200>{children}</VioletBodyText200>;
};

VehicleDetailsChargingInsightsCard.Loading = function ({ children }: Props) {
  return <LoadingSkeleton count={4} height={32} />;
};

export default VehicleDetailsChargingInsightsCard;
