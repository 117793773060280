import React from "react";
import { Pagination } from "@mui/material";
import { BlackBody2Text100, BlackH5Text100, BlackLinkText100 } from "../../text/Text";
import Card from "../../card/Card";
import RowBetweenBlock from "../../block/RowBetweenBlock";
import { getVehicleDriverSchedulePeriodInfo } from "../../../utils/vehicle-driver-schedule.utils";
import { getDriverFullName } from "../../../utils/driver.utils";
import { useHistory } from "react-router";
import Row from "../../custom/Row";
import { OldVehicleDriverSchedulesDataAccessorHook } from "../../../types/vehicle-driver-schedule.types";
import LoadingSkeleton from "../../loading/LoadingSkeleton";
import VehicleDriverSchedulePeriodBadge from "../../badge/VehicleDriverSchedulePeriodBadge";


type Props = {
  oldSchedulesHook: OldVehicleDriverSchedulesDataAccessorHook
}

function VehicleDetailsOldDriverAssignmentsModal({ oldSchedulesHook }: Props) {

  const { schedules, meta, setCurrentPage, currentPage, isLoading } = oldSchedulesHook;
  const totalPages = meta?.pagination.totalPages;


  const history = useHistory();

  return (
    <div className={"p-4"}>
      <BlackH5Text100 className={"mb-1"}>Previous assignments</BlackH5Text100>
      <BlackBody2Text100 className={"mb-2"}>On this page, you can review previous assignments. You will also find
        comprehensive details on drivers and their hours. Once assignments are completed, current assignments will be
        updated and displayed here.
      </BlackBody2Text100>

      {isLoading ? (<LoadingSkeleton count={3} height={110} className={"my-4 "} />) :
        (schedules?.map(schedule => {
              const {
                timeDifference,
                color,
                formattedStartDateDisplayText,
                formattedEndDateDisplayText

              } = getVehicleDriverSchedulePeriodInfo(schedule.startsAt, schedule.endsAt);


              return (
                <Card className={"bg-secondary mt-2"}>
                  <RowBetweenBlock
                    leftOverwrite={
                      <BlackLinkText100 onClick={() => history.push(`/drivers/${schedule.userId}`)}>
                        {getDriverFullName(schedule.user)}
                      </BlackLinkText100>
                    }
                    rightOverwrite={
                      <VehicleDriverSchedulePeriodBadge color={color} timeDifference={timeDifference} />
                    }
                  />

                  <RowBetweenBlock
                    className="mt-2"
                    leftText={"Assignment dates"}
                    rightOverwrite={
                      <Row className="align-items-center">
                        {formattedStartDateDisplayText} - {formattedEndDateDisplayText}
                      </Row>
                    }
                  />


                </Card>
              );

            }
          )
        )}
      <div className={"w-100 p-2 center mt-4"}>
        <Pagination
          onChange={(_, value) => setCurrentPage(value - 1)} defaultValue={currentPage + 1}
          count={totalPages ?? 1} color="primary" />
      </div>

    </div>

  );
}

export default VehicleDetailsOldDriverAssignmentsModal;