import { InboxSettingsKey } from "../types/settings.types";

export const getInboxNotificationPreferencesDisplayContent = (key: InboxSettingsKey) => {

  switch (key) {
    case InboxSettingsKey.ChargingSuspicious:
      return {
        title: "Suspicious Charging Activity",
        description: "Alerts about discrepancies or unusual activities in a charging session."
      };

    case InboxSettingsKey.DriverInvoiceUnpaid:
      return {
        title: "Unpaid Driver Invoice",
        description: "Alerts regarding outstanding invoices linked to drivers."
      };

    case InboxSettingsKey.NewDriverSignup:
      return {
        title: "New Driver Signup",
        description: "Notifications when a new driver signs up for your fleet."
      };

    case InboxSettingsKey.VehicleNotAssigned:
      return {
        title: "Unassigned Vehicle in Fleet",
        description: "Alerts when vehicles are not assigned to any driver."
      };

    case InboxSettingsKey.VehicleAssignmentEndsSoon:
      return {
        title: "Upcoming Assignment Schedule Expiration Reminder",
        description: "Reminders about upcoming assignment expirations for vehicles."
      };

    case InboxSettingsKey.VehicleDriverPinSet:
      return {
        title: "PIN to Drive Activation",
        description: "Notifications when the 'PIN to Drive' feature is activated and new PIN set for the Tesla."
      };

    case InboxSettingsKey.DriverPaymentMethodMissing:
      return {
        title: "Missing Payment Method",
        description: "Alerts when drivers do not have a payment method added."
      };
    default:
      return {
        title: "Unknown title",
        description: "Unknown description"
      };
  }
};