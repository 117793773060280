import { Row } from "reactstrap";
import { getDriverFullName } from "utils/driver.utils";
import MuiTooltip from "../tooltip/MuiTooltip";

export default function DynamicUserAvatarList({ users }) {
  if (users?.length === 0 || !users) {
    return <></>;
  }

  if (users?.length === 1 ) {
    return getDriverFullName(users[0])
  }

  const firstUserName = users[0].firstName + " " + users[0].lastName;
  const otherUsers = users.slice(1, users.length);
  const otherUsersLength = otherUsers.length;

  let uniqueKey = "";
  otherUsers.forEach((u) => {
    uniqueKey += u.firstName[0] + `${u.firstName[u.firstName.length - 1]}` + `-${otherUsersLength}`;
  });

  return (
    <Row className="flex align-center m-0">
      <p className="mr-1 mb-0 w-max-80px pointer-events-none">{firstUserName}</p>

      <MuiTooltip
        title={otherUsers.map((ou, ix) => (
          <div style={{ padding: 2 }} key={ix}>{`${ou.firstName} ${ou.lastName}`}</div>
        ))}
        placement="top"
      >
        <div id={uniqueKey} className="help-fill rounded-circle center bg-dark w-25px h-25px text-white">
          +{otherUsersLength}
        </div>
      </MuiTooltip>
    </Row>
  );
}

/**
 *  {otherUsers.map((ou, ix) => (
          <p key={ix}>{ou.firstName}</p>
        ))}
 */
