import React from "react";
import { SvgProps } from "../../../types/svg.types";

const DownloadInvoiceIcon = (props: SvgProps) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.5 13.125H15.4688C17.6172 13.125 19.375 12.2965 19.375 10.1719C19.375 8.04727 17.3047 7.30195 15.625 7.21875C15.2777 3.89609 12.8516 1.875 10 1.875C7.30469 1.875 5.56875 3.66367 5 5.4375C2.65625 5.66016 0.625 6.83906 0.625 9.28125C0.625 11.7234 2.73438 13.125 5.3125 13.125H7.5M7.5 15.6289L10 18.125L12.5 15.6289M10 8.75V17.5012"
        stroke="black" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default DownloadInvoiceIcon;