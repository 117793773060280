import React from "react";
import BaseModal, { BaseModalProps } from "./BaseModal";

type Props = BaseModalProps;
export default function CustomModalWithXIcon({ children, ...props }: Props) {
  return (
    <BaseModal isCloseIconVisible {...props}>
      {children}
    </BaseModal>
  );
}
