import { SvgProps } from "types/svg.types";

function TeslaIconWithCircleBackground(props: SvgProps) {
  return (
    <svg width="65" height="66" viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect y="0.5" width="65" height="65" rx="32.5" fill="#C4003E" />
      <path
        d="M32.4999 23.0446L36.2146 18.5043C36.2146 18.5043 42.5774 18.6393 48.9177 21.5867C47.2943 24.0413 44.0724 25.2428 44.0724 25.2428C43.8553 23.0862 42.3445 22.5598 37.5398 22.5598L32.5011 51L27.4275 22.5541C22.6576 22.5541 21.1468 23.0817 20.9297 25.2372C20.9297 25.2372 17.7066 24.0481 16.0844 21.5923C22.4236 18.6449 28.7875 18.5099 28.7875 18.5099L32.5022 23.0446H32.4966H32.4999ZM32.4999 17.1971C37.6208 17.1498 43.4863 17.9879 49.4914 20.6124C50.2946 19.159 50.4994 18.5211 50.4994 18.5211C43.9374 15.9191 37.7906 15.0236 32.4999 15C27.2092 15.0236 21.0624 15.9146 14.5005 18.5211C14.5005 18.5211 14.793 19.312 15.5085 20.6192C21.5146 17.9823 27.3791 17.1509 32.4999 17.1914V17.1971Z"
        fill="white"
      />
    </svg>
  );
}

export default TeslaIconWithCircleBackground;
