import React from "react";
import { Reimbursement } from "../../types/reimbursement.types";
import { DateTime } from "luxon";
import Row from "../custom/Row";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoicePdfTemplate from "../pdf/InvoicePdfTemplate";
import ExportIcon from "../icon/icons/ExportIcon";
import CircularLoadingSpinner from "../loading/CircularLoadingSpinner";


export const ReimbursementDownloadLinkButton = ({ row }: { row: Reimbursement }) => {

  const fileName = `HCR-Receipt-${row.id}-${DateTime.now().toLocaleString(DateTime.DATE_FULL)}.pdf`;

  return (
    <Row style={{ pointerEvents: "auto" }} className="w-100 w-min-100px center">
      <PDFDownloadLink
        document={<InvoicePdfTemplate reimbursement={{ ...row }} />}
        fileName={fileName}
      >
        {({ loading }) =>
          loading ? <CircularLoadingSpinner size={16} heightClassName={"h-20px"} /> : <ExportIcon />
        }
      </PDFDownloadLink>
      {/* <div className="vertical-border-line h-40px mx-2" />
      <ViewIcon /> */}
    </Row>
  );
};


