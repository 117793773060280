import Row from "../custom/Row";
import OutlinedButton from "./OutlinedButton";

export type OutlinedButtonWithIconProps = {
  readonly onClick?: () => void;
  readonly text?: string;
  readonly className?: string;
  readonly type?: string;
  readonly isLoading?: boolean;
  readonly isDisabled?: boolean;
  readonly leftIcon?: JSX.Element;
  readonly rightIcon?: JSX.Element;
  readonly theme?: string
  readonly textClassName?: string
  readonly isSmall?: boolean
};

export default function OutlinedButtonWithIcon(props: OutlinedButtonWithIconProps) {
  const { leftIcon, rightIcon, text } = props;

  return (
    <OutlinedButton
      textOverride={
        <Row className="center">
          {leftIcon && leftIcon}
          {text && <span
            className={`mx-1 fs-16px font-weight-normal ${props.textClassName ?? "text-black-100"}`}>{text}</span>}
          {rightIcon && rightIcon}
        </Row>
      }
      {...props}
    />
  );
}
