import ButtonWithIcon from "./ButtonWithIcon";

type Props = {
  readonly onClick?: (e: any) => void;
  readonly text?: string;
  readonly className?: string;
  readonly height?: number;
  readonly type?: string;
  readonly isLoading?: boolean;
  readonly isDisabled?: boolean;
  readonly leftIcon?: JSX.Element;
};

function PrimaryButton(props: Props) {
  return <ButtonWithIcon {...props} className={`${props.className} w-min-120px`} />;
}

export default PrimaryButton;
