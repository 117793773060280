import React, { useCallback, useMemo } from "react";

import { CircularProgress } from "@mui/material";

import { TableColumn } from "react-data-table-component";

import Row from "components/custom/Row";
import RightChevron from "components/icon/icons/RightChevron";
import PaginatedTable from "components/table/PaginatedTable";
import { useInvoicesAsync } from "hooks/data/invoices-data-accessor.hooks";
import { useStripeInvoiceUrlAccessor } from "hooks/invoice.hooks";
import { useHistory } from "react-router";
import { Invoice, InvoiceType } from "types/invoice.types";
import { getLocalFormattedDateAsDateShort } from "utils/date.utils";
import { getFormattedNumberForUsdByCent } from "utils/format.utils";
import { getFormattedDateForInvoicePeriod, getInvoiceDescription, getInvoiceTypeLabel } from "utils/invoice.utils";
import InvoiceTablePlaceholder from "./InvoiceTablePlaceholder";
import InvoiceTableRow from "./InvoiceTableRow";
import PaidStatusBadge from "../badge/PaidStatusBadge";
import FilterSearchInput from "../filter/FilterSearchInput";

export type InvoicesTableColumn = TableColumn<Invoice>[];

function InvoicesTable() {
  const history = useHistory();

  const { handleForwardToStripeAsync, isForwarding } = useStripeInvoiceUrlAccessor();

  const { invoices, isLoading, meta } = useInvoicesAsync();

  const columns = useMemo(
    () => [
      {
        grow: 1,
        name: "Invoice type",
        cell: (row) => (
          <InvoiceTableRow paymentState={row.paymentState}>{getInvoiceTypeLabel(row.invoiceType)}</InvoiceTableRow>
        )
      },
      {
        grow: 1,
        name: "Description",
        cell: (row) => (
          <InvoiceTableRow paymentState={row.paymentState}>{getInvoiceDescription(row)}</InvoiceTableRow>
        )
      },
      {
        grow: 1.4,
        name: "Period",
        cell: (row) => (
          <InvoiceTableRow paymentState={row.paymentState}>{getFormattedDateForInvoicePeriod(row)}</InvoiceTableRow>
        )
      },
      {
        grow: 1,
        name: "Created Date",
        cell: (row) => (
          <InvoiceTableRow paymentState={row.paymentState}>
            {getLocalFormattedDateAsDateShort(row.createdAt)}
          </InvoiceTableRow>
        )
      },

      {
        grow: 1,
        name: "Due date",
        cell: (row) => (
          <InvoiceTableRow paymentState={row.paymentState}>
            {getLocalFormattedDateAsDateShort(row.dueDate)}
          </InvoiceTableRow>
        )
      },
      {
        grow: 1,
        name: "Status",
        selector: (row) => (
          <PaidStatusBadge status={row.paymentState} />
        )
      },
      {
        grow: 1,
        name: "Amount",
        selector: (row) =>
          (
            <InvoiceTableRow paymentState={row.paymentState}>
              {getFormattedNumberForUsdByCent(row.netAmount)}
            </InvoiceTableRow>
          ) ?? "-"
      },

      {
        center: true,
        right: true,
        width: "70px",
        name: "",
        cell: (row) => (
          <Row className="w-100 justify-end pr-2">
            <RightChevron widthValue={8} heightValue={10} />
          </Row>
        )
      }
    ],
    []
  ) as InvoicesTableColumn;

  const handleRouting = useCallback(
    (id: string) => {
      if (isLoading) return;

      const invoice = invoices.find((invoice) => invoice.id === id);

      if (!invoice) {
        return;
      }

      const isDebitInvoice = invoice.invoiceType === InvoiceType.DEBIT;
      if (!isDebitInvoice) {
        return `/invoices/${id}`;
      }
    },
    [invoices, isLoading]
  );

  if (isForwarding) {
    return (
      <div className="center w-100 h-400px">
        <CircularProgress size={48} style={{ color: "#1E44FF" }} />
      </div>
    );
  }

  return (
    <>
      <div className="mt-4 invoices-table">
        <PaginatedTable
          onRowClicked={invoice => handleForwardToStripeAsync(invoice?.id)}
          getRoutePath={handleRouting}
          paginationTotalRows={meta?.pagination.totalElements}
          columns={columns}
          data={invoices}
          isLoading={isLoading}
          noDataComponent={
            <div className="h-min-400px center">
              <InvoiceTablePlaceholder
                description="After you start using our services, your invoices will be listed here for easy review and payment." />
            </div>
          }
          className="rounded-xxl custom-table-border"
        />
      </div>
    </>
  );
}

export default InvoicesTable;
