import React from "react";
import LoadingSkeleton from "../../loading/LoadingSkeleton";
import { BlackBody2Text0, BlackCaptionText200, GreenBodyText200 } from "../../text/Text";
import Row from "../../custom/Row";
import VehicleStatusServiceIcon from "../../icon/icons/VehicleStatusServiceIcon";
import ChargerLighteningIcon from "../../icon/icons/ChargerLighteningIcon";
import {
  getTeslaVehicleStatusContent,
  getVehicleConnectionStatus,
  getVehicleTelemetryDetails
} from "../../../utils/vehicle.utils";
import { TeslaDataAccessorHook, VehicleDataAccessorHook } from "../../../types/vehicle.types";

type Props = {
  readonly vehicleHook: VehicleDataAccessorHook;
  readonly teslaHook: TeslaDataAccessorHook
}

function VehicleDetailsVehicleStateBlock({ vehicleHook, teslaHook }: Props) {
  const isLoading = vehicleHook.isLoading || teslaHook.isTeslaInfoLoading
  const telemetry = vehicleHook.vehicle?.telemetry;
  const isCharging = telemetry?.charge?.data.charging;

  const {batteryPercentRemainingDisplayText} = getVehicleTelemetryDetails(telemetry)


  const {
    icon: teslaVehicleStateIcon,
    label: teslaVehicleStateLabel,
    textClass
  } = getTeslaVehicleStatusContent(teslaHook?.teslaVehicleStatus);

  const { isTeslaConnected } = getVehicleConnectionStatus(vehicleHook.vehicle);



  if (isLoading) {
    return <LoadingSkeleton count={1} className={"w-100px"} />;
  }


  return (

    <>
      {(isTeslaConnected || isCharging) && <BlackCaptionText200 className={"mr-2"}>Vehicle state</BlackCaptionText200>}
      {isTeslaConnected ?
        (
          <Row>
            {teslaHook.teslaInfo?.inService &&
              (
                <Row className={"mr-2"}>
                  <VehicleStatusServiceIcon />
                  <BlackBody2Text0 className={`ml-1`}>In service</BlackBody2Text0>
                </Row>
              )}

            <Row>
              {teslaVehicleStateIcon}
              <BlackBody2Text0
                className={`ml-1 ${textClass ?? ""}`}>{teslaVehicleStateLabel}</BlackBody2Text0>
            </Row>
          </Row>
        ) : (
          isCharging ?
            (
              <Row className={"align-items-center"}>
                <ChargerLighteningIcon fill={"#23d206"} />
                <GreenBodyText200 className={"ml-1"}>Charging {batteryPercentRemainingDisplayText}</GreenBodyText200>
              </Row>
            ) : <></>
        )
      }
    </>

  );
}

export default VehicleDetailsVehicleStateBlock;