import { ClickAwayListener, Popper } from "@mui/material";
import Card from "components/card/Card";

import React, { useCallback } from "react";
import Row from "../custom/Row";
import { BodyMediumText, BodyText } from "../text/Text";
import SelectorChevronIconBottom from "../icon/icons/SelectorChevronIconBottom";
import { PaymentState } from "../../types/payment.types";
import { AppColors } from "../../utils/colors.utils";
import TickIcon from "../icon/icons/TickIcon";


type Props = {
  paymentState: PaymentState;
  onPaymentStateChanged: (paymentState: PaymentState) => void;
}

export function ReimbursementTableStatusToggle({ paymentState, onPaymentStateChanged }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);


  const isPaid = paymentState === PaymentState.PAID;
  const isCanceled = paymentState === PaymentState.VOID;
  const isUnPaid = paymentState === PaymentState.UNPAID;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleStatusToggleClicked = useCallback((selectedStatus: PaymentState) => {
    onPaymentStateChanged(selectedStatus);
    setAnchorEl(null);
  }, [onPaymentStateChanged]);


  const label = isPaid ? "Paid" : isCanceled ? "Canceled" : "Unpaid"

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div>
        <Card padding="p-0"
              className={`align-items-center filter-editor-card p-1 px-2 my-2 ${!isUnPaid? "border" : "border-cherry"}`}>
          <Row onClick={handleClick} className={`align-items-center`}>
            <BodyMediumText
              className={`${!isUnPaid ? "text-black-0" : "text-cherry-400"}`}>{label}</BodyMediumText>
            <SelectorChevronIconBottom
              className={isUnPaid? "react-selector-chevron-icon-cherry" : "react-selector-chevron-icon"}
              fill={isUnPaid ? AppColors.cherry400 : ""} />
          </Row>


          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement={"bottom-start"}
            style={{ zIndex: 99999 }}
          >
            <Card padding="p-2" className={`my-2 filter-menu-card card-with-more-shadow w-min-150px  `}>
              {[PaymentState.PAID, PaymentState.UNPAID, PaymentState.VOID].map(c => {
                  const isPaid = c === PaymentState.PAID;
                  const isCanceled = c === PaymentState.VOID;
                  const isChecked = c === paymentState;

                  return <Row
                    className={"align-items-center justify-between p-1 clickable hover-background-light-50 rounded-xxl"}
                    onClick={() => handleStatusToggleClicked(c)} key={c}>
                    <BodyText
                      className={`${isChecked ? "text-blue-500" : "text-black-0"}`}>{isPaid ? "Paid" : isCanceled ? "Canceled" : "Unpaid"}</BodyText>
                    {isChecked && <TickIcon fill={AppColors.blue500} />}
                  </Row>;
                }
              )}
            </Card>
          </Popper>
        </Card>
      </div>
    </ClickAwayListener>
  );
}

