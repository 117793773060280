import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

import FleetGroupsPageHeader from "../../components/fleet-groups-components/FleetGroupsPageHeader";
import FleetGroupsMutationPagesContent from "../../components/fleet-groups-components/FleetGroupsMutationPagesContent";

export default function AddFleetGroupPage() {
  const { t } = useTranslation("common");
  return (
    <>
      <Head title={t("fleet_groups.add_fleet_group_page.title")}></Head>
      <Content>
        <FleetGroupsPageHeader
          title={t("fleet_groups.add_fleet_group_page.page_header")}
          description={t("fleet_groups.add_fleet_group_page.page_description")}
        />

        <FleetGroupsMutationPagesContent />
      </Content>
    </>
  );
}
