import {
  NeutralBodyLargeText100,
  NeutralBodyMediumText200,
  NeutralH5Text100,
  NeutralTitleMediumText100
} from "components/text/Text";
import { useAuthStatus, useSyncOrganization } from "hooks/auth.hooks";
import Card from "../card/Card";
import Row from "../custom/Row";
import MailFilledIcon from "../icon/icons/MailFilledIcon";
import HorizontalLine from "../line/HorizontalLine";
import { MockInboxSettings } from "../../utils/test.utils";
import SettingsPagePreferencesContentItem from "./SettingsPagePreferencesContentItem";
import { InboxSettingsKey } from "../../types/settings.types";
import { updateOrganizationInfoAsync } from "../../services/organization.services";
import { OrganizationProfileCommand } from "../../types/organization.types";
import { useAlertManager } from "../../hooks/alert.hooks";
import { handleApiErrorResponse } from "../../utils";
import { updateOrganizationInboxNotificationSettings } from "../../redux/slices/userSlice";
import { useAppDispatch } from "../../hooks/redux.hooks";
import { useState } from "react";

type Props = {};

function SettingsPagePreferencesContent({}: Props) {
  const [itemBeingToggled, setItemBeingToggled] = useState<InboxSettingsKey | null>(null);

  const { isLoading } = useSyncOrganization();
  const { user } = useAuthStatus();
  const notificationSettings = user?.notificationSettings?.inboxSettings ?? MockInboxSettings;
  const notificationSettingKeys = Object.keys(notificationSettings);

  const dispatch = useAppDispatch();

  const { handleOpenErrorAlert } = useAlertManager();

  const handleTogglePreferencesItem = async (key: InboxSettingsKey) => {
    try {
      setItemBeingToggled(key);

      const newInboxSettings = {
        ...notificationSettings, [key]: {
          ...notificationSettings[key],
          email: !notificationSettings[key].email
        }
      };

      await updateOrganizationInfoAsync({
        command: OrganizationProfileCommand.UPDATE_INBOX_NOTIFICATION_SETTINGS,
        inboxSettings: newInboxSettings
      });

      dispatch(updateOrganizationInboxNotificationSettings(newInboxSettings));
    } catch (e) {
      handleOpenErrorAlert(handleApiErrorResponse(e));
    } finally {
      setItemBeingToggled(null);
    }
  };

  return (
    <div className="w-100">
      <NeutralH5Text100>Preferences</NeutralH5Text100>
      <Card className={"mt-3"}>
        <Row className={"align-items-center"}>
          <MailFilledIcon />
          <NeutralTitleMediumText100 className={"ml-2"}>Email Preferences</NeutralTitleMediumText100>
        </Row>

        <HorizontalLine />

        <NeutralBodyLargeText100>Inbox Alerts</NeutralBodyLargeText100>
        <NeutralBodyMediumText200>Manage notifications for your EV fleet. Choose which alerts trigger an email to keep
          you informed about key events.</NeutralBodyMediumText200>

        <Card className={"card-secondary mt-2 overflow-auto"}>
          {notificationSettingKeys.map((key, ix) => (
            <div className={"w-100"} key={key}>
              <SettingsPagePreferencesContentItem
                onToggle={handleTogglePreferencesItem}
                isLoading={isLoading || (key === itemBeingToggled)}
                isDisabled={isLoading || (itemBeingToggled ? (key !== itemBeingToggled) : false)}
                isChecked={notificationSettings[key].email}
                itemKey={key as InboxSettingsKey}
              />
              {notificationSettingKeys.length - 1 !== ix && <HorizontalLine />}
            </div>
          ))}
        </Card>
      </Card>
    </div>
  )
    ;
}

export default SettingsPagePreferencesContent;
