import React from "react";

import { BlockDes, BlockTitle } from "../Component";

import FleetGroupsPageHeaderCard from "./FleetGroupsPageHeaderCard";
import { useTranslation } from "react-i18next";
import Row from "../custom/Row";
import Col from "../custom/Col";

export default function FleetGroupsPageHeader({ title, description }) {
  const { t } = useTranslation("common");
  return (
    <Row className="align-center justify-between">
      <Col className="my-1">
        <BlockTitle page tag="h5">
          {title}
        </BlockTitle>
        <BlockDes>{description}</BlockDes>
      </Col>

      <FleetGroupsPageHeaderCard />
    </Row>
  );
}
