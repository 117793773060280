import React from "react";

import Badge from "./Badge";

export default function FleetGroupBadge({ badgeContent }) {
  if (!badgeContent) {
    return <></>;
  }

  return (
    <Badge
      containerStyle={`${badgeContent.color} group-badge center`}
      text={badgeContent.name}
      textStyle="center text-center"
    />
  );
}
