import React from "react";
import ButtonWithIcon from "./ButtonWithIcon";

type Props = {
  readonly onNextButtonPressed: () => void;
};

function NextButton({ onNextButtonPressed }: Props) {
  return <ButtonWithIcon className="w-175px mt-md-0" text={"Next"} onClick={onNextButtonPressed} />;
}

export default NextButton;
