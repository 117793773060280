import { BlackBody2Text200, BlackBodyText100 } from "components/text/Text";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  label: string;
  value?: string;
  className?: string;
  isBorderHidden?: boolean;
  isTruncateAllowed?: boolean;
}>;
export default function DriverApprovalPageSectionBox({
                                                       label,
                                                       value,
                                                       className,
                                                       isBorderHidden,
                                                       children,
                                                       isTruncateAllowed = true
                                                     }: Props) {
  return (
    <div>
      <BlackBodyText100>{label}</BlackBodyText100>
      <BlackBody2Text200 className={`wide-md ${isTruncateAllowed ? "text-truncate" : ""}  `}>{value}</BlackBody2Text200>
      {!isBorderHidden && <div className="horizontal-border-line my-2 w-100" />}
    </div>
  );
}
