import { useCallback, useEffect, useState } from "react"
import { getHeaderStatisticsAsync } from "services/header.service"
import { HeaderStats } from "types/dashboard.types"

export const useHeaderStatsAsync = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [headerStats, setHeaderStats] = useState<HeaderStats | undefined>(undefined)

    const getHeaderStatisticsAsyncCallback = useCallback(
        async () => {
            try {
                setIsLoading(true)

                const res = await getHeaderStatisticsAsync()
                const statistics = res.data

                if (!statistics) {
                    throw Error('Statistics is undefined')
                }

                setHeaderStats(statistics)

            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        },
        [],
    )


    useEffect(() => {
        getHeaderStatisticsAsyncCallback();
    }, [getHeaderStatisticsAsyncCallback]);


    return { headerStats, isLoading, refetch: getHeaderStatisticsAsyncCallback }
}