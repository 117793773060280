// React
import { Route, Switch, withRouter } from "react-router-dom";
// Components & Pages & Routes
import LogoLoadingIndicator from "components/loading/LogoLoadingIndicator";
import Layout from "layout/Index";
import AuthNavigator from "pages/auth/AuthNavigator";
import InactiveUserPage from "pages/auth/InactiveUserPage";
import WaitingUserPage from "pages/auth/WaitingUserPage";
import Error404Modern from "pages/error/404-modern";
import PrivateRoute from "route/PrivateRoute";

// Hooks
import { useAuthStatus, useAuthSubscription } from "hooks/auth.hooks";
import { useIntercomBoot } from "hooks/intercom.hooks";
import { useInitMixpanel } from "hooks/mixpanel.hooks";
import PrivacyPage from "pages/privacy-and-terms/PrivacyPage";
import TermsPage from "pages/privacy-and-terms/TermsPage";

const context = "App";

const App = () => {
  /**
   * Hooks
   */

  const { isLoading } = useAuthSubscription();
  const { user, isAuthenticated, isUserPendingReview } = useAuthStatus();

  useIntercomBoot(user);
  useInitMixpanel();

  /**
   * Log
   */
  //console.log(context);



  /**
   * Render
   */
  if (isLoading) {
    return <LogoLoadingIndicator />;
  }

  return (
    <>
      <Switch>
        {/**  <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login} /> */}
        <Route exact path={`${process.env.PUBLIC_URL}/inactive-user`} component={InactiveUserPage} />

        {isAuthenticated && isUserPendingReview && (
          <Route exact path={`${process.env.PUBLIC_URL}/waiting-user`} component={WaitingUserPage} />
        )}

        {/** Privacy & Terms pages */}
        <Route path={`${process.env.PUBLIC_URL}/privacy`} component={PrivacyPage} />
        <Route path={`${process.env.PUBLIC_URL}/terms`} component={TermsPage} />

        {/* Auth Pages */}
        <Route path={`${process.env.PUBLIC_URL}/auth`} component={AuthNavigator} />

        <PrivateRoute isAuthenticated={isAuthenticated} user={user} exact={false} path="" component={Layout} />
        <Route component={Error404Modern} />
      </Switch>
    </>
  );
};
export default withRouter(App);
