import React from "react";
import { Icon } from "../Component";
import Row from "../custom/Row";
import OutlinedButton from "./OutlinedButton";

export default function OutlinedAddButton({ text, onClick }) {
  return (
    <OutlinedButton
      onClick={onClick}
      textOverride={
        <Row>
          <Icon name="plus" />
          <span className="font-weight-normal   text-base">{text}</span>
        </Row>
      }
    />
  );
}
