import CancelButton from "components/button/CancelButton";
import PrimaryButton from "components/button/PrimaryButton";
import AlertConfirmDialog from "components/notification/AlertConfirmDialog";
import { useAlertManager } from "hooks/alert.hooks";
import { useCallback, useState } from "react";
import { useParams } from "react-router";
import { updateVehicleTeslaAsync } from "services/vehicle.services";
import { TeslaCommand, TeslaDataAccessorHook } from "types/vehicle.types";
import { handleApiErrorResponse } from "utils";
import VehicleDetailsPageCardBlock from "./VehicleDetailsPageCardBlock";
import VehicleDetailsVirtualKeyControlledButton from "./VehicleDetailsVirtualKeyControlledButton";

type Props = {
  teslaHook: TeslaDataAccessorHook;
  isReadonly: boolean
};

function VehicleDetailsPortStatusEditor({ teslaHook, isReadonly }: Props) {
  const { isTeslaInfoLoading, teslaInfo, refetchTeslaInfo } = teslaHook;


  const isOpen = teslaInfo?.charge.port.open;

  const { vehicleId } = useParams<{ vehicleId: string }>();

  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleToggleDriverStatusAsync = useCallback(async () => {
    try {
      setIsSubmitting(true);

      await updateVehicleTeslaAsync(vehicleId, {
        command: isOpen ? TeslaCommand.CLOSE_CHARGE_PORT : TeslaCommand.OPEN_CHARGE_PORT
      });

      setIsConfirmDialogOpen(false);
      handleOpenSuccessAlert(isOpen ? "Charge port closed successfully." : "Charge port opened successfully.");
      refetchTeslaInfo();
    } catch (error) {
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  }, [handleOpenErrorAlert, handleOpenSuccessAlert, isOpen, refetchTeslaInfo, vehicleId]);

  return (
    <>
      <AlertConfirmDialog
        isDialogOpen={isConfirmDialogOpen}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setIsConfirmDialogOpen(false)} />
            </div>

            <PrimaryButton
              //leftIcon={isOpen ? <UnlockFilledIcon className="mr-1" /> : <LockClosedFilledIcon className="mr-1" />}
              type="button"
              text={isOpen ? "Close" : "Open"}
              isLoading={isSubmitting}
              onClick={handleToggleDriverStatusAsync}
            />
          </>
        }
        toggleIsDialogOpen={() => setIsConfirmDialogOpen(false)}
        title={`Are you sure you want to ${isOpen ? "Close" : "Open"} charging port of this vehicle?`}
      />

      <VehicleDetailsPageCardBlock
        isBorderBottomVisible={false}
        columnClass="h-28px"
        isLoading={isTeslaInfoLoading}
        leftLabel="Charge port"
        leftText={isOpen ? "Opened" : "Closed"}
        rightTextOverwrite={
          <VehicleDetailsVirtualKeyControlledButton
            isVirtualKeyNeeded={isReadonly}
            // leftIcon={isOpen ? <UnlockFilledIcon className="mr-1" /> : <LockClosedFilledIcon className="mr-1" />}
            type="button"
            text={isOpen ? "Close" : "Open"}
            isLoading={isSubmitting}
            onClick={() => setIsConfirmDialogOpen(true)}
          />
        }
      />
    </>
  );
}

export default VehicleDetailsPortStatusEditor;
