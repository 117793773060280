import { DateTime } from "luxon";
import {
  formatSecondsToHumanize,
  getLocalDate,
  getLocalFormattedDateForTables,
  ImmediateDateSpecifier,
  IndefiniteDateSpecifier,
  PredefinedMaxDateValue
} from "./date.utils";
import { VehicleDriverSchedule } from "../types/vehicle-driver-schedule.types";

export const getVehicleDriverSchedulePeriodsForPayload = (startsAt?: Date, endsAt?: Date) => {
  return {
    startsAt: startsAt ? startsAt.toISOString() : ImmediateDateSpecifier,
    endsAt: endsAt ? endsAt.toISOString() : IndefiniteDateSpecifier
  };
};


export const getVehicleDriverSchedulePeriodInfo = (startsAt: string, endsAt: string) => {
  const isInfinite = PredefinedMaxDateValue === endsAt;


  const startsAtDate = getLocalDate(startsAt) as DateTime;
  const endsAtDate = getLocalDate(endsAt) as DateTime;

  const currentTime = DateTime.local();

  // Check if the current time falls within the period
  const isCurrentTimeWithinPeriod = currentTime >= startsAtDate && currentTime <= endsAtDate;

  let timeDifference = "-";
  let color = "#E6D5FF"; // default for limitless

  if (!isInfinite) {
    const diffInSeconds = endsAtDate.diff(startsAtDate, "seconds").seconds;
    timeDifference = formatSecondsToHumanize(diffInSeconds);

    const diffInDays = endsAtDate.diff(startsAtDate, "days").days;
    if (diffInDays <= 17) {
      color = "#FFEFE6"; // 1-17 days
    } else if (diffInDays <= 254) {
      color = "#D4FDDF"; // 17-254 days
    }
  }

  // tell me current time is between this period
  const formattedStartDateDisplayText = getLocalFormattedDateForTables(startsAt);
  const formattedEndDateDisplayText = endsAt === PredefinedMaxDateValue ? "-" : getLocalFormattedDateForTables(endsAt);


  return {
    timeDifference,
    isActive: isCurrentTimeWithinPeriod,
    color,
    formattedStartDateDisplayText,
    formattedEndDateDisplayText
  };
};


export const getVehicleDriverSchedulePeriodsForSelector = (startsAt: string, endsAt: string) => {
  const isInfinite = PredefinedMaxDateValue === endsAt;


  const startsAtLocal = getLocalDate(startsAt) as DateTime;
  const endsAtLocal = isInfinite ? undefined : getLocalDate(endsAt) as DateTime;


  return {
    startsAt: startsAtLocal.toJSDate(),
    endsAt: endsAtLocal?.toJSDate()

  };
};


export const getCategorizedSchedules = (schedules: VehicleDriverSchedule[]): {
  activeSchedules: VehicleDriverSchedule[],
  plannedSchedules: VehicleDriverSchedule[]
} => {
  const activeSchedules: VehicleDriverSchedule[] = [];
  const plannedSchedules: VehicleDriverSchedule[] = [];

  schedules.forEach((schedule) => {
    const { startsAt, endsAt } = schedule;
    const { isActive } = getVehicleDriverSchedulePeriodInfo(startsAt, endsAt);

    if (isActive) {
      activeSchedules.push(schedule);
    } else {
      plannedSchedules.push(schedule);
    }
  });

  return {
    activeSchedules,
    plannedSchedules
  };
};


