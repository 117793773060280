import { FormValues } from "@aws-amplify/ui-react/dist/types/components/AccountSettings/types";
import { RSelect } from "components/Component";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import { dialCodesWithLabels } from "consts/phone.consts";
import React from "react";
import { DeepMap, FieldError, RegisterOptions } from "react-hook-form";
import { DialCode } from "types/auth.types";

import AuthFormInput from "./AuthFormInput";

type Props = {
  readonly onDialCodeChanged: React.Dispatch<React.SetStateAction<DialCode>>;
  readonly dialCode?: DialCode;
  readonly register: (options: RegisterOptions) => React.LegacyRef<HTMLInputElement> | undefined;
  readonly errors: DeepMap<FormValues, FieldError>;
  readonly defaultPhoneNumberValue?: string;
  readonly defaultSelectedDialCode?: DialCode;
};

function AuthPhoneNumberInput({
  onDialCodeChanged,
  dialCode,
  register,
  errors,
  defaultPhoneNumberValue,
  defaultSelectedDialCode,
}: Props) {
  return (
    <Row>
      <Col xs="3" className="mr-2">
        <RSelect
          value={dialCode}
          defaultValue={defaultSelectedDialCode}
          name="phone_number_dial_code"
          options={dialCodesWithLabels}
          onChange={onDialCodeChanged}
          menuPosition="fixed"
          menuPortalTarget={document.querySelector(".modal-content")}
          styles={{
            menuList: (styles) => {
              return {
                ...styles,
                maxHeight: 200,
              };
            },
          }}
          //placeholder={"US +1"}
          default
        />
      </Col>
      <Col>
        <AuthFormInput
          aria-label="phoneNumber"
          defaultValue={defaultPhoneNumberValue}
          register={register}
          inputMode="tel"
          type="text"
          name="phoneNumber"
          placeholder={"Phone number"}
          registerRef={register({
            required: "Phone number is required",
            pattern: {
              value: /^\s*[0-9]{10}\s*$/,
              message: "Phone number is not valid",
            },
          })}
          errorMessage={errors?.phoneNumber?.message}
        />
      </Col>
    </Row>
  );
}

export default AuthPhoneNumberInput;
