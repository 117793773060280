export default function DriverSupportListNotificationBadge({ unreadMessageCount }) {
  const countDisplay = unreadMessageCount > 9 ? "9+" : unreadMessageCount;

  if (!unreadMessageCount) {
    return <></>;
  }

  return (
    <div className="position-relative center">
      <p className="px-2 fs-13px rounded-xl bg-cherry-500 " style={{  color: "white", padding: 1 }}>
        {countDisplay}
      </p>
    </div>
  );
}
