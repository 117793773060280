import React from "react";
import { SvgProps } from "../../../types/svg.types";

const RightArrowItemIcon = (props: SvgProps) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.46875 1.37451L13.0938 6.99951L7.46875 12.6245M12.3125 6.99951H0.90625" stroke="#CBD5E1"
            strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default RightArrowItemIcon;