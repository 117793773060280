import React from "react";
import ButtonWithIcon from "./ButtonWithIcon";

type Props = {
  readonly onYesButtonPressed: () => void;
  readonly isLoading?: boolean;
};

function YesButton({ onYesButtonPressed, isLoading }: Props) {
  return (
    <ButtonWithIcon
      isLoading={isLoading}
      isDisabled={isLoading}
      className="w-175px mt-md-0"
      text={"Yes"}
      onClick={onYesButtonPressed}
    />
  );
}

export default YesButton;
