import { PaginationMeta } from "./api.types";
import { useInboxMessagesAsync } from "../hooks/data/inbox-data-accessor.hooks";
import { useInboxBulkActionHooks } from "../hooks/inbox.hooks";


export type InboxBulkActionCommandHistoryState = {
  type: InboxBulkActionCommand,
  affected: string[],
}

export enum InboxBulkActionCommand {
  ARCHIVE = "archive",
  UNARCHIVE = "unarchive",
  READ = "read",
  UNREAD = "unread"
}


export enum InboxMessageType {
  ACTIVE,
  ARCHIVED
}

export enum InboxMessageDataType {
  DRIVER = "driver",
  VEHICLE = "vehicle",
  SELF_SIGNUP = "signup-approval"
}


export enum MessageLevel {
  INFO = "INFO",
  WARNING = "WARNING",
  ALERT = "ALERT"
}

export type InboxMessage = {
  id: string
  level: MessageLevel
  title: string
  body: string
  data: object
  read: boolean
  archived: boolean
  createdAt: string
  updatedAt: string
}

export type InboxSliceState = {
  selectedMessage: InboxMessage | null
  messages: InboxMessage[]
  isLoading: boolean
  meta: PaginationMeta | null
}


export type UpdateInboxMessagesAsyncPayload = {
  ids: string[],
}


export type InboxFilters = {
  archived?: {
    $eq?: "true" | "false",
  },
}


export type InboxMessagesDataAccessorHooks = ReturnType<typeof useInboxMessagesAsync>;
export type InboxMessagesBulkActionHooks = ReturnType<typeof useInboxBulkActionHooks>;
