import { SvgProps } from "types/svg.types";

type Props = {};

function SettingsLockIconFilled(props: SvgProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.6178 19.1644C4.11789 19.1644 3.69005 18.9869 3.3343 18.631C2.97797 18.2744 2.7998 17.846 2.7998 17.346V8.25603C2.7998 7.75603 2.97797 7.32769 3.3343 6.97186C3.69005 6.61603 4.11789 6.43853 4.6178 6.43853H5.5268V4.62019C5.5268 3.3627 5.97014 2.29103 6.85672 1.40436C7.74272 0.517696 8.81447 0.0751953 10.0719 0.0751953C11.3294 0.0751953 12.4014 0.517696 13.2881 1.40436C14.174 2.29103 14.617 3.3627 14.617 4.62019V6.43853H15.5261C16.026 6.43853 16.4541 6.61603 16.8105 6.97186C17.1662 7.32769 17.3441 7.75603 17.3441 8.25603V17.346C17.3441 17.846 17.1662 18.2744 16.8105 18.631C16.4541 18.9869 16.026 19.1644 15.5261 19.1644H4.6178ZM10.0719 14.6194C10.5719 14.6194 11.0001 14.4419 11.3564 14.086C11.7121 13.7294 11.89 13.301 11.89 12.801C11.89 12.301 11.7121 11.8735 11.3564 11.5169C11.0001 11.161 10.5719 10.9835 10.0719 10.9835C9.57197 10.9835 9.14414 11.161 8.78839 11.5169C8.43205 11.8735 8.25389 12.301 8.25389 12.801C8.25389 13.301 8.43205 13.7294 8.78839 14.086C9.14414 14.4419 9.57197 14.6194 10.0719 14.6194ZM7.34489 6.43853H12.799V4.62019C12.799 3.8627 12.5338 3.21853 12.0036 2.68853C11.4733 2.15853 10.8295 1.8927 10.0719 1.8927C9.31439 1.8927 8.67055 2.15853 8.14022 2.68853C7.60997 3.21853 7.34489 3.8627 7.34489 4.62019V6.43853Z"
        fill="#020202" />
    </svg>
  );
}

export default SettingsLockIconFilled;
