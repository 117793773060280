import Row from "components/custom/Row";
import InfoIcon from "components/icon/icons/InfoIcon";
import XIcon from "components/icon/icons/XIcon";
import { BlackBodyText100, NeutralBodySmallText100 } from "components/text/Text";
import { Card } from "reactstrap";

type Props = { text?: string; textOverride?: React.JSX.Element; onClose?: () => void };

function InfoBadge({ text, textOverride, onClose }: Props) {
  return (
    <Card className="mt-2 py-2 px-3 rounded-xxl bg-bd-blue-100 ">
      <Row className="justify-between align-items-center flex-nowrap">
        <Row className="flex-nowrap">
          <div className="center w-min-20px mr-2">
            <InfoIcon />
          </div>
          {textOverride ?? <NeutralBodySmallText100>{text}</NeutralBodySmallText100>}
        </Row>
        {onClose && <XIcon onClick={onClose} className="clickable ml-2" width={12} height={12} />}

      </Row>
    </Card>
  );
}

export default InfoBadge;
