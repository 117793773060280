import InboxDangerIcon from "components/icon/icons/InboxDangerIcon";
import InboxWarningIcon from "components/icon/icons/InboxWarningIcon";
import InfoIcon from "components/icon/icons/InfoIcon";
import React from "react";
import { MessageLevel } from "types/inbox.types";

type Props = {
  level?: MessageLevel;
  isLarge?: boolean;
};

function InboxMessageLevelIcon({ level, isLarge }: Props) {
  if (level === MessageLevel.ALERT)
    return <InboxDangerIcon width={isLarge ? 48 : undefined} height={isLarge ? 48 : undefined} />;

  if (level === MessageLevel.WARNING)
    return <InboxWarningIcon width={isLarge ? 48 : undefined} height={isLarge ? 48 : undefined} />;

  return <InfoIcon fill="#0C0C0C" width={isLarge ? 48 : undefined} height={isLarge ? 48 : undefined} />;
}

export default InboxMessageLevelIcon;
