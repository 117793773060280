import React from "react";

type Props = {};

function VehicleDetailsInfoModalIcon({}: Props) {
  return (
    <svg width="401" height="322" viewBox="0 0 401 322" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3179_9506)">
        <path
          d="M358.367 157.437C358.367 160.329 358.289 163.201 358.132 166.053C357.564 176.714 355.908 187.289 353.191 197.614C353.187 197.619 353.187 197.619 353.191 197.623C352.835 198.989 352.456 200.35 352.056 201.698C352.039 201.763 352.022 201.824 352 201.885C351.408 203.907 350.776 205.911 350.104 207.896V207.9C339.533 239.082 319.46 266.168 292.704 285.355C265.949 304.542 233.854 314.866 200.93 314.875C126.46 314.875 64.0721 263.177 47.6934 193.709C44.8935 181.822 43.4837 169.65 43.4922 157.437C43.4922 155.011 43.5473 152.597 43.6575 150.196C44.9625 120.847 54.4947 92.4573 71.1655 68.2678C71.3656 67.972 71.57 67.6763 71.7788 67.3849C100.231 26.6469 147.47 0 200.93 0C276.482 0 339.601 53.2155 354.848 124.21C354.851 124.212 354.852 124.216 354.853 124.219C355.836 128.794 356.616 133.439 357.193 138.153C357.978 144.551 358.37 150.991 358.367 157.437Z"
          fill="white"
        />
        <path
          d="M275.758 68.2718H0.754255C0.63882 68.2693 0.528964 68.2217 0.448221 68.1391C0.367477 68.0566 0.322266 67.9457 0.322266 67.8303C0.322266 67.7148 0.367477 67.6039 0.448221 67.5214C0.528964 67.4388 0.63882 67.3912 0.754255 67.3887H275.758C275.873 67.3912 275.983 67.4388 276.064 67.5214C276.145 67.6039 276.19 67.7148 276.19 67.8303C276.19 67.9457 276.145 68.0566 276.064 68.1391C275.983 68.2217 275.873 68.2693 275.758 68.2718Z"
          fill="#CACACA"
        />
        <path
          d="M265.919 53.5781H254.259C254.028 53.5827 253.808 53.6777 253.646 53.8428C253.484 54.0079 253.394 54.2298 253.394 54.461C253.394 54.6922 253.484 54.9141 253.646 55.0792C253.808 55.2442 254.028 55.3393 254.259 55.3439H265.919C266.15 55.3393 266.37 55.2442 266.532 55.0792C266.694 54.9141 266.785 54.6922 266.785 54.461C266.785 54.2298 266.694 54.0079 266.532 53.8428C266.37 53.6777 266.15 53.5827 265.919 53.5781Z"
          fill="#3F3D56"
        />
        <path
          d="M265.919 56.8926H254.259C254.028 56.8972 253.808 56.9922 253.646 57.1573C253.484 57.3223 253.394 57.5443 253.394 57.7755C253.394 58.0066 253.484 58.2286 253.646 58.3936C253.808 58.5587 254.028 58.6537 254.259 58.6583H265.919C266.15 58.6537 266.37 58.5587 266.532 58.3936C266.694 58.2286 266.785 58.0066 266.785 57.7755C266.785 57.5443 266.694 57.3223 266.532 57.1573C266.37 56.9922 266.15 56.8972 265.919 56.8926Z"
          fill="#3F3D56"
        />
        <path
          d="M265.919 60.1992H254.259C254.028 60.2038 253.808 60.2988 253.646 60.4639C253.484 60.629 253.394 60.8509 253.394 61.0821C253.394 61.3132 253.484 61.5352 253.646 61.7003C253.808 61.8653 254.028 61.9604 254.259 61.965H265.919C266.15 61.9604 266.37 61.8653 266.532 61.7003C266.694 61.5352 266.785 61.3132 266.785 61.0821C266.785 60.8509 266.694 60.629 266.532 60.4639C266.37 60.2988 266.15 60.2038 265.919 60.1992Z"
          fill="#3F3D56"
        />
        <path
          d="M72.8089 86.2656H60.4618C53.4982 99.9751 48.5961 114.637 45.9141 129.778H72.8089C73.666 129.78 74.515 129.612 75.3071 129.285C76.0993 128.957 76.819 128.477 77.4251 127.871C78.0312 127.265 78.5117 126.545 78.839 125.753C79.1663 124.961 79.334 124.112 79.3325 123.255V92.7849C79.3303 91.0558 78.6421 89.3983 77.419 88.1761C76.196 86.9538 74.5379 86.2667 72.8089 86.2656Z"
          fill="#CBD5E1"
        />
        <path
          d="M164.274 86.2656H108.331C106.602 86.2667 104.944 86.9538 103.721 88.1761C102.498 89.3983 101.81 91.0559 101.808 92.7849V123.255C101.81 124.984 102.498 126.642 103.721 127.865C104.944 129.088 106.602 129.776 108.331 129.778H164.274C166.003 129.776 167.661 129.088 168.884 127.865C170.107 126.642 170.795 124.984 170.797 123.255V92.7849C170.795 91.0559 170.107 89.3983 168.884 88.1761C167.661 86.9538 166.003 86.2667 164.274 86.2656Z"
          fill="#CBD5E1"
        />
        <path
          d="M255.73 86.2656H199.787C198.058 86.2667 196.4 86.9538 195.177 88.1761C193.954 89.3983 193.266 91.0559 193.264 92.7849V123.255C193.266 124.984 193.954 126.642 195.177 127.865C196.4 129.088 198.058 129.776 199.787 129.778H255.73C257.459 129.776 259.117 129.088 260.34 127.865C261.563 126.642 262.251 124.984 262.253 123.255V92.7849C262.251 91.0559 261.563 89.3983 260.34 88.1761C259.117 86.9538 257.459 86.2668 255.73 86.2656Z"
          fill="#CBD5E1"
        />
        <path
          d="M72.8095 150.197H43.6575C43.5488 152.598 43.4937 155.012 43.4922 157.439C43.4837 169.651 44.8935 181.823 47.6934 193.71H72.8095C74.539 193.708 76.1971 193.02 77.4201 191.797C78.643 190.574 79.331 188.916 79.3331 187.186V156.717C79.3309 154.987 78.6427 153.33 77.4196 152.108C76.1966 150.885 74.5386 150.198 72.8095 150.197Z"
          fill="#CBD5E1"
        />
        <path
          d="M164.274 150.197H108.331C106.602 150.198 104.944 150.885 103.721 152.108C102.498 153.33 101.81 154.987 101.808 156.717V187.186C101.81 188.916 102.498 190.574 103.721 191.797C104.944 193.02 106.602 193.708 108.331 193.71H164.274C166.003 193.708 167.661 193.02 168.884 191.797C170.107 190.574 170.795 188.916 170.797 187.186V156.717C170.795 154.987 170.107 153.33 168.884 152.108C167.661 150.885 166.003 150.198 164.274 150.197Z"
          fill="#CBD5E1"
        />
        <path
          d="M255.73 150.197H199.787C198.058 150.198 196.4 150.885 195.177 152.108C193.954 153.33 193.266 154.987 193.264 156.717V187.186C193.266 188.916 193.954 190.574 195.177 191.797C196.4 193.02 198.058 193.708 199.787 193.71H255.73C255.908 193.71 256.086 193.701 256.265 193.684C256.964 193.633 257.649 193.463 258.291 193.184C259.456 192.685 260.451 191.858 261.155 190.804C261.858 189.75 262.24 188.514 262.253 187.247V156.717C262.251 154.987 261.563 153.33 260.34 152.108C259.117 150.885 257.459 150.198 255.73 150.197Z"
          fill="#CBD5E1"
        />
        <path
          d="M73.9709 137.057C81.1189 137.057 86.9134 131.262 86.9134 124.114C86.9134 116.966 81.1189 111.172 73.9709 111.172C66.8229 111.172 61.0283 116.966 61.0283 124.114C61.0283 131.262 66.8229 137.057 73.9709 137.057Z"
          fill="#1E44FF"
        />
        <path
          d="M72.2048 130.849C71.9813 130.849 71.7609 130.797 71.561 130.697C71.3611 130.597 71.1873 130.451 71.0532 130.273L67.5223 125.565C67.4088 125.413 67.3263 125.241 67.2794 125.058C67.2325 124.875 67.2221 124.684 67.2489 124.497C67.2756 124.31 67.3389 124.13 67.4353 123.967C67.5316 123.805 67.659 123.663 67.8103 123.549C67.9615 123.436 68.1336 123.353 68.3168 123.306C68.4999 123.259 68.6905 123.249 68.8777 123.276C69.0648 123.302 69.2449 123.366 69.4076 123.462C69.5702 123.558 69.7123 123.686 69.8258 123.837L72.1358 126.917L78.0689 118.017C78.1737 117.86 78.3085 117.725 78.4656 117.619C78.6227 117.514 78.7991 117.44 78.9846 117.403C79.1701 117.366 79.3611 117.366 79.5467 117.402C79.7323 117.439 79.9089 117.512 80.0663 117.617C80.2237 117.722 80.3589 117.857 80.4642 118.014C80.5694 118.171 80.6426 118.348 80.6796 118.533C80.7166 118.719 80.7167 118.91 80.6798 119.095C80.6429 119.281 80.5698 119.457 80.4647 119.615L73.4028 130.207C73.2751 130.399 73.1033 130.557 72.9018 130.669C72.7003 130.78 72.4751 130.842 72.2449 130.848C72.2316 130.848 72.2182 130.849 72.2048 130.849Z"
          fill="white"
        />
        <path
          d="M168.355 138.362C175.503 138.362 181.297 132.567 181.297 125.419C181.297 118.271 175.503 112.477 168.355 112.477C161.207 112.477 155.412 118.271 155.412 125.419C155.412 132.567 161.207 138.362 168.355 138.362Z"
          fill="#1E44FF"
        />
        <path
          d="M166.581 132.154C166.357 132.154 166.137 132.102 165.937 132.002C165.737 131.902 165.563 131.757 165.429 131.578L161.898 126.87C161.785 126.719 161.702 126.547 161.655 126.364C161.608 126.181 161.598 125.99 161.625 125.803C161.652 125.616 161.715 125.436 161.811 125.273C161.908 125.111 162.035 124.968 162.186 124.855C162.337 124.742 162.51 124.659 162.693 124.612C162.876 124.565 163.066 124.555 163.254 124.582C163.441 124.608 163.621 124.672 163.784 124.768C163.946 124.864 164.088 124.992 164.202 125.143L166.512 128.223L172.445 119.323C172.657 119.006 172.986 118.786 173.36 118.711C173.735 118.637 174.123 118.714 174.44 118.925C174.758 119.137 174.978 119.466 175.053 119.84C175.129 120.214 175.052 120.603 174.841 120.921L167.779 131.513C167.651 131.705 167.479 131.863 167.278 131.975C167.076 132.086 166.851 132.148 166.621 132.154C166.608 132.154 166.594 132.154 166.581 132.154Z"
          fill="white"
        />
        <path
          d="M353.162 195.158L349.111 155.945L348.042 139.806L359.381 137.756C359.381 137.756 361.079 148.851 362.565 155.361C364.03 161.786 360.612 194.297 360.521 195.155C361.354 195.899 361.941 196.879 362.205 197.965C362.468 199.05 362.396 200.19 361.998 201.234C361.6 202.278 360.894 203.176 359.974 203.81C359.054 204.444 357.964 204.783 356.846 204.784C355.729 204.784 354.638 204.446 353.718 203.813C352.797 203.18 352.091 202.282 351.691 201.239C351.292 200.196 351.219 199.056 351.482 197.97C351.744 196.884 352.33 195.904 353.162 195.158Z"
          fill="#A0616A"
        />
        <path d="M324.149 311.884L318.526 313.169L310.891 292.091L319.19 290.193L324.149 311.884Z" fill="#A0616A" />
        <path
          d="M325.166 309.722L323.731 310.048L323.583 310.083L317.02 309.013L316.829 308.982L314.089 312.253C312.217 312.681 310.592 313.835 309.571 315.462C308.55 317.088 308.216 319.053 308.644 320.925L308.652 320.964L308.7 321.156L309.535 320.964L326.832 317.007L325.166 309.722Z"
          fill="#2F2E41"
        />
        <path d="M357.676 315.562H351.907L349.164 293.312L357.677 293.313L357.676 315.562Z" fill="#A0616A" />
        <path
          d="M357.522 313.681L357.018 313.476L351.364 311.175L351.181 311.102L347.785 313.681C346.834 313.68 345.893 313.867 345.014 314.231C344.136 314.594 343.338 315.127 342.665 315.799C341.993 316.471 341.459 317.269 341.095 318.147C340.731 319.026 340.544 319.967 340.543 320.917V321.152L356.465 321.157H359.145V313.681H357.522Z"
          fill="#2F2E41"
        />
        <path
          d="M348.906 196.479L353.757 242.571C353.757 242.571 360.216 288.722 359.003 295.09C357.79 301.458 359.307 302.368 359.307 302.368L348.239 301.579C348.239 301.579 348.542 297.637 346.723 295.818C344.903 293.998 345.813 288.54 345.813 288.54L324.344 213.46L311.304 252.881C311.304 252.881 319.542 284.849 319.238 285.455C318.935 286.062 324.457 299.372 324.457 299.372L312.102 303.469C312.102 303.469 313.619 298.618 311.496 297.101C309.373 295.585 307.554 296.798 309.373 294.069C311.193 291.34 295.038 271.297 295.393 258.38C295.5 254.494 295.63 250.325 295.785 246.054C296.606 223.45 298.157 197.98 300.994 196.479C306.149 193.749 348.906 196.479 348.906 196.479Z"
          fill="#2F2E41"
        />
        <path
          d="M325.916 103.154C332.506 103.154 337.849 97.8111 337.849 91.2205C337.849 84.6299 332.506 79.2871 325.916 79.2871C319.325 79.2871 313.982 84.6299 313.982 91.2205C313.982 97.8111 319.325 103.154 325.916 103.154Z"
          fill="#A0616A"
        />
        <path
          d="M365.766 164.117L349.561 168.23L349.674 163.682L348.444 162.378L345.987 149.505L343.378 116.18L352.332 120.929L352.343 120.937C353.907 122.657 355.214 124.594 356.226 126.687C359.601 133.549 362.844 148.664 364.542 157.443C364.6 157.627 364.712 157.789 364.864 157.908C365.706 158.681 366.256 159.721 366.42 160.852C366.584 161.984 366.353 163.137 365.766 164.117Z"
          fill="#3F3D56"
        />
        <path
          d="M270.012 178.864C270.012 178.864 292.378 155.284 292.587 154.839C294.149 142.975 298.643 137.741 299.317 138.309L310.915 142.327C310.915 142.327 307.465 153.252 305.129 159.741C302.897 165.941 277.174 181.086 276.745 181.834C277.206 182.851 277.347 183.985 277.149 185.084C276.952 186.184 276.426 187.198 275.64 187.992C274.854 188.785 273.845 189.322 272.747 189.53C271.65 189.738 270.515 189.608 269.493 189.157C268.471 188.706 267.61 187.956 267.023 187.005C266.437 186.054 266.153 184.948 266.21 183.832C266.266 182.716 266.66 181.644 267.339 180.757C268.017 179.87 268.95 179.21 270.012 178.864Z"
          fill="#A0616A"
        />
        <path
          d="M316.49 118.325C316.49 118.325 306.449 113.404 302.554 123.069C300.419 128.526 294.958 141.77 292.237 149.922C292.219 149.974 292.194 150.023 292.163 150.068C291.435 151.15 291.042 152.422 291.033 153.726C291.034 153.82 291.024 153.915 291.004 154.008C291.004 154.008 302.039 156.158 305.321 161.942L306.623 160.529L306.854 159.539L316.49 118.325Z"
          fill="#3F3D56"
        />
        <path
          d="M353.018 201.502C329.907 206.275 299.281 198.07 299.281 198.07L307.451 162.655L304.547 125.593C304.192 121.061 306.773 116.812 310.789 115.307L316.334 113.23L320.918 106.668L334.01 107.098L339.244 113.987L343.374 116.178L352.328 120.927L352.368 120.947V120.966L349.558 148.223L347.863 164.657C347.863 164.657 353.01 183.141 351.954 185.592C350.898 188.039 351.816 186.857 352.072 188.166C352.328 189.474 353.112 189.557 353.231 192.134C353.353 194.708 353.018 201.502 353.018 201.502Z"
          fill="#3F3D56"
        />
        <path
          d="M338.873 86.9719C337.93 86.0416 339.349 83.5786 339.081 82.2813C338.774 80.7939 337.135 80.7504 336.744 79.2827C336.323 77.7039 334.641 76.8444 333.085 76.344C329.098 75.0682 324.844 74.8734 320.757 75.7793C318.948 76.221 317.167 76.7696 315.424 77.4223C314.22 77.8459 315.381 76.6931 314.177 77.1168C313.721 77.2183 313.319 77.4851 313.048 77.8656C312.832 78.2748 313.124 78.9292 313.579 78.8463C312.11 79.4943 310.64 80.1424 309.17 80.7905C309.995 81.6539 310.819 82.5173 311.643 83.3807C310.471 83.0472 309.222 84.4442 309.684 85.5714C309.87 85.9272 310.139 86.2325 310.47 86.461C310.8 86.6895 311.18 86.8343 311.579 86.883C312.903 87.1406 311.898 88.4004 313.247 88.3758C314.595 88.3513 323.998 85.5874 324.653 86.7659C325.12 87.6051 325.068 88.7167 325.735 89.4082C326.588 90.2927 328.076 89.9692 329.162 89.3933C330.247 88.8175 331.348 88.026 332.569 88.1641C333.843 88.3082 334.807 90.6636 335.121 91.9074C335.435 93.1511 335.237 94.4598 335.037 95.7269C334.775 97.391 332.148 99.0551 331.885 100.719C334.801 101.526 337.928 98.9601 339.081 94.4986C338.966 91.6784 340.882 88.9543 338.873 86.9719Z"
          fill="#2F2E41"
        />
        <path
          d="M258.806 200.118C265.954 200.118 271.749 194.323 271.749 187.175C271.749 180.027 265.954 174.232 258.806 174.232C251.658 174.232 245.864 180.027 245.864 187.175C245.864 194.323 251.658 200.118 258.806 200.118Z"
          fill="#1E44FF"
        />
        <path
          d="M257.048 193.911C256.825 193.911 256.604 193.859 256.404 193.759C256.204 193.659 256.031 193.514 255.896 193.335L252.366 188.627C252.252 188.476 252.17 188.304 252.123 188.121C252.076 187.937 252.065 187.747 252.092 187.56C252.119 187.373 252.182 187.193 252.279 187.03C252.375 186.867 252.502 186.725 252.654 186.612C252.805 186.498 252.977 186.416 253.16 186.369C253.343 186.322 253.534 186.311 253.721 186.338C253.908 186.365 254.088 186.428 254.251 186.525C254.413 186.621 254.556 186.748 254.669 186.9L256.979 189.979L262.912 181.08C263.017 180.922 263.152 180.787 263.309 180.682C263.466 180.576 263.642 180.503 263.828 180.466C264.013 180.428 264.204 180.428 264.39 180.465C264.576 180.501 264.752 180.574 264.91 180.679C265.067 180.784 265.202 180.919 265.307 181.076C265.413 181.234 265.486 181.41 265.523 181.596C265.56 181.781 265.56 181.972 265.523 182.158C265.486 182.343 265.413 182.52 265.308 182.677L258.246 193.27C258.118 193.462 257.947 193.62 257.745 193.731C257.544 193.843 257.318 193.904 257.088 193.911C257.075 193.911 257.061 193.911 257.048 193.911Z"
          fill="white"
        />
        <path
          d="M398.234 321.482C398.234 321.55 398.221 321.618 398.195 321.681C398.169 321.744 398.131 321.801 398.083 321.849C398.035 321.897 397.977 321.935 397.915 321.961C397.852 321.987 397.784 322 397.716 322H275.815C275.678 322 275.546 321.945 275.449 321.848C275.352 321.751 275.298 321.62 275.298 321.482C275.298 321.345 275.352 321.213 275.449 321.116C275.546 321.019 275.678 320.965 275.815 320.965H397.716C397.784 320.965 397.852 320.978 397.915 321.004C397.977 321.03 398.035 321.068 398.083 321.116C398.131 321.164 398.169 321.221 398.195 321.284C398.221 321.347 398.234 321.414 398.234 321.482Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_3179_9506">
          <rect width="400.443" height="321.999" fill="white" transform="translate(0.321289)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default VehicleDetailsInfoModalIcon;
