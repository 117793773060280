import React, { useState } from "react";
import Card from "../card/Card";
import { BlackBodyText100 } from "../text/Text";
import ChargingSessionsDetailsPageCardBlock from "./ChargingSessionsDetailsPageCardBlock";
import { Charging } from "../../types/charging-sessions.types";
import PrimaryButton from "../button/PrimaryButton";
import InfoBadge from "../badge/InfoBadge";
import CustomModalWithXIcon from "../modal/CustomModalWithXIcon";
import ChargingAssignDriverForm from "./ChargingAssignDriverForm";

type Props = {
  charging: Charging
  refetch: () => Promise<void>
}

export default function ChargingDetailsAssignDriverEditorCard({ charging, refetch }: Props) {
  const { user } = charging;

  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleCompleteAction = () => {
    setIsModalOpen(false);
    refetch();
  };

  if (user) {
    return <></>;
  }


  return (
    <>
      <CustomModalWithXIcon
        className={"wide-xs"}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      >
        <ChargingAssignDriverForm charging={charging} onCompleteAction={handleCompleteAction} />
      </CustomModalWithXIcon>

      <Card className={'mt-4'}>
        <>
          <ChargingSessionsDetailsPageCardBlock
            isBorderTopVisible={false}
            leftLabel="Driver name"
            leftTextOverwrite={
              <div className="clickable">
                <BlackBodyText100>
                  No driver assigned
                </BlackBodyText100>
              </div>
            }
            rightTextOverwrite={
              <>
                <PrimaryButton onClick={() => setIsModalOpen(true)} text={"Assign driver"} />
              </>
            }
          />

          <InfoBadge
            text={"Assign a driver to process payment through them. A new invoice will be generated for this charging session under the assigned driver."} />
        </>

      </Card>

    </>

  );
}

