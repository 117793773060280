import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { Icon } from "../Component";
import ButtonWithIcon from "./ButtonWithIcon";

type Props = { onVehiclesClicked?: () => void };

function VehiclesButton({ onVehiclesClicked }: Props) {
  /**
   * Hooks
   */
  const history = useHistory();

  /**
   * Handlers
   */
  const handleVehiclesClicked = useCallback(() => {
    if (onVehiclesClicked) {
      onVehiclesClicked();
      return;
    }
    history.push("/vehicles");
  }, [history, onVehiclesClicked]);

  return <ButtonWithIcon className="mt-md-0" text={"Vehicles"} onClick={handleVehiclesClicked} />;
}

export default VehiclesButton;
