import React, { useCallback, useRef, useState } from "react";
import Countdown from "react-countdown";

import OutlinedButton from "./OutlinedButton";

type Props = {
  readonly onResendCodeButtonPressed?: () => void;
  readonly className?: string;
  readonly isLoading?: boolean;
  readonly isDisabled?: boolean;
};

const CountDownTime = 59 * 1000;
const MaxAttemptLimit = 4;
function ResendCodeOutlinedButton({ onResendCodeButtonPressed, className, isLoading, isDisabled, ...props }: Props) {
  const [timer, setTimer] = useState<undefined | number>();
  const [clickAttempt, setClickAttempt] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);

  const handleCountDownComplete = useCallback(() => {
    setIsTimerActive(false);
  }, []);

  const startTimer = useCallback(() => {
    setIsTimerActive(true);
    setTimer(Date.now() + CountDownTime);
  }, []);

  const handleResendCodeButtonPressed = useCallback(() => {
    setClickAttempt((a) => a + 1);
    onResendCodeButtonPressed && onResendCodeButtonPressed();
    startTimer();
  }, [onResendCodeButtonPressed, startTimer]);

  const isButtonDisabled = isTimerActive || clickAttempt > MaxAttemptLimit;

  return (
    <OutlinedButton
      textOverride={
        isTimerActive ? (
          <Countdown
            onComplete={handleCountDownComplete}
            date={timer}
            renderer={(props) => <div>{props.seconds}</div>}
          />
        ) : undefined
      }
      text="Resend code"
      isDisabled={isDisabled || isButtonDisabled}
      onClick={handleResendCodeButtonPressed}
      className={className}
      textColor={"#680031"}
      theme="primary"
      {...props}
    />
  );
}

export default ResendCodeOutlinedButton;
