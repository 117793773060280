import React, { useMemo } from "react";

import Badge from "./Badge";
import { DriverBadgeStatus, DriverSignUpApplicationStatus } from "../../types/driver.types";

export default function DriverStatusBadge({ status }: { status: DriverBadgeStatus | DriverSignUpApplicationStatus}) {
  const badgeContent = useMemo(() => {
    const commonStyles = "px-2 rounded-xxxl center";
    switch (status) {
      case DriverBadgeStatus.ACTIVE:
        return {
          text: "Active",
          textStyle: "text-positive-200",
          containerStyle: `bg-positive-950 ${commonStyles}`
        };

      case DriverBadgeStatus.NOT_ACTIVE:
        return {
          text: "Inactive",
          textStyle: "text-cherry-200",
          containerStyle: `bg-cherry-1000 ${commonStyles}`
        };


      case DriverBadgeStatus.APPROVED:
        return {
          text: "Approved",
          textStyle: "text-positive-200",
          containerStyle: `bg-positive-950 ${commonStyles}`
        };

      case DriverBadgeStatus.REJECTED:
        return {
          text: "Rejected",
          textStyle: "text-cherry-200",
          containerStyle: `bg-cherry-1000 ${commonStyles}`
        };


      case DriverBadgeStatus.PENDING_REVIEW:
        return {
          text: "Pending",
          textStyle: "text-neutral-200",
          containerStyle: `bg-neutral-900 ${commonStyles}`
        };


      default:
        return {
          text: "Unknown status",
          textStyle: "text-neutral-200",
          containerStyle: `bg-neutral-900 ${commonStyles}`
        };
    }
  }, [status]);
  return <Badge {...badgeContent} />;
}
