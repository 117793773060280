import React from "react";
import { SvgProps } from "../../../types/svg.types";

const SearchIcon = ({ fill, width, height, className }: SvgProps) => {
  const fillValue = fill ?? "#680031";
  const widthValue = width ?? 20;
  const heightValue = height ?? 20;

  return (
    <svg
      width={widthValue}
      height={heightValue}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.7965 15.093C12.274 15.093 15.093 12.274 15.093 8.7965C15.093 5.31904 12.274 2.5 8.7965 2.5C5.31904 2.5 2.5 5.31904 2.5 8.7965C2.5 12.274 5.31904 15.093 8.7965 15.093Z"
        stroke="#A0A8AF"
        stroke-width="2.69139"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.5167 16.6659L15.093 13.2422"
        stroke="#A0A8AF"
        stroke-width="2.69139"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default SearchIcon;

/**
 *   width={`${widthValue}`}
      height={`${heightValue}`}
      viewBox={`0 0 ${widthValue} ${heightValue} `}
 */
