import React, { useState } from "react";
import WarningIcon from "../icon/icons/WarningIcon";
import { NeutralBodyMediumText0, NeutralBodyMediumText100, NeutralTitleLargeTextBlack } from "../text/Text";
import HoverUnderlinedTextButton from "../button/HoverUnderlinedTextButton";
import { useLocation } from "react-router";
import { useAppSelector } from "../../hooks/redux.hooks";
import CustomModalWithoutXIcon from "../modal/CustomModalWithoutXIcon";
import ClipboardWithCopyIcon from "../clipboard/ClipboardWithCopyIcon";
import { useHistory } from "react-router-dom";
import Row from "../custom/Row";
import PrimaryButton from "../button/PrimaryButton";
import TextButton from "../button/TextButton";


const appUrl = process.env.REACT_APP_APP_URL;


const AppWideTeslaConnectionBadge = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const appRoute = `${appUrl}/add-vehicle/tesla`;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const user = useAppSelector(state => state.user.currentUser);


  const isButtonVisible = pathname === "/" || pathname.includes("/vehicles");

  const areThereAnyInvalidTeslaTokens = user?.invalidTeslaTokens && user?.invalidTeslaTokens.length > 0;

  const invalidTokenCount = user?.invalidTeslaTokens.length;
  const suffix = (invalidTokenCount ?? 0) > 1 ? "s" : "";

  if (!isButtonVisible || !areThereAnyInvalidTeslaTokens) {
    return <></>;
  }

  return (
    <>
      <CustomModalWithoutXIcon className={"wide-sm"} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <div className="p-4">
          <NeutralTitleLargeTextBlack>Reconnect Tesla Accounts to Bluedot for Fleets</NeutralTitleLargeTextBlack>
          <NeutralBodyMediumText0 className={"mt-2"}>The following Tesla accounts need to be reconnected to Bluedot for
            Fleets:</NeutralBodyMediumText0>
          <ul className={"mt-2"}>
            {user?.invalidTeslaTokens.map((token, ix) => <Row className={"align-items-center clickable"} key={ix}>
              <NeutralBodyMediumText0 className={"mr-2"}> &#x2022;</NeutralBodyMediumText0>
              <ClipboardWithCopyIcon
                TextComponent={NeutralBodyMediumText0} text={token.email} />
            </Row>)}
          </ul>

          <NeutralBodyMediumText0 className={"mt-2"}>You can reconnect below:</NeutralBodyMediumText0>
          <TextButton
            text={appRoute}
            className={"text-decoration-underline clickable text-black-0"}
            onClick={() => history.push("/add-vehicle/tesla")}
          />


          <Row className={"mt-2 w-100 justify-content-end"}>
            <PrimaryButton text={"Done"} onClick={() => setIsModalOpen(false)} />
          </Row>

        </div>
      </CustomModalWithoutXIcon>
      <div className="center app-wide-badge">
        <WarningIcon width={18} height={18} />
        <NeutralBodyMediumText100 className={"mx-2 mr-4"}>Connection lost for {invalidTokenCount} Tesla
          account{suffix}!</NeutralBodyMediumText100>
        <HoverUnderlinedTextButton text={"Reconnect>"} onClick={() => setIsModalOpen(true)} />
      </div>
    </>
  );
};

export default AppWideTeslaConnectionBadge;