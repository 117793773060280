import React from "react";
import { SvgProps } from "types/svg.types";

function PasswordEyeIcon(props: SvgProps) {
  return (
    <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.199951 6.0001C1.12114 4.19311 2.524 2.676 4.25347 1.61644C5.98295 0.556866 7.97171 -0.00390625 9.99995 -0.00390625C12.0282 -0.00390625 14.017 0.556866 15.7464 1.61644C17.4759 2.676 18.8788 4.19311 19.7999 6.0001C18.8788 7.80708 17.4759 9.32419 15.7464 10.3838C14.017 11.4433 12.0282 12.0041 9.99995 12.0041C7.97171 12.0041 5.98295 11.4433 4.25347 10.3838C2.524 9.32419 1.12114 7.80708 0.199951 6.0001ZM9.99995 10.0001C11.0608 10.0001 12.0782 9.57867 12.8284 8.82852C13.5785 8.07838 14 7.06096 14 6.0001C14 4.93923 13.5785 3.92181 12.8284 3.17167C12.0782 2.42152 11.0608 2.0001 9.99995 2.0001C8.93909 2.0001 7.92167 2.42152 7.17152 3.17167C6.42138 3.92181 5.99995 4.93923 5.99995 6.0001C5.99995 7.06096 6.42138 8.07838 7.17152 8.82852C7.92167 9.57867 8.93909 10.0001 9.99995 10.0001ZM9.99995 8.0001C9.46952 8.0001 8.96081 7.78938 8.58574 7.41431C8.21067 7.03924 7.99995 6.53053 7.99995 6.0001C7.99995 5.46966 8.21067 4.96096 8.58574 4.58588C8.96081 4.21081 9.46952 4.0001 9.99995 4.0001C10.5304 4.0001 11.0391 4.21081 11.4142 4.58588C11.7892 4.96096 12 5.46966 12 6.0001C12 6.53053 11.7892 7.03924 11.4142 7.41431C11.0391 7.78938 10.5304 8.0001 9.99995 8.0001Z"
        fill="black"
      />
    </svg>
  );
}

export default PasswordEyeIcon;
