import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NeutralBodyText300, NeutralTitleMediumTextBlack } from "../text/Text";
import DriverFormReimbursementSetupStep from "./form/DriverFormReimbursementSetupStep";
import EditorModal from "../modal/EditorModal";
import { DriverHookStates } from "../../types/driver.types";
import { useForm } from "react-hook-form";
import { ReimbursementFormState } from "../../types/reimbursement.types";
import { useDriverDetailsReimbursementStates } from "../../hooks/driver.hooks";
import { handleApiErrorResponse } from "../../utils";
import { useAlertManager } from "../../hooks/alert.hooks";
import { getFormattedNumberForUsdByCent } from "../../utils/format.utils";

type Props = {
  isReimbursementFormModalOpen: boolean
  setIsReimbursementFormModalOpen: (p: boolean) => void
  driverHook: DriverHookStates
}

const DriverDetailsPageEnableReimbursementModal = ({
                                                     driverHook,
                                                     isReimbursementFormModalOpen,
                                                     setIsReimbursementFormModalOpen
                                                   }: Props) => {
  const { handleSubmit, register, reset, control, errors } = useForm<ReimbursementFormState>({
    mode: "all"
  });

  const { isLoading, driver } = driverHook;

  const chargingReimbursement = driver?.settings?.data.chargingReimbursement;
  const fixedRate = chargingReimbursement?.tariff?.fixed.energyPrice;


  /**
   *
   */
  useEffect(() => {
    if (isLoading || !fixedRate) return;

    reset({ fixedRate: getFormattedNumberForUsdByCent(fixedRate) });
  }, [fixedRate, isLoading, reset]);


  const driverId = driverHook.driver?.id;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const driverFormReimbursementStepStates = useDriverDetailsReimbursementStates(driverHook);


  const commonFormStepProps = useMemo(() => {
    return {
      register, control, errors
    };
  }, [register, control, errors]);


  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();

  /**
   *
   */
  const handleSubmitDriverReimbursementStep = useCallback(async (formData: ReimbursementFormState) => {
    const { fixedRate } = formData;


    try {
      setIsSubmitting(true);
      await driverFormReimbursementStepStates.handleSubmitEnableReimbursementCallback(driverId, fixedRate);
      handleOpenSuccessAlert("Reimbursement settings saved successfully!");
      setIsReimbursementFormModalOpen(false);
      driverHook.refetchDriver();
    } catch (err) {
      handleOpenErrorAlert(handleApiErrorResponse(err));
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  }, [driverFormReimbursementStepStates, driverHook, driverId, handleOpenErrorAlert, handleOpenSuccessAlert, setIsReimbursementFormModalOpen]);


  return (
    <EditorModal
      modalSize="wide-sm"
      setIsModalOpen={setIsReimbursementFormModalOpen}
      isModalOpen={isReimbursementFormModalOpen}
      onSubmit={handleSubmit(handleSubmitDriverReimbursementStep)}
      isLoading={isSubmitting}
      modalPadding={'p-0'}
      buttonPadding={'p-3'}
    >
      <div className={"h-max-80vh h-700px overflow-auto"}>
        <div className={"w-100 h-100 p-3"}>
          <NeutralTitleMediumTextBlack>Home Charging Reimbursement Status</NeutralTitleMediumTextBlack>
          <NeutralBodyText300 className={"mb-3"}>Include driver for home charging reimbursement
            process.</NeutralBodyText300>
          <DriverFormReimbursementSetupStep
            isToggleNeeded={false} {...driverFormReimbursementStepStates} {...commonFormStepProps} />
        </div>
      </div>

    </EditorModal>
  );
};

export default DriverDetailsPageEnableReimbursementModal;