// React
import { useAlertManager } from "hooks/alert.hooks";
import { useAllDriversAsync } from "hooks/data/driver-data-accessor.hooks";
import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { getDriversForDriverSelector } from "utils/driver.utils";

// Hooks && Services && Utils
// Types
import { Driver, DriverAssignmentFormOperation } from "../../types/driver.types";
import { Vehicle } from "../../types/vehicle.types";
import CancelButton from "../button/CancelButton";

// Components
import HorizontalLine from "components/line/HorizontalLine";
import VehicleDriverSchedulePeriodSelector
  from "components/vehicle-driver-schedule/form/VehicleDriverSchedulePeriodSelector";
import { useVehicleFormDriverSetupStates } from "hooks/vehicle.hooks";
import { handleApiErrorResponse } from "utils";
import SaveButton from "../button/SaveButton";
import Card from "../card/Card";
import Row from "../custom/Row";
import LoadingSkeleton from "../loading/LoadingSkeleton";
import { BlackH5Text0 } from "../text/Text";
import DriverSelectorEditor from "./editors/DriverSelectorEditor";

type Props = {
  readonly defaultDrivers?: Driver[];
  readonly vehicle?: Vehicle;
  readonly isVehicleLoading: boolean;
};

export type SelectedDriverForEditor = { label: string; value: string };
export type SelectedDriversForEditor = SelectedDriverForEditor[];

function AssignDriverForm({ vehicle, isVehicleLoading }: Props) {
  const history = useHistory();
  const { allDrivers, isLoading: isDriverOptionsLoading } = useAllDriversAsync();
  const driverOptions = getDriversForDriverSelector(allDrivers);

  const vehicleFormDriverSetupStates = useVehicleFormDriverSetupStates();
  const { handleSubmitAddScheduleAsync, handleSetDriverSelector, driverSelector } = vehicleFormDriverSetupStates;
  const { handleOpenErrorAlert } = useAlertManager();

  const [isLoading, setIsLoading] = useState(false);

  const handleCancelButtonPressed = useCallback(() => {
    try {
      history.goBack();
    } catch (error) {
      console.error(error);
    }
  }, [history]);

  /**
   *
   */
  const handleAssignDriverAsync = useCallback(async () => {
    if (!vehicle) {
      return;
    }
    try {
      setIsLoading(true);
      const vehicleId = vehicle.id;
      await handleSubmitAddScheduleAsync(vehicleId);
      const operation = DriverAssignmentFormOperation.Assign;
      history.push(`/assign-driver-completed/${operation}`, { vehicleId });
    } catch (err: any) {
      handleOpenErrorAlert(handleApiErrorResponse(err));
    } finally {
      setIsLoading(false);
    }
  }, [handleOpenErrorAlert, handleSubmitAddScheduleAsync, history, vehicle]);

  const handleAssignDriversClicked = handleAssignDriverAsync;

  // Values for rendering

  return (
    <div className="center">
      <div className="wide-sm-fix">
        <Card className="mt-4" padding="p-4">
          {isVehicleLoading ? (
            <LoadingSkeleton count={5} />
          ) : (
            <>
              <DriverSelectorEditor
                isLoading={isDriverOptionsLoading}
                driverOptions={driverOptions}
                editorLabelOverride={<BlackH5Text0 className="mb-2">Assign your driver</BlackH5Text0>}
                onSelectedDriverChanged={handleSetDriverSelector}
                selectedDriver={driverSelector}
              />

              <HorizontalLine />
              <VehicleDriverSchedulePeriodSelector
                vehicleScheduleSelectorHooks={vehicleFormDriverSetupStates}
                description="Choose a start date to initiate the driver’s access to the vehicle. If left unspecified, access begins
          immediately upon assignment. Similarly, select an end date to define when access will end. Without an end
          date, access continues indefinitely without a set expiration. This flexibility allows for both fixed-term and
          open-ended vehicle assignments."
              />

              <Row className="justify-content-end align-items-center mt-4 pt-4">
                <CancelButton type="button" onClick={handleCancelButtonPressed} />
                <SaveButton isLoading={isLoading} onClick={handleAssignDriversClicked} />
              </Row>
            </>
          )}
        </Card>
      </div>
    </div>
  );
}

export default AssignDriverForm;
