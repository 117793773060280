import CircularLoadingSpinner from "components/loading/CircularLoadingSpinner";
import { useTeslaConnection } from "hooks/vehicle.hooks";
import { useEffect } from "react";

type Props = {};

function TeslaRedirectionPage({}: Props) {
  const { handleSwitchTab } = useTeslaConnection();
  useEffect(() => {
    handleSwitchTab();
  }, [handleSwitchTab]);

  return <CircularLoadingSpinner />;
}

export default TeslaRedirectionPage;
