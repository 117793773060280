import React from "react";
import { VehicleDataAccessorHook } from "../../../../types/vehicle.types";
import Row from "../../../custom/Row";
import Col from "../../../custom/Col";
import VehicleDetailsOverviewCard from "../VehicleDetailsOverviewCard";
import VehicleDetailsAttributes from "../VehicleDetailsAttributes";
import VehiclesDetailsTirePressure from "../VehiclesDetailsTirePressure";
import VehicleDetailsFetchedInfoTooltip from "../VehicleDetailsFetchedInfoTooltip";
import { BlackSubtitleText100 } from "../../../text/Text";
import { getVehicleConnectionStatus } from "../../../../utils/vehicle.utils";


type Props = {
  readonly vehicleHook: VehicleDataAccessorHook;
}

function VehicleDetailsAboutContent({ vehicleHook }: Props) {
  const tirePressure = vehicleHook.vehicle?.telemetry?.tpms;
  const { isTeslaConnected } = getVehicleConnectionStatus(vehicleHook.vehicle);
  return (
    <Row>
      <Col lg={6} className="pr-lg-3">
        <VehicleDetailsOverviewCard title={"Attributes"}
                                    content={<VehicleDetailsAttributes vehicleHook={vehicleHook} />} />

      </Col>
      <Col lg={6} className="pl-lg-3">
        {isTeslaConnected && <VehicleDetailsOverviewCard
          titleOverwrite={
            <Row className={"align-items-center"}>
              <BlackSubtitleText100>Tire pressure</BlackSubtitleText100>
              <VehicleDetailsFetchedInfoTooltip fetchedAt={tirePressure?.meta.fetchedAt} />
            </Row>
          }
          content={
            <VehiclesDetailsTirePressure
              tirePressure={tirePressure}
            />
          }
        />}

      </Col>
    </Row>
  );
}

export default VehicleDetailsAboutContent;