import React from "react";
import Skeleton from "react-loading-skeleton";
import Card from "../card/Card";
import Row from "../custom/Row";
import TrendDownIcon from "../icon/icons/TrendDownIcon";
import TrendUpIcon from "../icon/icons/TrendUpIcon";
import {
  BlackH6Text0,
  NeutralBodyLargeText200,
  NeutralBodyMediumText200,
  NeutralTitleMediumTextBlack
} from "components/text/Text";

type DashboardOverviewCardProps = {
  title: string;
  total?: string;
  isLoading: boolean;
  percent?: number;
  isSmall?: boolean;
  titleLeftIcon?: React.ReactNode;
};

export default function DashboardOverviewCard({
                                                isSmall,
                                                title,
                                                total,
                                                isLoading,
                                                percent = 0,
                                                titleLeftIcon
                                              }: DashboardOverviewCardProps) {
  const isChangeZero = !percent;
  const isTrendUp = percent > 0;
  const percentFixed = percent?.toFixed(1) ?? 0;
  const percentDisplay = isTrendUp ? `+${percentFixed}%` : isChangeZero ? "0%" : `${percentFixed}%`;
  const heightClass = isSmall ? "h-96px" : "h-175px";
  const loadingSkeletonCount = isSmall ? 3 : 5;
  const loadingSkeletonHeight = isSmall ? 20 : 26;

  return (
    <Card className={`${heightClass} justify-between`} padding="p-2">
      {isLoading ? (
        <Skeleton height={loadingSkeletonHeight} count={loadingSkeletonCount} />
      ) : (
        <>

          <NeutralBodyMediumText200>{title}</NeutralBodyMediumText200>

          <Row className="d-flex justify-between align-items-center">
            <Row className="d-flex align-items-center">
              {titleLeftIcon}
              <BlackH6Text0>{total}</BlackH6Text0>
            </Row>



            <Row className="flex-start align-items-center">
              {!isChangeZero && (isTrendUp ? <TrendUpIcon /> : <TrendDownIcon />)}
              <NeutralBodyLargeText200 className="ml-1">{percentDisplay}</NeutralBodyLargeText200>
              <NeutralBodyLargeText200 className="ml-1">Last Month</NeutralBodyLargeText200>
            </Row>
          </Row>




        </>
      )}
    </Card>
  );
}
