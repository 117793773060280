import { BlackOverlineText200, BodyText } from "components/text/Text";
import Card from "./Card";

type Props = {
  readonly description: string;
};

function WhatIsNextCard({ description }: Props) {
  return (
    <Card padding="p-2" className="my-4 bg-secondary">
      <BodyText className="link-text">What’s Next:</BodyText>
      <BlackOverlineText200>{description}</BlackOverlineText200>
    </Card>
  );
}

export default WhatIsNextCard;
