import CrossIcon from "components/icon/icons/CrossIcon";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { BlackBodyText100, BlackBodyText200 } from "components/text/Text";
import { useTranslation } from "react-i18next";
import Row from "../../components/custom/Row";
import PageHeader from "../../components/header/PageHeader";
import VehiclesTable from "../../components/vehicle-components/VehiclesTable";
import { useAuthStatus, useSyncOrganization } from "../../hooks/auth.hooks";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import UnderlinedTextButton from "../../components/button/UnderlinedTextButton";
import { TextButtonMode } from "../../components/button/TextButton";
import HoverUnderlinedTextButton from "../../components/button/HoverUnderlinedTextButton";

export default function VehiclesPage() {
  const { t } = useTranslation("common");
  const { isLoading } = useSyncOrganization();
  const { user } = useAuthStatus();

  return (
    <>
      <Head title={t("vehicle.title.vehicles_page")}></Head>

      <Content>
        <PageHeader
          title="Vehicles"
          overrideDescription={
            isLoading ? (
              <div className="w-max-100 w-700px mt-1">
                <LoadingSkeleton count={1} />
              </div>
            ) : (
              <Row className="align-items-center mt-1 ">
                <BlackBodyText200>View and manage all your vehicles. Your organization has</BlackBodyText200>
                <BlackBodyText100 className="mx-1">{user?.vehicleCount} total vehicles.</BlackBodyText100>
                <Row
                  onClick={() =>
                    window.open(
                      "/vehicle-locator",
                      "_blank" // <- This is what makes it open in a new window.
                    )
                  }
                  className="align-items-center hover-text-decoration-underline clickable"
                >
                  <HoverUnderlinedTextButton mode={TextButtonMode.BLUE} text={'See them on the live map'}/>
                  <CrossIcon fill="#1E44FF" />
                </Row>
              </Row>
            )
          }
        />

        <VehiclesTable />
      </Content>
    </>
  );
}
