import React from "react";

type Props = {};

function QuestionMarkIcon({}: Props) {
  return (
    <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="53" height="53" rx="26.5" fill="#FFEFE6" />
      <g clip-path="url(#clip0_24874_75253)">
        <path
          d="M26.9696 14.5C23.2281 14.5 20.8047 16.033 18.9021 18.7668C18.5569 19.2628 18.6633 19.9427 19.1448 20.3078L21.1669 21.841C21.6531 22.2097 22.3449 22.1235 22.7256 21.6466C23.8998 20.1756 24.7708 19.3287 26.6048 19.3287C28.0469 19.3287 29.8306 20.2568 29.8306 21.6551C29.8306 22.7123 28.9579 23.2552 27.534 24.0535C25.8736 24.9844 23.6763 26.143 23.6763 29.0412V29.5C23.6763 30.1213 24.1799 30.625 24.8013 30.625H28.1984C28.8197 30.625 29.3234 30.1213 29.3234 29.5V29.2294C29.3234 27.2203 35.1953 27.1367 35.1953 21.7C35.1953 17.6058 30.9484 14.5 26.9696 14.5ZM26.4998 32.0059C24.7094 32.0059 23.2528 33.4625 23.2528 35.253C23.2528 37.0434 24.7094 38.5 26.4998 38.5C28.2903 38.5 29.7469 37.0434 29.7469 35.2529C29.7469 33.4625 28.2903 32.0059 26.4998 32.0059Z"
          fill="#FE5D02"
        />
      </g>
      <defs>
        <clipPath id="clip0_24874_75253">
          <rect width="18" height="24" fill="white" transform="translate(17.5 14.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default QuestionMarkIcon;
