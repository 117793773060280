import React, { useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Form, FormGroup, Modal, ModalBody } from "reactstrap";
import { updateVehicleAsync } from "../../../services/vehicle.services";
import { Vehicle, VehicleDetailsEditFormMode } from "../../../types/vehicle.types";
import CancelButton from "../../button/CancelButton";
import GotItButton from "../../button/GotItButton";
import SaveButton from "../../button/SaveButton";
import Col from "../../custom/Col";
import Row from "../../custom/Row";
import TickCircleIcon from "../../icon/icons/TickCircleIcon";
import { BlackH5Text0, BlackH5Text100 } from "../../text/Text";
import { useAlertManager } from "../../../hooks/alert.hooks";
import { handleApiErrorResponse } from "../../../utils";

type Props = {
  readonly isModalOpen: boolean;
  readonly setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly formMode: VehicleDetailsEditFormMode;
  readonly defaultValue?: string;
  readonly vehicle?: Vehicle;
  readonly triggerVehicleFetching: () => void;
};

type FormValues = {
  readonly name?: string;
  readonly plateNumber?: string;
};

function VehicleDetailsEditFormModal({
  isModalOpen,
  setIsModalOpen,
  formMode,
  defaultValue,
  vehicle,
  triggerVehicleFetching,
}: Props) {
 const {handleOpenSuccessAlert,handleOpenErrorAlert}=  useAlertManager()
  const [isCompletedModalOpen, setIsCompletedModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit } = useForm<FormValues>();

  const isModeEditName = formMode === VehicleDetailsEditFormMode.NAME;

  const formName = isModeEditName ? "name" : "plateNumber";
  const formTitle = isModeEditName ? "Edit vehicle name" : "Edit license plate";
  const formPlaceHolder = isModeEditName ? "Enter name" : "Enter license plate";
  const completedModalText = isModeEditName
    ? "Success! The vehicle name has been changed successfully."
    : "Success! The license plate has been changed successfully.";

  const handleValidatedSubmit: SubmitHandler<FormValues> = useCallback(
    async (validData) => {
      if (!vehicle) {
        return;
      }
      const finalData = isModeEditName
        ? {
            name: validData.name,
            plateNumber: vehicle.plateNumber,
          }
        : { plateNumber: validData.plateNumber, name: vehicle.name };

      try {
        setIsSubmitting(true);
        await updateVehicleAsync(vehicle.id, finalData);
        handleOpenSuccessAlert("Vehicle updated successfully.");
        setIsModalOpen(false)
      } catch (error) {
        handleOpenErrorAlert(handleApiErrorResponse((error)))
      } finally {
        setIsSubmitting(false);
        triggerVehicleFetching();
      }
    },
    [handleOpenErrorAlert, handleOpenSuccessAlert, isModeEditName, setIsModalOpen, triggerVehicleFetching, vehicle]
  );

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(!isModalOpen)}
      className="wide-xs modal-dialog-centered  overflow-hidden  "
    >
      <ModalBody className="w-md-100 h-md-75  h-lg-50  h-max-100 p-3 max-w-90  overflow-hidden position-relative">
        {isCompletedModalOpen ? (
          <Col className="d-flex flex-column justify-content-center align-items-center">
            <TickCircleIcon fill={"#23D206"} width={100} height={100} />
            <BlackH5Text100 className="my-3 text-center p-2 px-4">{completedModalText}</BlackH5Text100>
            <GotItButton
              onGotItButtonPressed={() => {
                setIsCompletedModalOpen(false);
                setIsModalOpen(false);
              }}
            />
          </Col>
        ) : (
          <Form
            className="w-100 h-100 "
            onReset={() => setIsModalOpen(false)}
            onSubmit={handleSubmit(handleValidatedSubmit)}
          >
            <BlackH5Text0>{formTitle}</BlackH5Text0>
            <Col className="mt-2">
              <FormGroup>
                <input
                  className="form-control"
                  type="text"
                  name={formName}
                  placeholder={formPlaceHolder}
                  defaultValue={defaultValue}
                  ref={register({ required: "required" })}
                />
              </FormGroup>
            </Col>
            <Row className="w-100 justify-end align-center mt-3">
              <CancelButton />
              <SaveButton isDisabled={isSubmitting} isLoading={isSubmitting} />
            </Row>
          </Form>
        )}
      </ModalBody>
    </Modal>
  );
}

export default VehicleDetailsEditFormModal;
