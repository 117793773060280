import { BlackBodyText200, BlackSubtitleText100 } from "components/text/Text";
import React from "react";
import Row from "../custom/Row";

export type ReadonlyFormBoxProps = {
  label: string;
  value?: string;
  jsx?: JSX.Element;
  textRightElement?: JSX.Element;
  className?: string;
};

export default function ReadonlyFormBox({ label, value, jsx, className, textRightElement }: ReadonlyFormBoxProps) {
  return (
    <div className={`form-box center justify-between ${className ?? ""}`}>
      <BlackBodyText200>{label}</BlackBodyText200>
      {value && <Row className={"align-items-center"}>
        <BlackSubtitleText100 className={"wide-md ml-2 text-truncate"}>{value}</BlackSubtitleText100>
        {textRightElement && textRightElement}
      </Row>
      }
      {jsx && <div className="max-w-250px ">{jsx}</div>}
    </div>
  );
}
