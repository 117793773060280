import React from "react";
import Content from "../../../layout/content/Content";
import AddVehicleButton from "../../button/AddVehicleButton";
import VehicleCircleIcon from "../../icon/icons/VehicleCircleIcon";
import VehicleLocatorPlaceholderIcon from "../../icon/icons/VehicleLocatorPlaceholderIcon";
import {
  GreenCaptionText200,
  BlackSubtitleText100,
  GreenCaptionText100,
  BlackCaptionText100,
  BlackCaptionText200,
} from "../../text/Text";

type Props = {};

function LiveVehicleMapPlaceholder({}: Props) {
  return (
    <Content>
      <div className="d-flex align-items-center justify-content-center flex-column">
        <VehicleLocatorPlaceholderIcon />
        <BlackSubtitleText100 className="mt-2">No vehicles on the map yet!</BlackSubtitleText100>
        <BlackCaptionText200 className="mb-4">
          Connect your vehicles to our platform for location tracking. Once connected, they’ll appear right here!
        </BlackCaptionText200>
        <AddVehicleButton />
      </div>
    </Content>
  );
}

export default LiveVehicleMapPlaceholder;
