import { Auth } from "aws-amplify";
import axios from "axios";

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const user = await Auth.currentAuthenticatedUser();
    config.headers["Authorization"] = user?.signInUserSession?.idToken?.jwtToken;
    config.headers["Content-Type"] = config.headers["Content-Type"] ?? "application/json";
    return config;
  },
  (error) => {
    window.location.href = "/logout";
    Promise.reject(error);
  }
);


/**
 * axiosApiInstance.interceptors.response.use(
 *   async (config) => {
 *     return config;
 *   },
 *   (error) => {
 *     window.dispatchEvent(new CustomEvent("api-error", { detail: handleApiErrorResponse(error) }));
 *     Promise.reject(error);
 *   }
 * );
 */


export default axiosApiInstance;
