import React from "react";
import { SvgProps } from "types/svg.types";

type Props = {};

function WarningIcon({ width = 28, height = 25, fill = "#FE5D02" }: SvgProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_25845_45075" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="25">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8604 2.28613L1.73438 23.231H25.9863L13.8604 2.28613Z"
          fill="white"
          stroke="white"
          stroke-width="2.80369"
          stroke-linejoin="round"
        />
        <path
          d="M13.8594 18.8208V19.372M13.8594 10.002L13.8638 15.5138"
          stroke="black"
          stroke-width="2.80369"
          stroke-linecap="round"
        />
      </mask>
      <g mask="url(#mask0_25845_45075)">
        <path d="M-0.53125 -0.839844H29.0597V26.303H-0.53125V-0.839844Z" fill={fill} />
      </g>
    </svg>
  );
}

export default WarningIcon;
