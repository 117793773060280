import React from "react";
import { OverlineCherryText500 } from "../../text/Text";
import Row from "../../custom/Row";

const VehicleDetailsControlsContentVirtualKeyAlert = () => {
  return (
    <Row className="align-items-center">
      <div className={"warning-dot mr-1"} />
      <OverlineCherryText500>Virtual key must be added.</OverlineCherryText500>
    </Row>
  );
};

export default VehicleDetailsControlsContentVirtualKeyAlert;