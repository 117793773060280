import { useTranslation } from "react-i18next";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

import DashboardPageOverviewHeader from "../../components/dashboard-components/DashboardPageOverviewHeader";
import DashboardPageInsightsHeader from "../../components/dashboard-components/DashboardPageInsightsHeader";

import DashboardOverviewCards from "../../components/dashboard-components/DashboardOverviewCards";
import DashboardInsightCards from "../../components/dashboard-components/DashboardInsightCards";

import ChargingSessionsDataTable from "../../components/charging-sessions-components/ChargingSessionsDataTable";
import DashboardOnboarding from "../../components/dashboard-components/dashboard-onboarding/DashboardOnboarding";

export default function DashboardPage() {
  const { t } = useTranslation("common");
  return (
    <>
      <Head title={t("dashboard.title")}></Head>
      <Content>
        <DashboardPageOverviewHeader />
        <DashboardOnboarding />
        <DashboardOverviewCards />
        <DashboardPageInsightsHeader />
        <DashboardInsightCards />
        <div className="py-2 mt-2">
          <ChargingSessionsDataTable />
        </div>
      </Content>
    </>
  );
}
