import React from "react";
import LoadingSkeleton from "./LoadingSkeleton";

type Props = {};

const LoadingSkeletonForTable = (props: Props) => {
  return (
    <div className="w-100 p-2 d-flex align-items-center overflow-hidden">
      <LoadingSkeleton height={30} count={15} />
    </div>
  );
};

export default LoadingSkeletonForTable;
