import { ChevronLeft } from "@mui/icons-material";
import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import TextButton from "./TextButton";

type AuthFlowBackButtonProps = {
  readonly onAuthFlowBackButtonPressed?: () => void;
};

export default function AuthFlowBackButton({ onAuthFlowBackButtonPressed }: AuthFlowBackButtonProps) {
  const { pathname } = useLocation();

  const routesWhereTheButtonDoesNotVisible = [
    "/",
    "/assign-driver-completed",
    "/add-driver/completed",
    "/add-vehicle/completed",
  ];
  const routesNeedsControl = ["/add-driver", "edit-driver"];
  const doesCurrentRouteNeedControl = !!routesNeedsControl.find((r) => {
    return pathname.includes(r);
  });

  const doesNotCurrentRouteShowTheButton = !!routesWhereTheButtonDoesNotVisible.find((r) => {
    return pathname === r;
  });

  const history = useHistory();

  const handleBackClicked = useCallback(() => {
    if (onAuthFlowBackButtonPressed) {
      onAuthFlowBackButtonPressed();
      return;
    }
    history.goBack();
  }, [history, onAuthFlowBackButtonPressed]);

  if (doesNotCurrentRouteShowTheButton) {
    return <></>;
  }

  return (
    <TextButton
      className="hover-background-light p-1 mb-4"
      onClick={handleBackClicked}
      icon={<ChevronLeft className="w-20px h-25px" htmlColor="#1c2b46" />}
      text="Back"
    />
  );
}
