import { Color, Plugin } from "chart.js";
import { AnyObject } from "chart.js/dist/types/basic";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import React, { useMemo } from "react";

import { Doughnut } from "react-chartjs-2";
import DoughnutPlaceholder from "../../icon/icons/DoughnutPlaceholder";
import { AppColors } from "../../../utils/colors.utils";

type Props = {
  readonly publicSessionSize: number;
  readonly homeSessionSize: number;
};

export default function VehicleDetailsChargingInsightsLocationGraph({ publicSessionSize, homeSessionSize }: Props) {
  const areThereAnyData = publicSessionSize + homeSessionSize > 0;

  const processedGraphData = useMemo(() => {
    return {
      data: [homeSessionSize, publicSessionSize],
      labels: ["Home", "Public"],
      backgroundColors: [AppColors.blue100, AppColors.mint500],
    };
  }, [homeSessionSize, publicSessionSize]);

  const { data, labels, backgroundColors } = processedGraphData;

  const totalLocations = publicSessionSize + homeSessionSize;

  const doughnutData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: backgroundColors,
      },
    ],
  };

  return (
    <div className="d-flex w-100 h-100 overflow-auto justify-content-end  align-items-center position-relative">
      {areThereAnyData ? (
        <Doughnut
          height={140}
          width={200}
          //Type 'Plugin<keyof ChartTypeRegistry, AnyObject>' is not assignable to type 'Plugin<"doughnut", AnyObject>'
          plugins={[ChartDataLabels as Plugin<"doughnut", AnyObject>]}
          options={{
            responsive: false,
            maintainAspectRatio: true,
            cutout: "70%",

            layout: {
              padding: {
                top: 25,
                left: 25,
                right: 25,
                bottom: 25,
              },
            },
            plugins: {
              tooltip: {
                includeInvisible: true,
                mode: "nearest",
                axis: "r",
                boxPadding: 5,
                rtl: false,
                yAlign: "bottom",
              },
              datalabels: {
                font: {
                  size: 10,
                },

                color: "white",
                anchor: "end",
                align: "end",
                offset: 4,
                backgroundColor: function (context: Context): Color | null {
                  //@ts-ignore
                  const bgColors: string[] = context?.dataset?.backgroundColor;

                  if (typeof bgColors[context.dataIndex] === "string") {
                    return bgColors[context.dataIndex] as Color;
                  }

                  return "black" as Color;
                },
                borderRadius: 4,
                formatter: function (value, context) {
                  return ((value / totalLocations) * 100)?.toFixed(1) + "%";
                },
              },
              legend: {
                display: false,
              },
            },
          }}
          data={doughnutData}
        />
      ) : (
        <DoughnutPlaceholder />
      )}
    </div>
  );
}
