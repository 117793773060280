import React, { Dispatch, SetStateAction, SyntheticEvent, useCallback, useState, ChangeEvent, useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import BluedotFleetWordmark from "../../../assets/images/logo/BluedotFleetWordmark.png";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";

import { AuthForgotPasswordFormFlowSteps, AWSSuccessfulForgotPasswordSubmitResponse } from "../../../types/auth.types";
import { BlackBodyText100, BlackBodyText200, BlackH3Text100 } from "../../text/Text";
import AuthFormInput from "./AuthFormInput";
import SendCodeButton from "../../button/SendCodeButton";
import AlertBox from "../../notification/AlertBox";
import { handleApiErrorResponse } from "../../../utils";
import SaveNewPasswordButton from "../../button/SaveNewPasswordButton";
import AuthPasswordInputs, { PasswordFormValues, ValidatePasswordRef } from "./AuthPasswordInputs";
import Card from "../../card/Card";

import OutlinedResendCodeButton from "../../button/ResendCodeOutlinedButton";
import { useForgotPasswordEmailSubmitAsync } from "../../../hooks/auth.hooks";
import AuthResendEmailCard from "./AuthResendEmailCard";
import { useTranslation } from "react-i18next";
import { useAlertManager } from "hooks/alert.hooks";

type Props = {
  readonly setFormFlowStep: Dispatch<SetStateAction<AuthForgotPasswordFormFlowSteps>>;
  readonly emailAddressCodeSent: {
    encrypted: string;
    original: string;
  };
};
type Code = {
  readonly code: string;
};
interface FormValues extends PasswordFormValues, Code {}

function ForgotPasswordFormFlowPasswordStep({ setFormFlowStep, emailAddressCodeSent }: Props) {
  const { register, handleSubmit, errors, trigger, setValue, reset, formState, control, getValues } =
    useForm<FormValues>({
      mode: "all",
    });

  const { handleOpenErrorAlert } = useAlertManager();
  const validatePasswordImperativeRef = React.useRef<ValidatePasswordRef>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation("common");

  const { forgotPasswordEmailSubmitAsync, isLoading: isResendCodeLoading } = useForgotPasswordEmailSubmitAsync();

  /**
   *
   */
  const forgotPasswordCodeSubmitAsync = useCallback(
    async (values: FormValues) => {
      const isValidated = validatePasswordImperativeRef?.current?.validatePassword();

      if (!isValidated) {
        return;
      }

      try {
        setIsLoading(true);
        await Auth.forgotPasswordSubmit(emailAddressCodeSent.original, values.code, values.password);
        setFormFlowStep(AuthForgotPasswordFormFlowSteps.COMPLETE);
      } catch (error) {
        handleOpenErrorAlert(handleApiErrorResponse(error));
      } finally {
        setIsLoading(false);
      }
    },
    [emailAddressCodeSent.original, handleOpenErrorAlert, setFormFlowStep]
  );

  const handleResendCodeButtonPressed = useCallback(() => {
    forgotPasswordEmailSubmitAsync({ email: emailAddressCodeSent.original });
  }, [emailAddressCodeSent.original, forgotPasswordEmailSubmitAsync]);

  /**
   *
   */
  const handleFormSubmit = useCallback(
    async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
      e.preventDefault();

      await trigger();

      handleSubmit(forgotPasswordCodeSubmitAsync)(e);
    },
    [handleSubmit, forgotPasswordCodeSubmitAsync, trigger]
  );

  return (
    <form onSubmit={handleFormSubmit} autoComplete="false">
      <BlackH3Text100 className="mb-2">{t("auth.forgot_password.flow_steps.password.title")}</BlackH3Text100>
      <BlackBodyText200 className="mb-4">
        {t("auth.forgot_password.flow_steps.password.description", { email: emailAddressCodeSent?.original })}
      </BlackBodyText200>
      <AuthFormInput
        register={register}
        placeholder="Verification code"
        autocomplete="one-time-code"
        role="presentation"
        required
        name="code"
        className="w-max-100 w-100"
      />
      <AuthPasswordInputs
        isForgotPasswordFlow
        getFormValues={getValues}
        ref={validatePasswordImperativeRef}
        register={register}
      />
      <SaveNewPasswordButton isLoading={isLoading} isDisabled={isLoading} type="submit" className="w-100 mt-4" />
      <AuthResendEmailCard isLoading={isResendCodeLoading} onResendCodeButtonPressed={handleResendCodeButtonPressed} />
    </form>
  );
}

export default ForgotPasswordFormFlowPasswordStep;
