import LoadingSkeletonForPaginatedTable from "components/loading/LoadingSkeletonForPaginatedTable";
import React from "react";
import DataTable, { TableProps } from "react-data-table-component";
import NoDataPlaceholder from "./placeholder/NoDataPlaceholder";

type Props = TableProps<any> & {
  noDataPlaceholder?: React.JSX.Element;
  progressComponents?: React.JSX.Element;
};

function ClientPaginatedTable({ noDataPlaceholder, progressComponents, ...props }: Props) {
  return (
    <DataTable
      progressComponent={progressComponents ?? <LoadingSkeletonForPaginatedTable />}
      noDataComponent={
        noDataPlaceholder ?? <NoDataPlaceholder />
      }
      paginationRowsPerPageOptions={[20, 50]}
      paginationPerPage={20}
      pagination
      highlightOnHover
      responsive={true}
      className="rounded-xxl custom-table-border overflow-auto"
      style={{ borderRadius: 10 }}
      {...props}
    />
  );
}

export default ClientPaginatedTable;
