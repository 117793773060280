import React from "react";
import { Link } from "react-router-dom";
import Icon from "../icon/Icon";
import classNames from "classnames";

export const Block = ({ className, size, ...props }) => {
  const blockClass = classNames({
    "nk-block": true,
    [`nk-block-${size}`]: size,
    [`${className}`]: className,
  });
  return <div className={blockClass}>{props.children}</div>;
};
export const BlockContent = ({ className, ...props }) => {
  const blockContentClass = classNames({
    "nk-block-content": true,
    [`${className}`]: className,
  });
  return <div className={blockContentClass}>{props.children}</div>;
};

export const BlockBetween = ({ className, ...props }) => {
  return <div className={`nk-block-between ${className ? className : ""}`}>{props.children}</div>;
};
export const BlockHead = ({ className, size, wide, ...props }) => {
  const blockHeadClass = classNames({
    "nk-block-head": true,
    [`nk-block-head-${size}`]: size,
    [`wide-${wide}`]: wide,
    [`${className}`]: className,
  });
  return <div className={blockHeadClass}>{props.children}</div>;
};
export const BlockHeadContent = ({ className, ...props }) => {
  return <div className={[`nk-block-head-content${className ? " " + className : ""}`]}>{props.children}</div>;
};
export const BlockTitle = ({ className, page, ...props }) => {
  const classes = [`nk-block-title  page-title ${className ? " " + className : ""}`];
  return (
    <React.Fragment>
      {!props.tag ? (
        <h3 className={classes}>{props.children}</h3>
      ) : (
        <props.tag className={classes}>{props.children}</props.tag>
      )}
    </React.Fragment>
  );
};

export const BlockDes = ({ className, page, ...props }) => {
  const classes = [`fs-16px page_description   nk-block-des${className ? " " + className : ""}`];
  return <div className={classes}>{props.children}</div>;
};

export const BlockBadgeText = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal${className ? " " + className : ""}`];
  return <div className={classes}>{props.children}</div>;
};

export const BlockLabel = ({ className, page, ...props }) => {
  const classes = [`fs-14px font-weight-normal text-soft ${className ? " " + className : ""}`];
  return <span className={classes}>{props.children}</span>;
};

export const BlackCaptionText200 = ({ className, page, ...props }) => {
  const classes = [`fs-14px font-weight-normal text-black-200 ${className ? " " + className : ""}`];
  return <span className={classes}>{props.children}</span>;
};

export const BlackCaptionText300 = ({ className, page, ...props }) => {
  const classes = [`fs-14px font-weight-normal text-black-300 ${className ? " " + className : ""}`];
  return <span className={classes}>{props.children}</span>;
};

export const CherryBodyText100 = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-cherry-100 ${className ? " " + className : ""}`];
  return <span className={classes}>{props.children}</span>;
};

export const BlackBodyText100 = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-black-100 ${className ? " " + className : ""}`];
  return (
    <p title={props.children} className={classes}>
      {props.children}
    </p>
  );
};

export const BlackBodyText200 = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-black-200 ${className ? " " + className : ""}`];
  return <span className={classes}>{props.children}</span>;
};

export const BlackBodyText300 = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-black-300 ${className ? " " + className : ""}`];
  return <span className={classes}>{props.children}</span>;
};

export const GreenBodyText300 = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal text-green-200 ${className ? " " + className : ""}`];
  return <span className={classes}>{props.children}</span>;
};

export const LinkTextPrimary = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal link-text ${className ? " " + className : ""}`];
  return (
    <span onClick={props.onClick} className={classes}>
      {props.children}
    </span>
  );
};

export const BlackH5Text0 = ({ className, page, ...props }) => {
  const classes = [`fs-26px font-weight-700 text-black-0 ${className ? " " + className : ""}`];
  return <span className={classes}>{props.children}</span>;
};

export const BlackSubtitleText300 = ({ className, page, ...props }) => {
  const classes = [`fs-17px font-weight-500 text-black-300 ${className ? " " + className : ""}`];
  return <span className={classes}>{props.children}</span>;
};

export const BlackSubtitleText100 = ({ className, page, ...props }) => {
  const classes = [`fs-17px font-weight-500 text-black-100 ${className ? " " + className : ""}`];
  return <span className={classes}>{props.children}</span>;
};

export const GreenSubtitleText200 = ({ className, page, ...props }) => {
  const classes = [`fs-17px font-weight-500 text-green-200 ${className ? " " + className : ""}`];
  return <span className={classes}>{props.children}</span>;
};

export const GreenSubtitleText100 = ({ className, page, ...props }) => {
  const classes = [`fs-17px font-weight-500 text-green-100 ${className ? " " + className : ""}`];
  return <span className={classes}>{props.children}</span>;
};

export const BlockFormLabel = ({className,...props}) => {
  const classes = [`mb-1 ${className ? " " + className : ""}`];
  return <BlackBodyText100 {...props} className={classes} />
};

export const BlockFormLabelWithoutMargin = ({ className, page, ...props }) => {
  const classes = [`fs-16px  font-wight-normal text-dark ${className ? " " + className : ""}`];
  return <div className={classes}>{props.children}</div>;
};

export const BlockDesLight = ({ className, page, ...props }) => {
  const classes = [`fs-16px font-weight-normal   text-light-3  nk-block-des${className ? " " + className : ""}`];
  return <div className={classes}>{props.children}</div>;
};

export const BlockTitleSecondary = ({ className, page, ...props }) => {
  const classes = [`fs-22px m-0 text-dark font-weight-normal ${className ? " " + className : ""}`];
  return (
    <h6 className={classes} {...props}>
      {props.children}
    </h6>
  );
};

export const BlockTitleTertiary = ({ className, page, ...props }) => {
  const classes = [`fs-17px m-0 text-dark font-weight-normal ${className ? " " + className : ""}`];
  return (
    <h6 className={classes} {...props}>
      {props.children}
    </h6>
  );
};

export const BlockText = ({ className, page, ...props }) => {
  const classes = [`m-0 text-dark font-weight-normal ${className ? " " + className : ""}`];
  return (
    <h6 title={props.children} className={classes} {...props}>
      {props.children}
    </h6>
  );
};

export const BackTo = ({ className, link, icon, ...props }) => {
  const classes = [`back-to${className ? " " + className : ""}`];
  return (
    <div className="nk-block-head-sub">
      <Link className={classes} to={process.env.PUBLIC_URL + link}>
        <Icon name={icon} />
        <span>{props.children}</span>
      </Link>
    </div>
  );
};
