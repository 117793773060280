import Col from "components/custom/Col";
import Row from "components/custom/Row";
import PageHeader from "components/header/PageHeader";
import InboxMessages from "components/inbox/InboxMessages";

import InboxPageLearnMoreModal from "components/inbox/InboxPageLearnMoreModal";
import { BlackBodyText200, BlackH6Text0 } from "components/text/Text";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

type Props = {};

function InboxPage(props: Props) {
  return (
    <>
      <Head title={"Inbox"}></Head>
      <Content>
        <PageHeader
          title="Inbox"
          overrideDescription={
            <Row className="align-items-center mt-1">
              <BlackBodyText200>Stay updated with fleet alerts and messages.</BlackBodyText200>
              <InboxPageLearnMoreModal />
            </Row>
          }
        />
        <InboxMessages />
      </Content>
    </>
  );
}

export default InboxPage;
