// React
import { useCallback, useState } from "react";

// Utils & Hooks

import { useDispatch } from "react-redux";

// Store
import { startDriverNonFormStateAsManual } from "redux/slices/driverSlice";

// Components
import SelectionCard from "components/card/SelectionCard";
import BaseModal from "components/modal/BaseModal";
import { DriverFormType } from "types/driver.types";
import { getDriverIconByType } from "utils/driver.utils";
import DriverSelfSignupModalContent from "./DriverSelfSignupModalContent";

type Props = {
  type: DriverFormType;
};
export default function DriverFormTypeSelectionCard({ type }: Props) {
  const dispatch = useDispatch();
  const [isSelfSignupModalOpen, setIsSelfSignupModalOpen] = useState(false);

  const isDriverFormTypeManual = type === DriverFormType.MANUAL;
  const isDriverFormTypeSelfSignup = type === DriverFormType.SELF_SIGNUP;

  const title = isDriverFormTypeManual ? "Add driver manually" : "Enable self-signup";
  const description = isDriverFormTypeManual
    ? "Enter driver details to add them directly. They'll receive a temporary password for their account."
    : "Share the fleet code with drivers. They can sign up themselves, awaiting your approval.";

  /**
   *
   */
  const handleDriverFormTypeSelectionCardClicked = useCallback(() => {
    if (isDriverFormTypeManual) {
      dispatch(startDriverNonFormStateAsManual());
      return;
    }
    setIsSelfSignupModalOpen(true);
  }, [dispatch, isDriverFormTypeManual]);

  return (
    <>
      {isDriverFormTypeSelfSignup && (
        <BaseModal className="wide-sm" setIsModalOpen={setIsSelfSignupModalOpen} isModalOpen={isSelfSignupModalOpen}>
          <DriverSelfSignupModalContent setIsSelfSignupModalOpen={setIsSelfSignupModalOpen} />
        </BaseModal>
      )}
      <SelectionCard
        description={description}
        icon={getDriverIconByType(isDriverFormTypeManual)}
        onClick={handleDriverFormTypeSelectionCardClicked}
        title={title}
      />
    </>
  );
}
