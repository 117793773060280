import { useCallback, useMemo, useState } from "react";
import { getInvoiceAsync } from "services/invoice.services";
import { ChargingInvoiceLineItem, Invoice, InvoicePaymentState, InvoiceType, PlatformInvoiceLineItem } from "types/invoice.types";
import { getInvoiceDetailsExportDataByType } from "utils/invoice.utils";
import { useAppDispatch } from "./redux.hooks";
import { useAlertManager } from "./alert.hooks";
import { handleApiErrorResponse } from "utils";

export const useInvoiceDetailsByType = (invoice?: Invoice) => {
    const isChargingInvoice = invoice?.invoiceType === InvoiceType.CHARGING;
    const lineItems = isChargingInvoice ? invoice?.lineItems as ChargingInvoiceLineItem[] : invoice?.lineItems as PlatformInvoiceLineItem[]
    const invoicePaymentState = invoice?.paymentState as InvoicePaymentState;
    const isPaid = invoicePaymentState === InvoicePaymentState.PAID;

    const handleForwardToStripe = () => {
        const forwardingUrl = invoice?.url;

        if (forwardingUrl) {
            window.open(forwardingUrl, "_blank", "noreferrer");
        }
    };


    // Expensive calculation! 
    const exportData = useMemo(() => {
        return getInvoiceDetailsExportDataByType(lineItems, isChargingInvoice) ?? []
    }, [isChargingInvoice, lineItems]);


    const percent = invoice?.grossAmount ? (invoice?.discountAmount * 100) / invoice?.grossAmount : 0;
    const percentFixed = percent?.toFixed(1);
    const title = isChargingInvoice ? "Charging fee invoice" : 'Platform fee invoice'
    const description = isChargingInvoice ? ' Review your billed vehicles or drivers' : ' Review your billed vehicles or drivers'

    return { isPaid, percentFixed, percent, exportData, handleForwardToStripe, title, description, invoicePaymentState, isChargingInvoice }

}


export const useStripeInvoiceUrlAccessor = () => {
    const { handleOpenErrorAlert } = useAlertManager()
    const [isForwarding, setIsForwarding] = useState(false)
    const handleForwardToStripeAsync = useCallback(async (invoiceId?: string) => {
        if (!invoiceId) {
            return
        }
        try {
            setIsForwarding(true);
            const forwardingUrl = (await getInvoiceAsync(invoiceId)).data.url;
            if (forwardingUrl) {
                window.open(forwardingUrl, "_blank", "noreferrer");
            }
        } catch (error: any) {
            console.error(error);
            handleOpenErrorAlert(handleApiErrorResponse(error))
        } finally {
            setIsForwarding(false);
        }
    }, [handleOpenErrorAlert]);

    return { isForwarding, handleForwardToStripeAsync }
}