import { SvgProps } from "types/svg.types";

type Props = {};

function SettingsPreferencesIconOutlined(props: SvgProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.41894 5.71494H13.7106C15.9362 5.71494 17.7481 7.52721 17.7481 9.75244C17.7481 11.9784 15.9363 13.7899 13.7106 13.7899H6.41894C4.19331 13.7899 2.38145 11.9784 2.38145 9.75244C2.38145 7.52722 4.19338 5.71494 6.41894 5.71494Z"
        stroke="#6F7981" strokeWidth="1.3" />
      <ellipse cx="6.56614" cy="9.75266" rx="2.83274" ry="2.83274" fill="#6F7981" />
    </svg>
  );
}

export default SettingsPreferencesIconOutlined;
