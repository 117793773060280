import FormButton from "./FormButton";

type Props = {
  readonly onSendCodeButtonPressed?: () => void;
  readonly className?: string;
  readonly type?: string;
  readonly isLoading?: boolean;
  readonly isDisabled?: boolean;
};

function SendCodeButton({ onSendCodeButtonPressed, ...props }: Props) {
  return <FormButton text={"Send code"} onFormButtonPressed={onSendCodeButtonPressed} {...props} />;
}

export default SendCodeButton;
