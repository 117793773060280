import React from "react";
import { BlockDes, Row } from "../Component";

export default function CounterCardWithIcon({ icon, count, description }) {
  return (
    <Row className="bg-white rounded-xxl py-1 px-2 d-inline-flex align-center m-0 h-42px">
      {icon && <div className="mr-2 center">{icon}</div>}

      <BlockDes className="text-soft">
        {count} {description}
      </BlockDes>
    </Row>
  );
}
