import TextButton, { TextButtonMode, TextButtonProps } from "./TextButton";
import { useMemo } from "react";

function HoverUnderlinedTextButton({ mode, ...props }: TextButtonProps) {

  const textButtonClassName = useMemo(() => {
    switch (mode) {
      case TextButtonMode.NEGATIVE:
        return "btn-negative-text";

      case TextButtonMode.PRIMARY:
        return "btn-primary-text";

      case TextButtonMode.BLUE:
        return "btn-blue-text";

      default:
        return "btn-blue-text";
    }

  }, [mode]);


  return <TextButton {...props}
                     className={`${textButtonClassName} hover-text-decoration-underline`}
                     textClassName={`clickable  ${props.textClassName ?? ""}`} />;
}

export default HoverUnderlinedTextButton;
