import React from "react";
import ButtonWithIcon from "./ButtonWithIcon";

type Props = {
  readonly onTryAgainButtonPressed: () => void;
};

function TryAgainButton({ onTryAgainButtonPressed }: Props) {
  return <ButtonWithIcon className="w-175px mt-md-0 ml-2" text={"Try Again"} onClick={onTryAgainButtonPressed} />;
}

export default TryAgainButton;
