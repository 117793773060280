import React from "react";
import { Link } from "react-router-dom";
import HoverUnderlinedTextButton from "./HoverUnderlinedTextButton";

type Props = {};

const SignInTextLink = (props: Props) => {
  return (
    <Link to={`${process.env.PUBLIC_URL}/auth/login`} type="button">
      <HoverUnderlinedTextButton textClassName={'fs-16px'} text={'Sign in'}/>
    </Link>
  );
};

export default SignInTextLink;
