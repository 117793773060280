import React from "react";
import Skeleton from "react-loading-skeleton";
import Content from "../../layout/content/Content";

type Props = {
  count: number;
  height?: number;
  className?: string;
};
export default function LoadingSkeleton({ count, height,className }: Props) {
  return <Skeleton enableAnimation containerClassName={`w-100 ${className??''}`} height={height ?? 20} count={count} />;
}
