import { Skeleton } from "@mui/material";
import { Chart, Title } from "chart.js";
import { useRef } from "react";
import { Bar } from "react-chartjs-2";
import { getMonthNameFromYearWeekFormat } from "utils/date.utils";
import { formatNumber } from "../../utils/charging.utils";
import GraphPlaceholder from "../dashboard-components/dashboard-placeholders/GraphPlaceholder";

Chart.register(Title);

const ReimbursementMonthlyGraph = ({ data, labels, isLoading, uniquePeriods }) => {
  const chartRef = useRef();
  const containerRef = useRef();

  const chartData = {
    labels: labels,
    datasets: data
  };

  function customLegendOnClick(event, legendItem, legend) {
    const chart = legend.chart;
    const visibleDatasets = chart.data.datasets.filter((dataset) => !dataset.hidden).length;
    const clickedDatasetIndex = legendItem.datasetIndex;
    const clickedDatasetHidden = chart.data.datasets[clickedDatasetIndex].hidden;

    // If there are more than one visible datasets or the clicked dataset is hidden, toggle the clicked dataset
    if (visibleDatasets > 1 || clickedDatasetHidden) {
      chart.data.datasets[clickedDatasetIndex].hidden = !clickedDatasetHidden;
      chart.update();
    }

    // If there is only one visible dataset and the clicked dataset is not hidden,
    // find the first hidden dataset and show it, then hide the clicked dataset
    if (visibleDatasets === 1 && !clickedDatasetHidden) {
      const firstHiddenDatasetIndex = chart.data.datasets.findIndex((dataset) => dataset.hidden);

      if (firstHiddenDatasetIndex !== -1) {
        chart.data.datasets[firstHiddenDatasetIndex].hidden = false;
        chart.data.datasets[clickedDatasetIndex].hidden = true;
        chart.update();
      }
    }
  }

  const yScaleText = {
    id: "yScaleText",
    afterDraw(chart, args, options) {
      try {
        const {
          ctx,
          chartArea: { top }
        } = chart;
        ctx.save();
        ctx.fillStyle = "#A0A8AF";
        ctx.fillText("Amount", 0, top - 20);
        ctx.restore();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const options = {
    type: "bar",
    responsive: true,
    maintainAspectRatio: false, // Add this line
    scales: {
      x: {
        beginAtZero: false,

        stacked: true,
        grid: {
          display: true,
          drawTicks: false,

          //  lineWidth: 0.5,
          drawOnChartArea: false,
          borderDash: [5, 10]
        },
        ticks: {
          color: "#A0A8AF",
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          padding: 10,

          font: {
            size: (ctx) => (ctx.chart.width < 480 ? 6 : 12) // Add this line
          },

        }
      },
      y: {
        grid: {
          drawTicks: false
        },
        border: {
          dash: [5, 10]
        },
        stacked: true,
        ticks: {
          color: "#A0A8AF",
          align: "center",
          crossAlign: "far",
          textAlign: "left", // Etiketlerin sola yaslanmasını sağlar
          callback: function(value, index, values) {
            return "$" + formatNumber(Number(value));
          }
        }
      }
    },

    plugins: {
      grid: {
        borderWidth: 0
      },
      legend: {
        display: true,
        position: "top",
        align: "end",
        labels: {
          usePointStyle: true,
          boxWidth: 12, // Add this line
          boxHeight: 12, // Add this line
          boxBorderRadius: 6, // Add this line
          font: {
            size: (ctx) => (ctx.chart.width < 480 ? 6 : 12) // Add this line
          }
        },
        onClick: customLegendOnClick
      },

      tooltip: {
        yAlign: "bottom",
        usePointStyle: true,
        callbacks: {
          label: function(context) {
            const { dataset, dataIndex } = context;

            const data = dataset?.data[dataIndex];
            const formattedValue = formatNumber(Number(data));

            return `${dataset.label}: $${formattedValue}`;
          },
          title: function(context) {
            const { label } = context[0];

            console.log("context", context[0]);
            console.log("label", label);
            return label;
          }
        }
      }
    }
  };

  if (isLoading) {
    return <Skeleton variant="rectangular" height={"100%"} />;
  }

  if (data.length === 0) {
    return (
      <div className="flex flex-fill  center">
        <GraphPlaceholder />
      </div>
    );
  }


  return (
    <div id="app-cost-bar" className="w-100 h-100  w-max-100 h-max-100" style={{ width: "99%" }}>
      <Bar style={{ paddingBottom: 10 }} ref={chartRef}
           data={chartData}
        //@ts-ignore
           options={options}
           plugins={[yScaleText]} />
    </div>
  );
};

export default ReimbursementMonthlyGraph;
