import React from "react";
import { SvgProps } from "../../../types/svg.types";

type Props = {};

function PencilIcon(props: SvgProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 15.5001H3.625L12.8417 6.28342L9.71667 3.15842L0.5 12.3751V15.5001ZM2.16667 13.0668L9.71667 5.51675L10.4833 6.28342L2.93333 13.8334H2.16667V13.0668ZM13.3083 0.74175C13.2312 0.664497 13.1397 0.603208 13.0389 0.56139C12.938 0.519572 12.83 0.498047 12.7208 0.498047C12.6117 0.498047 12.5036 0.519572 12.4028 0.56139C12.302 0.603208 12.2104 0.664497 12.1333 0.74175L10.6083 2.26675L13.7333 5.39175L15.2583 3.86675C15.3356 3.78966 15.3969 3.69808 15.4387 3.59727C15.4805 3.49646 15.502 3.38839 15.502 3.27925C15.502 3.17011 15.4805 3.06204 15.4387 2.96123C15.3969 2.86042 15.3356 2.76885 15.2583 2.69175L13.3083 0.74175Z"
        fill={props.fill ?? "#0C0C0C"}
      />
    </svg>
  );
}

export default PencilIcon;
