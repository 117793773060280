import { BlackBodyText100, BlackCaptionText200, BlackH5Text100 } from "components/text/Text";
import React from "react";
import { useTranslation } from "react-i18next";
import AddVehicleButton from "../button/AddVehicleButton";

import { BlockTitle, BlockDes } from "../Component";
import Col from "../custom/Col";
import Row from "../custom/Row";

export default function DashboardPageOverviewHeader() {
  const { t } = useTranslation("common");
  const title = t("dashboard.page_header");
  const description = t("dashboard.page_description");

  return (
    <Row className="justify-between align-items-center">
      <Col className="my-2">
        <BlackH5Text100 page tag="h5">
          {title}
        </BlackH5Text100>
        <BlackBodyText100 className="page_description mt-1">{description}</BlackBodyText100>
      </Col>

      <Row className="align-items-center mb-3 mb-md-0">
        {/**
         *  <div className="mr-3">
          <OutlinedAddDriverButton />
        </div>
         */}
        <AddVehicleButton />
      </Row>
    </Row>
  );
}
