import React, { Dispatch, SetStateAction, SyntheticEvent, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { AuthForgotPasswordFormFlowSteps, AWSSuccessfulForgotPasswordSubmitResponse } from "../../../types/auth.types";
import { BlackBodyText200, BlackH3Text100 } from "../../text/Text";
import AuthFormInput from "./AuthFormInput";
import SendCodeButton from "../../button/SendCodeButton";
import AlertBox from "../../notification/AlertBox";
import { useForgotPasswordEmailSubmitAsync } from "../../../hooks/auth.hooks";
import { useTranslation } from "react-i18next";

type Props = {
  readonly setFormFlowStep: Dispatch<SetStateAction<AuthForgotPasswordFormFlowSteps>>;
  setEmailAddressCodeSent: React.Dispatch<
    React.SetStateAction<{
      encrypted: string;
      original: string;
    }>
  >;
};

export type EmailFormValues = {
  readonly email: string;
};

function ForgotPasswordFormFlowEmailStep({ setFormFlowStep, setEmailAddressCodeSent }: Props) {
  const { register, handleSubmit, errors, trigger, setValue, reset, formState, control, getValues } =
    useForm<EmailFormValues>({
      mode: "onChange",
    });

  const { isValid } = formState;

  const { t } = useTranslation("common");

  const { forgotPasswordEmailSubmitAsync, isLoading } = useForgotPasswordEmailSubmitAsync();

  /**
   *
   */
  const forgotPasswordEmailSubmitAsyncWithStateCallback = useCallback(
    async (values: EmailFormValues) => {
      const response: AWSSuccessfulForgotPasswordSubmitResponse | undefined = await forgotPasswordEmailSubmitAsync(
        values
      );

      if (!response) {
        return;
      }

      setEmailAddressCodeSent({ encrypted: response?.CodeDeliveryDetails?.Destination, original: values.email });
      setFormFlowStep(AuthForgotPasswordFormFlowSteps.PASSWORD);
    },
    [forgotPasswordEmailSubmitAsync, setEmailAddressCodeSent, setFormFlowStep]
  );

  /**
   *
   */
  const handleFormSubmit = useCallback(
    async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
      e.preventDefault();

      await trigger();

      handleSubmit(forgotPasswordEmailSubmitAsyncWithStateCallback)(e);
    },
    [handleSubmit, forgotPasswordEmailSubmitAsyncWithStateCallback, trigger]
  );

  return (
    <form onSubmit={handleFormSubmit}>
      <BlackH3Text100 className="mb-2">{t("auth.forgot_password.flow_steps.email.title")}</BlackH3Text100>
      <BlackBodyText200 className="mb-4">{t("auth.forgot_password.flow_steps.email.description")}</BlackBodyText200>
      <AuthFormInput
        aria-label="email"
        register={register}
        inputMode="email"
        placeholder="Enter your email"
        label="Email"
        required
        name="email"
        type="email"
        className="w-max-100 w-100 "
      />
      <SendCodeButton isLoading={isLoading} isDisabled={isLoading} type="submit" className="w-100 mt-4" />
    </form>
  );
}

export default ForgotPasswordFormFlowEmailStep;
