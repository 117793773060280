import { useCallback, useEffect } from "react";
import { useUrlQuery } from "./router.hooks";
import { Query, SortDirection } from "types/filter.types";

export const useFilterUrlQuery = <T extends Record<string, unknown>>() => {
  const { query, setQuery, resetQuery } = useUrlQuery<Query<T>>();

  const handleSetQuery = useCallback(
    (callback: (currentQuery: Query<T>) => T) => {
      setQuery(q => {
        const updatedFilters = callback(q);
        return {
          ...q,
          pagination: undefined,
          filters: updatedFilters ? { ...q.filters, ...updatedFilters } : undefined
        };
      });
    },
    [setQuery]
  );

  const handleSetQueryWithoutResettingPagination = useCallback(
    (callback: (currentQuery: Query<T>) => T) => {
      setQuery(q => {
        const updatedFilters = callback(q);
        return {
          ...q,
          filters: updatedFilters ? { ...q.filters, ...updatedFilters } : undefined
        };
      });
    },
    [setQuery]
  );

  return {
    setFilterQuery: handleSetQuery,
    filterQuery: query.filters,
    resetQuery,
    handleSetQueryWithoutResettingPagination
  };
};

export const useSortingUrlQuery = <T extends Record<string, unknown>>() => {
  const { query, setQuery, resetQuery } = useUrlQuery<Query<T>>();
  const handleSetQuery = useCallback(
    (key: string, direction: SortDirection) => {
      setQuery(q => {
        return {
          ...q,
          pagination: undefined,
          sort: {
            [key]: direction
          }
        };
      });
    },
    [setQuery]
  );

  return { setSortingQuery: handleSetQuery, sortingQuery: query.sort, resetQuery };
};


export const useSortFilterOptionsBySelectedItems = <T>(setter: (value: React.SetStateAction<T[]>) => void, checker: (item: T) => boolean) => {
  useEffect(() => {
    setter((options) =>
      options.sort((a, b) => {
        // Assuming isSelectedVehicle is a function that takes a vehicle and returns a boolean
        const isSelectedA = checker(a);
        const isSelectedB = checker(b);

        // Sort in descending order: true first, false last
        return Number(isSelectedB) - Number(isSelectedA);
      })
    );
  }, [checker, setter]);
};