import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { TextButtonMode } from "./TextButton";
import HoverUnderlinedTextButton from "./HoverUnderlinedTextButton";

type Props = {
  readonly vehicleId: string;
};

function AssignDriverLinkButton({ vehicleId }: Props) {
  /**
   * Hooks
   */
  const history = useHistory();

  /**
   * Handlers
   */
  const handleAssignDriverButtonClicked = useCallback(() => {
    history.push(`/assign-driver/${vehicleId}`);
  }, [history, vehicleId]);

  return (
    <HoverUnderlinedTextButton
      mode={TextButtonMode.BLUE}
      text={"Assign Driver"}
      onClick={handleAssignDriverButtonClicked}
    />
  );
}

export default AssignDriverLinkButton;
