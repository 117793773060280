import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { Icon } from "../Component";
import ButtonWithIcon from "./ButtonWithIcon";

export default function DiscardChangesButton({ onDiscardChangesButtonClicked, isDisabled, widthClass }) {
  const widthClass_ = widthClass ?? "";

  /**
   * Render
   */

  return (
    <ButtonWithIcon
      isDisabled={isDisabled}
      className={`mt-md-0 ${widthClass_}`}
      text={"Discard Changes"}
      onClick={onDiscardChangesButtonClicked}
    />
  );
}
