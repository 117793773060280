import CancelButton from "components/button/CancelButton";
import PrimaryButton from "components/button/PrimaryButton";
import LockClosedFilledIcon from "components/icon/icons/LockClosedFilledIcon";
import UnlockFilledIcon from "components/icon/icons/UnlockFilledIcon";
import AlertConfirmDialog from "components/notification/AlertConfirmDialog";
import { useAlertManager } from "hooks/alert.hooks";
import { useCallback, useState } from "react";
import { useParams } from "react-router";
import { updateVehicleTeslaAsync } from "services/vehicle.services";
import { TeslaCommand, TeslaDataAccessorHook } from "types/vehicle.types";
import { handleApiErrorResponse } from "utils";
import VehicleDetailsPageCardBlock from "./VehicleDetailsPageCardBlock";
import VehicleDetailsVirtualKeyControlledButton from "./VehicleDetailsVirtualKeyControlledButton";

type Props = {
  teslaHook: TeslaDataAccessorHook;
  isReadonly: boolean
};

function VehicleDetailsLockStatusEditor({ teslaHook, isReadonly }: Props) {
  const { isTeslaInfoLoading, teslaInfo, refetchTeslaInfo } = teslaHook;


  const isLocked = teslaInfo?.locked;

  const { vehicleId } = useParams<{ vehicleId: string }>();

  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleToggleDriverStatusAsync = useCallback(async () => {
    try {
      setIsSubmitting(true);

      await updateVehicleTeslaAsync(vehicleId, {
        command: isLocked ? TeslaCommand.UNLOCK : TeslaCommand.LOCK
      });

      setIsConfirmDialogOpen(false);
      handleOpenSuccessAlert(isLocked ? "Vehicle unlocked successfully." : "Vehicle locked successfully.");
      refetchTeslaInfo();
    } catch (error) {
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  }, [handleOpenErrorAlert, handleOpenSuccessAlert, isLocked, refetchTeslaInfo, vehicleId]);

  return (
    <>
      <AlertConfirmDialog
        isDialogOpen={isConfirmDialogOpen}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setIsConfirmDialogOpen(false)} />
            </div>

            <PrimaryButton
              leftIcon={isLocked ? <UnlockFilledIcon className="mr-1" /> : <LockClosedFilledIcon className="mr-1" />}
              type="button"
              text={isLocked ? "Unlock" : "Lock"}
              isLoading={isSubmitting}
              onClick={handleToggleDriverStatusAsync}
            />
          </>
        }
        toggleIsDialogOpen={() => setIsConfirmDialogOpen(false)}
        title={`Are you sure you want to ${isLocked ? "Unlock" : "Lock"} this vehicle?`}
        description={
          isLocked
            ? "When you unlock the vehicle, it will be accessible from the outside again."
            : "When you lock the vehicle, it will be blocked from outside access."
        }
      />

      <VehicleDetailsPageCardBlock
        isBorderBottomVisible={false}
        columnClass="h-28px"
        isLoading={isTeslaInfoLoading}
        leftLabel="Door status"
        leftText={isLocked ? "Locked" : "Unlocked"}
        rightTextOverwrite={
          <VehicleDetailsVirtualKeyControlledButton
            isVirtualKeyNeeded={isReadonly}
            leftIcon={isLocked ? <UnlockFilledIcon className="mr-1" /> : <LockClosedFilledIcon className="mr-1" />}
            type="button"
            text={isLocked ? "Unlock" : "Lock"}
            isLoading={isSubmitting}
            onClick={() => setIsConfirmDialogOpen(true)}
          />
        }
      />
    </>
  );
}

export default VehicleDetailsLockStatusEditor;
