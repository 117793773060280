import React, { useCallback, useState } from "react";
import DownloadInvoiceIcon from "../../icon/icons/DownloadInvoiceIcon";
import { getTeslaChargingInvoiceAsync } from "../../../services/vehicle.services";
import { useAlertManager } from "../../../hooks/alert.hooks";
import { handleApiErrorResponse } from "../../../utils";

type Props = {
  id: string
  fullName: string
}

const TeslaDownloadSingleInvoiceButton = ({ id, fullName }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleOpenErrorAlert } = useAlertManager();

  const handleDownloadInvoice = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getTeslaChargingInvoiceAsync(id);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fullName); // Specify the file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      handleOpenErrorAlert(handleApiErrorResponse(e));
    } finally {
      setIsLoading(false);
    }
  }, [fullName, handleOpenErrorAlert, id]);

  return (
    <DownloadInvoiceIcon className={isLoading ? "cursor-progress disabled" : ""} onClick={handleDownloadInvoice} />
  );
};

export default TeslaDownloadSingleInvoiceButton;