import { useAuthServicesAsync } from "hooks/auth.hooks";
import React, { useEffect } from "react";

type Props = {};

function LogoutPage({}: Props) {
  const { logoutAsync } = useAuthServicesAsync();

  useEffect(() => {
    logoutAsync();
  }, [logoutAsync]);

  return <></>;
}

export default LogoutPage;
