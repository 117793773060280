import FormButton from "./FormButton";

type Props = {
  readonly onSignInButtonPressed?: () => void;
  readonly className?: string;
  readonly type?: string;
  readonly isLoading?: boolean;
  readonly isDisabled?: boolean;
};

function SignInButton({ onSignInButtonPressed, ...props }: Props) {
  return <FormButton text={"Sign in"} onFormButtonPressed={onSignInButtonPressed} {...props} />;
}

export default SignInButton;
