import TextButton from "components/button/TextButton";
import { BlackLinkText200 } from "components/text/Text";
import React from "react";
import TertiaryButton from "../../components/button/TertiaryButton";

type Props = {
  text: string;
  onClick: () => void;
};

function HeaderLink({ onClick, text }: Props) {
  return (
    <TertiaryButton text={text} onClick={onClick} className="" />
  );
}

export default HeaderLink;
