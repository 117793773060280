// React
import { useCallback, useState } from "react";
// Third Party
// Services && Hooks && Utils
import { useSmartCar } from "hooks/vehicle.hooks";

// Types
// Components
import Permissions from "assets/images/vehicle/Permissions.png";
import SmartcarSignIn from "assets/images/vehicle/SmartcarSignIn.png";
import SmartcarBrandLogos from "assets/images/vehicle/SmartcarBrandLogos.png";
import CircleBadge from "components/badge/CircleBadge";
import NextButton from "components/button/NextButton";
import Card from "components/card/Card";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import { BlackBodyText200, BlackCaptionText300, BlackSubtitleText0 } from "components/text/Text";
import Skeleton from "react-loading-skeleton";
import AddVehicleFormStarterNeedHelpButtonAndModal from "./AddVehicleFlowSelectionNeedHelpButtonAndModal";

type Props = {};

const componentContext = "AddVehicleFormStarter";

export default function AddVehicleSmartCarFormStarter(props: Props) {
  const [loadedImages, setLoadedImages] = useState<string[]>([]);

  const { handleSmartCarOpenDialog } = useSmartCar();

  const handleLoadImage = useCallback(
    (loadedImage) => {
      if (loadedImages.includes(loadedImage)) {
        return;
      }
      setLoadedImages((li) => [...li, loadedImage]);
    },
    [loadedImages]
  );

  const areAllImagesLoaded = loadedImages.length === 3;

  const renderImage = useCallback(
    (img: JSX.Element) => {
      return (
        <div className="center h-175px w-100">
          <>
            <div className={`${areAllImagesLoaded ? "d-block" : "d-none"}`}>{img}</div>

            {!areAllImagesLoaded && <Skeleton containerClassName="w-80" count={5} />}
          </>
        </div>
      );
    },
    [areAllImagesLoaded]
  );

  return (
    <div className="w-100 center ">
      <Card padding="p-4" className="wide-md-fix mt-2">
        <Row className="mb-3">
          <Col md="4" className="center flex-column h-200px justify-between">
            {renderImage(
              <img
                className="w-200px"
                src={SmartcarBrandLogos}
                alt="SmartcarBrandLogos"
                onLoad={() => handleLoadImage("SmartcarBrandLogos")}
              />
            )}

            <Row className="w-100 center">
              <CircleBadge className="h-20px w-20px bg-blue-100 text-white mr-2">1</CircleBadge>
              <BlackSubtitleText0>Select Vehicle</BlackSubtitleText0>
            </Row>
          </Col>
          <Col md="4" className="center flex-column  mt-4 mt-md-0  h-200px justify-between">
            {renderImage(
              <img
                className="w-175px"
                src={SmartcarSignIn}
                alt="SmartcarSignIn"
                onLoad={() => handleLoadImage("SmartcarSignIn")}
              />
            )}

            <Row className="w-100 center">
              <CircleBadge className="h-20px w-20px bg-blue-100 text-white mr-2">2</CircleBadge>
              <BlackSubtitleText0>Sign in</BlackSubtitleText0>
            </Row>
          </Col>
          <Col md="4" className="center flex-column h-200px mt-3 mt-md-0 justify-between">
            {renderImage(
              <img
                className="w-175px"
                src={Permissions}
                alt="Permissions"
                onLoad={() => handleLoadImage("Permissions")}
              />
            )}

            <Row className="w-100 center">
              <CircleBadge className="h-20px w-20px bg-blue-100 text-white mr-2">3</CircleBadge>
              <BlackSubtitleText0>Grant Permissions</BlackSubtitleText0>
            </Row>
          </Col>
        </Row>

        <BlackBodyText200 className={"mt-3"}>
          By connecting your EV, you'll effortlessly track charging sessions, keep an eye on battery levels, and monitor
          its mileage. Let's get started!
        </BlackBodyText200>

        <Row className="mt-4 center">
          <Col md="6" className="d-flex justify-content-start ">
            <AddVehicleFormStarterNeedHelpButtonAndModal />
          </Col>
          <Col md="6">
            <Row className="center">
              <Col className="mr-3">
                <BlackCaptionText300 className="text-right">Next: Select your vehicle</BlackCaptionText300>
              </Col>

              <NextButton onNextButtonPressed={handleSmartCarOpenDialog} />
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
