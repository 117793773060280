import { useCallback, useEffect, useState } from "react";
import { PaginationMeta } from "../../types/api.types";
import { useLocation } from "react-router";
import {
  getMonthlyReimbursementOverviewAsync,
  getMonthlyReimbursementStatisticsAsync,
  getReimbursementListAsync
} from "../../services/reimbursement.service";
import { Reimbursement, ReimbursementOverview } from "types/reimbursement.types";


export const useReimbursementListAsync = () => {
  const [reimbursements, setReimbursements] = useState<Array<Reimbursement>>([]);
  const [meta, setMeta] = useState<PaginationMeta | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const { search } = useLocation();


  const getReimbursementListAsyncCallback = useCallback(
    async (queryString: string) => {
      try {
        setIsLoading(true);
        const res = await getReimbursementListAsync(queryString);
        setReimbursements(res.data);
        setMeta(res.meta);

      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );


  useEffect(() => {
    getReimbursementListAsyncCallback(search);
  }, [getReimbursementListAsyncCallback, search]);


  return { reimbursements, meta, isLoading, refetch: () => getReimbursementListAsyncCallback(search), setIsLoading };
};


export const useReimbursementStatisticsByPeriodAsync = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [statistics, setStatistics] = useState<any[]>([]);

  const getReimbursementStatisticsByPeriodAsyncCallback = useCallback(
    async () => {
      try {
        setIsLoading(true);

        const res = await getMonthlyReimbursementStatisticsAsync();
        const statistics = res.data;

        if (!statistics) {
          throw Error("Statistics is undefined");
        }

        setStatistics(statistics);

      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );


  useEffect(() => {
    getReimbursementStatisticsByPeriodAsyncCallback();
  }, [getReimbursementStatisticsByPeriodAsyncCallback]);


  return { statistics, isLoading, refetch: getReimbursementStatisticsByPeriodAsyncCallback };
};


export const useReimbursementOverviewAsync = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [overview, setOverview] = useState<ReimbursementOverview | undefined>(undefined);

  const getReimbursementOverviewAsyncCallback = useCallback(
    async () => {
      try {
        setIsLoading(true);

        const res = await getMonthlyReimbursementOverviewAsync();
        const overview = res.data;

        if (!overview) {
          throw Error("Statistics is undefined");
        }

        setOverview(overview);

      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );


  useEffect(() => {
    getReimbursementOverviewAsyncCallback();
  }, [getReimbursementOverviewAsyncCallback]);


  return { overview, isLoading, refetch: getReimbursementOverviewAsyncCallback };
};

