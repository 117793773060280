import { useVehicleChargingSummaryAsync } from "../../../hooks/data/vehicle-data-accessor.hooks";
import { getFormattedNumberForEnergy } from "../../../utils/charging.utils";
import { getFormattedNumberForUsdByCent } from "../../../utils/format.utils";
import Card from "../../card/Card";
import Col from "../../custom/Col";
import Row from "../../custom/Row";
import ChargingStationIcon from "../../icon/icons/ChargingStationIcon";
import HomeIcon from "../../icon/icons/HomeIcon";
import { BlackBodyText300, BlackH5Text0 } from "../../text/Text";
import InsightCard from "./VehicleDetailsChargingInsightsCard";
import VehicleDetailsAllLocationInsight from "./VehicleDetailsAllLocationInsight";
import { getVehicleAllLocationInsightDetails } from "../../../utils/vehicle.utils";

type Props = {};

function VehicleDetailsChargingInsights({}: Props) {
  const { isLoading, vehicleChargingSummary } = useVehicleChargingSummaryAsync();

  const {
    homeSessions,
    publicSessions,
    totalSessionSize,
    homeSessionSize,
    publicSessionSize
  } = getVehicleAllLocationInsightDetails(vehicleChargingSummary);


  return (
    <div className="mt-2">
      <Row>
        <Col lg="4" className="h-175px pr-lg-2">
          <Card className="h-100 h-max-100">
            <VehicleDetailsAllLocationInsight isLoading={isLoading} vehicleChargingSummary={vehicleChargingSummary} />
          </Card>
        </Col>
        <Col lg="4" className="h-175px px-lg-2 mt-3 mt-lg-0 flex-column justify-between">
          <InsightCard isLoading={isLoading}>
            <InsightCard.Row>
              <InsightCard.ChargingTitle>Public</InsightCard.ChargingTitle>
              <ChargingStationIcon />
            </InsightCard.Row>
            <InsightCard.Row className="mt-3">
              <InsightCard.Cost>{getFormattedNumberForUsdByCent(publicSessions?.cost?.count)}</InsightCard.Cost>
              <InsightCard.CostPercentage>{publicSessions?.cost?.percent}%</InsightCard.CostPercentage>
            </InsightCard.Row>
            <InsightCard.Row className="mt-2">
              <InsightCard.Energy> {getFormattedNumberForEnergy(publicSessions?.energy?.count)} kWh</InsightCard.Energy>
              <InsightCard.EnergyPercentage>{publicSessions?.energy?.percent}%</InsightCard.EnergyPercentage>
            </InsightCard.Row>
            <InsightCard.Row className="mt-2">
              <InsightCard.Sessions>{publicSessionSize} sessions</InsightCard.Sessions>
              <InsightCard.SessionsPercentage>{publicSessions?.sessionSize.percent}%</InsightCard.SessionsPercentage>
            </InsightCard.Row>
          </InsightCard>
        </Col>
        <Col lg="4" className="h-175px pl-lg-2 mt-3 mt-lg-0 flex-column justify-between">
          <InsightCard isLoading={isLoading}>
            <InsightCard.Row>
              <InsightCard.HomeTitle>Home</InsightCard.HomeTitle>
              <HomeIcon />
            </InsightCard.Row>
            <InsightCard.Row className="mt-3">
              <InsightCard.Cost>{getFormattedNumberForUsdByCent(homeSessions?.cost?.count)}</InsightCard.Cost>

              <InsightCard.CostPercentage>{homeSessions?.cost?.percent}%</InsightCard.CostPercentage>
            </InsightCard.Row>
            <InsightCard.Row className="mt-2">
              <InsightCard.Energy>{getFormattedNumberForEnergy(homeSessions?.energy?.count)} kWh</InsightCard.Energy>
              <InsightCard.EnergyPercentage>{homeSessions?.energy?.percent}%</InsightCard.EnergyPercentage>
            </InsightCard.Row>
            <InsightCard.Row className="mt-2">
              <InsightCard.Sessions>{homeSessionSize} sessions</InsightCard.Sessions>
              <InsightCard.SessionsPercentage>{homeSessions?.sessionSize.percent}%</InsightCard.SessionsPercentage>
            </InsightCard.Row>
          </InsightCard>
        </Col>
      </Row>
    </div>
  );
}

export default VehicleDetailsChargingInsights;
