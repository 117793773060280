import React from "react";
import { Modal, ModalBody } from "reactstrap";
import XIcon from "../icon/icons/XIcon";

export type BaseModalProps = React.PropsWithChildren<{
  readonly isModalOpen: boolean;
  readonly setIsModalOpen: (isModalOpen: boolean) => void;
  readonly isCloseIconVisible?: boolean;
  readonly className?: string;
  readonly containerClassName?: string;
}>;

export default function BaseModal({
  children,
  isModalOpen,
  setIsModalOpen,
  isCloseIconVisible,
  className,
}: BaseModalProps) {
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={() => setIsModalOpen(!isModalOpen)}
      className={`modal-dialog-centered  overflow-hidden ${className ?? ""}`}
    >
      <ModalBody className="w-md-100 h-md-75  h-lg-50  h-max-100 p-0 max-w-90  overflow-hidden position-relative">
        {isCloseIconVisible && (
          <div data-testid="modal-close-button" onClick={() => setIsModalOpen(false)} className="modal-close-button">
            <XIcon />
          </div>
        )}
        {children}
      </ModalBody>
    </Modal>
  );
}
