import DriverList from "components/driver-components/DriverList";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { BlackSubtitleText0, BlackSubtitleText200 } from "components/text/Text";
import VehicleList from "components/vehicle-components/VehicleList";
import { useFleetGroupAsync } from "hooks/data/fleet-group-data-accessor.hooks";
import React from "react";

type Props = {
  groupId: string;
};

export default function FleetGroupsTableExpandedContent({ groupId }: Props) {
  const { isLoading, fleetGroup } = useFleetGroupAsync(groupId);

  if (isLoading || !fleetGroup) {
    return (
      <div className="p-2 pb-3">
        <LoadingSkeleton count={3} height={20} />
      </div>
    );
  }

  return (
    <div className="expanded-table-row">
      <BlackSubtitleText0>Drivers</BlackSubtitleText0>

      {fleetGroup.users?.length > 0 ? (
        <DriverList isForwardActive drivers={fleetGroup.users} />
      ) : (
        <BlackSubtitleText200>No drivers in the group.</BlackSubtitleText200>
      )}

      <div className="horizontal-border-line my-2" />

      <BlackSubtitleText0>Vehicles</BlackSubtitleText0>
      {fleetGroup.vehicles.length > 0 ? (
        <VehicleList isForwardActive vehicles={fleetGroup.vehicles} />
      ) : (
        <BlackSubtitleText200>No vehicles in the group.</BlackSubtitleText200>
      )}
    </div>
  );
}
