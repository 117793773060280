import React, { useCallback, useMemo, useState } from "react";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";

import { LinearProgress, linearProgressClasses } from "@mui/material";
import Row from "../custom/Row";
import {
  BlackBodyText100,
  BlackBodyText200,
  BlackBodyText300,
  BlackCaptionText200,
  BlackCaptionText300,
  BlackSubtitleText100,
  BlockLabel,
  CherryBodyText100,
  GreenSubtitleText200,
} from "../block/Block";
import SecondaryCard from "../card/SecondaryCard";
import DownloadTemplateButton from "../button/DownloadTemplateButton";
import ExcelImageWithWhiteBackground from "../../assets/images/ExcelImageWithWhiteBackground.png";
import Col from "../custom/Col";
import XIcon from "../icon/icons/XIcon";
import { byteDisplay } from "../../utils/helper";
import UploadDocumentIcon from "../icon/icons/UploadDocumentIcon";
import AddDriversButton from "../button/AddDriversButton";
import DriverBulkUploadFormCsvUploaderProgressBar from "./DriverBulkUploadFormCsvUploaderProgressBar";
import { useIntercom } from "react-use-intercom";
import { Buffer } from "buffer";

const reader = new FileReader();

const ALLOWED_EXTENSIONS = [
  "text/csv",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export function DriverBulkUploadFormCsvUploader({
  setRawFileDataAsBase64,
  errors,
  setClientErrors,
  setFileType,
  clearErrors,
}) {
  const { clientError, serverErrors } = errors;

  const intercom = useIntercom();
  const [acceptedFile, setAcceptedFile] = useState(undefined);
  const [progressValue, setProgressValue] = useState(0);
  const [isFileUploadingContentShowing, setIsFileUploadingContentShowing] = useState(false);

  const handleCustomerSupportLinkPressed = useCallback(() => {
    intercom.show();
  }, [intercom]);

  const onDrop = useCallback(
    (files) => {
      try {
        clearErrors();

        if (!files || files?.length === 0) {
          throw Error("File is not uploaded");
        }

        const acceptedFile = files[0];

        // Type Validation
        const fileExtension = acceptedFile.type;
        const isAllowed = ALLOWED_EXTENSIONS.some((substring) => {
          return fileExtension?.includes(substring);
        });

        // Limit Validation
        const byteLimit = 6 * 1000000;
        const isLimitExceed = acceptedFile.size > byteLimit;

        setAcceptedFile(acceptedFile);

        if (!isAllowed) {
          throw Error("Wrong file format!");
        }

        if (isLimitExceed) {
          throw Error("Limit Exceed !");
        }

        setIsFileUploadingContentShowing(true);

        reader.readAsDataURL(files[0]);

        reader.onerror = () => console.log("file reading has failed");
        reader.onload = (e) => {
          const data = e.target.result;
          let base64 = data.replace(/^data:.+;base64,/, "");

          setFileType(fileExtension);
          setRawFileDataAsBase64(Buffer.from(base64, "base64"));
        };
        reader.onprogress = (currentFile) => {
          setProgressValue(currentFile.loaded / currentFile.total);
        };
      } catch (error) {
        console.log("error", error.message);
        setClientErrors(error.message);
      }
    },
    [clearErrors, setClientErrors, setFileType, setRawFileDataAsBase64]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    },
  });

  const handleCloseIconClicked = useCallback(() => {
    setRawFileDataAsBase64(undefined);
    setIsFileUploadingContentShowing(false);
    setAcceptedFile(undefined);
    clearErrors();
  }, [clearErrors, setRawFileDataAsBase64]);

  const errorContext = useMemo(() => {
    const isError = !!clientError || !!serverErrors;
    let missingEmailCount = 0;
    let serverErrorKeys = undefined;
    if (serverErrors) {
      serverErrorKeys = Object.keys(serverErrors)
        ?.map((item) => {
          if (item === "undefined") {
            missingEmailCount++;
            return undefined;
          }
          return item;
        })
        .filter((item) => item); // Ignore undefined keys.
    }

    return { isError, serverErrorKeys, missingEmailCount };
  }, [clientError, serverErrors]);

  const { isError, serverErrorKeys, missingEmailCount } = errorContext;
  console.log("is Error,", serverErrorKeys);

  const driverBulkUploadCardContent = useMemo(() => {
    if (isFileUploadingContentShowing || isError) {
      return (
        <Col className="position-relative w-max-100">
          <XIcon className="card-close-icon" onClick={handleCloseIconClicked} />
          <Row className="align-items-center flex-nowrap ">
            <img width={60} src={ExcelImageWithWhiteBackground} alt="ExcelImageWithWhiteBackground" />
            <Col className="d-flex flex-column ml-2 justify-start align-items-start h-100 w-100">
              <BlackSubtitleText100 className="block w-max-150px w-max-md-400px  text-truncate">
                {acceptedFile?.name}
              </BlackSubtitleText100>

              <BlackBodyText300 className="block">{byteDisplay(acceptedFile?.size)}</BlackBodyText300>
            </Col>
          </Row>

          {isError ? (
            <Col className="d-flex flex-column ml-2 justify-start align-items-start h-100 mt-2 ">
              <CherryBodyText100 className="block text-left">
                We're sorry, but we couldn't process your file. Please check the error message below for more details
                and try again. <br />
                <>
                  {clientError && <span>{clientError}</span>}
                  {serverErrorKeys?.map((key, ix) => (
                    <span>
                      {key} :
                      {serverErrors[key]?.map((e) => (
                        <span>
                          {" "}
                          {e} {ix === serverErrorKeys.length - 1 ? "" : " "}
                        </span>
                      ))}
                      <br />
                    </span>
                  ))}
                  {missingEmailCount > 0 && "There are missing emails for some of drivers."}
                </>
              </CherryBodyText100>
              <BlackBodyText100 className="block text-left">
                For more information, visit our help center or contact our{" "}
                <span className="text-decoration-underline" onClick={handleCustomerSupportLinkPressed}>
                  Customer Support
                </span>
              </BlackBodyText100>
            </Col>
          ) : (
            <DriverBulkUploadFormCsvUploaderProgressBar progressValue={progressValue} />
          )}
        </Col>
      );
    }

    return (
      <a href={`https://bluedot-fleet.s3.amazonaws.com/Driver+Bulk+Upload+Template.xlsx`} download>
        <Row className="d-flex flex-row justify-between align-items-center ">
          <Row className="align-items-center flex-nowrap mb-3 mb-md-0">
            <img width={60} src={ExcelImageWithWhiteBackground} alt="ExcelImageWithWhiteBackground" />
            <BlackSubtitleText100 className="ml-2 text-left">Driver Bulk Upload Template</BlackSubtitleText100>
          </Row>
          <DownloadTemplateButton />
        </Row>
      </a>
    );
  }, [
    acceptedFile?.name,
    acceptedFile?.size,
    clientError,
    handleCloseIconClicked,
    handleCustomerSupportLinkPressed,
    isError,
    isFileUploadingContentShowing,
    missingEmailCount,
    progressValue,
    serverErrorKeys,
    serverErrors,
  ]);

  return (
    <div style={{ textAlign: "center" }}>
      <SecondaryCard error={!!isError} className="my-4 py-4 max-w-100 overflow-auto h-max-350px">
        {driverBulkUploadCardContent}
      </SecondaryCard>
      <div
        {...getRootProps()}
        className="drop-input-container d-flex center flex-column p-3 p-md-0"
        aria-disabled={isFileUploadingContentShowing}
      >
        <UploadDocumentIcon fillColor={isFileUploadingContentShowing ? "#6F7981" : undefined} />
        <BlackBodyText200 className={"mt-2"}>Supported formats: .csv, .xlsx, and .xls </BlackBodyText200>
        <BlackCaptionText300>Maximum size: 6 mb </BlackCaptionText300>
        <BlackSubtitleText100 className={"text-decoration-underline mt-2"}>Select file</BlackSubtitleText100>

        <input {...getInputProps()} />
      </div>
    </div>
  );
}
