import React from "react";
import { SvgProps } from "types/svg.types";

type Props = {};

function FilterCalendarIcon(props: SvgProps) {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2H13V0H15V2H16C16.5304 2 17.0391 2.21071 17.4142 2.58579C17.7893 2.96086 18 3.46957 18 4V18C18 18.5304 17.7893 19.0391 17.4142 19.4142C17.0391 19.7893 16.5304 20 16 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V4C0 3.46957 0.210714 2.96086 0.585786 2.58579C0.960859 2.21071 1.46957 2 2 2H3V0H5V2ZM2 6V18H16V6H2ZM4 9H6V11H4V9ZM8 9H10V11H8V9ZM12 9H14V11H12V9ZM12 13H14V15H12V13ZM8 13H10V15H8V13ZM4 13H6V15H4V13Z"
        fill="black"
      />
    </svg>
  );
}

export default FilterCalendarIcon;
