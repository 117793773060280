import PrimaryButton from "components/button/PrimaryButton";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import ArrowRightIcon from "components/icon/icons/ArrowRightIcon";
import DatePicker from "components/input/date-picker/DatePicker";
import { BlackBodyText100, BlackOverlineText300 } from "components/text/Text";
import { DateTime } from "luxon";
import { useRef } from "react";
import ReactDatePicker from "react-datepicker";
import { FormGroup } from "reactstrap";

import {
  VehicleDriverSchedule,
  VehicleDriverSchedulePeriodKey,
  VehicleSchedulePeriodSelectorHooks,
} from "types/vehicle-driver-schedule.types";
import { DateTimeOneHourLaterObject } from "utils/date.utils";

export type VehicleDriverSchedulePeriodSelectorProps = {
  vehicleScheduleSelectorHooks: VehicleSchedulePeriodSelectorHooks;
  isEditMode?: boolean;
  isPortal?: boolean;
  selectedScheduleToUpdate?: VehicleDriverSchedule | undefined;
  description?: string;
};

function VehicleDriverSchedulePeriodSelector({
  isPortal,
  vehicleScheduleSelectorHooks,
  isEditMode,
  description,
}: VehicleDriverSchedulePeriodSelectorProps) {
  const { handleSetVehicleSchedulePeriod, vehicleDriverSchedulePeriodSelector } = vehicleScheduleSelectorHooks;
  const startDatePickerRef = useRef<ReactDatePicker>(null);
  const endDatePickerRef = useRef<ReactDatePicker>(null);

  const { startsAt, endsAt } = vehicleDriverSchedulePeriodSelector;

  const isScheduleActive = startsAt && startsAt.getTime() < new Date().getTime();
  const isStartDateSelectorDisabled = isEditMode && isScheduleActive;

  const minStartDate = DateTime.now();

  const minEndDate =
    isScheduleActive || !startsAt
      ? minStartDate.plus(DateTimeOneHourLaterObject)
      : DateTime.fromJSDate(startsAt).plus(DateTimeOneHourLaterObject);

  const adjustEndDate = (startsAt?: Date, endsAt?: Date) => {
    if (!endsAt || !startsAt) {
      return;
    }

    const startDate = DateTime.fromJSDate(startsAt);
    const endDate = DateTime.fromJSDate(endsAt);

    const startDatePlusOneHour = startDate.plus({ hour: 1 });

    if (startDatePlusOneHour > endDate) {
      const newEndDate = startDatePlusOneHour.toJSDate();
      handleSetVehicleSchedulePeriod("endsAt", newEndDate);
    }
  };

  const filterStartDate = (time) => {
    const selectedDate = new Date(time);
    return minStartDate.toJSDate().getTime() < selectedDate.getTime();
  };

  const filterEndDate = (time) => {
    const selectedDate = new Date(time);
    return minEndDate.toJSDate().getTime() < selectedDate.getTime();
  };

  const handleDateChange = (date: Date, key: VehicleDriverSchedulePeriodKey) => {
    const selectedTime = new Date(date).getTime();
    let newDate: Date | undefined = date;

    const isStartDateControl = key === "startsAt";

    const minAllowedTime = isStartDateControl ? minStartDate : minEndDate;

    if (selectedTime < minAllowedTime.toJSDate().getTime()) {
      newDate = isStartDateControl ? undefined : minEndDate.toJSDate();
    }

    if (isStartDateControl) {
      adjustEndDate(newDate, endsAt);
    }

    handleSetVehicleSchedulePeriod(key, newDate);
  };

  const handleNowButtonPressed = () => {
    const newStartsAt = undefined;
    handleSetVehicleSchedulePeriod("startsAt", newStartsAt);
    adjustEndDate(newStartsAt, endsAt);
    startDatePickerRef?.current?.setOpen(false);
  };


  return (
    <Col md="12" className="mt-2">
      <BlackBodyText100 className="mb-1">Vehicle assignment period</BlackBodyText100>

      <BlackOverlineText300 className="mb-2">
        Choose a start date to initiate the driver’s access to the vehicle. Similarly, select an end date to define when
        access will end. Without an end date, access continues indefinitely without a set expiration. This flexibility
        allows for both fixed-term and open-ended vehicle assignments.
      </BlackOverlineText300>

      <FormGroup>
        <Row className=" align-items-center">
          <DatePicker
            ref={startDatePickerRef}
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeSelect
            timeIntervals={15}
            withPortal={isPortal}
            disabled={isStartDateSelectorDisabled}
            placeholder="Select start date"
            selectedDate={startsAt ?? new Date()}
            filterTime={filterStartDate}
            onDateChange={(date) => handleDateChange(date, "startsAt")}
            minDate={minStartDate.toJSDate()}
          >
            <PrimaryButton
              text="Set date as immediate"
              type="button"
              onClick={handleNowButtonPressed}
              className="w-100"
            />
          </DatePicker>
          <ArrowRightIcon className="mx-1 my-2 my-md-0" />
          <DatePicker
            ref={endDatePickerRef}
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeSelect
            timeIntervals={15}
            withPortal={isPortal}
            placeholder="Select end date (Optional)"
            selectedDate={endsAt}
            onDateChange={(date) => handleDateChange(date, "endsAt")}
            minDate={minEndDate.toJSDate()}
            filterTime={filterEndDate}
          />
        </Row>
      </FormGroup>
    </Col>
  );
}

export default VehicleDriverSchedulePeriodSelector;
