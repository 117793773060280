type Props = {};

function VehicleStatusServiceIcon({}: Props) {
  return (
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.1901 14.6654L14.7734 11.2487C15.1068 10.4154 14.9401 9.33203 14.1901 8.66536C13.4401 7.91536 12.3568 7.7487 11.4401 8.16536L13.0234 9.7487L11.8568 10.9154L10.1901 9.2487C9.77344 10.1654 9.9401 11.2487 10.6901 12.082C11.4401 12.832 12.4401 12.9987 13.2734 12.6654L16.6901 16.082C16.8568 16.2487 17.1068 16.2487 17.1901 16.082L18.0234 15.2487C18.2734 14.9987 18.2734 14.7487 18.1901 14.6654ZM9.9401 13.6654H0.773438V11.9987C0.773438 10.1654 3.77344 8.66536 7.4401 8.66536C7.85677 8.66536 8.27344 8.66536 8.60677 8.7487C8.35677 9.2487 8.27344 9.7487 8.27344 10.332C8.27344 11.6654 8.9401 12.9154 9.9401 13.6654ZM7.4401 0.332031C5.60677 0.332031 4.10677 1.83203 4.10677 3.66536C4.10677 5.4987 5.60677 6.9987 7.4401 6.9987C9.27344 6.9987 10.7734 5.4987 10.7734 3.66536C10.7734 1.83203 9.27344 0.332031 7.4401 0.332031Z"
        fill="#6F7981"
      />
    </svg>
  );
}

export default VehicleStatusServiceIcon;
