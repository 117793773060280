import Col from "components/custom/Col";
import React from "react";
import InvoicePlaceholder from "assets/images/invoice/InvoicePlaceholder.png";
import { BlackBodyText200, BlackSubtitleText100 } from "components/text/Text";

type Props = {
  readonly title?: string;
  readonly description?: string;
};

function InvoiceTablePlaceholder({ title = "No invoices yet!", description }: Props) {
  return (
    <Col className="d-flex flex-column align-items-center">
      <img width={120} src={InvoicePlaceholder} alt="invoice placeholder" />
      <BlackSubtitleText100 className="my-2">{title}</BlackSubtitleText100>
      {description && <BlackBodyText200 className="px-4">{description}</BlackBodyText200>}
    </Col>
  );
}

export default InvoiceTablePlaceholder;
