import React from "react";
import Content from "../../layout/content/Content";
import Row from "../../components/custom/Row";
import Col from "../../components/custom/Col";
import DetailsPageSessionCard from "../../components/charging-sessions-components/DetailsPageSessionCard";
import DetailsPageUserCard from "../../components/charging-sessions-components/DetailsPageUserCard";
import { useTranslation } from "react-i18next";
import Head from "../../layout/head/Head";
import { BlockDes, BlockTitle } from "../../components/Component";
import { useChargingAsync } from "hooks/data/charging-data-accessor.hooks";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import Card from "components/card/Card";
import NotFoundContent from "../../components/not-found/NotFoundContent";
import ChargingDetailsAssignDriverEditorCard
  from "../../components/charging-sessions-components/ChargingDetailsAssignDriverEditorCard";
import { useFeatureFlags } from "../../hooks/feature-flag.hooks";

export default function ChargingSessionDetailsPage() {
  const { t } = useTranslation("common");

  const { isLoading, charging, getChargingSessionAsyncCallback: refetch } = useChargingAsync();

  if (!charging && !isLoading) {
    return (
      <>
        <Head title={t("main_menu.chargingSessionDetails")} />
        <NotFoundContent />
      </>
    );
  }

  return (
    <React.Fragment>
      <Head title={t("main_menu.chargingSessionDetails")} />
      <Content>
        <Col className="mb-4 mt-2">
          <BlockTitle page tag="h5">
            {t("charging_session_details.title")}
          </BlockTitle>
          <BlockDes>{t("charging_session_details.description")}</BlockDes>
        </Col>
        <Row>
          <Col md="6" className="pr-md-2 mb-2">
            {isLoading ? (
              <Card>
                <LoadingSkeleton count={12} height={35} />{" "}
              </Card>
            ) : (
              <DetailsPageSessionCard isLoading={isLoading} chargingSessionDetails={charging} />
            )}
          </Col>
          <Col md="6" className="pl-md-2">
            {isLoading ? (
              <Card>
                <LoadingSkeleton count={8} height={35} />{" "}
              </Card>
            ) : (
              <>
                <DetailsPageUserCard refetch={refetch} chargingSessionDetails={charging} />
              </>
            )}


            {charging  &&
              <ChargingDetailsAssignDriverEditorCard refetch={refetch} charging={charging} />
            }

          </Col>
        </Row>
      </Content>
    </React.Fragment>
  )
    ;
}
