import ClearButton from "components/button/ClearButton";
import OutlinedButton from "components/button/OutlinedButton";
import PrimaryButton from "components/button/PrimaryButton";
import Card from "components/card/Card";
import Row from "components/custom/Row";
import { BlackBody2Text100, BlackOverlineText200 } from "components/text/Text";
import React, { PropsWithChildren } from "react";

export type FilterMenuCardProps = PropsWithChildren<{
  readonly title?: string;
  readonly description?: string;
  readonly className?: string;
  readonly onApplyPressed: () => void;
  readonly onClearPressed: () => void;
  readonly isApplyButtonDisabled?: boolean;
}>;

function FilterMenuCard({
  title,
  description,
  className,
  onApplyPressed,
  onClearPressed,
  children,
  isApplyButtonDisabled,
}: FilterMenuCardProps) {
  return (
    <Card padding="p-2" className={`my-2 filter-menu-card card-with-more-shadow w-min-250px  ${className}`}>
      {title && <BlackBody2Text100>{title}</BlackBody2Text100>}
      {description && <BlackOverlineText200>{description}</BlackOverlineText200>}
      <div className="py-1">{children}</div>

      <Row className="justify-between mt-1 pointer-events-all">
        <OutlinedButton onClick={onClearPressed} text={"Clear"} type="button" />
        <PrimaryButton isDisabled={isApplyButtonDisabled} onClick={onApplyPressed} text="Apply" type="button" />
      </Row>
    </Card>
  );
}

export default FilterMenuCard;
