import React from "react";
import { Icon } from "../Component";

export default function ControllerValidationError({ error }) {
  return (
    <>
      {error && (
        <small className="text-danger flex align-center ">
          <Icon className={"m-1"} name="info"></Icon>
          {error}
        </small>
      )}
    </>
  );
}
