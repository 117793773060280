// React
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router";

// 3rd party
import { useTranslation } from "react-i18next";

// Hooks & Utils
// Types
// Components
import PaginatedTableCsvLinkButton from "components/button/PaginatedTableCsvLinkButton";
import FilterSearchInput from "components/filter/FilterSearchInput";
import PaginatedTable from "components/table/PaginatedTable";
import { useUrlPagination } from "hooks/pagination.hooks";
import { getFormattedNumberForEnergy } from "utils/charging.utils";
import Row from "../custom/Row";
import NoDataPlaceholder from "../table/placeholder/NoDataPlaceholder";
import { Driver } from "../../types/driver.types";
import { getDriverFullName } from "../../utils/driver.utils";
import { getFixedRate, getFormattedNumberForUsdByCent } from "../../utils/format.utils";
import { getLocalFormattedDateForTables } from "../../utils/date.utils";
import { TableColumn } from "react-data-table-component";
import { useReimbursementListAsync } from "../../hooks/data/reimbursement-data-accessor.hooks";
import { Reimbursement } from "types/reimbursement.types";
import { ReimbursementTableStatusToggle } from "./ReimbursementTableStatusToggle";
import { PaymentState } from "../../types/payment.types";
import { getReimbursementListAsync, updateReimbursementAsync } from "../../services/reimbursement.service";
import { useAlertManager } from "../../hooks/alert.hooks";
import { handleApiErrorResponse } from "../../utils";
import { getChargingReimbursementRowForCsv } from "../../utils/reimbursement.utils";
import ReimbursementsTableFilterMenu from "./ReimbursementsTableFilterMenu";
import { ReimbursementDownloadLinkButton } from "./ReimbursementDownloadLinkButton";

export type ReimbursementTableColumn = TableColumn<Reimbursement>[];

type Props = {
  remount: () => void
}

const ReimbursementsTable = ({ remount }: Props) => {
  const { handleOpenErrorAlert, handleOpenSuccessAlert } = useAlertManager();
  /**
   * Hooks
   */

  const { t } = useTranslation("common");

  const history = useHistory();

  const { tablePageRowSize } = useUrlPagination();

  const { reimbursements, meta, isLoading, refetch, setIsLoading } = useReimbursementListAsync();

  const handlePaymentStateChanged = useCallback(async (id: string, newPaymentState: PaymentState) => {
    try {
      setIsLoading(true);
      await updateReimbursementAsync(id, { paymentState: newPaymentState });
      handleOpenSuccessAlert("Reimbursement status successfully updated!");
      remount();
    } catch (e) {
      console.error(e);
      handleOpenErrorAlert(handleApiErrorResponse(e));
    } finally {
      setIsLoading(false);
    }
  }, [handleOpenErrorAlert, handleOpenSuccessAlert, remount, setIsLoading]);





  const columns = useMemo(
    () => [
      {
        grow: 1.5,
        name: "Driver",
        selector: (row: Reimbursement) => getDriverFullName(row.user)
      },
      {
        grow: 2,
        width: "170px",
        name: "Status",
        selector: (row: Reimbursement) => (
          <ReimbursementTableStatusToggle
            paymentState={row.paymentState}

            onPaymentStateChanged={(ps) => handlePaymentStateChanged(row.id, ps)} />
        )
      },
      {

        grow: 1.2,
        name: "Reimbursed",
        selector: (row) => <>{getFormattedNumberForUsdByCent(row?.grossAmount)}</>
      },
      {
        grow: 2,
       // width: "200px",
        name: "Period",
        selector: (row: Reimbursement) => row.period
      },
      {
        grow: 1.2,
       // width: "120px",
        name: "Fixed rate",
        selector: (row: Reimbursement) => getFixedRate(row.payoutRate)?.label ?? "-"
      },
      {
        grow: 1.2,
        name: "Total kWh",
        cell: (row: Reimbursement) => getFormattedNumberForEnergy(row.energyDelivered) + "kWh"
      },
      {
        width: "150px",
        name: "Date",
        selector: (row: Reimbursement) => <>{getLocalFormattedDateForTables(row?.createdAt)} </>
      },
      {
        right: false,
        center: true,
        grow: 1,
        name: "Receipt",
        cell: (row: Reimbursement) => (
          <ReimbursementDownloadLinkButton row={row} />
        )
      }
    ],
    [handlePaymentStateChanged]
  ) as ReimbursementTableColumn;


  /**
   * Handlers
   */

  /**
   * Effects
   */

  /**
   * Render
   */

  return (
    <React.Fragment>
      <Row className="justify-between my-2 mt-3 align-center">
        <FilterSearchInput placeholder="Search here" />

        <PaginatedTableCsvLinkButton
          fileName="home-reimbursements"
          csvRowFormatter={getChargingReimbursementRowForCsv}
          totalPages={meta?.pagination.totalPages ?? 0}
          rowSizePerPage={tablePageRowSize}
          fetchListAsync={getReimbursementListAsync}
        />
      </Row>

      <ReimbursementsTableFilterMenu />


      <PaginatedTable
        noDataPlaceholder={
          <NoDataPlaceholder description="No reimbursement yet." />
        }
        paginationTotalRows={meta?.pagination.totalElements}
        columns={columns}
        data={reimbursements}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

export default ReimbursementsTable;
