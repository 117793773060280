import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import DiscardChangesButton from "../button/DiscardChangesButton";
import StayButton from "../button/StayButton";
import { BlackH5Text0 } from "../text/Text";

export default function AlertConfirmDialog({
  isLoading,
  isDialogOpen,
  toggleIsDialogOpen,
  onDialogConfirmed,
  description,
  descriptionOverride,
  title,
  overrideButtons,
  titleOverride,
  icon,
}: {
  isLoading?: boolean;
  isDialogOpen: boolean;
  toggleIsDialogOpen?: () => void;
  onDialogConfirmed?: () => void;
  description?: string;
  descriptionOverride?: JSX.Element;
  titleOverride?: JSX.Element;
  title?: string;
  overrideButtons?: JSX.Element;
  icon?: JSX.Element;
}) {
  return (
    <Dialog
      open={isDialogOpen}
      onClose={toggleIsDialogOpen}
      aria-labelledby="responsive-dialog-title"
      classes={{ paper: " p-1 rounded-xxl " }}
    >
      {icon && <DialogContent>{icon}</DialogContent>}

      <DialogTitle id="responsive-dialog-title">
        {titleOverride ?? (title && <BlackH5Text0>{title}</BlackH5Text0>)}
      </DialogTitle>

      <DialogContent>{descriptionOverride ?? <DialogContentText>{description}</DialogContentText>}</DialogContent>
      <DialogActions>
        {overrideButtons ?? (
          <>
            <StayButton isLoading={isLoading} onClick={toggleIsDialogOpen} />
            {onDialogConfirmed && (
              <DiscardChangesButton
                isDisabled={false}
                widthClass=""
                onDiscardChangesButtonClicked={onDialogConfirmed}
              />
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
