export const AppColors = {
  primary: "#1e44ff",
  blue0: "#000B3D",
  blue100: "#001058",
  blue200: "#001A8F",
  blue300: "#0025CC",
  blue400: "#002DF5",
  blue500: "#1e44ff",
  blue600: "#4769FF",
  blue700: "#6E86FF",
  blue800: "#96A7FF",
  blue900: "#BEC8FF",
  blue1000: "#E5EAFF",
  cherry0: "#3D0013",
  cherry100: "#660020",
  cherry200: "#8F002D",
  cherry300: "#A30034",
  cherry400: "#B8003A",
  cherry500: "#C4003E",
  cherry600: "#BF3D62",
  cherry700: "#CD6484",
  cherry800: "#DB8EA7",
  cherry900: "#F4B8CA",
  cherry1000: "#FFE5EE",
  mint0: "#021112",
  mint100: "#063437",
  mint200: "#0A565C",
  mint300: "#0E7981",
  mint400: "#129BA5",
  mint500: "#16BAC5",
  mint600: "#18CFDC",
  mint700: "#35DDE9",
  mint800: "#91E9F3",
  mint900: "#B6F1F7",
  mint1000: "#DAF8FB",
  neutralBlack: "#020202",
  neutral100: "#2E3338",
  neutral200: "#5D676F",
  neutral300: "#7A8590",
  neutral400: "#909AA2",
  neutral500: "#A0A8AF",
  neutral600: "#BCC2C7",
  neutral700: "#C7CCD1",
  neutral800: "#DEE1E3",
  neutral900: "#E9EBEC",
  neutral950: "#F1F5FA",
  neutralWhite: "#FFFFFF",
  positive0: "#0C3A03",
  positive100: "#146105",
  positive200: "#188B04",
  positive300: "#1FB206",
  positive400: "#23C606",
  positive500: "#23D206",
  positive600: "#2AEA08",
  positive700: "#34F812",
  positive800: "#9AFB88",
  positive900: "#CCFDC4",
  positive950: "#DEFDD8",
  positive1000: "#EEFEEB",
  violet500: "#BE8DFF",
  violet600: "#C89FFF",
  violet700: "#D2B1FF",
  violet800: "#DCC3FF",
  violet900: "#E6D5FF",
  violet1000: "#F1E5FF"
};