import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { RegisterOptions } from "react-hook-form";
import { handleValidatePassword } from "utils/auth.utils";

import { ConfirmPasswordValidationMessage } from "../../form/ConfirmPasswordValidationMessage";
import PasswordValidationMessages from "../../form/PasswordValidationMessages";
import AuthPasswordInput from "./AuthPasswordInput";

export type PasswordFormValues = {
  password: string;
  confirmPassword: string;
};

type Props = {
  readonly isForgotPasswordFlow?: boolean;
  readonly getFormValues: () => PasswordFormValues;
  readonly register: (options: RegisterOptions) => React.LegacyRef<HTMLInputElement> | undefined;
};

export type ValidatePasswordRef = {
  validatePassword: () => boolean;
};
const AuthPasswordInputs = forwardRef<ValidatePasswordRef, Props>(
  ({ getFormValues, register, isForgotPasswordFlow }, ref) => {
    useImperativeHandle(ref, () => ({
      validatePassword() {
        const password = getFormValues().password;
        return handlePasswordValidate(password);
      },
    }));

    const [isAllPasswordRequirementsValid, setIsAllPasswordRequirementsValid] = useState(false);

    const [isPasswordEntered, setIsPasswordEntered] = useState(false);
    const [confirmPasswordValidationMessage, setConfirmPasswordValidationMessage] = useState("");
    const [passwordValidationMessages, setPasswordValidationMessages] = useState<string[]>([]);

    const [isPasswordInputFirsTimeFocused, setIsPasswordInputFirsTimeFocused] = useState(true);

    /**
     *
     */
    const handlePasswordInputBlur = () => setIsPasswordInputFirsTimeFocused(false);

    /**
     *
     */
    const handleConfirmPasswordValidation = useCallback(() => {
      const password = getFormValues().password;
      const confirmPassword = getFormValues().confirmPassword;

      if (password === confirmPassword) {
        !!confirmPasswordValidationMessage && setConfirmPasswordValidationMessage("");
        return true;
      }

      setConfirmPasswordValidationMessage("Passwords should match");
      return false;
    }, [getFormValues, confirmPasswordValidationMessage]);
    /**
     *
     */
    const handlePasswordValidate = useCallback(
      (value: string) => {
        setIsPasswordEntered(value.length > 0);

        const { isValid, passwordValidation } = handleValidatePassword(value);

        setIsAllPasswordRequirementsValid(isValid);

        handleConfirmPasswordValidation();

        if (!isValid) {
          setPasswordValidationMessages(
            Object.values(passwordValidation)
              .filter((v) => !v.isValid)
              .map((v) => v.message)
          );

          return false;
        }
        setPasswordValidationMessages([]);
        return true;
      },
      [handleConfirmPasswordValidation]
    );

    /**
     *
     */
    const handleConfirmPasswordValidate = handleConfirmPasswordValidation;

    return (
      <>
        <AuthPasswordInput
          data-testid="password"
          register={register}
          onChange={(e) => handlePasswordValidate(e.target.value)}
          className="w-max-100 w-100"
          onBlur={handlePasswordInputBlur}
          placeholder={isForgotPasswordFlow ? "New password" : "Password"}
        />

        {isPasswordEntered && !isAllPasswordRequirementsValid && (
          <PasswordValidationMessages
            isFirsTimeFocused={isPasswordInputFirsTimeFocused}
            passwordValidationMessages={passwordValidationMessages}
          />
        )}

        <AuthPasswordInput
          data-testid="confirmPassword"
          register={register}
          onValidate={handleConfirmPasswordValidate}
          placeholder={isForgotPasswordFlow ? "Confirm new password" : "Confirm password"}
          name="confirmPassword"
          className="w-max-100 w-100 mt-4"
          aria-label="confirmPassword"
        />
        {isPasswordEntered && <ConfirmPasswordValidationMessage validationMessage={confirmPasswordValidationMessage} />}
      </>
    );
  }
);

export default AuthPasswordInputs;
