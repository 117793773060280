import FilterMenu from "components/filter/FilterMenu";
import React from "react";

import FilterDateSelector from "components/filter/FilterDateSelector";
import FilterVehicleStatusSelector from "./FilterVehicleStatusSelector";
import VehicleSortingSelector from "./VehicleSortingSelector";

type Props = {};

function VehiclesTableFilterMenu(props: Props) {
  return <FilterMenu selectors={[<FilterVehicleStatusSelector />]} sortingSelector={<VehicleSortingSelector />} />;
}

export default VehiclesTableFilterMenu;
