import React from "react";
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

import { getFixedRate, getFormattedNumberForUsdByCent, getFormattedPhoneNumber } from "utils/format.utils";
import { getDriverFullName } from "utils/driver.utils";
import { formatSeconds, getLocalFormattedDateAsDateMed } from "utils/date.utils";
import { getFormattedNumberForEnergy } from "utils/charging.utils";
import { Reimbursement } from "types/reimbursement.types";
import BluedotFleetWordMarkForPdf from "../icon/icons/BluedotFleetWordMarkForPdf";
import { AppColors } from "../../utils/colors.utils";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white"
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  header: {
    padding: "20px 10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  headerCompanyNameText: {
    color: "#1A1C21",
    fontSize: 16,
    fontWeight: "bold"
  },
  betweenWithBorder: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #D9D9D9",
    padding: "10px 0px"
  },
  between: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px 0px"
  },
  text: {
    color: "#1A1C21",
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 4
  },
  textMedium: {
    color: "#1A1C21",
    fontSize: 10,
    fontWeight: "medium",
    marginBottom: 4
  },
  subText: {
    color: "#5E6470",
    fontSize: 10,
    fontWeight: "medium"
  },
  semiBoldSubText: {
    color: "#5E6470",
    fontSize: 10,
    fontWeight: "semibold"
  },
  headerSeparator: {
    width: "100%",
    borderBottom: "2px solid #1e44ff"
  },
  verticalSeperator: {
    height: "28px",
    margin: "0px 10px",
    borderRight: "1px solid #D9D9D9"
  },
  personalInfoSection: {
    margin: "20px 0px"
  },
  detailsCardHeaderBox: {
    width: "49%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  detailsCard: {
    backgroundColor: "#F1F5FA",
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  detailsCardHeader: {
    paddingTop: 10,
    display: "flex",
    flexDirection: "row"
  },
  textEnd: {
    textAlign: "right"
  },
  alignEnd: {
    display: "flex",
    alignItems: "flex-end"
  },
  mt3: {
    marginTop: 24
  },
  mt2: {
    marginTop: 20
  },
  totalCard: {
    backgroundColor: AppColors.blue500,
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10
  },
  totalCardText: {
    color: "white",
    fontSize: 12,
    fontWeight: "bold"
  },
  spacing: {
    marginLeft: 10,
    marginRight: 10,
    paddingLeft: 10,
    paddingRight: 10
  }
});

type Props = {
  reimbursement?: Reimbursement;
};

const InvoiceTemplate = ({ reimbursement }: Props) => {
  const user = reimbursement?.user;
  const organization = reimbursement?.organization;
  const userAddress = user?.address;
  const formattedUserAddress =
    userAddress?.street + " " + userAddress?.street2 + ", " + userAddress?.state + " " + userAddress?.postalCode;

  const reimbursementPeriod = `${getLocalFormattedDateAsDateMed(
    reimbursement?.periodStartedAt
  )} - ${getLocalFormattedDateAsDateMed(reimbursement?.periodEndedAt)}`;

  const energyDelivered = getFormattedNumberForEnergy(reimbursement?.energyDelivered) + "kWh";
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <BluedotFleetWordMarkForPdf />
          <View style={styles.alignEnd}>
            <Text style={styles.headerCompanyNameText}>{organization?.businessName}</Text>
            <Text style={styles.subText}>{reimbursement?.organization?.email}</Text>
            <Text style={styles.subText}>{getFormattedPhoneNumber(reimbursement?.organization?.phoneNumber)}</Text>
          </View>
        </View>
        <View style={styles.headerSeparator} />
        <View style={styles.spacing}>
          <View style={styles.personalInfoSection}>
            <Text style={styles.text}>{getDriverFullName(reimbursement?.user)}</Text>
            <Text style={styles.subText}>{formattedUserAddress}</Text>
            <Text style={styles.subText}>{getFormattedPhoneNumber(user?.phoneNumber)}</Text>
          </View>
          <View style={[styles.between]}>
            <View>
              <Text style={styles.semiBoldSubText}>Reimbursement period</Text>
              <Text style={styles.text}>{reimbursementPeriod}</Text>
            </View>
            <View style={styles.alignEnd}>
              <Text style={[styles.semiBoldSubText, styles.textEnd]}>Receipt date</Text>
              <Text style={[styles.text, styles.textEnd]}>
                {getLocalFormattedDateAsDateMed(reimbursement?.createdAt)}
              </Text>
            </View>
          </View>

          <View style={[styles.betweenWithBorder]}>
            <View>
              <Text style={styles.semiBoldSubText}>Account ID</Text>
              <Text style={styles.text}>
                {organization?.businessName} - {organization?.id}
              </Text>
            </View>

            <View style={styles.alignEnd}>
              <Text style={[styles.semiBoldSubText, styles.textEnd]}>Receipt ID</Text>
              <Text style={[styles.text, styles.textEnd]}>{reimbursement?.id}</Text>
            </View>
          </View>
        </View>
        <View style={styles.detailsCard}>
          <View style={styles.betweenWithBorder}>
            <Text style={styles.semiBoldSubText}>Item description</Text>
            <Text style={styles.semiBoldSubText}>Amount</Text>
          </View>
          <View style={styles.betweenWithBorder}>
            <Text style={styles.textMedium}>Utility kWh billed</Text>
            <Text style={styles.textMedium}>-</Text>
          </View>
          <View style={styles.betweenWithBorder}>
            <Text style={styles.textMedium}>Effective rate per kWh (Fixed rate)</Text>
            <Text style={styles.textMedium}>{getFixedRate(reimbursement?.payoutRate)?.label ?? "-"}</Text>
          </View>
          <View style={styles.betweenWithBorder}>
            <Text style={styles.textMedium}>Effective tax rate on use</Text>
            <Text style={styles.textMedium}>{reimbursement?.taxRate}%</Text>
          </View>
          <View style={styles.betweenWithBorder}>
            <Text style={styles.textMedium}>Total charging time</Text>
            <Text style={styles.textMedium}>{formatSeconds(reimbursement?.duration)}</Text>
          </View>
          <View style={styles.between}>
            <Text style={styles.textMedium}>Total EV Charging kWh</Text>
            <Text style={styles.textMedium}>{energyDelivered}</Text>
          </View>
        </View>
        <View style={[styles.detailsCard, styles.mt2]}>
          <View style={styles.betweenWithBorder}>
            <Text style={styles.textMedium}>kWh reimbursement amt. </Text>
            <Text style={styles.textMedium}>{getFormattedNumberForUsdByCent(reimbursement?.grossAmount)}</Text>
          </View>
          <View style={styles.between}>
            <Text style={styles.textMedium}>Tax reimbursement amt. </Text>
            <Text style={styles.textMedium}>{getFormattedNumberForUsdByCent(reimbursement?.taxAmount)}</Text>
          </View>
        </View>
        <View style={[styles.between, styles.totalCard]}>
          <Text style={styles.totalCardText}>Total reimbursement amt.</Text>
          <Text style={styles.totalCardText}>{getFormattedNumberForUsdByCent(reimbursement?.netAmount)} </Text>
        </View>
      </Page>
    </Document>
  );
};

export default InvoiceTemplate;