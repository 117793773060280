import React from "react";
import HoverUnderlinedTextButton from "./HoverUnderlinedTextButton";

type Props = {
  onClick?: () => void
}

const LearnMoreTextButton = ({ onClick }: Props) => {
  return (
    <HoverUnderlinedTextButton className={"ml-1"} text={"Learn more"} onClick={onClick} />
  );
};

export default LearnMoreTextButton;