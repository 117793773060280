import OutlinedResendCodeButton from "components/button/ResendCodeOutlinedButton";
import Card from "components/card/Card";
import { BlackBodyText100, BlackBodyText200 } from "components/text/Text";
import React from "react";

type AuthResendEmailCardProps = {
  readonly isLoading: boolean;
  readonly onResendCodeButtonPressed: () => void;
};

function AuthResendEmailCard({ isLoading, onResendCodeButtonPressed }: AuthResendEmailCardProps) {
  return (
    <Card className="card-with-more-shadow mt-4 p-3">
      <BlackBodyText100>Having trouble finding our email?</BlackBodyText100>
      <BlackBodyText200 className="mt-2 pr-2">
        Please check your spam or junk folder if you haven't received the email. If it's still missing, click below to
        resend.
      </BlackBodyText200>
      <div className="w-100 d-flex justify-content-end  mt-2 mt-lg-3">
        <OutlinedResendCodeButton
          className="w-150px"
          isLoading={isLoading}
          isDisabled={isLoading}
          onResendCodeButtonPressed={onResendCodeButtonPressed}
        />
      </div>
    </Card>
  );
}

export default AuthResendEmailCard;
