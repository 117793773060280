import { useAppDispatch, useAppSelector } from "hooks/redux.hooks";
import { useCallback } from "react";
import { decrementDriverNonFormStep, resetDriverForm } from "redux/slices/driverSlice";
import { getDriverFormStepStatus } from "utils/driver.utils";
import OutlinedBackButton from "./OutlinedBackButton";

type Props = {};

function DriverFromBackButton({}: Props) {
  const driverNonFormState = useAppSelector((s) => s.driver.driverNonFormState);
  const { isFirstStep } = getDriverFormStepStatus(driverNonFormState);

  const dispatch = useAppDispatch();
  const DriverFromBackButtonClicked = useCallback(() => {
    if (isFirstStep) {
      dispatch(resetDriverForm());
      return;
    }
    dispatch(decrementDriverNonFormStep());
  }, [dispatch, isFirstStep]);

  return <OutlinedBackButton onBackButtonClicked={DriverFromBackButtonClicked} />;
}

export default DriverFromBackButton;
