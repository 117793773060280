import React from "react";

type Props = {
  className?: string;
  children: string;
};

export default function CircleBadge({ children, className }: Props) {
  return <div className={`circle-badge center ${className ?? ""}`}>{children}</div>;
}
