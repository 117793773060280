import React from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { BlackBodyText300, PurpleSubtitleText200 } from "../text/Text";

type Props = {};

const ForgotYourPasswordTextLink = (props: Props) => {
  const { url } = useRouteMatch();
  return (
    <Link to={`${url}/forgot-password`} type="button">
      <BlackBodyText300>Forgot your password?</BlackBodyText300>
    </Link>
  );
};

export default ForgotYourPasswordTextLink;
