import Row from "../custom/Row";
import LoadingSkeleton from "../loading/LoadingSkeleton";

import { BlackBodyText100, BlackCaptionText200 } from "../text/Text";

export type DetailsPageCardBlockProps = {
  leftLabel?: string;
  leftText?: string;
  leftTextOverwrite?: JSX.Element;
  leftAfterText?: string;
  leftBeforeText?: string;
  rightLabel?: string;
  rightText?: string;
  rightTextOverwrite?: JSX.Element;
  rightBeforeText?: string;
  isBorderTopVisible?: boolean;
  isBorderBottomVisible?: boolean;
  leftAfterLabel?: JSX.Element | string;
  rightAfterLabel?: JSX.Element | string;
  paddingClass?: string;
  columnClass?: string;
  isLoading?: boolean;
};

export default function DetailsPageCardBlock({
  leftLabel,
  leftAfterLabel,
  leftText,
  leftTextOverwrite,
  leftAfterText,
  leftBeforeText,
  rightLabel,
  rightAfterLabel,
  rightText,
  rightTextOverwrite,
  rightBeforeText,
  isBorderTopVisible,
  isBorderBottomVisible,
  paddingClass,
  isLoading,
  columnClass,
}: DetailsPageCardBlockProps) {
  let containerClass = paddingClass ?? "py-2 ";
  if (isBorderBottomVisible) {
    containerClass += " border-bottom ";
  }
  if (isBorderTopVisible) {
    containerClass += " border-top ";
  }

  if (isLoading) {
    return <LoadingSkeleton count={2} height={30} />;
  }

  const isSharedBlock = !!rightLabel && !!leftLabel && leftText && leftText.length > 20;

  return (
    <Row className={`justify-between flex-nowrap text-left  ${containerClass}`}>
      <div className={`d-flex justify-center flex-column flex-nowrap  ${isSharedBlock && "w-max-50-calc"}`}>
        <Row>
          {leftLabel && <BlackCaptionText200>{leftLabel}</BlackCaptionText200>}
          {leftAfterLabel && leftAfterLabel}
        </Row>

        <Row className={`justify-content-start align-center h-25px flex-nowrap ${columnClass}`}>
          {leftBeforeText && <div className="mr-1">{leftBeforeText}</div>}
          {leftTextOverwrite ??
            (leftText && (
              <BlackBodyText100 className={"mr-2 word-wrap-normal w-max-100 text-truncate"}>
                {leftText}
              </BlackBodyText100>
            ))}
          {leftAfterText && <div className="ml-1">{leftAfterText}</div>}
        </Row>
      </div>
      <div className={`d-flex justify-center flex-column ${isSharedBlock && "w-max-50-calc"}`}>
        <Row className="justify-end flex-nowrap w-100">
          {rightLabel && <BlackCaptionText200>{rightLabel}</BlackCaptionText200>}
          {rightAfterLabel && rightAfterLabel}
        </Row>
        <Row className="justify-end align-center h-25px flex-nowrap w-100">
          {rightBeforeText && rightBeforeText}
          {rightTextOverwrite ??
            (rightText && <BlackBodyText100 className="word-wrap-normal">{rightText}</BlackBodyText100>)}
        </Row>
      </div>
    </Row>
  );
}
