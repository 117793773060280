import React from "react";
import TextButton from "./TextButton";

type Props = {
  readonly onClicked: () => void;
};

function ArchiveLinkButton({ onClicked }: Props) {
  return <TextButton className="hover-background-light p-1" onClick={onClicked} text="Archive" />;
}

export default ArchiveLinkButton;
