import FilterMenuCheckbox from "components/filter/FilterMenuCheckbox";
import { useFilterUrlQuery } from "hooks/filter.hooks";
import { useCallback, useEffect, useState } from "react";

import FilterEditorCard from "components/filter/FilterEditorCard";

import { DriverTableFilters, UserStatus } from "types/driver.types";
import { getUserStatusAsBoolean } from "utils/driver.utils";
import { mapStringifiedBooleansAsBooleans } from "utils/format.utils";

type Props = {};

function FilterDriverStatusSelector(props: Props) {
  const { filterQuery, setFilterQuery } = useFilterUrlQuery<DriverTableFilters>();
  const [selectedStatus, setSelectedStatus] = useState<boolean[]>([]);
  const [variationCount, setVariationCount] = useState(0);

  useEffect(() => {
    const statusOnQuery = filterQuery?.active?.$in as string[];

    // Select both
    if (!statusOnQuery) {
      setSelectedStatus([]);
      setVariationCount(0);
      return;
    }

    const statusOnQueryAsBooleans = mapStringifiedBooleansAsBooleans(statusOnQuery);
    setVariationCount(Math.min(statusOnQueryAsBooleans.length ?? 0, 2));
    setSelectedStatus(statusOnQueryAsBooleans);
  }, [filterQuery]);

  const isSelectedStatus = useCallback(
    (status: boolean) => {
      return selectedStatus.includes(status);
    },
    [selectedStatus]
  );

  const handleStatusToggled = useCallback(
    (status: boolean) => {
      if (isSelectedStatus(status)) {
        setSelectedStatus(selectedStatus.filter((t) => t !== status));
        return;
      }
      setSelectedStatus([...selectedStatus, status]);
    },
    [isSelectedStatus, selectedStatus]
  );

  const handleSetStatusOnQuery = useCallback(
    (status: boolean[], isUndefined: boolean) => {
      setFilterQuery((q) => {
        return {
          active: isUndefined
            ? undefined
            : {
                $in: status,
              },
        };
      });
    },
    [setFilterQuery]
  );

  const handleClearButtonPressed = useCallback(() => {
    setSelectedStatus([]);
    handleSetStatusOnQuery([], true);
    setVariationCount(0);
  }, [handleSetStatusOnQuery]);

  const handleApplyButtonPressed = useCallback(() => {
    handleSetStatusOnQuery(selectedStatus, selectedStatus.length === 0);
  }, [handleSetStatusOnQuery, selectedStatus]);

  return (
    <FilterEditorCard
      className="my-1"
      title="Status"
      label="Status"
      description="Current status of your driver"
      variationCount={variationCount}
      onApplyPressed={handleApplyButtonPressed}
      onClearPressed={handleClearButtonPressed}
    >
      {[UserStatus.Active, UserStatus.Inactive].map((status, ix) => (
        <FilterMenuCheckbox
          key={ix}
          onToggle={() => handleStatusToggled(getUserStatusAsBoolean(status))}
          isChecked={isSelectedStatus(getUserStatusAsBoolean(status))}
          label={status}
        />
      ))}
    </FilterEditorCard>
  );
}

export default FilterDriverStatusSelector;
