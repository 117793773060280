import React from "react";

type Props = {};

function LocationIcon({}: Props) {
  return (
    <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.50001 0.4375C4.13298 0.439113 2.8224 0.982877 1.85577 1.94951C0.889132 2.91615 0.345368 4.22672 0.343755 5.59375C0.342118 6.71089 0.707028 7.79771 1.38251 8.6875C1.38251 8.6875 1.52313 8.87266 1.5461 8.89937L5.50001 13.5625L9.45579 8.89703C9.47641 8.87219 9.61751 8.6875 9.61751 8.6875L9.61797 8.68609C10.2931 7.79669 10.6579 6.71037 10.6563 5.59375C10.6546 4.22672 10.1109 2.91615 9.14424 1.94951C8.17761 0.982877 6.86703 0.439113 5.50001 0.4375ZM5.50001 7.46875C5.12917 7.46875 4.76665 7.35878 4.45831 7.15276C4.14997 6.94673 3.90965 6.65389 3.76773 6.31128C3.62582 5.96867 3.58869 5.59167 3.66103 5.22796C3.73338 4.86424 3.91196 4.53015 4.17418 4.26792C4.4364 4.0057 4.7705 3.82712 5.13421 3.75478C5.49793 3.68243 5.87493 3.71956 6.21754 3.86148C6.56015 4.00339 6.85298 4.24371 7.05901 4.55206C7.26504 4.8604 7.37501 5.22291 7.37501 5.59375C7.37439 6.09084 7.17664 6.56739 6.82515 6.91889C6.47365 7.27039 5.9971 7.46813 5.50001 7.46875Z"
        fill="#0C0C0C"
      />
    </svg>
  );
}

export default LocationIcon;
