import React from "react";

type Props = {};

function InvoiceMenuIconOutlined({}: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5 10.6641V19.793C19.5 20.3897 19.2629 20.962 18.841 21.384C18.419 21.8059 17.8467 22.043 17.25 22.043H6.75C6.15326 22.043 5.58097 21.8059 5.15901 21.384C4.73705 20.962 4.5 20.3897 4.5 19.793V4.79297C4.5 4.19623 4.73705 3.62394 5.15901 3.20198C5.58097 2.78002 6.15326 2.54297 6.75 2.54297H11.3789C11.7766 2.54303 12.158 2.70101 12.4392 2.98219L19.0608 9.60375C19.342 9.88499 19.4999 10.2664 19.5 10.6641Z"
        stroke="#6F7981"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M12 2.91797V8.54297C12 8.94079 12.158 9.32232 12.4393 9.60363C12.7206 9.88493 13.1022 10.043 13.5 10.043H19.125M8.25 13.793H15.75M8.25 17.543H15.75"
        stroke="#6F7981"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default InvoiceMenuIconOutlined;
