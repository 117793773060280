import React from "react";

type Props = {};

function UnlinkIconWithoutExclamation({}: Props) {
  return (
    <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3136_7471)">
        <path
          d="M38.0616 38.1923C36.5651 39.6712 34.7882 40.8642 32.8167 41.6841L36.7623 51.1812C39.9659 49.8469 42.8566 47.9081 45.2884 45.5048C47.7202 43.1015 49.693 40.2338 51.0649 37.0462C52.4346 33.8586 53.2035 30.3468 53.2252 26.6575C53.247 22.9681 52.5195 19.4497 51.1875 16.244C49.8533 13.0404 47.9144 10.1497 45.5111 7.71789C43.1079 5.28611 40.2402 3.31331 37.0526 1.94143L32.9953 11.3913C34.9569 12.2344 36.7218 13.4482 38.1986 14.9447C39.6776 16.4412 40.8705 18.2181 41.6904 20.1896C42.5103 22.161 42.9572 24.3268 42.9438 26.5947C42.9304 28.8626 42.4581 31.0231 41.615 32.9847C40.7719 34.9463 39.5581 36.7112 38.0616 38.188L38.0616 38.1923ZM7.50148 45.2821C9.90476 47.7139 12.7725 49.6866 15.9601 51.0585L20.0173 41.6087C18.0557 40.7656 16.2908 39.5517 14.814 38.0553C13.3351 36.5588 12.1421 34.7818 11.3222 32.8104C10.5023 30.839 10.0555 28.6731 10.0688 26.4052C10.0822 24.1373 10.5545 21.9769 11.3976 20.0153C12.2407 18.0537 13.4546 16.2888 14.951 14.8119C16.4475 13.333 18.2245 12.14 20.1959 11.3201L16.2503 1.82308C13.0467 3.15729 10.156 5.09614 7.7242 7.49942C5.29242 9.90269 3.31963 12.7704 1.94775 15.958C0.575867 19.1456 -0.190854 22.6574 -0.212601 26.3468C-0.234349 30.0361 0.493069 33.5546 1.82513 36.7603C3.15933 39.9638 5.09818 42.8546 7.50146 45.2864L7.50148 45.2821Z"
          fill="#DB8EA7"
        />
      </g>
      <defs>
        <clipPath id="clip0_3136_7471">
          <rect width="53" height="53" rx="26.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UnlinkIconWithoutExclamation;
