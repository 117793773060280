import React, { useCallback, useEffect, useMemo, useState } from "react";

// Third-party

import { ChannelList } from "@sendbird/uikit-react";

import Content from "../../layout/content/Content";
import { useHistory } from "react-router";
import Row from "../../components/custom/Row";
import { BlockDes, BlockTitle, UserAvatar } from "../../components/Component";
import { Auth } from "aws-amplify";

import { profileService } from "../../services";
import Skeleton from "react-loading-skeleton";
import "@sendbird/uikit-react/dist/index.css";
import Col from "../../components/custom/Col";
import Head from "../../layout/head/Head";
import DriverSupportListNotificationBadge from "../../components/driver-support-components/DriverSupportListNotificationBadge";
import moment from "moment";
import SearchInput from "../../components/input/SearchInput";
import { useSendbirdSdkAccessor } from "../../hooks/driver-support.hooks";
import PageHeader from "components/header/PageHeader";
import SendbirdDataProvider from "wrappers/SendBirdProvider";
import { useAuthStatus } from "hooks/auth.hooks";

export default function DriverSupportPage() {
  const history = useHistory();
  const { user } = useAuthStatus();
  const [searchInputValue, setSearchInputValue] = useState("");
  const [channelCount, setChannelCount] = useState(0);

  const { isSenbirdSdkAvailable, sdkInstance } = useSendbirdSdkAccessor();

  const getUserMessageRoomUrl = useCallback(async () => {
    const query = sdkInstance?.groupChannel?.createMyGroupChannelListQuery();
    //query2.listQuery.userIdsExactFilter = ["a77216e9-7e2e-4f69-a9d3-00ecb0eb972c"]; // Filter by user IDs
    // await query2.next();
    if (!query) {
      return;
    }

    query.includeEmpty = true;
    const room = await query.next();

    if (room) {
      setChannelCount(room.length);
    }
  }, [sdkInstance]);

  useEffect(() => {
    if (isSenbirdSdkAvailable) {
      getUserMessageRoomUrl();
    }
  }, [getUserMessageRoomUrl, isSenbirdSdkAvailable]);

  const handleSearchInputTyped = useCallback((event) => {
    setSearchInputValue(event.target.value);
  }, []);

  if (!user || !isSenbirdSdkAvailable) {
    return (
      <Content>
        <Skeleton count={20} height={20} />
      </Content>
    );
  }

  const CustomChannelPreview = ({ channel, onClick }) => {
    let driver = undefined;
    channel?.members.forEach((m) => {
      if (m.userId !== user.id) {
        driver = m;
      }
    });

    const driverNameDisplay = driver?.nickname?.split(" ")[0];

    const unreadMessageCount = channel.unreadMessageCount;

    const lastMessage = channel?.lastMessage;
    const message = lastMessage?.message;

    const updatedAt = lastMessage?.createdAt;
    const generatedAt = channel?.createdAt;
    const dateWillShown = updatedAt ? new Date(updatedAt) : new Date(generatedAt);

    const timeDisplay = moment(dateWillShown).fromNow();

    if (sdkInstance) {
      return (
        <div onClick={onClick} className="border-bottom px-2 py-1 h-65px d-flex align-center channel-preview-list-item">
          <Row className="d-flex flex-1  justify-between align-center w-100 ">
            <Row
              className="flex-nowrap"
              style={{
                maxWidth: "45%",
              }}
            >
              <div className="user-avatar">
                <span>{driver?.nickname?.charAt(0).toUpperCase()}</span>
              </div>
              <div className="ml-2 w-max-100 flex-column align-start justify-center">
                <span
                  className={"fs-13px  text-truncate"}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                    display: "inline-block",
                  }}
                >
                  {driverNameDisplay}
                </span>
                <span className="sub-text text-truncate">{message}</span>
              </div>
            </Row>

            <div className="d-flex flex-1 flex-column align-end ">
              <span className="sub-text mb-1 d-flex flex-1">{timeDisplay}</span>
              <DriverSupportListNotificationBadge unreadMessageCount={unreadMessageCount} />
            </div>
          </Row>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Content>
      <Head title={"Messages"}></Head>
      <PageHeader
        title="Messages"
        description="  Your hub for direct, real-time communication with your EV fleet drivers. Manage your EV fleet more
          efficiently."
      />

      <Row className="m-0 justify-between my-2">
        <SearchInput onChange={handleSearchInputTyped} placeholder={"Search here..."} />
      </Row>

      {sdkInstance && (
        <div className="sendbird-channel-list">
          <ChannelList
            renderPlaceHolderLoading={() => <></>}
            renderHeader={() => (
              <>
                {user ? (
                  <div className="user-card sm border-bottom p-2 mb-2 d-flex ">
                    <div className="user-info">
                      <span className="lead-text">{user?.businessName}</span>
                      <span className="sub-text"> Total Drivers: {channelCount}</span>
                    </div>
                  </div>
                ) : (
                  <Skeleton count={3} />
                )}
              </>
            )}
            disableAutoSelect
            onChannelSelect={(channel) => {
              if (channel && channel.url) {
                history.push(`driver-support/message-room/${channel.url}`);
              }
            }}
            queries={{
              channelListQuery: {
                includeEmpty: true,
                nicknameContainsFilter: searchInputValue,
              },
            }}
            renderChannelPreview={CustomChannelPreview}
          />
        </div>
      )}
    </Content>
  );
}

/**
 *       <Channel channelUrl={currentChannelUrl} />
 */
