import FilterSortingSelector from "components/filter/FilterSortingSelector";
import { SortDirection, SortableFields } from "types/filter.types";
import { getSortingOption } from "utils/filter.utils";

const sortOptions = [
  getSortingOption(SortableFields.Date, SortDirection.DESC),
  getSortingOption(SortableFields.Date, SortDirection.ASC),
  getSortingOption(SortableFields.DriverName, SortDirection.ASC),
  getSortingOption(SortableFields.DriverName, SortDirection.DESC),
];

function DriverSortingSelector() {
  return <FilterSortingSelector sortOptions={sortOptions} />;
}

export default DriverSortingSelector;
