import { useCallback, useMemo, useState } from "react";

import DataTable, { TableColumn } from "react-data-table-component";
import LoadingSkeletonForPaginatedTable from "components/loading/LoadingSkeletonForPaginatedTable";

import InvoiceTablePlaceholder from "../InvoiceTablePlaceholder";
import { PlatformInvoiceLineItem } from "types/invoice.types";

import { getPlatformInvoiceTableRowData } from "utils/invoice.utils";
import ClientPaginatedTable from "../../table/ClientPaginatedTable";

export type PlatformInvoiceLineItemsTableColumn = TableColumn<PlatformInvoiceLineItem>[];
type Props = {
  readonly lineItems: PlatformInvoiceLineItem[];
  readonly isLoading: boolean;
};

function PlatformInvoiceDetailsTable({ lineItems, isLoading }: Props) {
  const columns = useMemo(
    () => [
      {
        grow: 2,
        name: "Name",
        cell: (row) => (
          <p title={getPlatformInvoiceTableRowData(row).name}>{getPlatformInvoiceTableRowData(row).name ?? "-"}</p>
        ),
      },
      {
        grow: 1,
        name: "Type",
        cell: (row) => (
          <p title={getPlatformInvoiceTableRowData(row).type}>{getPlatformInvoiceTableRowData(row).type ?? "-"}</p>
        ),
      },
      {
        grow: 1,
        name: "Price",
        cell: (row) => (
          <p title={getPlatformInvoiceTableRowData(row).price}>{getPlatformInvoiceTableRowData(row).price ?? "-"}</p>
        ),
      },
    ],
    []
  ) as PlatformInvoiceLineItemsTableColumn;

  return (
    <div className="mt-4 w-100 invoices-table">
      <ClientPaginatedTable
        columns={columns}
        data={lineItems}
        progressPending={isLoading}
        noDataComponent={<InvoiceTablePlaceholder title="No vehicles or drivers!" />}
        progressComponent={
          <div className="w-100 h-100 px-2 d-flex flex-column rounded-xxl align-items-center overflow-hidden">
            <LoadingSkeletonForPaginatedTable count={20} />
          </div>
        }
      />
    </div>
  );
}

export default PlatformInvoiceDetailsTable;
