import AddVehicleButton from "components/button/AddVehicleButton";
import { useDashboardInsights } from "hooks/data/dashboard-data-accessor.hooks";
import { useMemo } from "react";
import DataTable from "react-data-table-component";
import { MostCostlyVehicle } from "types/dashboard.types";
import { TableColumn } from "types/table.types";
import { MostCostlyVehiclesMockPlaceholderItem } from "utils/fleet-groups.utils";
import { getFormattedNumberForUsdByCent } from "../../../utils/format.utils";
import RankingCircleBadge from "../../badge/RankingCircleBadge";
import { DashboardTablePlaceholder } from "../dashboard-placeholders/DashboardTablePlaceholder";

export default function DashboardMostCostlyVehiclesTable() {
  const { insights } = useDashboardInsights();
  const mostCostlyVehicles = useMemo(()=>{
    return insights?.mostCostlyVehicles ?? [];
  },[insights?.mostCostlyVehicles])

  const filledVehiclesData = useMemo(() => {
    const mostCostlyVehiclesLength = mostCostlyVehicles?.length ?? 0;

    // fill the vehicles by mock data placeholder the rest if the vehicles count is less than 3
    if (mostCostlyVehiclesLength > 0 && mostCostlyVehiclesLength < 3) {
      const placeholderCount = 3 - mostCostlyVehiclesLength;

      const newGroupsData = Array.from(mostCostlyVehicles);

      for (let i = 0; i < placeholderCount; i++) {
        newGroupsData.push(MostCostlyVehiclesMockPlaceholderItem);
      }

      return newGroupsData;
    }

    return mostCostlyVehicles;
  }, [mostCostlyVehicles]);

  const columns = useMemo(
    () => [
      {
        grow: 1.5,
        name: "Car name",
        id: "racing-badge-container",
        selector: (row, ix) => {
          return (
            <div className="d-flex justify-start align-items-center">
              <RankingCircleBadge rank={ix + 1} iExtensionVisible />
              {!row?.isPlaceholder && (
                <p title={row.vehicle?.name} className="flex-lg-grow-1 mb-0 ml-1 text-truncate">
                  {row.vehicle?.name ?? "-"}
                </p>
              )}
            </div>
          );
        },
      },

      {
        grow: 1,
        name: "Model",
        selector: (row) => <p title={row?.vehicle?.model}>{row?.vehicle?.model}</p>,
      },

      {
        grow: 1,
        name: "Total Cost",
        selector: (row) => {
          const calculatedCost = getFormattedNumberForUsdByCent(row?.cost);
          return <p title={calculatedCost}>{calculatedCost}</p>;
        },
      },
      {
        grow: 1,
        name: "%",
        selector: (row) => row?.percentage?.toFixed(2) ?? 0,
      },
    ],
    []
  ) as TableColumn<MostCostlyVehicle>[];

  if (!filledVehiclesData || filledVehiclesData?.length < 1) {
    return <DashboardTablePlaceholder button={<AddVehicleButton />} />;
  }

  return (
    <div className="d-flex flex-1 w-100 flex-column racing-badge-container">
      <DataTable
        className="d-lg-block d-flex flex-1 rounded-xxl"
        columns={columns}
        data={filledVehiclesData}
        pagination={false}
        highlightOnHover
        conditionalRowStyles={[
          {
            when: (row) => !!row?.isPlaceholder,
            style: {
              opacity: 0.5,
            },
          },
        ]}
        responsive
      />
    </div>
  );
}
