import React from "react";

const ArchiveOutlineIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.8125 5.34375V14.3438C2.81361 14.7164 2.96213 15.0734 3.22561 15.3369C3.48909 15.6004 3.84613 15.7489 4.21875 15.75H13.7812C14.1539 15.7489 14.5109 15.6004 14.7744 15.3369C15.0379 15.0734 15.1864 14.7164 15.1875 14.3438V5.34375"
        stroke="black" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.3281 2.25H2.67188C2.12822 2.25 1.6875 2.69072 1.6875 3.23438V4.07812C1.6875 4.62178 2.12822 5.0625 2.67188 5.0625H15.3281C15.8718 5.0625 16.3125 4.62178 16.3125 4.07812V3.23438C16.3125 2.69072 15.8718 2.25 15.3281 2.25Z"
        stroke="black" strokeWidth="1.25" strokeLinejoin="round" />
      <path d="M11.25 10.6875L9 12.9375L6.75 10.6875M9 12.1602V7.875" stroke="black" strokeWidth="1.25"
            strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default ArchiveOutlineIcon;