import React from "react";
import { DriverSelfSignupStep } from "../../types/driver.types";
import { getDriverSelfSignupStepsContent } from "../../utils/driver.utils";
import Row from "components/custom/Row";
import { NeutralTitleMediumText100, NeutralTitleMediumText300 } from "../text/Text";
import Card from "../card/Card";


type Props = {
  step: DriverSelfSignupStep
}


const DriverSelfSignupStepsHeader = ({ step }: Props) => {
  const { label, isOptional, stepText, ix, isStepIndexVisible } = getDriverSelfSignupStepsContent(step);
  return (

    <Card className="py-2 px-3 rounded-xxl bg-bd-blue-100 mb-4">

      <Row className="w-100 d-flex justify-between align-items-center">
        <Row>
          {isStepIndexVisible && <NeutralTitleMediumText300 className={"mr-1"}>Step {ix}:</NeutralTitleMediumText300>}
          <NeutralTitleMediumText100 className={"mr-1"}>{label}</NeutralTitleMediumText100>
          {isOptional && (<NeutralTitleMediumText300>(Optional)</NeutralTitleMediumText300>)}
        </Row>
        <NeutralTitleMediumText300>{stepText}</NeutralTitleMediumText300>
      </Row>

    </Card>

  );
};

export default DriverSelfSignupStepsHeader;