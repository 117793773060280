import React, { useMemo } from "react";
import { FormGroup } from "reactstrap";

import { RSelect } from "components/Component";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import HorizontalLine from "components/line/HorizontalLine";
import ReactSelectWithCustomFooter from "components/select/ReactSelectWithCustomFooter";
import { dialCodesWithLabels } from "consts/phone.consts";
import { mapFleetGroupsForSelector } from "utils/fleet-groups.utils";

import InputControllerWithValidationError from "components/form/InputControllerWithValidationError";
import { BlackBody2Text200, BlackBodyText100, BlackSubtitleText100 } from "components/text/Text";
import { useFleetGroupsAsync } from "hooks/data/fleet-group-data-accessor.hooks";
import { DriverFormFleetGroupSelectorState, DriverFormStepsCommonProps } from "types/driver.types";

import { useTranslation } from "react-i18next";
import { SelectorStringOption } from "types/editor.types";

type Props = DriverFormStepsCommonProps & {
  selectedDialCode: SelectorStringOption;
  setSelectedDialCode: React.Dispatch<React.SetStateAction<SelectorStringOption>>;
  fleetGroupSelector: DriverFormFleetGroupSelectorState;
  setFleetGroupSelector: React.Dispatch<React.SetStateAction<DriverFormFleetGroupSelectorState>>;
};

export default function DriverFormPersonalInformationStep({
  control,
  register,
  errors,
  setSelectedDialCode,
  selectedDialCode,
  fleetGroupSelector,
  setFleetGroupSelector,
  isEditMode,
}: Props) {
  const { t } = useTranslation("common");
  const { fleetGroups } = useFleetGroupsAsync();

  const fleetGroupOptions = useMemo(() => {
    return mapFleetGroupsForSelector(fleetGroups) ?? [];
  }, [fleetGroups]);

  /**
   *
   */
  function handleDialCodeChange(data) {
    setSelectedDialCode(data);
  }

  /**
   *
   */
  const handleFleetGroupsChanged = (data: SelectorStringOption[]) => {
    setFleetGroupSelector((s) => {
      return { ...s, data: data };
    });
  };

  const handleFleetGroupsSelectAllPressed = () => {
    setFleetGroupSelector((s) => {
      return { ...s, data: fleetGroupOptions };
    });
  };

  const handleFleetGroupsSelectClearPressed = () => {
    setFleetGroupSelector((s) => {
      return { ...s, data: [] };
    });
  };

  return (
    <div className="d-flex flex-column w-100  p-0">
      {isEditMode ? (
        <>
          <BlackBodyText100>Personal information</BlackBodyText100>
          <BlackBody2Text200>
            The following details cannot be changed once submitted. If adjustments are necessary, reach out to customer
            support for help.
          </BlackBody2Text200>
          <BlackBody2Text200> &#x2022; Full name</BlackBody2Text200>
          <BlackBody2Text200> &#x2022; Email address</BlackBody2Text200>
        </>
      ) : (
        <>
          <Row className="align-items-center mb-1">
            <BlackSubtitleText100>Personal Information</BlackSubtitleText100>
          </Row>

          <HorizontalLine />

          <Row className="mt-2">
            <Col md="6" className="pr-md-2">
              <InputControllerWithValidationError
                name="firstName"
                placeholder={t("driver.common.form.add_driver_form.first_name") ?? ""}
                formLabel={t("driver.common.form.add_driver_form.first_name") ?? ""}
                errors={errors}
                errorMessage="First Name is required"
                register={register}
                control={control}
              />
            </Col>
            <Col md="6" className="pl-md-2 mt-3 mt-md-0">
              <InputControllerWithValidationError
                name="lastName"
                placeholder={t("driver.common.form.add_driver_form.last_name") ?? ""}
                formLabel={t("driver.common.form.add_driver_form.last_name") ?? ""}
                errors={errors}
                errorMessage="Last name is required."
                register={register}
                control={control}
              />
            </Col>
          </Row>

          <Col className="mt-3">
            <InputControllerWithValidationError
              name="email"
              type="email"
              placeholder={t("general.email") ?? ""}
              formLabel={t("driver.common.form.add_driver_form.email") ?? ""}
              errors={errors}
              control={control}
              registerRef={register({
                required: "Email address is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Email address is not valid",
                },
              })}
            />
          </Col>
        </>
      )}

      <Col className="mt-4">
        <BlackBodyText100 className="mb-1">{t("driver.common.form.add_driver_form.phoneNumber")}</BlackBodyText100>
        <Row>
          <div className="w-100px mr-2">
            <RSelect
              // inputRef={register({ required: "required" })}
              //isOptionDisabled={() => true}
              //defaultValue={selectedDialCode.dial_code}

              value={selectedDialCode}
              name="phoneNumberDialCode"
              options={dialCodesWithLabels}
              onChange={(data) => handleDialCodeChange(data)}
              control={control}
              placeholder={"US +1"}
              menuPosition="fixed"
              menuPortalTarget={document.querySelector(".modal-content")}
              styles={{
                menuList: (styles) => {
                  return {
                    ...styles,
                    maxHeight: 200,
                  };
                },
              }}
            />
          </div>
          <Col className=" w-100">
            <InputControllerWithValidationError
              inputMode="tel"
              type="text"
              name="phoneNumber"
              placeholder={t("driver.common.form.add_driver_form.phoneNumber") ?? ""}
              registerRef={register({
                required: "Phone number is required",
                pattern: {
                  value: /^\s*[0-9]{10}\s*$/,
                  message: "Phone number is not valid",
                },
              })}
              errors={errors}
              control={control}
            />
          </Col>
        </Row>
      </Col>
      <Col className="mt-4">
        <FormGroup>
          <BlackBodyText100 className="mb-1">
            {t("driver.common.form.add_driver_form.fleet_group.label")}
          </BlackBodyText100>
          <ReactSelectWithCustomFooter
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 999999 }),
              menuList: (styles) => {
                return {
                  ...styles,
                  maxHeight: 180,
                };
              },
            }}
            menuPosition="fixed"
            menuPortalTarget={document.querySelector(".modal-content")}
            // inputRef={register({ required: "required" })}
            //defaultValue={vehicles.data}
            noOptionsMessage={() => "No fleet groups"}
            name={"fleet_groups"}
            placeholder={t("driver.common.form.add_driver_form.fleet_group.placeholder")}
            isMulti
            onSelectAllPressed={handleFleetGroupsSelectAllPressed}
            onSelectClearPressed={handleFleetGroupsSelectClearPressed}
            options={fleetGroupOptions}
            value={fleetGroupSelector.data}
            onChange={(data) => handleFleetGroupsChanged(data)}
          />
        </FormGroup>
      </Col>
    </div>
  );
}
