import React from "react";
import ButtonWithIcon from "./ButtonWithIcon";

type Props = {
  readonly onClick: () => void;
  readonly isLoading?: boolean;
};

function ArchiveButton({ onClick, isLoading }: Props) {
  return (
    <ButtonWithIcon
      className="w-175px mt-md-0"
      text={"Archive"}
      onClick={onClick}
      isLoading={isLoading}
      isDisabled={isLoading}
    />
  );
}

export default ArchiveButton;
