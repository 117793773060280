import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import VehiclesButton from "../../button/VehiclesButton";
import FormCompletedContent from "../../form/FormCompletedContent";
import ThreeDotWithCircleBackgroundIcon from "../../icon/icons/ThreeDotWithCircleBackgroundIcon";
import AddVehicleStarterWarningCard from "./add-vehicle/AddVehicleStarterWarningCard";
import { useAddVehicleCompletedHandlers } from "../../../hooks/vehicle.hooks";

type Props = {
  isTeslaContent: boolean
};

function VehicleFormCompletedContent({ isTeslaContent }: Props) {
  const { t } = useTranslation("common");
  const history = useHistory();

  const { handleResetHelperMessage, helperMessage } = useAddVehicleCompletedHandlers();

  const title = t("vehicle.form.completed_content.title");
  const mockDescription = t("vehicle.form.completed_content.description");
  
  return (
    <FormCompletedContent icon={<ThreeDotWithCircleBackgroundIcon fill="#23D206" />}>
      <>
        <FormCompletedContent.Title>{title}</FormCompletedContent.Title>

        <FormCompletedContent.Description>{helperMessage ?? mockDescription}</FormCompletedContent.Description>

        {isTeslaContent && <div className="w-100 px-4">
          <AddVehicleStarterWarningCard />
        </div>}


        <FormCompletedContent.Buttons
          buttons={[<VehiclesButton onVehiclesClicked={() => {
            handleResetHelperMessage();
            history.replace("/vehicles");
          }
          } />]}
        />
      </>
    </FormCompletedContent>
  );
}

export default VehicleFormCompletedContent;
