import React, { useCallback } from "react";
import LocationIcon from "../../icon/icons/LocationIcon";
import { useGoogleReverseGeocoding } from "../../../hooks/google-maps.hooks";
import { VehicleTelemetry } from "../../../types/vehicle.types";
import LoadingSkeleton from "../../loading/LoadingSkeleton";
import UnderlinedTextButton from "../../button/UnderlinedTextButton";
import { TextButtonMode } from "../../button/TextButton";

type Props = {
  isLoading: boolean
  telemetry?: VehicleTelemetry
}

function VehicleDetailsLocationInfo({ isLoading, telemetry }: Props) {

  const { location, link, isLocationLoading } = useGoogleReverseGeocoding(telemetry?.location?.data);

  const handleLocationAddressClicked = useCallback(() => {
    window.open(link);
  }, [link]);


  if (isLoading || isLocationLoading) {
    return <LoadingSkeleton count={1} className={"py-1"} />;
  }

  return (
    <>
      {location && (
        <div className="align-items-center flex-nowrap d-inline-flex">
          <LocationIcon />
          <UnderlinedTextButton mode={TextButtonMode.PRIMARY} text={location} className="pl-2  clickable" onClick={handleLocationAddressClicked} />


          {/*<VehicleDetailsFetchedInfoTooltip fetchedAt={telemetry?.location?.meta?.fetchedAt} />*/}
        </div>
      )}
    </>
  );
}

export default VehicleDetailsLocationInfo;