import FormButton from "./FormButton";

type Props = {
  readonly onSaveNewPasswordButtonPressed?: () => void;
  readonly className?: string;
  readonly type?: string;
  readonly isLoading?: boolean;
  readonly isDisabled?: boolean;
};

function SaveNewPasswordButton({ onSaveNewPasswordButtonPressed, ...props }: Props) {
  return <FormButton text={"Save new password"} onFormButtonPressed={onSaveNewPasswordButtonPressed} {...props} />;
}

export default SaveNewPasswordButton;
