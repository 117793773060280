import React from "react";

import Row from "../custom/Row";
import Col from "../custom/Col";
import { BlackBodyText200, BlackH5Text100 } from "../text/Text";

type Props = {
  readonly title?: string | null;
  readonly description?: string | null;
  readonly overrideDescription?: JSX.Element;
  readonly rightElement?: JSX.Element;
};

export default function PageHeader({ title, description, overrideDescription, rightElement }: Props) {
  return (
    <Row className="flex-wrap align-center justify-between my-2">
      <Col md={`${rightElement ? "6" : "12"}`}>
        <BlackH5Text100>{title}</BlackH5Text100>
        {overrideDescription ?? <BlackBodyText200 className="mt-1">{description}</BlackBodyText200>}
      </Col>
      {rightElement && <div className="mt-2 mt-md-0">{rightElement}</div>}
    </Row>
  );
}
