import branchIO from "branch-sdk";
import { getDialCodeForSelectorByCountryDialCode } from "consts/phone.consts";
import { COUNTRIES, getStateCode, getStateKeyByCode } from "consts/state.consts";
import parsePhoneNumberFromString from "libphonenumber-js";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { addVehicleDriverScheduleAsync } from "services/vehicle-driver-schedule.services";
import {
  Address,
  Country,
  Driver,
  DriverFormAddressSelectorKey,
  DriverFormAddressSelectorState,
  DriverFormFleetGroupSelectorState,
  DriverFormVehicleSelectorState,
  DriverHookStates,
  DriverLocationPayload,
  DriverUpdateCommand
} from "types/driver.types";

import { SelectorStringOption } from "types/editor.types";
import { VehicleDriverScheduleAddPayload } from "types/vehicle-driver-schedule.types";
import { mapFleetGroupsForSelector } from "utils/fleet-groups.utils";
import { getVehicleDriverSchedulePeriodsForPayload } from "utils/vehicle-driver-schedule.utils";
import { useAuthStatus } from "./auth.hooks";
import { useVehicleDriverSchedulePeriodSelector } from "./vehicle-driver-schedule.hooks";
import { LatLng, ReimbursementRectangleBound } from "types/reimbursement.types";
import { useDriverAsync, useDriverSignupAsync } from "./data/driver-data-accessor.hooks";
import {
  calculateZoomForRectangle,
  getDriverFullAddress,
  getDriverReimbursementLocationFromLocations,
  getFormattedCentByUsdString
} from "../utils/driver.utils";
import { useGoogleApiLoader } from "./google-maps.hooks";
import { updateDriverAsync } from "../services/driver.services";
import {
  calculatePolygonCenter,
  getFormattedReimbursementLocation,
  getRectangleBoundsAndCenter
} from "../utils/reimbursement.utils";
import { useDebounce } from "./vehicle.hooks";
import { VehicleFormVehicleSelectorState } from "../types/vehicle.types";

export const useDriverFormAddressSelector = () => {
  const { t } = useTranslation("common");
  const initialAddressSelectors = useMemo(() => {
    return {
      state: {
        isRequired: true,
        error: undefined,
        data: undefined,
        errorMessage: t("fleet_management.create_modal.state_form_error")
      },
      city: {
        isRequired: true,
        error: undefined,
        data: undefined,
        errorMessage: t("fleet_management.create_modal.city_form_error")
      },
      country: {
        isRequired: true,
        error: undefined,
        data: COUNTRIES.US,
        errorMessage: t("fleet_management.create_modal.country_form_error")
      }
    };
  }, [t]);

  const [addressSelectors, setAddressSelectors] = useState<DriverFormAddressSelectorState>(initialAddressSelectors);

  /**
   *
   */
  const handleSetAddressSelectorByKey = useCallback(
    (key: DriverFormAddressSelectorKey, data: SelectorStringOption) => {
      // If state is changed, city should be reset.
      const isStateChanged = key === DriverFormAddressSelectorKey.STATE;
      const isCountryChanged = key === DriverFormAddressSelectorKey.COUNTRY;
      console.log("is country changed", isCountryChanged);
      setAddressSelectors((s) => {
        return {
          ...s,
          city: (isCountryChanged || isStateChanged) ? { ...s.city, data: undefined } : s.city,
          state: isCountryChanged ? { ...s.state, data: undefined } : s.state,
          [key]: { ...addressSelectors[key], data, error: undefined }
        };
      });
    },
    [addressSelectors, setAddressSelectors]
  );


  const validateAddressSelectors = useCallback(() => {
    let errorCount = 0;

    const newSelectorState = { ...addressSelectors };
    Object.keys(addressSelectors).forEach((selector) => {
      const iteratedSelectorData = addressSelectors[selector];
      const { isRequired, data, errorMessage } = iteratedSelectorData;

      if (isRequired) {
        const isDataSelected = !!data;

        const newErrorStatus = isDataSelected ? undefined : errorMessage;

        if (!isDataSelected) {
          errorCount++;
        }

        if (newErrorStatus !== iteratedSelectorData.error) {
          newSelectorState[selector] = { ...iteratedSelectorData, error: newErrorStatus };
        }
      }
    });

    setAddressSelectors(newSelectorState);

    return errorCount === 0;
  }, [addressSelectors, setAddressSelectors]);

  const handleInitAddressStatesForEditForm = useCallback(
    (driver: Driver) => {
      const address = driver.address;
      const country = address.country;
      const stateCode = address?.state;
      const city = address?.city;

      const state = getStateKeyByCode(country, stateCode);

      if (state && city) {
        setAddressSelectors((s) => {
          return {
            ...s,
            state: {
              ...s.state,
              data: { label: state, value: state }
            },
            city: {
              ...s.city,
              data: {
                label: city,
                value: city
              }
            }
          };
        });
      }
    },
    [setAddressSelectors]
  );

  return {
    city: addressSelectors.city.data?.value,
    state: addressSelectors.state.data?.value,
    stateCode: getStateCode(addressSelectors.country.data.value as Country, addressSelectors.state.data?.value ?? ""),
    addressSelectors,
    setAddressSelectors: handleSetAddressSelectorByKey,
    initialAddressSelectors,
    validateAddressSelectors,
    handleInitAddressStatesForEditForm
  };
};

export const useDriverFormFleetGroupSelector = () => {
  const initialFleetGroupsState = useMemo(() => {
    return {
      isRequired: false,
      error: undefined,
      data: [],
      errorMessage: ""
    };
  }, []);

  const [fleetGroupSelector, setFleetGroupSelector] =
    useState<DriverFormFleetGroupSelectorState>(initialFleetGroupsState);

  const groupIds = fleetGroupSelector.data.map((g) => g.value);
  return { fleetGroupSelector, setFleetGroupSelector, groupIds };
};

/**
 *
 * export const useDriverFormVehicleAssignmentPeriodStates = () => {
 const [startDate, setStartDate] = useState(new Date());
 const [endDate, setEndDate] = useState<Date | undefined>();

 return {
 startDate,
 setStartDate,
 endDate,
 setEndDate,
 };
 };
 */

export const useDriverFormPersonalInformationStepStates = () => {
  const [selectedDialCode, setSelectedDialCode] = useState<SelectorStringOption>({
    value: "+1",
    label: "US +1"
  });

  const driverFormFleetGroupSelector = useDriverFormFleetGroupSelector();
  const { setFleetGroupSelector } = driverFormFleetGroupSelector;

  const handleInitPersonalInformationStatesForEditForm = useCallback(
    (driver: Driver) => {
      const dialCode = "+" + parsePhoneNumberFromString(driver.phoneNumber)?.countryCallingCode;
      setSelectedDialCode(getDialCodeForSelectorByCountryDialCode(dialCode));
      setFleetGroupSelector((s) => {
        return { ...s, data: mapFleetGroupsForSelector(driver.groups ?? []) };
      });
    },
    [setFleetGroupSelector]
  );

  const dialCode = selectedDialCode.value;
  return {
    ...driverFormFleetGroupSelector,
    selectedDialCode,
    setSelectedDialCode,
    dialCode,
    handleInitPersonalInformationStatesForEditForm
  };
};

export const useDriverFormAddressSetupStepStates = () => {
  return { ...useDriverFormAddressSelector() };
};

export const useDriverFormVehicleSelector = () => {
  const [vehicleSelector, setVehicleSelector] = useState<DriverFormVehicleSelectorState | undefined>(undefined);

  const vehicleId = vehicleSelector?.value;
  const isVehicleSelected = !!vehicleId;

  const handleSetVehicleSelector = setVehicleSelector;

  const handleResetState = useCallback(() => {
    setVehicleSelector(undefined);
  }, []);

  return {
    vehicleId,
    isVehicleSelected,
    vehicleSelector,
    handleSetVehicleSelector,
    handleResetState
  };
};

export const useDriverFormsReimbursementStepFormSubmitHandlers = (boundsToSubmit?: ReimbursementRectangleBound) => {
  const handleSubmitEnableReimbursementCallback = useCallback(async (driverId?: string, fixedRate?: string) => {
    if (!driverId || !fixedRate) {
      throw Error("Driver id or fixed rate is missing");
      return;
    }

    if (!boundsToSubmit) {
      throw Error("Bounds must be selected");
    }


    return updateDriverAsync(driverId, {
      command: DriverUpdateCommand.ENABLE_CHARGING_REIMBURSEMENT,
      fixedEnergyPrice: getFormattedCentByUsdString(fixedRate),
      location: getFormattedReimbursementLocation(boundsToSubmit)
    });
  }, [boundsToSubmit]);


  const handleSubmitDisableReimbursementCallback = useCallback(async (driverId?: string) => {
    if (!driverId) {
      throw Error("Driver id  is missing");
    }

    return updateDriverAsync(driverId, {
      command: DriverUpdateCommand.DISABLE_CHARGING_REIMBURSEMENT
    });
  }, []);


  const handleSubmitUpdateReimbursementLocationCallback = useCallback(async (driverId?: string) => {
    if (!driverId) {
      throw Error("Driver id is missing");
    }

    if (!boundsToSubmit) {
      throw Error("Bounds are missing");
    }

    return updateDriverAsync(driverId, {
      command: DriverUpdateCommand.UPDATE_CHARGING_REIMBURSEMENT_LOCATION,
      location: getFormattedReimbursementLocation(boundsToSubmit)
    });
  }, [boundsToSubmit]);


  const handleSubmitUpdateReimbursementFixedEnergyPriceCallback = useCallback(async (driverId?: string, fixedRate?: string) => {
    if (!driverId || !fixedRate) {
      throw Error("Driver id or fixed rate is missing");
    }


    return updateDriverAsync(driverId, {
      command: DriverUpdateCommand.UPDATE_CHARGING_REIMBURSEMENT_FIXED_ENERGY_PRICE,
      fixedEnergyPrice: getFormattedCentByUsdString(fixedRate)
    });
  }, []);


  return {
    handleSubmitEnableReimbursementCallback,
    handleSubmitDisableReimbursementCallback,
    handleSubmitUpdateReimbursementLocationCallback,
    handleSubmitUpdateReimbursementFixedEnergyPriceCallback
  };

};


export const useDriverFormsReimbursementStepStates = (savedAddress?: Address, isLoading?: boolean, location?: DriverLocationPayload | null) => {
  const { isLoaded: isGoogleApiLoaded } = useGoogleApiLoader();
  const [isReimbursementActive, setIsReimbursementActive] = useState(false);

  const [zoom, setZoom] = useState(20);


  const [newInitialAddress, setNewInitialAddress] = useState("");


  const [address, setAddress] = useState<string>("");
  const [center, setCenter] = useState<LatLng | undefined>();
  const [bounds, setBounds] = useState<ReimbursementRectangleBound | undefined>();

  const [boundsToSubmit, setBoundsToSubmit] = useState<ReimbursementRectangleBound | undefined>(bounds);

  const debouncedBoundsValueToGetAddress = useDebounce(boundsToSubmit, 1000);


  const handleSetInitialCenterAndBoundsByAddress = useCallback(async (address: string) => {
    const geocoder = new google.maps.Geocoder();
    const radius = 0.00009;

    return new Promise<{ center: LatLng, bounds: ReimbursementRectangleBound }>((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        if (status === "OK" && results && results[0]) {
          const location = results[0].geometry.location;
          const center = { lat: location.lat(), lng: location.lng() };


          const bounds = {
            north: center.lat + radius,
            south: center.lat - radius,
            east: center.lng + radius,
            west: center.lng - radius
          };

          setCenter(center);
          setBoundsToSubmit(bounds);
          setBounds(bounds);

          resolve({ center, bounds });
        } else {
          reject(status);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (isLoading || !isGoogleApiLoaded) return;

    if (location) {
      return;
    }


    setNewInitialAddress(getDriverFullAddress(savedAddress));
  }, [isGoogleApiLoaded, isLoading, location, savedAddress]);


  useEffect(() => {
    if (isLoading || !isGoogleApiLoaded) return;

    if (!location) {
      return;
    }

    const boundsAndCenter = getRectangleBoundsAndCenter(location.polygon.coordinates);

    if (!boundsAndCenter?.bounds) {
      return;
    }
    setZoom(calculateZoomForRectangle(boundsAndCenter.bounds));
  }, [isGoogleApiLoaded, isLoading, location, savedAddress]);


  useEffect(() => {
    if (isLoading || !isGoogleApiLoaded) return;


    if (location) {
      const rectangleBoundsAndCenter = getRectangleBoundsAndCenter(location.polygon.coordinates);
      if (!rectangleBoundsAndCenter) {
        return;
      }


      const { center, bounds } = rectangleBoundsAndCenter;

      setCenter(center);
      setBoundsToSubmit(bounds);
      setBounds(bounds);
      return;
    }


    if (savedAddress) {
      handleSetInitialCenterAndBoundsByAddress(getDriverFullAddress(savedAddress));
    }

  }, [savedAddress, handleSetInitialCenterAndBoundsByAddress, isGoogleApiLoaded, isLoading, location]);


  useEffect(() => {
    if (newInitialAddress) {
      handleSetInitialCenterAndBoundsByAddress(newInitialAddress);
    }
  }, [handleSetInitialCenterAndBoundsByAddress, newInitialAddress]);


  const handleSetGeocodeAddressFromLatLng = useCallback(async (lat: number, lng: number) => {
    const geocoder = new google.maps.Geocoder();
    const latLng = new google.maps.LatLng(lat, lng);

    return new Promise((resolve, reject) => {
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK" && results && results[0]) {
          const formattedAddress = results[0].formatted_address;
          setAddress(formattedAddress);

          const input = document.getElementById("google-autocomplete-address") as HTMLInputElement;
          if (input) {
            input.value = formattedAddress;
          }
        } else {
          reject(status);
        }
      });
    });
  }, [setAddress]);


  useEffect(() => {
    if (!debouncedBoundsValueToGetAddress) {
      return;
    }


    const rectangleCenter = calculatePolygonCenter([
      { lat: debouncedBoundsValueToGetAddress.north, lng: debouncedBoundsValueToGetAddress.east },
      { lat: debouncedBoundsValueToGetAddress.north, lng: debouncedBoundsValueToGetAddress.west },
      { lat: debouncedBoundsValueToGetAddress.south, lng: debouncedBoundsValueToGetAddress.east },
      { lat: debouncedBoundsValueToGetAddress.south, lng: debouncedBoundsValueToGetAddress.west }
    ]);

    handleSetGeocodeAddressFromLatLng(rectangleCenter.lat, rectangleCenter.lng);
  }, [setCenter, debouncedBoundsValueToGetAddress, handleSetGeocodeAddressFromLatLng]);


  useEffect(() => {
    setBoundsToSubmit(bounds);
  }, [bounds]);


  return {
    bounds,
    setBounds,
    center,
    address,
    isReimbursementActive,
    setIsReimbursementActive,
    setBoundsToSubmit,
    isLoading,
    isGoogleApiLoaded,
    setNewInitialAddress,
    newInitialAddress,
    zoom,
    ...useDriverFormsReimbursementStepFormSubmitHandlers(boundsToSubmit)
  };
};

export const useDriverFormReimbursementStepStates = (driverId_?: string) => {
  const { driver, isLoading } = useDriverAsync(driverId_);
  return { ...useDriverFormsReimbursementStepStates(driver?.address, isLoading) };
};


export const useDriverDetailsReimbursementStates = (driverHook: DriverHookStates) => {
  const { driver, isLoading } = driverHook;
  return {
    ...useDriverFormsReimbursementStepStates(driver?.address, isLoading, getDriverReimbursementLocationFromLocations(driverHook))
  };
};

export const useDriverSelfSignupFormReimbursementStepStates = () => {
  const { driverSignup, isLoading } = useDriverSignupAsync();
  return { ...useDriverFormsReimbursementStepStates(driverSignup?.address, isLoading) };
};

export const useDriverFormVehicleSetupStepStates = () => {
  const driverFormVehicleSelectorHooks = useDriverFormVehicleSelector();
  const { vehicleId } = driverFormVehicleSelectorHooks;
  const vehicleDriverSchedulePeriodSelectorHooks = useVehicleDriverSchedulePeriodSelector();
  const { vehicleDriverSchedulePeriodSelector } = vehicleDriverSchedulePeriodSelectorHooks;

  const handleSubmitAddScheduleAsync = useCallback(
    async (driverId: string) => {
      if (!vehicleId) {
        throw Error("Please select vehicle");
      }

      const { startsAt, endsAt } = vehicleDriverSchedulePeriodSelector;

      const payload: VehicleDriverScheduleAddPayload = {
        ...getVehicleDriverSchedulePeriodsForPayload(startsAt, endsAt),
        driverId,
        vehicleId
      };

      return addVehicleDriverScheduleAsync(payload);
    },
    [vehicleId, vehicleDriverSchedulePeriodSelector]
  );

  const handleResetState = useCallback(() => {
    driverFormVehicleSelectorHooks.handleResetState();
    vehicleDriverSchedulePeriodSelectorHooks.handleResetState();
  }, [driverFormVehicleSelectorHooks, vehicleDriverSchedulePeriodSelectorHooks]);

  return {
    ...vehicleDriverSchedulePeriodSelectorHooks,
    ...driverFormVehicleSelectorHooks,
    handleSubmitAddScheduleAsync,
    handleResetState
  };
};

export const useBranchIoDriverAppLink = () => {
  const { user } = useAuthStatus();

  const [appLink, setAppLink] = useState<string | null>("");

  useEffect(() => {
    branchIO.init(process.env.REACT_APP_BRANCHIO_PUBLIC_KEY ?? "");
    branchIO.link(
      {
        data: {
          fleetCode: user?.signUpCode.code
        }
      },
      (err, link) => {
        if (err) {
          console.error(err);
          return;
        }
        setAppLink(link);
      }
    );
  }, [user?.signUpCode.code]);

  return { appLink };
};



export const useFormDriverSelector = () => {
  const [driverSelector, setDriverSelector] = useState<SelectorStringOption | undefined>(undefined);

  const driverId = driverSelector?.value;
  const isDriverSelected = !!driverId;

  const handleSetDriverSelector = setDriverSelector;

  const handleResetState = useCallback(() => {
    setDriverSelector(undefined);
  }, []);

  return {
    driverId,
    isDriverSelected,
    driverSelector,
    handleSetDriverSelector,
    handleResetState
  };
};