import OverviewCard from "components/dashboard-components/DashboardOverviewCard";
import { getFormattedNumberForUsdByCent } from "utils/format.utils";
import { ReimbursementOverview } from "../../types/reimbursement.types";

type Props = {
  overview: ReimbursementOverview | undefined
  isLoading: boolean
}

export default function TotalReimbursementCostStatisticCard({ overview, isLoading }: Props) {
  const costOverview = overview?.totalCostOverview;
  return (
    <OverviewCard
      total={getFormattedNumberForUsdByCent(costOverview?.total)}
      percent={costOverview?.monthlyTrend.percentageChange}
      isLoading={isLoading}
      title={"Total Cost"}
    />
  );
}
