// React
import React, { useCallback, useEffect, useState } from "react";

// 3rd party
import { useHistory } from "react-router";

// Hooks & Utils
import { useOnboardingStatus } from "../../../hooks/dashboard.hooks";

// Components
import Card from "../../card/Card";
import Col from "../../custom/Col";
import Row from "../../custom/Row";
import DashboardOnboardingModal from "./DashboardOnboardingModal";
import MiraAvatar from "../../../assets/images/dashboard/MiraAvatar.png";
import { BlackCaptionText300, BlackSubtitleText100 } from "../../text/Text";
import DashboardOnboardingStepCard from "./DashboardOnboardingStepCard";
import XIcon from "../../icon/icons/XIcon";
import AddVehicleImage from "../../../assets/images/dashboard/AddVehicleImage.png";
import AddDriverImage from "../../../assets/images/dashboard/AddDriverImage.png";
import AddGroupsImage from "../../../assets/images/dashboard/AddGroupsImage.png";
import SecondaryStartButton from "../../button/SecondaryStartButton";
import SecondaryAddButton from "../../button/SecondaryAddButton";
import { useTranslation } from "react-i18next";
import { useSyncOrganization } from "hooks/auth.hooks";

type Props = {};

const context = "Dashboard.DashboardOnboarding";
function DashboardOnboarding({}: Props) {
  const history = useHistory();
  const { t } = useTranslation("common");

  useSyncOrganization();

  const {
    isOnboardingActive,
    isOnboardingModalActive,
    isDriverStepCompleted,
    isGroupStepCompleted,
    isVehicleStepCompleted,
  } = useOnboardingStatus();

  console.log(context);

  const [isOnboardingVisible, setIsOnboardingVisible] = useState(isOnboardingActive);

  useEffect(() => {
    setIsOnboardingVisible(isOnboardingActive);
  }, [isOnboardingActive]);

  /**
   *
   */
  const handleAddVehiclePressed = useCallback(() => {
    history.push("/add-vehicle");
  }, [history]);

  /**
   *
   */
  const handleAddDriverPressed = useCallback(() => {
    history.push("/add-driver");
  }, [history]);

  if (!isOnboardingVisible) {
    return <></>;
  }
  return (
    <>
      <Card padding="px-4 pt-4 pb-1" className="mb-4 mt-2 position-relative">
        <div className="absolute-top-right mr-3 mt-2 cursor-pointer" onClick={() => setIsOnboardingVisible(false)}>
          <XIcon />
        </div>
        <Row>
          <img className="rounded-circle w-40px h-40px mr-2" src={MiraAvatar} alt="Mira Avatar" />
          <Col>
            <BlackCaptionText300>{t("dashboard.onboarding.title")}</BlackCaptionText300>
            <BlackSubtitleText100>{t("dashboard.onboarding.description")}</BlackSubtitleText100>
          </Col>
        </Row>
        <Row className="mt-3">
          <DashboardOnboardingStepCard
            paddingClasses="pr-2"
            title={t("dashboard.onboarding.step_card.add_vehicle_step.title")}
            description={t("dashboard.onboarding.step_card.add_vehicle_step.description")}
            stepNumber={1}
            isTickIconChecked={isVehicleStepCompleted}
            hasItBorder
            button={<SecondaryStartButton onSecondaryStartButtonClicked={handleAddVehiclePressed} />}
            image={<img className="h-auto w-300px w-max-100 mt-3" src={AddVehicleImage} alt="Add Vehicle" />}
          />
          <DashboardOnboardingStepCard
            isTickIconChecked={isDriverStepCompleted}
            title={t("dashboard.onboarding.step_card.add_driver_step.title")}
            description={t("dashboard.onboarding.step_card.add_driver_step.description")}
            stepNumber={2}
            hasItBorder
            button={<SecondaryAddButton onSecondaryAddButtonClicked={handleAddDriverPressed} />}
            image={<img className="h-auto w-300px w-max-100 mt-3 " src={AddDriverImage} alt="Add Driver" />}
          />
          <DashboardOnboardingStepCard
            isTickIconChecked={isGroupStepCompleted}
            paddingClasses="px-3 pt-2 pt-lg-0"
            title={t("dashboard.onboarding.step_card.add_fleet_groups_step.title")}
            description={t("dashboard.onboarding.step_card.add_fleet_groups_step.description")}
            stepNumber={3}
            image={<img className="h-auto w-300px w-max-100 mt-2 " src={AddGroupsImage} alt="Add Groups" />}
          />
        </Row>
      </Card>
      {isOnboardingModalActive && <DashboardOnboardingModal />}
    </>
  );
}

export default DashboardOnboarding;
