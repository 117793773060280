import React from "react";
import Card from "../card/Card";
import { CircularProgress } from "@mui/material";
import Row from "../custom/Row";
import { BlackOverlineText200 } from "../text/Text";


function ProcessingCircularBar() {
  return (
    <Card className={"bg-secondary p-1 center my-2"}>
      <Row>
        <CircularProgress size={20} style={{ color: "#6F7981FF" }} />
        <BlackOverlineText200 className={"ml-2"}>Processing...</BlackOverlineText200>
      </Row>
    </Card>
  );
}

export default ProcessingCircularBar;