import React, { useMemo } from "react";
import PaginatedTable from "components/table/PaginatedTable";
import { useTeslaInvoiceListAsync } from "hooks/data/vehicle-data-accessor.hooks";
import { TableColumn } from "react-data-table-component";
import { TeslaChargingWithTeslaInvoice } from "types/vehicle.types";
import TeslaInvoiceTableFilterMenu from "../filter/TeslaInvoiceTableFilterMenu";
import { useChargingSessionCommonTableColumns } from "../../../hooks/charging-session-hooks";
import Row from "../../custom/Row";
import FilterSearchInput from "../../filter/FilterSearchInput";
import TeslaDownloadSingleInvoiceButton from "./TeslaDownloadSingleInvoiceButton";
import TeslaDownloadAllInvoicesButton from "./TeslaDownloadAllInvoicesButton";

type InvoicesTableColumn = TableColumn<TeslaChargingWithTeslaInvoice>[];
export default function TeslaInvoiceTable() {
  const { invoices, isLoading, meta } = useTeslaInvoiceListAsync();
  const commonTableColumns = useChargingSessionCommonTableColumns();

  const columns = useMemo(
    () => [
      {
        style: { padding: 0, paddingLeft: 20 },
        width: "50px",
        name: "",
        selector: (row) => <TeslaDownloadSingleInvoiceButton id={row.id} fullName={row.teslaInvoice.fullName} />
      },
      {
        width: "200px",
        name: "Invoice Name",
        selector: (row) => row.teslaInvoice?.displayName
      },
      commonTableColumns.driver,
      commonTableColumns.state,
      commonTableColumns.vehicle,
      commonTableColumns.source,
      commonTableColumns.chargerName,
      commonTableColumns.type,
      commonTableColumns.energy,
      commonTableColumns.cost,
      commonTableColumns.payStat,
      commonTableColumns.whoPays,
      commonTableColumns.duration,
      commonTableColumns.date
    ],
    [commonTableColumns]
  ) as InvoicesTableColumn;


  return (
    <>
      <Row className="justify-between my-2 align-center">
        <FilterSearchInput placeholder="Search name, vehicle or charger (min 2 char.)" />
        <TeslaDownloadAllInvoicesButton />
      </Row>

      <TeslaInvoiceTableFilterMenu />

      <PaginatedTable
        columns={columns}
        data={invoices}
        paginationTotalRows={meta?.pagination.totalElements}
        isLoading={isLoading}
      />
    </>
  );
}
