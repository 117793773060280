import React from "react";
import VehicleDetailsChargingInsights from "../VehicleDetailsChargingInsights";
import VehicleChargingSessionsTable from "../VehicleChargingSessionsTable";


function VehicleDetailsChargingContent(props) {
  return (
    <div>
      <VehicleDetailsChargingInsights />
      <VehicleChargingSessionsTable />
    </div>
  );
}

export default VehicleDetailsChargingContent;