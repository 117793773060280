import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { User } from '../types/user.types';
import { generateUserHmacForIntercom } from '../utils/auth.utils';


function useIntercomTrigger() {
    const intercom = useIntercom();
    const triggerIntercom = () => {
        intercom.show();
    };

    return { triggerIntercom }
}


function useIntercomBoot(user?: User) {
    const intercom = useIntercom()

    useEffect(() => {
        if (!user) {
            return;
        }

        intercom.boot({
            userId: user.id,
            userHash: generateUserHmacForIntercom(user),
            name: user.name, // user name
            email: user.email, // user email
            createdAt: new Date(user?.createdAt).getTime(), // user SignUp date as Unix timestamp
        });
    }, [intercom, user]);

}

export { useIntercomBoot, useIntercomTrigger };
