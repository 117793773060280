import { AxiosError } from "axios";
import { useAlertManager } from "hooks/alert.hooks";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { setIsVehiclesLoading, setVehiclesAndMeta } from "redux/slices/vehicleSlice";
import { PaginationMeta } from "types/api.types";
import {
  CachedVehicleData,
  TeslaAlerts,
  TeslaChargingWithTeslaInvoice,
  TeslaCommand,
  TeslaDrivePin,
  TeslaError,
  TeslaKeyStatus,
  TeslaVehicleInfo,
  TeslaVehicleStatus,
  Vehicle,
  VehicleChargingDetail,
  VehicleChargingSummary,
  VehicleLocation
} from "types/vehicle.types";
import { getAllPaginatedListByLoopAsync } from "utils/data.utils";
import {
  getTeslaChargingInvoicesAsync,
  getVehicleAsync,
  getVehicleChargingListByIdAsync,
  getVehicleChargingSummaryByIdAsync,
  getVehicleLocationsAsync,
  getVehiclesAsync,
  getVehicleTeslaAlertsAsync,
  getVehicleTeslaDrivePinAsync,
  getVehicleTeslaInfoAsync,
  getVehicleTeslaKeyStatusAsync,
  updateVehicleTeslaAsync
} from "../../services/vehicle.services";
import { useAppDispatch, useAppSelector } from "../redux.hooks";
import { handleApiErrorResponse } from "../../utils";
import { useVehicleIdFromParams } from "../vehicle.hooks";
import qs from "qs";

export const useAllVehiclesAsync = () => {
  const [allVehicles, setAllVehicles] = useState<Vehicle[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllVehiclesAsyncCallback = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getAllPaginatedListByLoopAsync<Vehicle>(getVehiclesAsync);
      setAllVehicles(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getAllVehiclesAsyncCallback();
  }, [getAllVehiclesAsyncCallback]);

  return { allVehicles, isLoading };
};


export const useAllTeslaVehiclesAsync = () => {
  const [allVehicles, setAllVehicles] = useState<Vehicle[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllVehiclesAsyncCallback = useCallback(async () => {
    try {
      setIsLoading(true);

      const teslaBrandFilterQueryString = qs.stringify({
        filters: {
          brand: {
            $in: ["TESLA"]
          }
        }
      }, { addQueryPrefix: false, skipNulls: true });
      const response = await getAllPaginatedListByLoopAsync<Vehicle>((q) => getVehiclesAsync(q + "&" + teslaBrandFilterQueryString));
      setAllVehicles(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getAllVehiclesAsyncCallback();
  }, [getAllVehiclesAsyncCallback]);

  return { allVehicles, isLoading };
};


export const useVehiclesAsync = () => {
  const dispatch = useAppDispatch();

  const { search } = useLocation();

  const getVehiclesAsyncCallback = useCallback(
    async (queryString: string) => {
      try {
        dispatch(setIsVehiclesLoading(true));
        const res = await getVehiclesAsync(queryString);
        dispatch(setVehiclesAndMeta({ meta: res.meta, vehicles: res.data }));
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setIsVehiclesLoading(false));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getVehiclesAsyncCallback(search);
  }, [getVehiclesAsyncCallback, search]);

  return { ...useAppSelector((state) => state.vehicle), refetch: getVehiclesAsyncCallback };
};

export const useVehicles = () => {
  return { ...useAppSelector((state) => state.vehicle) };
};

export const useVehicleAsync = () => {
  const { vehicleId } = useParams<{ vehicleId: string }>();

  const [vehicle, setVehicle] = useState<Vehicle | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetVehicleCallbackAsync = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getVehicleAsync(vehicleId);
      setVehicle(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [vehicleId]);

  useEffect(() => {
    handleGetVehicleCallbackAsync();
  }, [handleGetVehicleCallbackAsync]);

  return { isLoading, vehicle, refetch: handleGetVehicleCallbackAsync };
};

export const useVehicleChargingSummaryAsync = () => {
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const [vehicleChargingSummary, setVehicleChargingSummary] = useState<VehicleChargingSummary | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetVehicleChargingSummaryCallbackAsync = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getVehicleChargingSummaryByIdAsync(vehicleId);
      setVehicleChargingSummary(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [vehicleId]);

  useEffect(() => {
    handleGetVehicleChargingSummaryCallbackAsync();
  }, [handleGetVehicleChargingSummaryCallbackAsync]);

  return { isLoading, vehicleChargingSummary };
};


export const useVehicleChargingListAsync = (isOverview?: boolean) => {
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const [vehicleChargingList, setVehicleChargingList] = useState<VehicleChargingDetail[]>([]);
  const [chargingListMeta, setChargingListMeta] = useState<PaginationMeta | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { search } = useLocation();

  const handleGetVehicleChargingListByIdAsync = useCallback(
    async (queryString: string) => {
      try {
        setIsLoading(true);

        const qs = isOverview ? "?pagination%5Bpage%5D=0&pagination%5Bsize%5D=3" : queryString;

        const response = await getVehicleChargingListByIdAsync(vehicleId, qs);

        setChargingListMeta(response.meta);
        setVehicleChargingList(response.data ?? []);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [isOverview, vehicleId]
  );

  useEffect(() => {
    handleGetVehicleChargingListByIdAsync(search);
  }, [handleGetVehicleChargingListByIdAsync, search]);

  return { isLoading, vehicleChargingList, chargingListMeta };
};

export const useVehicleLocationsAsync = () => {
  const [vehicleLocations, setVehicleLocations] = useState<Array<VehicleLocation> | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetVehicleLocationsAsync = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getVehicleLocationsAsync();
      setVehicleLocations(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleGetVehicleLocationsAsync();
  }, [handleGetVehicleLocationsAsync]);

  return { isLoading, vehicleLocations };
};


export const useVehicleTeslaAlertsAsync = (isConnected?: boolean) => {

  const { vehicleId } = useVehicleIdFromParams();
  const [alerts, setAlerts] = useState<TeslaAlerts | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetVehicleAlertsAsync = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getVehicleTeslaAlertsAsync(vehicleId);
      setAlerts(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [vehicleId]);

  useEffect(() => {
    if (!isConnected) {
      return;
    }
    handleGetVehicleAlertsAsync();
  }, [handleGetVehicleAlertsAsync, isConnected]);

  return { isLoading, alerts };
};


export const useCachedVehicleSmartcarDataAsync = (vehicleId: string) => {
  const cacheDuration = 60000;

  // Use Map<string, CachedVehicleData> for caching vehicles
  const [cachedData, setCachedData] = useState<Map<string, CachedVehicleData>>(new Map());

  const [vehicle, setVehicle] = useState<Vehicle | undefined>(undefined);

  const [isLoading, setIsLoading] = useState(false);

  const handleCachedVehicleSmartcarDataAsync = useCallback(async () => {
    const currentTime = new Date().getTime();
    const cachedVehicleData = cachedData.get(vehicleId);

    if (cachedVehicleData && currentTime - cachedVehicleData.lastFetchedAt < cacheDuration) {
      setVehicle(cachedVehicleData.vehicle);
      return;
    }

    try {
      setIsLoading(true);

      const response = await getVehicleAsync(vehicleId);
      const vehicleData = response.data;
      setVehicle(vehicleData);

      const currentTime = new Date().getTime();
      setCachedData(
        new Map(cachedData).set(vehicleId, {
          vehicle: vehicleData,
          lastFetchedAt: currentTime
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [cachedData, vehicleId]);

  useEffect(() => {
    handleCachedVehicleSmartcarDataAsync();

    // Do not set handleCachedVehicleSmartcarDataAsync as a dependency since it triggers this effect when the cache is changed which causes an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleId]);

  return {
    isLoading,
    vehicle
  };
};

/**
 * Tesla
 */

export const useVehicleTeslaKeyStatusAsync = (isConnected?: boolean) => {
  const { vehicleId } = useParams<{ vehicleId: string }>();

  const [keyStatus, setKeyStatus] = useState<TeslaKeyStatus | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetVehicleTeslaKeyStatusCallbackAsync = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getVehicleTeslaKeyStatusAsync(vehicleId);
      setKeyStatus(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [vehicleId]);

  useEffect(() => {
    if (!isConnected) {
      return;
    }

    handleGetVehicleTeslaKeyStatusCallbackAsync();
  }, [handleGetVehicleTeslaKeyStatusCallbackAsync, isConnected]);

  return { keyStatus, isLoading, refetchDrivePin: handleGetVehicleTeslaKeyStatusCallbackAsync };
};

export const useVehicleTeslaAsync = (isConnected: boolean) => {
  const { vehicleId } = useParams<{ vehicleId: string }>();

  const { handleOpenErrorAlert, handleOpenSuccessAlert } = useAlertManager();

  const [isTeslaSleeping, setIsTeslaSleeping] = useState<boolean>(false);
  const [teslaInfo, setTeslaInfo] = useState<TeslaVehicleInfo | undefined>(undefined);

  const teslaVehicleStatus: TeslaVehicleStatus | undefined = isTeslaSleeping
    ? TeslaVehicleStatus.ASLEEP
    : teslaInfo?.state;

  const [isTeslaInfoLoading, setIsTeslaInfoLoading] = useState(false);

  const [teslaDrivePin, setTeslaDrivePin] = useState<TeslaDrivePin | undefined>(undefined);
  const [isTeslaDrivePinLoading, setIsTeslaDrivePinLoading] = useState(true);

  const [isWakeUpCalling, setIsWakeUpCalling] = useState(false);

  const [isWakeUpSequenceRunning, setIsWakeUpSequenceRunning] = useState(false);
  /**
   *
   */
  const handleGetVehicleTeslaInfoCallbackAsync = useCallback(async () => {
    try {
      setIsTeslaInfoLoading(true);
      const response = await getVehicleTeslaInfoAsync(vehicleId);
      setTeslaInfo(response.data);
      setIsTeslaSleeping(false);

      return {
        isTeslaSleeping: false
      };
    } catch (error: unknown) {
      console.error(error);

      const isTeslaSleeping = error instanceof AxiosError && error?.response?.data?.error === TeslaError.E_ASLEEP;

      setIsTeslaSleeping(isTeslaSleeping);

      if (!isTeslaSleeping) {
        handleOpenErrorAlert(handleApiErrorResponse((error)));
      }

      return {
        isTeslaSleeping
      };

    } finally {
      setIsTeslaInfoLoading(false);
    }
  }, [handleOpenErrorAlert, vehicleId]);

  /**
   *
   */
  const handleGetVehicleTeslaDrivePinCallbackAsync = useCallback(async () => {
    try {
      setIsTeslaDrivePinLoading(true);
      setTeslaDrivePin((await getVehicleTeslaDrivePinAsync(vehicleId)).data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsTeslaDrivePinLoading(false);
    }
  }, [vehicleId]);

  /**
   *
   */
  const refetchTeslaInfo = useCallback(() => {
    setIsTeslaInfoLoading(true);
    // Give timeout to Prevent the inconsistency related lock data since Tesla api latency
    setTimeout(() => {
      handleGetVehicleTeslaInfoCallbackAsync();
    }, 500);
  }, [handleGetVehicleTeslaInfoCallbackAsync]);

  /**
   * Triggers a sequence of wake-up commands to a Tesla vehicle, attempting up to 5 times if the vehicle remains asleep.
   */
  const triggerVehicleWakeUpSequence = useCallback(async () => {
    console.log("Initiating wake-up sequence for the vehicle...");

    setIsWakeUpSequenceRunning(true);

    for (let attempt = 0; attempt < 5; attempt++) {
      console.log(`Wake-up attempt ${attempt + 1} started.`);

      try {
        console.log("Sending wake-up command to the vehicle...");
        await updateVehicleTeslaAsync(vehicleId, { command: TeslaCommand.WAKE_UP });

        console.log("Wake-up command sent. Waiting for 3 seconds before checking status...");
        await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds

        const { isTeslaSleeping } = await handleGetVehicleTeslaInfoCallbackAsync();


        if (!isTeslaSleeping) {
          console.log("Vehicle is awake.");
          handleOpenSuccessAlert("Your vehicle has been successfully awakened");
          break; // Exit the loop if the vehicle is awake
        } else if (attempt === 4) {
          console.log("Maximum attempts reached. The vehicle remains asleep.");
          handleOpenErrorAlert("Unable to wake up your vehicle. Please try again. If the issue continues, contact customer support for assistance.");
        }
      } catch (error) {
        console.error("Error during wake-up process: ", error);
      } finally {
        console.log(`Wake-up attempt ${attempt + 1} completed.`);
      }

      if (attempt < 4 && isTeslaSleeping) {
        console.log("Vehicle is still sleeping. Preparing for another attempt.");
      }
    }

    setIsWakeUpSequenceRunning(false);
    console.log("Wake-up sequence completed.");
  }, [isTeslaSleeping, vehicleId, handleGetVehicleTeslaInfoCallbackAsync, handleOpenSuccessAlert, handleOpenErrorAlert]);

  /**
   *
   */
  const handleTriggerVehicleWakeUpSequence = useCallback(async () => {
    try {
      setIsWakeUpCalling(true);
      await updateVehicleTeslaAsync(vehicleId, { command: TeslaCommand.WAKE_UP });
    } catch (error) {
      console.error(error);
    } finally {
      setIsWakeUpCalling(false);

      if (!isWakeUpSequenceRunning) {
        await triggerVehicleWakeUpSequence();
      } else {
        console.log("Wake up sequence is already running.");
      }
    }
  }, [isWakeUpSequenceRunning, triggerVehicleWakeUpSequence, vehicleId]);

  useEffect(() => {
    if (!isConnected) {
      return;
    }
    handleGetVehicleTeslaInfoCallbackAsync();
    handleGetVehicleTeslaDrivePinCallbackAsync();
  }, [handleGetVehicleTeslaDrivePinCallbackAsync, handleGetVehicleTeslaInfoCallbackAsync, isConnected]);

  return {
    isTeslaDrivePinLoading,
    isTeslaInfoLoading,
    teslaInfo,
    teslaDrivePin,
    teslaVehicleStatus,
    isTeslaSleeping,
    isWakeUpCalling,
    isWakeUpSequenceRunning,
    handleTriggerVehicleWakeUpSequence,
    refetchDrivePin: handleGetVehicleTeslaDrivePinCallbackAsync,
    refetchTeslaInfo
  };
};


export const useTeslaInvoiceListAsync = () => {
  const [invoices, setInvoices] = useState<Array<TeslaChargingWithTeslaInvoice>>([]);
  const [meta, setMeta] = useState<PaginationMeta | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const { search } = useLocation();


  const getChargingListAsyncCallback = useCallback(
    async (queryString: string) => {
      try {
        setIsLoading(true);
        const res = await getTeslaChargingInvoicesAsync(queryString);
        setInvoices(res.data);
        setMeta(res.meta);

      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );


  useEffect(() => {
    getChargingListAsyncCallback(search);
  }, [getChargingListAsyncCallback, search]);


  return { invoices, meta, isLoading, getChargingListAsyncCallback };
};