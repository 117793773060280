type Props = {};

function PinIconFilled({}: Props) {
  return (
    <svg width="53" height="34" viewBox="0 0 53 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.54464 0C4.27328 0 0 4.27328 0 9.54464V23.8616C0 29.133 4.27328 33.4062 9.54464 33.4062H10.0382H22.8149H42.9509C48.2222 33.4062 52.4955 29.133 52.4955 23.8616V9.54464C52.4955 4.27328 48.2222 0 42.9509 0H9.54464Z"
        fill="black"
      />
      <path
        d="M9.27667 20.9394L9.64075 17.5944L6.9557 19.5741L6 17.9357L9.09464 16.6387L6.02275 15.3872L6.91019 13.7944L9.6635 15.774L9.27667 12.4063H11.097L10.7102 15.774L13.4408 13.7944L14.3055 15.3645L11.2336 16.6615L14.3282 17.9357L13.3953 19.5513L10.7102 17.5944L11.0743 20.9394H9.27667Z"
        fill="white"
      />
      <path
        d="M20.4762 20.9394L20.8403 17.5944L18.1553 19.5741L17.1996 17.9357L20.2942 16.6387L17.2223 15.3872L18.1098 13.7944L20.8631 15.774L20.4762 12.4063H22.2966L21.9098 15.774L24.6403 13.7944L25.505 15.3645L22.4331 16.6615L25.5278 17.9357L24.5948 19.5513L21.9098 17.5944L22.2739 20.9394H20.4762Z"
        fill="white"
      />
      <path
        d="M31.6758 20.9394L32.0399 17.5944L29.3548 19.5741L28.3991 17.9357L31.4938 16.6387L28.4219 15.3872L29.3093 13.7944L32.0626 15.774L31.6758 12.4063H33.4962L33.1093 15.774L35.8399 13.7944L36.7046 15.3645L33.6327 16.6615L36.7273 17.9357L35.7944 19.5513L33.1093 17.5944L33.4734 20.9394H31.6758Z"
        fill="white"
      />
      <path
        d="M42.9485 20.9393L43.3125 17.5943L40.6275 19.574L39.6718 17.9356L42.7664 16.6386L39.6945 15.3871L40.582 13.7943L43.3353 15.7739L42.9485 12.4062H44.7688L44.382 15.7739L47.1126 13.7943L47.9772 15.3644L44.9054 16.6614L48 17.9356L47.0671 19.5512L44.382 17.5943L44.7461 20.9393H42.9485Z"
        fill="white"
      />
    </svg>
  );
}

export default PinIconFilled;
