import React from "react";
import { SvgProps } from "types/svg.types";

const TrendDownIcon = ({ fill, width, height }: SvgProps) => {
  const fillValue = fill ?? "#680031";
  const widthValue = width ?? 16;
  const heightValue = height ?? 18;

  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#FFE5F1" />
      <path
        d="M15 16.5L16.7175 14.7825L13.0575 11.1225L10.0575 14.1225L4.5 8.5575L5.5575 7.5L10.0575 12L13.0575 9L17.7825 13.7175L19.5 12V16.5L15 16.5Z"
        fill="#BF3D62"
      />
    </svg>
  );
};
export default TrendDownIcon;

/**
 *   width={`${widthValue}`}
      height={`${heightValue}`}
      viewBox={`0 0 ${widthValue} ${heightValue}
 */
