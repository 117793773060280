import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { DriverAssignmentFormOperation } from "types/driver.types";
import FormCompletedContent from "../../components/form/FormCompletedContent";
import TickCircleIcon from "../../components/icon/icons/TickCircleIcon";
import PrimaryButton from "../../components/button/PrimaryButton";
import VehiclesButton from "../../components/button/VehiclesButton";

type Props = {};

function AssignDriverFormCompletedContent({}: Props) {
  const { state } = useLocation<{ vehicleId: string }>();
  const { driverName, operation } = useParams<{
    driverName: string,
    operation: string,
  }>();
  console.log("location", state);

  const vehicleId = state?.vehicleId;

  const { t } = useTranslation("common");

  const history = useHistory();

  const isUnassigned = operation === DriverAssignmentFormOperation.Unassign;
  const title = isUnassigned ? t("vehicle.form.assign_driver.completed_content.deleted.title") : t("vehicle.form.assign_driver.completed_content.assigned.title");
  const description = isUnassigned ? t("vehicle.form.assign_driver.completed_content.deleted.description", { driverName }) : t("vehicle.form.assign_driver.completed_content.assigned.description", { driverName });

  return (
    <FormCompletedContent icon={<TickCircleIcon className="" fill={"#23D206"} width={100} height={100} />}>
      <>
        <FormCompletedContent.Title>{title}</FormCompletedContent.Title>

        <FormCompletedContent.Description>{description}</FormCompletedContent.Description>

        <FormCompletedContent.Buttons
          buttons={[vehicleId ?
            <PrimaryButton text={"Vehicle"} onClick={() => history.push(`/vehicles/${state?.vehicleId}`)} /> :
            <VehiclesButton />]} />
      </>
    </FormCompletedContent>
  );
}

export default AssignDriverFormCompletedContent;
