import PageHeader from "../../components/header/PageHeader";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import InfoBadge from "../../components/badge/InfoBadge";
import { NeutralBodyMediumText100 } from "../../components/text/Text";
import CustomerSupportLinkButton from "../../components/button/CustomerSupportLinkButton";
import TeslaInvoiceTable from "../../components/vehicle-components/tesla/TeslaInvoicesTable";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { useFeatureFlags } from "../../hooks/feature-flag.hooks";

export default function TeslaInvoicesPage() {
  const history = useHistory();

  const { isTeslaInvoiceListingEnabled } = useFeatureFlags();


  useEffect(() => {
    if (!isTeslaInvoiceListingEnabled) {
      history.replace("/");
    }

  }, [history, isTeslaInvoiceListingEnabled]);

  if (!isTeslaInvoiceListingEnabled) {
    return <></>;
  }

  return (
    <>
      <Head title={"Tesla invoices"}></Head>

      <Content>
        <PageHeader
          title="Supercharger Invoices"
          description={"View and download supercharger invoices incurred in a selected date range."}
        />
        <InfoBadge
          textOverride={<>
            <NeutralBodyMediumText100>
              To export supercharger invoices for a vehicle, the owner must connect their Tesla account with Bluedot for
              Fleets.
              For assistance connecting other accounts, please contact <CustomerSupportLinkButton />.
            </NeutralBodyMediumText100>
          </>}
        />

        <div className={"mt-4"}>
          <TeslaInvoiceTable />
        </div>

      </Content>
    </>
  );
}
