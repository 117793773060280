import { Auth, Hub } from "aws-amplify";
import { EmailFormValues } from "components/auth-components/form-components/ForgotPasswordFormFlowEmailStep";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { login, logout } from "../redux/slices/userSlice";

import { getOrganizationInfoAsync } from "../services/organization.services";
import {
  AWSSuccessfulForgotPasswordSubmitResponse,
  AWSSuccessfulResendSignUpSubmitResponse
} from "../types/auth.types";
import { handleApiErrorResponse } from "../utils";

import { useAppDispatch, useAppSelector } from "./redux.hooks";

import AuthChargingSessionsOverviewBanner from "assets/images/auth/AuthChargingSessionsOverviewBannerImage.webp";
import AuthDashboardOverviewBanner from "assets/images/auth/AuthDashboardOverviewBanner.webp";
import AuthRegisterFlowCompanyStepBanner from "assets/images/auth/AuthRegisterFlowCompanyStepBanner.webp";

import AuthSentCodeBanner from "assets/images/auth/AuthSentCodeBanner.webp";
import { UserSignUpApplicationStatus } from "types/organization.types";
import { useAlertManager } from "./alert.hooks";

const IMAGES = [
  AuthDashboardOverviewBanner,
  AuthRegisterFlowCompanyStepBanner,
  AuthChargingSessionsOverviewBanner,
  AuthSentCodeBanner
];


/**
 * Represents a hook.
 * Returns current user statues.
 */
export function useAuthStatus() {
  const user = useAppSelector((state) => state.user.currentUser);

  const isAuthenticated = !!user;
  const isUserActive = user?.active;
  const isUserRejected = user?.status === UserSignUpApplicationStatus.REJECTED;
  const isUserApproved = user?.status === UserSignUpApplicationStatus.APPROVED;
  const isUserPendingReview = user?.status === UserSignUpApplicationStatus.PENDING_REVIEW;

  return { user, isUserActive, isAuthenticated, isUserRejected, isUserApproved, isUserPendingReview };
}


/**
 * Represents a hook.
 * Subscribes Aws Auth events and it should be used top of the tree of App.
 */
export function useAuthSubscription() {
  const user = useAppSelector((state) => state.user.currentUser);
  const isAuthenticated = !!user;
  const { isLoading, getOrganizationInfoAsyncCallback } = useSyncOrganization();

  /**
   *
   */
  useEffect(() => {
    try {
      const cleanup = Hub.listen("auth", (data) => {
        switch (data.payload.event) {
          case "signIn":
            getOrganizationInfoAsyncCallback();
            break;
        }
      });

      return () => {
        cleanup();
      };
    } catch (error) {
      console.log(error);
    }


  }, [getOrganizationInfoAsyncCallback]);

  return { isAuthenticated, isLoading, user };
}


/**
 * Represents a hook.
 * Fetch up to date organization for dashboard onboarding
 */
export function useSyncOrganization() {
  const { getOrganizationInfoAsyncCallback, isLoading } = useGetOrganizationInfoCallbackAsync();

  /**
   *
   */
  useEffect(() => {
    getOrganizationInfoAsyncCallback();
  }, [getOrganizationInfoAsyncCallback]);

  return { isLoading, getOrganizationInfoAsyncCallback };
}


/**
 * Represents a hook.
 * Returns a callback that fetches organization and its loading state.
 */
export function useGetOrganizationInfoCallbackAsync() {

  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  /**
   *
   */
  const getOrganizationInfoAsyncCallback = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await getOrganizationInfoAsync();
      if (res.data) {
        dispatch(login(res.data));
      } else {
        throw Error("An error occurred.");
      }
    } catch (err) {
      console.error(err);
      dispatch(logout());
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);


  return { isLoading, getOrganizationInfoAsyncCallback };
}


/**
 * Represents a hook.
 * Redirect the user to correct route by auth status.
 */
export const useAuthRedirect = () => {
  const history = useHistory();
  const { isAuthenticated } = useAuthStatus();

  const redirectUserToCorrectRouteByAuthStatus = useCallback(async () => {

    if (isAuthenticated) {
      return history.push("/");
    }

  }, [history, isAuthenticated]);

  useEffect(() => {
    redirectUserToCorrectRouteByAuthStatus();
  }, [isAuthenticated, redirectUserToCorrectRouteByAuthStatus]);
};


export const useForgotPasswordEmailSubmitAsync = () => {
  const { handleOpenErrorAlert } = useAlertManager();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  /**
   *
   */
  const forgotPasswordEmailSubmitAsync = useCallback(
    async (values: EmailFormValues) => {
      try {
        setIsLoading(true);
        const response: AWSSuccessfulForgotPasswordSubmitResponse = await Auth.forgotPassword(
          values.email
        );
        return response;
      } catch (error: any) {
        handleOpenErrorAlert(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [handleOpenErrorAlert]
  );

  return { forgotPasswordEmailSubmitAsync, isLoading, errorMessage };
};


export const useSignUpResendConfirmationEmailSubmitAsync = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  /**
   *
   */
  const SignUpResendConfirmationEmailSubmitAsync = useCallback(
    async (values: EmailFormValues) => {
      try {
        setIsLoading(true);
        const response: AWSSuccessfulResendSignUpSubmitResponse = await Auth.resendSignUp(
          values.email
        );
        return response;
      } catch (error) {
        setErrorMessage(handleApiErrorResponse(error));
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return { SignUpResendConfirmationEmailSubmitAsync, isLoading, errorMessage };
};


export const useAuthServicesAsync = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  /**
   *
   */
  const logoutAsync = useCallback(
    async () => {
      try {
        setIsLoading(true);
        await Auth.signOut();
        dispatch(logout());
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  return { logoutAsync, isLoading };
};


export const usePreloadAuthImages = () => {
  useEffect(() => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.onload = () => resolve(image);
        loadImg.onerror = (err) => reject(err);
        loadImg.src = image;
      });
    };

    Promise.all(IMAGES.map((image) => loadImage(image))).catch((err) => console.error("Failed to load images", err));
  }, []);
};