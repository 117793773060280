import React, { PropsWithChildren } from "react";
import Head from "../../layout/head/Head";
import Card from "../card/Card";
import Col from "../custom/Col";
import Row from "../custom/Row";
import TickCircleIcon from "../icon/icons/TickCircleIcon";
import { BlackBodyText100, BlackH5Text0, BlackSubtitleText200, BlackSubtitleText300 } from "../text/Text";

type Props = PropsWithChildren<{
  icon?: JSX.Element;
}>;

const FormCompletedContent = ({ children, ...props }: Props) => {
  const icon = props.icon ?? <TickCircleIcon className="" fill={"#23D206"} width={100} height={100} />;
  return (
    <>
      <div className="my-5 py-5 center">
        <Card padding={"p-4"} className={"d-flex flex-column center w-max-100 wide-sm-fix "}>
          {icon}
          {children}
        </Card>
      </div>
    </>
  );
};

FormCompletedContent.Title = function FormCompletedContentTitle({ children }: PropsWithChildren) {
  return (
    <BlackH5Text0 page className={"mt-4 text-center"}>
      {children}
    </BlackH5Text0>
  );
};

FormCompletedContent.Description = function FormCompletedContentDescription({ children }: PropsWithChildren) {
  return (
    <BlackBodyText100 page className={"mt-3 text-center mb-2  px-2 px-lg-4"}>
      {children}
    </BlackBodyText100>
  );
};

FormCompletedContent.Buttons = function FormCompletedContentButtons({ buttons }: { buttons: JSX.Element[] }) {
  return (
    <Row className="d-flex center w-100  mt-2 mt-md-2">
      {buttons.map((button) => (
        <Col className="p-2 center">{button}</Col>
      ))}
    </Row>
  );
};

export default FormCompletedContent;
