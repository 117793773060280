import React from "react";

import TirePressureVehicleImage from "assets/images/vehicle/TirePressureCarImage.png";
import Row from "../../custom/Row";
import Col from "../../custom/Col";
import { BlackBodyText100 } from "../../text/Text";
import { VehicleTpmsInfoWithMeta } from "../../../types/vehicle.types";

type Props = {
  tirePressure?: VehicleTpmsInfoWithMeta
}

function VehiclesDetailsTirePressure({ tirePressure }: Props) {
  const tirePressureData = tirePressure?.data;
  const frontLeft = tirePressureData?.frontLeft;
  const frontRight = tirePressureData?.frontRight;
  const rearLeft = tirePressureData?.rearLeft;
  const rearRight = tirePressureData?.rearRight;


  return (
    <Row className={"w-250px"}>
      <Col className="flex-column justify-between h-110px">
        <BlackBodyText100>{frontLeft ? frontLeft?.toFixed(1) : "- "}psi</BlackBodyText100>
        <BlackBodyText100>{rearLeft ? rearLeft?.toFixed(1) : "- "}psi</BlackBodyText100>
      </Col>
      <img width={110} height={110} src={TirePressureVehicleImage} alt="Tire pressure vehicle" />
      <Col className="flex-column justify-between h-110px">
        <BlackBodyText100
          className={"text-right"}>{frontRight ? frontRight?.toFixed(1) : "- "}psi</BlackBodyText100>
        <BlackBodyText100
          className={"text-right"}>{rearRight ? rearRight?.toFixed(1) : "- "}psi</BlackBodyText100>
      </Col>
    </Row>
  );
}

export default VehiclesDetailsTirePressure;