import React from "react";
import LoadingLogoAnimationData from "../../assets/lottie/loading-animation.json";
import Lottie from "lottie-react";

export default function LogoLoadingIndicator() {
  return (
    <div
      className="nk-block"
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        padding: 5,
      }}
    >
      <Lottie animationData={LoadingLogoAnimationData} loop={true} style={{ width: 160 }} />
    </div>
  );
}
