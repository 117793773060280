import DynamicListWithTooltip from "components/list/DynamicListWithTooltip";
import { getDriverFullName } from "utils/driver.utils";
import LoadingSkeleton from "../loading/LoadingSkeleton";
import { useParams } from "react-router";
import AssignDriverLinkButton from "../button/AssignDriverLinkButton";
import { VehicleDriverSchedulesDataAccessorHook } from "../../types/vehicle-driver-schedule.types";

type Props = {
  vehicleDriverSchedulesHook: VehicleDriverSchedulesDataAccessorHook
};

function VehicleDriverScheduleDriverList({ vehicleDriverSchedulesHook }: Props) {
  const { isLoading: isSchedulesLoading, schedules } = vehicleDriverSchedulesHook;

  const { vehicleId } = useParams<{ vehicleId: string }>();

  const list = schedules.map((s) => {
    return { name: getDriverFullName(s.user) };
  });

  if (isSchedulesLoading) {
    return <LoadingSkeleton count={1} className={"w-100px"} />;
  }

  if (list.length === 0) {
    return <AssignDriverLinkButton vehicleId={vehicleId} />;
  }

  return <DynamicListWithTooltip list={list} />;
}

export default VehicleDriverScheduleDriverList;
