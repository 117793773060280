import PageHeader from "../header/PageHeader";

import VehicleHeaderCard from "./VehiclePageHeaderCard";

type Props = {
  title: string;
  description?: string;
};
export default function VehiclePageHeader({ title }: Props) {
  return <PageHeader title={title} rightElement={<VehicleHeaderCard />} />;
}
