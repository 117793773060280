type Props = {};

function CreditCardIcon({}: Props) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 4.77734C2.8954 4.77734 2 5.67234 2 6.77734V8.77734H22V6.77734C22 5.67234 21.1046 4.77734 20 4.77734H4Z"
        fill="#0C0C0C"
      />
      <path
        d="M2.6 11.3773H21.4V18.7773C21.4 19.5509 20.7733 20.1773 20 20.1773H4C3.22669 20.1773 2.6 19.5509 2.6 18.7773V11.3773Z"
        stroke="#0C0C0C"
        stroke-width="1.2"
      />
      <path
        d="M14.5 14.7773C14.2239 14.7773 14 15.0013 14 15.2773V17.2773C14 17.5533 14.2239 17.7773 14.5 17.7773H18.5C18.7761 17.7773 19 17.5533 19 17.2773V15.2773C19 15.0013 18.7761 14.7773 18.5 14.7773H14.5Z"
        fill="#0C0C0C"
      />
    </svg>
  );
}

export default CreditCardIcon;
