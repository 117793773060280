import React, { useCallback, useState } from "react";
import { downloadTeslaChargingInvoicesAsync } from "../../../services/vehicle.services";
import { useAlertManager } from "../../../hooks/alert.hooks";
import { handleApiErrorResponse } from "../../../utils";
import OutlinedButton from "../../button/OutlinedButton";
import { useLocation } from "react-router";


const TeslaDownloadAllInvoicesButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { handleOpenErrorAlert, handleOpenSuccessAlert, handleOpenWarningAlert } = useAlertManager();

  const { search } = useLocation();

  const handleDownloadInvoices = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await downloadTeslaChargingInvoicesAsync(search);


      const message = response.data.message;
      const failedCount = response.data.failed.length;


      const url = response.data.url;

      if (!url) {
        handleOpenErrorAlert(message);
        return;
      }

      const link = document.createElement("a");
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();


      if (failedCount > 0) {
        handleOpenWarningAlert(message);
      } else {
        handleOpenSuccessAlert(message);
      }

    } catch (e) {
      handleOpenErrorAlert(handleApiErrorResponse(e));
    } finally {
      setIsLoading(false);
    }
  }, [search, handleOpenErrorAlert, handleOpenWarningAlert, handleOpenSuccessAlert]);

  return (
    <OutlinedButton
      type="button"
      isLoading={isLoading}
      onClick={handleDownloadInvoices}
      text={"Download all invoices"} />
  );
};

export default TeslaDownloadAllInvoicesButton;