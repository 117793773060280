import React from "react";
import { SvgProps } from "types/svg.types";

type Props = {};

function CrossIcon(props: SvgProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6 18L17 7M17 7V17.56M17 7H6.44"
        stroke={props.fill ?? "black"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default CrossIcon;
