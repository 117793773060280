import FilterMenu from "components/filter/FilterMenu";
import React from "react";
import FilterDateSelector from "../../filter/FilterDateSelector";
import TeslaInvoiceFilterVehicleSelector from "./TeslaInvoiceFilterVehicleSelector";
import ChargingFilterDriverSelector from "../../charging-sessions-components/filter/ChargingFilterDriverSelector";
import FilterPaymentStateSelector from "../../charging-sessions-components/filter/ChargingPaymentStateSelector";

type Props = {};

function TeslaInvoiceTableFilterMenu(props: Props) {
  return <FilterMenu selectors={[
    <ChargingFilterDriverSelector key={0} />, <TeslaInvoiceFilterVehicleSelector key={1} />,
    <FilterPaymentStateSelector key={2} />,
    <FilterDateSelector key={3} />
  ]}
  />;
}

export default TeslaInvoiceTableFilterMenu;
