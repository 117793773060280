import { TotalEnergyChart } from "components/charts/Chart";
import { useMemo } from "react";

import { DashboardTablePlaceholder } from "../dashboard-placeholders/DashboardTablePlaceholder";

import { useDashboardInsights } from "hooks/data/dashboard-data-accessor.hooks";
import { DashboardInsightFilterReturnDataType } from "types/dashboard.types";
import {
  convertFilterToFilterReturnType,
  extendFilterOptions,
  getTotalEnergyOrCostDataForGraphByFilter,
} from "utils/dashboard.utils";
import { getMonthNameByDateString } from "utils/date.utils";

export default function DashboardEnergyGraph() {
  const { filter, insights } = useDashboardInsights();
  const totalEnergyData = insights?.totalEnergyByPeriod;
  const areThereAnyData = totalEnergyData && totalEnergyData.length > 0;

  const totalEnergyOrCostDataForGraph = useMemo(() => {
    if (!areThereAnyData) {
      return undefined;
    }

    return getTotalEnergyOrCostDataForGraphByFilter(filter, totalEnergyData);
  }, [areThereAnyData, filter, totalEnergyData]);

  const areThereAnyTotalValueInsideGraphDataList =
    (totalEnergyOrCostDataForGraph?.data ?? []).reduce((accumulator, currentValue) => accumulator + currentValue, 0) >
    0;

  if (!totalEnergyOrCostDataForGraph || !areThereAnyTotalValueInsideGraphDataList) {
    return <DashboardTablePlaceholder />;
  }

  const { data, labels } = totalEnergyOrCostDataForGraph;

  const totalEnergyChartData = {
    labels: labels,
    dataUnit: "kWh",
    stacked: true,
    datasets: [
      {
        backgroundColor: data?.map((_) => "#16BAC5"),
        data: data,
      },
    ],
  };
  const isMonthly = convertFilterToFilterReturnType(filter) === DashboardInsightFilterReturnDataType.Monthly;
  const { start } = extendFilterOptions(filter);
  const selectedMonth = isMonthly ? getMonthNameByDateString(start) : undefined;
  return <TotalEnergyChart tooltipTitlePrefix={selectedMonth} data={totalEnergyChartData} />;
}
