import { useEffect, useState } from "react";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import PasswordEyeHideIcon from "components/icon/icons/PasswordEyeHideIcon";
import PasswordEyeIcon from "components/icon/icons/PasswordEyeIcon";
import PinIcon from "components/icon/icons/PinIcon";
import IosSwitch from "components/input/switch/IosSwitch";
import BaseModal from "components/modal/BaseModal";
import { BlackBody2Text200, BlackOverlineText200, WhiteOverlineText0, WhiteSubtitleText0 } from "components/text/Text";
import VehicleDetailsPinToDriveEditorForm from "./VehicleDetailsPinToDriveEditorForm";
import XIcon from "components/icon/icons/XIcon";
import PrimaryButton from "components/button/PrimaryButton";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { useVehicleTeslaKeyStatusAsync } from "hooks/data/vehicle-data-accessor.hooks";
import { TeslaDataAccessorHook, TeslaManagementMode } from "types/vehicle.types";
import VehicleDetailsTeslaKeySendSmsForm from "./VehicleDetailsTeslaKeySendSmsForm";

type Props = {
  teslaHook: TeslaDataAccessorHook;
};

function VehicleDetailsPinToDriveEditor({ teslaHook }: Props) {
  const { isLoading: isKeyStatusLoading, keyStatus } = useVehicleTeslaKeyStatusAsync(true);
  const [isKeyTooltipOpen, setIsKeyTooltipOpen] = useState(false);

  const [isSendSmsModalOpen, setIsSendSmsModalOpen] = useState(false);


  useEffect(() => {
    if (keyStatus?.key) {
      setIsKeyTooltipOpen(!keyStatus.key.paired);
    }
  }, [keyStatus?.key]);

  const [isPinShown, setIsPinShown] = useState(false);
  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false);

  const { teslaDrivePin, isTeslaDrivePinLoading: isLoading, refetchDrivePin } = teslaHook;
  const pin = teslaDrivePin?.drive.pin;
  const isEnabled = !!pin;
  const isSystemMode = teslaDrivePin?.drive.managementMode === TeslaManagementMode.SYSTEM;


  if (isLoading || isKeyStatusLoading) {
    return <div className={"py-2"}><LoadingSkeleton count={2} /></div>;
  }

  return (
    <>
      <BaseModal
        className="wide-sm"
        isCloseIconVisible
        isModalOpen={isSendSmsModalOpen}
        setIsModalOpen={setIsSendSmsModalOpen}
      >
        {isSendSmsModalOpen && <VehicleDetailsTeslaKeySendSmsForm onFormReset={() => setIsSendSmsModalOpen(false)} />}
      </BaseModal>

      <BaseModal
        className="wide-xs"
        isCloseIconVisible
        isModalOpen={isEditorModalOpen}
        setIsModalOpen={setIsEditorModalOpen}
      >
        {isEditorModalOpen && (
          <VehicleDetailsPinToDriveEditorForm
            isDisableMode={isEnabled}
            refetch={refetchDrivePin}
            onFormReset={() => setIsEditorModalOpen(false)}
          />
        )}
      </BaseModal>

      <Row className="justify-between align-items-center mt-2">
        <Col>
          <Row>
            <BlackBody2Text200>PIN to drive</BlackBody2Text200>
            <div className="ml-1">
              <PinIcon />
            </div>
          </Row>
          <Row className="align-items-center">
            {pin ? (
              <>
                {isPinShown ? pin : <>****</>}
                <div className="vertical-border-line-gray h-20px mx-1" />
                {isPinShown ? (
                  <PasswordEyeHideIcon className=" clickable" onClick={() => setIsPinShown(false)} />
                ) : (
                  <PasswordEyeIcon className=" clickable" onClick={() => setIsPinShown(true)} />
                )}
              </>
            ) : (
              "-"
            )}
          </Row>
        </Col>
        <div className="position-relative">
          <IosSwitch
            isDisabled={isSystemMode}
            onToggle={() => {
              if (!keyStatus?.key.paired) {
                setIsKeyTooltipOpen(true);
                return;
              }
              setIsEditorModalOpen(true);
            }}
            label={""}
            isChecked={isEnabled}
          />
          {isKeyTooltipOpen && (
            <div className="gray-tooltip card-with-more-shadow">
              <Row className="align-items-center justify-between">
                <WhiteSubtitleText0>Enable Third-Party Virtual Key</WhiteSubtitleText0>
                <XIcon fill="white" className="clickable" onClick={() => setIsKeyTooltipOpen(false)} />
              </Row>

              <WhiteOverlineText0 className="my-3">
                To use "Pin to Drive" and other features seamlessly, please set up your Third-Party Virtual Key. It's a
                simple step that ensures uninterrupted access to your vehicle's full capabilities. Tap here to receive a
                direct link for easy Bluedot setup in the Tesla app. This one-time setup unlocks comprehensive control
                and seamless integration.
              </WhiteOverlineText0>

              <Row className="justify-end mt-4">
                <PrimaryButton
                  text="Send message"
                  onClick={() => {
                    setIsSendSmsModalOpen(true);
                    setIsKeyTooltipOpen(false);
                  }}
                />
              </Row>
            </div>
          )}
        </div>
      </Row>

      {isSystemMode && (
        <BlackOverlineText200 className="mt-1">
          When system managed is enabled, pin to drive cannot be changed.
        </BlackOverlineText200>
      )}
    </>
  );
}

export default VehicleDetailsPinToDriveEditor;
