import { SvgProps } from "types/svg.types";

type Props = {};

function SettingsUserIconFilled(props: SvgProps) {
  return (

    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0002 10.4503C12.5103 10.4503 14.5452 8.41535 14.5452 5.90519C14.5452 3.39502 12.5103 1.36011 10.0002 1.36011C7.49 1.36011 5.45508 3.39502 5.45508 5.90519C5.45508 8.41535 7.49 10.4503 10.0002 10.4503Z"
        fill="#0C0C0C" />
      <path
        d="M7.49978 11.3679C4.36203 11.3679 1.81836 13.9115 1.81836 17.0492V17.731C1.81836 18.233 2.22536 18.64 2.72744 18.64H17.2717C17.7737 18.64 18.1807 18.233 18.1807 17.731V17.0492C18.1807 13.9115 15.637 11.3679 12.4994 11.3679H7.49978Z"
        fill="#0C0C0C" />
    </svg>

  );
}

export default SettingsUserIconFilled;
