import ClipboardWithCopyIcon from "components/clipboard/ClipboardWithCopyIcon";
import Row from "components/custom/Row";
import { BlackBody2Text100 } from "components/text/Text";
import { useHistory } from "react-router";
import { VehicleDriverSchedule } from "types/vehicle-driver-schedule.types";
import { getLocalFormattedDateForTables, PredefinedMaxDateValue } from "utils/date.utils";
import { getDriverFullName } from "utils/driver.utils";
import { getVehicleDriverSchedulePeriodInfo } from "utils/vehicle-driver-schedule.utils";
import DriverDetailsSectionFormBox from "../driver-components/DriverDetailsSectionFormBox";
import Card from "./Card";
import PencilIcon from "../icon/icons/PencilIcon";
import TrashIcon from "../icon/icons/TrashIcon";
import VehicleDriverSchedulePeriodBadge from "../badge/VehicleDriverSchedulePeriodBadge";
import React from "react";
import PrimaryNegativeButton from "../button/PrimaryNegativeButton";
import ButtonWithIcon from "../button/ButtonWithIcon";
import UnderlinedTextButton from "../button/UnderlinedTextButton";
import { TextButtonMode } from "../button/TextButton";


type Props = {
  schedule: VehicleDriverSchedule;
  onEditClicked: (schedule: VehicleDriverSchedule) => void;
  onDeleteClicked: (params: { schedule: VehicleDriverSchedule; isActive: boolean }) => void;
  isForDriver?: boolean;
  ix: number;
  isBorderBottomVisible?: boolean
};

function DriverVehicleScheduleCard({
                                     schedule,
                                     onDeleteClicked,
                                     onEditClicked,
                                     ix,
                                     isForDriver,
                                     isBorderBottomVisible
                                   }: Props) {
  const history = useHistory();
  const { isActive, timeDifference, color } = getVehicleDriverSchedulePeriodInfo(schedule.startsAt, schedule.endsAt);
  return (
    <>
      <Card className={"mt-2"}>
        <Row className="justify-between ">
          <Row className="align-items-center">
            <BlackBody2Text100 className="mr-2">Vehicle assign. {ix + 1}</BlackBody2Text100>

            {!isActive && <div className="scheduled-badge mr-2">Scheduled</div>}

          </Row>
          <Row className="align-items-center">
            {isActive ? (
              <PrimaryNegativeButton
                isSmall
                text={"Stop"}
                onClick={() => onDeleteClicked({ schedule, isActive })} />

            ) : (
              <PrimaryNegativeButton
                isSmall
                leftIcon={<TrashIcon />}
                text={""}
                onClick={() => onDeleteClicked({ schedule, isActive: false })} />
            )}

            <ButtonWithIcon
              isSmall
              className={"ml-2"}
              leftIcon={<PencilIcon fill={"white"} />}
              text={""}
              onClick={() => onEditClicked(schedule)}
            />

          </Row>

        </Row>
        <DriverDetailsSectionFormBox className="mt-3" isBorderHidden label={isForDriver ? "Driver name" : "Vehicle"}>
          {isForDriver ? (
            <UnderlinedTextButton text={getDriverFullName(schedule.user)} mode={TextButtonMode.PRIMARY}
                                  onClick={() => history.push(`/drivers/${schedule.userId}`)} />


          ) : (
            <UnderlinedTextButton text={schedule.vehicle.name} mode={TextButtonMode.PRIMARY}
                                  onClick={() => history.push(`/vehicles/${schedule.vehicleId}`)} />


          )}
        </DriverDetailsSectionFormBox>
        {!isForDriver && (
          <DriverDetailsSectionFormBox className="mt-3" isBorderHidden label={"VIN"}>
            <ClipboardWithCopyIcon TextComponent={BlackBody2Text100} text={schedule?.vehicle?.vin} />
          </DriverDetailsSectionFormBox>
        )}

        <DriverDetailsSectionFormBox className="mt-3" isBorderHidden label={"Vehicle assignment period"}>
          <VehicleDriverSchedulePeriodBadge color={color} timeDifference={timeDifference} />
        </DriverDetailsSectionFormBox>
        <DriverDetailsSectionFormBox
          className="mt-3"
          isBorderHidden
          label={"Start date"}
          value={getLocalFormattedDateForTables(schedule.startsAt)}
        />
        <DriverDetailsSectionFormBox
          className="mt-3"
          isBorderHidden
          label={"End date (optional)"}
          value={schedule.endsAt === PredefinedMaxDateValue ? "-" : getLocalFormattedDateForTables(schedule.endsAt)}
        />
      </Card>

    </>
  )
    ;
}

export default DriverVehicleScheduleCard;
