import { ApiResponse, PaginatedApiResponse } from "types/api.types";
import { AddManualInvoicePayload, Invoice } from "types/invoice.types";
import { generateApiEndpoint } from "utils";
import restApiClient from "./client";


const serviceName = 'invoices'

/**
 * GET: 'admin/invoices/{id}'
 */
export async function getInvoicesAsync(query: string = '') {
    const endpoint = generateApiEndpoint(serviceName);
    return (await restApiClient.get<PaginatedApiResponse<Invoice>>(endpoint + query)).data;
}


/**
 * GET: 'admin/invoices/{id}'
 */
export async function getInvoiceAsync(invoiceId: string): Promise<ApiResponse<Invoice>> {
    const endpoint = generateApiEndpoint(`${serviceName}/${invoiceId}`);
    const response = await restApiClient.get(endpoint);
    return response.data;
}


/**
 * GET: 'admin/stripe/portal'
 */
export async function getInvoiceStripePortalUrlAsync(): Promise<{ url: string }> {
    const endpoint = generateApiEndpoint(`stripe/portal`);
    const response = await restApiClient.post(endpoint);
    return response.data;
}

