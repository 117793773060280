import { CircularProgress, CircularProgressProps } from "@mui/material";
import React, { MouseEventHandler, ReactNode } from "react";
import Button from "./Button";

interface SecondaryButtonProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
  textOverride?: ReactNode;
  text?: string;
  textColor?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  height?: number | string;
  theme?: string;
  [x: string]: any;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  isDisabled,
  isLoading,
  className,
  textOverride,
  text,
  textColor,
  onClick,
  height,
  theme,
  ...otherProps
}) => {
  const baseHeight = 48;
  const themeValue = theme ?? "bd-blue";
  const textColorValue = textColor ?? "text-blue-100";
  const buttonHeight = height ?? baseHeight;
  const buttonHeightClassName = `h-${buttonHeight}px`;

  return (
    <Button
      disabled={isDisabled}
      color={themeValue}
      className={`rounded-xxl center  ${buttonHeightClassName} ${className ?? ""}`}
      onClick={onClick}
      {...otherProps}
    >
      {isLoading ? (
        <CircularProgress size={12} color="primary" />
      ) : (
        textOverride ?? <span className={`font-weight-normal title ${textColorValue}`}>{text}</span>
      )}
    </Button>
  );
};

export default SecondaryButton;
