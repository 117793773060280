import { CircularProgress } from "@mui/material";
import React from "react";

type Props = {
  size?: number
  heightClassName?: string
};

function CircularLoadingSpinner({
                                  size = 75,
                                  heightClassName = "h-475px"
                                }: Props) {
  return (
    <div className={`w-100 ${heightClassName} center`}>
      <CircularProgress size={size} style={{ color: "#1E44FF" }} />
    </div>
  );
}

export default CircularLoadingSpinner;
