import React from "react";
import DetailsPageCardBlock, { DetailsPageCardBlockProps } from "../../block/DetailsPageCardBlock";

type Props = DetailsPageCardBlockProps;

function VehicleDetailsPageCardBlock(props: Props) {
  return <DetailsPageCardBlock paddingClass="pt-2 pb-1" isBorderBottomVisible {...props} />;
}

export default VehicleDetailsPageCardBlock;
