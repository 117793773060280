import React from "react";

type Props = {};

function UnlinkIcon({}: Props) {
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2512_17570)">
        <rect x="0.20459" y="0.5" width="53" height="53" rx="26.5" fill="#FFE5F1" />
        <path
          d="M38.2674 38.6923C36.7709 40.1712 34.994 41.3642 33.0226 42.1841L36.9681 51.6812C40.1717 50.3469 43.0624 48.4081 45.4942 46.0048C47.926 43.6015 49.8988 40.7338 51.2707 37.5462C52.6404 34.3586 53.4093 30.8468 53.431 27.1575C53.4528 23.4681 52.7254 19.9497 51.3933 16.744C50.0591 13.5404 48.1202 10.6497 45.717 8.21789C43.3137 5.78611 40.446 3.81331 37.2584 2.44143L33.2011 11.8913C35.1627 12.7344 36.9276 13.9482 38.4044 15.4447C39.8834 16.9412 41.0764 18.7181 41.8963 20.6896C42.7162 22.661 43.163 24.8268 43.1496 27.0947C43.1362 29.3626 42.6639 31.5231 41.8208 33.4847C40.9777 35.4463 39.7639 37.2112 38.2674 38.688L38.2674 38.6923ZM7.70729 45.7821C10.1106 48.2139 12.9783 50.1866 16.1659 51.5585L20.2231 42.1087C18.2615 41.2656 16.4966 40.0517 15.0198 38.5553C13.5409 37.0588 12.3479 35.2818 11.528 33.3104C10.7081 31.339 10.2613 29.1731 10.2747 26.9052C10.288 24.6373 10.7603 22.4769 11.6034 20.5153C12.4465 18.5537 13.6604 16.7888 15.1568 15.3119C16.6533 13.833 18.4303 12.64 20.4017 11.8201L16.4561 2.32308C13.2525 3.65729 10.3618 5.59614 7.93001 7.99942C5.49823 10.4027 3.52544 13.2704 2.15356 16.458C0.781677 19.6456 0.014957 23.1574 -0.00679082 26.8468C-0.0285386 30.5361 0.69888 34.0546 2.03094 37.2603C3.36514 40.4638 5.30399 43.3546 7.70727 45.7864L7.70729 45.7821Z"
          fill="#DB8EA7"
        />
        <path
          d="M24.8178 30.5117L24.3523 14.5861H28.9072L28.4085 30.5117H24.8178ZM26.7129 38.0921C25.915 38.0921 25.2611 37.8483 24.7513 37.3607C24.2637 36.873 24.0199 36.2857 24.0199 35.5985C24.0199 34.8893 24.2637 34.2908 24.7513 33.8032C25.2611 33.3156 25.915 33.0717 26.7129 33.0717C27.4665 33.0717 28.0871 33.3156 28.5748 33.8032C29.0846 34.2908 29.3395 34.8893 29.3395 35.5985C29.3395 36.2857 29.0846 36.873 28.5748 37.3607C28.0871 37.8483 27.4665 38.0921 26.7129 38.0921Z"
          fill="#C4003E"
        />
      </g>
      <defs>
        <clipPath id="clip0_2512_17570">
          <rect x="0.20459" y="0.5" width="53" height="53" rx="26.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UnlinkIcon;
