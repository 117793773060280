import Row from "components/custom/Row";
import BaseButton from "./BaseButton";


function CopyCodeButton(props) {
  const { leftIcon, rightIcon, text } = props;
  return (
    <BaseButton
      theme={"light"}
      outline
      textOverride={
        <Row className="center">
          {leftIcon && leftIcon}
          <span className="font-weight-light fs-14px">{text}</span>
          {rightIcon && rightIcon}
        </Row>
      }
      {...props}
    />
  );
}

export default CopyCodeButton;
