import React from "react";

type Props = {
  fill?: string;
};

function ChargerLighteningIcon({ fill = "white" }: Props) {
  return (
    <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.38631 0.737305C9.48825 0.796404 9.56714 0.888328 9.61008 0.998058C9.65303 1.10779 9.6575 1.22884 9.62275 1.34144L7.74682 7.43692H11.2081C11.3098 7.43688 11.4093 7.46663 11.4943 7.52249C11.5793 7.57835 11.6461 7.65788 11.6864 7.75127C11.7267 7.84465 11.7388 7.9478 11.7212 8.04798C11.7036 8.14816 11.657 8.24098 11.5872 8.31499L3.25437 17.1687C3.17374 17.2544 3.06645 17.3103 2.94998 17.3272C2.83351 17.3442 2.71474 17.3212 2.61302 17.262C2.51129 17.2028 2.43262 17.1109 2.38982 17.0013C2.34702 16.8916 2.34262 16.7707 2.37733 16.6583L4.25327 10.5617H0.792007C0.690289 10.5618 0.590786 10.532 0.50578 10.4762C0.420774 10.4203 0.353988 10.3408 0.313665 10.2474C0.273342 10.154 0.261249 10.0509 0.278877 9.95068C0.296505 9.8505 0.343084 9.75768 0.412862 9.68367L8.74572 0.830008C8.82625 0.744387 8.93337 0.688525 9.04967 0.671505C9.16598 0.654485 9.28462 0.677308 9.38631 0.736263V0.737305Z"
        fill={fill}
      />
    </svg>
  );
}

export default ChargerLighteningIcon;
