import { DateTime } from "luxon";
import React from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import ExportIcon from "../icon/icons/ExportIcon";
import TertiaryButton from "./TertiaryButton";
import OutlinedButtonWithIcon from "./OutlinedButtonWithIcon";

type ListItem = {
  [key: string]: any;
};

type Props = {
  data: ListItem[];
  fileName: string;
};

function CsvLinkButton({ data, fileName }: Props) {
  const { t } = useTranslation("common");
  return (
    <CSVLink data={data ?? []} filename={`${DateTime.now().toLocaleString(DateTime.DATE_FULL)}-${fileName}.csv`}>
      <OutlinedButtonWithIcon leftIcon={<ExportIcon />} text={t("general.export") as string} />
    </CSVLink>
  );
}

export default CsvLinkButton;
