import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";

import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

import AddDriverFormLayout from "components/driver-components/AddDriverFormLayout";
import { resetDriverForm } from "../../redux/slices/driverSlice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

export default function AddDriverPage() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(resetDriverForm());
  }, [dispatch, pathname]);

  const { t } = useTranslation("common");
  return (
    <>
      <Head title={t("driver.main_page.title")}></Head>
      <Content>
        <AddDriverFormLayout />
      </Content>
    </>
  );
}
