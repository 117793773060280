import {
  BlackBodyText100,
  BlackBodyText200,
  BlackSubtitleText100,
  BlackSubtitleText200,
  LinkTextPrimary,
} from "components/text/Text";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import React, { useCallback } from "react";
import BluedotFleetWordmark from "assets/images/logo/BluedotFleetWordmark.png";

type Props = {};

function PrivacyPage({}: Props) {
  const handleOpenLinkClicked = useCallback((url: string) => {
    window.open(url, "_blank");
  }, []);

  return (
    <>
      <Head title={"Privacy Policy"}></Head>

      <div className="center">
        <div className="wide-md-fix p-4">
          <div className="w-100 d-flex my-3  justify-content-start ">
            <img width={220} src={BluedotFleetWordmark} alt="logo" />
          </div>
          <BlackSubtitleText100 className="my-2 mt-4">Bluedot Privacy Policy</BlackSubtitleText100>
          <BlackSubtitleText200 className="mt-3">
            This Privacy Policy describes how Bluedot Technologies, Inc. ("Bluedot," "we", “us” or "our") handles
            personal information that we collect through our digital properties that link to this Privacy Policy,
            including our website and mobile application (collectively, the “Service”), as well as through social media,
            our marketing activities, and other activities described in this Privacy Policy.
          </BlackSubtitleText200>
          <BlackSubtitleText200 className="mt-3">
            Bluedot may provide additional or supplemental privacy policies to individuals for specific products or
            services that we offer at the time we collect personal information.
          </BlackSubtitleText200>
          <BlackSubtitleText200 className="mt-3">
            We use your personal data to provide and improve the service. By using the service, you agree to the
            collection and use of information in accordance with this Privacy Policy. Bluedot requires location
            permission to find chargers, restaurants, and grocery stores near you and this also means that while you are
            driving in the free driving mode you earn oxygen points even when the app is in the background. Please note
            that tracking will stop automatically if you log out or remove the app from the background.
          </BlackSubtitleText200>
          <BlackSubtitleText100 className="my-2 mt-4">Personal information we collect</BlackSubtitleText100>
          <BlackBodyText100 className="my-2 mt-4">Information you provide to us.</BlackBodyText100>
          <BlackBodyText200>
            The personal information you may provide to us through the Service or otherwise includes:
          </BlackBodyText200>
          <ul className="mt-2">
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Contact data, </span> such as your first and last name, email
                address, billing and mailing addresses, and phone number.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Demographic Information, </span> such as your city, state,
                country of residence, postal code, and age.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Profile data, </span> such as the username and password that
                you may set to establish an online account on the Service, date of birth, biographical details,
                photograph, interests, preferences, car model, and any other information that you add to your account
                profile.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Communications </span> that we exchange with you, including
                when you contact us through the Service, social media, or otherwise.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Transactional data, </span> such as information relating to or
                needed to complete your orders on or through the Service, including order numbers and transaction
                history.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Marketing data, </span> such as your preferences for receiving
                our marketing communications and details about your engagement with them.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; User-generated content, </span> such as photos, images, music,
                videos, comments, questions, messages, works of authorship, and other content or information that you
                generate, transmit or otherwise make available on the Service, as well as associated metadata. Metadata
                includes information on how, when, where and by whom a piece of content was collected and how that
                content has been formatted or edited. Metadata also includes information that users can add or can have
                added to their content, such as keywords, geographical or location information, and other similar data.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Relationship data, </span> such as familial or other
                relationships to third parties whose personal information you may provide to us.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Financial information, </span> such as your stated income,
                economic standing, financial account information.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Government-issued identification numbers, </span> such as
                national identification number (e.g., Social Security Number, passport number), state or local
                identification number (e.g., driver’s license or state ID number), or other identification information
                (e.g., military ID, tribal identification, alien identification), and an image of the relevant
                identification card.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Identity or address verification information, </span> that you
                may provide to allow us to confirm your identity or address, such as utility bills, account statements,
                lease agreements, pay stubs, or tax return information.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Payment information </span> is needed to complete transactions,
                including payment card information or bank account number.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Promotional information, </span> including information you
                share when you enter a competition, promotion, or complete a survey. Please note that if you participate
                in sweepstakes, contests,s or giveaways through the Service, we may ask you for your Contact Data to
                notify you if you win or not, to verify your identity, and/or to send you prizes. In some situations, we
                may need additional information as a part of the entry process, such as a prize selection choice. These
                sweepstakes and contests are voluntary. We recommend that you read the rules and other relevant
                information for each sweepstake and contest that you enter.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Location data, </span> Bluedot requires location permission to
                find chargers, restaurants, and grocery stores near you and this also means that while you are driving
                in the free driving mode you earn oxygen points even when the app is in the background. Please note that
                tracking will stop automatically if you log out or remove the app from the background.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Usage data, </span> that is collected automatically when using
                the Service. Usage Data may include information such as your Device’s Internet Protocol address (e.g. IP
                address), browser type, browser version, the pages of our Service that you visit, the time and date of
                your visit, the time spent on those pages, unique device identifiers and other diagnostic data. When You
                access the Service by or through a mobile device, we may collect certain information automatically,
                including, but not limited to, the type of mobile device You use, your mobile device unique ID, the IP
                address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You
                use, unique device identifiers and other diagnostic data. We may also collect information that Your
                browser sends whenever You visit our Service or when You access the Service by or through a mobile
                device.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Other data </span> not specifically listed here, which we will
                use as described in this Privacy Policy or as otherwise disclosed at the time of collection.
              </BlackBodyText200>
            </li>
          </ul>
          <BlackBodyText100 className="my-2 mt-4">Third-party sources.</BlackBodyText100>
          <BlackBodyText200>
            We may combine personal information we receive from you with personal information we obtain from other
            sources, such as:
          </BlackBodyText200>
          <ul className="mt-2">
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Public sources, </span> such as social media platforms and
                other publicly available sources.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Marketing partners, </span> such as joint marketing partners.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Third-party services, </span> such as social media services,
                that you use to log into, or otherwise link to, your Service account. This data may include your
                username, profile picture and other information associated with your account on that third-party service
                that is made available to us based on your account settings on that service.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Other data </span> not specifically listed here, which we will
                use as described in this Privacy Policy or as otherwise disclosed at the time of collection.
              </BlackBodyText200>
            </li>
          </ul>
          <BlackBodyText100 className="my-2 mt-4">Automatic data collection.</BlackBodyText100>
          <BlackBodyText200>
            We, our service providers, and our business partners may automatically log information about you, your
            computer or mobile device, and your interaction over time with the Service, our communications and other
            online services, such as:
          </BlackBodyText200>
          <ul className="mt-2">
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Device data, </span> such as your computer’s or mobile device’s
                operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk
                size, CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers (including
                identifiers used for advertising purposes), language settings, mobile device carrier, radio/network
                information (e.g., Wi-Fi, LTE, 3G), and general location information such as city, state or geographic
                area.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Online activity data, </span> such as pages or screens you
                viewed, how long you spent on a page or screen, the website you visited before browsing to the Service,
                navigation paths between pages or screens, information about your activity on a page or screen, access
                times and duration of access, and whether you have opened our emails or clicked links within them.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Location data </span> when you authorize the Service to access
                your device’s location. <span className="text-black-0">Cookies.</span> Some of our automatic data
                collection is facilitated by cookies and similar technologies.
              </BlackBodyText200>
            </li>
          </ul>
          <BlackBodyText100 className="my-2 mt-4">Cookies and similar technologies. </BlackBodyText100>
          <BlackBodyText200>
            Some of the automatic collection described above is facilitated by the following technologies:
          </BlackBodyText200>
          <ul className="mt-2">
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Cookies, </span> which are small text files that websites store
                on user devices and that allow web servers to record users’ web browsing activities and remember their
                submissions, preferences, and login status as they navigate a site. Cookies used on our sites include
                both “session cookies” that are deleted when a session ends, “persistent cookies” that remain longer,
                “first party” cookies that we place and “third party” cookies that our third-party business partners and
                service providers place.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Local storage technologies, </span> like HTML5, that provide
                cookie-equivalent functionality but can store larger amounts of data on your device outside of your
                browser in connection with specific applications. them.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Web beacons, </span> also known as pixel tags or clear GIFs,
                which are used to demonstrate that a webpage or email address was accessed or opened, or that certain
                content was viewed or clicked.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Session Replay Technologies. </span> We use third party
                services provided by Segment and Hotjar that employ software code to record users’ interactions with the
                Service in a manner that allows us to watch DVR-like replays of those user sessions. The replays include
                users’ clicks, mobile app touches, mouse movements, and scrolls during those sessions. These replays
                help us diagnose usability problems and identify areas for improvement. You can learn more about Segment
                and Hotjar at{" "}
                <LinkTextPrimary onClick={() => handleOpenLinkClicked("https://segment.com/legal/privacy/")}>
                  https://segment.com/legal/privacy/
                </LinkTextPrimary>{" "}
                and at{" "}
                <LinkTextPrimary onClick={() => handleOpenLinkClicked("https://www.hotjar.com/privacy/")}>
                  https://www.hotjar.com/privacy/
                </LinkTextPrimary>{" "}
                and you can opt-out of tracking by Hotjar at{" "}
                <LinkTextPrimary
                  onClick={() => handleOpenLinkClicked("https://www.hotjar.com/legal/policies/do-not-track/")}
                >
                  https://www.hotjar.com/legal/policies/do-not-track/
                </LinkTextPrimary>
                .
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Technical operation. </span> To allow the technical operation
                of the Service, such as by remembering your selections and preferences as you navigate the site, and
                whether you are logged in when you visit password protected areas of the Service.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Functionality. </span> To help our third-party advertising
                partners collect information about how you use the Service and other online services over time, which
                they use to show you ads on other online services they believe will interest you and measure how the ads
                perform.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Analytics. </span> To help us understand user activity on the
                Service, including which pages are most and least visited and how visitors move around the Service, as
                well as user interactions with our emails.
              </BlackBodyText200>
            </li>
          </ul>
          <BlackBodyText100 className="my-2 mt-4">Data about others. </BlackBodyText100>
          <BlackBodyText200>
            We may offer features that help users invite their friends or contacts to use the Service, and we may
            collect contact details about these invitees so we can deliver their invitations. Please do not refer
            someone to us or share their contact details with us unless you have their permission to do so. When you use
            the invitation function and grant our application access to your address book, we will access your address
            book to identify your friends.
          </BlackBodyText200>
          <BlackSubtitleText100 className="my-4 mt-4">How we use your personal information</BlackSubtitleText100>
          <BlackBodyText200>
            We may use your personal information for the following purposes or as otherwise described at the time of
            collection:
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Service delivery.</BlackBodyText100>
          <BlackBodyText200>We may use your personal information to:</BlackBodyText200>
          <ul className="mt-2">
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022;</span> provide, operate and improve the Service and our
                business;
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022;</span> determine your eligibility for getting Bluedot Card
                (Bluedot offers its services currently only to EV-owners);
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022;</span> establish and maintain your user profile on the Service;
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022;</span> facilitate your invitations to friends who you want to
                invite to join the Service;
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022;</span> enable security features of the Service, such as by
                sending you security codes via email or SMS, and remembering devices from which you have previously
                logged in;
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022;</span> communicate with you about the Service, including by
                sending announcements, updates, security alerts, and support and administrative messages;
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022;</span> understand your needs and interests, and personalize
                your experience with the Service and our communications; and provide support for the Service, and
                respond to your requests, questions, and feedback.
              </BlackBodyText200>
            </li>
          </ul>
          <BlackBodyText100 className="my-2 mt-4">Research and development.</BlackBodyText100>
          <BlackBodyText200>
            We may use your personal information for research and development purposes, including to analyze and improve
            the Service and our business.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Marketing and advertising.</BlackBodyText100>
          <BlackBodyText200>
            We, our service providers and our third-party advertising partners may collect and use your personal
            information for marketing and advertising purposes:
          </BlackBodyText200>
          <ul className="mt-2">
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Direct marketing. </span> We may send you direct marketing
                communications.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Interest-based advertising. </span> Our third-party advertising
                partners may use cookies and similar technologies to collect information about your interaction
                (including the data described in the "Automatic data collection" section above) with the Service, our
                communications and other online services over time, and use that information to serve online ads that
                they think will interest you. This is called interest-based advertising. We may also share information
                about our users with these companies to facilitate interest-based advertising to those or similar users
                on other online platforms.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0">
                  {" "}
                  &#x2022; To manage our recruiting and process employment applications.
                </span>
                We may use personal information, such as information submitted to us in a job application, to facilitate
                our recruitment activities and process employment applications, such as by evaluating a job candidate
                for an employment activity, and monitoring recruitment statistics.
              </BlackBodyText200>
            </li>
          </ul>
          <BlackBodyText100 className="my-2 mt-4">Compliance and protection..</BlackBodyText100>
          <BlackBodyText200>We may use your personal information to:</BlackBodyText200>
          <ul className="mt-2">
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022;</span>comply with applicable laws, lawful requests, and legal
                processes, such as to respond to subpoenas or requests from government authorities;
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022;</span> protect our, your or others’ rights, privacy, safety or
                property (including by making and defending legal claims);
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022;</span> audit our internal processes for compliance with legal
                and contractual requirements or our internal policies;
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022;</span> enforce the terms and conditions that govern the
                Service; and prevent, identify, investigate and deter fraudulent, harmful, unauthorized, unethical or
                illegal activity, including cyberattacks and identity theft.
              </BlackBodyText200>
            </li>
          </ul>
          <BlackBodyText100 className="my-2 mt-4">With your consent.</BlackBodyText100>
          <BlackBodyText200>
            In some cases, we may specifically ask for your consent to collect, use or share your personal information,
            such as when required by law.{" "}
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">
            To create anonymous, aggregated or de-identified data.
          </BlackBodyText100>
          <BlackBodyText200>
            We may create anonymous, aggregated or de-identified data from your personal information and other
            individuals whose personal information we collect. We make personal information into anonymous, aggregated
            or de-identified data by removing information that makes the data identifiable to you. We may use this
            anonymous, aggregated or de-identified data and share it with third parties for our lawful business
            purposes, including to analyze and improve the Service and promote our business.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Cookies and similar technologies.</BlackBodyText100>
          <BlackBodyText200>
            We may use these for the purposes outlined in the "Cookies and similar technologies" section above.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Retention. </BlackBodyText100>
          <BlackBodyText200>
            We generally retain personal information to fulfill the purposes for which we collected it, including for
            the purposes of satisfying any legal, accounting, or reporting requirements, to establish or defend legal
            claims, or for fraud prevention purposes. To determine the appropriate retention period for personal
            information, we may consider factors such as the amount, nature, and sensitivity of the personal
            information, the potential risk of harm from unauthorized use or disclosure of your personal information,
            the purposes for which we process your personal information and whether we can achieve those purposes
            through other means, and the applicable legal requirements. When we no longer require the personal
            information we have collected about you, we may either delete it, anonymize it, or isolate it from further
            processing.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">How we share your personal information</BlackBodyText100>
          <BlackBodyText200>
            We may share your personal information with the following parties and as otherwise described in this Privacy
            Policy or at the time of collection.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Affiliates.</BlackBodyText100>
          <BlackBodyText200>
            Our corporate parent, subsidiaries, and affiliates, for purposes consistent with this Privacy Policy.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Service providers.</BlackBodyText100>
          <BlackBodyText200>
            Third parties that provide services on our behalf or help us operate the Service or our business (such as
            hosting, information technology, customer support, email delivery, marketing, consumer research and website
            analytics). For example, we use Piermont to provide FDIC insured bank accounts for our users.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Payment processors.</BlackBodyText100>
          <BlackBodyText200>
            Any payment card information you use to make a purchase on the Service is collected and processed directly
            by our payment processors, such as Stripe. Stripe may use your payment data in accordance with its privacy
            policy,{" "}
            <LinkTextPrimary onClick={() => handleOpenLinkClicked("https://stripe.com/privacy")}>
              https://stripe.com/privacy
            </LinkTextPrimary>
            .
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Mobile wallet providers.</BlackBodyText100>
          <BlackBodyText200>
            We may share your account or payment card information with mobile wallet providers, such as Apple Pay or
            Google Pay, to enable you to use your Bluedot accounts with these services. Apple Pay and Google Pay may use
            your payment data in accordance with their respective privacy policies,{" "}
            <LinkTextPrimary onClick={() => handleOpenLinkClicked("https://www.apple.com/legal/privacy/ ")}>
              https://www.apple.com/legal/privacy/
            </LinkTextPrimary>{" "}
            and{" "}
            <LinkTextPrimary
              onClick={() =>
                handleOpenLinkClicked(
                  "https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en. "
                )
              }
            >
              https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en.
            </LinkTextPrimary>{" "}
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Advertising partners.</BlackBodyText100>
          <BlackBodyText200>
            Third-party advertising companies for the interest-based advertising purposes described above.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Third parties designated by you.</BlackBodyText100>
          <BlackBodyText200>
            We may share your personal data with third parties where you have instructed us or provided your consent to
            do so. For example, we may share your personal information with individuals that you add as contacts or that
            you interact with to send or receive payments. We may also share your personal information with third-party
            advertisers with whom we are collaborating to offer you additional services such as sweepstakes, raffles and
            promotions. We will share personal information that is needed for these other companies to provide the
            services that you have requested.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Business and marketing partners.</BlackBodyText100>
          <BlackBodyText200>
            Third parties with whom we co-sponsor events or promotions, with whom we jointly offer products or services,
            or whose products or services may be of interest to you.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Linked third-party services. </BlackBodyText100>
          <BlackBodyText200>
            If you log into the Service with, or otherwise link your Service account to, a social media or other
            third-party service, we may share your personal information with that third-party service. The third party’s
            use of the shared information will be governed by its privacy policy and the settings associated with your
            account with the third-party service.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Professional advisors. </BlackBodyText100>
          <BlackBodyText200>
            Professional advisors, such as lawyers, auditors, bankers and insurers, where necessary in the course of the
            professional services that they render to us.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Authorities and others</BlackBodyText100>
          <BlackBodyText200>
            Law enforcement, government authorities, and private parties, as we believe in good faith to be necessary or
            appropriate for the "Compliance and protection" purposes described above.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Business transferees. </BlackBodyText100>
          <BlackBodyText200>
            Acquirers and other relevant participants in business transactions (or negotiations of or due diligence for
            such transactions) involving a corporate divestiture, merger, consolidation, acquisition, reorganization,
            sale or other disposition of all or any portion of the business or assets of, or equity interests in,
            Bluedot or our affiliates.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Other users and the public.</BlackBodyText100>
          <BlackBodyText200>
            Your name and profile information may be visible to other users of the Service and the public. For example,
            other users of the Service or the public may have access to your information when adding contacts or sending
            payments to other users through the Service. This information can be seen, collected and used by others and
            we are not responsible for any such use of this information. We make commercially reasonable efforts to
            verify that the parties with whom our mobile application shares personal information provide a level of
            protection of personal information consistent with the practices described in this Privacy Policy, except
            that all such parties described above other than service providers and affiliates may, to the extent
            permitted by law, use personal information as described in their own privacy policies.
          </BlackBodyText200>
          <BlackSubtitleText100 className="my-2 mt-4">Your choices </BlackSubtitleText100>
          <BlackBodyText100 className="my-2 mt-4">Access or update your information. </BlackBodyText100>
          <BlackBodyText200>
            You are able to update your Bluedot profile information, email, phone number, VIN, and address by accessing
            the “Profile” tab in the Service. When you update information, we may maintain a copy of the unrevised
            information in our records.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Opt-out of marketing communications. </BlackBodyText100>
          <BlackBodyText200>
            You may opt-out of marketing-related emails by following the opt-out or unsubscribe instructions at the
            bottom of the email. Please note that if you choose to opt-out of marketing-related emails, you may continue
            to receive service-related and other non-marketing emails. If you receive marketing text messages from us,
            you may opt out of receiving further marketing text messages from us by replying STOP to our marketing
            message.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Advertising choices. </BlackBodyText100>
          <BlackBodyText200>You can limit use of your information for interest-based advertising by:</BlackBodyText200>
          <ul className="mt-2">
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Browser settings.</span> Blocking third-party cookies in your
                browser settings.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Privacy browsers/plug-ins.</span> By using privacy browsers or
                ad-blocking browser plug-ins that let you block tracking technologies.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Platform settings. </span> Google and Facebook offer opt-out
                features that let you opt-out of use of your information for interest-based advertising:
              </BlackBodyText200>
              <BlackBodyText200>
                Google:{" "}
                <LinkTextPrimary onClick={() => handleOpenLinkClicked(" https://adssettings.google.com/ ")}>
                  https://adssettings.google.com/
                </LinkTextPrimary>
              </BlackBodyText200>
              <BlackBodyText200>
                Facebook:{" "}
                <LinkTextPrimary onClick={() => handleOpenLinkClicked(" https://www.facebook.com/about/ads ")}>
                  https://www.facebook.com/about/ads
                </LinkTextPrimary>
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Ad industry tools.</span> Opting out of interest-based ads from
                companies participating in the following industry opt-out programs:
              </BlackBodyText200>
              <BlackBodyText200>
                Network Advertising Initiative:{" "}
                <LinkTextPrimary
                  onClick={() => handleOpenLinkClicked("http://www.networkadvertising.org/managing/opt_out.asp  ")}
                >
                  http://www.networkadvertising.org/managing/opt_out.asp
                </LinkTextPrimary>
              </BlackBodyText200>
              <BlackBodyText200>Digital Advertising Alliance: optout.aboutads.info.</BlackBodyText200>
              <BlackBodyText200>
                AppChoices mobile app, available at:{" "}
                <LinkTextPrimary onClick={() => handleOpenLinkClicked("https://www.youradchoices.com/appchoices")}>
                  https://www.youradchoices.com/appchoices
                </LinkTextPrimary>
                , which will allow you to opt-out of interest-based ads in mobile apps served by participating members
                of the Digital Advertising Alliance.
              </BlackBodyText200>
            </li>
            <li className="my-1">
              <BlackBodyText200>
                <span className="text-black-0"> &#x2022; Mobile settings.</span> Using your mobile device settings to
                limit use of the advertising ID associated with your mobile device for interest-based advertising
                purposes. You will need to apply these opt-out settings on each device from which you wish to opt-out.
              </BlackBodyText200>
              <BlackBodyText200>
                We cannot offer any assurances as to whether the companies we work with participate in the opt-out
                programs described above.{" "}
              </BlackBodyText200>
            </li>
          </ul>
          <BlackBodyText100 className="my-2 mt-4">Do Not Track.</BlackBodyText100>
          <BlackBodyText200>
            Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you
            visit. We currently do not respond to “Do Not Track” or similar signals.{" "}
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Declining to provide information.</BlackBodyText100>
          <BlackBodyText200>
            We need to collect personal information to provide certain services. If you do not provide the information
            we identify as required or mandatory, we may not be able to provide those services. For example, you can
            limit the car information you provide to the Service, but this may limit your ability to obtain
            automotive&charging related deals through the Service.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Linked third-party platforms.</BlackBodyText100>
          <BlackBodyText200>
            If you choose to connect to the Service through your social media account or other third-party platform, you
            may be able to use your settings in your account with that platform to limit the information we receive from
            it. If you revoke our ability to access information from a third-party platform, that choice will not apply
            to information that we have already received from that third party.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Delete your content or close your account.</BlackBodyText100>
          <BlackBodyText200>
            You can choose to delete certain content through your account. If you wish to request to close your account,
            please contact us as outlined below. Some limited information may remain in our records after we delete your
            account, but this does not include any bank account information.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Requests to Delete</BlackBodyText100>
          <BlackBodyText200>
            You have the right to request that we delete any Personal Data about you that we have collected. Upon
            receiving a verified request to delete Personal Data, we will do so unless otherwise authorized by law. You
            may submit a request to delete Personal Data by emailing help@thebluedot.co
          </BlackBodyText200>
          <BlackBodyText200 className="mt-2">
            Upon receipt of a request, we may ask you for additional information to verify your identity. Any additional
            information you provide will be used only to verify your identity and not for any other purpose.
          </BlackBodyText200>
          <BlackBodyText200 className="mt-2">
            We will acknowledge the receipt of your request within ten (10) days of receipt. Subject to our ability to
            verify your identity, we will respond to your request within 45 days of receipt. In order to protect your
            privacy and the security of Personal Data about you, we typically verify your request by requesting
            additional identifying information relating to you and/or you’re your mobile device.
          </BlackBodyText200>
          <BlackSubtitleText100 className="my-2 mt-4">Other sites and services</BlackSubtitleText100>
          <BlackBodyText200 className="mt-4">
            The Service may contain links to websites, mobile applications, and other online services operated by third
            parties. In addition, our content may be integrated into web pages or other online services that are not
            associated with us. These links and integrations are not an endorsement of, or representation that we are
            affiliated with, any third party. We do not control websites, mobile applications or online services
            operated by third parties, and we are not responsible for their actions. We encourage you to read the
            privacy policies of the other websites, mobile applications and online services you use.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Security </BlackBodyText100>
          <BlackBodyText200>
            We employ a number of technical, organizational and physical safeguards designed to protect the personal
            information we collect. However, security risk is inherent in all internet and information technologies and
            we cannot guarantee the security of your personal information.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">International data transfer </BlackBodyText100>
          <BlackBodyText200>
            We are headquartered in the United States and may use service providers that operate in other countries.
            Your personal information may be transferred to the United States or other locations where privacy laws may
            not be as protective as those in your state, province, or country.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Children </BlackBodyText100>
          <BlackBodyText200>
            The Service is not intended for use by anyone under 18 years of age. If you are a parent or guardian of a
            child from whom you believe we have collected personal information in a manner prohibited by law, please
            contact us. If we learn that we have collected personal information through the Service from a child without
            the consent of the child’s parent or guardian as required by law, we will comply with applicable legal
            requirements to delete the information.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">Changes to this Privacy Policy</BlackBodyText100>
          <BlackBodyText200 className=" mb-4">
            We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy
            Policy, we will notify you by updating the date of this Privacy Policy and posting it on the Service or
            other appropriate means. Any modifications to this Privacy Policy will be effective upon our posting the
            modified version (or as otherwise indicated at the time of posting). In all cases, your use of the Service
            after the effective date of any modified Privacy Policy indicates your acceptance of the modified Privacy
            Policy.
          </BlackBodyText200>
          <BlackBodyText100 className="my-2 mt-4">How to contact us</BlackBodyText100>
          <BlackBodyText200>Via the Bluedot in-app Support Chat feature</BlackBodyText200>
          <BlackBodyText200>Email: info@thebluedot.co</BlackBodyText200>{" "}
        </div>
      </div>
    </>
  );
}

export default PrivacyPage;
