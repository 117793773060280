import { Channel } from "@sendbird/uikit-react";
import React, { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router";

import Content from "../../layout/content/Content";

import { useSendbirdSdkAccessor } from "../../hooks/driver-support.hooks";
import Head from "../../layout/head/Head";
import { ErrorBoundary } from "react-error-boundary";
import SendbirdDataProvider from "wrappers/SendBirdProvider";
import CircularLoadingSpinner from "components/loading/CircularLoadingSpinner";

export default function DriverSupportMessageRoom() {
  const { messageRoomId } = useParams();
  const { isSenbirdSdkAvailable } = useSendbirdSdkAccessor();

  if (!messageRoomId) {
    return <>Message room id could not retrieve from urls</>;
  }

  return (
    <div className="flex flex-1 ">
      <Head title={"Messages"}></Head>
      <Content>
        <div className="w-100  position-relative py-2  message-room-container">
          {isSenbirdSdkAvailable ? (
            <ErrorBoundary onError={(e) => console.log("ErrorBoundary", e)}>
              <Channel channelUrl={messageRoomId} />
            </ErrorBoundary>
          ) : (
            <CircularLoadingSpinner />
          )}
        </div>
      </Content>
    </div>
  );
}
