import { Auth } from "aws-amplify";
import AuthFormInput from "components/auth-components/form-components/AuthFormInput";
import AuthPasswordInput from "components/auth-components/form-components/AuthPasswordInput";
import AuthPasswordInputs, {
  PasswordFormValues,
  ValidatePasswordRef,
} from "components/auth-components/form-components/AuthPasswordInputs";
import CancelButton from "components/button/CancelButton";
import PrimaryButton from "components/button/PrimaryButton";
import SaveButton from "components/button/SaveButton";
import SaveNewPasswordButton from "components/button/SaveNewPasswordButton";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import InputControllerWithValidationError from "components/form/InputControllerWithValidationError";
import EditorModal from "components/modal/EditorModal";
import AlertConfirmDialog from "components/notification/AlertConfirmDialog";
import AlertGotItDialog from "components/notification/AlertGotItDialog";
import { BlackBodyText200, BlackH5Text100 } from "components/text/Text";
import { useAlertManager } from "hooks/alert.hooks";
import { useAuthStatus } from "hooks/auth.hooks";
import { useAppDispatch } from "hooks/redux.hooks";
import React, { SyntheticEvent, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Form } from "reactstrap";

import { handleApiErrorResponse } from "utils";
import SettingsPageFormBox from "../SettingsPageFormBox";

type Props = {};

interface FormValues extends PasswordFormValues {
  currentPassword: string;
}

function SettingsPageChangePasswordEditor({}: Props) {
  const { handleOpenErrorAlert } = useAlertManager();

  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const { user } = useAuthStatus();
  const history = useHistory();

  const dispatch = useAppDispatch();
  const validatePasswordImperativeRef = React.useRef<ValidatePasswordRef>(null);

  const { register, handleSubmit, errors, trigger, control, reset, getValues } = useForm<FormValues>({
    mode: "all",
  });

  /**
   *
   */
  const forgotPasswordCodeSubmitAsync = useCallback(
    async (values: FormValues) => {
      const isValidated = validatePasswordImperativeRef?.current?.validatePassword();

      if (!isValidated) {
        return;
      }

      try {
        setIsLoading(true);
        await Auth.changePassword(await Auth.currentAuthenticatedUser(), values.currentPassword, values.password);
        setIsFormDialogOpen(false);
        setIsSuccessDialogOpen(true);
      } catch (error) {
        handleOpenErrorAlert(handleApiErrorResponse(error));
      } finally {
        setIsLoading(false);
      }
    },
    [handleOpenErrorAlert]
  );

  /**
   *
   */
  const handleFormSubmit = useCallback(
    async (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
      e.preventDefault();

      await trigger();

      handleSubmit(forgotPasswordCodeSubmitAsync)(e);
    },
    [handleSubmit, forgotPasswordCodeSubmitAsync, trigger]
  );

  return (
    <>
      <AlertGotItDialog
        isDialogOpen={isSuccessDialogOpen}
        title="Success!"
        description="Your password has been changed. You can now log in using your new password. "
        onGotItButtonPressed={() => {
          history.push("/");
        }}
      />

      <EditorModal
        setIsModalOpen={setIsFormDialogOpen}
        isLoading={isLoading}
        isModalOpen={isFormDialogOpen}
        onSubmit={handleFormSubmit}
        isCloseIconVisible={false}
        title={"Change password"}
      >
        <BlackBodyText200 className="mb-4">Enter and confirm new credentials.</BlackBodyText200>
        <AuthPasswordInput
          name="currentPassword"
          data-testid="currentPassword"
          register={register}
          required
          className="w-max-100 w-100"
          placeholder={"Current password"}
        />
        <AuthPasswordInputs
          isForgotPasswordFlow
          getFormValues={getValues}
          ref={validatePasswordImperativeRef}
          register={register}
        />
      </EditorModal>
      <PrimaryButton text="Change password" onClick={() => setIsFormDialogOpen(true)} />
    </>
  );
}

export default SettingsPageChangePasswordEditor;
