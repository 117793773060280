import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router";

import Row from "../../custom/Row";

import { useFleetGroupBadgeDeeds } from "../../../hooks/fleet-group.hooks";
import DynamicVehicleList from "../../list/DynamicVehicleList";

import AddDriverButton from "components/button/AddDriverButton";
import FilterSearchInput from "components/filter/FilterSearchInput";
import PaginatedTable from "components/table/PaginatedTable";
import { useDriversAsync } from "hooks/data/driver-data-accessor.hooks";
import { Driver, DriverBadgeStatus } from "types/driver.types";
import { FleetGroup } from "types/fleet-group.types";
import { TableColumn } from "types/table.types";
import { getWhoPaysDisplayTextByPayerType } from "../../../utils/charging.utils";
import RightChevron from "../../icon/icons/RightChevron";
import DynamicFleetGroupBadgeList from "../../list/DynamicFleetGroupBadgeList";
import DriversTableFilterMenu from "../filter/DriversTableFilterMenu";
import PaginatedTableCsvLinkButton from "../../button/PaginatedTableCsvLinkButton";
import { getDriversAsync } from "../../../services/driver.services";
import { getDriverRowForCsv } from "../../../utils/driver.utils";
import DriverStatusBadge from "../../badge/DriverStatusBadge";

export type DriversTableColumn = TableColumn<Driver>[];
const DriversPageDriversTable = () => {
  const { t } = useTranslation("common");

  const { drivers, meta, isDriversLoading } = useDriversAsync();

  const fleetGroups: FleetGroup[] = useMemo(() => {
    return (
      drivers
        .reduce<FleetGroup[]>((acc, driver) => {
          return acc.concat(driver.groups);
        }, [])
        .flat<FleetGroup[]>() ?? []
    );
  }, [drivers]);

  const history = useHistory();

  const { getGroupBadgeContent } = useFleetGroupBadgeDeeds(fleetGroups);

  const columns = useMemo(
    () => [
      {
        grow: 1.5,

        name: "Driver Name",
        selector: (row: Driver) => row.firstName + " " + row.lastName
      },
      {
        grow: 1,
        name: t("general.status"),
        selector: (row: Driver) => (
          <DriverStatusBadge status={row.active ? DriverBadgeStatus.ACTIVE : DriverBadgeStatus.NOT_ACTIVE} />
        )
      },

      {
        grow: 1.5,
        name: "Vehicles",
        selector: (row: Driver) => (row.vehicles?.length > 0 ? <DynamicVehicleList vehicles={row.vehicles} /> : "-")
      },

      {
        grow: 1.5,
        name: "Who Pays",
        selector: (row: Driver) => <>{getWhoPaysDisplayTextByPayerType(row?.payer)} </>
      },

      {
        grow: 1.5,
        name: "Group",
        selector: (row: Driver) => {
          return <DynamicFleetGroupBadgeList getGroupBadgeContent={getGroupBadgeContent} fleetGroups={row.groups} />;
        }
      },
      {
        grow: 0.3,
        name: "",
        cell: (row: Driver) => <RightChevron widthValue={5} />
      }
    ],
    [getGroupBadgeContent, t]
  ) as DriversTableColumn;

  return (
    <>
      <Row className="justify-between my-2">
        <FilterSearchInput placeholder="Search driver" />

        <Row>
          <PaginatedTableCsvLinkButton
            fileName="drivers"
            csvRowFormatter={getDriverRowForCsv}
            totalPages={meta?.pagination.totalPages ?? 0}
            rowSizePerPage={meta?.pagination.size ?? 20}
            fetchListAsync={getDriversAsync}
          />


          <div className={"ml-4"}>
            <AddDriverButton />
          </div>
        </Row>

      </Row>

      <DriversTableFilterMenu />

      <PaginatedTable
        columns={columns}
        data={drivers}
        paginationTotalRows={meta?.pagination.totalElements}
        isLoading={isDriversLoading}
        getRoutePath={id => `/drivers/${id}`}
      />
    </>
  );
};

export default DriversPageDriversTable;
