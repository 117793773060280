import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Form, FormGroup } from "reactstrap";
import { addFleetGroupAsync, updateFleetGroupAsync } from "../../services/fleet-group.services";
import CancelButton from "../button/CancelButton";
import SaveButton from "../button/SaveButton";
import Col from "../custom/Col";
import Row from "../custom/Row";
import ControllerValidationError from "../form/ControllerValidationError";
import ReactSelectWithCustomFooter from "../select/ReactSelectWithCustomFooter";

import { CircularProgress } from "@mui/material";
import { BlackBodyText100 } from "components/text/Text";
import { useAlertManager } from "hooks/alert.hooks";
import { useAllDriversAsync } from "hooks/data/driver-data-accessor.hooks";
import { useFleetGroupAsync } from "hooks/data/fleet-group-data-accessor.hooks";
import { getDriverFullName, getDriversForDriverSelector } from "utils/driver.utils";
import Head from "../../layout/head/Head";
import NotFoundContent from "../not-found/NotFoundContent";

export default function FleetGroupsForm({ isEditMode }: { isEditMode: boolean }) {
  const { fleetGroupId } = useParams<{ fleetGroupId: string }>();

  const { fleetGroup, isLoading } = useFleetGroupAsync(fleetGroupId);

  const { t } = useTranslation("common");
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { allDrivers, isLoading: isDriverOptionsLoading } = useAllDriversAsync();
  const driverOptions = getDriversForDriverSelector(allDrivers);

  const { register, handleSubmit, errors } = useForm();

  const defaultValues = useMemo(() => {
    return {
      defaultDrivers:
        fleetGroup?.users?.map((u) => {
          return { label: getDriverFullName(u), value: u.id };
        }) ?? [],
      defaultGroupName: fleetGroup?.name
    };
  }, [fleetGroup?.name, fleetGroup?.users]);

  const [driverSelector, setDriverSelector] = useState({
    error: "",
    data: defaultValues.defaultDrivers,
    errorMessage: t("fleet_groups.common.form.drivers.error")
  });

  const { handleCloseAlert, handleOpenErrorAlert, handleShowAlertByType } = useAlertManager();

  useEffect(() => {
    setDriverSelector((s) => {
      return { ...s, data: defaultValues.defaultDrivers };
    });
  }, [defaultValues.defaultDrivers]);

  /**
   * Utils
   */
  const redirectToFleetGroupsPage = useCallback(() => {
    // Wait 1 sec to show user the success message.
    setTimeout(() => {
      history.push("/fleet-groups");
    }, 1000);
  }, [history]);

  const validateDriverSelector = useCallback(() => {
    if (isEditMode) {
      return true;
    }

    const { data, errorMessage, error } = driverSelector;

    const isDataEntered = data && data?.length > 0;

    const newErrorStatus = isDataEntered ? "" : errorMessage;
    if (newErrorStatus !== error) {
      setDriverSelector({
        ...driverSelector,
        error: newErrorStatus
      });
    }

    return isDataEntered;
  }, [driverSelector, isEditMode]);

  const handleInitFormFlags = useCallback(() => {
    setIsSubmitting(true);
    handleCloseAlert();
  }, [handleCloseAlert]);

  /**
   *
   */
  const handleValidatedSubmitEdit = useCallback(
    async (validatedData) => {
      const groupId = fleetGroup?.id;
      if (!groupId) {
        return;
      }

      const areTheSelectorsValidated = validateDriverSelector();
      if (!areTheSelectorsValidated) {
        return;
      }

      handleInitFormFlags();

      let isSuccess = true;
      let message = "An error occurred!";

      const submittedSelectorData = driverSelector?.data?.map((v) => v.value);

      try {
        const finalData = {
          ...validatedData,
          users: submittedSelectorData
        };

        await updateFleetGroupAsync(groupId, finalData);

        message = "Fleet Group was updated successfully!";

        redirectToFleetGroupsPage();
      } catch (err: any) {
        const errorMessageFromBackend = err?.response?.data?.message;

        isSuccess = false;
        message = errorMessageFromBackend;
      } finally {
        setIsSubmitting(false);
        handleShowAlertByType(isSuccess, message);
      }
    },
    [
      fleetGroup?.id,
      validateDriverSelector,
      handleInitFormFlags,
      driverSelector?.data,
      redirectToFleetGroupsPage,
      handleShowAlertByType
    ]
  );

  /**
   *
   */
  const handleValidatedSubmitAdd = useCallback(
    async (validatedData) => {
      const areTheSelectorsValidated = validateDriverSelector();
      if (!areTheSelectorsValidated) {
        return;
      }

      handleInitFormFlags();

      let isSuccess = true;
      let message = "Fleet Group successfully added!";

      const submittedSelectorData = driverSelector.data?.map((v) => v.value);

      const finalData = {
        ...validatedData,
        users: submittedSelectorData
      };

      try {
        await addFleetGroupAsync(finalData);

        redirectToFleetGroupsPage();
      } catch (err: any) {
        const errorMessageFromBackend = err?.response?.data?.message;
        isSuccess = false;
        message = errorMessageFromBackend;
      } finally {
        setIsSubmitting(false);
        handleShowAlertByType(isSuccess, message);
      }
    },
    [driverSelector.data, handleInitFormFlags, handleShowAlertByType, redirectToFleetGroupsPage, validateDriverSelector]
  );

  /**
   *
   */
  const handleSelectAllPressed = useCallback(() => {
    setDriverSelector({
      ...driverSelector,
      data: driverOptions
    });
  }, [driverOptions, driverSelector]);

  /**
   *
   */
  const handleDriverSelected = useCallback(
    (value) => {
      setDriverSelector({
        ...driverSelector,
        data: value
      });
    },
    [driverSelector]
  );

  /**
   *
   */
  const handleSelectClearPressed = useCallback(() => {
    setDriverSelector({
      ...driverSelector,
      data: []
    });
  }, [driverSelector]);

  const handleValidatedSubmit = isEditMode ? handleValidatedSubmitEdit : handleValidatedSubmitAdd;

  if (isEditMode && isLoading ) {
    return (
      <div className="center h-350px">
        <CircularProgress size={75} style={{ color: "#1E44FF" }} />
      </div>
    );
  }


  if (isEditMode && !isLoading && !fleetGroup) {
    return <>
      <NotFoundContent />
    </>;
  }

  return (
    <Col className="d-flex flex-1 flex-column p-1">
      <BlackBodyText100 className="lh-22px">
        Efficiently manage your EV fleet by creating fleet groups tailored to your organization&apos;s structure. Adding
        fleet groups allows you to group drivers, and vehicles, ensuring a smooth and well-organized experience.
      </BlackBodyText100>
      <div className="horizontal-border-line my-3" />
      <Form
        className="w-100 h-100 center flex-column"
        onReset={() => history.push("/fleet-groups")}
        onSubmit={handleSubmit(handleValidatedSubmit)}
      >
        <Col>
          <FormGroup>
            <label className="form-label">{t("fleet_groups.common.form.fleet_group_name.title")}</label>
            <input
              defaultValue={fleetGroup?.name ?? undefined}
              className="form-control"
              type="text"
              name="name"
              placeholder={t("fleet_groups.common.form.fleet_group_name.placeholder") ?? ""}
              ref={register({
                required: "Group name must be at least 2 characters long.",
                validate: (str) => {
                  if (str?.trim()?.length < 2) {
                    return "Group name must be at least 2 characters long.";
                  }
                }
              })}
            />
            <ControllerValidationError error={errors?.name?.message} />
          </FormGroup>
        </Col>
        <Col className="mt-3">
          <FormGroup>
            <label className="form-label">{t("fleet_groups.common.form.drivers.title")}</label>
            <ReactSelectWithCustomFooter
              onSelectAllPressed={handleSelectAllPressed}
              onSelectClearPressed={handleSelectClearPressed}
              defaultValue={defaultValues?.defaultDrivers}
              noOptionsMessage={() => (isDriverOptionsLoading ? "Loading..." : "No drivers")}
              name={"drivers"}
              placeholder={isDriverOptionsLoading ? "Loading..." : t("fleet_groups.common.form.drivers.placeholder")}
              isMulti
              options={driverOptions}
              value={driverSelector.data}
              onChange={handleDriverSelected}
            />
            <ControllerValidationError error={driverSelector.error} />
          </FormGroup>
        </Col>

        <Row className="row w-100 justify-end align-center mt-4">
          <CancelButton />
          <SaveButton isDisabled={isSubmitting} isLoading={isSubmitting} />
        </Row>
      </Form>
    </Col>
  );
}
