import { createSlice } from "@reduxjs/toolkit";

export const chargingSessionsSlice = createSlice({
  name: "chargingSessions",
  initialState: {
    chargingSessions: [],
    vehicleToBeUpdated: null,
  },
  reducers: {
    setChargingSessions: (state, action) => {
      state.chargingSessions = action.payload;
    },
  },
});

export const { setChargingSessions } = chargingSessionsSlice.actions;
export default chargingSessionsSlice.reducer;
