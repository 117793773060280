import React from "react";
import FleetGroupsTablePlaceholder from "images/FleetGroupsTablePlaceholder.png";
import { BlockLabel, BlockText } from "../block/Block";
import Card from "../card/Card";
export default function FleetGroupsTablePlaceHolder() {
  return (
    <Card className="d-flex flex-1 flex-column justify-center align-center mt-3 fleet_group_table_placeholder">
      <img alt="fleet group" src={FleetGroupsTablePlaceholder} className="w-150px w-max-100 h-auto mb-4" />
      <BlockText className="mt-2 text-center">
        Looks like you haven't created any fleet groups for your drivers yet.
      </BlockText>
      <BlockLabel className="fleet_group_table_placeholder__label mt-1 p-2">
        Fleet groups allow you to organize your fleet and assign drivers to specific groups. To get started, click the
        'Create Fleet Group' button and give your group a name.
      </BlockLabel>
    </Card>
  );
}
