import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationMeta } from "types/api.types";
import { Vehicle, VehicleSliceState } from "../../types/vehicle.types";

const initialState: VehicleSliceState = {
  meta: null,
  vehicles: [],
  isVehiclesLoading: false,
  helperMessage: null
};


export const vehicleSlice = createSlice({
    name: "vehicle",
    initialState,
    reducers: {
      setVehiclesAndMeta: (state, action: PayloadAction<{ vehicles: Vehicle[]; meta: PaginationMeta }>) => {
        state.vehicles = action.payload.vehicles;
        state.meta = action.payload.meta;
      },
      setIsVehiclesLoading: (state, action) => {
        state.isVehiclesLoading = action.payload;
      },
      setVehicleHelperMessage: (state, action: PayloadAction<string | null>) => {
        state.helperMessage = action.payload;
      }
    }
  })
;

export const { setVehiclesAndMeta, setIsVehiclesLoading, setVehicleHelperMessage } = vehicleSlice.actions;
export default vehicleSlice.reducer;
