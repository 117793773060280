import React, { PropsWithChildren } from "react";
import Row from "../../custom/Row";
import TableCheckbox from "./TableCheckbox";

type Props = {
  isChecked?: boolean;
  isClearable?: boolean;
  onToggle: (isChecked?: boolean) => void;
}

const ToggleCheckbox = ({ isChecked, isClearable, onToggle }: Props) => {
  return (
    <Row className="align-items-center clickable py-1" onClick={onToggle}>
      <TableCheckbox isChecked={isChecked} isClearable={isClearable} />
    </Row>
  );
};

export default ToggleCheckbox;