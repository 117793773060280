import Col from "components/custom/Col";
import Row from "components/custom/Row";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import AuthSentCodeBanner from "assets/images/auth/AuthSentCodeBanner.png";
import SignUpFormFlowConfirmationStep from "components/auth-components/form-components/SignUpFormFlowConfirmationStep";
import AuthFlowBackButton from "components/button/AuthFlowBackButton";
import AuthBluedotFleetWordMark from "components/auth-components/form-components/AuthBluedotFleetWordMark";

type Props = {};
type LocationState = {
  email?: string;
};

function UnConfirmedUserPage({}: Props) {
  const [unConfirmedUserEmail, setUnConfirmedUserEmail] = useState<string | undefined>(undefined);
  const location = useLocation<LocationState>();
  const history = useHistory();

  useEffect(() => {
    const email = location?.state?.email;
    if (email) {
      setUnConfirmedUserEmail(email);
      return;
    }
    history.replace("/auth");
  }, [history, location?.state?.email]);

  return (
    <>
      <Row className="screen-height-container">
        <Col className="bg-white scrollable-container">
          {unConfirmedUserEmail && (
            <div className="auth-form-container">
              <AuthFlowBackButton />
              <AuthBluedotFleetWordMark />
              <div className="mt-4">
                <SignUpFormFlowConfirmationStep emailAddressCodeSent={{ original: unConfirmedUserEmail }} />
              </div>
            </div>
          )}
        </Col>
        <Col className={`h-100 d-none d-lg-flex align-items-center justify-content-center`}>
          <img width={400} src={AuthSentCodeBanner} alt="Auth Sent Code Banner" />
        </Col>
      </Row>
    </>
  );
}

export default UnConfirmedUserPage;
