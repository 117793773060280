import React, { useState } from "react";
import { FormGroup } from "reactstrap";

import Col from "components/custom/Col";
import { NumericFormat } from "react-number-format";

import { BlackBodyText100, BlackOverlineText300 } from "components/text/Text";
import { ErrorBoundary } from "react-error-boundary";
import { Controller } from "react-hook-form";
import { DriverFormStepsCommonProps } from "types/driver.types";
import { PayerType } from "types/payment.types";
import LearnMoreTextButton from "../../button/LearnMoreTextButton";
import DriverFormResponsibilityModal from "../DriverFormResponsibilityModal";


type Props = DriverFormStepsCommonProps & { isApprovalForm?: boolean };

export default function DriverFormChargingStep({ register, control, isApprovalForm }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <ErrorBoundary fallback={<>An error occured</>}>
      <div className="d-flex flex-column w-100  p-0">
        <Col md={isApprovalForm ? "6" : "12"}>
          <FormGroup>

            <BlackBodyText100>Monthly Charging Limit (Optional)</BlackBodyText100>
            <BlackOverlineText300>Set the driver's monthly spending limit for in app EV charging.</BlackOverlineText300>
            <div className="w-100 relative mt-2 d-flex align-items-center">
              {/**     <span className="position-absolute dollar-prefix-text">$</span> */}
              <Controller
                // onValueChange={(e) => handleErrorMessage(e.value)}
                control={control}
                prefix="$"
                as={NumericFormat}
                decimalScale={2} // decimalScale prop to limit the decimal points up to 2
                allowNegative={false} // allowNegative prop to disallow negative values
                thousandSeparator
                name="credit"
                className="form-control"
                placeholder="Type charging spending limit (optional)"

                // defaultValue={driverFormState?.credit}
              />
            </div>
          </FormGroup>
        </Col>

        <Col className="mt-4">
          <BlackBodyText100>Payment Responsibility</BlackBodyText100>
          <BlackOverlineText300>
            Choose who will cover the cost of EV charging.{" "}
            <LearnMoreTextButton onClick={() => setIsModalOpen(true)} />
          </BlackOverlineText300>

          <FormGroup
            name="payer"
            className="custom-control custom-radio form-control  d-flex align-items-center  my-2"
            htmlFor="organization"
          >
            <input
              type="radio"
              className="custom-control-input h-100"
              name="payer"
              id="organization"
              value={PayerType.ORGANIZATION}
              ref={register({})}
            />
            <label className="custom-control-label w-100 " htmlFor="organization">
              Organization Pays
            </label>
          </FormGroup>

          <FormGroup
            className="custom-control custom-radio form-control  d-flex align-items-center "
            htmlFor="driver"
            name="payer"
          >
            <input
              type="radio"
              className="custom-control-input"
              name="payer"
              id="driver"
              value={PayerType.DRIVER}
              ref={register({})}
            />
            <label className="custom-control-label w-100" htmlFor="driver">
              Driver Pays
            </label>
          </FormGroup>
        </Col>
      </div>

      <DriverFormResponsibilityModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </ErrorBoundary>
  );
}
