import React from "react";
import { SvgProps } from "../../../types/svg.types";

const VehiclePlaceholder = ({ fill, width, height, className }: SvgProps) => {
  const fillValue = fill ?? "#CBD5E1";
  const widthValue = width ?? 20;
  const heightValue = height ?? 16;

  return (
    <div className={`w-100 h-100 d-flex justify-center align-items-center   ${className ?? "bg-secondary"}`}>
      <svg width={widthValue} height={heightValue} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.9374 7.4374C19.895 7.3245 19.8315 7.22219 19.7539 7.14104L17.8452 5.09473L16.1517 1.18558C16.0071 0.913917 15.8024 0.688118 15.559 0.532881C15.3191 0.374115 15.0474 0.292969 14.7687 0.292969H5.23219C4.95347 0.292969 4.68181 0.374115 4.43837 0.529352C4.19493 0.68459 3.99383 0.910389 3.84564 1.18205L2.15215 5.09121L0.246968 7.14104C0.16935 7.22571 0.105843 7.3245 0.0635061 7.4374C0.0211687 7.5503 0 7.67026 0 7.79374V15.4039C0 15.6403 0.0811466 15.8661 0.225799 16.0319C0.370452 16.1977 0.564498 16.293 0.769129 16.293H4.23021C4.53716 16.293 4.99934 15.9366 4.99934 15.5838V14.2961H15.0015V15.4074C15.0015 15.7638 15.3085 16.2965 15.6154 16.2965H19.2282C19.4329 16.2965 19.6269 16.2012 19.7716 16.0354C19.9162 15.8696 19.9974 15.6438 19.9974 15.4074V7.79727C19.9974 7.67379 19.9762 7.55383 19.9338 7.44093L19.9374 7.4374ZM4.20551 5.79683L5.56384 2.51568C5.62029 2.38161 5.75083 2.29341 5.89548 2.29341H14.116C14.2606 2.29341 14.3947 2.38161 14.4476 2.51568L15.7954 5.79683C15.8942 6.03321 15.7178 6.29429 15.4637 6.29429H4.53716C4.2796 6.29429 4.10673 6.03321 4.20551 5.79683ZM6.00132 10.6374C6.00132 11.0008 5.70849 11.2936 5.34509 11.2936H2.65667C2.29327 11.2936 2.00044 11.0008 2.00044 10.6374V8.95096C2.00044 8.52406 2.40265 8.21006 2.81544 8.3159L5.50386 8.98624C5.79669 9.06033 6.00132 9.32141 6.00132 9.6213V10.6374ZM18.004 10.6374C18.004 11.0008 17.7111 11.2936 17.3477 11.2936H14.6593C14.2959 11.2936 14.0031 11.0008 14.0031 10.6374V9.6213C14.0031 9.32141 14.2077 9.05681 14.5006 8.98624L17.189 8.3159C17.6018 8.21359 18.004 8.52406 18.004 8.95096V10.6374Z"
          fill={fillValue}
        />
      </svg>
    </div>
  );
};
export default VehiclePlaceholder;

/**
 *   width={`${widthValue}`}
      height={`${heightValue}`}
      viewBox={`0 0 ${widthValue} ${heightValue} `}
 */
