import { useTranslation } from "react-i18next";
import AddVehicleStarter from "../../components/vehicle-components/form/add-vehicle/AddVehicleFlowSelection";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

type Props = {};

function AddVehiclePage(props: Props) {
  const { t } = useTranslation("common");

  return (
    <>
      <Head title={t("vehicle.title.add_vehicles_page")}></Head>
      <Content>
        <AddVehicleStarter />
      </Content>
    </>
  );
}

export default AddVehiclePage;
