import React from "react";
import BluedotLogoIcon from "assets/images/logo/BluedotLogoIcon.png";
import BluedotFleetOSWordmarkAndLogo from "assets/images/logo/BluedotFleetOSWordmarkAndLogo.png";
import { Link } from "react-router-dom";
import BluedotFleetOsLogo from "components/icon/icons/BluedotFleetOsLogo";
import Row from "components/custom/Row";
import BluedotFleetOsWorkMark from "components/icon/icons/BluedotFleetOsWorkMark";

const Logo = ({ isCompact }) => {
  return (
    <Link to={`${process.env.PUBLIC_URL}/`}>
      <Row className={`${isCompact ? "compact-logo" : ""} align-items-center`}>
        <BluedotFleetOsLogo />
        {!isCompact && <BluedotFleetOsWorkMark className="menu-item-text-spacing" />}
      </Row>
    </Link>
  );
};

export default Logo;
