import React from "react";
import TickCircleIcon, { TickCircleIconProps } from "./TickCircleIcon";

type Props = TickCircleIconProps;

function CheckedTickCircleIcon(props: Props) {
  return <TickCircleIcon fill="#2BA318" {...props} />;
}

export default CheckedTickCircleIcon;
