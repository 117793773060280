type Props = {};

function PinIconFilledWithXIcon({}: Props) {
  return (
    <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.54464 12.5938C4.27328 12.5938 0 16.867 0 22.1384V36.4554C0 41.7267 4.27328 46 9.54464 46H10.0382H22.8149H42.9509C48.2222 46 52.4955 41.7267 52.4955 36.4554V22.1384C52.4955 16.867 48.2222 12.5938 42.9509 12.5938H9.54464Z"
        fill="black"
      />
      <path
        d="M9.27667 33.5331L9.64075 30.1882L6.9557 32.1678L6 30.5295L9.09464 29.2325L6.02275 27.981L6.91019 26.3881L9.6635 28.3678L9.27667 25.0001H11.097L10.7102 28.3678L13.4408 26.3881L14.3055 27.9582L11.2336 29.2552L14.3282 30.5295L13.3953 32.1451L10.7102 30.1882L11.0743 33.5331H9.27667Z"
        fill="white"
      />
      <path
        d="M20.4762 33.5331L20.8403 30.1882L18.1553 32.1678L17.1996 30.5295L20.2942 29.2325L17.2223 27.981L18.1098 26.3881L20.8631 28.3678L20.4762 25.0001H22.2966L21.9098 28.3678L24.6403 26.3881L25.505 27.9582L22.4331 29.2552L25.5278 30.5295L24.5948 32.1451L21.9098 30.1882L22.2739 33.5331H20.4762Z"
        fill="white"
      />
      <path
        d="M31.6758 33.5331L32.0399 30.1882L29.3548 32.1678L28.3991 30.5295L31.4938 29.2325L28.4219 27.981L29.3093 26.3881L32.0626 28.3678L31.6758 25.0001H33.4962L33.1093 28.3678L35.8399 26.3881L36.7046 27.9582L33.6327 29.2552L36.7273 30.5295L35.7944 32.1451L33.1093 30.1882L33.4734 33.5331H31.6758Z"
        fill="white"
      />
      <path
        d="M42.9485 33.533L43.3125 30.1881L40.6275 32.1677L39.6718 30.5294L42.7664 29.2324L39.6945 27.9809L40.582 26.388L43.3353 28.3677L42.9485 25H44.7688L44.382 28.3677L47.1126 26.388L47.9772 27.9581L44.9054 29.2551L48 30.5294L47.0671 32.145L44.382 30.1881L44.7461 33.533H42.9485Z"
        fill="white"
      />
      <rect x="42" y="38" width="14" height="13" rx="6.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.9979 52.8356C53.6005 52.8356 57.3317 49.1044 57.3317 44.5018C57.3317 39.8991 53.6005 36.168 48.9979 36.168C44.3952 36.168 40.6641 39.8991 40.6641 44.5018C40.6641 49.1044 44.3952 52.8356 48.9979 52.8356ZM50.8836 44.62C50.8185 44.5549 50.8185 44.4494 50.8836 44.3843L52.8282 42.4397C52.9909 42.2769 52.9909 42.0131 52.8282 41.8504L51.6496 40.6718C51.4869 40.5091 51.2231 40.5091 51.0604 40.6718L49.1157 42.6165C49.0506 42.6815 48.9451 42.6815 48.88 42.6165L46.9353 40.6718C46.7726 40.5091 46.5088 40.5091 46.346 40.6718L45.1675 41.8504C45.0047 42.0131 45.0047 42.2769 45.1675 42.4397L47.1121 44.3843C47.1772 44.4494 47.1772 44.5549 47.1121 44.62L45.1675 46.5647C45.0047 46.7274 45.0047 46.9912 45.1675 47.154L46.346 48.3325C46.5088 48.4953 46.7726 48.4953 46.9353 48.3325L48.88 46.3879C48.9451 46.3228 49.0506 46.3228 49.1157 46.3879L51.0603 48.3325C51.2231 48.4953 51.4869 48.4953 51.6496 48.3325L52.8282 47.154C52.9909 46.9912 52.9909 46.7274 52.8282 46.5647L50.8836 44.62Z"
        fill="#C4003E"
      />
    </svg>
  );
}

export default PinIconFilledWithXIcon;
