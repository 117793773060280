import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import PageHeader from "components/header/PageHeader";
import ReimbursementStats from "../../components/reimbursement/ReimbursementStats";
import ReimbursementsTable from "../../components/reimbursement/ReimbursementsTable";
import { useReimbursementOnboardingStatus } from "../../hooks/reimbursement.hooks";
import Col from "../../components/custom/Col";
import { NeutralBodyMediumText100, NeutralH5TextBlack, NeutralTitleMediumText100 } from "../../components/text/Text";
import Row from "../../components/custom/Row";
import PrimaryButton from "../../components/button/PrimaryButton";
import CustomModalWithXIcon from "../../components/modal/CustomModalWithXIcon";
import InfoBadge from "../../components/badge/InfoBadge";
import { useRemount } from "../../hooks/render.hooks";

const ReimbursementsPage = () => {
  const { isMounted, remount } = useRemount();

  const { isFirstBrowserLogin } = useReimbursementOnboardingStatus();

  const [isOnboardingVisible, setIsOnboardingVisible] = useState(isFirstBrowserLogin);

  useEffect(() => {
    setIsOnboardingVisible(isFirstBrowserLogin);
  }, [isFirstBrowserLogin]);


  if (!isMounted) {
    return <Head title={"Reimbursements"} />;
  }


  const tutorialSource = `https://${process.env.REACT_APP_S3_FLEETS_BUCKET}.s3.amazonaws.com/tutorials/HomeChargingReimbursement.mp4`

  return (
    <React.Fragment>
      <CustomModalWithXIcon isModalOpen={isOnboardingVisible} setIsModalOpen={setIsOnboardingVisible}>
        <Col className={"h-max-80vh h-700px overflow-auto p-2"}>
          <Col className={"my-2 mb-3"}>
            <NeutralH5TextBlack>
              Activating Your Home Charging System
            </NeutralH5TextBlack>
            <NeutralBodyMediumText100 className="mt-2">Easily track and manage your team's home charging expenses.
              Ensure
              seamless reimbursement for electric vehicle charging costs incurred at home.</NeutralBodyMediumText100>
          </Col>

          <video autoPlay={true} width="100%" height="auto" controls className={"rounded-xxxl"}>
            <source src={tutorialSource}
                    type="video/mp4" />
          </video>

          <Col className={"my-2 mb-3 pl-1"}>
            <NeutralTitleMediumText100>&#x2022; {" "} Simplifies the reimbursement process by automating home charging
              expense tracking.</NeutralTitleMediumText100>
            <NeutralTitleMediumText100>&#x2022; {" "} Ensures accurate and fair compensation for drivers charging their
              vehicles at home.</NeutralTitleMediumText100>
            <NeutralTitleMediumText100>&#x2022; {" "} Enhances operational efficiency with an easy-to-use, integrated
              system.
              expense tracking.</NeutralTitleMediumText100>
          </Col>

          <Row className="my-2  w-100 align-items-center justify-content-end">
            <PrimaryButton text={"Got it"} onClick={() => setIsOnboardingVisible(false)} />
          </Row>
        </Col>
      </CustomModalWithXIcon>

      <Head title={"Reimbursements"} />
      <Content className="h-auto">
        <PageHeader title={"Home Charging Reimbursement"}
                    description={"Quickly access key metrics and vital information about your reimbursement process."} />

        <div className="my-4">
          <InfoBadge
            text={"Home charging reimbursement is currently available only for vehicles using Geotab. If you have activated HCR but it's not functioning, please ensure your vehicle is equipped with Geotab."} />
        </div>
        <ReimbursementStats />

        <ReimbursementsTable remount={remount} />
      </Content>;
    </React.Fragment>
  )
    ;
};

export default ReimbursementsPage;
