export enum AlertType {
  SUCCESS,
  ERROR,
  WARNING,
  SUCCESS_WITH_ACTION
}

export type AlertSliceState = {
  type: AlertType
  message: string,
  callback?: () => void,
  actionText?: string,
  isOpen: boolean
}