import BluedotFleetOsWordMarkAuth from "../../icon/icons/BluedotFleetOsWordMarkAuth";

type Props = {};

function AuthBluedotFleetWordMark({}: Props) {
  return (
    <div className="w-100 d-flex my-4  justify-content-start ">
     <BluedotFleetOsWordMarkAuth/>
    </div>
  );
}

export default AuthBluedotFleetWordMark;
