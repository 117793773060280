import React from "react";
import { SvgProps } from "types/svg.types";
import InfoIcon from "./InfoIcon";

function BlackInfoIcon(props: SvgProps) {
  return (
    <div>
      <InfoIcon {...props} fill="black" />
    </div>
  );
}

export default BlackInfoIcon;
