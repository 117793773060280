import PasswordEyeHideIcon from "components/icon/icons/PasswordEyeHideIcon";
import { useState } from "react";
import PasswordEyeIcon from "../../icon/icons/PasswordEyeIcon";
import AuthFormInput, { AuthFormInputProps } from "./AuthFormInput";

type AuthPasswordInputProps = AuthFormInputProps;

export default function AuthPasswordInput(props: AuthPasswordInputProps) {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const defaultPasswordInputProps = {
    inputMode: "password",
    placeholder: "Enter your password",
    label: "Password",
    required: true,
    name: "password",
    type: "password",
    "aria-label": "password",
  };

  const inputProps = {
    ...defaultPasswordInputProps,
    ...props,
    type: props.type ?? (isPasswordShown ? "text" : "password"),
  };

  return (
    <div className="position-relative">
      <AuthFormInput {...inputProps} />
      {isPasswordShown ? (
        <PasswordEyeHideIcon className="password-eye-icon clickable" onClick={() => setIsPasswordShown(false)} />
      ) : (
        <PasswordEyeIcon className="password-eye-icon clickable" onClick={() => setIsPasswordShown(true)} />
      )}
    </div>
  );
}
