import restApiClient from "./client";
import { ApiResponse } from "types/api.types";
import { HeaderStats } from "types/dashboard.types";
import { generateApiEndpoint } from "utils";

/**
 * GET: '/api/admin/organization/dashboard/header-stats'
 */
export async function getHeaderStatisticsAsync(): Promise<ApiResponse<HeaderStats>> {
    const endpoint = generateApiEndpoint("organization/dashboard/header-stats");
    const response = await restApiClient.get(endpoint);

    return response.data;
}