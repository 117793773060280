export enum SettingsPageTabs {
  ACCOUNT = "account", SECURITY = "security",
  PREFERENCES = "preferences",
}


export type NotificationSetting = {
  email: boolean;
  sms: boolean;
  push: boolean;
}

export type InboxNotificationSettings = {
  chargingSuspicious: NotificationSetting,
  vehicleDriverPinSet: NotificationSetting,
  driverInvoiceUnpaid: NotificationSetting,
  driverSignupCreated: NotificationSetting,
  vehicleNotAssigned: NotificationSetting,
  vehicleAssignmentEndsSoon: NotificationSetting,
  driverPaymentMethodMissing: NotificationSetting
}


export enum InboxSettingsKey {
  ChargingSuspicious = "chargingSuspicious",
  DriverInvoiceUnpaid = "driverInvoiceUnpaid",
  VehicleNotAssigned = "vehicleNotAssigned",
  VehicleAssignmentEndsSoon = "vehicleAssignmentEndsSoon",
  VehicleDriverPinSet = "vehicleDriverPinSet",
  NewDriverSignup = "driverSignupCreated",
  DriverPaymentMethodMissing = "driverPaymentMethodMissing"
};