import { useTranslation } from "react-i18next";
import Card from "../../components/card/Card";
import LoadingSkeleton from "../../components/loading/LoadingSkeleton";
import VehicleHeader from "../../components/vehicle-components/VehiclePageHeader";
import VehicleForm from "../../components/vehicle-components/form/VehicleManualFlowForm";
import { useVehicleAsync, } from "../../hooks/data/vehicle-data-accessor.hooks";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";

export default function EditVehicle() {
  const { t } = useTranslation("common");

  const { isLoading,vehicle} = useVehicleAsync()

  return (
    <>
      <Head title={t("vehicle.title.edit_vehicles_page")}></Head>
      <Content>
        <VehicleHeader title={"Edit Vehicle"} />

        <Card className="mt-4 py-4 px-1 center ">
          {isLoading && !vehicle ? (
            <LoadingSkeleton height={30} count={10} />
          ) : (
            <VehicleForm isEditMode={true} defaultDataForEditMode={vehicle} />
          )}
        </Card>
      </Content>
    </>
  );
}
