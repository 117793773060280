import { SvgProps } from "types/svg.types";

type Props = {};

function SettingsLockIconFilled(props: SvgProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75862 7.39602L3.75827 7.39637C3.51384 7.64046 3.3998 7.91638 3.3998 8.25598V17.346C3.3998 17.6854 3.51373 17.9616 3.75862 18.2068L3.75862 7.39602ZM3.75862 7.39602C4.00241 7.15217 4.27784 7.03848 4.6178 7.03848H5.5268H7.34488H12.799H14.617H15.526C15.866 7.03848 16.1419 7.15218 16.3864 7.39626C16.6302 7.64019 16.744 7.91614 16.744 8.25598V17.346C16.744 17.6856 16.6303 17.9618 16.3862 18.2067C16.1417 18.4507 15.8659 18.5643 15.526 18.5643H4.6178C4.27791 18.5643 4.00252 18.4506 3.75877 18.2069L3.75862 7.39602Z"
        stroke="#6F7981" strokeWidth="1.2" />
      <path
        d="M10.0719 14.6192C10.5719 14.6192 11.0001 14.4417 11.3564 14.0859C11.7121 13.7292 11.89 13.3009 11.89 12.8009C11.89 12.3009 11.7121 11.8734 11.3564 11.5167C11.0001 11.1609 10.5719 10.9834 10.0719 10.9834C9.57199 10.9834 9.14416 11.1609 8.78841 11.5167C8.43207 11.8734 8.25391 12.3009 8.25391 12.8009C8.25391 13.3009 8.43207 13.7292 8.78841 14.0859C9.14416 14.4417 9.57199 14.6192 10.0719 14.6192Z"
        fill="#6F7981" stroke="#6F7981" strokeWidth="1.2" />
      <path
        d="M5.52637 4.62019V6.43853H7.34445V4.62019C7.34445 3.8627 7.60953 3.21853 8.13978 2.68853C8.67011 2.15853 9.31395 1.8927 10.0714 1.8927C10.829 1.8927 11.4729 2.15853 12.0031 2.68853C12.5334 3.21853 12.7985 3.8627 12.7985 4.62019V6.43853H14.6165V4.62019C14.6165 3.3627 14.1735 2.29103 13.2876 1.40436C12.4009 0.517696 11.3289 0.0751953 10.0714 0.0751953C8.81403 0.0751953 7.74228 0.517696 6.85628 1.40436C5.9697 2.29103 5.52637 3.3627 5.52637 4.62019Z"
        fill="#6F7981" />
    </svg>
  );
}

export default SettingsLockIconFilled;
