import { BlackBodyText100, BlackSubtitleText100 } from "components/text/Text";
import { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";

import Col from "components/custom/Col";
import Row from "components/custom/Row";
import PermanentDriverIconLight from "components/icon/icons/PermanentDriverIconLight";
import HorizontalLine from "components/line/HorizontalLine";

import { RSelect } from "components/Component";
import ControllerValidationError from "components/form/ControllerValidationError";

import InputControllerWithValidationError from "components/form/InputControllerWithValidationError";
import { getCitiesByStateForSelector, getCountriesForSelector, getStatesForSelector } from "consts/state.consts";
import { useTranslation } from "react-i18next";
import {
  Country,
  DriverFormAddressSelectorKey,
  DriverFormAddressSelectorState,
  DriverFormStepsCommonProps,
} from "types/driver.types";
import { SelectorStringOption } from "types/editor.types";

type Props = DriverFormStepsCommonProps & {
  addressSelectors: DriverFormAddressSelectorState;
  setAddressSelectors: (key: DriverFormAddressSelectorKey, data: SelectorStringOption) => void;
};
export default function DriverFormAddressStep({
  addressSelectors,
  setAddressSelectors,
  register,
  errors,
  control,
}: Props) {
  const { t } = useTranslation("common");

  const countryOptions = getCountriesForSelector();
  const [cityOptions, setCityOptions] = useState<SelectorStringOption[]>([]);
  const [stateOptions, setStateOptions] = useState<SelectorStringOption[]>([]);

  /**
   *
   */
  const selectedCountry = addressSelectors?.country?.data;
  const isCountrySelected = !!selectedCountry?.value;

  const selectedState = addressSelectors?.state?.data;
  const isStateSelected = !!selectedState?.value;

  const addressStateSelectorName = DriverFormAddressSelectorKey.STATE;
  const addressCitySelectorName = DriverFormAddressSelectorKey.CITY;
  const addressCountrySelectorName = DriverFormAddressSelectorKey.COUNTRY;

  const addressStateSelector = addressSelectors[addressStateSelectorName];
  const addressCitySelector = addressSelectors[addressCitySelectorName];
  const addressCountrySelector = addressSelectors[addressCountrySelectorName];

  useEffect(() => {
    const selectedCountryValue = selectedCountry?.value as Country;
    if (selectedCountryValue) {
      setStateOptions(getStatesForSelector(selectedCountryValue));
    }
  }, [selectedState, selectedCountry]);

  useEffect(() => {
    const selectedCountryValue = selectedCountry?.value as Country;
    const selectedStateValue = selectedState?.value;
    if (selectedStateValue && selectedCountryValue) {
      const cities = getCitiesByStateForSelector(selectedCountryValue, selectedStateValue);
      setCityOptions(cities);
    }
  }, [selectedState, selectedCountry]);

  return (
    <div className="d-flex flex-column w-100 my-2 p-0">
      <Row className="align-items-center mb-1">
        <PermanentDriverIconLight />
        <BlackSubtitleText100 className={"ml-3 font-weight-medium"}>Address</BlackSubtitleText100>
      </Row>

      <HorizontalLine />

      <Col className="mt-2">
        <InputControllerWithValidationError
          name="street1"
          placeholder={t("driver.common.form.add_driver_form.address_line.placeholder", { count: 1 }) ?? ""}
          formLabel={t("driver.common.form.add_driver_form.address_line.label", { count: 1 }) ?? ""}
          errors={errors}
          errorMessage='Address Line 1 is required"'
          register={register}
          control={control}
        />
      </Col>

      <Col className="mt-3">
        <FormGroup>
          <InputControllerWithValidationError
            name="street2"
            placeholder={"Address Line 2 (Optional)"}
            formLabel={"Address Line 2"}
            errors={errors}
            control={control}
            registerRef={register({ required: false })}
          />
        </FormGroup>
      </Col>

      <Col className="mt-3">
        <Row>
          <Col md="6" className="pr-md-2">
            <FormGroup>
              <BlackBodyText100 className="mb-1">Country</BlackBodyText100>
              <RSelect
                value={addressCountrySelector.data}
                name={addressCountrySelectorName}
                placeholder={t("Select...")}
                options={countryOptions}
                onChange={(data: SelectorStringOption) => setAddressSelectors(addressCountrySelectorName, data)}
              />
              <ControllerValidationError error={addressCountrySelector.error} />
            </FormGroup>
          </Col>
          <Col md="6" className="pl-md-2 mt-3  mt-md-0">
            <FormGroup>
              <BlackBodyText100 className="mb-1">{t("driver.common.form.add_driver_form.state")}</BlackBodyText100>
              <RSelect
                // inputRef={register({ required: "required" })}
                //isOptionDisabled={() => true}
                isDisabled={!isCountrySelected}
                value={addressStateSelector.data ?? 0}
                name={addressStateSelectorName}
                placeholder={t("Select...")}
                options={stateOptions}
                onChange={(data: SelectorStringOption) => setAddressSelectors(addressStateSelectorName, data)}
              />
              <ControllerValidationError error={addressStateSelector.error} />
            </FormGroup>
          </Col>
        </Row>
      </Col>

      <Col className="mt-3">
        <Row>
          <Col md="6" className="pr-md-2">
            <FormGroup>
              <BlackBodyText100 className="mb-1">{t("driver.common.form.add_driver_form.city")}</BlackBodyText100>
              <RSelect
                isDisabled={!isStateSelected}
                value={addressCitySelector.data ?? 0}
                name={addressCitySelectorName}
                placeholder={t("Select...")}
                options={cityOptions}
                onChange={(data: SelectorStringOption) => setAddressSelectors(addressCitySelectorName, data)}
              />
              <ControllerValidationError error={addressCitySelector.error} />
            </FormGroup>
          </Col>

          <Col md="6" className="pl-md-2 mt-3  mt-md-0">
            <InputControllerWithValidationError
              name="postalCode"
              placeholder={t("driver.common.form.add_driver_form.postal_code.placeholder") ?? ""}
              formLabel={t("driver.common.form.add_driver_form.postal_code.label") ?? ""}
              errors={errors}
              control={control}
              registerRef={register({
                required: "Postal code is required",
                pattern: {
                  value:
                    selectedCountry.value === Country.CANADA
                      ? /^([ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z])(?:[\s-]?([ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z])?)?$/i
                      : /^\s*\d{5}(?:[-\s]\d{4})?\s*$/,
                  message: "Postal code is not valid",
                },
              })}
            />
          </Col>
        </Row>
      </Col>
    </div>
  );
}
