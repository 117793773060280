import React from "react";
import Col from "../custom/Col";
import Row from "../custom/Row";
import FleetGroupsForm from "./FleetGroupsForm";
import FleetGroupImage from "../../images/fleet-groups-image.png";
import Card from "../card/Card";

export default function FleetGroupsMutationPagesContent({ isEditMode }:{isEditMode:boolean}) {
  return (
    <Card className="mt-3">
      <Row>
        <Col lg="6" className="center flex-1">
          <img width={375} alt="fleet group" src={FleetGroupImage} className="w-375px w-max-100 h-auto" />
        </Col>
        <Col lg="6">
          <FleetGroupsForm isEditMode={isEditMode} />
        </Col>
      </Row>
    </Card>
  );
}
