import { SortDirection, SortOption, SortableFields } from "types/filter.types";

export const getSortingOption = (
  field: SortableFields = SortableFields.Date,
  direction: SortDirection = SortDirection.DESC
): SortOption => {
  const isDirectionASC = direction === SortDirection.ASC;

  switch (field) {
    case SortableFields.Date:
      return {
        label: isDirectionASC ? "Oldest first" : "Newest first",
        value: {
          field,
          direction,
        },
      };

    case SortableFields.DriverName:
      return {
        label: isDirectionASC ? "Driver name (A-Z)" : "Driver name (Z-A)",
        value: {
          field,
          direction,
          isSeparatedFromTop: isDirectionASC,
        },
      };

    case SortableFields.VehicleName:
      return {
        label: isDirectionASC ? "Vehicle name (A-Z)" : "Vehicle name (Z-A)",
        value: {
          field,
          direction,
          isSeparatedFromTop: isDirectionASC,
        },
      };

    default:
      return {
        label: isDirectionASC ? "Oldest first" : "Newest first",
        value: {
          field,
          direction,
        },
      };
  }
};
