import { useAuthServicesAsync, useAuthStatus } from "hooks/auth.hooks";
import { useCallback, useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";

const context = "PrivateRoute";
const PrivateRoute = ({ exact, component: Component, user, ...rest }) => {
  let history = useHistory();

  const { logoutAsync } = useAuthServicesAsync();
  const { isAuthenticated, isUserActive, isUserApproved, isUserRejected, isUserPendingReview } = useAuthStatus();

  const handleRedirectInactiveUser = useCallback(async () => {
    const callback = "redirect";
    console.log(context, callback);
    const redirectUrl = `${process.env.PUBLIC_URL}/inactive-user`;
    history.replace(redirectUrl);
    await logoutAsync();
  }, [history, logoutAsync]);

  const validateAuthorization = useCallback(() => {
    const callback = "validateAuthorization";
    console.log(context, callback);

    const isCurrentRoutePathWaiting = window.location.href.includes("waiting-user");

    if (isUserPendingReview) {
      return history.push("/waiting-user?redirect=true");
    }

    if (isUserApproved && isUserActive) {
      if (isCurrentRoutePathWaiting) {
        history.push("/");
      }
      return;
    }

    if (!isUserActive) {
      return handleRedirectInactiveUser();
    }

    if (isUserRejected) {
      return handleRedirectInactiveUser();
    }
  }, [handleRedirectInactiveUser, history, isUserActive, isUserApproved, isUserRejected, isUserPendingReview]);

  useEffect(() => {
    if (isAuthenticated) {
      validateAuthorization();
    }
  }, [isAuthenticated, validateAuthorization]);

  return (
    <Route
      exact={exact}
      {...rest}
      render={(props) => (isAuthenticated ? <Component user={user} {...props} {...rest} /> : <Redirect to="/auth" />)}
    />
  );
};

export default PrivateRoute;
