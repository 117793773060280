import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import SendbirdDataProvider from "wrappers/SendBirdProvider";
import { useSendbirdSdkAccessor } from "../../hooks/driver-support.hooks";
import MessageIcon from "../icon/icons/MessageIcon";
import BaseButton from "./BaseButton";

function MessageButtonBase(props) {
  const text = props.text ?? "Message";
  const height = props.height ?? 42;
  const userId = props.userId;
  const history = useHistory();

  const [messageRoomUrl, setMessageRoomUrl] = useState(undefined);
  const { isSenbirdSdkAvailable, sdkInstance } = useSendbirdSdkAccessor();

  const getUserMessageRoomUrl = useCallback(
    async (userId_) => {
      const query = sdkInstance?.groupChannel?.createMyGroupChannelListQuery();

      if (!query) {
        return;
      }

      query.includeEmpty = true;

      const room = await query.next();

      if (room) {
        const correctRoom = room.find((r) => {
          return r?.members?.find((m) => {
            return m?.userId === userId_;
          });
        });

        setMessageRoomUrl(correctRoom?.url);
      }
    },
    [sdkInstance?.groupChannel]
  );

  useEffect(() => {
    if (isSenbirdSdkAvailable && userId) {
      getUserMessageRoomUrl(userId);
    }
  }, [getUserMessageRoomUrl, isSenbirdSdkAvailable, sdkInstance, userId]);

  const handleMessageButtonClicked = useCallback(() => {
    if (!messageRoomUrl) {
      return;
    }

    history.push(`/driver-support/message-room/${messageRoomUrl}`);
  }, [messageRoomUrl, history]);

  return (
    <BaseButton
      {...props}
      textOverride={
        <div className="d-flex flex-1 center ">
          <MessageIcon fill={"white"} />
          <span className="ml-2 font-weight-normal text-white title">{text}</span>
        </div>
      }
      isDisabled={!isSenbirdSdkAvailable || !messageRoomUrl}
      height={height}
      onClick={handleMessageButtonClicked}
    />
  );
}

export default function MessageButton(props) {
  return (
    <SendbirdDataProvider>
      <MessageButtonBase {...props} />
    </SendbirdDataProvider>
  );
}
