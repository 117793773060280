import React from "react";
import Card from "./Card";

export default function SecondaryCard(props) {
  return (
    <Card
      className={`card-secondary${props.error ? "--with-error" : ""} ${props.className ? props.className : ""}`}
      children={props.children}
    />
  );
}
