// React
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useHistory } from "react-router";

//Components
import AuthFlowBackButton from "../button/AuthFlowBackButton";
import ForgotPasswordFormFlowEmailStep from "./form-components/ForgotPasswordFormFlowEmailStep";
import ForgotPasswordFormFlowPasswordStep from "./form-components/ForgotPasswordFormFlowPasswordStep";

// Images

//Types
import { AuthForgotPasswordFormFlowSteps } from "../../types/auth.types";
import AuthBluedotFleetWordMark from "./form-components/AuthBluedotFleetWordMark";

type Props = {
  formFlowStep: AuthForgotPasswordFormFlowSteps;
  setFormFlowStep: Dispatch<SetStateAction<AuthForgotPasswordFormFlowSteps>>;
};

function ForgotPasswordFormFlow({ formFlowStep, setFormFlowStep }: Props) {
  /**
   * Hooks
   */

  const [emailAddressCodeSent, setEmailAddressCodeSent] = useState({
    encrypted: "",
    original: "",
  });

  const history = useHistory();

  /**
   *
   */
  const formStep = useMemo(() => {
    switch (formFlowStep) {
      case AuthForgotPasswordFormFlowSteps.EMAIL:
        return (
          <ForgotPasswordFormFlowEmailStep
            setEmailAddressCodeSent={setEmailAddressCodeSent}
            setFormFlowStep={setFormFlowStep}
          />
        );

      case AuthForgotPasswordFormFlowSteps.PASSWORD:
        return (
          <ForgotPasswordFormFlowPasswordStep
            emailAddressCodeSent={emailAddressCodeSent}
            setFormFlowStep={setFormFlowStep}
          />
        );

      default:
        return (
          <ForgotPasswordFormFlowEmailStep
            setEmailAddressCodeSent={setEmailAddressCodeSent}
            setFormFlowStep={setFormFlowStep}
          />
        );
    }
  }, [emailAddressCodeSent, formFlowStep, setFormFlowStep]);

  // Cheap function no need to cache with callback
  /**
   *
   */
  const handleBackButtonPressed = () => {
    if (formFlowStep === AuthForgotPasswordFormFlowSteps.PASSWORD) {
      setFormFlowStep(AuthForgotPasswordFormFlowSteps.EMAIL);
      return;
    }

    history.goBack();
  };

  /**
   * Render
   */
  return (
    <div className="auth-form-container">
      <AuthFlowBackButton onAuthFlowBackButtonPressed={handleBackButtonPressed} />

      <AuthBluedotFleetWordMark />

      <div className="py-2">{formStep}</div>
    </div>
  );
}

export default ForgotPasswordFormFlow;
