import TextButton, { TextButtonMode, TextButtonProps } from "./TextButton";
import { useMemo } from "react";

function UnderlinedTextButton({ mode, ...props }: TextButtonProps) {

  const textButtonClassName = useMemo(() => {
    switch (mode) {
      case TextButtonMode.NEGATIVE:
        return "btn-negative-text";

      case TextButtonMode.PRIMARY:
        return "btn-primary-text";

      default:
        return "btn-primary-text";
    }

  }, [mode]);


  return <TextButton {...props}
                     className={`${textButtonClassName} ${props.className}`}
                     textClassName={` clickable text-decoration-underline ${props.textClassName ?? ""}`} />;
}

export default UnderlinedTextButton;
