import React from "react";
import DashboardGraphPlaceholderIcon from "../../icon/icons/DashboardGraphPlaceholder";
import { BlackBodyText300 } from "../../text/Text";

export default function GraphPlaceholder({ button }: { button?: JSX.Element }) {
  return (
    <div className="center h-100 flex-column ">
      <DashboardGraphPlaceholderIcon />
      <BlackBodyText300 className={"my-3"}>No data to display</BlackBodyText300>
      {button && <div className="my-2">{button}</div>}
    </div>
  );
}
