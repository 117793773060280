import React from "react";

type Props = {};

function BluedotInAppIcon({}: Props) {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3270_8674)">
        <path
          d="M6.43208 5.43239C7.01672 4.84775 7.71224 4.37484 8.48504 4.04807L6.91256 0.325195C5.65676 0.856915 4.52528 1.62552 3.57524 2.57556C2.6252 3.5256 1.8566 4.65707 1.32488 5.91287C0.794 7.16867 0.5 8.55047 0.5 10.0003C0.5 11.4502 0.794 12.8311 1.32488 14.0878C1.8566 15.3436 2.6252 16.475 3.57524 17.4251C4.52528 18.3751 5.65676 19.1437 6.91256 19.6754L8.48504 15.9526C7.71224 15.6258 7.01588 15.1529 6.43208 14.5682C5.84744 13.9836 5.37452 13.2881 5.04776 12.5153C4.721 11.7425 4.5404 10.8924 4.5404 10.0012C4.5404 9.10991 4.721 8.25983 5.04776 7.48703C5.37452 6.71423 5.84744 6.01787 6.43208 5.43407V5.43239ZM18.4248 2.57556C17.4747 1.62552 16.3432 0.856915 15.0874 0.325195L13.515 4.04807C14.2878 4.37484 14.9841 4.84775 15.5679 5.43239C16.1526 6.01703 16.6255 6.71255 16.9522 7.48535C17.279 8.25815 17.4596 9.10823 17.4596 9.99947C17.4596 10.8907 17.279 11.7408 16.9522 12.5136C16.6255 13.2864 16.1526 13.9828 15.5679 14.5666C14.9833 15.1512 14.2878 15.6241 13.515 15.9509L15.0874 19.6738C16.3432 19.142 17.4747 18.3734 18.4248 17.4234C19.3748 16.4734 20.1434 15.3419 20.6751 14.0861C21.2068 12.8303 21.5 11.4485 21.5 9.99863C21.5 8.54879 21.206 7.16783 20.6751 5.91119C20.1434 4.6554 19.3748 3.52392 18.4248 2.57388V2.57556Z"
          fill="#1E44FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_3270_8674">
          <rect width="21" height="19.3494" fill="white" transform="translate(0.5 0.325195)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BluedotInAppIcon;
