import React from "react";

import Col from "components/custom/Col";
import Row from "components/custom/Row";
import VehicleDetailsOverviewCard from "../VehicleDetailsOverviewCard";
import { TeslaDataAccessorHook, VehicleDataAccessorHook } from "types/vehicle.types";
import VehicleDetailsLocationMap from "../VehicleDetailsLocationMap";
import VehicleDetailsLocationInfo from "../VehicleDetailsLocationInfo";
import { BlackBody2Text200, BlackSubtitleText100 } from "../../../text/Text";
import { getDateAsFromNowFormatWithFetchedPrefix } from "../../../../utils/date.utils";
import { useHistory } from "react-router";
import VehicleDetailsDriveEditors from "../VehicleDetailsDriveEditors";
import { getTeslaKeyControlInfoDetails, getVehicleConnectionStatus } from "../../../../utils/vehicle.utils";
import { useVehicleTeslaKeyStatusAsync } from "../../../../hooks/data/vehicle-data-accessor.hooks";
import VehicleDetailsVehicleStateCard from "../VehicleDetailsVehicleStateCard";
import Card from "../../../card/Card";
import VehicleDetailsLockStatusEditor from "../VehicleDetailsLockStatusEditor";
import VehicleDetailsRemoteStartEditor from "../VehicleDetailsRemoteStartEditor";
import VehicleDetailsTeslaControlEditors from "../VehicleDetailsTeslaControlEditors";
import LoadingSkeleton from "../../../loading/LoadingSkeleton";
import VehicleDetailsPortStatusEditor from "../VehicleDetailsPortStatusEditor";
import VehicleDetailsControlsContentVirtualKeyAlert from "../VehicleDetailsControlsContentVirtualKeyAlert";

type Props = {
  readonly vehicleHook: VehicleDataAccessorHook;
  readonly teslaHook: TeslaDataAccessorHook;
}

function VehicleDetailsControlContent({ vehicleHook, teslaHook }: Props): JSX.Element {
  const history = useHistory();
  const { isTeslaConnected } = getVehicleConnectionStatus(vehicleHook.vehicle);
  const keyStatusHook = useVehicleTeslaKeyStatusAsync(true);

  const isTeslaAwake = !teslaHook.isTeslaSleeping && teslaHook.teslaInfo;

  const { isNewProtocolAndKeyNotPaired, isControlsVisibleByKeyStatus } = getTeslaKeyControlInfoDetails(keyStatusHook);

  return (
    <Row>
      <Col lg={6} className="pr-lg-3">
        {vehicleHook.isLoading && <Card className={"mt-4"}><LoadingSkeleton count={4} height={40} /></Card>}


        {isTeslaConnected && (
          <VehicleDetailsOverviewCard
            className={"mt-4"}
            title={"Controls"}
            titleOverwrite={
              <Row className={"align-items-center"}>
                <BlackSubtitleText100 className={"mr-2"}>Controls</BlackSubtitleText100>
                {
                  isNewProtocolAndKeyNotPaired &&
                  <VehicleDetailsControlsContentVirtualKeyAlert />
                }
              </Row>
            }
            content={
              <VehicleDetailsDriveEditors
                teslaKeyStatusHook={keyStatusHook}
                teslaHook={teslaHook}
              />
            }
          />

        )}


        {isTeslaConnected &&
          <Card
            className={"mt-4"}>
            <VehicleDetailsVehicleStateCard teslaHook={teslaHook} />


            {isTeslaAwake && (
              <>
                <div className="horizontal-border-line my-2 w-100" />
                <VehicleDetailsLockStatusEditor isReadonly={!isControlsVisibleByKeyStatus} teslaHook={teslaHook} />

                <div className="horizontal-border-line my-2 w-100" />
                <VehicleDetailsPortStatusEditor isReadonly={!isControlsVisibleByKeyStatus} teslaHook={teslaHook} />

                <VehicleDetailsRemoteStartEditor isReadonly={!isControlsVisibleByKeyStatus} teslaHook={teslaHook} />
              </>
            )}
          </Card>
        }


        {isTeslaConnected && isTeslaAwake &&
          <Card
            className={"mt-4"}>
            <VehicleDetailsTeslaControlEditors isControlsVisibleByKeyStatus={isControlsVisibleByKeyStatus}
                                               teslaHook={teslaHook} />
          </Card>
        }


      </Col>
      <Col lg={6} className="pl-lg-3">

        <VehicleDetailsOverviewCard className={"mt-4"}
                                    onLinkClick={() => history.push("/vehicle-locator")}
                                    titleOverwrite={
                                      <Row className="align-items-center">
                                        <BlackSubtitleText100 className={"mr-2"}>Location</BlackSubtitleText100>
                                        <BlackBody2Text200>{getDateAsFromNowFormatWithFetchedPrefix(vehicleHook.vehicle?.telemetry?.location?.meta.fetchedAt)}</BlackBody2Text200>
                                      </Row>
                                    }
                                    linkRoute={""}
                                    linkText={"See details>"}
                                    description={<VehicleDetailsLocationInfo telemetry={vehicleHook.vehicle?.telemetry}
                                                                             isLoading={vehicleHook.isLoading} />}
                                    content={<VehicleDetailsLocationMap vehicleHook={vehicleHook} />} />


      </Col>
    </Row>
  );

}

export default VehicleDetailsControlContent;