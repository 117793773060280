import { PaginatedApiResponse } from "types/api.types";
import qs from 'qs'

export const getAllPaginatedListByLoopAsync = async <T>(fetchPaginatedList: (query: string) => Promise<PaginatedApiResponse<T>>) => {
    const list: T[] = []
    let hasMore = true
    let ix = 0

    while (hasMore) {
        try {
            const paginationQueryString = qs.stringify({
                pagination: {
                    page: ix,
                    size: 50
                }
            }, { addQueryPrefix: true, skipNulls: true, })
            const response = await fetchPaginatedList(paginationQueryString);
            list.push(...response.data);
            hasMore = response.meta.pagination.hasMore
            ix++
        } catch (error) {
            console.error(error)
            break
        }
    }

    return list
}