import React from "react";
import { SvgProps } from "types/svg.types";

type Props = {};

function ArrowUpRightIcon(props: SvgProps) {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.66797 12.11L11.3346 5.44336"
        stroke="#4665FF"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66797 5.44336H11.3346V12.11"
        stroke="#4665FF"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ArrowUpRightIcon;
