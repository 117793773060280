import AuthPhoneNumberInput from "components/auth-components/form-components/AuthPhoneNumberInput";

import CancelButton from "components/button/CancelButton";
import PrimaryButton from "components/button/PrimaryButton";
import Row from "components/custom/Row";
import { BlackBodyText100, BlackBodyText200, BlackH5Text100 } from "components/text/Text";
import { getDialCodeForSelectorByCountryDialCode } from "consts/phone.consts";
import { useAlertManager } from "hooks/alert.hooks";
import { useAuthStatus } from "hooks/auth.hooks";
import parsePhoneNumberFromString from "libphonenumber-js";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Form } from "reactstrap";
import { updateVehicleTeslaAsync } from "services/vehicle.services";
import { DialCode } from "types/auth.types";
import { TeslaCommand } from "types/vehicle.types";
import { handleApiErrorResponse } from "utils";
import { formatPhoneNumberByDialCodeAndNumber } from "utils/format.utils";

type Props = {
  onFormReset: () => void;
};

type SendSmsFormState = {
  phoneNumber: string;
};

function VehicleDetailsTeslaKeySendSmsForm({ onFormReset }: Props) {
  const { handleOpenErrorAlert, handleOpenSuccessAlert } = useAlertManager();
  const { user } = useAuthStatus();
  const { vehicleId } = useParams<{ vehicleId: string }>();

  const [dialCode, setDialCode] = useState<DialCode>({ label: "US +1", value: "+1" });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { handleSubmit, register, watch, reset, errors } = useForm<SendSmsFormState>({
    mode: "all",
  });

  useEffect(() => {
    if (!user) {
      return;
    }

    const parsedPhoneNumber = parsePhoneNumberFromString(user.phoneNumber);
    const phoneNumberWithoutDialCode = parsedPhoneNumber?.nationalNumber ?? "";

    reset({
      phoneNumber: phoneNumberWithoutDialCode,
    });

    const dialCode = "+" + parsedPhoneNumber?.countryCallingCode;
    setDialCode(getDialCodeForSelectorByCountryDialCode(dialCode));
  }, [reset, user]);

  const handleSubmitSendSms = useCallback(
    async (formData: SendSmsFormState) => {
      try {
        setIsSubmitting(true);
        const phoneNumberWithDialCode = formatPhoneNumberByDialCodeAndNumber(dialCode, formData.phoneNumber);
        await updateVehicleTeslaAsync(vehicleId, {
          phoneNumber: phoneNumberWithDialCode,
          command: TeslaCommand.SEND_VIRTUAL_KEY_LINK_SMS,
        });
        handleOpenSuccessAlert("Sms is sent successfully!");
        onFormReset();
      } catch (error) {
        handleOpenErrorAlert(handleApiErrorResponse(error));
      } finally {
        setIsSubmitting(false);
      }
    },
    [dialCode, handleOpenErrorAlert, handleOpenSuccessAlert, onFormReset, vehicleId]
  );

  return (
    <div className="p-4">
      <BlackH5Text100>Set Up Third-Party Virtual Key</BlackH5Text100>
      <BlackBodyText200 className="my-2">
        To ensure full access to all available settings for your vehicle, it's essential to activate the Third-Party
        Virtual Key.
      </BlackBodyText200>

      <Form className="w-100" onSubmit={handleSubmit(handleSubmitSendSms)}>
        <div className="w-60">
          <BlackBodyText100 className="mb-1">Your phone number</BlackBodyText100>
          <AuthPhoneNumberInput
            defaultSelectedDialCode={dialCode}
            errors={errors}
            register={register}
            onDialCodeChanged={setDialCode}
            dialCode={dialCode}
          />
        </div>

        <BlackBodyText200 className="mt-4">
          We'll send instructions to this phone number. You can click and accept the Tesla invite link to grant Bluedot
          access to manage your vehicle. You can disable this access anytime you want under your Tesla account.
        </BlackBodyText200>

        <Row className="justify-content-end mt-4">
          <div className="mr-2">
            <CancelButton type="button" onClick={onFormReset} />
          </div>

          <PrimaryButton type="submit" text={"Send"} isLoading={isSubmitting} />
        </Row>
      </Form>
    </div>
  );
}

export default VehicleDetailsTeslaKeySendSmsForm;
