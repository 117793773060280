import { FleetGroup } from "types/fleet-group.types";
import { getDriverFullName } from "./driver.utils";

export function filterFleetGroupsByQuery(fleetGroups: FleetGroup[], query: string) {
  return fleetGroups.filter((fleetGroup) => {
    const { name, users, vehicles } = fleetGroup;

    const name_ = name?.toLowerCase();

    let driverNames = "";
    let vehicleNames = "";

    users?.forEach((u) => (driverNames += getDriverFullName(u)?.toLocaleLowerCase()));
    vehicles?.forEach((v) => (vehicleNames += v.name?.toLowerCase()));

    return (
      (name_ && name_.includes(query)) ||
      (driverNames && driverNames.includes(query)) ||
      (vehicleNames && vehicleNames.includes(query))
    );
  });
}

export function mapFleetGroupsForSelector(fleetGroups: FleetGroup[]) {
  return fleetGroups.map((g) => {
    return { label: g.name, value: g.id };
  });
}

export const MostCostlyFleetGroupsMockPlaceholderItem = {
  isPlaceholder: true,
  group: {
    id: "Group",
    name: "Group",
  },
  cost: 0,
  percentage: 0,
};

export const MostCostlyVehiclesMockPlaceholderItem = {
  isPlaceholder: true,
  vehicle: {
    name: "Name",
    model: "Model",
    plate: "Plate",
    brand: "Brand",
  },
  cost: 0,
  percentage: 0,
};

export const FlEET_GROUP_COLORS = {
  PURPLE: "purple_badge",
  ORANGE: "orange_badge",
  GREEN: "green_badge",
  PINK: "pink_badge",
  YELLOW: "yellow_badge",
  BLUE: "blue_badge",
};

export const fleetGroupColorStack = Object.keys(FlEET_GROUP_COLORS);
