import DatabaseHelperImage from "assets/images/vehicle/DatabaseHelperImage.png";
import Row from "components/custom/Row";
import CustomModalWithXIcon from "components/modal/CustomModalWithXIcon";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import HoverUnderlinedTextButton from "../../../../button/HoverUnderlinedTextButton";
import { NeutralBodyText200 } from "../../../../text/Text";
import PrimaryButton from "../../../../button/PrimaryButton";
import NeedHelpButton from "../../../../button/NeedHelpLinkButton";
import { useIntercomTrigger } from "../../../../../hooks/intercom.hooks";


export default function DriverSelfSignupApprovalPageLearnMoreModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation("common");


  const { triggerIntercom } = useIntercomTrigger();
  return (
    <>
      <CustomModalWithXIcon isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} className="wide-sm">
        <Row className="w-100 h-100 p-2">
          <img width={"100%"} src={DatabaseHelperImage} alt="DatabaseHelper" />
        </Row>
        <NeutralBodyText200 className={"my-2 p-2"}>Geotab is a connected vehicle company, helping businesses leverage data
          to better manage and track their fleet. Their GPS fleet management solution captures rich data on vehicle
          position, speed, fuel use, idling and more.</NeutralBodyText200>
        <Row className="w-100 h-100 d-flex justify-between  p-2">
          <NeedHelpButton onClick={triggerIntercom} />
          <PrimaryButton text={"Got it"} onClick={() => setIsModalOpen(false)}/>
        </Row>
      </CustomModalWithXIcon>
      <HoverUnderlinedTextButton className={"ml-1"} text={"See example"} onClick={() => setIsModalOpen(true)} />
    </>
  );
}
