import Row from "components/custom/Row";
import ChevronBottomIcon from "components/icon/icons/ChevronBottomIcon";
import { BlackBodyText100 } from "components/text/Text";
import React, { useState } from "react";
import { Collapse } from "reactstrap";

type Props = {
  className?: string;
  title: string;
  body: JSX.Element;
  icon: JSX.Element;
};

const SettingsPageAccordionBox = ({ icon, className, title, body }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`accordion${className ? " " + className : ""}`}>
      <div className="accordion-item">
        <div
          className={`d-flex justify-between clickable align-items-center accordion-head${!isOpen ? " collapsed" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <Row className="align-items-center">
            {icon}
            <BlackBodyText100 className="ml-2">{title}</BlackBodyText100>
          </Row>
          <ChevronBottomIcon />
        </div>
        <Collapse className="accordion-body" isOpen={isOpen}>
          <div className="accordion-inner">{body}</div>
        </Collapse>
      </div>
    </div>
  );
};

export default SettingsPageAccordionBox;
