type Props = {};

function PinIcon({}: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.1" y="4.6" width="17.8" height="10.8" rx="2.4" stroke="#6F7981" stroke-width="1.2" />
      <path
        d="M4.51529 11.644L4.62811 10.6076L3.79613 11.221L3.5 10.7133L4.45889 10.3115L3.50705 9.92367L3.78203 9.43012L4.63516 10.0435L4.51529 9.00003H5.07935L4.95949 10.0435L5.80557 9.43012L6.07349 9.91662L5.12165 10.3185L6.08054 10.7133L5.79146 11.2139L4.95949 10.6076L5.0723 11.644H4.51529Z"
        fill="#6F7981"
      />
      <path
        d="M7.98554 11.644L8.09835 10.6076L7.26637 11.221L6.97025 10.7133L7.92914 10.3115L6.9773 9.92367L7.25227 9.43012L8.1054 10.0435L7.98554 9.00003H8.54959L8.42973 10.0435L9.27581 9.43012L9.54374 9.91662L8.5919 10.3185L9.55079 10.7133L9.26171 11.2139L8.42973 10.6076L8.54254 11.644H7.98554Z"
        fill="#6F7981"
      />
      <path
        d="M11.4558 11.644L11.5686 10.6076L10.7366 11.221L10.4405 10.7133L11.3994 10.3115L10.4475 9.92367L10.7225 9.43012L11.5756 10.0435L11.4558 9.00003H12.0198L11.9 10.0435L12.7461 9.43012L13.014 9.91662L12.0621 10.3185L13.021 10.7133L12.732 11.2139L11.9 10.6076L12.0128 11.644H11.4558Z"
        fill="#6F7981"
      />
      <path
        d="M14.9487 11.644L15.0615 10.6076L14.2295 11.221L13.9334 10.7133L14.8923 10.3114L13.9404 9.92364L14.2154 9.43009L15.0685 10.0435L14.9487 9H15.5127L15.3929 10.0435L16.239 9.43009L16.5069 9.91659L15.555 10.3185L16.5139 10.7133L16.2249 11.2139L15.3929 10.6076L15.5057 11.644H14.9487Z"
        fill="#6F7981"
      />
    </svg>
  );
}

export default PinIcon;
