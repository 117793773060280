import React, { useCallback, useMemo } from "react";
import { defaultPasswordValidationMessages } from "../../consts/auth.consts";
import GrayCircleIcon from "../icon/icons/GrayCircleIcon";
import TickCircleIcon from "../icon/icons/TickCircleIcon";
import XCircleIcon from "../icon/icons/XCircleIcon";
import { BlackCaptionText0, BlackCaptionText100, BlackCaptionText300, CherryCaptionText100 } from "../text/Text";

type PasswordValidationMessagesProps = {
  readonly passwordValidationMessages?: string[];
  readonly isFirsTimeFocused: boolean;
};

export default function PasswordValidationMessages({
  isFirsTimeFocused,
  passwordValidationMessages,
}: PasswordValidationMessagesProps) {
  const defaultValidationMessages = Object.values(defaultPasswordValidationMessages);

  const PasswordValidationMessagesListItem = useCallback((props: React.PropsWithChildren) => {
    return <div className={`flex align-center my-1`}>{props.children}</div>;
  }, []);

  /**
   *
   */
  const isValid = (validationMessage: string) => {
    return !passwordValidationMessages?.includes(validationMessage);
  };

  return (
    <div className="password-validation-box-container">
      <BlackCaptionText100>Your password must contain:</BlackCaptionText100>
      {defaultValidationMessages.map((vm) => (
        <PasswordValidationMessagesListItem>
          {isValid(vm) ? (
            <>
              <div className={"mr-1"} data-testid="tickCircleIcon">
                <TickCircleIcon fill={"black"} />
              </div>
              <BlackCaptionText0>{vm}</BlackCaptionText0>
            </>
          ) : (
            <>
              {isFirsTimeFocused ? (
                <>
                  <GrayCircleIcon className={"mr-1"} />
                  <BlackCaptionText300>{vm}</BlackCaptionText300>
                </>
              ) : (
                <>
                  <XCircleIcon className={"mr-1"} />
                  <CherryCaptionText100>{vm}</CherryCaptionText100>
                </>
              )}
            </>
          )}
        </PasswordValidationMessagesListItem>
      ))}
    </div>
  );
}
