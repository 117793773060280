import { SvgProps } from "types/svg.types";

type Props = {};

function UnlockFilledIcon(props: SvgProps) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_28986_214796)">
        <path
          d="M1.5 3.27273C1.5 2.66996 1.76339 2.09189 2.23223 1.66567C2.70107 1.23945 3.33696 1 4 1C4.66304 1 5.29893 1.23945 5.76777 1.66567C6.23661 2.09189 6.5 2.66996 6.5 3.27273V6"
          stroke="black"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 6.5H5C4.17157 6.5 3.5 7.17157 3.5 8V13.5C3.5 14.3284 4.17157 15 5 15H12C12.8284 15 13.5 14.3284 13.5 13.5V8C13.5 7.17157 12.8284 6.5 12 6.5Z"
          stroke="black"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_28986_214796">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default UnlockFilledIcon;
