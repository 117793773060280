import React from "react";

type Props = {};

function DashboardMenuIconOutlined({}: Props) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0015 3.78966L19.5899 9.69176V20.5893H15.4129V15.9855C15.4129 14.6625 14.337 13.59 13.0175 13.59H10.989C9.66607 13.59 8.59361 14.666 8.59361 15.9855V20.5893H4.41663V9.69176L12.005 3.78966M12.005 2.26562C11.7898 2.26562 11.5782 2.33618 11.3982 2.47377L3.38297 8.70749C3.14307 8.89447 3.00195 9.18022 3.00195 9.48714V21.0197C3.00195 21.563 3.44293 22.004 3.98622 22.004H9.01341C9.5567 22.004 9.99769 21.563 9.99769 21.0197V15.9925C9.99769 15.4492 10.4387 15.0082 10.982 15.0082H13.0105C13.5538 15.0082 13.9948 15.4492 13.9948 15.9925V21.0197C13.9948 21.563 14.4357 22.004 14.979 22.004H20.0062C20.5495 22.004 20.9905 21.563 20.9905 21.0197V9.48714C20.9905 9.18375 20.8494 8.89447 20.6095 8.70749L12.5942 2.47377C12.4178 2.33618 12.2026 2.26562 11.9874 2.26562H12.005Z"
        fill="#6F7981"
      />
    </svg>
  );
}

export default DashboardMenuIconOutlined;
