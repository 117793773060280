import React from "react";
import driverStatusInfoImage from "../../assets/images/driverStatusInfoImage.svg";
import { BlockDesLight, BlockFormLabel, BlockText, BlockTitleSecondary, BlockTitleTertiary } from "../block/Block";
import Col from "../custom/Col";
import Row from "../custom/Row";
import { CustomerServiceLink } from "../links/Links";

export default function DriverFormDriverStatusInfoModalContent() {
  return (
    <Row className="w-100 h-100 ">
      <Col md="5" className="driver-form-driver-status-info-modal center p-4">
        <img src={driverStatusInfoImage} alt="DriverFormPaymentResponsibilityImage" />
      </Col>
      <Col md="7" className="p-5 h-max-80vh overflow-auto">
        <BlockTitleSecondary className={"mb-5"}>Not sure which driver type to choose?</BlockTitleSecondary>
        <BlockDesLight>Let's understand the differences.</BlockDesLight>
        <BlockTitleTertiary className={"my-4"}> Permanent Drivers</BlockTitleTertiary>
        <BlockDesLight>
          These are your regular, long-term employees. They have full access to your driver app. Their access is not
          time-limited, and they remain in the system until manually removed or deactivated. You have the flexibility to
          set their status to 'Inactive' whenever you choose.
        </BlockDesLight>
        <BlockTitleTertiary className={"my-4"}> Temporary Drivers</BlockTitleTertiary>
        <BlockDesLight>
          Perfectly suited for both short-term and long-term rentals, seasonal engagements, or trial periods. These
          drivers have limited access to your driver app and will be automatically removed from the system after their
          contract ends.
        </BlockDesLight>
        <CustomerServiceLink />
      </Col>
    </Row>
  );
}
