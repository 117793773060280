// React
import React, { useState } from "react";

// Hooks & Utils
import { useAppSelector } from "../../../hooks/redux.hooks";
import { useTranslation } from "react-i18next";

// Components
import Col from "../../custom/Col";
import Row from "../../custom/Row";
import CustomModalWithoutXIcon from "../../modal/CustomModalWithoutXIcon";
import { BlackBodyText0, BlackBodyText300, BlackH3Text100 } from "../../text/Text";
import OnboardingModalBanner from "../../../assets/images/dashboard/OnboardingModalBanner.png";
import GetStartedButton from "../../button/GetStartedButton";

type Props = {};

export default function DashboardOnboardingModal(props: Props) {
  const { t } = useTranslation("common");
  const user = useAppSelector((state) => state.user.currentUser);
  const [isModalOpen, setIsModalOpen] = useState(true);
  return (
    <CustomModalWithoutXIcon isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <Col className=" center flex-column">
        <img className="w-max-100 h-200px object-fit-cover" src={OnboardingModalBanner} alt="OnboardingModalBanner" />
        <Col className="my-4 px-4">
          <BlackH3Text100>
            {t("dashboard.onboarding.modal.title.welcome_message")}{" "}
            <span className="text-blue-500"> {t("dashboard.onboarding.modal.title.brand")}</span>,
            <span className="d-block"> {user?.name}!</span>
          </BlackH3Text100>
          <BlackBodyText0 className="mt-2">{t("dashboard.onboarding.modal.description")}</BlackBodyText0>
        </Col>
        <Row className="my-2 px-3 mb-4 w-100 align-items-center justify-content-center justify-content-md-end">
          <BlackBodyText300 className="d-none d-md-block mr-3">
            {t("dashboard.onboarding.modal.instruction_message")}
          </BlackBodyText300>
          <GetStartedButton onGetStartedButtonPressed={() => setIsModalOpen(false)} />
        </Row>
      </Col>
    </CustomModalWithoutXIcon>
  );
}
