import { useCallback, useEffect, useState } from "react";
import DriverDetailsSectionFormBox from "./DriverDetailsSectionFormBox";
import DriverFormResponsibilityModal from "./DriverFormResponsibilityModal";
import DriverDetailsSectionCard from "./driver-details/DriverDetailsSectionCard";

import EditorModal from "components/modal/EditorModal";
import { BlackOverlineText300 } from "components/text/Text";
import { useAlertManager } from "hooks/alert.hooks";
import { useAppDispatch } from "hooks/redux.hooks";
import { useForm } from "react-hook-form";
import { setDriver } from "redux/slices/driverSlice";
import { updateDriverAsync } from "services/driver.services";
import { Driver, DriverFormState, DriverUpdateChargingSetupCommand, DriverUpdateCommand } from "types/driver.types";
import { PayerType } from "types/payment.types";
import { handleApiErrorResponse } from "utils";
import {
  getChargingLimit,
  getDriverFormStateForEditMode,
  getDriverPaymentResponsibilityLabelByPayerType,
  getFormattedCreditValueForAddDriverService
} from "../../utils/driver.utils";
import DriverFormChargingStep from "./form/DriverFormChargingStep";
import HoverUnderlinedTextButton from "../button/HoverUnderlinedTextButton";

type Props = {
  isLoading: boolean;
  driver?: Driver;
  refetchDriver: () => Promise<void>
};


type EditDriverPersonalInformationFormState = {
  credit: number | null | undefined;
  payer: PayerType;
};

export default function DriverDetailsPageChargingCard({ driver, refetchDriver,isLoading }: Props) {
  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();

  const dispatch = useAppDispatch();

  const { handleSubmit, errors, reset, register, control } = useForm<DriverFormState>({
    mode: "all"
  });

  const commonFormStepProps = { register, control, errors };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isHelperModalOpen, setIsHelperModalOpen] = useState(false);

  const chargingLimit = driver?.limits?.chargingLimit;

  const chargingLimitDisplay = getChargingLimit(chargingLimit)?.label;

  /**
   * Effects
   */

  /**
   * Set initial values
   */
  useEffect(() => {
    if (!driver) {
      return;
    }

    reset(getDriverFormStateForEditMode(driver));
  }, [driver, reset]);

  /**
   * Handlers
   */
  const handleSubmitAsync = useCallback(
    async (validData: EditDriverPersonalInformationFormState) => {
      if (!driver) {
        return;
      }
      try {
        const formattedCreditValue = getFormattedCreditValueForAddDriverService(validData.credit);

        const payload: DriverUpdateChargingSetupCommand = {
          command: DriverUpdateCommand.UPDATE_CHARGING_SETTINGS,
          credit: formattedCreditValue,
          payer: validData.payer
        };

        setIsSubmitting(true);

        const updatedDriver = (await updateDriverAsync(driver.id, payload)).data;

        setIsFormModalOpen(false);
        handleOpenSuccessAlert("Charging information successfully updated!");
        dispatch(setDriver({ ...driver, ...updatedDriver }));
        refetchDriver()
      } catch (error) {
        console.error(error);
        handleOpenErrorAlert(handleApiErrorResponse(error));
      } finally {
        setIsSubmitting(false);
      }
    },
    [dispatch, driver, handleOpenErrorAlert, handleOpenSuccessAlert, refetchDriver]
  );


  return (
    <>
      <DriverFormResponsibilityModal isModalOpen={isHelperModalOpen} setIsModalOpen={setIsHelperModalOpen} />

      <EditorModal
        setIsModalOpen={setIsFormModalOpen}
        isModalOpen={isFormModalOpen}
        onSubmit={handleSubmit(handleSubmitAsync)}
        isLoading={isSubmitting}
      >
        <DriverFormChargingStep isEditMode {...commonFormStepProps} />
      </EditorModal>
      <DriverDetailsSectionCard
        loadingCount={3}
        isLoading={isLoading}
        headerTitle={"Charging setup"}
        isEditButtonShowing
        onEditButtonPressed={() => setIsFormModalOpen(true)}
        content={
          <>
            <DriverDetailsSectionFormBox
              label={"Monthly charging credit"}
              value={chargingLimitDisplay}
              descriptionElement={
                <BlackOverlineText300>Set the driver's monthly spending limit for EV charging</BlackOverlineText300>
              }
            />

            <DriverDetailsSectionFormBox
              isBorderHidden
              label={"Payment Responsibility"}
              value={getDriverPaymentResponsibilityLabelByPayerType(driver?.payer)}
              descriptionElement={
                <BlackOverlineText300>
                  Choose who will cover the cost of EV charging.{" "}
                  <HoverUnderlinedTextButton text={"Learn more"} onClick={() => setIsHelperModalOpen(true)} />
                </BlackOverlineText300>
              }
            />
          </>
        }
      />
    </>
  );
}
