import GotItButton from "components/button/GotItButton";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import TickCircleIcon from "components/icon/icons/TickCircleIcon";
import { BlackH5Text100, BlackSubtitleText100 } from "components/text/Text";
import React from "react";
import AlertConfirmDialog from "./AlertConfirmDialog";

type Props = {
  onGotItButtonPressed: () => void;
  title: string;
  description?: string;
  isDialogOpen: boolean;
};

function AlertGotItDialog({ onGotItButtonPressed, isDialogOpen, title, description }: Props) {
  return (
    <AlertConfirmDialog
      isDialogOpen={isDialogOpen}
      overrideButtons={
        <Row className="w-100 center">
          <GotItButton onGotItButtonPressed={onGotItButtonPressed} />
        </Row>
      }
      toggleIsDialogOpen={onGotItButtonPressed}
      descriptionOverride={
        <Col className="d-flex flex-column justify-content-center align-items-center">
          <TickCircleIcon fill={"#23D206"} width={100} height={100} />
          <BlackH5Text100 className="mt-3 text-center p-2 px-4">{title}</BlackH5Text100>
          <BlackSubtitleText100 className="text-center">{description}</BlackSubtitleText100>
        </Col>
      }
    />
  );
}

export default AlertGotItDialog;
