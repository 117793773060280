import { useMemo } from "react";
import { usePlacesWidget } from "react-google-autocomplete";

export function useReimbursementOnboardingStatus() {
  const storageKey = "isFirstBrowserLoginForReimbursement";


  const isFirstBrowserLogin = useMemo(() => {
    const storedFlag = localStorage.getItem(storageKey);

    // Set the flag to false for future visits
    localStorage.setItem(storageKey, "false");

    // Check if it was the first login based on the storedFlag
    return storedFlag === null;
  }, []);


  return { isFirstBrowserLogin };
}

const api =process.env.REACT_APP_GOOGLE_MAPS_API_KEY

export const useGoogleAutoComplete = (onPlaceSelected: (place: any) => void) => {
  const { ref } = usePlacesWidget({
    apiKey: api ?? "",
    onPlaceSelected: onPlaceSelected
  });
  return { ref };
};