import CustomerSupportAvatarList from "components/avatar-list/CustomerSupportAvatarList";
import Row from "components/custom/Row";
import { BlueCaptionText200 } from "components/text/Text";
import React from "react";

import { useIntercom } from "react-use-intercom";
import HoverUnderlinedTextButton from "./HoverUnderlinedTextButton";

type Props = {};

function CustomerSupportTextButton({}: Props) {
  const intercom = useIntercom();
  const handleCustomerSupportLinkPressed = () => {
    intercom.show();
  };
  return (
    <Row className="clickable align-items-center" onClick={handleCustomerSupportLinkPressed}>
      <CustomerSupportAvatarList />
      <HoverUnderlinedTextButton text={'Customer Support'} />
    </Row>
  );
}

export default CustomerSupportTextButton;
