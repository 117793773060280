import { HorizontalBarChart } from "components/charts/Chart";
import { useMemo } from "react";
import { getProcessedLocationsGraphData } from "../../../utils/dashboard.utils";
import { DashboardTablePlaceholder } from "../dashboard-placeholders/DashboardTablePlaceholder";

import { useDashboardInsights } from "hooks/data/dashboard-data-accessor.hooks";

export default function DashboardLocationsGraph() {
  const { insights } = useDashboardInsights();
  const locationData = insights?.countByChargingLocation;

  const areThereAnyData = locationData && locationData.length > 0;

  const processedGraphData = useMemo(() => {
    if (!areThereAnyData) {
      return;
    }
    return getProcessedLocationsGraphData(locationData);
  }, [areThereAnyData, locationData]);

  if (!processedGraphData) {
    return <DashboardTablePlaceholder />;
  }

  const { data, labels, backgroundColors } = processedGraphData;

  const locationsGraphData = {
    labels: labels,
    datasets: [
      {
        backgroundColor: backgroundColors,
        data: data,
      },
    ],
  };

  return (
    <div className="w-100  align-self-start">
      <HorizontalBarChart data={locationsGraphData} />
    </div>
  );
}
