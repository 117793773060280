import React, { useCallback, useEffect, useReducer, useState } from "react";
import { addDriversAsync } from "../../services/driver.services";
import { BlackBodyText200, BlackH5Text0 } from "../block/Block";
import AddDriversButton from "../button/AddDriversButton";
import DownloadTemplateButton from "../button/DownloadTemplateButton";
import Card from "../card/Card";
import SecondaryCard from "../card/SecondaryCard";
import Col from "../custom/Col";
import Row from "../custom/Row";
import { useDispatch } from "react-redux";

import { DriverBulkUploadFormCsvUploader } from "./DriverBulkUploadFormCsvUploader";
import {
  setBulkUploadDriverNonFormStateAsCompleted,
  setDriverNonFormStateAsCompleted,
} from "../../redux/slices/driverSlice";
import AlertConfirmDialog from "../notification/AlertConfirmDialog";
import { Prompt, useHistory } from "react-router";

export default function DriverBulkUploadForm() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [forwardingLocationAfterConfirm, setForwardingLocationAfterConfirm] = useState("");
  const [isNavigationAllowing, setIsNavigationAllowing] = useState(false);
  const [isExitFormWarningModalOpen, setIsExitFormWarningModalOpen] = useState(false);

  const [rawFileDataAsBase64, setRawFileDataAsBase64] = useState(undefined);
  const [fileType, setFileType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [errors, dispatchErrors] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { serverErrors: undefined, clientError: undefined }
  );

  const isDriversUploaded = rawFileDataAsBase64?.length > 0;

  /**
   *
   */
  useEffect(() => {
    if (isNavigationAllowing && forwardingLocationAfterConfirm) {
      history.push(forwardingLocationAfterConfirm);
    }
  }, [forwardingLocationAfterConfirm, history, isNavigationAllowing]);

  const handleAddDriversClicked = useCallback(async () => {
    if (!isDriversUploaded || !fileType) {
      return;
    }

    const context = "handleAddDriversClicked.rawFileDataAsBase64 :";

    //    console.log(context, rawFileDataAsBase64);

    console.log(context, fileType);

    try {
      setIsLoading(true);
      const response = await addDriversAsync(rawFileDataAsBase64, fileType);
      if (response) {
        if (response.errors) {
          dispatchErrors({ serverErrors: response.errors, clientErrors: [] });
          return;
        }
        dispatch(setBulkUploadDriverNonFormStateAsCompleted(response.drivers ?? 0));
        history.push("/add-driver/completed");
        // done!
        return;
      }
    } catch (error) {
      dispatchErrors({ serverErrors: { "Network error": ["File format is not valid."] }, clientErrors: [] });
    } finally {
      setIsLoading(false);
    }
  }, [isDriversUploaded, fileType, rawFileDataAsBase64, dispatch, history]);

  /**
   *
   */
  const handleDialogConfirmed = useCallback(() => {
    if (forwardingLocationAfterConfirm) {
      setIsNavigationAllowing(true);
    }
  }, [forwardingLocationAfterConfirm]);

  const isBulkUploadFormDirty = !!rawFileDataAsBase64;

  return (
    <Row>
      <AlertConfirmDialog
        isDialogOpen={isExitFormWarningModalOpen}
        title="Unsaved Changes"
        description="You have unsaved changes. If you leave, you'll lose these changes."
        onDialogConfirmed={handleDialogConfirmed}
        toggleIsDialogOpen={() => setIsExitFormWarningModalOpen(false)}
      />
      <Prompt
        when={isBulkUploadFormDirty && !isNavigationAllowing}
        message={(location) => {
          if (isBulkUploadFormDirty) {
            setForwardingLocationAfterConfirm(location.pathname);
            setIsExitFormWarningModalOpen(true);
            return false;
          }
        }}
      />
      <Col lg="2" />
      <Col lg="8">
        <Card padding="p-4" className={"mb-5"}>
          <BlackH5Text0>Add Drivers in Bulk</BlackH5Text0>
          <BlackBodyText200>
            To make adding multiple drivers quick and efficient, we've created a handy template for you to use. Simply
            click on the "Download Template" button and a spreadsheet will download to your device. This spreadsheet is
            pre-structured with the necessary fields for each driver's information.
          </BlackBodyText200>
          <BlackBodyText200 className={"mt-1"}>
            1. Carefully fill out all relevant fields.
            <br />
            2. Do not alter the structure or delete any rows or columns.
            <br />
            3. Save the file in the correct format as stated (like .csv or .xlsx) before uploading.
          </BlackBodyText200>

          <DriverBulkUploadFormCsvUploader
            setRawFileDataAsBase64={setRawFileDataAsBase64}
            setFileType={setFileType}
            errors={errors}
            setClientErrors={(ce) => dispatchErrors({ clientError: ce })}
            clearErrors={() => dispatchErrors({ clientError: undefined, serverErrors: undefined })}
          />

          <div className="w-100 d-flex justify-end mt-4">
            <AddDriversButton
              isLoading={isLoading}
              widthClass={"w-145px"}
              isDisabled={!isDriversUploaded}
              onAddDriversClicked={handleAddDriversClicked}
            />
          </div>
        </Card>
      </Col>
      <Col lg="2" />
    </Row>
  );
}
