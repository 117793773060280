import { useDashboardInsights } from "hooks/data/dashboard-data-accessor.hooks";
import { useAppSelector } from "hooks/redux.hooks";
import { useCallback, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { MostCostlyGroup } from "types/dashboard.types";
import { TableColumn } from "types/table.types";
import { MostCostlyFleetGroupsMockPlaceholderItem } from "utils/fleet-groups.utils";
import { colorTheFleetGroups } from "../../../redux/slices/fleetGroupsSlice";
import { getFormattedNumberForUsdByCent } from "../../../utils/format.utils";
import FleetGroupBadge from "../../badge/FleetGroupBadge";
import RankingCircleBadge from "../../badge/RankingCircleBadge";

import { FleetGroup } from "types/fleet-group.types";
import { DashboardTablePlaceholder } from "../dashboard-placeholders/DashboardTablePlaceholder";

export default function DashboardMostCostlyGroupsTable() {
  // Cached departments by color
  const coloredGroups = useAppSelector((state) => state?.fleetGroups?.coloredFleetGroups);

  const { insights } = useDashboardInsights();
  const mostCostlyGroups = useMemo(() => {
    return insights?.mostCostlyGroups ?? [];
  }, [insights?.mostCostlyGroups]);

  const dispatch = useDispatch();

  const filledGroupsData = useMemo(() => {
    const mostCostlyGroupsLength = mostCostlyGroups?.length ?? 0;

    // fill the groups by mock data placeholder the rest if the groups count is less than 3
    if (mostCostlyGroupsLength > 0 && mostCostlyGroupsLength < 3) {
      const placeholderCount = 3 - mostCostlyGroupsLength;

      const newGroupsData = Array.from(mostCostlyGroups);

      for (let i = 0; i < placeholderCount; i++) {
        newGroupsData.push(MostCostlyFleetGroupsMockPlaceholderItem);
      }

      return newGroupsData;
    }

    return mostCostlyGroups;
  }, [mostCostlyGroups]);

  useEffect(() => {
    if (!filledGroupsData) {
      return;
    }

    const mostCostlyGroupsAsFleetGroups: FleetGroup[] = filledGroupsData.map((g) => {
      return {
        name: g.group.name,
        cost: g.cost,
        id: g.group.id,
        vehicles: [],
        users: [],
        isPlaceholder: g.isPlaceholder
      };
    });
    dispatch(colorTheFleetGroups(mostCostlyGroupsAsFleetGroups));
  }, [coloredGroups, dispatch, filledGroupsData]);

  const getGroupBadgeColor = useCallback(
    (groupName) => {
      const existingGroup = coloredGroups?.find((group) => group?.name === groupName);

      if (existingGroup && existingGroup?.color) {
        return existingGroup;
      }
    },
    [coloredGroups]
  );

  const columns = useMemo(() => {
    return [
      {
        grow: 2,
        name: "Group name",
        id: "racing-badge-container",
        selector: (row, ix) => {
          const badgeColor = getGroupBadgeColor(row?.group.name);

          return badgeColor ? (
            <p className="d-flex justify-start align-items-center">
              {/**
               * Ugly solution : pointer-events:none prevents title attribute
               * https://stackoverflow.com/a/47412524/13592168
               */}
              <RankingCircleBadge rank={ix + 1} iExtensionVisible />
              {!row?.isPlaceholder && (
                <p>
                  <FleetGroupBadge badgeContent={badgeColor} />
                </p>
              )}
            </p>
          ) : (
            <></>
          );
        }
      },

      {
        grow: 1.5,
        name: "Total Cost",
        selector: (row) => getFormattedNumberForUsdByCent(row?.cost)
      },

      {
        name: "%",
        selector: (row) => row?.percentage?.toFixed(2) ?? 0
      }
    ];
  }, [getGroupBadgeColor]) as TableColumn<MostCostlyGroup>[]; // Any should be type of FleetGroups or department

  if (!filledGroupsData || filledGroupsData?.length < 1) {
    return <DashboardTablePlaceholder />;
  }

  return (
    <div className="d-flex flex-1 w-100 flex-column racing-badge-container">
      <DataTable
        style={{
          margin: 0
        }}
        className="d-lg-block rounded-xxl "
        columns={columns}
        data={filledGroupsData}
        pagination={false}
        conditionalRowStyles={[
          {
            when: (row) => !!row.isPlaceholder,
            style: {
              opacity: 0.5
            }
          }
        ]}
        highlightOnHover
        responsive
      />
    </div>
  );
}
