import CancelButton from "components/button/CancelButton";
import YesButton from "components/button/YesButton";
import DriverSelectorEditor from "components/driver-components/editors/DriverSelectorEditor";
import HorizontalLine from "components/line/HorizontalLine";
import EditorModal from "components/modal/EditorModal";
import AlertConfirmDialog from "components/notification/AlertConfirmDialog";
import { BlackBodyText100, BlackH5Text0 } from "components/text/Text";
import VehicleDriverSchedulePeriodSelector
  from "components/vehicle-driver-schedule/form/VehicleDriverSchedulePeriodSelector";
import { useAlertManager } from "hooks/alert.hooks";
import { useAllDriversAsync } from "hooks/data/driver-data-accessor.hooks";
import { useVehicleFormDriverSetupStates } from "hooks/vehicle.hooks";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { updateVehicleDriverScheduleAsync } from "services/vehicle-driver-schedule.services";
import { VehicleDriverSchedule, VehicleDriverScheduleUpdatePayload } from "types/vehicle-driver-schedule.types";
import { handleApiErrorResponse } from "utils";
import { getDriverFullName, getDriversForDriverSelector } from "utils/driver.utils";
import {
  getVehicleDriverSchedulePeriodsForPayload,
  getVehicleDriverSchedulePeriodsForSelector
} from "utils/vehicle-driver-schedule.utils";
import VehicleDriverScheduleCard from "../../card/VehicleDriverScheduleCard";
import LoadingSkeleton from "../../loading/LoadingSkeleton";

type Props = {
  schedules: VehicleDriverSchedule[];
  refetch: () => void;
  isLoading: boolean
};

function VehicleDetailsVehicleDriverScheduleListWithEditors({ schedules, refetch, isLoading }: Props) {
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const { allDrivers, isLoading: isDriverOptionsLoading } = useAllDriversAsync();
  const driverOptions = getDriversForDriverSelector(allDrivers);

  const { handleOpenSuccessAlert, handleOpenErrorAlert } = useAlertManager();

  const vehicleFormDriverSetupStates = useVehicleFormDriverSetupStates();
  const {
    handleSetDriverSelector,
    driverSelector,
    isDriverSelected,
    setVehicleDriverSchedulePeriodSelector,
    handleDeleteScheduleAsync,
    setSelectedScheduleIdToDelete,
    selectedScheduleIdToDelete
  } = vehicleFormDriverSetupStates;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditorModalOpen, setIsEditorModalOpen] = useState(false);
  const [selectedScheduleToUpdate, setSelectedScheduleToUpdate] = useState<VehicleDriverSchedule | undefined>(
    undefined
  );

  const isEditMode = !!selectedScheduleToUpdate;

  const { handleSubmit } = useForm({
    mode: "all"
  });

  useEffect(() => {
    if (selectedScheduleToUpdate && isEditMode) {
      setVehicleDriverSchedulePeriodSelector({
        ...getVehicleDriverSchedulePeriodsForSelector(
          selectedScheduleToUpdate.startsAt,
          selectedScheduleToUpdate.endsAt
        )
      });
      handleSetDriverSelector({
        label: getDriverFullName(selectedScheduleToUpdate.user),
        value: selectedScheduleToUpdate.userId
      });
    }
  }, [selectedScheduleToUpdate, isEditMode, setVehicleDriverSchedulePeriodSelector, handleSetDriverSelector]);

  const handleEditClicked = useCallback((schedule: VehicleDriverSchedule) => {
    setSelectedScheduleToUpdate(schedule);
    setIsEditorModalOpen(true);
  }, []);

  const handleSubmitUpdateAsync = useCallback(async () => {
    if (!selectedScheduleToUpdate) {
      return;
    }

    const { vehicleDriverSchedulePeriodSelector, isDriverSelected } = vehicleFormDriverSetupStates;

    if (!selectedScheduleToUpdate.vehicleId) {
      throw Error("Vehicle is unknown");
    }

    if (!isDriverSelected) {
      throw Error("Please select driver");
    }

    const { startsAt, endsAt } = vehicleDriverSchedulePeriodSelector;

    try {
      const payload: VehicleDriverScheduleUpdatePayload = {
        ...getVehicleDriverSchedulePeriodsForPayload(startsAt, endsAt)
      };
      setIsSubmitting(true);
      await updateVehicleDriverScheduleAsync(selectedScheduleToUpdate.id, payload);
      setIsEditorModalOpen(false);
      handleOpenSuccessAlert("Schedule is successfully updated!");
      refetch();
    } catch (error) {
      console.error(error);
      handleOpenErrorAlert(handleApiErrorResponse(error));
    } finally {
      setIsSubmitting(false);
    }
  }, [handleOpenErrorAlert, handleOpenSuccessAlert, refetch, selectedScheduleToUpdate, vehicleFormDriverSetupStates]);

  const handleSubmitDeleteAsync = useCallback(async () => {
    setIsSubmitting(true);
    await handleDeleteScheduleAsync();
    setIsSubmitting(false);
    setSelectedScheduleIdToDelete(undefined);
    refetch();
  }, [handleDeleteScheduleAsync, refetch, setSelectedScheduleIdToDelete]);


  if (isLoading) {
    return <LoadingSkeleton count={4} height={45} />;
  }

  return (
    <div>
      <AlertConfirmDialog
        title={`Are you sure you want to ${selectedScheduleIdToDelete?.isActive ? "stop" : "delete"} this assignment?`}
        isDialogOpen={!!selectedScheduleIdToDelete}
        overrideButtons={
          <>
            <div className="mr-2">
              <CancelButton onClick={() => setSelectedScheduleIdToDelete(undefined)} />
            </div>

            <YesButton isLoading={isSubmitting} onYesButtonPressed={handleSubmitDeleteAsync} />
          </>
        }
      />
      <EditorModal
        modalSize="wide-sm"
        setIsModalOpen={setIsEditorModalOpen}
        isModalOpen={isEditorModalOpen}
        onSubmit={handleSubmit(handleSubmitUpdateAsync)}
        isLoading={isSubmitting}
        isDisabled={!isDriverSelected}
      >
        <div className="p-2">
          <DriverSelectorEditor
            isDisabled={true}
            isLoading={isDriverOptionsLoading}
            driverOptions={driverOptions}
            editorLabelOverride={<BlackH5Text0 className="mb-2">Assign your driver</BlackH5Text0>}
            onSelectedDriverChanged={handleSetDriverSelector}
            selectedDriver={driverSelector}
          />

          <HorizontalLine />
          <VehicleDriverSchedulePeriodSelector
            isEditMode
            isPortal
            selectedScheduleToUpdate={selectedScheduleToUpdate}
            vehicleScheduleSelectorHooks={vehicleFormDriverSetupStates}
            description="Choose a start date to initiate the driver’s access to the vehicle. If left unspecified, access begins
             immediately upon assignment. Similarly, select an end date to define when access will end. Without an end
            date, access continues indefinitely without a set expiration. This flexibility allows for both fixed-term and
           open-ended vehicle assignments."
          />
        </div>
      </EditorModal>


      {schedules.length === 0 ? (
        '-'
      ) : (
        <div className="h-max-475px  overflow-auto">
          {schedules.map((schedule, ix) => (
            <VehicleDriverScheduleCard
              ix={ix}
              schedule={schedule}
              onDeleteClicked={setSelectedScheduleIdToDelete}
              onEditClicked={handleEditClicked}
              isForDriver
            />

          ))}
        </div>
      )}
      
    </div>
  );
}

export default VehicleDetailsVehicleDriverScheduleListWithEditors;
