import React from "react";

type Props = {};

function ClockIcon({}: Props) {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 0.493164C12.8355 0.493164 9.74207 1.43155 7.11088 3.18965C4.4797 4.94775 2.42894 7.44661 1.21793 10.3702C0.0069325 13.2938 -0.309921 16.5109 0.307443 19.6146C0.924806 22.7183 2.44866 25.5692 4.6863 27.8069C6.92394 30.0445 9.77487 31.5684 12.8786 32.1857C15.9823 32.8031 19.1993 32.4862 22.1229 31.2752C25.0466 30.0642 27.5454 28.0135 29.3035 25.3823C31.0616 22.7511 32 19.6577 32 16.4932C32 12.2497 30.3143 8.18004 27.3137 5.17946C24.3131 2.17887 20.2435 0.493164 16 0.493164ZM22.2 21.6732C22.1274 21.7831 22.0337 21.8776 21.9244 21.9512C21.8151 22.0248 21.6924 22.0761 21.5632 22.1021C21.434 22.1281 21.301 22.1284 21.1717 22.1028C21.0425 22.0772 20.9195 22.0264 20.81 21.9532L14.91 17.9532V9.24316C14.91 8.97795 15.0154 8.72359 15.2029 8.53606C15.3904 8.34852 15.6448 8.24316 15.91 8.24316C16.1752 8.24316 16.4296 8.34852 16.6171 8.53606C16.8047 8.72359 16.91 8.97795 16.91 9.24316V16.8932L21.91 20.2832C22.0207 20.355 22.1161 20.4481 22.1907 20.557C22.2653 20.6659 22.3176 20.7885 22.3445 20.9178C22.3715 21.047 22.3726 21.1803 22.3478 21.3099C22.323 21.4396 22.2728 21.563 22.2 21.6732ZM21.85 6.72316C20.443 5.87508 18.8691 5.34141 17.2364 5.15878C15.6038 4.97614 13.9509 5.14886 12.3912 5.66509C10.8316 6.18132 9.40205 7.02883 8.20074 8.14948C6.99943 9.27013 6.05476 10.6374 5.43155 12.1575C4.80835 13.6775 4.52135 15.3144 4.59025 16.9559C4.65915 18.5973 5.08232 20.2044 5.83072 21.6669C6.57913 23.1294 7.63506 24.4127 8.92606 25.4287C10.2171 26.4447 11.7126 27.1695 13.31 27.5532L13 29.1232C11.168 28.6967 9.45026 27.8778 7.9655 26.7229C6.48075 25.5681 5.2643 24.1047 4.40014 22.4339C3.53597 20.7632 3.04468 18.9248 2.96018 17.0456C2.87567 15.1665 3.19997 13.2914 3.91067 11.5498C4.62138 9.80824 5.70156 8.24162 7.07666 6.95813C8.45176 5.67463 10.089 4.70484 11.8754 4.11569C13.6618 3.52655 15.5547 3.33209 17.4236 3.54574C19.2924 3.75938 21.0927 4.37605 22.7 5.35316L21.85 6.72316Z"
        fill="black"
      />
    </svg>
  );
}

export default ClockIcon;
