import React from "react";
import { Row, UncontrolledTooltip } from "reactstrap";
import { TooltipComponent } from "../Component";
import MuiTooltip from "../tooltip/MuiTooltip";

export default function DynamicVehicleList({ vehicles }) {
  if (vehicles?.length === 0 || !vehicles) {
    return <></>;
  }

  if (vehicles?.length === 1 && vehicles[0].name) {
    return <>{vehicles[0].name}</>;
  }

  const firstVehicleName = vehicles[0].name;
  const others = vehicles.slice(1, vehicles.length);
  const othersLength = others.length;

  let uniqueKey = "";
  others.forEach((u) => {
    uniqueKey += u.name[0] + `${u.name[u.name.length - 1]}` + `-${othersLength}`;
  });

  return (
    <Row className="flex align-center m-0 priority-link">
      <p className="mr-1 mb-0 w-max-80px">{firstVehicleName}</p>

      <MuiTooltip
        title={others.map((o, ix) => (
          <div style={{ padding: 2 }} key={ix}>{`${o.name}`}</div>
        ))}
        placement="top"
      >
        <div id={uniqueKey} className="help-fill rounded-circle center bg-dark w-25px h-25px text-white">
          +{othersLength}
        </div>
      </MuiTooltip>
    </Row>
  );
}

/**
 *  {others.map((ou, ix) => (
          <p key={ix}>{ou.firstName}</p>
        ))}
 */
