import React from "react";

import Col from "../../custom/Col";
import Row from "../../custom/Row";
import CheckedTickCircleIcon from "../../icon/icons/CheckedTickCircleIcon";
import TickCircleIcon from "../../icon/icons/TickCircleIcon";
import { BlackCaptionText100, BlackCaptionText300, BlackSubtitleText100 } from "../../text/Text";

type Props = {
  readonly hasItBorder?: boolean;
  readonly stepNumber: number;
  readonly isTickIconChecked?: boolean;
  readonly title: string;
  readonly description: string;
  readonly button?: JSX.Element;
  readonly image: JSX.Element;
  readonly paddingClasses?: string;
};

const DashboardOnboardingStepCard = ({
  hasItBorder,
  stepNumber,
  isTickIconChecked,
  title,
  description,
  button,
  image,
  ...props
}: Props) => {
  const paddingClasses = props.paddingClasses ?? "px-3";
  return (
    <Col
      md="6"
      lg="4"
      className={`h-330px py-1  flex-column justify-between  ${
        hasItBorder ? "dashboard-onboarding-card-border mb-3 mb-md-0" : ""
      }  ${paddingClasses}`}
    >
      <Col>
        <Row className="align-items-center mb-1">
          <BlackCaptionText100 className="mr-2">Step {stepNumber}</BlackCaptionText100>
          {isTickIconChecked ? (
            <CheckedTickCircleIcon width={24} height={24} />
          ) : (
            <TickCircleIcon fill="#CBD5E1" width={24} height={24} />
          )}
        </Row>
        <BlackSubtitleText100>{title}</BlackSubtitleText100>
        <BlackCaptionText300>{description}</BlackCaptionText300>
        {button && !isTickIconChecked && <div className="mt-2">{button}</div>}
      </Col>
      {image}
    </Col>
  );
};

export default DashboardOnboardingStepCard;
