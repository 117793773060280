import RowBetweenBlock from "../../block/RowBetweenBlock";
import { VehicleDataAccessorHook, VehicleDetailsEditFormMode } from "../../../types/vehicle.types";
import LoadingSkeleton from "../../loading/LoadingSkeleton";
import {
  getVehicleConnectionStatus,
  getVehicleModelYear,
  getVehicleTelemetryDetails
} from "../../../utils/vehicle.utils";
import { BlackBody2Text200, BlackBodyText100 } from "../../text/Text";
import ClipboardWithCopyIcon from "../../clipboard/ClipboardWithCopyIcon";
import PencilIcon from "../../icon/icons/PencilIcon";
import Row from "../../custom/Row";
import { useState } from "react";
import VehicleDetailsEditFormModal from "./VehicleDetailsEditFormModal";
import VehicleDetailsFetchedInfoTooltip from "./VehicleDetailsFetchedInfoTooltip";

type Props = {
  vehicleHook: VehicleDataAccessorHook
  isOverview?: boolean
}

function VehicleDetailsAttributes({ vehicleHook, isOverview }: Props) {
  const { isLoading, vehicle } = vehicleHook;
  const [isEditVehicleFormModalOpen, setIsEditVehicleFormModalOpen] = useState(false);

  const { isManualAddedCar } = getVehicleConnectionStatus(vehicleHook.vehicle);

  if (isLoading) {
    return <LoadingSkeleton height={isOverview ? 25 : 45} count={isOverview ? 4 : 7} />;
  }

  const vehicleModelYear = getVehicleModelYear(vehicle);

  const isTelemetryInfoBlocksVisible = !isOverview && !isManualAddedCar

  const {
    odometer,
    batteryPercentRemainingDisplayText,
    batteryRangeDisplayText,
    odometerDisplayText,
    battery
  } = getVehicleTelemetryDetails(vehicle?.telemetry);

  return (
    <>

      {isEditVehicleFormModalOpen && (<VehicleDetailsEditFormModal
          vehicle={vehicle}
          defaultValue={vehicle?.plateNumber}
          formMode={VehicleDetailsEditFormMode.PLATE}
          isModalOpen={isEditVehicleFormModalOpen}
          setIsModalOpen={setIsEditVehicleFormModalOpen}
          triggerVehicleFetching={vehicleHook.refetch}
        />
      )}

      <div>

        <RowBetweenBlock isBorderBottomVisible leftText={"Make"} rightText={vehicle?.carBrand} />
        <RowBetweenBlock isBorderBottomVisible leftText={"Model  - Year"} rightText={vehicleModelYear} />
        <RowBetweenBlock isBorderBottomVisible leftText={"VIN"}
                         rightOverwrite={<ClipboardWithCopyIcon TextComponent={BlackBodyText100}
                                                                text={vehicle?.vin} />} />

        <RowBetweenBlock
          isBorderBottomVisible={isTelemetryInfoBlocksVisible}
          leftOverwrite={
            <Row>
              <BlackBody2Text200>License plate</BlackBody2Text200>
              <div className="ml-1 clickable" onClick={() => setIsEditVehicleFormModalOpen(true)}>
                <PencilIcon />
              </div>
            </Row>
          }
          rightText={"-"}
          leftText={"License plate"}
          rightOverwrite={vehicle?.plateNumber ? <ClipboardWithCopyIcon TextComponent={BlackBodyText100}
                                                                        text={vehicle?.plateNumber} /> : undefined} />


        {isTelemetryInfoBlocksVisible&&
          <>
            <RowBetweenBlock
              isBorderBottomVisible
              leftText={"Battery"}
              rightOverwrite={
                <Row>
                  <BlackBodyText100>{batteryPercentRemainingDisplayText}</BlackBodyText100>
                  <VehicleDetailsFetchedInfoTooltip fetchedAt={battery?.meta?.fetchedAt} />
                </Row>
              }
            />

            <RowBetweenBlock isBorderBottomVisible
                             leftText={"Odometer"}
                             rightOverwrite={
                               <Row>
                                 <BlackBodyText100>{odometerDisplayText}</BlackBodyText100>
                                 <VehicleDetailsFetchedInfoTooltip
                                   fetchedAt={
                                     odometer?.meta?.fetchedAt
                                   }
                                 />
                               </Row>
                             }
            />
            <RowBetweenBlock
              leftText={"Remaining Range"}
              rightOverwrite={
                <Row>
                  <BlackBodyText100>{batteryRangeDisplayText}</BlackBodyText100>
                  <VehicleDetailsFetchedInfoTooltip fetchedAt={battery?.meta?.fetchedAt} />
                </Row>
              } />


          </>
        }

      </div>
    </>
  );
}

export default VehicleDetailsAttributes;