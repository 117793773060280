import React from "react";
import Card from "components/card/Card";
import Row from "components/custom/Row";
import { BlackBodyText300, BlackSubtitleText100 } from "components/text/Text";
import { useHistory, useParams } from "react-router";
import Col from "../../custom/Col";
import HoverUnderlinedTextButton from "../../button/HoverUnderlinedTextButton";
import { TextButtonMode } from "../../button/TextButton";

type Props = {
  title?: string,
  titleOverwrite?: React.JSX.Element
  linkText?: string,
  linkRoute?: string,
  onLinkClick?: () => void
  description?: string | React.JSX.Element
  content: React.JSX.Element
  className?: string
}

function VehicleDetailsOverviewCard({
                                      title,
                                      titleOverwrite,
                                      linkText,
                                      linkRoute,
                                      onLinkClick,
                                      description,
                                      content,
                                      className
                                    }: Props): JSX.Element {
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const history = useHistory();


  return (
    <Card className={className}>
      <Col className="mb-4">
        <Row className="w-100 justify-between align-items-center">
          {titleOverwrite ?? <BlackSubtitleText100>{title}</BlackSubtitleText100>}

          {linkText && linkRoute &&
            <HoverUnderlinedTextButton text={linkText} mode={TextButtonMode.BLUE} onClick={() => {
              if (onLinkClick) {
                onLinkClick();
                return;
              }
              history.push(`/vehicles/${vehicleId}/${linkRoute}`);
            }} />}
        </Row>
        {description && <BlackBodyText300>{description}</BlackBodyText300>}
      </Col>
      {content}
    </Card>
  );
}

export default VehicleDetailsOverviewCard;