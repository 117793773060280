import { SvgProps } from "types/svg.types";

function InfoIconWithCard(props: SvgProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="24" height="24" rx="4" fill="white" />
      <path
        d="M12 16C12.2267 16 12.4168 15.9232 12.5704 15.7696C12.724 15.616 12.8005 15.4261 12.8 15.2V12C12.8 11.7733 12.7232 11.5832 12.5696 11.4296C12.416 11.276 12.2261 11.1995 12 11.2C11.7733 11.2 11.5832 11.2768 11.4296 11.4304C11.276 11.584 11.1995 11.7739 11.2 12V15.2C11.2 15.4267 11.2768 15.6168 11.4304 15.7704C11.584 15.924 11.7739 16.0005 12 16ZM12 9.6C12.2267 9.6 12.4168 9.5232 12.5704 9.3696C12.724 9.216 12.8005 9.02613 12.8 8.8C12.8 8.57333 12.7232 8.3832 12.5696 8.2296C12.416 8.076 12.2261 7.99947 12 8C11.7733 8 11.5832 8.0768 11.4296 8.2304C11.276 8.384 11.1995 8.57387 11.2 8.8C11.2 9.02667 11.2768 9.2168 11.4304 9.3704C11.584 9.524 11.7739 9.60053 12 9.6ZM12 20C10.8933 20 9.85333 19.7899 8.88 19.3696C7.90667 18.9493 7.06 18.3795 6.34 17.66C5.62 16.94 5.05013 16.0933 4.6304 15.12C4.21067 14.1467 4.00053 13.1067 4 12C4 10.8933 4.21013 9.85333 4.6304 8.88C5.05067 7.90667 5.62053 7.06 6.34 6.34C7.06 5.62 7.90667 5.05013 8.88 4.6304C9.85333 4.21067 10.8933 4.00053 12 4C13.1067 4 14.1467 4.21013 15.12 4.6304C16.0933 5.05067 16.94 5.62053 17.66 6.34C18.38 7.06 18.9501 7.90667 19.3704 8.88C19.7907 9.85333 20.0005 10.8933 20 12C20 13.1067 19.7899 14.1467 19.3696 15.12C18.9493 16.0933 18.3795 16.94 17.66 17.66C16.94 18.38 16.0933 18.9501 15.12 19.3704C14.1467 19.7907 13.1067 20.0005 12 20ZM12 18.4C13.7867 18.4 15.3 17.78 16.54 16.54C17.78 15.3 18.4 13.7867 18.4 12C18.4 10.2133 17.78 8.7 16.54 7.46C15.3 6.22 13.7867 5.6 12 5.6C10.2133 5.6 8.7 6.22 7.46 7.46C6.22 8.7 5.6 10.2133 5.6 12C5.6 13.7867 6.22 15.3 7.46 16.54C8.7 17.78 10.2133 18.4 12 18.4Z"
        fill="#6F7981"
      />
    </svg>
  );
}

export default InfoIconWithCard;
