import BlackButton from "components/button/BlackButton";
import WarningIcon from "components/icon/icons/WarningIcon";
import { BlackBody2Text100, CherrySubtitleText500, PurpleSubtitleText200 } from "components/text/Text";
import React from "react";
import { useHistory } from "react-router";
import { AppColors } from "../../utils/colors.utils";

type Props = {
  isCollapsed: boolean;
};

function UnpaidInvoiceAlertBox({ isCollapsed }: Props) {
  const history = useHistory();
  return (
    <div
      onClick={() => history.push("/invoices")}
      className={`unpaid-invoice-alert-box ${isCollapsed ? "rounded-xl clickable" : "rounded-xxl"}`}
    >
      <WarningIcon width={22} height={20} fill={AppColors.cherry500} />
      {!isCollapsed && (
        <>
          <CherrySubtitleText500 className="mt-1">Action Needed</CherrySubtitleText500>
          <BlackBody2Text100 className="h-max-65px">
            You have an outstanding invoice. Review and settle it when convenient!
          </BlackBody2Text100>
          <BlackButton className="mt-2" text="Pay Now" />
        </>
      )}
    </div>
  );
}

export default UnpaidInvoiceAlertBox;
