import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FleetGroup, FleetGroupSliceState } from "types/fleet-group.types";
import { FlEET_GROUP_COLORS, fleetGroupColorStack } from "utils/fleet-groups.utils";

const initialState: FleetGroupSliceState = {
  latestExpandedGroup: "",
  fleetGroups: [],
  isFleetGroupsLoading: false,
  coloredFleetGroups: [],
  colorsStack: fleetGroupColorStack,
};

const getRandomGroupColor = () => {
  return fleetGroupColorStack[Math.floor(Math.random() * fleetGroupColorStack.length)];
};

export const fleetGroupsSlice = createSlice({
  name: "fleetGroups",
  initialState: initialState,
  reducers: {
    setFleetGroups: (state, action: PayloadAction<FleetGroup[]>) => {
      state.fleetGroups = action.payload;
    },
    setLatestExpandedGroup: (state, action: PayloadAction<string>) => {
      state.latestExpandedGroup = action.payload;
    },
    setIsFleetGroupsLoading: (state, action) => {
      state.isFleetGroupsLoading = action.payload;
    },
    colorTheFleetGroups: (state, action: PayloadAction<FleetGroup[]>) => {
      const fleetGroupsNeedToBeColored = action.payload;

      fleetGroupsNeedToBeColored.forEach((uncoloredFleetGroup) => {
        const alreadyColoredFleetGroup = state.coloredFleetGroups.find(
          (group) => group?.name === uncoloredFleetGroup?.name
        );
        if (alreadyColoredFleetGroup && alreadyColoredFleetGroup.color) {
          return;
        }

        const color = state.colorsStack.shift() ?? getRandomGroupColor();

        state.coloredFleetGroups.push({ ...uncoloredFleetGroup, color: FlEET_GROUP_COLORS[color] });
      });
    },
  },
});

export const { setFleetGroups, colorTheFleetGroups, setIsFleetGroupsLoading, setLatestExpandedGroup } =
  fleetGroupsSlice.actions;
export default fleetGroupsSlice.reducer;
