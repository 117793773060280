import { Check } from "@mui/icons-material";
import { Stepper } from "@mui/material";

import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

import Step from "@mui/material/Step";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import TickIcon from "../icon/icons/TickIcon";
import XCircleIconWhite from "../icon/icons/XCircleIconWhite";
import Card from "../card/Card";
import { NeutralBodyMediumText100, NeutralBodyMediumText500, PositiveLabelMediumText400 } from "../text/Text";
import Row from "../custom/Row";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  transition: "background-color 0.5s linear",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4"
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
    transition: "background-color 0.5s linear"
  }
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <Check className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool
};


const ColorlibConnector = styled(StepConnector)(({ isError, currentStep, completed }) => {
  console.log("completed", completed);
  return {
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      left: 16
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: isError ? "red" : "#23D206",
        transition: "background-color 2s linear",
        marginTop: 0
      }
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: isError ? "red" : "#23D206",
        transition: "background-color 2s linear",
        marginTop: 0
      }
    },
    [`& .${stepConnectorClasses.line}`]: {
      width: 2,
      border: 0,
      backgroundColor: "#D3DAE6",
      transition: "background-color 2s linear",
      borderRadius: 1,
      marginTop: 4
    },
    [`&.${stepConnectorClasses.root}`]: {
      marginLeft: 12,
      marginBottom: 4
    }
  };
});

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  transition: "background-color 2s linear",
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 26,
  height: 26,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#6E86FF"
  }),
  ...(ownerState.completed && {
    backgroundColor: "#23D206"
  }),
  ...(ownerState.error && {
    backgroundColor: "#BD271E"
  })
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, isError, currentStep } = props;

  // props.icon index is starting from 1, we need to adjust currentStep accordingly
  const hasCurrentStepError = active && isError;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active, error: hasCurrentStepError }} className={className}>
      {hasCurrentStepError ? <XCircleIconWhite /> : completed ? <TickIcon /> : props.icon}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node
};

const steps = [
  { label: "Personal information", isOptional: false },
  { label: "Address", isOptional: false },
  { label: "Charging setup", isOptional: false },
  { label: "Home charging", isOptional: true },
  { label: "Vehicle setup ", isOptional: true }
];

export default function DriverFormStepper({ currentStep, isError }) {
  return (
    <Card>
      <Stepper
        variant="progress"
        orientation="vertical"
        activeStep={currentStep}
        connector={<ColorlibConnector isError={isError} currentStep={currentStep} />}
      >
        {steps.map(({ label, isOptional }, ix) => (
          <Step key={label}>
            <StepLabel
              className={"p-0"}
              content={"hi"}
              StepIconComponent={(props) => ColorlibStepIcon({ ...props, isError, currentStep })}>
              {currentStep > ix && (
                <PositiveLabelMediumText400>
                  Completed
                </PositiveLabelMediumText400>
              )}


              <Row className={'align-items-center'}>
                <NeutralBodyMediumText100>{label}</NeutralBodyMediumText100>
                {isOptional && (<NeutralBodyMediumText500 className={'ml-1'}>(Optional)</NeutralBodyMediumText500>)}
              </Row>

            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Card>
  );
}
