import PaidStatusBadge from "components/badge/PaidStatusBadge";
import BlackButton from "components/button/BlackButton";
import CsvLinkButton from "components/button/CsvLinkButton";
import Card from "components/card/Card";
import Col from "components/custom/Col";
import Row from "components/custom/Row";
import PageHeader from "components/header/PageHeader";
import BlackInfoIcon from "components/icon/icons/BlackInfoIcon";
import LoadingSkeleton from "components/loading/LoadingSkeleton";
import { BlackBodyText100, BlackBodyText200, BlackH5Text100, BlackH6Text0 } from "components/text/Text";
import { useInvoiceAsync } from "hooks/data/invoices-data-accessor.hooks";
import { useInvoiceDetailsByType } from "hooks/invoice.hooks";
import Content from "layout/content/Content";
import Head from "layout/head/Head";
import { ChargingInvoiceLineItem, PlatformInvoiceLineItem } from "types/invoice.types";
import { getLocalFormattedDateAsDateShort } from "utils/date.utils";
import { getFormattedNumberForUsdByCent } from "utils/format.utils";
import ChargingInvoiceDetailsTable from "../../components/invoice/tables/ChargingInvoiceDetailsTable";
import PlatformInvoiceDetailsTable from "../../components/invoice/tables/PlatformInvoiceDetailsTable";
import NotFoundContent from "../../components/not-found/NotFoundContent";
import React from "react";

type Props = {};

function InvoiceDetailsPage({}: Props) {
  const { invoice, isLoading } = useInvoiceAsync();
  const {
    exportData,
    handleForwardToStripe,
    isPaid,
    percentFixed,
    description,
    title,
    invoicePaymentState,
    percent,
    isChargingInvoice,
  } = useInvoiceDetailsByType(invoice);



  if (!isLoading && !invoice) {
    return <>
      <Head title={"Invoice Details"} />
      <NotFoundContent />
    </>;
  }


  return (
    <>
      <Head title={"Invoice Details"} />
      <Content>
        <PageHeader
          title={isLoading ? "" : title}
          rightElement={
            isLoading ? (
              <div className="w-200px">
                <LoadingSkeleton count={1} />
              </div>
            ) : (
              <Row className="align-items-end">
                <BlackBodyText100 className="mr-2">Total</BlackBodyText100>
                <BlackH5Text100>{getFormattedNumberForUsdByCent(invoice?.netAmount)}</BlackH5Text100>
              </Row>
            )
          }
        />

        {isLoading ? (
          <LoadingSkeleton count={4} />
        ) : (
          <>
            {" "}
            <Row className="justify-between">
              <Row className="align-items-center">
                <BlackBodyText100 className="mr-2">
                  {getLocalFormattedDateAsDateShort(invoice?.createdAt)}
                </BlackBodyText100>
                <PaidStatusBadge status={invoicePaymentState} />
              </Row>
              <BlackButton
                className="mt-3 mt-lg-0"
                text={isPaid ? "See invoice" : "Pay invoice"}
                onClick={handleForwardToStripe}
              />
            </Row>
            {percent > 0 && (
              <Card className="mt-4">
                <Row className="flex-nowrap">
                  <div className="center">
                    <BlackInfoIcon />
                  </div>

                  <BlackBodyText100 className="ml-2">
                    You have a discount of {percentFixed}%. This discount has been automatically applied to your
                    invoice. Below, you can find a breakdown of the sessions where the discount was applied. Enjoy the
                    savings!
                  </BlackBodyText100>
                </Row>
              </Card>
            )}
            <Row className="justify-between my-2 mt-4 align-items-center">
              <Col>
                <BlackH6Text0>Statement</BlackH6Text0>
                <BlackBodyText200>{description}</BlackBodyText200>
              </Col>

              {exportData && <CsvLinkButton data={exportData} fileName="invoice-statement" />}
            </Row>
          </>
        )}

        {isChargingInvoice ? (
          <ChargingInvoiceDetailsTable
            isLoading={isLoading}
            lineItems={(invoice?.lineItems as ChargingInvoiceLineItem[]) ?? []}
          />
        ) : (
          <PlatformInvoiceDetailsTable
            isLoading={isLoading}
            lineItems={(invoice?.lineItems as PlatformInvoiceLineItem[]) ?? []}
          />
        )}
      </Content>
    </>
  );
}

export default InvoiceDetailsPage;
