import { ChevronLeft } from "@mui/icons-material";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { DriverFormMode, DriverFormStatus } from "types/driver.types";
import { getDriverFormStepStatus } from "utils/driver.utils";
import { decrementDriverNonFormStep, resetDriverForm } from "../../redux/slices/driverSlice";
import TextButton from "./TextButton";
import { AppColors } from "../../utils/colors.utils";

export default function BackButton() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const driverNonFormState = useSelector((state) => state.driver.driverNonFormState);

  const { status, mode } = driverNonFormState;
  const isEditMode = mode === DriverFormMode.EDIT;
  const isDriverFormActive = status === DriverFormStatus.ACTIVE;
  const { isFirstStep, isVehicleSetupStep, isReimbursementStep } = getDriverFormStepStatus(driverNonFormState);

  const routesWhereTheButtonDoesNotVisible = [
    "/",
    "/assign-driver-completed",
    "/add-driver/completed",
    "/add-vehicle/completed",
    "/inactive-user",
    "/vehicle-locator"
  ];
  const routesNeedsControl = ["/add-driver"];
  const doesCurrentRouteNeedControl = !!routesNeedsControl.find((r) => {
    return pathname.includes(r);
  });

  const doesNotCurrentRouteShowTheButtonForDriverFormSteps = isVehicleSetupStep || isReimbursementStep;

  const doesNotCurrentRouteShowTheButton = !!routesWhereTheButtonDoesNotVisible.find((r) => {
    return pathname === r;
  }) || (doesCurrentRouteNeedControl && doesNotCurrentRouteShowTheButtonForDriverFormSteps);

  const history = useHistory();

  const handleDriverFormNavigationBackPressed = useCallback(() => {
    if (isFirstStep) {
      if (isEditMode) {
        history.goBack();
        return;
      }

      dispatch(resetDriverForm());
      return;
    }

    dispatch(decrementDriverNonFormStep());
  }, [dispatch, history, isEditMode, isFirstStep]);

  const handleBackClicked = useCallback(() => {
    if (doesCurrentRouteNeedControl && isDriverFormActive) {
      handleDriverFormNavigationBackPressed();
      return;
    }
    history.goBack();
  }, [doesCurrentRouteNeedControl, handleDriverFormNavigationBackPressed, history, isDriverFormActive]);

  if (doesNotCurrentRouteShowTheButton) {
    return <></>;
  }

  return (
    <TextButton
      id={'back-button'}
      className="hover-background-white  p-1"
      onClick={handleBackClicked}
      icon={<ChevronLeft className="w-20px h-25px" htmlColor={AppColors.neutral100} />}
      textClassName={"text-neutral-100"}
      text="Back"
    />
  );
}
