import React from "react";

type Props = {};

function MailFilledIcon({}: Props) {
  return (

    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.874 0.75H3.12402C2.42806 0.750744 1.76081 1.02755 1.26869 1.51967C0.776569 2.01179 0.499768 2.67904 0.499023 3.375V14.625C0.499768 15.321 0.776569 15.9882 1.26869 16.4803C1.76081 16.9725 2.42806 17.2493 3.12402 17.25H18.874C19.57 17.2493 20.2372 16.9725 20.7294 16.4803C21.2215 15.9882 21.4983 15.321 21.499 14.625V3.375C21.4983 2.67904 21.2215 2.01179 20.7294 1.51967C20.2372 1.02755 19.57 0.750744 18.874 0.75ZM18.2093 5.09203L11.4593 10.342C11.3277 10.4444 11.1657 10.4999 10.999 10.4999C10.8323 10.4999 10.6703 10.4444 10.5387 10.342L3.78871 5.09203C3.7094 5.03214 3.64279 4.95709 3.59274 4.87123C3.54269 4.78537 3.51021 4.69042 3.49717 4.5919C3.48414 4.49338 3.49081 4.39325 3.51681 4.29733C3.5428 4.20142 3.5876 4.11162 3.6486 4.03316C3.7096 3.95471 3.78559 3.88916 3.87214 3.84032C3.95869 3.79149 4.05409 3.76034 4.15278 3.74869C4.25148 3.73704 4.3515 3.74513 4.44705 3.77247C4.54259 3.79981 4.63175 3.84587 4.70934 3.90797L10.999 8.79984L17.2887 3.90797C17.446 3.7892 17.6437 3.73711 17.8391 3.76296C18.0345 3.78881 18.2118 3.89053 18.3328 4.04612C18.4538 4.20171 18.5086 4.39866 18.4855 4.59439C18.4624 4.79011 18.3632 4.96888 18.2093 5.09203Z"
        fill="black" />
    </svg>

  );
}

export default MailFilledIcon;
