import { SvgProps } from "types/svg.types";

type Props = {};

function SelfSignupDriverIcon(props: SvgProps) {
  return (
    <svg width="65" height="66" viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect y="0.5" width="65" height="65" rx="32.5" fill="#2F6265" />
      <path
        d="M31.25 32.9202C35.4497 32.9202 38.8542 29.5157 38.8542 25.3161C38.8542 21.1164 35.4497 17.7119 31.25 17.7119C27.0503 17.7119 23.6458 21.1164 23.6458 25.3161C23.6458 29.5157 27.0503 32.9202 31.25 32.9202Z"
        fill="white"
      />
      <path
        d="M27.0677 35.9619C21.8181 35.9619 17.5625 40.2175 17.5625 45.4671V46.6077C17.5625 47.4477 18.2434 48.1286 19.0833 48.1286H43.4167C44.2566 48.1286 44.9375 47.4477 44.9375 46.6077V45.4671C44.9375 40.2175 40.6819 35.9619 35.4323 35.9619H27.0677Z"
        fill="white"
      />
      <path
        d="M44.4042 41.857C44.2402 42.6481 43.8512 43.4017 43.2373 44.0156L39.0977 48.1552C37.4221 49.8308 34.7051 49.8309 33.0294 48.1552L31.8462 46.972C30.1704 45.2962 30.1706 42.5793 31.8462 40.9037L33.4074 39.3425C33.7419 39.0079 34.2113 38.9747 34.562 39.1339L34.5621 39.1339C34.5724 39.1386 34.5827 39.1435 34.5929 39.1485C34.757 38.3575 35.146 37.6039 35.7599 36.99L39.8995 32.8504C41.5751 31.1748 44.292 31.1747 45.9677 32.8504L47.151 34.0336C48.8267 35.7094 48.8266 38.4263 47.151 40.1019L45.5898 41.6631C45.2552 41.9977 44.7859 42.0309 44.4351 41.8717L44.4042 41.857ZM44.4042 41.857C44.4145 41.8621 44.4247 41.867 44.4351 41.8717L44.4042 41.857ZM35.3862 43.584C35.5508 43.4195 35.6624 43.2265 35.7251 43.0231C35.7366 43.0349 35.7482 43.0466 35.7599 43.0583L36.3512 43.6496C36.541 43.8394 36.7728 43.9615 37.0166 44.0159L36.0635 44.9689L35.0324 43.9378L35.3862 43.584ZM43.611 37.4215C43.4464 37.5861 43.3347 37.7791 43.2721 37.9825C43.2605 37.9707 43.249 37.959 43.2373 37.9473L42.6457 37.3558C42.4561 37.1661 42.2244 37.044 41.9807 36.9896L42.9336 36.0366L43.9647 37.0678L43.611 37.4215ZM39.2489 40.3269L39.2473 40.3253L38.9461 40.0241L39.1694 39.8009C39.2238 40.0446 39.3459 40.2763 39.5355 40.466L40.051 40.9815L39.8272 41.2053C39.773 40.9624 39.6516 40.7315 39.4631 40.5421L39.2489 40.3269Z"
        fill="white"
        stroke="#2F6265"
        stroke-width="2"
      />
    </svg>
  );
}

export default SelfSignupDriverIcon;
