import HorizontalLine from "components/line/HorizontalLine";
import { BlackBody2Text100, BlackBody2Text200 } from "components/text/Text";
import React, { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  label: string;
  value?: string;
  className?: string;
  descriptionElement?: React.JSX.Element;
  isBorderHidden?: boolean;
}>;
export default function DriverDetailsSectionFormBox({
  label,
  value,
  className,
  isBorderHidden,
  descriptionElement,
  children,
}: Props) {
  return (
    <div>
      <div className={`center justify-between ${className ?? ""}`}>
        <BlackBody2Text200>{label}</BlackBody2Text200>
        {value && <BlackBody2Text100 className={"wide-md ml-2 text-truncate"}>{value}</BlackBody2Text100>}
        {children && <div className="max-w-250px ">{children}</div>}
      </div>
      {descriptionElement && <div className="mt-1">{descriptionElement}</div>}
      {!isBorderHidden && <HorizontalLine />}
    </div>
  );
}
