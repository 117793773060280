import FilterEditorCard from "components/filter/FilterEditorCard";
import FilterMenuCheckbox from "components/filter/FilterMenuCheckbox";
import { useFilterUrlQuery } from "hooks/filter.hooks";
import { useCallback, useEffect, useMemo, useState } from "react";

import { getReimbursementPaymentStateOptions } from "../../utils/reimbursement.utils";
import { ReimbursementFilters, ReimbursementPaymentState } from "../../types/reimbursement.types";

type Props = {};

function ReimbursementFilterPaymentStateSelector(props: Props) {
  const { filterQuery, setFilterQuery } = useFilterUrlQuery<ReimbursementFilters>();
  const payStatsOnQuery = useMemo(() => filterQuery?.paymentState?.$in ?? [], [filterQuery?.paymentState?.$in]);

  const [selectedPayStat, setSelectedPayStats] = useState<ReimbursementPaymentState[]>(payStatsOnQuery);
  const [variationCount, setVariationCount] = useState(0);

  const paymentStateOptions = getReimbursementPaymentStateOptions();

  useEffect(() => {
    setSelectedPayStats(payStatsOnQuery);
    setVariationCount(Math.min(payStatsOnQuery?.length ?? 0, paymentStateOptions.length));
  }, [filterQuery, payStatsOnQuery, paymentStateOptions.length]);

  const isSelectedPayStat = useCallback(
    (payStat: ReimbursementPaymentState) => {
      return selectedPayStat.includes(payStat);
    },
    [selectedPayStat]
  );

  const handlePayStatToggled = useCallback(
    (payStat: ReimbursementPaymentState) => {
      if (isSelectedPayStat(payStat)) {
        setSelectedPayStats(selectedPayStat.filter((n) => n !== payStat));
        return;
      }
      setSelectedPayStats([...selectedPayStat, payStat]);
    },
    [isSelectedPayStat, selectedPayStat]
  );

  const handleSetPayStatsOnQuery = useCallback(
    (payStats: ReimbursementPaymentState[]) => {
      setFilterQuery((q) => {
        return {
          paymentState: {
            $in: payStats
          }
        };
      });
    },
    [setFilterQuery]
  );

  const handleClearButtonPressed = useCallback(() => {
    setSelectedPayStats([]);
    handleSetPayStatsOnQuery([]);
    setVariationCount(0);
  }, [handleSetPayStatsOnQuery]);

  const handleApplyButtonPressed = useCallback(() => {
    handleSetPayStatsOnQuery(selectedPayStat);
    setVariationCount(selectedPayStat.length);
  }, [handleSetPayStatsOnQuery, selectedPayStat]);

  return (
    <FilterEditorCard
      className="my-1"
      title="PayStat"
      label="PayStat"
      description="Payment status of reimbursement"
      variationCount={variationCount}
      onApplyPressed={handleApplyButtonPressed}
      onClearPressed={handleClearButtonPressed}
    >
      {paymentStateOptions.map((payStatOption, ix) => (
        <FilterMenuCheckbox
          key={ix}
          onToggle={() => handlePayStatToggled(payStatOption.value)}
          isChecked={isSelectedPayStat(payStatOption.value)}
          label={payStatOption.label}
          textClassName={payStatOption.textClass}
        />
      ))}
    </FilterEditorCard>
  );
}

export default ReimbursementFilterPaymentStateSelector;
