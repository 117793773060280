import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, UserState } from "../../types/user.types";
import { InboxNotificationSettings } from "../../types/settings.types";

const initialState: UserState = {
  currentUser: null
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateOrganizationBusinessName: (state, action: PayloadAction<string>) => {
      if (!state.currentUser) {
        return;
      }
      state.currentUser.businessName = action.payload;
    },
    updateOrganizationInboxNotificationSettings: (state, action: PayloadAction<InboxNotificationSettings>) => {
      if (!state.currentUser) {
        return;
      }
      state.currentUser.notificationSettings.inboxSettings = action.payload;
    },
    reduceUnreadMessageCount: (state) => {
      if (state.currentUser?.unreadMessageCount && state.currentUser.unreadMessageCount > 0) {
        --state.currentUser.unreadMessageCount;
      }
    },
    login: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
    logout: (state) => {
      state.currentUser = null;
    }

  }
});

export const {
  login,
  logout,
  updateOrganizationBusinessName,
  reduceUnreadMessageCount,
  updateOrganizationInboxNotificationSettings
} = userSlice.actions;
export default userSlice.reducer;